import React from 'react';
import { useDispatch } from 'react-redux';

import { BookIcon } from 'assets/icons';
import { Card } from 'components/Card';
import { Typography, TypographyVariants } from 'components/Typography';
import { KBArticleTableFilterToRequest } from 'features/KnowledgeBase/types';

import {
  ActionsProps,
  KBArticlesSearch,
  SelectorsProps,
} from '../../../../components/KBArticlesSearch';
import {
  fetchArticlesMainRequest,
  resetArticlesStateArticlesMain,
  setArticlesMainFilter,
} from '../../ducks/actions';
import {
  getArticlesMain,
  getLoadingArticlesMain,
  getPaginationArticlesMain,
  getSelectedOrganizationArticlesMain,
} from '../../ducks/selectors';
import { FetchArticlesParams } from '../../ducks/types';

import styles from './KBMainWelcome.module.scss';

export const KBMainWelcome = () => {
  const dispatch = useDispatch();

  const resetState = () => {
    dispatch(resetArticlesStateArticlesMain());
  };

  const fetch = (params: FetchArticlesParams) => {
    dispatch(fetchArticlesMainRequest(params));
  };

  const setFilter = (filter: KBArticleTableFilterToRequest) => {
    dispatch(setArticlesMainFilter(filter));
  };

  const selectorsProps: SelectorsProps = {
    getList: getArticlesMain,
    getLoading: getLoadingArticlesMain,
    getOrganizationsSelected: getSelectedOrganizationArticlesMain,
    getPaginationArticles: getPaginationArticlesMain,
  };

  const actionsProps: ActionsProps = {
    resetState,
    fetch,
    setFilter,
  };

  return (
    <Card className={styles.KBMainWelcome}>
      <div className={styles.KBMainWelcome__wrapper}>
        <BookIcon className={styles.KBMainWelcome__bookIcon} />
        <Typography
          variant={TypographyVariants.h2}
          className={styles.KBMainWelcome__title}
        >
          Добро пожаловать
        </Typography>
        <Typography
          variant={TypographyVariants.b3}
          className={styles.KBMainWelcome__subTitle}
        >
          Найдите интересующую вас информацию в одной из категорий или
          воспользуйтесь поиском
        </Typography>
        <KBArticlesSearch
          selectorsProps={selectorsProps}
          actionsProps={actionsProps}
        />
      </div>
    </Card>
  );
};

import cn from 'clsx';
import React, { forwardRef } from 'react';

import { Typography, TypographyVariants } from 'components/Typography';

import styles from './ToggleSwitch.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  classNameLabel?: string;
}

export const ToggleSwitch = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { label, disabled, className, classNameLabel, ...otherProps } = props;

    return (
      <label
        className={cn(
          styles.toggleSwitch,
          {
            [styles.toggleSwitch_disabled]: disabled,
          },
          className
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          disabled={disabled}
          className={styles.toggleSwitch__input}
          {...otherProps}
        />
        <span className={cn(styles.toggleSwitch__slider)} />
        {label && (
          <Typography
            variant={TypographyVariants.b3}
            className={cn(styles.toggleSwitch__label, classNameLabel)}
          >
            {label}
          </Typography>
        )}
      </label>
    );
  }
);

import { debounce } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ValueType } from 'components/Select/types';
import { CustomFieldTypeForFilter } from 'components/Table/components/TableFilter/types';
import {
  getCustomFieldType,
  getPreparedCustomFieldsForSelect,
} from 'components/Table/components/TableFilter/utils';
import {
  getIsAdmin,
  getIsClient,
  getIsGroupSpecialist,
  getManagerWorkGroupsIds,
  getUserId,
  getWorkGroupsIds,
} from 'features/Auth/ducks/selectors';
import { OrganizationContext } from 'features/Organization';
import { RouterHref } from 'routes/routerHref';
import { LocationState } from 'routes/types';
import { checkObjectIdentity } from 'utils';

import {
  createFilterRequest,
  deleteFilterRequest,
  fetchTicketsRequest,
  setCurrentFilterId,
  setCurrentFilterTitleRequest,
  setCurrentTicketPage,
  setIsUsedFilter,
  setSortTickets,
  setTicketsFilter,
} from '../ducks/actions';
import {
  enabledFieldsList,
  getCurrentFilterId,
  getFiltersData,
  getFilterValues,
  getIsUsedFilter,
  getSettingFields,
  getStatusesSelectList,
  getTicketTypesOptions,
} from '../ducks/selectors';
import {
  SetCurrentFilterTitleRequest,
  TicketsFilterForRender,
  TicketTabType,
} from '../types';
import {
  getCurrentTableType,
  getDefaultFilterValues,
  getPreparedCustomFieldsForSubmit,
  getPrepareFilterTicketsToRender,
  getPrepareFilterTicketsToRequest,
} from '../utils';

export const useTicketsFilter = () => {
  const dispatch = useDispatch();

  const isWorkGroupSpecialist = useSelector(getIsGroupSpecialist);
  const isAdmin = useSelector(getIsAdmin);
  const isClient = useSelector(getIsClient);
  const userId = useSelector(getUserId);
  const userWorkGroupsIds = useSelector(getWorkGroupsIds);
  const userManagerWorkGroupsIds = useSelector(getManagerWorkGroupsIds);
  const filterValues = useSelector(getFilterValues);
  const statusesOptions = useSelector(getStatusesSelectList);
  const filtersData = useSelector(getFiltersData);
  const currentFilterId = useSelector(getCurrentFilterId);
  const isUsedFilter = useSelector(getIsUsedFilter);
  const { defaultColumns, customColumns } = useSelector(enabledFieldsList);
  const settingFields = useSelector(getSettingFields);
  const ticketTypesOptions = useSelector(getTicketTypesOptions);
  const customFieldType = getCustomFieldType(settingFields?.customColumns);

  const { pathname } = useLocation<LocationState>();

  const { contractIds } = useContext(OrganizationContext);

  const [filterTitle, setFilterTitle] = useState('');

  const isMyTicketsPage = pathname === RouterHref.Tickets;
  const isAllTicketsPage = pathname === RouterHref.TicketsAll;
  const isGroupsTicketsPage = pathname === RouterHref.TicketsGroup;

  const isOrganizationCardPage = pathname.includes('organizations');

  const isSpecialistAllTickets =
    !isClient && (isAllTicketsPage || isGroupsTicketsPage);
  const isSpecialistColumn = isSpecialistAllTickets || isClient;

  const defaultFilterValues = getDefaultFilterValues({
    userId,
    userWorkGroupsIds,
    isMyTicketsPage,
    isAllTicketsPage,
    contractIds,
    userManagerWorkGroupsIds,
    isGroupsTicketsPage,
  });

  const disableReset = checkObjectIdentity(
    filterValues as Record<string, TicketsFilterForRender>,
    defaultFilterValues as Record<string, TicketsFilterForRender>
  );

  const fetchTicketsDebounced = useMemo(
    () =>
      debounce((ticketTab: TicketTabType) => {
        dispatch(
          fetchTicketsRequest({ ticketTab, withFirstTicketFetch: true })
        );
      }, 500),
    []
  );

  const formSubmitHandler = (filter: TicketsFilterForRender) => {
    const ticketTab = getCurrentTableType(pathname);

    dispatch(setTicketsFilter(filter));
    dispatch(setCurrentTicketPage(0));
    dispatch(setSortTickets('datecreate_DESC'));
    fetchTicketsDebounced(ticketTab);
  };

  const resetFilterHandler = () => {
    formSubmitHandler(defaultFilterValues);

    if (currentFilterId !== 'NONE') {
      dispatch(setCurrentFilterId('NONE'));
    }
  };

  const resetCurrentFilter = () => {
    dispatch(setCurrentFilterId(''));
    dispatch(setIsUsedFilter(true));
  };

  const handleChangeInputFilter =
    (valueName: string) => (value: React.ChangeEvent<HTMLInputElement>) => {
      resetCurrentFilter();

      formSubmitHandler({
        ...filterValues,
        [valueName]: value.currentTarget.value,
      });
    };

  const handleChangeInputCustomField =
    (valueName: CustomFieldTypeForFilter) =>
    (value: React.ChangeEvent<HTMLInputElement>) => {
      resetCurrentFilter();

      const preparedFilter = {
        ...filterValues,
        customFields: [{ ...valueName, text: value.currentTarget.value }],
      };

      formSubmitHandler({
        ...preparedFilter,
        customFields: getPreparedCustomFieldsForSubmit(
          preparedFilter,
          filterValues
        ),
      });
    };

  const handleChangeSelectFilter =
    <T>(valueName: string) =>
    (value: ValueType<T> | string) => {
      resetCurrentFilter();

      formSubmitHandler({
        ...filterValues,
        [valueName]: value,
      });
    };

  const handleChangeSelectCustomField =
    <T>(valueName: CustomFieldTypeForFilter) =>
    (value: ValueType<T> | string) => {
      resetCurrentFilter();

      const preparedFilter = {
        ...filterValues,
        customFields: [
          {
            ...valueName,
            ...getPreparedCustomFieldsForSelect(valueName, value),
          },
        ],
      };

      formSubmitHandler({
        ...preparedFilter,
        customFields: getPreparedCustomFieldsForSubmit(
          preparedFilter,
          filterValues
        ),
      });
    };

  const setCurrentFilters = (id: string) => {
    dispatch(setCurrentFilterId(id));
    dispatch(setIsUsedFilter(false));

    const currentFilter = filtersData?.find(
      (filter) => filter.id === id
    )?.filter;

    if (id === 'NONE') {
      resetFilterHandler();
      dispatch(setIsUsedFilter(false));
    }

    if (currentFilter) {
      formSubmitHandler(getPrepareFilterTicketsToRender(currentFilter));
    }
  };

  const handleChangeFilterTitle = (title: string) => {
    setFilterTitle(title);
  };

  const handleSubmitFilter = () => {
    const ticketTab = getCurrentTableType(pathname);

    dispatch(
      createFilterRequest({
        title: filterTitle,
        ticketTab,
        filter: getPrepareFilterTicketsToRequest({
          filter: filterValues,
          isAdmin,
          ticketTab,
        }),
      })
    );
  };

  const handleSubmitFilterTitle = ({
    id,
    title,
  }: SetCurrentFilterTitleRequest) => {
    dispatch(
      setCurrentFilterTitleRequest({
        id,
        title,
        ticketTab: getCurrentTableType(pathname),
      })
    );
  };

  const handleDeleteFilter = (id: string) => {
    dispatch(
      deleteFilterRequest({ id, ticketTab: getCurrentTableType(pathname) })
    );

    if (id === currentFilterId) {
      resetFilterHandler();
      dispatch(setCurrentFilterId('NONE'));
      dispatch(setIsUsedFilter(false));
    }
  };

  return {
    state: {
      filterValues,
      filtersData,
      defaultFilterValues,
      currentFilterId,
      isUsedFilter,
      statusesOptions,
      isOrganizationCardPage,
      isClient,
      isMyTicketsPage,
      isSpecialistColumn,
      isWorkGroupSpecialist,
      defaultColumns,
      customColumns,
      customFieldType,
      ticketTypesOptions,
      ticketTab: getCurrentTableType(pathname),
    },
    methods: {
      handleChangeInputFilter,
      handleChangeSelectFilter,
      resetFilterHandler,
      setCurrentFilters,
      handleSubmitFilter,
      handleSubmitFilterTitle,
      handleDeleteFilter,
      handleChangeFilterTitle,
      disableReset,
      handleChangeSelectCustomField,
      handleChangeInputCustomField,
    },
  };
};

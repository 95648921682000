import React from 'react';
import { Redirect } from 'react-router-dom';

import { BreadCrumbsLayout } from 'core/layouts/BreadCrumbsLayout';
import { HrefLayout } from 'core/layouts/HrefLayout';
import { PageTitleLayout } from 'core/layouts/PageTitleLayout';
import { AccessedRoute } from 'features/Auth/types';
import { CustomRoute } from 'routes/CustomRoute';

import { RouterConfig } from '../types';

export const getRouteList = (
  routes: RouterConfig[],
  accessedRoutes?: AccessedRoute[]
) => {
  return routes.map(
    ({
      href,
      component,
      exact = true,
      getPageTitle,
      breadCrumbTitle,
      withPermission = true,
    }) => {
      const isUserHasAccess =
        accessedRoutes?.find((accessedRoute) => accessedRoute.route === href) ||
        !withPermission;

      return (
        <CustomRoute
          key={`${breadCrumbTitle}-${href}`}
          exact={exact}
          path={href}
          render={(props) => {
            if (isUserHasAccess) {
              const Component = component;

              const getTitle = () => {
                if (getPageTitle) {
                  const isIdentical = getPageTitle() === breadCrumbTitle;
                  return isIdentical
                    ? getPageTitle()
                    : getPageTitle(breadCrumbTitle);
                }
                return breadCrumbTitle;
              };

              return (
                <PageTitleLayout pageTitle={getTitle()}>
                  <BreadCrumbsLayout routes={routes} props={props}>
                    <HrefLayout href={href}>
                      <Component />
                    </HrefLayout>
                  </BreadCrumbsLayout>
                </PageTitleLayout>
              );
            }
            return <Redirect to="/404" />;
          }}
        />
      );
    }
  );
};

import cn from 'clsx';
import React, { SVGProps } from 'react';

import styles from './IconButtonWrapper.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: SVGProps<SVGElement>;
}

export const IconButtonWrapper: React.FC<Props> = ({
  className,
  type,
  icon,
  ...otherProps
}) => {
  return (
    <button
      type={type}
      className={cn(styles.iconButton, className)}
      {...otherProps}
    >
      {icon}
    </button>
  );
};

import React, { memo } from 'react';

import { Navigation } from 'core/components/Navigation';
import { TopBar } from 'core/components/TopBar';
import { UserAssistance } from 'features/UserAssistance';

import styles from './SidebarLayout.module.scss';

interface Props {
  authLoading?: boolean;
}

export const SidebarLayout: React.FC<Props> = memo(
  ({ children, authLoading }) => {
    const topBar = !authLoading && <TopBar />;

    return (
      <div className={styles.sidebarLayout}>
        <Navigation authLoading={authLoading} />
        <div className={styles.sidebarLayout__wrapper}>
          {topBar}
          {children}
        </div>
        <UserAssistance />
      </div>
    );
  }
);

import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { setRedirectPath } from 'core/ducks/actions';
import { Contract, System, TicketTypeSettings, WorkGroup } from 'core/types';
import { request as contractRequest } from 'features/Contract/ducks/api/requests';
import { SLAType } from 'features/Contract/types';
import { getFilterGroupsToRequest } from 'features/WorkGroups/ducks/saga';
import { ResponseWithMeta } from 'store/types';
import { Attachment } from 'types/models/attachment';
import { createError } from 'utils';

import {
  fetchAttachmentsSuccessSupAgDetail,
  fetchSlaBySAIdBySystemIdSuccessSADetail,
  fetchSupplementaryAgreementRequestSADetail,
  fetchSupplementaryAgreementSuccessSADetail,
  fetchSystemByIdSuccessSupAgDetail,
  fetchSystemsBySAIdSuccessSADetail,
  fetchTicketTypesSuccessSADetail,
  fetchWorkGroupsBySupAgBySystemSuccessSupAgDetail,
  getNumberForCreateSuccessSupAgDetail,
  hideAttachmentsLoadingSADetail,
  hideSupplementaryAgreementLoadingSADetail,
  loadingHideSlaBySAIdBySystemIdRequestSADetail,
  loadingHideSystemByIdSADetail,
  loadingHideWorkGroupsBySupAgBySystemSupAgDetail,
  loadingShowSlaBySAIdBySystemIdRequestSADetail,
  loadingShowSystemByIdSADetail,
  loadingShowWorkGroupsBySupAgBySystemSupAgDetail,
  showAttachmentsLoadingSADetail,
  showSupplementaryAgreementLoadingSADetail,
} from './actions';
import { request } from './api/requests';
import {
  getGroupsFilterSupAgDetail,
  getPropsGroupsSupAgDetail,
} from './selectors';
import {
  CreateSupplementaryAgreementSupAgDetailAction,
  DeleteSupplementaryAgreementDetailRequestAction,
  FetchAttachmentsRequestSupAgDetailAction,
  FetchSlaBySAIdBySystemIdRequestSupAgDetailAction,
  FetchSystemByIdRequestSupAgDetailAction,
  FetchSystemsBySAIdRequestSupAgDetailAction,
  FetchTicketTypesRequestSupAgDetailAction,
  FetchWorkGroupsBySABySystemRequestSupAgDetailAction,
  GetNumberForCreateRequestSupAgDetailAction,
  SupplementaryAgreementDetail,
} from './types';

function* SupAgADetailFetch({
  payload,
}: ReturnType<typeof fetchSupplementaryAgreementRequestSADetail>) {
  try {
    yield put(showSupplementaryAgreementLoadingSADetail());
    const supplementaryAgreement: Contract = yield call(
      request.fetchCurrentSupplementaryAgreement,
      payload
    );
    yield put(
      fetchSupplementaryAgreementSuccessSADetail(supplementaryAgreement)
    );
    yield put(hideSupplementaryAgreementLoadingSADetail());
  } catch (e) {
    yield put(hideSupplementaryAgreementLoadingSADetail());
    createError(e);
  }
}

function* deleteSupplementaryAgreement({
  payload,
}: DeleteSupplementaryAgreementDetailRequestAction) {
  try {
    if (payload?.id) {
      yield call(request.deleteSupplementaryAgreement, payload.id);
    }
    if (payload?.mainContractId) {
      yield put(setRedirectPath(`/admin/contracts/${payload.mainContractId}`));
    }
  } catch (e) {
    createError(e);
  }
}

function* createSupplementaryAgreement({
  payload,
}: CreateSupplementaryAgreementSupAgDetailAction) {
  try {
    const { contractId, systemId, ...other } = payload;
    if (contractId && systemId) {
      const createdSupAg: Contract = yield call(
        request.createSupAg,
        contractId,
        systemId,
        other
      );
      yield put(
        setRedirectPath(`/admin/supplementary-agreement/${createdSupAg.id}`)
      );
    }
  } catch (e) {
    createError(e);
  }
}

function* fetchSupplementaryAgreementAttachments({
  payload,
}: FetchAttachmentsRequestSupAgDetailAction) {
  try {
    yield put(showAttachmentsLoadingSADetail());
    const attachments: Attachment[] = yield call(
      request.fetchSupAgAttachments,
      payload
    );
    if (attachments) {
      yield put(fetchAttachmentsSuccessSupAgDetail(attachments));
    }
    yield put(hideAttachmentsLoadingSADetail());
  } catch (e) {
    yield put(hideAttachmentsLoadingSADetail());
    createError(e);
  }
}

function* fetchSystemById({
  payload,
}: FetchSystemByIdRequestSupAgDetailAction) {
  try {
    yield put(loadingShowSystemByIdSADetail());
    const system: System = yield call(request.fetchSystemById, payload);
    yield put(fetchSystemByIdSuccessSupAgDetail(system));
    yield put(loadingHideSystemByIdSADetail());
  } catch (e) {
    yield put(loadingHideSystemByIdSADetail());
    createError(e);
  }
}

function* fetchSystemBySupAgId({
  payload,
}: FetchSystemsBySAIdRequestSupAgDetailAction) {
  try {
    yield put(loadingShowSystemByIdSADetail());
    const systems: System[] = yield call(request.fetchSystemBySupAgId, payload);
    yield put(fetchSystemsBySAIdSuccessSADetail(systems));
    yield put(loadingHideSystemByIdSADetail());
  } catch (e) {
    yield put(loadingHideSystemByIdSADetail());
    createError(e);
  }
}

function* fetchWorkGroups({
  payload,
}: FetchWorkGroupsBySABySystemRequestSupAgDetailAction) {
  try {
    const { contractId, systemId } = payload;
    if (contractId && systemId) {
      yield put(loadingShowWorkGroupsBySupAgBySystemSupAgDetail());
      const filters: ReturnType<typeof getGroupsFilterSupAgDetail> =
        yield select(getGroupsFilterSupAgDetail);
      const {
        pageNum,
        pageSize,
        sort,
      }: ReturnType<typeof getPropsGroupsSupAgDetail> = yield select(
        getPropsGroupsSupAgDetail
      );

      const groups: ResponseWithMeta<WorkGroup[]> = yield call(
        request.fetchGroupsByContractIdBySystemId,
        contractId,
        systemId,
        pageNum,
        pageSize,
        sort,
        getFilterGroupsToRequest(filters)
      );
      yield put(fetchWorkGroupsBySupAgBySystemSuccessSupAgDetail(groups));
      yield put(loadingHideWorkGroupsBySupAgBySystemSupAgDetail());
    }
  } catch (e) {
    yield put(loadingHideWorkGroupsBySupAgBySystemSupAgDetail());
    createError(e);
  }
}

function* fetchSLABySupAgIdBySystemId({
  payload,
}: FetchSlaBySAIdBySystemIdRequestSupAgDetailAction) {
  try {
    const { systemId, contractId } = payload;
    if (systemId && contractId) {
      yield put(loadingShowSlaBySAIdBySystemIdRequestSADetail());
      const sla: SLAType = yield call(
        request.fetchSLAByContractIdBySystemId,
        contractId,
        systemId
      );
      yield put(fetchSlaBySAIdBySystemIdSuccessSADetail(sla));
      yield put(loadingHideSlaBySAIdBySystemIdRequestSADetail());
    }
  } catch (e) {
    yield put(loadingHideSlaBySAIdBySystemIdRequestSADetail());
    createError(e);
  }
}

function* fetchTicketTypes({
  payload,
}: FetchTicketTypesRequestSupAgDetailAction) {
  try {
    const ticketTypes: TicketTypeSettings[] = yield call(
      contractRequest.fetchSettingsTicketType,
      payload
    );
    yield put(fetchTicketTypesSuccessSADetail(ticketTypes));
  } catch (e) {
    createError(e);
  }
}

function* getCreateNumber({
  payload,
}: GetNumberForCreateRequestSupAgDetailAction) {
  try {
    if (payload) {
      const title: string = yield call(request.getCreateSupAgNumber, payload);
      yield put(getNumberForCreateSuccessSupAgDetail(title));
    }
  } catch (e) {
    createError(e);
  }
}

export function* supplementaryAgreementDetailSaga(): Generator<StrictEffect> {
  yield takeEvery(
    SupplementaryAgreementDetail.FETCH_SUP_AG_REQUEST,
    SupAgADetailFetch
  );
  yield takeEvery(
    SupplementaryAgreementDetail.DELETE_SUP_AG_REQUEST,
    deleteSupplementaryAgreement
  );
  yield takeEvery(
    SupplementaryAgreementDetail.CREATE_SUP_AG_DETAIL,
    createSupplementaryAgreement
  );
  yield takeEvery(
    SupplementaryAgreementDetail.FETCH_SYSTEM_BY_ID_REQUEST,
    fetchSystemById
  );
  yield takeEvery(
    SupplementaryAgreementDetail.FETCH_SYSTEMS_BY_SUP_AG_ID_REQUEST,
    fetchSystemBySupAgId
  );
  yield takeEvery(
    SupplementaryAgreementDetail.FETCH_SUP_AG_ATTACHMENTS_REQUEST,
    fetchSupplementaryAgreementAttachments
  );
  yield takeEvery(
    SupplementaryAgreementDetail.FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_REQUEST,
    fetchWorkGroups
  );
  yield takeEvery(
    SupplementaryAgreementDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
    fetchSLABySupAgIdBySystemId
  );
  yield takeEvery(
    SupplementaryAgreementDetail.FETCH_TICKET_TYPES_REQUEST,
    fetchTicketTypes
  );
  yield takeEvery(
    SupplementaryAgreementDetail.GET_NUMBER_FOR_CREATE_REQUEST,
    getCreateNumber
  );
}

import { createSelector } from 'reselect';

import { SelectOption } from 'components/Select/types';
import { CustomFieldType } from 'components/types';
import { convertKeyValueOptionToArraySelectOption } from 'core/utils';
import { RootState } from 'store/rootReducer';
import { KeyValueOption } from 'types/models/meta';

import { AttributesForFilter, CustomFields } from '../types';

export const getTriggers = (state: RootState) => state.triggers.triggers;
export const getLoading = (state: RootState) => state.triggers.loading;
export const getCurrentTrigger = (state: RootState) => state.triggers.trigger;
export const getCurrentTriggerId = (state: RootState) =>
  state.triggers.triggerId;
export const getFilterValues = (state: RootState) => state.triggers.filter;
export const getAttributesFields = (state: RootState) =>
  state.triggers.attributesFields;
export const getFiltersFields = (state: RootState) =>
  state.triggers.filtersFields;
export const getOrganization = (state: RootState) =>
  state.triggers.filtersFields?.organizations;
export const getSystems = (state: RootState) =>
  state.triggers.filtersFields?.systems || [];
export const getTicketTypes = (state: RootState) =>
  state.triggers.filtersFields?.ticketTypes || [];
export const getTicketPriorities = (state: RootState) =>
  state.triggers.filtersFields?.ticketPriorities || [];
export const getEnvironments = (state: RootState) =>
  state.triggers.filtersFields?.environments || [];
export const getStatus = (state: RootState) =>
  state.triggers.filtersFields?.status || [];
export const getClients = (state: RootState) =>
  state.triggers.filtersFields?.clients || [];
export const getCustomFields = (state: RootState) =>
  state.triggers.customFields || [];
export const getNextTicketStatus = (state: RootState) =>
  state.triggers.nextTicketStatus || [];

export const getCustomFieldsMap = createSelector<
  RootState,
  CustomFields[] | undefined,
  Record<string, CustomFieldType>
>([getCustomFields], (customFields) => {
  return (
    customFields?.reduce<Record<string, CustomFieldType>>(
      (acc, { id, customFieldType }) => {
        const result = { ...acc };
        result[id] = customFieldType;
        return result;
      },
      {}
    ) || {}
  );
});

export const getNextTicketStatusOptions = createSelector<
  RootState,
  KeyValueOption[],
  SelectOption[]
>([getNextTicketStatus], (nextTicketStatus): SelectOption[] => {
  return convertKeyValueOptionToArraySelectOption(nextTicketStatus) || [];
});

export const getAttributesForFilter = createSelector<
  RootState,
  KeyValueOption[] | KeyValueOption | undefined,
  AttributesForFilter
>(
  [
    getOrganization,
    getSystems,
    getTicketTypes,
    getTicketPriorities,
    getEnvironments,
    getStatus,
    getClients,
  ],
  (
    organizations,
    systems,
    ticketTypes,
    ticketPriorities,
    environments,
    status,
    clients
  ): AttributesForFilter => {
    return {
      organizations:
        convertKeyValueOptionToArraySelectOption(organizations) || [],
      systems: convertKeyValueOptionToArraySelectOption(systems) || [],
      ticketTypes: convertKeyValueOptionToArraySelectOption(ticketTypes) || [],
      ticketPriorities:
        convertKeyValueOptionToArraySelectOption(ticketPriorities) || [],
      environments:
        convertKeyValueOptionToArraySelectOption(environments) || [],
      status: convertKeyValueOptionToArraySelectOption(status) || [],
      clients: convertKeyValueOptionToArraySelectOption(clients) || [],
    };
  }
);

export const getPropsTriggers = (state: RootState) => ({
  pageNum: state.triggers.pagination?.pageNum,
  pageSize: state.triggers.pagination?.pageSize,
  loadingTriggers: getLoading(state),
  sortTriggers: state.triggers?.sort,
  totalElements: state.triggers.pagination?.totalElements,
});

import cn from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LazyLoader } from 'core/components/LazyLoader';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { getLoadingContract } from 'features/Contracts/ducks/selectors';

import { ContractForm } from './components/ContractForm';
import { ContractSign } from './components/ContractSign';
import { EnvironmentTableContainer } from './components/EnvironmentTableContainer';
import { ResponsibleForContract } from './components/ResponsibleForContract';
import { SupplementaryAgreementsAndAttachments } from './components/SupplementaryAgreementsAndAttachments';
import { SystemsWithSLA } from './components/SystemsWithSLA';
import { WorkGroupsTableContainer } from './components/WorkGroupsTableContainer';
import styles from './Contract.module.scss';
import {
  fetchContractAttachmentsRequestContractDetail,
  fetchContractDetailRequest,
  fetchSupplementaryAgreementsRequest,
  fetchSystemsByContractIdRequestContractDetail,
  resetContractDetailState,
  resetContractDetailStateWithoutSupportTypeAndTransfer,
} from './ducks/actions';
import { getContractDetail, getSystemsTransfer } from './ducks/selectors';
import { useContractId } from './hooks/useContractId';

interface Props {
  className?: string;
}

export const Contract: React.FC<Props> = ({ className }) => {
  const contractId = useContractId();

  const dispatch = useDispatch();

  const loading = useSelector(getLoadingContract);
  const contract = useSelector(getContractDetail);
  const { isTransfer } = useSelector(getSystemsTransfer);

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);

  const isMobileAll = isMobile || isMobileSmall;

  const isAccessToCreateContract = useSelector(
    getIsAccessedAction(ActionList.CreateContract)
  );

  useEffect(() => {
    if (contractId) {
      dispatch(fetchContractDetailRequest(contractId));
      dispatch(fetchSupplementaryAgreementsRequest(contractId));
      dispatch(fetchContractAttachmentsRequestContractDetail(contractId));
      dispatch(
        fetchSystemsByContractIdRequestContractDetail({
          id: contractId,
          withDeletedSystems: true,
        })
      );
    }
  }, [contractId]);

  useEffect(
    () => () => {
      if (isTransfer) {
        dispatch(resetContractDetailState());
        return;
      }
      dispatch(resetContractDetailStateWithoutSupportTypeAndTransfer());
    },
    [isTransfer]
  );

  if (loading && !contract) {
    return <LazyLoader className={styles.contact__loader} />;
  }

  const mobileSignComponent = isMobileAll && contractId && (
    <div className={styles.contract__subscribe}>
      <ContractSign status={contract?.status} contractId={contractId} />
    </div>
  );

  return (
    <div className={cn(styles.contract, className)}>
      {mobileSignComponent}
      <div className={styles.contract__leftContainer}>
        <ContractForm />
        <SystemsWithSLA />
        <EnvironmentTableContainer />
        <WorkGroupsTableContainer />
      </div>
      <div className={styles.contract__rightContainer}>
        <ResponsibleForContract
          usersList={contract?.userList}
          showCreateBtn={Boolean(contractId && isAccessToCreateContract)}
        />
        <SupplementaryAgreementsAndAttachments />
      </div>
    </div>
  );
};

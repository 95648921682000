import cn from 'clsx';
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LazyLoader } from 'core/components/LazyLoader';
import { ContractsTableContainer } from 'features/Contracts/components/ContractsTableContainer';
import {
  fetchContractsByOrganizationsRequest,
  resetContractsState,
} from 'features/Contracts/ducks/actions';
import { TableContractsTypes } from 'features/Contracts/types';
import {
  fetchCurrentOrganization,
  resetOrganizationState,
  setCurrentOrganizationId,
} from 'features/Organizations/ducks/actions';
import {
  getCurrentOrganization,
  getLoadingOrganization,
} from 'features/Organizations/ducks/selectors';
import { resetSystemsState } from 'features/Systems/ducks/actions';
import { TicketsTableContainer } from 'features/Tickets/components/TicketsTableContainer';
import {
  fetchTicketsCountByContractIdRequest,
  fetchTicketsRequest,
  resetTicketsState,
  setTicketsFilter,
} from 'features/Tickets/ducks/actions';
import { getFilterValues } from 'features/Tickets/ducks/selectors';
import {
  TableTicketsTypes,
  TicketsFilterForRender,
} from 'features/Tickets/types';
import { getDefaultFilterValues } from 'features/Tickets/utils';
import { WorkGroupsTableContainer } from 'features/WorkGroups/components/WorkGroupsTableContainer';
import {
  fetchGroupsByOrganizationId,
  resetWorkGroupsState,
} from 'features/WorkGroups/ducks/actions';
import { TableWorkGroupsTypes } from 'features/WorkGroups/types';
import { checkObjectIdentity } from 'utils';

import { OrganizationForm } from './components/OrganizationForm';
import { useOrganizationId } from './hooks/useOrganizationId';
import styles from './Organization.module.scss';

interface OrganizationContext {
  contractIds: string[];
}

export const OrganizationContext = createContext<OrganizationContext>({
  contractIds: [],
});

interface Props {
  className?: string;
}

export const Organization: React.FC<Props> = ({ className }) => {
  const organizationId = useOrganizationId();

  const organization = useSelector(getCurrentOrganization);
  const filterValues = useSelector(getFilterValues);
  const loading = useSelector(getLoadingOrganization);

  const [contractIds, setContractIds] = useState<string[]>([]);

  const defaultFilterValues = getDefaultFilterValues({ contractIds });

  const isFilterActive = !checkObjectIdentity(
    filterValues as Record<string, TicketsFilterForRender>,
    defaultFilterValues as Record<string, TicketsFilterForRender>
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (organization) {
      const organizationContractIds = organization.contractList.map(
        (contract) => {
          return contract.id;
        }
      );
      setContractIds(organizationContractIds);
    }
  }, [organization]);

  useEffect(() => {
    dispatch(setTicketsFilter(defaultFilterValues));
    if (organizationId && contractIds.length) {
      dispatch(fetchTicketsCountByContractIdRequest(contractIds));
      dispatch(fetchTicketsRequest({}));
    }
  }, [organizationId, contractIds]);

  useEffect(() => {
    if (organizationId) {
      dispatch(setCurrentOrganizationId(organizationId));
      dispatch(fetchCurrentOrganization(organizationId));
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchContractsByOrganizationsRequest());
      dispatch(fetchGroupsByOrganizationId());
    }
    return () => {
      if (organizationId) {
        dispatch(resetContractsState());
        dispatch(resetWorkGroupsState());
        dispatch(resetOrganizationState());
        dispatch(resetTicketsState());
      }
      dispatch(resetSystemsState());
    };
  }, []);

  if (loading && !organization) {
    return <LazyLoader className={styles.organization__loader} />;
  }

  return (
    <OrganizationContext.Provider value={{ contractIds }}>
      <div className={cn(styles.organization, className)}>
        <div className={styles.organization__leftContainer}>
          <OrganizationForm />
          <ContractsTableContainer
            tableType={TableContractsTypes.FROM_ORGANIZATIONS}
            className={styles.organization__table}
            classNameClose={styles.organization__table_close}
          />
          <WorkGroupsTableContainer
            tableType={TableWorkGroupsTypes.FROM_ORGANIZATIONS}
            className={styles.organization__table}
          />
        </div>
        <div className={styles.organization__rightContainer}>
          <TicketsTableContainer
            tableType={TableTicketsTypes.BY_CONTRACT_IDS}
            title="Тикеты"
            withOutTabs
            isFilterActive={isFilterActive}
            className={styles.organization__ticketsTable}
          />
        </div>
      </div>
    </OrganizationContext.Provider>
  );
};

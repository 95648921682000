import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getChangeButtonProps } from 'components/Table';
import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import {
  fetchRespByRoleIdRequest,
  resetResponsibilitiesState,
} from 'features/Responsibilities/ducks/actions';
import { getCurrentUserId, getUserLogin } from 'features/Users/ducks/selectors';
import {
  getCurrentGroupId,
  getGroupTitle,
  getWorkGroup,
} from 'features/WorkGroups/ducks/selectors';
import { RouterHref } from 'routes/routerHref';
import { checkObjectIdentity } from 'utils';

import {
  deleteRoleRequest,
  fetchRoleByIdRequest,
  fetchRoleInGroupByUserId,
  fetchRolesAddInGroupByOrgIdRequest,
  fetchRolesByGroupId,
  fetchRolesByUserIdRequest,
  fetchRolesRequest,
  setCurrentRoleId,
  setCurrentRolesPage,
  setSizePage,
  setSortRoles,
} from '../ducks/actions';
import {
  getCurrentRole,
  getCurrentRoleId,
  getPropsRoles,
  getRoles,
  getRolesFilter,
} from '../ducks/selectors';
import { TableRolesTypes } from '../types';

const getTitleAndIcon = (tableType: TableRolesTypes, totalElements: number) => {
  const { createTitle, icon } = getChangeButtonProps(totalElements || 0);

  const createTableTitle =
    tableType === TableRolesTypes.FULL ? 'Создать' : createTitle;

  const createIcon = tableType === TableRolesTypes.FULL ? undefined : icon;
  return {
    createTitle: createTableTitle,
    icon: createIcon,
  };
};

const getDisableCreateBtn = (
  tableType: TableRolesTypes,
  pathname: string,
  userId?: string
) => {
  return (
    ((tableType === TableRolesTypes.FROM_USERS ||
      tableType === TableRolesTypes.FROM_GROUPS) &&
      !userId &&
      (pathname === RouterHref.AdminUsers ||
        pathname.includes(RouterHref.AdminGroups))) ||
    (tableType === TableRolesTypes.FROM_USERS &&
      pathname === RouterHref.AdminCreateUser) ||
    pathname === RouterHref.AdminCreateGroup
  );
};

export const getFetchFunction = (
  tableType: TableRolesTypes,
  groupId?: string,
  userId?: string
) => {
  const functionsByTableType = {
    [TableRolesTypes.FULL]: fetchRolesRequest,
    [TableRolesTypes.FROM_USERS]: fetchRolesByUserIdRequest,
    [TableRolesTypes.ADD_MODAL]: fetchRolesAddInGroupByOrgIdRequest,
    [TableRolesTypes.FROM_GROUPS]: (() => {
      if (groupId && userId) {
        return fetchRoleInGroupByUserId;
      }
      return fetchRolesByGroupId;
    })(),
  };
  return functionsByTableType[tableType];
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useRolesTableContainer = (tableType: TableRolesTypes) => {
  const { pageNum, pageSize, sortRoles, totalElements, loadingRoles } =
    useSelector(getPropsRoles);
  const { pathname } = useLocation();
  const roles = useSelector(getRoles);
  const role = useSelector(getCurrentRole);
  const currentRoleId = useSelector(getCurrentRoleId);
  const currentGroupTitle =
    pathname === RouterHref.AdminGroups
      ? useSelector(getGroupTitle)
      : useSelector(getWorkGroup)?.title;
  const groupId = useSelector(getCurrentGroupId);
  const userLogin = useSelector(getUserLogin);
  const userId = useSelector(getCurrentUserId);

  const isAccessToCreateRole = useSelector(
    getIsAccessedAction(ActionList.CreateRole)
  );
  const isAccessToDeleteRole = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );
  const filterValues = useSelector(getRolesFilter);

  const [showFilterRow, setShowFilterRow] = useState(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [changeModals, setOpenChangeModals] = useState({
    isEditMode: false,
    isOpenModal: false,
  });

  const toggleIsOpenChangeModals = () => {
    setOpenChangeModals((prev) => ({
      ...prev,
      isOpenModal: !prev.isOpenModal,
    }));
  };

  const setIsEditModeModal = () => {
    setOpenChangeModals((prev) => ({ ...prev, isEditMode: true }));
  };

  const setIsCreateModeModal = () => {
    setOpenChangeModals((prev) => ({ ...prev, isEditMode: false }));
  };

  const handleEditClick = () => {
    setIsEditModeModal();
    toggleIsOpenChangeModals();
  };

  const handleCreateClick = () => {
    setIsCreateModeModal();
    toggleIsOpenChangeModals();
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const getAdditionalComponentTitle =
    currentGroupTitle && userLogin
      ? userLogin?.substring(0, 7)
      : currentGroupTitle?.substring(0, 7);

  const disableCreateBtn = getDisableCreateBtn(tableType, pathname, userId);

  const isFilterActive = useMemo(() => {
    if (
      tableType === TableRolesTypes.FROM_GROUPS ||
      tableType === TableRolesTypes.FROM_USERS
    ) {
      delete filterValues.type;
    }
    return !checkObjectIdentity(filterValues, {});
  }, [tableType, filterValues]);

  const searchDisabled = !roles?.length || (showFilterRow && isFilterActive);

  const getActiveId = () => {
    const keys = {
      [TableRolesTypes.FROM_GROUPS]: `${groupId}${userId}`,
      [TableRolesTypes.FROM_USERS]: userId,
      [TableRolesTypes.FULL]: '',
      [TableRolesTypes.ADD_MODAL]: '',
    };
    return keys[tableType];
  };

  useEffect(() => {
    if (showFilterRow) {
      setShowFilterRow(false);
    }
  }, [getActiveId()]);

  const toggleModal = () => {
    setIsModal(!isModal);
    setShowFilterRow(false);
  };

  const dispatch = useDispatch();

  const handleSort = (value: string) => {
    dispatch(setSortRoles(value));
    dispatch(getFetchFunction(tableType, groupId, userId)());
  };

  const handleRowFullTableClick = (value: string) => {
    if (currentRoleId !== value) {
      dispatch(resetResponsibilitiesState());
      dispatch(setCurrentRoleId(value));
      dispatch(fetchRoleByIdRequest(value));
      dispatch(fetchRespByRoleIdRequest());
    }
  };

  const getCreateFunction = () => {
    const obj = {
      [TableRolesTypes.FULL]: handleCreateClick,
      [TableRolesTypes.FROM_USERS]: toggleModal,
      [TableRolesTypes.FROM_GROUPS]: toggleModal,
      [TableRolesTypes.ADD_MODAL]: undefined,
    };
    return obj[tableType];
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentRolesPage(page));
    dispatch(getFetchFunction(tableType, groupId, userId)());
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePage(arg));
  };

  const { createTitle, icon } = getTitleAndIcon(tableType, totalElements || 0);

  const handleDelete = () => {
    if (role?.id) {
      dispatch(deleteRoleRequest(role?.id));
    }
  };

  const handleShowModalDelete = () => {
    if (role?.id) {
      setShowDeleteModal(true);
    }
  };

  return {
    methods: {
      toggleIsOpenChangeModals,
      handleEditClick,
      toggleDeleteModal,
      toggleFilterRow,
      toggleModal,
      handleSort,
      getCreateFunction,
      handleChangePage,
      handleChangePageSize,
      handleDelete,
      handleShowModalDelete,
      onRowClick: handleRowFullTableClick,
    },
    state: {
      pageNum,
      pageSize,
      sortRoles,
      totalElements,
      roles,
      role,
      isAccessToCreateRole,
      isAccessToDeleteRole,
      showFilterRow,
      isModal,
      showDeleteModal,
      changeModals,
      disableCreateBtn,
      isFilterActive,
      searchDisabled,
      createTitle,
      icon,
      getAdditionalComponentTitle,
      currentGroupTitle,
      groupId,
      currentRoleId,
      loadingRoles,
    },
  };
};

import React from 'react';

import { AdminsLayout } from 'core/layouts/AdminsLayout';
import { Contracts } from 'features/Contracts';

const AdminContracts = () => {
  return (
    <AdminsLayout>
      <Contracts />
    </AdminsLayout>
  );
};

export default AdminContracts;

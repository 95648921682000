import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import { SLADuration } from 'features/SLA/types';

export const getFormatDate = (date: string) => {
  return format(new Date(date), 'd MMM HH:mm', {
    locale: ru,
  });
};

export const getFormatDateWithYear = (date: string | Date) => {
  return format(new Date(date), 'd MMM yyyy HH:mm', {
    locale: ru,
  });
};

export const getFormatDateWithoutTime = (date: string) => {
  return format(new Date(date), 'd MMM yyyy', {
    locale: ru,
  });
};

export const getFormatFullDate = (date: string) => {
  return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss", {
    locale: ru,
  });
};

export const getAttachmentDate = (date: string) => {
  return format(new Date(date), 'dd.MM.yy', {
    locale: ru,
  });
};

export const getMsToTime = (ms: number | null): SLADuration => {
  let time = ms || 0;
  const milliseconds = time % 1000;
  time = (time - milliseconds) / 1000;
  const seconds = time % 60;
  time = (time - seconds) / 60;
  const minutes = time % 60;
  const hours = (time - minutes) / 60;

  return { hours, minutes, seconds };
};

export const getDurationTime = (sec: number) => {
  const { hours, minutes, seconds } = getMsToTime(sec * 1000);
  return { hours, minutes, seconds };
};

export const getFormatDisplayedTime = (time?: number) => {
  if (time) {
    if (time.toString().length === 1) {
      return `0${time}`;
    }
    return time;
  }
  return `00`;
};

export const getFormatWorkingTimeForPattern = (time: string) => {
  const result = time?.split('');
  result.length = 5;
  if (Number(result[0]) === 0) {
    return result.splice(1).join('');
  }
  return result.join('');
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getManagerWorkGroupsIds,
  getUserId,
  getWorkGroupsIds,
} from 'features/Auth/ducks/selectors';
import { resetSystemsState } from 'features/Systems/ducks/actions';
import { TicketsExport } from 'features/TicketsExport';
import { closeExportPanel } from 'features/TicketsExport/ducks/actions';
import { getIsExportPanelOpen } from 'features/TicketsExport/ducks/selectors';
import { TicketsSettingFields } from 'features/TicketsSettingFields/TicketsSettingFields';
import { fetchWorkGroupsFromMyOrgRequest } from 'features/WorkGroups/ducks/actions';
import { RouterHref } from 'routes/routerHref';
import { LocationState } from 'routes/types';
import { checkObjectIdentity } from 'utils';

import { CurrentTicket } from './components/CurrentTicket';
import { TicketsTableContainer } from './components/TicketsTableContainer';
import {
  fetchFiltersDataRequest,
  fetchTicketsRequest,
  resetFiltersState,
  resetTicketsState,
  setTicketsFilter,
} from './ducks/actions';
import {
  getCurrentTicket,
  getFilterValues,
  getSettingFields,
} from './ducks/selectors';
import styles from './Tickets.module.scss';
import { TableTicketsTypes, TicketsFilterForRender } from './types';
import { getCurrentTableType, getDefaultFilterValues } from './utils';

export const Tickets = () => {
  const { pathname, state } = useLocation<LocationState>();

  const userId = useSelector(getUserId);
  const userWorkGroupsIds = useSelector(getWorkGroupsIds);
  const userManagerWorkGroupsIds = useSelector(getManagerWorkGroupsIds);
  const filterValues = useSelector(getFilterValues);
  const ticket = useSelector(getCurrentTicket);
  const isExportPanelOpen = useSelector(getIsExportPanelOpen);
  const settingFields = useSelector(getSettingFields);

  const isMyTicketsPage = pathname === RouterHref.Tickets;
  const isAllTicketsPage = pathname === RouterHref.TicketsAll;
  const isGroupsTicketsPage = pathname === RouterHref.TicketsGroup;

  const defaultFilterValues = getDefaultFilterValues({
    userId,
    userWorkGroupsIds,
    isMyTicketsPage,
    isAllTicketsPage,
    userManagerWorkGroupsIds,
    isGroupsTicketsPage,
  });

  const isFilterActive = !checkObjectIdentity(
    filterValues as Record<string, TicketsFilterForRender>,
    defaultFilterValues as Record<string, TicketsFilterForRender>
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setTicketsFilter({
        ...defaultFilterValues,
        tagName: state?.tagName,
      })
    );
    dispatch(
      fetchTicketsRequest({
        withFirstTicketFetch: true,
        ticketTab: getCurrentTableType(pathname),
      })
    );
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchFiltersDataRequest(getCurrentTableType(pathname)));
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchWorkGroupsFromMyOrgRequest());
    return () => {
      dispatch(resetTicketsState());
      dispatch(resetSystemsState());
      dispatch(resetFiltersState());
    };
  }, []);

  const toggleExportPanel = () => {
    dispatch(closeExportPanel());
  };

  return (
    <div className={styles.tickets}>
      <TicketsTableContainer
        tableType={TableTicketsTypes.FULL}
        isFilterActive={isFilterActive}
        className={styles.tickets__ticketsTableContainer}
      />
      <CurrentTicket
        className={styles.tickets__currentTicket}
        ticket={ticket}
      />
      <TicketsExport
        isOpen={isExportPanelOpen}
        toggleDrawer={toggleExportPanel}
      />
      <TicketsSettingFields
        settingFields={settingFields}
        disabledField="Номер тикета"
      />
    </div>
  );
};

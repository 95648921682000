import { createSelector } from 'reselect';

import { SelectOption } from 'components/Select/types';
import { Contract, Organization, System } from 'core/types';
import { convertOrganizationToSelectOption } from 'core/utils';
import { RootState } from 'store/rootReducer';

export const getLoadingContractDetail = (
  state: RootState
): boolean | undefined => state.contractDetail.contractLoading;
export const getLoadingSupplementaryAgreements = (
  state: RootState
): boolean | undefined => state.contractDetail.supplementaryAgreementsLoading;
export const getContractDetail = (state: RootState): Contract | undefined =>
  state.contractDetail.contract;
export const getSystemSelected = (state: RootState) =>
  state.contractDetail.selectedSystem;
export const getSystemsTransfer = (state: RootState) =>
  state.contractDetail.systemsTransfer || {};
export const getSystemsForTransfer = (state: RootState) =>
  state.contractDetail.systemsTransfer?.systems;
export const getSupportType = (state: RootState) =>
  state.contractDetail.supportType;

export const getTransferSystemIds = createSelector<
  RootState,
  System[] | undefined,
  string[]
>(
  [getSystemsForTransfer],
  (systems) => systems?.map((system) => system.id) || []
);

export const getPropsSupplementaryAgreements = (state: RootState) => ({
  pageNum: state.contractDetail?.supplementaryAgreementsPagination?.pageNum,
  pageSize: state.contractDetail.supplementaryAgreementsPagination?.pageSize,
  loadingSA: getLoadingSupplementaryAgreements(state),
  sortSA: state.contractDetail.supplementaryAgreementsSort,
  totalElements:
    state.contractDetail.supplementaryAgreementsPagination?.totalElements,
});

export const getSystemsForAddingContractDetail = (state: RootState) =>
  state.contractDetail.systems;
export const getSystemsForAddingToContractLoadingContractDetail = (
  state: RootState
) => state.contractDetail.systemsForAddingToContractLoading;

export const getSystemsForAddingSAContractDetail = (state: RootState) =>
  state.contractDetail.systemsForSA;
export const getSystemsForAddingSALoadingContractDetail = (state: RootState) =>
  state.contractDetail.systemsFofSALoading;

export const getSystemsByContractIdSaved = (state: RootState) =>
  state.contractDetail.systemsByContractIdSaved;
export const getSystemsLoadingContractDetail = (state: RootState) =>
  state.contractDetail.systemsByContractIdSavedLoading;
export const getPropsSystemsContractDetail = (state: RootState) => ({
  pageNum: state.contractDetail.systemsByContractIdSavedPagination?.pageNum,
  sort: state.contractDetail.systemsByContractIdSavedSort,
  pageSize: state.contractDetail.systemsByContractIdSavedPagination?.pageSize,
  totalElements:
    state.contractDetail.systemsByContractIdSavedPagination?.totalElements,
  loading: getSystemsLoadingContractDetail(state),
});
export const getSystemsFilterContractDetail = (state: RootState) =>
  state.contractDetail.systemsByContractIdSavedFilter || {};
export const getActiveSystemIdContractDetail = (state: RootState) =>
  state.contractDetail.systemIdActive;

export const getSupplementaryAgreementsContractDetail = (state: RootState) =>
  state.contractDetail.supplementaryAgreements;
export const getSystemIdContractDetail = (state: RootState) =>
  state.contractDetail.systemId;

export const getOrganizationsContractDetail = (
  state: RootState
): Organization[] | undefined => state.contractDetail.organizations;

export const getLoadingOrganizationsContractDetail = (
  state: RootState
): boolean | undefined => state.contractDetail.organizationsLoading;

export const getSelectedOrganizationContractDetail = (state: RootState) =>
  state.contractDetail?.organizationSelected;
export const getFilterValuesOrganizationsContractDetail = (state: RootState) =>
  state.contractDetail.organizationsFilter;

export const getOrganizationsSelectListContractDetail = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizationsContractDetail], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization: Organization): SelectOption => ({
        title: organization.title,
        value: organization.id || '',
      })
    );
  }
  return [];
});

export const getPropsOrganizationsContractDetail = (state: RootState) => ({
  pageNum: state.contractDetail.organizationsPagination?.pageNum,
  pageSize: state.contractDetail.organizationsPagination?.pageSize,
  loadingOrganization: getLoadingOrganizationsContractDetail(state),
  sortOrganizations: state.contractDetail?.organizationsSort,
  totalElements: state.contractDetail.organizationsPagination?.totalElements,
});

export const getPartnerOrganizationsContractDetail = (state: RootState) =>
  state.contractDetail.partnerOrganizations;
export const getLoadingPartnerOrganizationsContractDetail = (
  state: RootState
) => state.contractDetail.partnerOrganizationsLoading;

export const getPartnerOrganizationsOptionsContractDetail = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getPartnerOrganizationsContractDetail], (organizations) =>
  convertOrganizationToSelectOption(organizations)
);

export const getUsersContractDetail = (state: RootState) =>
  state.contractDetail.users;
export const getUsersLoadingContractDetail = (state: RootState) =>
  state.contractDetail.usersLoading;
export const getPropsUsersContractDetail = (state: RootState) => ({
  pageNum: state.contractDetail.usersPagination?.pageNum,
  pageSize: state.contractDetail.usersPagination?.pageSize,
  totalElements: state.contractDetail.usersPagination?.totalElements,
  loadingUsers: getUsersLoadingContractDetail(state),
});
export const getUsersFilterContractDetail = (state: RootState) =>
  state.contractDetail.usersFilter || {};

export const getAttachmentsContractDetail = (state: RootState) =>
  state.contractDetail.attachments;
export const getLoadingAttachmentsContractDetail = (state: RootState) =>
  state.contractDetail.attachmentsLoading;

export const getGroupsByContractBySystemContractDetail = (state: RootState) =>
  state.contractDetail.groups;
export const getEnvironmentsBySystem = (state: RootState) =>
  state.contractDetail.environments;
export const getEnvironmentsFilterBySystem = (state: RootState) =>
  state.contractDetail.environmentsFilter || {};
export const getPropsEnvironmentsBySystem = (state: RootState) => ({
  pageNum: state.contractDetail.environmentsPagination?.pageNum,
  pageSize: state.contractDetail.environmentsPagination?.pageSize,
  totalElements: state.contractDetail.environmentsPagination?.totalElements,
  sort: state.contractDetail.environmentsSort,
  loading: state.contractDetail.environmentsLoading,
});
export const getEnvironmentsSettingsBySystem = (state: RootState) =>
  state.contractDetail.environmentSettings;
export const getGroupsLoadingByContractBySystemContractDetail = (
  state: RootState
) => state.contractDetail.groupsLoading;
export const getGroupsFilterByContractBySystemContractDetail = (
  state: RootState
) => state.contractDetail.groupsFilter || {};
export const getPropsGroupsContractDetail = (state: RootState) => ({
  pageNum: state.contractDetail.groupsPagination?.pageNum,
  pageSize: state.contractDetail.groupsPagination?.pageSize,
  totalElements: state.contractDetail.groupsPagination?.totalElements,
  sort: state.contractDetail.groupsSort,
  loading: getGroupsLoadingByContractBySystemContractDetail(state),
});

export const getGroupsByContractContractDetail = (state: RootState) =>
  state.contractDetail.groupsByContract;
export const getGroupsLoadingByContractContractDetail = (state: RootState) =>
  state.contractDetail.groupsByContractLoading;
export const getGroupsFilterByContractContractDetail = (state: RootState) =>
  state.contractDetail.groupsByContractFilter;
export const getPropsGroupsByContractContractDetail = (state: RootState) => ({
  pageNum: state.contractDetail.groupsByContractPagination?.pageNum,
  pageSize: state.contractDetail.groupsByContractPagination?.pageSize,
  totalElements: state.contractDetail.groupsByContractPagination?.totalElements,
  sort: state.contractDetail.groupsByContractSort,
  loading: getGroupsLoadingByContractContractDetail(state),
});

export const getSLAPatterns = (state: RootState) =>
  state.contractDetail.slaPatterns;
export const getSLAPatternsLoading = (state: RootState) =>
  state.contractDetail.slaPatternsLoading;
export const getIsPatternTitleExist = (state: RootState) =>
  state.contractDetail.isPatternTitleExist;
export const getPatternActive = (state: RootState) =>
  state.contractDetail.slaPatternActive;

export const getSLAByContractSystemContractDetail = (state: RootState) =>
  state.contractDetail.slaByContractBySystem;
export const getSLAByContractSystemLoadingContractDetail = (state: RootState) =>
  state.contractDetail.slaByContractBySystemLoading;

export const getTicketTypesSettings = (state: RootState) =>
  state.contractDetail.ticketTypesSettings || [];
export const getTicketTypesSettingsLoading = (state: RootState) =>
  state.contractDetail.ticketTypesSettingsLoading;
export const getTicketTypes = (state: RootState) =>
  state.contractDetail.ticketTypes || [];
export const getSelectedSystemSla = (state: RootState) =>
  state.contractDetail.selectedSystemSla;

export const getContractPermission = (state: RootState) =>
  state.contractDetail.contractPermission;

import React from 'react';

import { Organization as OrganizationContent } from 'features/Organization';

import { OrganizationLayout } from '../../layouts/OrganizationLayout';

import styles from './Organization.module.scss';

const Organization = () => {
  return (
    <OrganizationLayout>
      <OrganizationContent className={styles.organization__content} />
    </OrganizationLayout>
  );
};

export default Organization;

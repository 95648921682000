import React, { useEffect, useState } from 'react';

import { ExclamationIcon, QuestionIcon, StarIcon } from 'assets/icons';
import { Tooltip } from 'components/Tooltip';
import { Typography, TypographyVariants } from 'components/Typography';
import { getRatingPercents } from 'features/Desktop/utils';

import { KpiData } from '../../types';

import styles from './KpiRating.module.scss';

interface Props {
  kpiData?: KpiData;
}

export const KpiRating: React.FC<Props> = ({ kpiData }) => {
  const ratingData = kpiData?.info.closedInfo.rating;
  const ratingProblems = kpiData?.info.closedInfo.problems;

  const currentRating = getRatingPercents(Number(ratingData));

  const radius = 65;
  const circumference = 2 * Math.PI * radius;

  const [offset, setOffset] = useState(circumference);

  const setProgress = (percent: number) => {
    const currentOffset = circumference - (percent / 100) * circumference;
    setOffset(currentOffset);
  };

  const rating = Math.ceil((ratingData || 0) * 100) / 100;

  useEffect(() => {
    setProgress(currentRating);
  }, [currentRating]);

  const problems = ratingProblems?.map((el) => {
    return (
      <div key={el} className={styles.tooltipProblem}>
        <ExclamationIcon className={styles.tooltipProblemIcon} />
        {el}
      </div>
    );
  });

  const tooltip = !!ratingProblems?.length && (
    <>
      <QuestionIcon data-tip data-for="rating" className={styles.ratingIcon} />
      <Tooltip id="rating">
        <div className={styles.tooltip}>
          <Typography
            variant={TypographyVariants.h3}
            className={styles.tooltipTitle}
          >
            Что улучшить?
          </Typography>
          <div>{problems}</div>
        </div>
      </Tooltip>
    </>
  );

  return (
    <div className={styles.rating}>
      <Typography
        component="div"
        variant={TypographyVariants.b1}
        className={styles.ratingTitle}
      >
        Качество работы
        {tooltip}
      </Typography>
      <div>
        <div className={styles.ratingContainer}>
          <div>
            <StarIcon className={styles.ratingStar} />
            <StarIcon className={styles.ratingStarCenter} />
            <StarIcon className={styles.ratingStar} />
          </div>
          <div>
            <Typography variant={TypographyVariants.d2}>{rating}</Typography>
          </div>
          <svg className={styles.circularProgressBar}>
            <circle
              className={styles.circularProgressBarCircle}
              cx="60"
              cy="60"
              r={radius}
              style={{
                strokeDashoffset: `${offset}`,
                strokeDasharray: `${circumference} ${circumference}`,
              }}
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

import { useSelector } from 'react-redux';

import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';

export const useCanCreateArticleOrCategory = () => {
  const isAccessCreateArticleInClientOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateKBArticleInClientOrganization)
  );
  const isAccessCreateArticleInMyOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateKBArticleInMyOrganization)
  );

  return (
    isAccessCreateArticleInClientOrganization ||
    isAccessCreateArticleInMyOrganization
  );
};

import { Organization } from 'core/types';
import {
  Article,
  CategoryArticle,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from 'features/KnowledgeBase/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const request = {
  fetchArticlesMainRequest: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter: KBArticleTableFilterToRequest = {}
  ) =>
    ApiClient.post<Article[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.fetchArticlesMain(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchOrganizationsArticlesMainRequest: (filter: KBOrganizationsFilter = {}) =>
    ApiClient.post<Organization[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.fetchOrganizationsArticlesMain(),
      data: { ...filter },
    }),
  fetchCategoriesRequest: (organizationId?: string) =>
    ApiClient.get<CategoryArticle[]>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.fetchCategories(organizationId),
    }),
};

import React from 'react';

import { PreviewAttachmentType } from 'features/Ticket/types';

import { PreviewAttachment } from '../PreviewAttachment';
import { getFileField } from '../TicketInfo/utils/getFileField';

export const ContentPreviewAttachment: React.FC<PreviewAttachmentType> = ({
  files,
  attachment,
  handleAttachment,
  handleDeleteFile,
  handleDownloadFile,
  size,
  isButtonDelete,
}) => {
  return (
    <>
      {files?.map((file, index) => {
        const attachmentField = getFileField(file, attachment);
        return (
          <PreviewAttachment
            key={attachmentField.attachmentId}
            file={file}
            index={index}
            handleAttachment={handleAttachment}
            handleDeleteFile={handleDeleteFile}
            handleDownloadFile={handleDownloadFile}
            attachmentField={attachmentField}
            size={size}
            isButtonDelete={isButtonDelete}
          />
        );
      })}
    </>
  );
};

import { WorkGroup } from 'core/types';
import { DeleteDataType } from 'features/Organizations/ducks/types';
import { CreateWorkGroupData } from 'features/WorkGroup/types';
import { ResponseWithMeta } from 'store/types';

import { WorkGroupFilter } from '../types';

export enum WorkGroups {
  FETCH_WORK_GROUPS_SUCCESS = 'WORK_GROUPS/FETCH_WORK_GROUPS_SUCCESS',
  FETCH_WORK_GROUPS_REQUEST = 'WORK_GROUPS/FETCH_WORK_GROUPS_REQUEST',
  WORK_GROUPS_LOADING_SHOW = 'WORK_GROUPS/WORK_GROUPS_LOADING_SHOW',
  WORK_GROUPS_LOADING_HIDE = 'WORK_GROUPS/WORK_GROUPS_LOADING_HIDE',
  WORK_GROUP_LOADING_SHOW = 'WORK_GROUPS/WORK_GROUP_LOADING_SHOW',
  WORK_GROUP_LOADING_HIDE = 'WORK_GROUPS/WORK_GROUP_LOADING_HIDE',
  WORK_GROUPS_ADD_LOADING_SHOW = 'WORK_GROUPS/WORK_GROUPS_ADD_LOADING_SHOW',
  WORK_GROUPS_ADD_LOADING_HIDE = 'WORK_GROUPS/WORK_GROUPS_ADD_LOADING_HIDE',
  RESET_WORK_GROUPS_STATE = 'WORK_GROUPS/RESET_WORK_GROUPS_STATE',
  FETCH_WORK_GROUPS_BY_ORGANIZATION_ID_REQUEST = 'WORK_GROUPS/FETCH_WORK_GROUPS_BY_ORGANIZATION_ID_REQUEST',
  FETCH_WORK_GROUPS_BY_USERID_REQUEST = 'WORK_GROUPS/FETCH_WORK_GROUPS_BY_USERID_REQUEST',
  FETCH_WORK_GROUP_REQUEST = 'WORK_GROUPS/FETCH_WORK_GROUP_REQUEST',
  FETCH_WORK_GROUP_SUCCESS = 'WORK_GROUPS/FETCH_WORK_GROUP_SUCCESS',
  CREATE_WORK_GROUP = 'WORK_GROUPS/CREATE_WORK_GROUP',
  EDIT_WORK_GROUP = 'WORK_GROUPS/EDIT_WORK_GROUP',
  SET_WORK_GROUP_ID = 'WORK_GROUPS/SET_WORK_GROUP_ID',
  SET_CURRENT_GROUPS_PAGE = 'WORK_GROUPS/SET_CURRENT_GROUPS_PAGE',
  SET_SORT_GROUPS = 'WORK_GROUPS/SET_SORT_GROUPS',
  SET_SIZE_PAGE_WORK_GROUPS = 'WORK_GROUPS/SET_SIZE_PAGE_WORK_GROUPS',
  SET_GROUPS_ADD_PAGE = 'WORK_GROUPS/SET_GROUPS_ADD_PAGE',
  SET_SIZE_PAGE_GROUPS_ADD = 'WORK_GROUPS/SET_SIZE_PAGE_GROUPS_ADD',
  SET_WORK_GROUP_TITLE = 'WORK_GROUPS/SET_WORK_GROUP_TITLE',
  UPDATE_WORK_GROUP = 'WORK_GROUPS/UPDATE_WORK_GROUP',
  SET_WORK_GROUP_FILTER = 'WORK_GROUPS/SET_WORK_GROUP_FILTER',
  FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_REQUEST = 'WORK_GROUPS/FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_REQUEST',
  FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_SUCCESS = 'WORK_GROUPS/FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_SUCCESS',
  SET_WORK_GROUP_ADD_FILTER = 'WORK_GROUPS/SET_WORK_GROUP_ADD_FILTER',
  FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST = 'WORK_GROUPS/FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST',
  RESET_WORK_GROUPS_ADD_STATE = 'WORK_GROUPS/RESET_WORK_GROUPS_ADD_STATE',
  FETCH_WORK_GROUPS_FROM_MY_ORG_REQUEST = 'WORK_GROUPS/FETCH_WORK_GROUPS_FROM_MY_ORG_REQUEST',
  FETCH_WORK_GROUPS_FROM_MY_ORG_SUCCESS = 'WORK_GROUPS/FETCH_WORK_GROUPS_FROM_MY_ORG_SUCCESS',
  DELETE_WORK_GROUP_REQUEST = 'WORK_GROUPS/DELETE_WORK_GROUP_REQUEST',
  RESET_CURRENT_WORK_GROUP = 'WORK_GROUPS/RESET_CURRENT_WORK_GROUP',
}

export interface ShowWorkGroupsLoadingAction {
  type: WorkGroups.WORK_GROUPS_LOADING_SHOW;
}

export interface HideWorkGroupsLoadingAction {
  type: WorkGroups.WORK_GROUPS_LOADING_HIDE;
}

export interface ShowWorkGroupLoadingAction {
  type: WorkGroups.WORK_GROUP_LOADING_SHOW;
}

export interface HideWorkGroupLoadingAction {
  type: WorkGroups.WORK_GROUP_LOADING_HIDE;
}

export interface ShowWorkGroupsAddLoadingAction {
  type: WorkGroups.WORK_GROUPS_ADD_LOADING_SHOW;
}

export interface HideWorkGroupsAddLoadingAction {
  type: WorkGroups.WORK_GROUPS_ADD_LOADING_HIDE;
}

export interface FetchWorkGroupsRequestAction {
  type: WorkGroups.FETCH_WORK_GROUPS_REQUEST;
}

export interface FetchWorkGroupsSuccessAction {
  type: WorkGroups.FETCH_WORK_GROUPS_SUCCESS;
  payload: ResponseWithMeta<WorkGroup[]>;
}

export interface SetCurrentGroupsPageAction {
  type: WorkGroups.SET_CURRENT_GROUPS_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: WorkGroups.SET_SIZE_PAGE_WORK_GROUPS;
  payload: number;
}

export interface SetGroupsAddPageAction {
  type: WorkGroups.SET_GROUPS_ADD_PAGE;
  payload: number;
}

export interface SetSizePageGroupsAddAction {
  type: WorkGroups.SET_SIZE_PAGE_GROUPS_ADD;
  payload: number;
}

export interface SetSortGroupsAction {
  type: WorkGroups.SET_SORT_GROUPS;
  payload: string;
}

export interface ResetWorkGroupsStateAction {
  type: WorkGroups.RESET_WORK_GROUPS_STATE;
}

export interface FetchGroupsByOrganizationRequestAction {
  type: WorkGroups.FETCH_WORK_GROUPS_BY_ORGANIZATION_ID_REQUEST;
}

export interface FetchWorkGroupRequestAction {
  type: WorkGroups.FETCH_WORK_GROUP_REQUEST;
  payload: string;
}

export interface FetchCurrentWorkGroupSuccessAction {
  type: WorkGroups.FETCH_WORK_GROUP_SUCCESS;
  payload?: WorkGroup;
}

export interface CreateWorkGroupAction {
  type: WorkGroups.CREATE_WORK_GROUP;
  payload: CreateWorkGroupData;
}

export interface EditWorkGroupAction {
  type: WorkGroups.EDIT_WORK_GROUP;
  payload: CreateWorkGroupData;
}

export interface FetchGroupsByUserIdRequestAction {
  type: WorkGroups.FETCH_WORK_GROUPS_BY_USERID_REQUEST;
}

export interface SetWorkGroupIdAction {
  type: WorkGroups.SET_WORK_GROUP_ID;
  payload: string;
}

export interface SetGroupTitleAction {
  type: WorkGroups.SET_WORK_GROUP_TITLE;
  payload?: string;
}

export interface UpdateWorkGroupAction {
  type: WorkGroups.UPDATE_WORK_GROUP;
  payload: WorkGroup;
}

export interface SetWorkGroupFilterAction {
  type: WorkGroups.SET_WORK_GROUP_FILTER;
  payload: WorkGroupFilter;
}

export interface SetWorkGroupAddFilterAction {
  type: WorkGroups.SET_WORK_GROUP_ADD_FILTER;
  payload: WorkGroupFilter;
}

export interface FetchWorkGroupsAddSuccessAction {
  type: WorkGroups.FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_SUCCESS;
  payload: ResponseWithMeta<WorkGroup[]>;
}

export interface ResetWorkGroupsAddStateAction {
  type: WorkGroups.RESET_WORK_GROUPS_ADD_STATE;
}

export interface FetchWorkGroupsFromMyOrgRequestAction {
  type: WorkGroups.FETCH_WORK_GROUPS_FROM_MY_ORG_REQUEST;
}

export interface FetchWorkGroupsFromMyOrgSuccessAction {
  type: WorkGroups.FETCH_WORK_GROUPS_FROM_MY_ORG_SUCCESS;
  payload: WorkGroup[];
}

export interface DeleteWorkGroupRequestAction {
  type: WorkGroups.DELETE_WORK_GROUP_REQUEST;
  payload: DeleteDataType;
}

export interface ResetCurrentWorkGroupAction {
  type: WorkGroups.RESET_CURRENT_WORK_GROUP;
}

export type WorkGroupsActionsTypes =
  | FetchWorkGroupsSuccessAction
  | ResetWorkGroupsStateAction
  | FetchGroupsByOrganizationRequestAction
  | FetchCurrentWorkGroupSuccessAction
  | SetWorkGroupIdAction
  | SetCurrentGroupsPageAction
  | SetSizePageAction
  | SetSortGroupsAction
  | SetGroupTitleAction
  | SetWorkGroupFilterAction
  | SetWorkGroupAddFilterAction
  | FetchWorkGroupsAddSuccessAction
  | ResetWorkGroupsAddStateAction
  | FetchWorkGroupsFromMyOrgSuccessAction
  | SetGroupsAddPageAction
  | SetSizePageGroupsAddAction
  | ResetCurrentWorkGroupAction;

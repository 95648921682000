import cn from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';

import { Clock12, ClockFat } from 'assets/icons';
import { ConditionBlock } from 'components/ConditionBlock';
import { InfoBlock } from 'components/InfoBlock';
import { Typography, TypographyVariants } from 'components/Typography';
import { Ticket, TicketSLAType } from 'features/Ticket/types';
import { getDurationTime, getFormatDisplayedTime } from 'utils';

import { SLAProgressBar } from './components/SLAProgressBar';
import { SLATimer } from './components/SLATimer';
import { SLA_TYPE_TITLES_MAP } from './constants';
import styles from './SLA.module.scss';
import { SLASize } from './types';
import { getTimeForSetTimeout } from './utils';

const slaTypeStylesMap = {
  [TicketSLAType.DECISION]: styles.sla__condition_decision,
  [TicketSLAType.REACTION]: styles.sla__condition_reaction,
};

interface Props {
  ticket: Ticket;
  updateTicket(): void;
  size?: SLASize;
  isTicketPage?: boolean;
  className?: string;
}

export const SLA: React.FC<Props> = ({
  ticket,
  updateTicket,
  size = 'default',
  isTicketPage,
  className,
}) => {
  const ticketId = ticket?.id;

  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [timerActive, setTimerActive] = useState(false);
  const [preparedTimeLeft, setPreparedTimeLeft] = useState<number | null>(null);

  const isSmall = size === 'small';

  const sla = ticket?.sla;
  const onPause = ticket?.sla?.onPause;
  const slaType = ticket?.sla?.slaType;
  const isRoundTheClock = ticket?.sla?.roundTheClock;
  const reactionExpireSeconds = sla?.reactionExpireSeconds;
  const maxDecisionSeconds = sla?.maxDecisionSeconds;
  const maxReactionSeconds = sla?.maxReactionSeconds;
  const nowIsWorkTime = sla?.nowIsWorkTime;
  const isExpired = sla?.expired;
  const secondsAfterTimerRefresh = sla?.secondsAfterTimerRefresh;

  const isDecision = slaType === TicketSLAType.DECISION;

  const secondsOnTimer = useMemo(
    () => (sla?.secondsOnTimer || 0) * 1000,
    [sla?.secondsOnTimer]
  );

  const timeAll = useMemo(
    () => ((isDecision ? maxDecisionSeconds : maxReactionSeconds) || 0) * 1000,
    [isDecision, maxDecisionSeconds, maxReactionSeconds]
  );

  const expireTimePercents = useMemo(
    () => ((timeAll - timeLeft) / timeAll) * 100,
    [timeAll, timeLeft]
  );

  const expireTime = getDurationTime(Number(reactionExpireSeconds) || 0);
  const currentExpireTime = `-${getFormatDisplayedTime(
    expireTime.hours || 0
  )}:${getFormatDisplayedTime(
    expireTime.minutes || 0
  )}:${getFormatDisplayedTime(expireTime.seconds || 0)}`;

  useEffect(() => {
    if (secondsOnTimer) {
      setTimeLeft(secondsOnTimer);
    }
    if (!onPause && nowIsWorkTime) {
      setTimerActive(true);
      return;
    }
    setTimerActive(false);
  }, [onPause, nowIsWorkTime, secondsOnTimer]);

  useEffect(() => {
    let timeoutId: number;
    if (timerActive) {
      timeoutId = setTimeout(
        setTimeLeft,
        1000,
        isExpired ? timeLeft + 1000 : timeLeft - 1000
      );
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeLeft, timerActive]);

  useEffect(() => {
    if (timeLeft) {
      setPreparedTimeLeft(timeLeft);
    }
  }, [timeLeft]);

  useEffect(() => {
    return () => {
      setTimeLeft(0);
      setTimerActive(false);
      setPreparedTimeLeft(null);
    };
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (secondsAfterTimerRefresh && ticketId) {
      timeoutId = setTimeout(() => {
        updateTicket();
      }, getTimeForSetTimeout(secondsAfterTimerRefresh));
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [secondsAfterTimerRefresh, ticketId]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!isExpired && sla?.secondsOnTimer && ticketId) {
      timeoutId = setTimeout(() => {
        updateTicket();
      }, getTimeForSetTimeout(sla?.secondsOnTimer));
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isExpired, ticketId, secondsOnTimer]);

  const getExpireTime = !!reactionExpireSeconds && (
    <Typography
      variant={TypographyVariants.o}
      component="div"
      className={cn(styles.sla__expireTime, {
        [styles.sla__expireTime_small]: isSmall,
        [styles.sla__expireTime_ticketsPage]: isTicketPage,
      })}
    >
      {currentExpireTime}
    </Typography>
  );

  const conditions = sla && (
    <div
      className={cn(styles.sla__conditionWrapper, {
        [styles.sla__conditionWrapper_small]: isSmall,
        [styles.sla__conditionWrapper_ticketsPage]: isTicketPage,
      })}
    >
      <ConditionBlock
        text={SLA_TYPE_TITLES_MAP[slaType || TicketSLAType.REACTION]}
        className={cn(
          styles.sla__condition,
          {
            [styles.sla__condition_small]: isSmall,
          },
          slaTypeStylesMap[slaType || TicketSLAType.REACTION]
        )}
      />
      <div
        className={cn(styles.sla__roundClock, {
          [styles.sla__condition_decision]: !isRoundTheClock,
          [styles.sla__condition_reaction]: isRoundTheClock,
        })}
      >
        <ClockFat />
        <Typography
          variant={TypographyVariants.h5}
          className={styles.sla__clockFormat}
        >
          {isRoundTheClock ? '24/7' : '5/2'}
        </Typography>
      </div>
      {getExpireTime}
    </div>
  );

  const defaultContent = !isSmall && (
    <>
      <InfoBlock
        className={styles.sla__icon}
        mainTitle="SLA"
        icon={
          <Clock12
            className={cn(styles.sla__clock, {
              [styles.sla__clock_expired]: isExpired,
            })}
          />
        }
        classNameIconWrapper={cn(styles.sla__iconInfo, {
          [styles.sla__iconInfo_expired]: isExpired,
        })}
        classNameInfoWrapper={styles.sla__iconInfoWrapper}
        classNameTitle={styles.sla__iconTitle}
      />
      <SLATimer
        size={size}
        timeLeft={preparedTimeLeft}
        timeAll={timeAll}
        isExpired={isExpired}
      />
      {isExpired && (
        <Typography
          variant={TypographyVariants.o}
          className={styles.sla__expiredText}
        >
          Просрочен
        </Typography>
      )}
      {!isExpired && (
        <SLAProgressBar
          expirePercents={expireTimePercents}
          className={styles.sla__progressBar}
        />
      )}
      {conditions}
    </>
  );

  const smallContent = isSmall && (
    <>
      <div className={styles.sla__wrapper}>
        <InfoBlock
          className={cn(styles.sla__icon, styles.sla__icon_small)}
          mainTitle="SLA"
          icon={
            <Clock12
              className={cn(styles.sla__clock, {
                [styles.sla__clock_expired]: isExpired,
              })}
            />
          }
          classNameIconWrapper={cn(
            styles.sla__iconInfo,
            styles.sla__iconInfo_small,
            { [styles.sla__iconInfo_expired]: isExpired }
          )}
          classNameInfoWrapper={cn(
            styles.sla__iconInfoWrapper,
            styles.sla__iconInfoWrapper_small
          )}
          classNameTitle={styles.sla__iconTitle}
        />
        <div className={styles.sla__subWrapper}>
          <div className={styles.sla__subWrapperHeader}>
            <Typography
              variant={TypographyVariants.b3}
              className={styles.sla__subWrapperHeaderTitle}
            >
              SLA
            </Typography>
            {conditions}
          </div>
          <SLATimer
            size={size}
            timeLeft={preparedTimeLeft}
            timeAll={timeAll}
            isExpired={isExpired}
          />
          {isExpired && (
            <Typography
              variant={TypographyVariants.o}
              className={cn(styles.sla__expiredText, {
                [styles.sla__expiredText_small]: isSmall,
              })}
            >
              Просрочен
            </Typography>
          )}
        </div>
      </div>
      {!isExpired && (
        <SLAProgressBar
          expirePercents={expireTimePercents}
          className={cn(styles.sla__progressBar, styles.sla__progressBar_small)}
        />
      )}
    </>
  );

  return (
    <div className={cn(styles.sla, { [styles.sla_small]: isSmall }, className)}>
      {defaultContent}
      {smallContent}
    </div>
  );
};

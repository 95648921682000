import React from 'react';

import { User as UserContent } from 'features/User';

import { UserLayout } from '../../layouts/UserLayout';

import styles from './User.module.scss';

const User = () => {
  return (
    <UserLayout>
      <UserContent className={styles.user__content} />
    </UserLayout>
  );
};

export default User;

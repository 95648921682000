import {
  DeleteFileAttachmentsTicketRequest,
  Note,
  TicketAttachmentFile,
} from 'features/Ticket/types';
import { Attachment, ParentType } from 'types/models/attachment';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const request = {
  addAttachment: (data: FormData) =>
    ApiClient.post<Attachment[]>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.addAttachment(),
      data,
    }),
  fetchTicketAttachments: (ticketId: string) =>
    ApiClient.get<Attachment[]>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.fetchAttachments(),
      params: {
        parent: ParentType.TICKET,
        id: ticketId,
      },
    }),
  fetchTicketCommentsAttachments: (ticketId: string) =>
    ApiClient.get<TicketAttachmentFile[]>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.fetchTicketCommentsAttachments(ticketId),
    }),
  fetchTicketNotesAttachments: (ticketId: string) =>
    ApiClient.get<Note[]>({
      baseURL: getEnv('REACT_APP_NOTES_URL'),
      url: endpoints.fetchTicketNotesAttachments(ticketId),
      data: {},
    }),
  fetchContractAttachments: (contractId: string) =>
    ApiClient.get<Attachment[]>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.fetchAttachments(),
      params: {
        parent: ParentType.CONTRACT,
        id: contractId,
      },
    }),
  deleteFileAttachmentsTicket: ({
    attachmentId,
    attachmentFileId,
  }: DeleteFileAttachmentsTicketRequest) =>
    ApiClient.delete({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.deleteFileAttachmentsTicket(
        attachmentId,
        attachmentFileId
      ),
    }),
};

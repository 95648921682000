import { getValueFromValueType } from 'components/Select';
import { ValueType } from 'components/Select/types';
import { convertKeyValueOptionToArraySelectOption } from 'core/utils';

import { TriggerAttributes } from '../types';

interface Props {
  attributesFields: TriggerAttributes;
  attribute: ValueType<string>;
}

export const getAttributesValueOptions = ({
  attributesFields,
  attribute,
}: Props) => {
  const optionValue = getValueFromValueType(attribute) || '';
  const fields = attributesFields[optionValue as keyof TriggerAttributes];

  return convertKeyValueOptionToArraySelectOption(fields) || [];
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { PlusIcon } from 'assets/icons';
import { Popover } from 'components/Popover';
import { PopoverButton } from 'components/Popover/components/PopoverButton';
import { PopoverTitle } from 'components/Popover/components/PopoverTitle';
import { Radio } from 'components/Radio';
import { getLoading } from 'features/Tickets/ducks/selectors';

import { FilterData, SetCurrentFilterTitleRequest } from '../../types';
import { getFiltersRadioItems } from '../../utils';
import { TicketsPopoverInner } from '../TicketsPopoverInner';
import { TicketsPopovers } from '../TicketsPopovers';

import styles from './TicketsDropContentFilters.module.scss';

interface Props {
  filtersData: FilterData[];
  currentFilterId?: string;
  isUsedFilter?: boolean;
  setCurrentFilters: (id: string) => void;
  handleSubmitFilter: () => void;
  handleSubmitFilterTitle?: (data: SetCurrentFilterTitleRequest) => void;
  handleDeleteFilter: (id: string) => void;
  handleChangeFilterTitle: (title: string) => void;
}

export const TicketsDropContentFilters: React.FC<Props> = (props) => {
  const {
    filtersData,
    currentFilterId,
    isUsedFilter,
    setCurrentFilters,
    handleSubmitFilter,
    handleSubmitFilterTitle,
    handleDeleteFilter,
    handleChangeFilterTitle,
  } = props;

  const loading = useSelector(getLoading);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleTogglePopover = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  return (
    <div className={styles.filters}>
      <PopoverTitle title="Фильтры" />
      <div className={styles.filtersBlocks}>
        <Radio
          items={getFiltersRadioItems(filtersData)}
          value={currentFilterId ?? ''}
          onChange={(event) => {
            setCurrentFilters(event.target.value);
          }}
          disabled={loading}
          className={styles.filtersRadio}
        />
        <TicketsPopovers
          items={getFiltersRadioItems(filtersData)}
          onChangePopover={handleChangeFilterTitle}
          handleDelete={handleDeleteFilter}
          handleSubmitPopover={handleSubmitFilterTitle}
        />
      </div>
      {isUsedFilter && (
        <Popover
          togglePopover={handleTogglePopover}
          isOpen={isPopoverOpen}
          positions={['left']}
          align="start"
          content={
            <TicketsPopoverInner
              formSubmitHandler={handleSubmitFilter}
              onChange={handleChangeFilterTitle}
              resetTitle="Отмена"
              closePopoverWithReset={setIsPopoverOpen}
            />
          }
          popoverClassName={styles.filters__popoverInner}
        >
          <PopoverButton
            onClick={handleTogglePopover}
            icon={<PlusIcon className={styles.filters__popoverInnerIcon} />}
            className={styles.filters__popoverInnerBtn}
          >
            Сохранить фильтр
          </PopoverButton>
        </Popover>
      )}
    </div>
  );
};

import {
  SettingField,
  SettingFields,
} from 'components/Table/components/SettingFields/types';
import { RenderTypes, TableColumns } from 'components/Table/types';

import { TicketsTableFields } from '../types';

const getRenderTypes = (value: string) => {
  if (value === TicketsTableFields.TICKET_NUMBER) {
    return { renderType: RenderTypes.NOTIFICATION_LINK };
  }
  if (value === TicketsTableFields.SYSTEM) {
    return { renderType: RenderTypes.CONDITION };
  }
  return undefined;
};

const getPreparedSortField = (value: string): string => {
  const idsMap: Record<string, string> = {
    specialistId: 'specialist',
    clientId: 'client',
    systemId: 'system',
    tags: '',
  };
  return idsMap[value] ?? value;
};

const preparedColumns = (
  columns: SettingField[],
  isDefaultColumns?: boolean
) => {
  return columns.map(({ name, naturalKey }) => ({
    title: name,
    sortField: getPreparedSortField(naturalKey),
    fieldName: naturalKey,
    ...(isDefaultColumns && getRenderTypes(name)),
  }));
};

export const getColumns = (settingFields?: SettingFields): TableColumns => {
  const defaultColumns = preparedColumns(
    settingFields?.defaultColumns || [],
    true
  );
  const customColumns = preparedColumns(settingFields?.customColumns || []);
  return [...defaultColumns, ...customColumns];
};

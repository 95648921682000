import { FetchRequestArgs } from 'components/SearchSelect/types';
import { Environment, System, SystemsFilter } from 'core/types';
import { ContractDetailPayload } from 'features/Contract/ducks/types';
import { SLAType } from 'features/Contract/types';
import { ResponseWithMeta } from 'store/types';
import { UpdateContentType } from 'types/models/meta';

import { SystemPriority } from '../types';

import {
  DeleteSystemRequestAction,
  FetchCurrentSystemRequestAction,
  FetchFiltersSystemsRequestAction,
  FetchFiltersSystemsSuccessJoinAction,
  FetchFiltersSystemsSuccessUpdateAction,
  FetchMySystemsRequestAction,
  FetchSystemPrioritiesRequestAction,
  FetchSystemPrioritiesSuccessAction,
  FetchSystemsByContractIdRequestAction,
  FetchSystemsOrganizationForJiraRequestAction,
  FetchSystemsOrganizationRequestAction,
  FetchSystemsOrganizationSuccessAction,
  FetchSystemsRequestAction,
  FetchSystemsSuccessJoinAction,
  FetchSystemsSuccessUpdateAction,
  FetchSystemsWithContractsRequestAction,
  HideSystemsLoadingAction,
  ResetCurrentSystemAction,
  ResetSystemIdStateAction,
  ResetSystemPrioritiesAction,
  ResetSystemsStateAction,
  SetCurrentOrganizationSystemIdAction,
  SetCurrentSystemIdAction,
  SetCurrentSystemsPageAction,
  SetCurrentSystemSuccessAction,
  SetIsErrorNoDataAction,
  SetSelectedSystemSuccessAction,
  SetSizePageAction,
  SetSortSystemsAction,
  SetSystemsByContractAction,
  SetSystemsFilterAction,
  ShowSystemsLoadingAction,
  Systems,
  SystemTicketPayload,
} from './types';

export const showSystemsLoading = (): ShowSystemsLoadingAction => {
  return {
    type: Systems.SYSTEMS_LOADING_SHOW,
  };
};

export const hideSystemsLoading = (): HideSystemsLoadingAction => {
  return {
    type: Systems.SYSTEMS_LOADING_HIDE,
  };
};

export const fetchSystemsRequest = (): FetchSystemsRequestAction => {
  return {
    type: Systems.FETCH_SYSTEMS_REQUEST,
  };
};

export const fetchSystemsOrganizationRequest = (
  id: string
): FetchSystemsOrganizationRequestAction => {
  return {
    type: Systems.FETCH_SYSTEMS_ORGANIZATION_REQUEST,
    payload: id,
  };
};

export const fetchSystemsSuccessUpdate = (
  data: ResponseWithMeta<System[]>
): FetchSystemsSuccessUpdateAction => {
  return {
    type: Systems.FETCH_SYSTEMS_SUCCESS_UPDATE,
    payload: data,
  };
};

export const fetchSystemsSuccessJoin = (
  data: ResponseWithMeta<System[]>
): FetchSystemsSuccessJoinAction => {
  return {
    type: Systems.FETCH_SYSTEMS_SUCCESS_JOIN,
    payload: data,
  };
};

export const fetchFiltersSystemsSuccessUpdate = (
  data: ResponseWithMeta<System[]>
): FetchFiltersSystemsSuccessUpdateAction => {
  return {
    type: Systems.FETCH_FILTERS_SYSTEMS_SUCCESS_UPDATE,
    payload: data,
  };
};

export const fetchFiltersSystemsSuccessJoin = (
  data: ResponseWithMeta<System[]>
): FetchFiltersSystemsSuccessJoinAction => {
  return {
    type: Systems.FETCH_FILTERS_SYSTEMS_SUCCESS_JOIN,
    payload: data,
  };
};

export const fetchSystemsOrganizationSuccess = (
  data: System[]
): FetchSystemsOrganizationSuccessAction => {
  return {
    type: Systems.FETCH_SYSTEMS_ORGANIZATION_SUCCESS,
    payload: data,
  };
};

export const fetchMySystemsRequest = (
  updateType: UpdateContentType
): FetchMySystemsRequestAction => {
  return {
    type: Systems.FETCH_MY_SYSTEMS_REQUEST,
    payload: updateType,
  };
};

export const fetchFiltersSystemsRequest = (
  payload: FetchRequestArgs<SystemTicketPayload>
): FetchFiltersSystemsRequestAction => {
  return {
    type: Systems.FETCH_FILTERS_SYSTEMS_REQUEST,
    payload,
  };
};

export const fetchSystemsWithContractsRequest =
  (): FetchSystemsWithContractsRequestAction => {
    return {
      type: Systems.FETCH_SYSTEMS_WITH_CONTRACTS_REQUEST,
    };
  };

export const fetchCurrentSystemRequest = (
  id: string
): FetchCurrentSystemRequestAction => {
  return {
    type: Systems.FETCH_CURRENT_SYSTEM_REQUEST,
    payload: id,
  };
};

export const setCurrentSystemsPage = (
  page: number
): SetCurrentSystemsPageAction => {
  return {
    type: Systems.SET_CURRENT_SYSTEMS_PAGE,
    payload: page,
  };
};

export const setSizePage = (size: number): SetSizePageAction => {
  return {
    type: Systems.SET_SIZE_PAGE_SYSTEMS,
    payload: size,
  };
};

export const setSortSystems = (sort: string): SetSortSystemsAction => {
  return {
    type: Systems.SET_SORT_SYSTEMS,
    payload: sort,
  };
};

export const setCurrentSystemSuccess = (
  data: System
): SetCurrentSystemSuccessAction => {
  return {
    type: Systems.SET_CURRENT_SYSTEM_SUCCESS,
    payload: data,
  };
};

export const resetSystemsState = (): ResetSystemsStateAction => {
  return {
    type: Systems.RESET_SYSTEMS_STATE,
  };
};

export const resetSystemIdState = (): ResetSystemIdStateAction => {
  return {
    type: Systems.RESET_SYSTEM_ID_STATE,
  };
};

export const setCurrentSystemId = (id: string): SetCurrentSystemIdAction => {
  return {
    type: Systems.SET_CURRENT_SYSTEM_ID,
    payload: id,
  };
};

export const setCurrentOrganizationSystemId = (
  id?: string
): SetCurrentOrganizationSystemIdAction => {
  return {
    type: Systems.SET_CURRENT_ORGANIZATION_SYSTEM_ID,
    payload: id,
  };
};

export const fetchSystemsByContractId =
  (): FetchSystemsByContractIdRequestAction => {
    return {
      type: Systems.FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST,
    };
  };

export const setSystemsByContract = (
  system: System
): SetSystemsByContractAction => {
  return {
    type: Systems.SET_SYSTEMS_BY_CONTRACT,
    payload: system,
  };
};

export const setSystemsFilter = (
  data: SystemsFilter
): SetSystemsFilterAction => {
  return {
    type: Systems.SET_SYSTEMS_FILTER,
    payload: data,
  };
};

export const setSelectedSystemSuccess = (
  data: System
): SetSelectedSystemSuccessAction => {
  return {
    type: Systems.SET_SELECTED_SYSTEM_SUCCESS,
    payload: data,
  };
};

export const fetchSystemPrioritiesRequest = (
  systemId: string
): FetchSystemPrioritiesRequestAction => {
  return {
    type: Systems.FETCH_SYSTEM_PRIORITIES_REQUEST,
    payload: systemId,
  };
};

export const fetchSystemPrioritiesSuccess = (
  priorities: SystemPriority[]
): FetchSystemPrioritiesSuccessAction => {
  return {
    type: Systems.FETCH_SYSTEM_PRIORITIES_SUCCESS,
    payload: priorities,
  };
};

export const resetSystemPriorities = (): ResetSystemPrioritiesAction => {
  return {
    type: Systems.RESET_SYSTEM_PRIORITIES,
  };
};

export const setIsErrorNoData = (data: boolean): SetIsErrorNoDataAction => {
  return {
    type: Systems.SET_IS_ERROR_NO_DATA,
    payload: data,
  };
};

export const deleteSystemRequest = (id: string): DeleteSystemRequestAction => {
  return {
    type: Systems.DELETE_SYSTEM_BY_ID_REQUEST,
    payload: id,
  };
};

export const fetchSystemsOrganizationForJiraRequest = (
  id: string
): FetchSystemsOrganizationForJiraRequestAction => {
  return {
    type: Systems.FETCH_SYSTEMS_ORGANIZATION_FOR_JIRA_REQUEST,
    payload: id,
  };
};

export const resetCurrentSystem = (): ResetCurrentSystemAction => {
  return {
    type: Systems.RESET_CURRENT_SYSTEM,
  };
};

export const fetchSLAByContractIdBySystemIdRequestSystems = (
  payload: ContractDetailPayload
) => {
  return {
    type: Systems.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
    payload,
  };
};

export const fetchSLAByContractIdBySystemIdSuccessSystems = (sla: SLAType) => {
  return {
    type: Systems.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS,
    payload: sla,
  };
};

export const resetSLAByContractIdBySystemIdSystems = () => {
  return {
    type: Systems.RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID,
  };
};

export const fetchEnvironmentsRequest = () => {
  return {
    type: Systems.FETCH_ENVIRONMENTS_REQUEST,
  };
};

export const fetchEnvironmentsSuccess = (
  payload: ResponseWithMeta<Environment[]>
) => {
  return {
    type: Systems.FETCH_ENVIRONMENTS_SUCCESS,
    payload,
  };
};

import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ContractStatus, SupportType, TicketTypeSettings } from 'core/types';
import { useContractId } from 'features/Contract/hooks/useContractId';
import { RouterHref } from 'routes/routerHref';
import { checkObjectIdentity } from 'utils';

import {
  attachSystemAndSLAToContractDetail,
  fetchSlaByContractIdBySystemIdRequestContractDetail,
  fetchSystemsByContractIdRequestContractDetail,
  fetchWorkGroupsByContractBySystemRequestContractDetail,
  setActiveSystemByContractIdContractDetail,
  setAllCheckedSystemsForTransfer,
  setCheckedSystemForTransfer,
  setContractIdForTransfer,
  setIsTransfer,
  setPageSizeSystemsByContractIdContractDetail,
  setPageSystemsByContractIdContractDetail,
  setSortSystemsByContractIdContractDetail,
  setSystemSelectedByContract,
  updateContractSLARequestContractDetail,
} from '../ducks/actions';
import {
  getActiveSystemIdContractDetail,
  getContractDetail,
  getPropsSystemsContractDetail,
  getSLAByContractSystemContractDetail,
  getSystemsByContractIdSaved,
  getSystemsFilterContractDetail,
  getSystemsTransfer,
  getTicketTypes,
  getTicketTypesSettings,
  getTicketTypesSettingsLoading,
} from '../ducks/selectors';
import { AttachSystemAndSLAToContractType } from '../ducks/types';
import { findSelectedSystem, getPreparedTicketTypes } from '../utils';

const CREATE_TITLE = 'Добавить';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useSystemsTableContainer = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { pageNum, pageSize, sort, totalElements, loading } = useSelector(
    getPropsSystemsContractDetail
  );
  const systemsTransfer = useSelector(getSystemsTransfer);
  const { isTransfer } = systemsTransfer;
  const contractIdFromParams = useContractId();
  const contractId = contractIdFromParams || systemsTransfer.contractId;
  const systemsByContractIdSaved = useSelector(getSystemsByContractIdSaved);
  const systems = isTransfer
    ? systemsTransfer?.systems
    : systemsByContractIdSaved;
  const filterValues = useSelector(getSystemsFilterContractDetail);
  const currentSystemId = useSelector(getActiveSystemIdContractDetail);
  const sla = useSelector(getSLAByContractSystemContractDetail);
  const contract = useSelector(getContractDetail);
  const ticketTypesSettings = useSelector(getTicketTypesSettings);
  const ticketTypes = useSelector(getTicketTypes);
  const ticketTypesSettingsLoading = useSelector(getTicketTypesSettingsLoading);

  const [tableOpened, setTableOpened] = useState(true);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isChangeSLAModal, setIsChangeSLAModal] = useReducer(
    (prev) => !prev,
    false
  );

  const [showFilterRow, setShowFilterRow] = useState(false);
  const [slaTicketTypes, setSlaTicketTypes] = useState<TicketTypeSettings[]>();

  const isFilterActive = !checkObjectIdentity(filterValues, {});
  const canChangeSLA = contract?.status === ContractStatus.NOT_SIGNED;
  const isLicensed = contract?.supportType === SupportType.LICENSED;
  const isClosed = contract?.status === ContractStatus.CLOSED;

  const popoverDisabled =
    !isLicensed || isClosed || !systemsTransfer.systems?.length;

  const searchDisabled =
    !systemsByContractIdSaved?.length || (showFilterRow && isFilterActive);

  const goToCreateContract = () => {
    push(RouterHref.AdminCreateContract);
    dispatch(setIsTransfer(true));
    dispatch(setContractIdForTransfer(contractId));
  };

  const prepareDataConditionBlock = () => {
    if (sla?.workingHoursFrom?.length && sla?.workingHoursTo?.length) {
      return `${sla?.workingHoursFrom.slice(
        0,
        5
      )} - ${sla?.workingHoursTo.slice(0, 5)}`;
    }
    return undefined;
  };

  const autoClosingTime =
    sla && sla.autoClosableTickets && sla?.closeAfterHours
      ? sla.closeAfterHours.toString()
      : undefined;

  const toggleModal = () => {
    setIsModal(!isModal);
    setSlaTicketTypes(undefined);
  };

  const toggleFilterRow = contractId
    ? () => {
        setShowFilterRow((prevState) => !prevState);
      }
    : undefined;

  const onRowClickFunction = (value: string) => {
    if (currentSystemId !== value) {
      dispatch(setActiveSystemByContractIdContractDetail(value));
      dispatch(
        fetchWorkGroupsByContractBySystemRequestContractDetail({
          contractId,
          systemId: value,
        })
      );
      dispatch(
        fetchSlaByContractIdBySystemIdRequestContractDetail({
          contractId,
          systemId: value,
        })
      );
      const selectedSystem = findSelectedSystem(
        value,
        systemsByContractIdSaved
      );
      dispatch(setSystemSelectedByContract(selectedSystem));
    }
  };

  const handleChangePage = (page: number) => {
    dispatch(setPageSystemsByContractIdContractDetail(page));
    dispatch(
      fetchSystemsByContractIdRequestContractDetail({
        id: contractId,
        withDeletedSystems: true,
      })
    );
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setPageSizeSystemsByContractIdContractDetail(arg));
  };

  const handleSort = (value: string) => {
    dispatch(setSortSystemsByContractIdContractDetail(value));
    dispatch(
      fetchSystemsByContractIdRequestContractDetail({
        id: contractId,
        withDeletedSystems: true,
      })
    );
  };

  const createFunction =
    contractId && contract?.status === ContractStatus.NOT_SIGNED
      ? toggleModal
      : undefined;

  const onCreateSLA = (data: AttachSystemAndSLAToContractType) => {
    dispatch(attachSystemAndSLAToContractDetail(data));
  };

  const onUpdateSLA = (data: AttachSystemAndSLAToContractType) => {
    dispatch(
      updateContractSLARequestContractDetail({
        ...data,
        systemId: currentSystemId,
      })
    );
  };

  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;

    const selectedSystem = findSelectedSystem(id, systemsByContractIdSaved);
    if (selectedSystem) {
      dispatch(setCheckedSystemForTransfer(selectedSystem));
    }
  };

  const onSelectAllCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    if (systemsByContractIdSaved) {
      const systemsForTransfer = checked ? systemsByContractIdSaved : [];
      dispatch(setAllCheckedSystemsForTransfer(systemsForTransfer));
    }
  };

  useEffect(() => {
    setSlaTicketTypes(getPreparedTicketTypes(ticketTypes));
  }, [ticketTypes]);

  return {
    methods: {
      setTableOpened,
      toggleModal,
      toggleFilterRow,
      handleClickSystem: onRowClickFunction,
      handleChangePage,
      handleChangePageSize,
      handleSort,
      onChangeCheckbox,
      onSelectAllCheckbox,
      createFunction,
      prepareDataConditionBlock,
      onCreateSLA,
      setIsChangeSLAModal,
      onUpdateSLA,
      goToCreateContract,
    },
    state: {
      pageNum,
      pageSize,
      sortSystems: sort,
      totalElements,
      systems,
      tableOpened,
      isModal,
      showFilterRow,
      isFilterActive,
      searchDisabled,
      loading,
      currentSystemId,
      contractId,
      createTitle: CREATE_TITLE,
      sla,
      autoClosingTime,
      slaTicketTypes: isChangeSLAModal ? ticketTypesSettings : slaTicketTypes,
      isChangeSLAModal,
      canChangeSLA,
      popoverDisabled,
      isTransfer,
      ticketTypesSettingsLoading,
      ticketTypesSettings,
    },
  };
};

import React from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'components/Table';
import { Article } from 'features/KnowledgeBase/types';

import { TABLE_ARTICLE_FIELDS } from '../../../../constants';
import { getArticlesTableLoading } from '../../ducks/selectors';
import { KBTableArticlesFilter } from '../KBTableArticlesFilter';

import { getDataTable } from './utils';

interface Props {
  articles?: Article[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: ReturnType<typeof getDataTable>[number]) => void;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  activeRowId?: string;
}

export const KBTableArticles: React.FC<Props> = ({
  articles,
  handleRowClick,
  sort,
  handleSort,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const data = getDataTable({ articles });

  const loading = useSelector(getArticlesTableLoading);

  return (
    <Table
      data={data}
      columns={TABLE_ARTICLE_FIELDS}
      onRowClick={handleRowClick}
      onSort={handleSort}
      sort={sort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      filterComponent={showFilterRow && <KBTableArticlesFilter />}
      disabledSort={loading}
    />
  );
};

import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { getValueFromValueType } from 'components/Select';
import { setAlert } from 'core/ducks/actions';
import {
  createErrorAlert,
  createSuccessAlert,
} from 'core/layouts/AlertsLayout';
import { System } from 'core/types';
import { SystemPriority } from 'features/Systems/types';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import {
  Field,
  FieldsIntegration,
  JiraIntegration,
  JiraIntegrationsFilter,
  JiraIntegrationsFormData,
  JiraPriority,
  JiraStatus,
  JiraTicketType,
  Status,
} from '../types';

import {
  fetchFieldsIntegrationsSuccess,
  fetchJiraFieldsSuccess,
  fetchJiraIntegrationsSuccess,
  fetchJiraIntegrationSuccess,
  fetchJiraPrioritiesByIdSuccess,
  fetchJiraPrioritiesByIntegrationIdSuccess,
  fetchJiraPrioritiesSuccess,
  fetchJiraStatusesByIdSuccess,
  fetchJiraStatusesSuccess,
  fetchJiraTicketTypeByIntegrationIdSuccess,
  fetchJiraTicketTypesByIntegrationIdSuccess,
  fetchJiraTicketTypeSuccess,
  fetchSpFieldsSuccess,
  fetchSPStatusesForCurrentSuccess,
  fetchSPStatusesSuccess,
  fetchSystemPrioritiesSuccess,
  fetchTicketTypeSuccess,
  hideJiraIntegrationInfoLoading,
  hideJiraIntegrationsLoading,
  hidePrioritiesLoading,
  hideStatusesLoading,
  setCurrentJiraIntegrationId,
  setCurrentSystemId,
  showJiraIntegrationInfoLoading,
  showJiraIntegrationsLoading,
  showPrioritiesLoading,
  showStatusesLoading,
  updateJiraIntegrationById,
} from './actions';
import { request } from './api/requests';
import {
  getCurrentJiraIntegrationId,
  getCurrentSystemId,
  getJiraIntegrationsFilter,
  getPropsJiraIntegrations,
  getSelectedJiraTicketType,
  getTypeId,
} from './selectors';
import {
  CreateFieldsIntegrationAction,
  CreateJiraIntegrationAction,
  DeleteFieldsIntegrationAction,
  DeleteJiraIntegrationAction,
  EditFieldsIntegrationAction,
  EditJiraIntegrationAction,
  EditJiraIntegrationsActiveAction,
  FetchFieldsIntegrationsRequestAction,
  FetchInfoForJiraIntegrationAction,
  FetchJiraFieldsRequestAction,
  FetchJiraIntegrationRequestAction,
  FetchJiraPrioritiesByIdAction,
  FetchJiraPrioritiesByIntegrationIdRequestAction,
  FetchJiraPrioritiesRequestAction,
  FetchJiraSPStatusesForCurrentRequestAction,
  FetchJiraSPStatusesRequestAction,
  FetchJiraStatusesByIdAction,
  FetchJiraStatusesRequestAction,
  FetchJiraTicketTypeByIntegrationIdRequestAction,
  FetchJiraTicketTypesByIntegrationIdRequestAction,
  FetchJiraTicketTypesRequestAction,
  FetchSpFieldsRequestAction,
  FetchSystemPrioritiesRequestAction,
  FetchTicketTypesRequestAction,
  Jira,
} from './types';

export const getJiraIntegrationsFilterToRequest = (
  filter: JiraIntegrationsFormData
): JiraIntegrationsFilter => {
  const { systemId, isActive, organizationId } = filter;
  return {
    ...filter,
    systemId: getValueFromValueType(systemId),
    isActive: getValueFromValueType<boolean>(isActive),
    organizationId: getValueFromValueType(organizationId),
  };
};

function* jiraIntegrationsFetch() {
  try {
    const {
      pageNum,
      pageSize,
      sort,
    }: ReturnType<typeof getPropsJiraIntegrations> = yield select(
      getPropsJiraIntegrations
    );
    const filter: ReturnType<typeof getJiraIntegrationsFilter> = yield select(
      getJiraIntegrationsFilter
    );

    yield put(showJiraIntegrationsLoading());
    const jiraIntegrations: ResponseWithMeta<JiraIntegration[]> = yield call(
      request.fetchJiraIntegrations,
      pageNum,
      pageSize,
      sort,
      filter
    );
    yield put(fetchJiraIntegrationsSuccess(jiraIntegrations));

    yield put(hideJiraIntegrationsLoading());
  } catch (e) {
    yield put(hideJiraIntegrationsLoading());
    createError(e);
  }
}

function* jiraStatusesFetch({ payload }: FetchJiraStatusesRequestAction) {
  try {
    const jiraStatuses: JiraStatus[] = yield call(
      request.fetchJiraStatuses,
      payload
    );
    yield put(fetchJiraStatusesSuccess(jiraStatuses));
  } catch (e) {
    createError(e);
  }
}

function* jiraPrioritiesFetch({ payload }: FetchJiraPrioritiesRequestAction) {
  try {
    const jiraPriorities: JiraPriority[] = yield call(
      request.fetchJiraPriorities,
      payload
    );
    yield put(fetchJiraPrioritiesSuccess(jiraPriorities));
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraPrioritiesByIntegrationId({
  payload,
}: FetchJiraPrioritiesByIntegrationIdRequestAction) {
  try {
    const jiraPriorities: JiraPriority[] = yield call(
      request.fetchJiraPrioritiesByIntegrationId,
      payload
    );
    yield put(fetchJiraPrioritiesByIntegrationIdSuccess(jiraPriorities));
  } catch (e) {
    createError(e);
  }
}

function* fetchTicketTypes({ payload }: FetchTicketTypesRequestAction) {
  try {
    const { typeList }: System = yield call(request.fetchTicketTypes, payload);
    if (typeList) {
      yield put(fetchTicketTypeSuccess(typeList));
    }
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraTicketType({ payload }: FetchJiraTicketTypesRequestAction) {
  try {
    const jiraTicketTypes: JiraTicketType[] = yield call(
      request.fetchJiraTicketType,
      payload
    );
    yield put(fetchJiraTicketTypeSuccess(jiraTicketTypes));
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraTicketTypesByIntegrationId({
  payload,
}: FetchJiraTicketTypesByIntegrationIdRequestAction) {
  try {
    const jiraTicketTypes: JiraTicketType[] = yield call(
      request.fetchJiraTicketTypesByIntegrationId,
      payload
    );
    yield put(fetchJiraTicketTypesByIntegrationIdSuccess(jiraTicketTypes));
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraTicketTypeByIntegrationId({
  payload,
}: FetchJiraTicketTypeByIntegrationIdRequestAction) {
  try {
    const jiraTicketType: JiraTicketType[] = yield call(
      request.fetchJiraTicketTypeByIntegrationId,
      payload
    );
    yield put(fetchJiraTicketTypeByIntegrationIdSuccess(jiraTicketType));
  } catch (e) {
    createError(e);
  }
}

function* createJiraIntegration({ payload }: CreateJiraIntegrationAction) {
  try {
    const jiraTicketType: JiraTicketType | undefined = yield select(
      getSelectedJiraTicketType(payload.idType)
    );

    const {
      name,
      login,
      password,
      jiraUrl,
      projectKey,
      organizationId,
      systemId,
      ticketTypeId,
      statusMapping,
      priorityMapping,
    } = payload;

    const jiraIntegration: JiraIntegration = yield call(
      request.createJiraIntegration,
      {
        name,
        login,
        password,
        jiraUrl,
        projectKey,
        organizationId,
        systemId,
        ticketTypeId,
      }
    );

    if (jiraTicketType) {
      yield call(request.mappingTicketTypes, {
        idType: jiraTicketType.idType,
        description: jiraTicketType.description,
        name: jiraTicketType.name,
        subtask: jiraTicketType.subtask,
        propertyId: jiraIntegration.id || '',
        ticketTypeId: ticketTypeId || '',
      });
    }

    yield call(request.mappingStatuses, statusMapping);
    yield call(request.mappingPriorities, priorityMapping);

    yield put(
      setAlert(createSuccessAlert('Интеграция с Jira успешно создана'))
    );
    yield call(jiraIntegrationsFetch);
    yield put(setCurrentJiraIntegrationId(jiraIntegration.id));
    yield put(setCurrentSystemId(jiraIntegration.systemId));
    yield put(fetchJiraIntegrationSuccess(jiraIntegration));
  } catch (e) {
    yield put(
      setAlert(createErrorAlert('Не удалось создать интеграцию с Jira'))
    );
    createError(e);
  }
}

function* editJiraIntegration({ payload }: EditJiraIntegrationAction) {
  try {
    const { ticketTypeUpdateDto, statusMapping, priorityMapping } =
      payload || {};

    if (ticketTypeUpdateDto) {
      yield call(request.editMappingJiraTicketType, ticketTypeUpdateDto);
    }
    if (statusMapping) {
      yield call(request.editMappingStatuses, statusMapping);
    }
    if (priorityMapping) {
      yield call(request.editMappingPriorities, priorityMapping);
    }

    yield put(
      setAlert(createSuccessAlert('Интеграция с Jira успешно отредактирована'))
    );
  } catch (e) {
    yield put(
      setAlert(createErrorAlert('Не удалось отредактировать  интеграцию Jira'))
    );
    createError(e);
  }
}

function* jiraStatusesByIdFetch({ payload }: FetchJiraStatusesByIdAction) {
  try {
    yield put(showStatusesLoading());
    const jiraStatuses: JiraStatus[] = yield call(
      request.fetchJiraStatusesById,
      payload
    );
    yield put(fetchJiraStatusesByIdSuccess(jiraStatuses));
  } catch (e) {
    createError(e);
  } finally {
    yield put(hideStatusesLoading());
    yield put(hideJiraIntegrationInfoLoading());
  }
}

function* jiraPrioritiesByIdFetch({ payload }: FetchJiraPrioritiesByIdAction) {
  try {
    yield put(showPrioritiesLoading());
    const jiraPriorities: JiraPriority[] = yield call(
      request.fetchJiraPrioritiesById,
      payload
    );
    yield put(fetchJiraPrioritiesByIdSuccess(jiraPriorities));
  } catch (e) {
    createError(e);
  } finally {
    yield put(hidePrioritiesLoading());
    yield put(hideJiraIntegrationInfoLoading());
  }
}

function* deleteJiraIntegration({ payload }: DeleteJiraIntegrationAction) {
  try {
    yield call(request.deleteJiraIntegration, payload);
    yield put(
      setAlert(createSuccessAlert('Интеграция с Jira успешно удалена'))
    );
    yield call(jiraIntegrationsFetch);
    yield put(setCurrentJiraIntegrationId(undefined));
    yield put(setCurrentSystemId(undefined));
    yield put(fetchJiraIntegrationSuccess(undefined));
  } catch (e) {
    yield put(
      setAlert(createErrorAlert('Не удалось удалить интеграцию с Jira'))
    );
    createError(e);
  }
}

function* jiraIntegrationFetch({ payload }: FetchJiraIntegrationRequestAction) {
  try {
    const jiraIntegration: JiraIntegration = yield call(
      request.fetchJiraIntegration,
      payload
    );
    yield put(fetchJiraIntegrationSuccess(jiraIntegration));

    const systemId = jiraIntegration?.systemId;

    if (systemId) {
      yield call(fetchTicketTypes, {
        payload: systemId,
        type: Jira.FETCH_TICKET_TYPES_REQUEST,
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* editJiraIntegrationsActive({
  payload,
}: EditJiraIntegrationsActiveAction) {
  try {
    const jiraIntegration: JiraIntegration = yield call(
      request.editJiraIntegrationActive,
      payload
    );
    yield put(fetchJiraIntegrationSuccess(jiraIntegration));
    yield put(updateJiraIntegrationById(jiraIntegration));
  } catch (e) {
    createError(e);
  }
}

function* fetchSpFields({ payload }: FetchSpFieldsRequestAction) {
  try {
    const spFields: Field[] = yield call(request.fetchSpFields, payload);
    yield put(fetchSpFieldsSuccess(spFields));
  } catch (e) {
    createError(e);
  }
}

function* fetchJiraFields({ payload }: FetchJiraFieldsRequestAction) {
  try {
    const jiraFields: Field[] = yield call(request.fetchJiraFields, payload);
    yield put(fetchJiraFieldsSuccess(jiraFields));
  } catch (e) {
    createError(e);
  }
}

function* fieldsIntegrationsFetch({
  payload,
}: FetchFieldsIntegrationsRequestAction) {
  try {
    const fieldsIntegrations: FieldsIntegration[] = yield call(
      request.fetchFieldsIntegrations,
      payload
    );
    yield put(fetchFieldsIntegrationsSuccess(fieldsIntegrations));
  } catch (e) {
    yield put(hideJiraIntegrationInfoLoading());
    createError(e);
  }
}

function* jiraSPStatusesFetch({ payload }: FetchJiraSPStatusesRequestAction) {
  try {
    const statuses: Status[] = yield call(request.fetchSPStatuses, payload);
    yield put(fetchSPStatusesSuccess(statuses));
  } catch (e) {
    createError(e);
  }
}

function* jiraSPStatusesForCurrentFetch({
  payload,
}: FetchJiraSPStatusesForCurrentRequestAction) {
  try {
    const statuses: Status[] = yield call(request.fetchSPStatuses, payload);
    yield put(fetchSPStatusesForCurrentSuccess(statuses));
  } catch (e) {
    createError(e);
  }
}

function* fetchInfoForJiraIntegration({
  payload,
}: FetchInfoForJiraIntegrationAction) {
  const { systemId, typeId } = payload;
  const args = { systemId, typeId };

  try {
    yield put(showJiraIntegrationInfoLoading());
    yield call(jiraSPStatusesForCurrentFetch, {
      payload: args,
      type: Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_REQUEST,
    });
    yield call(jiraStatusesByIdFetch, {
      payload: args,
      type: Jira.FETCH_JIRA_STATUSES_BY_ID_REQUEST,
    });
    yield call(jiraPrioritiesByIdFetch, {
      payload: args,
      type: Jira.FETCH_JIRA_PRIORITIES_BY_ID_REQUEST,
    });
    yield call(fieldsIntegrationsFetch, {
      payload: args,
      type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
    });
  } catch (e) {
    createError(e);
  } finally {
    yield put(hideJiraIntegrationInfoLoading());
  }
}

function* createFieldsIntegration({ payload }: CreateFieldsIntegrationAction) {
  try {
    const systemId: ReturnType<typeof getCurrentSystemId> = yield select(
      getCurrentSystemId
    );
    const jiraIntegrationId: ReturnType<typeof getCurrentJiraIntegrationId> =
      yield select(getCurrentJiraIntegrationId);

    const { typeId } = payload || {};

    if (systemId && jiraIntegrationId && typeId) {
      yield call(request.createFieldsIntegration, payload, {
        systemId,
        typeId,
      });
      yield call(fieldsIntegrationsFetch, {
        payload: { systemId, typeId },
        type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
      });
      yield call(fetchSpFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_SP_FIELDS_REQUEST,
      });
      yield call(fetchJiraFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_JIRA_FIELDS_REQUEST,
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* deleteFieldsIntegration({ payload }: DeleteFieldsIntegrationAction) {
  try {
    const systemId: ReturnType<typeof getCurrentSystemId> = yield select(
      getCurrentSystemId
    );
    const jiraIntegrationId: ReturnType<typeof getCurrentJiraIntegrationId> =
      yield select(getCurrentJiraIntegrationId);
    const typeId: ReturnType<typeof getTypeId> = yield select(getTypeId);

    yield call(request.deleteFieldsIntegration, payload);

    if (systemId && jiraIntegrationId && typeId) {
      yield call(fieldsIntegrationsFetch, {
        payload: { systemId, typeId },
        type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
      });
      yield call(fetchSpFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_SP_FIELDS_REQUEST,
      });
      yield call(fetchJiraFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_JIRA_FIELDS_REQUEST,
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* editFieldsIntegration({ payload }: EditFieldsIntegrationAction) {
  try {
    const systemId: ReturnType<typeof getCurrentSystemId> = yield select(
      getCurrentSystemId
    );
    const jiraIntegrationId: ReturnType<typeof getCurrentJiraIntegrationId> =
      yield select(getCurrentJiraIntegrationId);
    const typeId: ReturnType<typeof getTypeId> = yield select(getTypeId);

    if (systemId && jiraIntegrationId && typeId) {
      yield call(request.editFieldsIntegration, payload);
      yield call(fieldsIntegrationsFetch, {
        payload: { systemId, typeId },
        type: Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
      });
      yield call(fetchSpFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_SP_FIELDS_REQUEST,
      });
      yield call(fetchJiraFields, {
        payload: { systemId, typeId },
        type: Jira.FETCH_JIRA_FIELDS_REQUEST,
      });
    }
  } catch (e) {
    createError(e);
  }
}

function* systemPrioritiesFetch({
  payload,
}: FetchSystemPrioritiesRequestAction) {
  try {
    const priorities: SystemPriority[] = yield call(
      request.fetchSystemPriorities,
      payload
    );
    yield put(fetchSystemPrioritiesSuccess(priorities));
  } catch (e) {
    yield put(fetchSystemPrioritiesSuccess([]));
    createError(e);
  }
}

export function* jiraSaga(): Generator<StrictEffect> {
  yield takeEvery(Jira.FETCH_JIRA_INTEGRATIONS_REQUEST, jiraIntegrationsFetch);
  yield takeEvery(Jira.FETCH_JIRA_STATUSES_REQUEST, jiraStatusesFetch);
  yield takeEvery(Jira.FETCH_JIRA_PRIORITIES_REQUEST, jiraPrioritiesFetch);
  yield takeEvery(
    Jira.FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_REQUEST,
    fetchJiraPrioritiesByIntegrationId
  );
  yield takeEvery(Jira.CREATE_JIRA_INTEGRATION, createJiraIntegration);
  yield takeEvery(Jira.EDIT_JIRA_INTEGRATION, editJiraIntegration);
  yield takeEvery(
    Jira.FETCH_JIRA_STATUSES_BY_ID_REQUEST,
    jiraStatusesByIdFetch
  );
  yield takeEvery(
    Jira.FETCH_JIRA_PRIORITIES_BY_ID_REQUEST,
    jiraPrioritiesByIdFetch
  );
  yield takeEvery(Jira.DELETE_JIRA_INTEGRATION, deleteJiraIntegration);
  yield takeEvery(Jira.FETCH_JIRA_INTEGRATION_REQUEST, jiraIntegrationFetch);
  yield takeEvery(
    Jira.EDIT_JIRA_INTEGRATION_ACTIVE,
    editJiraIntegrationsActive
  );
  yield takeEvery(Jira.FETCH_SP_FIELDS_REQUEST, fetchSpFields);
  yield takeEvery(Jira.FETCH_JIRA_FIELDS_REQUEST, fetchJiraFields);
  yield takeEvery(
    Jira.FETCH_FIELDS_INTEGRATIONS_REQUEST,
    fieldsIntegrationsFetch
  );
  yield takeEvery(
    Jira.FETCH_INFO_FOR_JIRA_INTEGRATION,
    fetchInfoForJiraIntegration
  );
  yield takeEvery(Jira.CREATE_FIELDS_INTEGRATION, createFieldsIntegration);
  yield takeEvery(Jira.DELETE_FIELDS_INTEGRATION, deleteFieldsIntegration);
  yield takeEvery(Jira.EDIT_FIELDS_INTEGRATION, editFieldsIntegration);
  yield takeEvery(Jira.FETCH_SYSTEM_PRIORITIES_REQUEST, systemPrioritiesFetch);
  yield takeEvery(Jira.FETCH_JIRA_SP_STATUSES_REQUEST, jiraSPStatusesFetch);
  yield takeEvery(
    Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_REQUEST,
    jiraSPStatusesForCurrentFetch
  );
  yield takeEvery(Jira.FETCH_TICKET_TYPES_REQUEST, fetchTicketTypes);
  yield takeEvery(Jira.FETCH_JIRA_TICKET_TYPES_REQUEST, fetchJiraTicketType);
  yield takeEvery(
    Jira.FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_REQUEST,
    fetchJiraTicketTypesByIntegrationId
  );
  yield takeEvery(
    Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_REQUEST,
    fetchJiraTicketTypeByIntegrationId
  );
}

import cn from 'clsx';
import React, { memo, useCallback } from 'react';
import {
  Connection,
  Handle,
  HandleProps,
  NodeProps,
  Position,
  useEdges,
  useNodes,
} from 'reactflow';

import { Typography, TypographyVariants } from 'components/Typography';
import { getIsValidConnection } from 'features/StatusesBuilder/utils';

import { EdgeType, NodeType } from '../../types';

import styles from './CustomNode.module.scss';

const handlesConfig: HandleProps[] = [
  { id: 'a', type: 'target', position: Position.Left },
  { id: 'b', type: 'target', position: Position.Top },
  { id: 'a', type: 'source', position: Position.Right },
  { id: 'b', type: 'source', position: Position.Bottom },
];

interface Props extends NodeType {}

export const CustomNode = memo(({ data, selected }: NodeProps<Props>) => {
  const {
    label,
    color,
    hideLeftHandle,
    hideTopHandle,
    hideRightHandle,
    hideBottomHandle,
  } = data;

  const edges = useEdges<EdgeType>();
  const nodes = useNodes<NodeType>();

  const isValidConnection = useCallback(
    (connection: Connection) => {
      return getIsValidConnection(connection, edges, nodes);
    },
    [edges, getIsValidConnection]
  );

  const handles = handlesConfig.map((handle) => {
    const { id, type, position } = handle;

    if (
      (position === Position.Top && hideTopHandle) ||
      (position === Position.Bottom && hideBottomHandle) ||
      (position === Position.Left && hideLeftHandle) ||
      (position === Position.Right && hideRightHandle)
    ) {
      return null;
    }

    return (
      <Handle
        key={`${id}-${type}`}
        id={id}
        type={type}
        position={position}
        isValidConnection={isValidConnection}
        className={styles.customNode__handle}
      />
    );
  });

  return (
    <div
      className={cn(styles.customNode, {
        [styles.customNode_selected]: selected,
      })}
    >
      <div
        className={styles.customNode__icon}
        style={{ backgroundColor: `var(--${color})` }}
      />
      <Typography
        variant={TypographyVariants.b3}
        className={styles.customNode__label}
      >
        {label}
      </Typography>
      {handles}
    </div>
  );
});

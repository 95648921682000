import React from 'react';
import { Controller } from 'react-hook-form';

import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { TableBodyCell } from 'components/Table/components/TableBodyCell';
import { TableFilter } from 'components/Table/components/TableFilter';
import { Size } from 'components/types';
import { IS_ACTIVE_OPTIONS } from 'core/constants';
import { useTriggersFilter } from 'features/Triggers/hooks/useTriggersFilter';
import { checkObjectIdentity } from 'utils';

import styles from './TriggersFilter.module.scss';

export const TriggersFilter: React.FC = React.memo(() => {
  const {
    state: {
      filterValues,
      titleToInput,
      organizationsOptions,
      systemsOptions,
      ticketTypesOptions,
      ticketPrioritiesOptions,
      environmentsOptions,
      statusOptions,
      clientsOptions,
    },
    methods: { control, resetFilter, formSubmitHandler },
  } = useTriggersFilter();

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell className={styles.triggersFilter__cell} key={item.key}>
        {item}
      </TableBodyCell>
    ));

  const filterElements = [
    <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
    <Controller
      control={control}
      name="isActive"
      key="isActive"
      render={({ field }) => {
        return (
          <Select<boolean>
            size={Size.xs}
            mobileModalTitle="активность"
            options={IS_ACTIVE_OPTIONS}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isTooltip={false}
          />
        );
      }}
    />,
    <Controller
      control={control}
      name="organizations"
      key="organizations"
      render={({ field }) => {
        return (
          <Select
            size={Size.xs}
            mobileModalTitle="организация"
            options={organizationsOptions}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isMulti
          />
        );
      }}
    />,
    <Controller
      control={control}
      name="systems"
      key="systems"
      render={({ field }) => {
        return (
          <Select
            size={Size.xs}
            mobileModalTitle="система"
            options={systemsOptions}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isMulti
          />
        );
      }}
    />,
    <Controller
      control={control}
      name="status"
      key="status"
      render={({ field }) => {
        return (
          <Select
            size={Size.xs}
            mobileModalTitle="статус"
            options={statusOptions}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
          />
        );
      }}
    />,
    <Controller
      control={control}
      name="ticketTypes"
      key="ticketTypes"
      render={({ field }) => {
        return (
          <Select
            size={Size.xs}
            mobileModalTitle="тип тикета"
            options={ticketTypesOptions}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isMulti
          />
        );
      }}
    />,
    <Controller
      control={control}
      name="ticketPriorities"
      key="ticketPriorities"
      render={({ field }) => {
        return (
          <Select
            size={Size.xs}
            mobileModalTitle="приоритет"
            options={ticketPrioritiesOptions}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isMulti
          />
        );
      }}
    />,
    <Controller
      control={control}
      name="environments"
      key="environments"
      render={({ field }) => {
        return (
          <Select
            size={Size.xs}
            mobileModalTitle="среда"
            options={environmentsOptions}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isMulti
          />
        );
      }}
    />,
    <Controller
      control={control}
      name="clients"
      key="clients"
      render={({ field }) => {
        return (
          <Select
            size={Size.xs}
            mobileModalTitle="клиент"
            options={clientsOptions}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isMulti
          />
        );
      }}
    />,
    <Input size={Size.xs} type="customField" key="customField" disabled />,
  ];

  const filter = <>{tableBodyWrapper(filterElements)}</>;

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});

import React from 'react';
import { useHistory } from 'react-router-dom';

import { CompassIcon } from 'assets/icons';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { Typography, TypographyVariants } from 'components/Typography';
import { RouterHref } from 'routes/routerHref';

import styles from './NotFound.module.scss';

export const NotFound = () => {
  const { goBack, push } = useHistory();

  const goBachHandler = () => {
    goBack();
  };

  const goToTicketsHandler = () => {
    push(RouterHref.Tickets);
  };

  return (
    <Card className={styles.notFound}>
      <div className={styles.notFound__iconWrapper}>
        <CompassIcon className={styles.notFound__icon} />
        <h2 className={styles.notFound__title}>404</h2>
      </div>
      <Typography
        variant={TypographyVariants.d2}
        className={styles.notFound__subTitle}
      >
        Что-то пошло не так
      </Typography>
      <Typography
        variant={TypographyVariants.b3}
        className={styles.notFound__text}
      >
        Данной страницы не существует, вернитесь назад или перейдите к списку
        тикетов.
      </Typography>
      <div className={styles.notFound__buttons}>
        <Button
          onClick={goBachHandler}
          className={styles.notFound__goBackButton}
        >
          Назад
        </Button>
        <Button
          onClick={goToTicketsHandler}
          appearance="outline"
          className={styles.notFound__goTicketsButton}
        >
          К тикетам
        </Button>
      </div>
    </Card>
  );
};

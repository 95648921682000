import React from 'react';

import { MainLayout } from 'core/layouts/MainLayout';

import { Storages as StoragesContent } from '../../Storages';

import styles from './Storages.module.scss';

const Storages = () => {
  return (
    <MainLayout title="Хранилища" classNameRoot={styles.storages}>
      <StoragesContent />
    </MainLayout>
  );
};

export default Storages;

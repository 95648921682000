import cn from 'clsx';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { TypographyLink } from 'components-new/TypographyLink';

import { Calendar } from './components/Calendar';
import { Footer } from './components/Footer';
import { Sidebar } from './components/Sidebar';
import styles from './DatePicker.module.scss';
import {
  DatePickerProps,
  RangeType,
  RangeTypesEnum,
  SelectedDateType,
} from './types';

export const DatePicker: FC<DatePickerProps> = ({
  className,
  pickedDate,
  setPickedDate,
  showTime,
  showRange,
  onCancel,
  onSubmit,
}) => {
  const [selectedDate, setSelectedDate] = useState<SelectedDateType>(() => {
    if (showRange) {
      const today = pickedDate?.from?.getTime() ? pickedDate?.from : new Date();
      const from = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const to = new Date(
        from.getFullYear(),
        from.getMonth() + 1,
        from.getDate()
      );
      return { from, to };
    }
    return pickedDate instanceof Date && pickedDate?.getTime()
      ? pickedDate
      : new Date();
  });

  const [approximateToDate, setApproximateToDate] = useState<Date>();

  const changeSelectedDate = (key?: RangeTypesEnum) => {
    return (date: Date) =>
      showRange && key
        ? setSelectedDate({ ...selectedDate, [key]: date })
        : setSelectedDate(date);
  };

  const changePickedDate = useCallback(() => {
    return (date: Date) => {
      if (!showRange) {
        setPickedDate(date);
        return;
      }
      if (!(pickedDate && pickedDate.from)) {
        setPickedDate({ from: date });
        return;
      }

      if (new Date(date) >= new Date(pickedDate.from)) {
        setPickedDate({ ...pickedDate, to: date });
        return;
      }
      const from = date;
      const to = pickedDate.from;
      setPickedDate({ from, to });
    };
  }, [showRange, pickedDate, setPickedDate]);

  const changePickedDateByKey = (key: string) => {
    return (date: Date) =>
      showRange && setPickedDate({ ...pickedDate, [key]: date });
  };

  const cancelBtnClickHandler = () => {
    onCancel();
    setPickedDate(undefined);
  };

  const submitBtnClickHandler = () => {
    onSubmit();
  };

  const resetBtnClickHandler = (event?: React.MouseEvent) => {
    event?.preventDefault();
    setPickedDate(undefined);
  };

  const calendar = useMemo(
    () =>
      selectedDate instanceof Date &&
      (pickedDate instanceof Date || pickedDate === undefined) ? (
        <Calendar
          showTime={showTime}
          pickedDate={pickedDate}
          changeDate={changePickedDate()}
          selectedDate={selectedDate}
          setSelectedDate={changeSelectedDate()}
        />
      ) : (
        !(pickedDate instanceof Date) &&
        Object.keys(selectedDate).map((key) => {
          return (
            <Calendar
              key={key}
              rangeType={RangeTypesEnum[key as RangeType]}
              showTime={showTime}
              pickedDate={pickedDate?.[key as RangeType]}
              pickedDateRange={pickedDate}
              changeDateByKey={changePickedDateByKey(key)}
              changeDate={changePickedDate()}
              selectedDate={
                selectedDate instanceof Date
                  ? selectedDate
                  : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    selectedDate[key as RangeType]!
              }
              setSelectedDate={changeSelectedDate(RangeTypesEnum.from)}
              approximateToDate={approximateToDate}
              setApproximateToDate={setApproximateToDate}
            />
          );
        })
      ),
    [
      selectedDate,
      pickedDate,
      approximateToDate,
      setApproximateToDate,
      showTime,
    ]
  );

  const getPickedDate = useMemo(
    () =>
      pickedDate instanceof Date || (!pickedDate?.to && !pickedDate?.from)
        ? undefined
        : pickedDate,
    [pickedDate]
  );

  useEffect(() => {
    if (!(selectedDate instanceof Date)) {
      const from = selectedDate?.from;

      if (from) {
        const to = new Date(
          from.getFullYear(),
          from.getMonth() + 1,
          from.getDate()
        );
        setSelectedDate({ from, to });
      }
    }
  }, [!(selectedDate instanceof Date) ? selectedDate?.from : null]);

  const sidebar = useMemo(
    () =>
      showRange && (
        <Sidebar
          className={styles.datePicker__sidebar}
          setPickedDate={setPickedDate}
        />
      ),
    [showRange, setPickedDate]
  );

  const setCurrentDay = (event?: React.MouseEvent) => {
    event?.preventDefault();

    const today = new Date();
    const from = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    setPickedDate(from);
    setSelectedDate(from);
  };

  return (
    <div
      className={cn(styles.datePicker, {
        [styles.datePicker_range]: showRange,
        className,
      })}
    >
      <div
        className={cn(styles.datePicker__main, {
          [styles.datePicker__main_range]: showRange,
        })}
      >
        <div
          className={cn(styles.datePicker__mainWrapper, {
            [styles.datePicker__mainWrapper_range]: showRange,
          })}
        >
          <div
            className={cn(styles.datePicker__mainContent, {
              [styles.datePicker__mainContent_range]: showRange,
            })}
          >
            {calendar}
          </div>
          <TypographyLink
            className={styles.datePicker__typographyLink}
            variant="l2"
            as="button"
            onClick={showRange ? resetBtnClickHandler : setCurrentDay}
          >
            {showRange ? 'Сбросить всё' : 'Сегодня'}
          </TypographyLink>
        </div>
        {sidebar}
      </div>
      <Footer
        cancel={() => cancelBtnClickHandler()}
        submit={submitBtnClickHandler}
        pickedDate={getPickedDate}
        resetRange={resetBtnClickHandler}
        showTime={!!showTime}
        range={!!showRange}
      />
    </div>
  );
};

import { Connection, Edge, Node } from 'reactflow';

import { EdgeType, NodeType } from '../types';

export const getIsValidConnection = (
  connection: Connection,
  edges: Edge<EdgeType>[],
  nodes: Node<NodeType>[],
  oldEdge?: Edge<EdgeType>
) => {
  const isIdenticalNode = connection.target === connection.source;

  const newEdgePath = `${connection.source}-${connection.target}`;
  const oldEdgePath = `${oldEdge?.source}-${oldEdge?.target}`;

  const isIdenticalEdge =
    !!edges.find((edge) => {
      const edgePath = `${edge.source}-${edge.target}`;
      return newEdgePath === edgePath;
    }) && oldEdgePath !== newEdgePath;

  const nodeStatusNew = nodes.find((node) => node.data.label === 'Новый');
  const nodeStatusClose = nodes.find((node) => node.data.label === 'Закрыт');
  const nodeStatusWaitingInfo = nodes.find(
    (node) => node.data.label === 'В ожидании информации'
  );
  const nodeStatusWaitingClose = nodes.find(
    (node) => node.data.label === 'В ожидании закрытия'
  );

  const isProtectedSource =
    connection.source === nodeStatusNew?.id ||
    connection.source === nodeStatusWaitingInfo?.id ||
    connection.source === nodeStatusWaitingClose?.id;

  const isProtectedTarget =
    connection.target === nodeStatusClose?.id ||
    connection.target === nodeStatusWaitingInfo?.id;

  return (
    !isIdenticalNode &&
    !isIdenticalEdge &&
    !isProtectedSource &&
    !isProtectedTarget
  );
};

import cn from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import { ArrowLeftIcon, CloseIcon } from 'assets/icons';
import { IconButtonWrapper } from 'components/IconButtonWrapper';
import { Loader } from 'components/Loader';

import { getLoadingDetail } from '../../ducks/selectors';
import { SolutionType } from '../../types';
import { SimilarArticleContent } from '../SimilarArticleContent';
import { SimilarSolutionHeader } from '../SimilarSolutionHeader';
import { SimilarTicketContent } from '../SimilarTicketContent';

import styles from './SimilarSolution.module.scss';

interface Props {
  type: SolutionType;
  isOpen: boolean;
  toggleIsOpen(): void;
  toggleParentModal(): void;
}

export const SimilarSolution: React.FC<Props> = ({
  type,
  isOpen,
  toggleIsOpen,
  toggleParentModal,
}) => {
  const loading = useSelector(getLoadingDetail);

  const isTicketTab = type === SolutionType.TICKET;

  const closeAllModalsHandler = () => {
    toggleIsOpen();
    toggleParentModal();
  };

  if (!isOpen) {
    return null;
  }

  const loader = loading && <Loader />;

  const content = !loading && (
    <>
      <SimilarSolutionHeader toggleIsOpen={toggleIsOpen} type={type} />
      {isTicketTab ? <SimilarTicketContent /> : <SimilarArticleContent />}
    </>
  );

  return (
    <div
      className={styles.similarSolution}
      onMouseDown={toggleIsOpen}
      aria-hidden="true"
    >
      <div
        className={styles.similarSolution__container}
        onMouseDown={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <div className={styles.similarSolution__header}>
          <h3 className={styles.similarSolution__headerTitle}>
            Похожие решения
          </h3>
          <IconButtonWrapper
            className={styles.similarSolution__headerCloseButton}
            onClick={closeAllModalsHandler}
            icon={
              <CloseIcon className={styles.similarSolution__headerCloseIcon} />
            }
          />
          <IconButtonWrapper
            className={styles.similarSolution__headerCloseButtonMobile}
            onClick={closeAllModalsHandler}
            icon={
              <ArrowLeftIcon
                className={styles.similarSolution__headerCloseIcon}
              />
            }
          />
        </div>
        <div
          className={cn(styles.similarSolution__content, {
            [styles.similarSolution__content_loading]: loading,
          })}
        >
          {loader}
          {content}
        </div>
      </div>
    </div>
  );
};

import cn from 'clsx';
import React, { useState } from 'react';

import {
  CountRecords,
  Props as CountRecordsProps,
} from 'components/CountRecords';
import { Pagination, Props as PaginationProps } from 'components/Pagination';
import {
  ApproveOrCancel,
  Props as ApproveOrCancelProps,
} from 'core/modals/ApproveOrCancel';
import { Info, Props as InfoProps } from 'core/modals/Info';

import { TableHeader, Props as TableHeaderProps } from '../TableHeader';

import styles from './TableContainer.module.scss';

interface Props {
  classNameRoot?: string;
  classNameContent?: string;
  headerProps?: TableHeaderProps;
  contentTable?: JSX.Element;
  countRecordsProps?: CountRecordsProps;
  paginationProps: PaginationProps;
  approveOrCancelProps?: ApproveOrCancelProps;
  infoProps?: InfoProps;
  isWithOutsideClick?: boolean;
}

export const TableContainer: React.FC<Props> = ({
  classNameRoot,
  classNameContent,
  headerProps,
  contentTable,
  countRecordsProps,
  paginationProps,
  approveOrCancelProps,
  infoProps,
  isWithOutsideClick,
}) => {
  const [tableOpened, setTableOpened] = useState(true);

  const loader = headerProps?.loading && (
    <>
      <div className={styles.tableContainer__progressLine} />
      <div className={styles.tableContainer__thumb} />
    </>
  );

  return (
    <div
      className={cn(styles.tableContainer, classNameRoot, {
        [styles.tableContainer_close]: !tableOpened,
      })}
    >
      {headerProps && (
        <TableHeader
          {...headerProps}
          tableOpened={tableOpened}
          handleTableOpen={setTableOpened}
          isWithOutsideClick={isWithOutsideClick}
        />
      )}
      <div
        className={cn(
          styles.tableContainer__content,
          { [styles.tableContainer__content_loading]: headerProps?.loading },
          classNameContent
        )}
      >
        <div
          className={cn(styles.tableContainer__background, {
            [styles.tableContainer__background_show]: headerProps?.loading,
          })}
        />
        {contentTable}
        {loader}
        <div className={styles.tableContainer__pagination}>
          {countRecordsProps && (
            <CountRecords
              {...countRecordsProps}
              className={styles.tableContainer__countRecords}
            />
          )}
          {paginationProps && <Pagination {...paginationProps} />}
        </div>
      </div>
      {approveOrCancelProps && <ApproveOrCancel {...approveOrCancelProps} />}
      {infoProps && <Info {...infoProps} />}
    </div>
  );
};

import { convertDateToString } from 'components/DatePicker';

export const getCurrentDate = (from: Date, to: Date) => {
  if (from && to) {
    const currentFrom = convertDateToString(from, false);
    const currentTo = convertDateToString(to, false);

    return `${currentFrom} - ${currentTo}`;
  }
  return '';
};

import React, { useEffect } from 'react';

interface Props {
  pageTitle?: string;
}

export const PageTitleLayout: React.FC<Props> = ({ children, pageTitle }) => {
  useEffect(() => {
    document.title = pageTitle || '';
  }, [pageTitle]);

  return <>{children}</>;
};

import {
  convertFromDateFormat,
  convertToDateFormat,
} from 'components/DatePicker';
import { getValueFromValueType } from 'components/Select';
import { SelectOption } from 'components/Select/types';
import { Priority } from 'core/types';

import {
  TicketsFilterForRender,
  TicketsFilterForRequest,
  TicketTabType,
} from '../types';

const getValue = (values?: (string | SelectOption)[]) => {
  return (values || []).map((item) =>
    typeof item === 'string' ? item : getValueFromValueType(item)
  );
};

interface Props {
  filter: TicketsFilterForRender;
  isAdmin: boolean;
  ticketTab?: TicketTabType;
  userId?: string;
}

export const getPrepareFilterTicketsToRequest = ({
  filter,
  isAdmin,
  ticketTab,
  userId,
}: Props): TicketsFilterForRequest => {
  const {
    number,
    theme,
    priorities,
    statuses,
    systemIds,
    specialistIds,
    clientIds,
    memberIds,
    dateSolve,
    workGroupIds,
    tagName,
    onlyActive,
    contractIds,
    typeIds,
    dateCreate,
    customFields,
  } = filter;

  const isMyTicketsPage = ticketTab === TicketTabType.MY_TICKETS;
  const currentUserIdArray = userId ? [userId] : [];

  return {
    dateSolveFrom: convertFromDateFormat(dateSolve),
    dateSolveTo: convertToDateFormat(dateSolve),
    number,
    theme,
    priorities: (priorities || []).map(
      (item) => getValueFromValueType(item) as Priority
    ),
    statuses: getValue(statuses),
    systemIds: getValue(systemIds),
    clientIds: getValue(clientIds),
    specialistIds: getValue(specialistIds),
    ...(isMyTicketsPage && {
      memberIds: memberIds || currentUserIdArray,
    }),
    workGroupIds: isAdmin ? [] : workGroupIds,
    tagName,
    onlyActive,
    contractIds,
    dateCreateFrom: convertFromDateFormat(dateCreate),
    dateCreateTo: convertToDateFormat(dateCreate),
    typeIds: getValue(typeIds),
    customFields,
  } as TicketsFilterForRequest;
};

import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { CloseIcon, PlusIcon } from 'assets/icons';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { RadioSlide } from 'components/RadioSlide';
import { Select } from 'components/Select';
import { Tooltip } from 'components/Tooltip';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { ConditionType } from 'features/Rules/types';
import { getAttributesValueOptions } from 'features/Rules/utils';

import { INITIAL_ATTRIBUTES, RADIO_TABS } from '../../constants';
import { useRuleForm } from '../../hooks/useRuleForm';

import styles from './RuleModalContent.module.scss';

interface Props {
  ruleForm: ReturnType<typeof useRuleForm>;
  onReset(): void;
}

export const RuleModalContent: React.FC<Props> = ({ onReset, ruleForm }) => {
  const { state, methods } = ruleForm;

  const {
    control,
    reset,
    setValue,
    onAddAttributeHandler,
    onDeleteAttributeHandler,
    onChangeAttribute,
    onChangeValue,
    setAttributes,
    setAttributeIsChanged,
    formSubmitHandler,
  } = methods;

  const {
    attributes,
    errors,
    isDisabledSubmit,
    isValidAttributes,
    attributesFields,
    attributesOptions,
    conditionsOptions,
    executorOptions,
    titleInputOptions,
    numberInputOptions,
  } = state;

  useEffect(() => {
    return () => {
      setAttributes(INITIAL_ATTRIBUTES);
      setAttributeIsChanged(false);
      reset();
    };
  }, []);

  const attributesRows = attributes.map(
    ({ id, attribute, isActive, value }) => {
      const attributesValueOptions = getAttributesValueOptions({
        attributesFields,
        attribute,
      });

      return (
        isActive && (
          <div key={id} className={styles.ruleModalContent__row}>
            <Select
              label="Атрибут"
              value={attribute}
              onChange={onChangeAttribute(id)}
              options={attributesOptions}
              mobileModalTitle="атрибут"
              className={styles.ruleModalContent__selectAttribute}
            />
            <Select
              label="Значение"
              value={value}
              onChange={onChangeValue(id)}
              options={attributesValueOptions}
              mobileModalTitle="значение"
              disabled={!attribute}
              isMulti
            />
            <button
              onClick={onDeleteAttributeHandler(id)}
              className={styles.ruleModalContent__closeButton}
            >
              <CloseIcon className={styles.ruleModalContent__closeButtonIcon} />
            </button>
          </div>
        )
      );
    }
  );

  return (
    <form className={styles.ruleModalContent}>
      <div>
        <Input
          {...titleInputOptions}
          label="Название"
          error={!!errors.title}
          errorMessage={errors.title?.message}
          className={styles.ruleModalContent__titleInput}
        />
        <Typography
          variant={TypographyVariants.h4}
          className={styles.ruleModalContent__subTitle}
        >
          Если тикет имеет следующие атрибуты
        </Typography>
        {attributesRows}
        <Button
          type="button"
          onClick={onAddAttributeHandler}
          icon={<PlusIcon />}
          appearance="flat"
          disabled={!isValidAttributes}
          size={Size.xs}
        >
          Добавить атрибут
        </Button>
        <div className={styles.ruleModalContent__separator}>И</div>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.ruleModalContent__subTitle}
        >
          Если
        </Typography>
        <div className={styles.ruleModalContent__row}>
          <Controller
            control={control}
            name="conditionType"
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return (
                <div className={styles.ruleModalContent__row}>
                  <Select<ConditionType>
                    label="Условие"
                    value={field.value}
                    onChange={field.onChange}
                    options={conditionsOptions}
                    mobileModalTitle="условие"
                    className={styles.ruleModalContent__selectCondition}
                    isTooltip={false}
                  />
                </div>
              );
            }}
          />
          <Input
            {...numberInputOptions}
            mask="99:99"
            label="Время"
            defaultValue="00:00"
          />
        </div>

        <Typography
          variant={TypographyVariants.h4}
          className={styles.ruleModalContent__subTitle}
        >
          Тогда
        </Typography>
        <Controller
          control={control}
          name="executorType"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <RadioSlide
                items={RADIO_TABS}
                value={field.value}
                onChange={(e) => {
                  setValue('executor', null);
                  field.onChange(e);
                }}
                className={styles.ruleModalContent__radio}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="executor"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <Select
                label="Исполнитель"
                value={field.value}
                onChange={field.onChange}
                options={executorOptions}
                mobileModalTitle="исполнителя"
              />
            );
          }}
        />
      </div>
      <div className={styles.ruleModalContent__footer}>
        <div data-tip data-for="submit-button">
          <Button
            disabled={isDisabledSubmit}
            className={styles.ruleModalContent__button}
            onClick={formSubmitHandler}
          >
            Создать
          </Button>
        </div>
        {isDisabledSubmit && (
          <Tooltip id="submit-button" place="right">
            Остались незаполненные поля
          </Tooltip>
        )}
        <Button
          type="button"
          onClick={onReset}
          appearance="flat"
          className={styles.ruleModalContent__button}
        >
          Отмена
        </Button>
      </div>
    </form>
  );
};

import { Reducer } from 'redux';

import { WorkGroup } from 'core/types';
import {
  AccessedRoute,
  CurrentUser,
  CurrentUserOrganization,
} from 'features/Auth/types';
import { ReducerMap } from 'store/types';

import {
  CurrentUserActionsTypes,
  CurrentUserTypes,
  FetchPermissionsSuccessAction,
  SetAuthorizedAction,
  SetCurrentUserAction,
  SetUserOrganizationAction,
} from './types';

export interface CurrentUserReducerState {
  user?: CurrentUser;
  accessedRoutes?: AccessedRoute[];
  workGroupsIds?: string[];
  workGroups?: WorkGroup[];
  managerWorkGroupsIds?: string[];
  managerWorkGroups?: WorkGroup[];
  actionList?: string[];
  isAuthorized?: boolean;
  organization?: CurrentUserOrganization;
}

const initialState: CurrentUserReducerState = {
  accessedRoutes: [],
  workGroupsIds: [],
  managerWorkGroupsIds: [],
  actionList: [],
  isAuthorized: false,
};

const currentUserReducerMap: ReducerMap<
  CurrentUserReducerState,
  CurrentUserActionsTypes
> = {
  [CurrentUserTypes.SET_CURRENT_USER]: (state, action) => {
    const { payload } = action as SetCurrentUserAction;
    return {
      ...state,
      user: payload,
    };
  },
  [CurrentUserTypes.SET_AUTHORIZED]: (state, action) => {
    const { payload } = action as SetAuthorizedAction;
    return {
      ...state,
      isAuthorized: payload,
    };
  },
  [CurrentUserTypes.SET_USER_ORGANIZATION]: (state, action) => {
    const { payload } = action as SetUserOrganizationAction;
    return {
      ...state,
      organization: payload,
    };
  },
  [CurrentUserTypes.FETCH_USER_PERMISSIONS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchPermissionsSuccessAction;
    const { accessedRoutes, actionList, workGroups } = payload;
    const workGroupsIds = workGroups?.map((workGroup) => workGroup.id);
    const managerWorkGroups = workGroups?.filter(
      (workGroup) => workGroup.isManager
    );
    const managerWorkGroupsIds = managerWorkGroups?.map(
      (workGroup) => workGroup.id
    );

    return {
      ...state,
      accessedRoutes,
      actionList,
      workGroupsIds,
      managerWorkGroupsIds,
      workGroups,
      managerWorkGroups,
    };
  },
};

export const currentUser: Reducer<
  CurrentUserReducerState,
  CurrentUserActionsTypes
> = (state = initialState, action) => {
  const reducer = currentUserReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};

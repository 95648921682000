import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addEdge,
  Connection,
  Edge,
  Node,
  OnEdgeUpdateFunc,
  updateEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import { ValueType } from 'components/Select/types';
import { getTheme } from 'core/ducks/selectors';
import { TicketType } from 'core/types';
import {
  fetchTicketTypesForSystemRequest,
  fetchWorkflowRequest,
  saveWorkflow,
  setCurrentSystemId,
} from 'features/StatusesBuilder/ducks/actions';

import {
  getSystemId,
  getSystems,
  getSystemsSelectList,
  getTicketTypesSelectList,
  getWorkflow,
} from '../ducks/selectors';
import { EdgeType, NodeType } from '../types';
import { getIsValidConnection } from '../utils';

interface Props {
  isSmallWindowWidth: boolean | null;
}

export const useBuilder = ({ isSmallWindowWidth }: Props) => {
  const theme = useSelector(getTheme);
  const systemsOptions = useSelector(getSystemsSelectList);
  const systemId = useSelector(getSystemId);
  const workflow = useSelector(getWorkflow);
  const ticketTypes = useSelector(getTicketTypesSelectList);
  const systems = useSelector(getSystems);

  const [isDetailStatusOpen, setIsDetailStatusOpen] = useState<boolean>(false);
  const [systemOption, setSystemOption] = useState<ValueType<string> | null>(
    null
  );

  const [ticketTypeOption, setTicketTypeOption] = useState<TicketType | null>(
    null
  );
  const [nodes, setNodes, onNodesChange] = useNodesState<NodeType>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeType>([]);

  const dispatch = useDispatch();

  const onConnect = useCallback(
    (params: Edge | Connection) => setEdges((els) => addEdge(params, els)),
    [setEdges]
  );

  const selectedEdge = useMemo(
    () => edges.find((edge: Edge<EdgeType>) => edge.selected),
    [edges]
  );

  const selectedNode = useMemo(
    () => nodes.find((node) => node.selected),
    [nodes]
  );

  const onEdgeUpdate: OnEdgeUpdateFunc = useCallback(
    (oldEdge: Edge<EdgeType>, newConnection) => {
      const isValidConnection = getIsValidConnection(
        newConnection,
        edges,
        nodes,
        oldEdge
      );
      if (isValidConnection && !oldEdge.data?.protected) {
        setEdges((els) => updateEdge(oldEdge, newConnection, els));
      }
    },
    [getIsValidConnection, edges]
  );

  const onEdgeDelete = useCallback(() => {
    if (selectedEdge && !selectedEdge.data?.protected) {
      setEdges((prevEdges) =>
        prevEdges.filter((prevEdge) => prevEdge.id !== selectedEdge.id)
      );
    }
  }, [selectedEdge, setEdges]);

  const onCreateNode = useCallback(
    (node: Node<NodeType>) => {
      setNodes((prevNodes) => [...prevNodes, node]);
    },
    [setNodes]
  );

  const onCreateEdge = useCallback(
    (edge: Edge<EdgeType>) => {
      setEdges((prevEdges) => [...prevEdges, edge]);
    },
    [setEdges]
  );

  const toggleIsDetailStatusOpen = useCallback(() => {
    setIsDetailStatusOpen((prevState) => !prevState);
  }, [setIsDetailStatusOpen]);

  const onResetSelectedNode = useCallback(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        return { ...node, selected: false };
      })
    );
  }, [setNodes]);

  const onCloseHandler = useCallback(
    () => setIsDetailStatusOpen(false),
    [setIsDetailStatusOpen]
  );

  const onEditNodeHandler = useCallback(
    (id: string, data: NodeType) => {
      setNodes((prevNodes) =>
        prevNodes.map((prevNode) => {
          if (prevNode.id === id) {
            return { ...prevNode, data };
          }
          return prevNode;
        })
      );
    },
    [setNodes]
  );

  const onDeleteNodeHandler = useCallback(
    (id?: string) => {
      setNodes((prevNodes) =>
        prevNodes.filter((prevNode) => prevNode.id !== id)
      );
      setEdges((prevEdges) =>
        prevEdges.filter(
          (prevEdge) => prevEdge.source !== id && prevEdge.target !== id
        )
      );
    },
    [setNodes, setEdges]
  );

  const isChanges = useMemo(() => {
    const preparedNodes = nodes.map((node) => {
      return _.omit(node, 'width', 'height', 'selected', 'dragging');
    });
    const preparedEdges = edges.map((edge) => {
      return _.omit(edge, 'selected', 'dragging');
    });

    const isIdenticalNodes = _.isEqual(workflow?.nodes, preparedNodes);
    const isIdenticalEdges = _.isEqual(workflow?.edges, preparedEdges);

    return (
      (!isIdenticalNodes || !isIdenticalEdges) && workflow && !!ticketTypeOption
    );
  }, [nodes, edges, workflow]);

  const isCreateDisabled = useMemo(
    () => isDetailStatusOpen || !!selectedNode || !ticketTypeOption,
    [isDetailStatusOpen, selectedNode, ticketTypeOption]
  );

  const onSetBuilderValues = useCallback(() => {
    if (workflow) {
      setNodes(workflow.nodes);
      setEdges(workflow.edges);
      return;
    }
    setNodes([]);
    setEdges([]);
  }, [setNodes, setEdges, workflow]);

  const onSaveBuilderValues = useCallback(() => {
    if (workflow) {
      dispatch(
        saveWorkflow({
          systemId: workflow.systemId,
          version: workflow.version,
          typeId: ticketTypeOption?.value,
          nodes,
          edges,
          systemTitle: systems?.find((system) => system.id === systemId)?.title,
        })
      );
    }
  }, [nodes, edges, workflow, systemOption]);

  const isIsolatedNode = useMemo(() => {
    return !!nodes
      .filter((node) => {
        const title = node.data.label;
        return title !== 'Новый' && title !== 'Закрыт';
      })
      .find((node) => {
        const sourceEdge = edges.find((edge) => edge.source === node.id);
        const targetEdge = edges.find((edge) => edge.target === node.id);

        return !sourceEdge || !targetEdge;
      });
  }, [nodes, edges]);

  useEffect(() => {
    if (systemOption) {
      dispatch(
        setCurrentSystemId(
          !Array.isArray(systemOption) ? systemOption.value : undefined
        )
      );
    }
  }, [systemOption]);

  useEffect(() => {
    setSystemOption(null);
  }, [isSmallWindowWidth]);

  useEffect(() => {
    if (systemId) {
      dispatch(fetchTicketTypesForSystemRequest(systemId));
      setNodes([]);
      setEdges([]);
      setTicketTypeOption(null);
    }
  }, [systemId]);

  useEffect(() => {
    if (ticketTypeOption) {
      dispatch(
        fetchWorkflowRequest({
          systemId: systemId || '',
          typeId: ticketTypeOption.value,
        })
      );
    }
  }, [ticketTypeOption]);

  useEffect(() => {
    onSetBuilderValues();
  }, [workflow]);

  return {
    methods: {
      onNodesChange,
      onEdgesChange,
      onConnect,
      onEdgeUpdate,
      onEdgeDelete,
      onCreateNode,
      onCreateEdge,
      toggleIsDetailStatusOpen,
      onResetSelectedNode,
      onCloseHandler,
      onEditNodeHandler,
      onDeleteNodeHandler,
      onSetBuilderValues,
      setSystemOption,
      setTicketTypeOption,
      onSaveBuilderValues,
    },
    state: {
      theme,
      isDetailStatusOpen,
      selectedNode,
      selectedEdge,
      nodes,
      edges,
      isChanges,
      systemsOptions,
      systemOption,
      ticketTypeOption,
      systemId,
      ticketTypes,
      isCreateDisabled,
      isIsolatedNode,
    },
  };
};

import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import { getValueFromValueType } from 'components/Select';
import { setAlert } from 'core/ducks/actions';
import { createSuccessAlert } from 'core/layouts/AlertsLayout';
import { CustomField } from 'core/types';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import { CustomFieldsFilter, CustomFieldsFilterToRequest } from '../types';

import {
  fetchCurrentCustomField,
  fetchCustomFieldsSuccess,
  hideCustomFieldsLoading,
  resetCurrentCustomField,
  resetCustomFieldState,
  setCurrentCustomFieldId,
  setCurrentCustomFieldSuccess,
  showCustomFieldsLoading,
} from './actions';
import { request } from './api/requests';
import { getFilterValues, getPropsCustomFields } from './selectors';
import {
  CreateCustomFieldAction,
  CustomFields,
  DeleteCustomFieldRequestAction,
  UpdateCustomFieldAction,
} from './types';

export const getFilterCustomFieldsRequest = (
  filter: CustomFieldsFilter
): CustomFieldsFilterToRequest => {
  const { organizationId, systemId, customFieldType, accessType } = filter;
  const organizationIdValue = getValueFromValueType(organizationId);
  const systemIdValue = getValueFromValueType(systemId);
  const typeValue = getValueFromValueType(customFieldType);
  const accessTypeValue = getValueFromValueType(accessType);
  return {
    ...filter,
    organizationId: organizationIdValue,
    systemId: systemIdValue,
    customFieldType: typeValue,
    accessType: accessTypeValue,
  };
};

function* customFieldsFetch() {
  try {
    const {
      pageNum,
      pageSize,
      sortCustomFields,
    }: ReturnType<typeof getPropsCustomFields> = yield select(
      getPropsCustomFields
    );
    const filter: ReturnType<typeof getFilterValues> = yield select(
      getFilterValues
    );
    yield put(showCustomFieldsLoading());
    const customField: ResponseWithMeta<CustomField[]> = yield call(
      request.fetchCustomFields,
      pageNum,
      pageSize,
      sortCustomFields,
      getFilterCustomFieldsRequest(filter)
    );
    yield put(fetchCustomFieldsSuccess(customField));
    yield put(hideCustomFieldsLoading());
  } catch (e) {
    createError(e);
    yield put(hideCustomFieldsLoading());
  }
}

function* currentCustomFieldFetch({
  payload,
}: ReturnType<typeof fetchCurrentCustomField>) {
  try {
    if (payload) {
      const result = (yield call(
        request.fetchCurrentCustomField,
        payload
      )) as CustomField;
      yield put(setCurrentCustomFieldSuccess(result));
    }
  } catch (e) {
    createError(e);
  }
}

function* customFieldCreate({ payload }: CreateCustomFieldAction) {
  try {
    const {
      pageNum,
      pageSize,
      sortCustomFields,
    }: ReturnType<typeof getPropsCustomFields> = yield select(
      getPropsCustomFields
    );
    yield put(showCustomFieldsLoading());
    const newCustomField: CustomField = yield call(
      request.createCustomField,
      payload
    );
    yield put(
      setAlert(
        createSuccessAlert(
          `Кастомное поле для системы "${payload.system?.title}" успешно создано`
        )
      )
    );
    const customFields: ResponseWithMeta<CustomField[]> = yield call(
      request.fetchCustomFields,
      pageNum,
      pageSize,
      sortCustomFields
    );
    yield put(fetchCustomFieldsSuccess(customFields));
    if (newCustomField.id) {
      yield all([
        put(setCurrentCustomFieldId(newCustomField.id)),
        put(fetchCurrentCustomField(newCustomField.id)),
      ]);
    }
    yield put(hideCustomFieldsLoading());
  } catch (e) {
    createError(e);
    yield put(hideCustomFieldsLoading());
  }
}

function* customFieldUpdate({ payload }: UpdateCustomFieldAction) {
  try {
    yield call(request.updateCustomField, payload);
    yield put(
      setAlert(
        createSuccessAlert(
          `Кастомное поле для системы "${payload.system?.title}" успешно отредактировано`
        )
      )
    );
    yield put(resetCustomFieldState());
    yield call(customFieldsFetch);
  } catch (e) {
    createError(e);
  }
}

function* deleteCustomField({ payload }: DeleteCustomFieldRequestAction) {
  try {
    const { id, systemTitle } = payload;
    yield call(request.deleteCustomField, id);
    yield put(
      setAlert(
        createSuccessAlert(
          `Кастомное поле для системы "${systemTitle}" успешно удалено`
        )
      )
    );
    yield put(resetCurrentCustomField());
    yield call(customFieldsFetch);
  } catch (e) {
    createError(e);
    yield put(hideCustomFieldsLoading());
  }
}

export function* customFieldsSaga(): Generator<StrictEffect> {
  yield takeEvery(CustomFields.FETCH_CUSTOM_FIELDS_REQUEST, customFieldsFetch);
  yield takeEvery(
    CustomFields.FETCH_CUSTOM_FIELD_REQUEST,
    currentCustomFieldFetch
  );
  yield takeEvery(CustomFields.CREATE_CUSTOM_FIELD, customFieldCreate);
  yield takeEvery(CustomFields.DELETE_CUSTOM_FIELD_REQUEST, deleteCustomField);
  yield takeEvery(CustomFields.UPDATE_CUSTOM_FIELD, customFieldUpdate);
}

import cn from 'clsx';
import React, { forwardRef } from 'react';

import styles from './Card.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const Card = forwardRef<HTMLDivElement, Props>(
  ({ className, children, ...otherProps }, ref) => {
    return (
      <div className={cn(styles.card, className)} {...otherProps} ref={ref}>
        {children}
      </div>
    );
  }
);

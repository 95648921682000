import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getScreenWidth } from 'core/ducks/selectors';
import { ScreenWidth } from 'core/types';
import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { resetContractsState } from 'features/Contracts/ducks/actions';
import { fetchRoleByIdSuccess } from 'features/Roles/ducks/actions';
import { checkObjectIdentity } from 'utils';

import {
  deleteCustomFieldRequest,
  fetchCurrentCustomField,
  fetchCustomFieldsRequest,
  setCurrentCustomFieldId,
  setCurrentCustomFieldsPage,
  setSizePage,
  setSortCustomFields,
} from '../ducks/actions';
import {
  getCurrentCustomField,
  getCurrentCustomFieldId,
  getCustomFields,
  getFilterValues,
  getPropsCustomFields,
} from '../ducks/selectors';

export const useCustomFieldsTableContainer = () => {
  const dispatch = useDispatch();

  const {
    pageNum,
    pageSize,
    sortCustomFields,
    loadingCustomFields,
    totalElements,
  } = useSelector(getPropsCustomFields);
  const customFields = useSelector(getCustomFields);
  const customField = useSelector(getCustomFields);
  const customFieldId = useSelector(getCurrentCustomFieldId);
  const activeCustomField = useSelector(getCurrentCustomField);
  const filterValues = useSelector(getFilterValues);
  const isAccessToCreateCustomField = useSelector(
    getIsAccessedAction(ActionList.ViewingSettings)
  );
  const isAccessToDeleteCustomField = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );

  const [isModal, setIsModal] = useState<boolean>(false);
  const screenWidth = useSelector(getScreenWidth);
  const [changeModals, setOpenChangeModals] = useState({
    isEditMode: false,
    isOpenModal: false,
  });
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const toggleIsOpenChangeModals = () => {
    setOpenChangeModals((prev) => ({
      ...prev,
      isOpenModal: !prev.isOpenModal,
    }));
  };
  const setIsEditModeModal = () => {
    setOpenChangeModals((prev) => ({ ...prev, isEditMode: true }));
  };

  const setIsCreateModeModal = () => {
    setOpenChangeModals((prev) => ({ ...prev, isEditMode: false }));
  };

  const handleCreateClick = () => {
    setIsCreateModeModal();
    toggleIsOpenChangeModals();
  };

  const handleEditClick = () => {
    setIsEditModeModal();
    toggleIsOpenChangeModals();
  };

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const isFilterActive = !checkObjectIdentity(
    filterValues as Record<string, unknown>,
    {}
  );

  const searchDisabled =
    !customFields?.length || (showFilterRow && isFilterActive);

  const toggleModal = () => {
    setIsModal(!isModal);
    setShowFilterRow(false);
  };

  const canCreateCustomField = true;

  const onRowClickFunctions = (value: string) => {
    if (customFieldId !== value) {
      dispatch(resetContractsState());
      dispatch(setCurrentCustomFieldId(value));
      dispatch(fetchCurrentCustomField(value));
    }
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentCustomFieldsPage(page));
    dispatch(fetchCustomFieldsRequest());
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePage(arg));
  };

  const handleSort = (value: string) => {
    dispatch(setSortCustomFields(value));
    dispatch(fetchCustomFieldsRequest());
  };
  const handleRowFullTableClick = (value: string) => {
    if (customFieldId !== value) {
      dispatch(setCurrentCustomFieldId(value));
      dispatch(fetchCurrentCustomField(value));
      return;
    }
    if (isMobile || isMobileSmall) {
      dispatch(setCurrentCustomFieldId(undefined));
      dispatch(fetchRoleByIdSuccess());
    }
  };

  const getCreateTitle = () => {
    return 'Создать';
  };

  const handleShowModalDelete = () => {
    if (activeCustomField?.id) {
      setShowDeleteModal(true);
    }
  };

  const handleDelete = () => {
    if (activeCustomField?.id) {
      dispatch(
        deleteCustomFieldRequest({
          id: activeCustomField.id,
          systemTitle: activeCustomField.system?.title || '',
        })
      );
    }
  };

  return {
    methods: {
      handleDelete,
      toggleDeleteModal,
      toggleIsOpenChangeModals,
      handleEditClick,
      toggleModal,
      toggleFilterRow,
      handleClickCustomField: onRowClickFunctions,
      handleChangePage,
      handleChangePageSize,
      handleSort,
      handleCreateClick,
      handleShowModalDelete,
      getCreateTitle,
      onRowClick: handleRowFullTableClick,
    },
    state: {
      pageNum,
      pageSize,
      sortCustomFields,
      totalElements,
      customFields,
      customField,
      customFieldId,
      isAccessToCreateCustomField,
      isAccessToDeleteCustomField,
      isModal,
      changeModals,
      filterValues,
      showFilterRow,
      showDeleteModal,
      loadingCustomFields,
      searchDisabled,
      canCreateCustomField,
      activeCustomField,
    },
  };
};

import React from 'react';

import { KBLayout } from '../../components/KBLayout';
import { KBMain } from '../../views/KBMain';

const KBMainPage = () => {
  return (
    <KBLayout>
      <KBMain />
    </KBLayout>
  );
};

export default KBMainPage;

import { TicketTabType } from '../types';

export const getCurrentTableType = (type: string) => {
  if (type === '/tickets') {
    return TicketTabType.MY_TICKETS;
  }
  if (type === '/tickets/all') {
    return TicketTabType.ALL_TICKETS;
  }
  return TicketTabType.MY_GROUPS_TICKETS;
};

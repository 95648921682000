import { BreadCrumb } from 'components/BreadCrumbs';
import {
  Core,
  SetAlertAction,
  SetBreadCrumbsConfigAction,
  SetHrefAction,
  SetIsContrastThemeAction,
  SetIsSystemThemeAction,
  SetRedirectPathAction,
  SetScreenWidthAction,
  SetThemeAction,
} from 'core/ducks/types';
import { Alert, ScreenWidth, Theme, ThemeActive } from 'core/types';

export const setScreenWidth = (
  screenWidth: ScreenWidth
): SetScreenWidthAction => {
  return {
    type: Core.SET_SCREEN_WIDTH,
    payload: screenWidth,
  };
};

export const setRedirectPath = (path: string | null): SetRedirectPathAction => {
  return {
    type: Core.SET_REDIRECT_PATH,
    payload: path,
  };
};

export const setTheme = (theme: Theme): SetThemeAction => {
  return {
    type: Core.SET_THEME,
    payload: theme,
  };
};

export const setIsSystemTheme = (
  systemTheme: ThemeActive
): SetIsSystemThemeAction => {
  return {
    type: Core.SET_IS_SYSTEM_THEME,
    payload: systemTheme,
  };
};

export const setIsContrastTheme = (
  contrastTheme: ThemeActive
): SetIsContrastThemeAction => {
  return {
    type: Core.SET_IS_CONTRAST_THEME,
    payload: contrastTheme,
  };
};

export const setAlert = (alert: Alert): SetAlertAction => {
  return {
    type: Core.SET_ALERT,
    payload: alert,
  };
};

export const deleteAlert = (id: string) => {
  return {
    type: Core.DELETE_ALERT,
    payload: id,
  };
};

export const setBreadCrumbsConfig = (
  breadCrumbsConfig: BreadCrumb[]
): SetBreadCrumbsConfigAction => {
  return {
    type: Core.SET_BREAD_CRUMBS_CONFIG,
    payload: breadCrumbsConfig,
  };
};

export const setHref = (href: string): SetHrefAction => {
  return {
    type: Core.SET_HREF,
    payload: href,
  };
};

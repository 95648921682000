import cn from 'clsx';
import React from 'react';

import styles from './PopoverItemWrapper.module.scss';

interface Props {
  className?: string;
}

export const PopoverItemWrapper: React.FC<Props> = ({
  children,
  className,
}) => {
  return (
    <div className={cn(styles.popoverItemWrapper, className)}>{children}</div>
  );
};

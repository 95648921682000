import cn from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import { MainLayout } from 'core/layouts/MainLayout';

import { ProfileUser } from '../../components/ProfileUser';
import { getNotificationsProfile } from '../../ducks/selectors';

import styles from './ProfileLayout.module.scss';

export const ProfileLayout: React.FC = React.memo(({ children }) => {
  const notifications = useSelector(getNotificationsProfile);

  return (
    <MainLayout
      title="Профиль"
      withHeader={false}
      classNameRoot={cn(styles.profileLayout, {
        [styles.profileLayout_noData]: !notifications.length,
      })}
    >
      <div className={styles.profileLayout__wrapper}>
        <ProfileUser className={styles.profileLayout__user} />
        {children}
      </div>
    </MainLayout>
  );
});

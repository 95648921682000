import React from 'react';

import { MainLayout } from 'core/layouts/MainLayout';
import { Header } from 'features/Desktop/components/Header';

import { DesktopHistory as DesktopHistoryContent } from '../../views/DesktopHistory';

import styles from './DesktopHistory.module.scss';

const DesktopHistory = () => {
  return (
    <MainLayout customHeader={<Header />}>
      <DesktopHistoryContent className={styles.desktopHistory__content} />
    </MainLayout>
  );
};

export default DesktopHistory;

import { CustomFieldType } from 'components/types';

export const FIELD_NAMES_BY_CUSTOM_FIELD_TYPE = {
  [CustomFieldType.FIELD_DATE]: 'date',
  [CustomFieldType.FIELD_FLAG]: 'flag',
  [CustomFieldType.FIELD_TEXT]: 'text',
};

export const SYSTEM_TICKET_INFO = 'SYSTEM_TICKET_INFO';

export const STEP_ZOOM = 0.45;

export const CUSTOM_STYLE_MAIN_CONTENT = { width: '100%', height: '100%' };

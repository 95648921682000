import cn from 'clsx';
import React, { forwardRef } from 'react';

import { TabItem } from 'components/types';

import styles from './RadioColors.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  classNameTitleLabel?: string;
  classNameIconLabel?: string;
  classNameLabelBlock?: string;
  items: TabItem[];
}

export const RadioColors = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { className, items, classNameLabelBlock, disabled, ...otherProps } =
    props;

  const itemsEls = items.map(({ value }) => (
    <label
      key={String(value)}
      className={cn(styles.radioColors__label, {
        [styles.radioColors__label_disabled]: disabled,
      })}
    >
      <input
        ref={ref}
        value={value}
        type="radio"
        disabled={disabled}
        className={styles.radioColors__input}
        {...otherProps}
      />
      <div className={styles.radioColors__item}>
        <span
          className={classNameLabelBlock}
          style={{ backgroundColor: `var(--${value})` }}
        />
      </div>
    </label>
  ));

  return <div className={cn(styles.radioColors, className)}>{itemsEls}</div>;
});

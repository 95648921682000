import AdminContractsPage from 'features/Contracts/pages/AdminContracts/AdminContracts';
import CustomFields from 'features/CustomFields';
import DesktopCurrentStatePage from 'features/Desktop/pages/DesktopCurrentState';
import DesktopEmployeesPage from 'features/Desktop/pages/DesktopEmployees/DesktopEmployees';
import DesktopHistoryPage from 'features/Desktop/pages/DesktopHistory/DesktopHistory';
import DesktopKpiPage from 'features/Desktop/pages/DesktopKpi/DesktopKpi';
// eslint-disable-next-line max-len
// import JiraIntegrationsPage from 'features/JiraIntegrations/pages/AdminJiraIntegrations/AdminJiraIntegrations';
import { Organizations } from 'features/Organizations';
import ProfileNews from 'features/Profile/components/ProfileNews';
import ProfileNotifications from 'features/Profile/components/ProfileNotifications';
import { PROFILE_TITLE, RedirectComponent } from 'features/Profile/config';
import ProfileSettingsPage from 'features/Profile/pages/ProfileSettings';
import ProfileTelegramPage from 'features/Profile/pages/ProfileTelegram';
import AdminPowersPage from 'features/Responsibilities/pages/AdminPowers/AdminPowers';
import AdminRolesPage from 'features/Roles/pages/AdminRoles/AdminRoles';
import AdminRulesPage from 'features/Rules/pages/AdminRules';
import StatusesBuilder from 'features/StatusesBuilder/pages/StatusesBuilder';
import AdminSystemsPage from 'features/Systems/pages/AdminSystems/AdminSystems';
import AdminTriggersPage from 'features/Triggers/pages/AdminTriggers';
import AdminUsersPage from 'features/Users/pages/AdminUsers/AdminUsers';
import AdminGroupsPage from 'features/WorkGroups/pages/AdminGroups/AdminGroups';
import { RouterConfig } from 'routes/types';
import {
  getAdminsTitle,
  getDesktopTitle,
  getKnowledgeBaseTitle,
  getProfileTitle,
  getStorageTitle,
  getTicketsTitle,
} from 'routes/utils';

import CompaniesPage from '../features/Companies/pages/Companies';
import ContractPage from '../features/Contract/pages/Contract';
import CreateTicketPage from '../features/CreateTicket/pages/CreateTicket';
// import AdminSettings from '../features/CustomFields/pages/AdminSettings';
// import DesktopListPage from '../features/Desktop/pages/DesktopList';
import KnowledgeBaseArticlePage from '../features/KnowledgeBase/pages/KBArticlePage';
import KnowledgeBaseCreate from '../features/KnowledgeBase/pages/KBCreateArticlePage';
import KnowledgeBaseEditArticlePage from '../features/KnowledgeBase/pages/KBEditArticlePage';
import KnowledgeBasePage from '../features/KnowledgeBase/pages/KBMainPage';
import KnowledgeBaseTable from '../features/KnowledgeBase/pages/KBTablePage';
import NotFoundPage from '../features/NotFound';
import OrganizationPage from '../features/Organization/pages/Organization';
import AdminPage from '../features/Organizations/pages/Admin';
// import AdminList from '../features/Organizations/pages/AdminList';
// import ProfilePage from '../features/Profile/pages/Profile';
import Storage from '../features/Storage/pages/Storage';
import Storages from '../features/Storages/pages/Storages';
import SupplementaryAgreementPage from '../features/SupplementaryAgreement/pages/SupplementaryAgreement';
import SystemPage from '../features/System/pages/System';
import TicketPage from '../features/Ticket/pages/Ticket';
import TicketsPage from '../features/Tickets/pages/Tickets';
import UserPage from '../features/User/pages/User';
import WorkGroupPage from '../features/WorkGroup/pages/WorkGroup';

import { RouterHref } from './routerHref';

export const routerConfig: RouterConfig[] = [
  {
    href: RouterHref.Companies,
    component: CompaniesPage,
    withPermission: false,
    breadCrumbTitle: 'Главная',
  },
  {
    href: RouterHref.Tickets,
    component: TicketsPage,
    getPageTitle: getTicketsTitle,
    breadCrumbTitle: 'Тикеты',
  },
  {
    href: RouterHref.TicketsAll,
    component: TicketsPage,
    getPageTitle: getTicketsTitle,
    breadCrumbTitle: 'Все тикеты',
  },
  {
    href: RouterHref.TicketsGroup,
    component: TicketsPage,
    getPageTitle: getTicketsTitle,
    breadCrumbTitle: 'Тикеты группы',
  },
  {
    href: RouterHref.DesktopKpi,
    component: DesktopKpiPage,
    getPageTitle: getDesktopTitle,
    breadCrumbTitle: 'Рабочий стол',
    withPermission: false,
  },
  {
    href: RouterHref.DesktopCurrentState,
    component: DesktopCurrentStatePage,
    getPageTitle: getDesktopTitle,
    breadCrumbTitle: 'Текущее состояние',
  },
  {
    href: RouterHref.DesktopHistory,
    component: DesktopHistoryPage,
    getPageTitle: getDesktopTitle,
    breadCrumbTitle: 'История',
  },
  {
    href: RouterHref.DesktopEmployees,
    component: DesktopEmployeesPage,
    getPageTitle: getDesktopTitle,
    breadCrumbTitle: 'Сотрудники',
  },
  // {
  //   href: RouterHref.DesktopKpi,
  //   component: DesktopListPage,
  //   pageTitle: getDesktopTitle('Текущее состояние'),
  //   exact: false,
  //   withPermission: false,
  //   breadCrumbTitle: 'Текущее состояние',
  // },
  {
    href: RouterHref.CreateTicket,
    component: CreateTicketPage,
    getPageTitle: getTicketsTitle,
    breadCrumbTitle: 'Создать тикет',
  },
  {
    href: RouterHref.TicketCard,
    component: TicketPage,
    getPageTitle: getTicketsTitle,
    breadCrumbTitle: 'Карточка тикета',
  },
  {
    href: RouterHref.Admin,
    component: AdminPage,
    getPageTitle: getAdminsTitle,
    withPermission: false,
    breadCrumbTitle: 'Админ',
  },
  {
    href: RouterHref.AdminOrganizations,
    component: Organizations,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Структура',
  },
  {
    href: RouterHref.AdminContracts,
    component: AdminContractsPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Контракты',
  },
  {
    href: RouterHref.AdminSystems,
    component: AdminSystemsPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Системы',
  },
  {
    href: RouterHref.AdminUsers,
    component: AdminUsersPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Пользователи',
  },
  {
    href: RouterHref.AdminGroups,
    component: AdminGroupsPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Группы',
  },
  {
    href: RouterHref.AdminRoles,
    component: AdminRolesPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Роли',
  },
  {
    href: RouterHref.AdminPowers,
    component: AdminPowersPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Полномочия',
  },
  {
    href: RouterHref.AdminCustomFields,
    component: CustomFields,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Настройки',
    withPermission: false,
  },
  // {
  //   href: RouterHref.AdminJiraIntegrations,
  //   component: JiraIntegrationsPage,
  //   getPageTitle: getAdminsTitle,
  //   breadCrumbTitle: 'Интеграция c Jira',
  // },
  {
    href: RouterHref.AdminStatusesBuilder,
    component: StatusesBuilder,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Настройка статусов',
  },
  {
    href: RouterHref.AdminAssigningRules,
    component: AdminRulesPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Правила',
    withPermission: false,
  },
  {
    href: RouterHref.AdminTriggeredRules,
    component: AdminTriggersPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Триггеры',
  },
  {
    href: RouterHref.AdminCreateOrganization,
    component: OrganizationPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Создать организациию',
  },
  {
    href: RouterHref.AdminCreateUser,
    component: UserPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Создать пользователя',
  },
  {
    href: RouterHref.AdminCreateContract,
    component: ContractPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Создать контракт',
  },
  {
    href: RouterHref.AdminCreateSupplementaryAgreement,
    component: SupplementaryAgreementPage,
    getPageTitle: getAdminsTitle,
    withPermission: false,
    breadCrumbTitle: 'Создать допсоглашение',
  },
  {
    href: RouterHref.AdminCreateGroup,
    component: WorkGroupPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Создать группу',
  },
  {
    href: RouterHref.AdminCreateSystem,
    component: SystemPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Создать систему',
  },
  {
    href: RouterHref.AdminOrganizationCard,
    component: OrganizationPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка организации',
  },
  {
    href: RouterHref.AdminUserCard,
    component: UserPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка пользователя',
  },
  {
    href: RouterHref.AdminWorkGroupCard,
    component: WorkGroupPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка рабочей группы',
  },
  {
    href: RouterHref.AdminContractCard,
    component: ContractPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка контракта',
  },
  {
    href: RouterHref.AdminSupplementaryAgreementCard,
    component: SupplementaryAgreementPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка допсоглашения',
  },
  {
    href: RouterHref.AdminSystemCard,
    component: SystemPage,
    getPageTitle: getAdminsTitle,
    breadCrumbTitle: 'Карточка системы',
  },
  {
    href: RouterHref.Profile,
    component: RedirectComponent,
    getPageTitle: getProfileTitle,
    withPermission: false,
    breadCrumbTitle: 'Профиль',
  },
  {
    href: RouterHref.ProfileNotifications,
    component: ProfileNotifications,
    getPageTitle: getProfileTitle,
    exact: false,
    withPermission: false,
    breadCrumbTitle: PROFILE_TITLE,
  },
  {
    href: RouterHref.ProfileTelegram,
    component: ProfileTelegramPage,
    getPageTitle: getProfileTitle,
    withPermission: false,
    breadCrumbTitle: 'Чат бот',
  },
  {
    href: RouterHref.ProfileNews,
    component: ProfileNews,
    getPageTitle: getProfileTitle,
    exact: false,
    withPermission: false,
    breadCrumbTitle: 'Новости',
  },
  {
    href: RouterHref.ProfileSettings,
    component: ProfileSettingsPage,
    getPageTitle: getProfileTitle,
    withPermission: false,
    breadCrumbTitle: 'Настройки',
  },
  {
    href: RouterHref.KnowledgeBase,
    component: KnowledgeBasePage,
    getPageTitle: getKnowledgeBaseTitle,
    withPermission: false,
    breadCrumbTitle: 'База знаний',
  },
  {
    href: RouterHref.KnowledgeBaseCategoryTable,
    component: KnowledgeBaseTable,
    getPageTitle: getKnowledgeBaseTitle,
    withPermission: false,
    breadCrumbTitle: 'Таблица',
  },
  {
    href: RouterHref.KnowledgeBaseArticleCreate,
    component: KnowledgeBaseCreate,
    getPageTitle: getKnowledgeBaseTitle,
    breadCrumbTitle: 'Создание статьи',
  },
  {
    href: RouterHref.KnowledgeBaseArticleEdit,
    component: KnowledgeBaseEditArticlePage,
    getPageTitle: getKnowledgeBaseTitle,
    breadCrumbTitle: 'Редактирование статьи',
  },
  {
    href: RouterHref.KnowledgeBaseArticle,
    component: KnowledgeBaseArticlePage,
    getPageTitle: getKnowledgeBaseTitle,
    withPermission: false,
    breadCrumbTitle: 'Просмотр статьи',
  },
  {
    href: RouterHref.Storages,
    component: Storages,
    getPageTitle: getStorageTitle,
    breadCrumbTitle: 'Хранилища',
  },
  {
    href: RouterHref.Storage,
    component: Storage,
    getPageTitle: getStorageTitle,
  },
  {
    href: RouterHref.NotFound,
    component: NotFoundPage,
    withPermission: false,
    breadCrumbTitle: 'Страница не найдена',
  },
];

import { isNil } from 'lodash';

import { CustomFieldType } from 'components/types';

import { CustomFieldTypeForFilter } from '../types';

const getPropsCustomFieldType = (field: CustomFieldTypeForFilter) => {
  if (!field.type) {
    return null;
  }
  const { date, flag, text, type } = field;

  const fieldTypeMap = {
    [CustomFieldType.FIELD_DATE]: date,
    [CustomFieldType.FIELD_FLAG]: flag,
    [CustomFieldType.FIELD_TEXT]: text,
  };

  return fieldTypeMap[type];
};

export const getValueCustomFieldsForFilter = (
  fields?: CustomFieldTypeForFilter[]
) => {
  const customFieldsForTable: Record<string, string | boolean> = {};

  fields?.forEach((field) => {
    const value = getPropsCustomFieldType(field);

    if (!isNil(value)) {
      customFieldsForTable[field.fieldId] = value;
    }
  });

  return customFieldsForTable;
};

import { SelectOption } from 'components/Select/types';
import { Organization } from 'core/types';
import {
  Article,
  CategoryArticle,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from 'features/KnowledgeBase/types';
import { ResponseWithMeta } from 'store/types';

import {
  ArticlesMain,
  FetchArticlesMainRequestAction,
  FetchArticlesParams,
  SetArticlesMainFilterAction,
} from './types';

export const setArticlesMainFilter = (
  data: KBArticleTableFilterToRequest
): SetArticlesMainFilterAction => {
  return {
    type: ArticlesMain.SET_FILTER_ARTICLES_MAIN,
    payload: data,
  };
};

export const fetchArticlesMainRequest = (
  data: FetchArticlesParams
): FetchArticlesMainRequestAction => {
  return {
    type: ArticlesMain.FETCH_REQUEST_ARTICLES_MAIN,
    payload: data,
  };
};

export const fetchArticlesMainSuccess = (data: ResponseWithMeta<Article[]>) => {
  return {
    type: ArticlesMain.FETCH_SUCCESS_ARTICLES_MAIN,
    payload: data,
  };
};

export const fetchJoinSuccessArticlesMain = (
  data: ResponseWithMeta<Article[]>
) => {
  return {
    type: ArticlesMain.FETCH_ARTICLES_MAIN_JOIN_SUCCESS,
    payload: data,
  };
};

export const resetArticlesStateArticlesMain = () => {
  return {
    type: ArticlesMain.RESET_ARTICLES_STATE_ARTICLES_MAIN,
  };
};

export const loadingShowArticlesMain = () => {
  return {
    type: ArticlesMain.LOADING_SHOW_ARTICLES_MAIN,
  };
};

export const loadingHideArticlesMain = () => {
  return {
    type: ArticlesMain.LOADING_HIDE_ARTICLES_MAIN,
  };
};

export const fetchDefaultArticlesMainRequest = () => {
  return {
    type: ArticlesMain.FETCH_DEFAULT_ARTICLES_MAIN_REQUEST,
  };
};

export const fetchDefaultArticlesMainSuccess = (data: Article[]) => {
  return {
    type: ArticlesMain.FETCH_DEFAULT_ARTICLES_MAIN_SUCCESS,
    payload: data,
  };
};

export const fetchOrganizationsArticlesMainRequest = (
  filter: KBOrganizationsFilter
) => {
  return {
    type: ArticlesMain.FETCH_ORGANIZATIONS_REQUEST_ARTICLES_MAIN,
    payload: filter,
  };
};

export const fetchOrganizationsArticlesMainSuccess = (data: Organization[]) => {
  return {
    type: ArticlesMain.FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_MAIN,
    payload: data,
  };
};

export const setArticlesMainPage = (page: number) => {
  return {
    type: ArticlesMain.SET_ARTICLES_MAIN_PAGE,
    payload: page,
  };
};

export const organizationsLoadingShowArticlesMain = () => {
  return {
    type: ArticlesMain.ORGANIZATIONS_LOADING_SHOW_ARTICLES_MAIN,
  };
};

export const organizationsLoadingHideArticlesMain = () => {
  return {
    type: ArticlesMain.ORGANIZATIONS_LOADING_HIDE_ARTICLES_MAIN,
  };
};

export const fetchCategoriesRequestArticlesMain = (organizationId?: string) => {
  return {
    type: ArticlesMain.FETCH_CATEGORIES_REQUEST_ARTICLES_MAIN,
    payload: organizationId,
  };
};

export const fetchCategoriesSuccessArticlesMain = (
  categories: CategoryArticle[]
) => {
  return {
    type: ArticlesMain.FETCH_CATEGORIES_SUCCESS_ARTICLES_MAIN,
    payload: categories,
  };
};

export const categoriesLoadingShowArticlesMain = () => {
  return {
    type: ArticlesMain.CATEGORIES_LOADING_SHOW_ARTICLES_MAIN,
  };
};

export const categoriesLoadingHideArticlesMain = () => {
  return {
    type: ArticlesMain.CATEGORIES_LOADING_HIDE_ARTICLES_MAIN,
  };
};

export const resetArticlesMainState = () => {
  return {
    type: ArticlesMain.RESET_ARTICLES_MAIN_STATE,
  };
};

export const createCategoryRequestArticlesMain = (
  data: Partial<CategoryArticle>
) => {
  return {
    type: ArticlesMain.CREATE_CATEGORY_REQUEST_ARTICLES_MAIN,
    payload: data,
  };
};

export const editCategoryRequestArticlesMain = (data: CategoryArticle) => {
  return {
    type: ArticlesMain.EDIT_CATEGORY_REQUEST_ARTICLES_MAIN,
    payload: data,
  };
};

export const deleteCategoryRequestArticlesMain = (id: string) => {
  return {
    type: ArticlesMain.DELETE_CATEGORY_REQUEST_ARTICLES_MAIN,
    payload: id,
  };
};

export const setSelectedOrganizationArticlesMain = (
  option: SelectOption | null
) => {
  return {
    type: ArticlesMain.SET_ORGANIZATION_SELECTED_ARTICLES_MAIN,
    payload: option,
  };
};

export const editArticleRequestArticlesMain = (article: Partial<Article>) => {
  return {
    type: ArticlesMain.EDIT_ARTICLE_REQUEST_ARTICLES_MAIN,
    payload: article,
  };
};

export const deleteArticleRequestArticlesMain = (id: string) => {
  return {
    type: ArticlesMain.DELETE_ARTICLE_REQUEST_ARTICLES_MAIN,
    payload: id,
  };
};

export const resetCategoriesStateMain = () => {
  return {
    type: ArticlesMain.RESET_CATEGORIES_STATE_ARTICLES_MAIN,
  };
};

import { Contract, System, TicketTypeSettings, WorkGroup } from 'core/types';
import { ContractDetailPayload } from 'features/Contract/ducks/types';
import { ContractIdSystemIdArgs, SLAType } from 'features/Contract/types';
import { WorkGroupFilter } from 'features/WorkGroups/types';
import { ResponseWithMeta } from 'store/types';
import { Attachment } from 'types/models/attachment';

import { CreateSupplementaryAgreementType } from '../types';

export enum SupplementaryAgreementDetail {
  // детальное допсоглашение
  SUPPLEMENTARY_AGREEMENT_LOADING_SHOW = 'SUPPLEMENTARY_AGREEMENT/SUPPLEMENTARY_AGREEMENT_LOADING_SHOW',
  SUPPLEMENTARY_AGREEMENT_LOADING_HIDE = 'SUPPLEMENTARY_AGREEMENT/SUPPLEMENTARY_AGREEMENT_LOADING_HIDE',
  FETCH_SUP_AG_REQUEST = 'SUPPLEMENTARY_AGREEMENT/FETCH_SUP_AG_REQUEST',
  FETCH_SUP_AG_SUCCESS = 'SUPPLEMENTARY_AGREEMENT/FETCH_SUP_AG_SUCCESS',

  // запрос на создание
  CREATE_SUP_AG_DETAIL = 'SUPPLEMENTARY_AGREEMENT/CREATE_SUP_AG',

  // подгрузка системы для создания допсоглашения (1 система по id)
  FETCH_SYSTEM_BY_ID_REQUEST = 'SUPPLEMENTARY_AGREEMENT/FETCH_SYSTEM_BY_ID_REQUEST_SUP_AG_DETAIL',
  FETCH_SYSTEM_BY_ID_SUCCESS = 'SUPPLEMENTARY_AGREEMENT/FETCH_SYSTEM_BY_ID_SUCCESS_SUP_AG_DETAIL',
  LOADING_SHOW_SYSTEM_BY_ID = 'SUPPLEMENTARY_AGREEMENT/LOADING_SHOW_SYSTEM_BY_ID_SUP_AG_DETAIL',
  LOADING_HIDE_SYSTEM_BY_ID = 'SUPPLEMENTARY_AGREEMENT/LOADING_HIDE_SYSTEM_BY_ID_SUP_AG_DETAIL',

  // подгрузка систем по id сохраненного допсоглашения (массив систем с размером 1)
  FETCH_SYSTEMS_BY_SUP_AG_ID_REQUEST = 'SUPPLEMENTARY_AGREEMENT/FETCH_SYSTEMS_BY_SUP_AG_ID_REQUEST',
  FETCH_SYSTEMS_BY_SUP_AG_ID_SUCCESS = 'SUPPLEMENTARY_AGREEMENT/FETCH_SYSTEMS_BY_SUP_AG_ID_SUCCESS',

  // удаление допсоглашения
  DELETE_SUP_AG_REQUEST = 'SUPPLEMENTARY_AGREEMENT/DELETE_SUP_AG_REQUEST',

  RESET_SUP_AG_DETAIL_STATE = 'SUPPLEMENTARY_AGREEMENT/RESET_SUP_AG_DETAIL_STATE',

  // ответственные уже добавленные в контракт (грузятся прямо в контракте), state не нужен

  // вложения допсоглашения
  ATTACHMENTS_LOADING_SHOW = 'SUPPLEMENTARY_AGREEMENT/ATTACHMENTS_LOADING_SHOW',
  ATTACHMENTS_LOADING_HIDE = 'SUPPLEMENTARY_AGREEMENT/ATTACHMENTS_LOADING_HIDE',
  FETCH_SUP_AG_ATTACHMENTS_REQUEST = 'SUPPLEMENTARY_AGREEMENT/FETCH_SUP_AG_ATTACHMENTS_REQUEST',
  FETCH_SUP_AG_ATTACHMENTS_SUCCESS = 'SUPPLEMENTARY_AGREEMENT/FETCH_SUP_AG_ATTACHMENTS_SUCCESS',

  // рабочие группы по системе допсоглашения
  FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_REQUEST = 'SUPPLEMENTARY_AGREEMENT/FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_REQUEST',
  FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_SUCCESS = 'SUPPLEMENTARY_AGREEMENT/FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_SUCCESS',
  LOADING_SHOW_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM = 'SUPPLEMENTARY_AGREEMENT/LOADING_SHOW_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM',
  LOADING_HIDE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM = 'SUPPLEMENTARY_AGREEMENT/LOADING_HIDE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM',
  SET_WORK_GROUPS_PAGE_BY_SUP_AG_BY_SYSTEM = 'SUPPLEMENTARY_AGREEMENT/SET_WORK_GROUPS_PAGE_BY_SUP_AG_BY_SYSTEM',
  SET_WORK_GROUPS_FILTER_BY_SUP_AG_BY_SYSTEM = 'SUPPLEMENTARY_AGREEMENT/SET_WORK_GROUPS_FILTER_BY_SUP_AG_BY_SYSTEM',
  SET_SIZE_PAGE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM = 'SUPPLEMENTARY_AGREEMENT/SET_SIZE_PAGE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM',
  SET_WORK_GROUPS_SORT_BY_SUP_AG_BY_SYSTEM = 'SUPPLEMENTARY_AGREEMENT/SET_WORK_GROUPS_SORT_BY_SUP_AG_BY_SYSTEM',

  // подгрузка sla для связки система-контракт
  LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST = 'SUPPLEMENTARY_AGREEMENT/LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST',
  LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST = 'SUPPLEMENTARY_AGREEMENT/LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST',
  FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST = 'SUPPLEMENTARY_AGREEMENT/FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST',
  FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS = 'SUPPLEMENTARY_AGREEMENT/FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS',

  // подгрузка типов тикета для связки система-контракт
  FETCH_TICKET_TYPES_REQUEST = 'SUPPLEMENTARY_AGREEMENT/FETCH_TICKET_TYPES_REQUEST',
  FETCH_TICKET_TYPES_SUCCESS = 'SUPPLEMENTARY_AGREEMENT/FETCH_TICKET_TYPES_SUCCESS',

  // получение номера допсоглашения
  GET_NUMBER_FOR_CREATE_REQUEST = 'SUPPLEMENTARY_AGREEMENT/GET_NUMBER_FOR_CREATE_REQUEST',
  GET_NUMBER_FOR_CREATE_SUCCESS = 'SUPPLEMENTARY_AGREEMENT/GET_NUMBER_FOR_CREATE_SUCCESS',
}

export interface EditContractPayload {
  contract: Contract;
  systemId?: string;
}

export type AttachSystemAndSLAToContractType = ContractDetailPayload & {
  sla: SLAType;
};
// детальное допсоглашение
export interface ShowSupplementaryAgreementLoadingSupAgDetailAction {
  type: SupplementaryAgreementDetail.SUPPLEMENTARY_AGREEMENT_LOADING_SHOW;
}

export interface HideSupplementaryAgreementLoadingSupAgDetailAction {
  type: SupplementaryAgreementDetail.SUPPLEMENTARY_AGREEMENT_LOADING_HIDE;
}

export interface FetchSupplementaryAgreementRequestSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_SUP_AG_REQUEST;
  payload: string;
}

export interface FetchSupplementaryAgreementSuccessSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_SUP_AG_SUCCESS;
  payload: Contract;
}

// запрос на создание
export interface CreateSupplementaryAgreementSupAgDetailAction {
  type: SupplementaryAgreementDetail.CREATE_SUP_AG_DETAIL;
  payload: CreateSupplementaryAgreementType;
}

// подгрузка системы для создания допсоглашения (1 система по id)
export interface FetchSystemByIdRequestSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_SYSTEM_BY_ID_REQUEST;
  payload: string;
}

export interface FetchSystemByIdSuccessSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_SYSTEM_BY_ID_SUCCESS;
  payload: System;
}

export interface LoadingShowSystemByIdSupAgDetailAction {
  type: SupplementaryAgreementDetail.LOADING_SHOW_SYSTEM_BY_ID;
}

export interface LoadingHideSystemByIdSupAgDetailAction {
  type: SupplementaryAgreementDetail.LOADING_HIDE_SYSTEM_BY_ID;
}

// подгрузка систем по id сохраненного допсоглашения (массив систем с размером 1)
export interface FetchSystemsBySAIdRequestSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_SYSTEMS_BY_SUP_AG_ID_REQUEST;
  payload: string;
}

export interface FetchSystemsBySAIdSuccessSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_SYSTEMS_BY_SUP_AG_ID_SUCCESS;
  payload: System[];
}
// удаление допсоглашения
export interface DeleteSupplementaryAgreementDetailRequestAction {
  type: SupplementaryAgreementDetail.DELETE_SUP_AG_REQUEST;
  payload: Contract;
}

export interface ResetSupplementaryAgreementDetailStateAction {
  type: SupplementaryAgreementDetail.RESET_SUP_AG_DETAIL_STATE;
}

// attachments
export interface ShowAttachmentsLoadingSupAgDetailAction {
  type: SupplementaryAgreementDetail.ATTACHMENTS_LOADING_SHOW;
}

export interface HideAttachmentsLoadingSupAgDetailAction {
  type: SupplementaryAgreementDetail.ATTACHMENTS_LOADING_HIDE;
}

export interface FetchAttachmentsRequestSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_SUP_AG_ATTACHMENTS_REQUEST;
  payload: string;
}

export interface FetchAttachmentsSuccessSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_SUP_AG_ATTACHMENTS_SUCCESS;
  payload: Attachment[];
}

// work groups
export interface FetchWorkGroupsBySABySystemRequestSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_REQUEST;
  payload: ContractDetailPayload;
}

export interface FetchWorkGroupsBySABySystemSuccessSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_SUCCESS;
  payload: ResponseWithMeta<WorkGroup[]>;
}

export interface LoadingShowWorkGroupsBySABySystemSupAgDetailAction {
  type: SupplementaryAgreementDetail.LOADING_SHOW_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM;
}

export interface LoadingHideWorkGroupsBySABySystemSupAgDetailAction {
  type: SupplementaryAgreementDetail.LOADING_HIDE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM;
}

export interface SetPageWorkGroupsBySABySystemSupAgDetailAction {
  type: SupplementaryAgreementDetail.SET_WORK_GROUPS_PAGE_BY_SUP_AG_BY_SYSTEM;
  payload: number;
}

export interface SetFilterWorkGroupsBySABySystemSupAgDetailAction {
  type: SupplementaryAgreementDetail.SET_WORK_GROUPS_FILTER_BY_SUP_AG_BY_SYSTEM;
  payload: WorkGroupFilter;
}

export interface SetSizePageWorkGroupsBySABySystemSupAgDetailAction {
  type: SupplementaryAgreementDetail.SET_SIZE_PAGE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM;
  payload: number;
}

export interface SetSortWorkGroupsBySABySystemSupAgDetailAction {
  type: SupplementaryAgreementDetail.SET_WORK_GROUPS_SORT_BY_SUP_AG_BY_SYSTEM;
  payload: string;
}
// sla
export interface FetchSlaBySAIdBySystemIdRequestSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST;
  payload: ContractDetailPayload;
}

export interface FetchSlaBySAIdBySystemIdSuccessSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS;
  payload: SLAType;
}

export interface FetchTicketTypesRequestSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_TICKET_TYPES_REQUEST;
  payload: ContractIdSystemIdArgs;
}

export interface FetchTicketTypesSuccessSupAgDetailAction {
  type: SupplementaryAgreementDetail.FETCH_TICKET_TYPES_SUCCESS;
  payload: TicketTypeSettings[];
}

export interface LoadingShowSlaBySAIdBySystemIdRequestSupAgDetailAction {
  type: SupplementaryAgreementDetail.LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST;
}

export interface LoadingHideSlaBySAIdBySystemIdRequestSupAgDetailAction {
  type: SupplementaryAgreementDetail.LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST;
}

export interface GetNumberForCreateRequestSupAgDetailAction {
  type: SupplementaryAgreementDetail.GET_NUMBER_FOR_CREATE_REQUEST;
  payload: string;
}

export interface GetNumberForCreateSuccessSupAgDetailAction {
  type: SupplementaryAgreementDetail.GET_NUMBER_FOR_CREATE_SUCCESS;
  payload: string;
}

export type SupAgDetailActionsTypes =
  | ShowSupplementaryAgreementLoadingSupAgDetailAction
  | HideSupplementaryAgreementLoadingSupAgDetailAction
  | FetchSupplementaryAgreementRequestSupAgDetailAction
  | FetchSupplementaryAgreementSuccessSupAgDetailAction
  | CreateSupplementaryAgreementSupAgDetailAction
  | FetchSystemByIdRequestSupAgDetailAction
  | FetchSystemByIdSuccessSupAgDetailAction
  | LoadingShowSystemByIdSupAgDetailAction
  | LoadingHideSystemByIdSupAgDetailAction
  | FetchSystemsBySAIdRequestSupAgDetailAction
  | FetchSystemsBySAIdSuccessSupAgDetailAction
  | DeleteSupplementaryAgreementDetailRequestAction
  | ResetSupplementaryAgreementDetailStateAction
  | ShowAttachmentsLoadingSupAgDetailAction
  | HideAttachmentsLoadingSupAgDetailAction
  | FetchAttachmentsRequestSupAgDetailAction
  | FetchAttachmentsSuccessSupAgDetailAction
  | FetchWorkGroupsBySABySystemRequestSupAgDetailAction
  | FetchWorkGroupsBySABySystemSuccessSupAgDetailAction
  | LoadingShowWorkGroupsBySABySystemSupAgDetailAction
  | LoadingHideWorkGroupsBySABySystemSupAgDetailAction
  | SetPageWorkGroupsBySABySystemSupAgDetailAction
  | SetFilterWorkGroupsBySABySystemSupAgDetailAction
  | SetSizePageWorkGroupsBySABySystemSupAgDetailAction
  | SetSortWorkGroupsBySABySystemSupAgDetailAction
  | FetchSlaBySAIdBySystemIdRequestSupAgDetailAction
  | FetchSlaBySAIdBySystemIdSuccessSupAgDetailAction
  | FetchTicketTypesSuccessSupAgDetailAction
  | LoadingShowSlaBySAIdBySystemIdRequestSupAgDetailAction
  | LoadingHideSlaBySAIdBySystemIdRequestSupAgDetailAction
  | GetNumberForCreateRequestSupAgDetailAction
  | GetNumberForCreateSuccessSupAgDetailAction;

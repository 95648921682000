import React from 'react';

import { TabItem } from 'components/types';

import { SetCurrentFilterTitleRequest } from '../../types';
import { TicketsPopover } from '../TicketsPopover';

import styles from './TicketsPopovers.module.scss';

interface Props {
  items: TabItem[];
  onChangePopover?: (title: string) => void;
  handleSubmitPopover?: (data: SetCurrentFilterTitleRequest) => void;
  handleDelete?: (id: string) => void;
}

export const TicketsPopovers: React.FC<Props> = (props) => {
  const { items, onChangePopover, handleSubmitPopover, handleDelete } = props;

  const itemsEls = items
    .slice(1)
    .map(({ title, value }) => (
      <TicketsPopover
        key={value}
        title={title}
        value={value}
        onChangePopover={onChangePopover}
        handleSubmitPopover={handleSubmitPopover}
        handleDelete={handleDelete}
        className={styles.popovers__item}
      />
    ));

  return <div className={styles.popovers}>{itemsEls}</div>;
};

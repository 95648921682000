import { Action } from 'core/types';
import { RootState } from 'store/rootReducer';

export const getActions = (state: RootState): Action[] | undefined =>
  state.actions.actions;
export const getLoading = (state: RootState): boolean | undefined =>
  state.actions.loading;
export const getActionsCreate = (state: RootState) =>
  state.actions.actionsCreate;
export const getActionsFilter = (state: RootState) =>
  state.actions.actionsFilter || {};
export const getPropsActions = (state: RootState) => ({
  pageNum: state.actions.pagination?.pageNum,
  pageSize: state.actions.pagination?.pageSize,
  sortActions: state.actions.sort,
  totalElements: state.actions.pagination?.totalElements,
  loadingActions: state.actions.loading,
});

import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { InputDatePicker } from 'components/DatePicker/components/InputDatePicker';
import { Select } from 'components/Select';
import { SelectOption } from 'components/Select/types';

import { EmployeesFormData } from '../../types';

import styles from './EmployeesForm.module.scss';

interface Props {
  control: Control<EmployeesFormData>;
  managerWorkGroupsList: SelectOption[];
}

export const EmployeesForm: React.FC<Props> = ({
  control,
  managerWorkGroupsList,
}) => {
  return (
    <>
      <Controller
        control={control}
        name="workGroupId"
        render={({ field }) => {
          return (
            <Select<string>
              label="Группа исполнителей"
              mobileModalTitle="группу исполнителей"
              value={field.value}
              className={styles.employeesForm__select}
              options={managerWorkGroupsList}
              onChange={field.onChange}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="date"
        render={({ field }) => {
          return (
            <InputDatePicker
              type="datePicker"
              placeholder="Период"
              value={field.value}
              onChange={field.onChange}
              className={styles.employeesForm__select}
              showRange
            />
          );
        }}
      />
    </>
  );
};

import React from 'react';
import { compose } from 'redux';

import { withAuthAwaiter } from 'core/hocs/withAuthAwaiter';
import { withRedux } from 'core/hocs/withRedux';
import { withScreenWidth } from 'core/hocs/withScreenWidth';
import { withTheme } from 'core/hocs/withTheme';
import { RouterHistory } from 'routes/RouterHistory';
import { register } from 'serviceWorker';
import 'i18n/config';

const App: React.FC = () => {
  return <RouterHistory />;
};

const withAppData = (Component: React.FC): React.FC =>
  compose<React.FC>(
    withRedux,
    withScreenWidth,
    withTheme,
    withAuthAwaiter
  )(Component);

register();

export default withAppData(App);

import { Priority } from 'core/types';

import { CheckedPriorityType, SLATableDataType } from '../types';

export const initialCheckedPriority: CheckedPriorityType = {
  [Priority.HIGH]: false,
  [Priority.LOW]: false,
  [Priority.MIDDLE]: false,
  [Priority.NONE]: false,
  [Priority.VERY_HIGH]: false,
};

export const getInitialCheckedSLA = (tableData?: SLATableDataType) => {
  const result = { ...initialCheckedPriority };
  if (tableData) {
    Object.keys(initialCheckedPriority).forEach((priority) => {
      if (tableData[priority as keyof typeof tableData]) {
        result[priority as keyof typeof result] = true;
        return;
      }
      result[priority as keyof typeof result] = false;
    });
  }
  return result;
};

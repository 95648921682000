import React from 'react';

import { MailIcon, PhoneIcon } from 'assets/icons';
import { Avatar } from 'components/Avatar';
import { IconBlock } from 'components/IconBlock';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { User } from 'core/types';
import { getShortName } from 'utils';

import styles from './ResponsibleUser.module.scss';

interface Props {
  user: User;
}

export const ResponsibleUser: React.FC<Props> = ({ user }) => {
  return (
    <div className={styles.responsibleUser}>
      <div className={styles.responsibleUser__name}>
        <Avatar
          size={Size.xs}
          initialsUser={`${user.firstName} ${user.lastName}`}
        />
        <Typography variant={TypographyVariants.b3}>
          {getShortName(user.firstName, user.lastName)}
        </Typography>
      </div>
      <div className={styles.responsibleUser__personalData}>
        <IconBlock
          text={user.phoneNumber}
          icon={
            <PhoneIcon className={styles.responsibleUser__personalDataIcon} />
          }
        />
        <IconBlock
          text={user.email}
          icon={
            <MailIcon className={styles.responsibleUser__personalDataIcon} />
          }
        />
      </div>
    </div>
  );
};

import { WorkGroup } from 'core/types';
import { CreateWorkGroupDataToRequest } from 'features/WorkGroup/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { WorkGroupFilterToRequest } from '../../types';

import { endpoints } from './endpoints';

export const request = {
  fetchWorkGroups: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<WorkGroup[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroups(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchWorkGroupsByOrganizationId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroups(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter, organizationId: id },
    }),
  fetchWorkGroup: (id: string) =>
    ApiClient.get<WorkGroup>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroup(id),
      data: {},
    }),
  createWorkGroup: (body: CreateWorkGroupDataToRequest) =>
    ApiClient.post<WorkGroup>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createUser(),
      data: body,
    }),
  editWorkGroup: (body: CreateWorkGroupDataToRequest) =>
    ApiClient.put<WorkGroup>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.editUser(),
      data: body,
    }),
  fetchWorkGroupsByUserId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroupsByUserId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  updateWorkGroup: (data: WorkGroup) =>
    ApiClient.put<WorkGroup>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.editUser(),
      data,
    }),
  fetchWorkGroupsByContractId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroupsContractId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchWorkGroupsByContractIdBySystemId: (
    contractId: string,
    systemId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroupsContractIdSystemId(contractId, systemId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchWorkGroupsByContractIdByOrgId: (
    contractId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroupsContractIdOrgId(contractId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchWorkGroupsFromMyOrg: () =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroupsFromMyOrg(),
      params: { sort: 'TITLE_ASC' },
      data: {},
    }),
  deleteWorkGroup: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.deleteWorkGroup(id),
      data: {},
    }),
};

import { TicketsFilterForRender, TicketsFilterForRequest } from '../types';

interface Props {
  filter?: TicketsFilterForRender;
}

export const getStatusTicketFilterRequest = ({
  filter = {},
}: Props): TicketsFilterForRequest => {
  const { specialistIds, clientIds, workGroupIds } = filter;

  return {
    clientIds,
    specialistIds,
    workGroupIds: workGroupIds || [],
  } as TicketsFilterForRequest;
};

import { TabItem } from 'components/types';
import { SLAChoiceType } from 'features/Contract/types';

export const SLIDE_RADIO_TABS = [
  {
    title: 'Создать новый',
    value: SLAChoiceType.CREATE_NEW,
  },
  {
    title: 'Сохраненные шаблоны',
    value: SLAChoiceType.SELECT_FROM_LIST,
  },
];

export const TIMES_SLIDE: TabItem<number>[] = [
  {
    title: '12ч',
    value: 12,
  },
  {
    title: '24ч',
    value: 24,
  },
  {
    title: '48ч',
    value: 48,
  },
];

export const AUTO_CLOSING_INFO =
  'Автоматическое закрытие тикета в статусе "В ожидании решения" после истечения заданного времени';

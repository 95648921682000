import cn from 'clsx';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { InfoQuestionMarkIcon } from 'assets/icons';
import { Accordion } from 'components/Accordion';
import { Card } from 'components/Card';
import { convertFromDateFormat } from 'components/DatePicker';
import { InputDatePicker } from 'components/DatePicker/components/InputDatePicker';
import { regExpDate } from 'components/DatePicker/constants';
import { Input } from 'components/Input';
import { Radio } from 'components/Radio';
import { getValueFromValueType, Select } from 'components/Select';
import { TextArea } from 'components/TextArea';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { Tooltip } from 'components/Tooltip';
import { Typography, TypographyVariants } from 'components/Typography';
import { BottomButtonsBlock } from 'core/components/BottomButtonsBlock';
import { SUPPORT_RADIO_TABS } from 'core/constants';
import { ApproveOrCancel } from 'core/modals/ApproveOrCancel';
import { ContractStatus, ContractType, OrganizationType } from 'core/types';
import { convertKeyValueOptionToSelectOption } from 'core/utils';
import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import {
  createContractDetail,
  fetchOrganizationsRequestContractDetail,
  fetchPartnerOrganizationsRequestContractDetail,
  resetOrganizationOnlyStateContractDetail,
  setCurrentOrganizationsPageContractDetail,
  setOrganizationsFilterContractDetail,
  setOrganizationsSortContractDetail,
  systemTransfer,
  updateContractDetail,
  updateNotificationsExpireContract,
} from 'features/Contract/ducks/actions';
import {
  getContractDetail,
  getContractPermission,
  getLoadingPartnerOrganizationsContractDetail,
  getOrganizationsSelectListContractDetail,
  getPartnerOrganizationsOptionsContractDetail,
  getPropsOrganizationsContractDetail,
  getSystemsTransfer,
} from 'features/Contract/ducks/selectors';
import { checkValidNotificationExpiry } from 'features/Contract/utils';
import {
  ActionsType,
  OrganizationSelect,
} from 'features/Organizations/components/OrganizationSelect';
import { RouterHref } from 'routes/routerHref';
import { LocationState } from 'routes/types';

import {
  DEFAULT_CONTRACT_FORM_VALUES,
  SELECT_TYPES_LIST,
} from '../../constants';
import { useContractId } from '../../hooks/useContractId';
import {
  CreateContractData,
  ValidDateType,
  validSupplementaryAgreementMap,
} from '../../types';

import styles from './ContractForm.module.scss';

const actionsList: ActionsType = {
  setPage: setCurrentOrganizationsPageContractDetail,
  setFilter: setOrganizationsFilterContractDetail,
  fetchData: fetchOrganizationsRequestContractDetail,
  setSort: setOrganizationsSortContractDetail,
  resetData: resetOrganizationOnlyStateContractDetail,
  getList: getOrganizationsSelectListContractDetail,
  getPropsData: getPropsOrganizationsContractDetail,
};

interface Props {
  className?: string;
}

export const ContractForm: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { state } = useLocation<LocationState>();

  const mainLayoutSticky = document.getElementById('mainLayoutSticky');
  const contract = useSelector(getContractDetail);
  const contractPermission = useSelector(getContractPermission);
  const partnerOrganizationsOptions = useSelector(
    getPartnerOrganizationsOptionsContractDetail
  );
  const isLoadingPartnerOrganizations = useSelector(
    getLoadingPartnerOrganizationsContractDetail
  );

  const selectTypeOptions = SELECT_TYPES_LIST.filter(
    (type) => type.value !== ContractType.SUPPLEMENTARY_AGREEMENT
  );

  const { isTransfer } = useSelector(getSystemsTransfer);
  const contractId = useContractId();
  const isAccessToCreateContract = useSelector(
    getIsAccessedAction(ActionList.CreateContract)
  );

  const [isErrorValidDate, setIsErrorValidDate] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isSwitchPartnerOrg, setIsSwitchPartnerOrg] = useState(false);

  const toggleModal = () => setIsModal(!isModal);

  const organizationId = state?.organizationId;
  const organizationTitle = state?.organizationTitle;

  const canEditForm = isAccessToCreateContract && !contractId;
  const isDisabled = !(
    !contractId || contract?.status !== ContractStatus.CLOSED
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    resetField,
    formState: { errors, isValid, isDirty },
  } = useForm<CreateContractData>({
    mode: 'onChange',
    defaultValues: DEFAULT_CONTRACT_FORM_VALUES,
  });

  const { openDate, endDate, partnerOrganizationId, notificationConfig } =
    watch();

  const { contractExpireIsEnable, notifyBeforeExpire } =
    notificationConfig || {};

  const isValidPartnerOrg =
    (isSwitchPartnerOrg && !isEmpty(partnerOrganizationId)) ||
    !isSwitchPartnerOrg;

  const canSaveContract =
    isValid && !isErrorValidDate && canEditForm && isDirty && isValidPartnerOrg;

  const numberInputOptions = register('number', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Номер договора контракта не может длиннее 100 символов.',
    },
  });

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название контракта не может длиннее 100 символов.',
    },
  });

  const descriptionTextAreaOptions = register('description', {
    maxLength: {
      value: 255,
      message: 'Описание контракта не может длиннее 255-ти символов.',
    },
  });

  const endAfterStart = () => {
    const openDateTime = convertFromDateFormat(openDate)?.getTime();
    const endDateTime = convertFromDateFormat(endDate)?.getTime();
    if (openDateTime && endDateTime) {
      return openDateTime > endDateTime;
    }
    return false;
  };

  const getInitialTypeOption = () => {
    if (contract?.type) {
      return {
        value: contract.type,
        title:
          SELECT_TYPES_LIST.find((item) => item.value === contract?.type)
            ?.title || '',
      };
    }
    return null;
  };

  const getInitialOrganizationOption = () => {
    if (contractId && contract?.organization?.id) {
      return {
        value: contract.organization.id,
        title: contract.organization.title || '',
      };
    }
    if (organizationTitle && organizationId) {
      return {
        value: organizationId,
        title: organizationTitle,
      };
    }
    return null;
  };

  const formSubmitHandler = handleSubmit((data) => {
    const openingDate = convertFromDateFormat(data.openDate);
    const closingDate = convertFromDateFormat(data.endDate);

    const {
      type,
      organizationId: organization,
      partnerOrganizationId: partnerOrganization,
      ...otherData
    } = data;

    if (openingDate && closingDate) {
      const prepareCreateContractData = {
        ...otherData,
        type: getValueFromValueType(type) as ContractType,
        openDate: format(openingDate, 'yyyy-MM-dd', {
          locale: ru,
        }),
        endDate: format(closingDate, 'yyyy-MM-dd', {
          locale: ru,
        }),
        organizationId: getValueFromValueType(organization),
        partnerOrganizationId: getValueFromValueType(partnerOrganization),

        ...(contractPermission && {
          contractPermission: {
            ...contractPermission,
          },
        }),
      };

      if (isTransfer) {
        dispatch(systemTransfer(prepareCreateContractData));
        return;
      }

      if (!contract && !contractId) {
        dispatch(createContractDetail(prepareCreateContractData));
        return;
      }

      dispatch(updateContractDetail(prepareCreateContractData));
    }
  });

  const onApproveModal = () => {
    push(RouterHref.AdminContracts);
    reset();
  };

  useEffect(() => {
    if (contract && contractId) {
      setValue('title', contract?.title);
      setValue('number', contract?.number);
      setValue('type', getInitialTypeOption());
      setValue('description', contract?.description);
      setValue('openDate', format(new Date(contract?.openDate), 'dd.MM.yyyy'));
      setValue('endDate', format(new Date(contract?.endDate), 'dd.MM.yyyy'));
      setValue('supportType', contract.supportType);
      setValue(
        'notificationConfig.contractExpireIsEnable',
        contract.contractNotificationConfig?.contractExpireIsEnable
      );
      setValue(
        'notificationConfig.notifyBeforeExpire',
        contract.contractNotificationConfig?.notifyBeforeExpire
      );

      if (contract?.partnerOrganization) {
        setIsSwitchPartnerOrg(true);
        setValue(
          'partnerOrganizationId',
          convertKeyValueOptionToSelectOption(contract.partnerOrganization)
        );
      }
    }
    setValue('organizationId', getInitialOrganizationOption());
  }, [contract, contractId]);

  useEffect(() => {
    setIsErrorValidDate(endAfterStart());
  }, [endDate, openDate]);

  const checkNotification = (value: number) => {
    return checkValidNotificationExpiry({
      closingDate: endDate,
      openDate,
      notificationDate: value,
    });
  };

  const updateNotifications = (isEnable: boolean, notifyBefore: number) => {
    if (contract && contractId && !checkNotification(notifyBefore)) {
      dispatch(
        updateNotificationsExpireContract({
          contractId,
          isEnable,
          notifyBefore,
        })
      );
    }
  };

  const toggleNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    if (notifyBeforeExpire) {
      updateNotifications(checked, notifyBeforeExpire);
    }
  };

  const togglePartnerOrganization = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;

    setIsSwitchPartnerOrg(checked);

    if (checked) {
      dispatch(
        fetchPartnerOrganizationsRequestContractDetail([
          OrganizationType.PARTNER,
        ])
      );
      return;
    }
    resetField('partnerOrganizationId');
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (contractExpireIsEnable) {
      updateNotifications(contractExpireIsEnable, +value);
    }
  };

  const notificationInputOptions = register(
    'notificationConfig.notifyBeforeExpire',
    {
      onChange: handleInput,
      validate: (value) => {
        return (
          !value ||
          !checkNotification(+value) ||
          validSupplementaryAgreementMap[ValidDateType.BEYOND_CONTRACT_END_DATE]
        );
      },
    }
  );

  const notificationContent = (
    <div className={styles.contractForm__accordionContainer}>
      <Controller
        name="notificationConfig.contractExpireIsEnable"
        key="notificationConfig.contractExpireIsEnable"
        control={control}
        render={({ field }) => {
          return (
            <ToggleSwitch
              label="Уведомление об окончании контракта"
              disabled={isDisabled}
              checked={field.value}
              onChange={(value) => {
                field.onChange(value);
                toggleNotification(value);
              }}
              classNameLabel={styles.contractForm__accordionSwitchLabel}
            />
          );
        }}
      />
      {contractExpireIsEnable && (
        <Input
          {...notificationInputOptions}
          label="Уведомлять руководителя группы за"
          disabled={isDisabled}
          type="number"
          min={0}
          step={1}
          error={!!errors.notificationConfig?.notifyBeforeExpire}
          errorMessage={errors.notificationConfig?.notifyBeforeExpire?.message}
          className={styles.contractForm__accordionInput}
        />
      )}
    </div>
  );

  const addPartnerOrganization = isSwitchPartnerOrg && (
    <Controller
      control={control}
      name="partnerOrganizationId"
      rules={{
        required: true,
      }}
      render={({ field }) => {
        return (
          <Select
            label="Организация"
            value={field.value}
            onChange={field.onChange}
            options={partnerOrganizationsOptions}
            className={styles.contractForm__accordionInput}
            loading={isLoadingPartnerOrganizations}
            disabled={!!(contract && contractId)}
          />
        );
      }}
    />
  );

  const supportRadioTabs =
    contract && contractId
      ? SUPPORT_RADIO_TABS.filter((tab) => tab.value === contract.supportType)
      : SUPPORT_RADIO_TABS;

  const supportItems = supportRadioTabs.map(
    ({ title, value, description }) => ({
      title,
      value,
      icon: (
        <>
          <InfoQuestionMarkIcon
            data-tip
            data-for={value}
            className={styles.contractForm__icon}
          />
          <Tooltip id={value} className={styles.contractForm__tooltip}>
            {description}
          </Tooltip>
        </>
      ),
    })
  );

  return (
    <div className={cn(styles.contractForm, className)}>
      <Card className={styles.contractForm__formHeader}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.contractForm__formHeaderTab}
        >
          Информация
        </Typography>
      </Card>
      <Card className={styles.contractForm__formContent}>
        <form className={styles.contractForm__form}>
          <Input
            {...titleInputOptions}
            label="Название"
            disabled={!canEditForm}
            error={!!errors.title}
            errorMessage={errors.title?.message}
            className={cn(
              styles.contractForm__input,
              styles.contractForm__input_oneOfThree
            )}
          />
          <Controller
            control={control}
            name="organizationId"
            key="organizationId"
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return (
                <OrganizationSelect
                  onChange={field.onChange}
                  value={field.value}
                  className={cn(
                    styles.contractForm__input,
                    styles.contractForm__input_oneOfThree
                  )}
                  placeholder="Организация"
                  disabled={!canEditForm}
                  customActions={actionsList}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="type"
            key="type"
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return (
                <Select<ContractType>
                  mobileModalTitle="тип"
                  disabled={!canEditForm}
                  value={field.value}
                  options={selectTypeOptions}
                  onChange={field.onChange}
                  label="Tип"
                  className={cn(
                    styles.contractForm__input,
                    styles.contractForm__input_oneOfThree
                  )}
                  isTooltip={false}
                />
              );
            }}
          />
          <Input
            {...numberInputOptions}
            label="Номер договора"
            disabled={!canEditForm}
            error={!!errors.number}
            errorMessage={errors.number?.message}
            className={cn(
              styles.contractForm__input,
              styles.contractForm__input_oneOfThree
            )}
          />
          <Controller
            name="openDate"
            rules={{
              required: true,
              pattern: {
                value: regExpDate,
                message: 'Некорректный формат даты',
              },
            }}
            control={control}
            render={({ field }) => {
              return (
                <InputDatePicker
                  type="datePicker"
                  placeholder="Дата начала"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.openDate}
                  errorMessage={errors.openDate?.message}
                  disabled={!canEditForm}
                  className={cn(
                    styles.contractForm__input,
                    styles.contractForm__input_oneOfThree
                  )}
                />
              );
            }}
          />
          <Controller
            name="endDate"
            rules={{
              required: true,
              pattern: {
                value: regExpDate,
                message: 'Некорректный формат даты',
              },
              deps: ['notificationConfig'],
            }}
            control={control}
            render={({ field }) => {
              return (
                <InputDatePicker
                  type="datePicker"
                  placeholder="Дата окончания"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.endDate || isErrorValidDate}
                  errorMessage={
                    errors.endDate?.message || isErrorValidDate
                      ? validSupplementaryAgreementMap[
                          ValidDateType.END_AFTER_START_DATE
                        ]
                      : ''
                  }
                  disabled={!canEditForm}
                  className={cn(
                    styles.contractForm__input,
                    styles.contractForm__input_oneOfThree
                  )}
                />
              );
            }}
          />
          <TextArea
            {...descriptionTextAreaOptions}
            label="Описание"
            disabled={!canEditForm}
            error={!!errors.description}
            errorMessage={errors.description?.message}
            className={styles.contractForm__textarea}
          />

          <div className={styles.contractForm__radio}>
            <Typography variant={TypographyVariants.h5}>
              Тип поддержки
            </Typography>

            <Controller
              name="supportType"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <Radio
                    className={styles.contractForm__radioGroup}
                    disabled={!canEditForm || isTransfer}
                    items={supportItems}
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </div>

          {canEditForm && (
            <BottomButtonsBlock
              isOpen={canSaveContract}
              parentNode={mainLayoutSticky}
              onCancel={toggleModal}
              onSave={formSubmitHandler}
              disabledSubmit={!canSaveContract}
            />
          )}
        </form>

        <Accordion
          title="Дополнительно"
          className={styles.contractForm__accordion}
        >
          {notificationContent}
          <div className={styles.contractForm__accordionContainer}>
            <ToggleSwitch
              label="Добавить партнёрскую организацию"
              disabled={!!(contract && contractId)}
              checked={isSwitchPartnerOrg}
              onChange={togglePartnerOrganization}
              classNameLabel={styles.contractForm__accordionSwitchLabel}
            />
            {addPartnerOrganization}
          </div>
        </Accordion>
      </Card>

      <ApproveOrCancel
        onApprove={onApproveModal}
        isModal={isModal}
        toggleModal={toggleModal}
        text="Вы уверены, что хотите отменить создание контракта?"
      />
    </div>
  );
};

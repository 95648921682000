import cn from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LazyLoader } from 'core/components/LazyLoader';
import { ResponsibleForContract } from 'features/Contract/components/ResponsibleForContract';
import { WorkGroupsTableContainer } from 'features/Contract/components/WorkGroupsTableContainer';
import { AttachmentsSupAg } from 'features/SupplementaryAgreement/components/AttachmentsSupAg';

import { CreateSupAgWrapper } from './components/CreateSupAgWrapper';
import {
  fetchAttachmentsRequestSupAgDetail,
  fetchSupplementaryAgreementRequestSADetail,
  fetchSystemByIdRequestSupAgDetail,
  fetchSystemsBySAIdRequestSADetail,
  fetchTicketTypesRequestSADetail,
  fetchWorkGroupsBySupAgBySystemRequestSupAgDetail,
} from './ducks/actions';
import {
  getAttachmentsSupAgDetail,
  getLoadingSupAgDetail,
  getSupAgDetail,
  getSystemIdForSupAg,
} from './ducks/selectors';
import { useWorkGroupsSupAgTableContainer } from './hooks/useWorkGroupsSupAgTableContainer';
import styles from './SupplementaryAgreement.module.scss';

interface Props {
  className?: string;
}

export interface ParamsProps {
  supplementaryAgreementId?: string;
  contractId?: string;
  systemId?: string;
}

export const SupplementaryAgreement: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const attachments = useSelector(getAttachmentsSupAgDetail) || [];
  const systemIdFromSupAg = useSelector(getSystemIdForSupAg);

  const {
    supplementaryAgreementId,
    contractId,
    systemId: systemFromIdUrl,
  } = useParams<ParamsProps>();

  const supplementaryAgreement = useSelector(getSupAgDetail);
  const loading = useSelector(getLoadingSupAgDetail);

  useEffect(() => {
    if (contractId && systemFromIdUrl) {
      dispatch(fetchSupplementaryAgreementRequestSADetail(contractId));
      dispatch(
        fetchTicketTypesRequestSADetail({
          contractId,
          systemId: systemFromIdUrl,
        })
      );
      dispatch(fetchAttachmentsRequestSupAgDetail(contractId));
      dispatch(fetchSystemByIdRequestSupAgDetail(systemFromIdUrl));
      dispatch(
        fetchWorkGroupsBySupAgBySystemRequestSupAgDetail({
          contractId,
          systemId: systemFromIdUrl,
        })
      );
    }
  }, [contractId, systemFromIdUrl]);

  useEffect(() => {
    if (supplementaryAgreementId) {
      dispatch(
        fetchSupplementaryAgreementRequestSADetail(supplementaryAgreementId)
      );
      dispatch(fetchSystemsBySAIdRequestSADetail(supplementaryAgreementId));
    }
  }, [supplementaryAgreementId]);

  useEffect(() => {
    if (supplementaryAgreement?.mainContractId) {
      dispatch(
        fetchAttachmentsRequestSupAgDetail(
          supplementaryAgreement.mainContractId
        )
      );
    }
  }, [supplementaryAgreement]);

  useEffect(() => {
    if (supplementaryAgreement?.mainContractId && systemIdFromSupAg) {
      dispatch(
        fetchWorkGroupsBySupAgBySystemRequestSupAgDetail({
          contractId: supplementaryAgreement.mainContractId,
          systemId: systemIdFromSupAg,
        })
      );
    }
  }, [systemIdFromSupAg, supplementaryAgreement]);

  if (loading && !supplementaryAgreement) {
    return <LazyLoader className={styles.supplementaryAgreement__loader} />;
  }

  return (
    <div className={cn(styles.supplementaryAgreement, className)}>
      <div className={styles.supplementaryAgreement__leftContainer}>
        <CreateSupAgWrapper />
        <WorkGroupsTableContainer
          customHook={useWorkGroupsSupAgTableContainer}
        />
      </div>
      <div className={styles.supplementaryAgreement__rightContainer}>
        <ResponsibleForContract usersList={supplementaryAgreement?.userList} />
        <AttachmentsSupAg attachments={attachments} />
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MAX_FILE_SIZE_TEXT } from 'core/constants';
import { setAlert } from 'core/ducks/actions';
import { createErrorAlert } from 'core/layouts/AlertsLayout';
import { getIsClient, getUserId } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { fetchTicketEvaluationRequest } from 'features/Ticket/ducks/assessmentInteraction/actions';
import { getTicketEvaluation } from 'features/Ticket/ducks/assessmentInteraction/selectors';
import { fetchTicketRequest } from 'features/Ticket/ducks/ticket/actions';
import {
  getIsAccessedTicketAction,
  getLoading,
} from 'features/Ticket/ducks/ticket/selectors';
import { useIsAccessToChangeStatus } from 'features/Ticket/hooks/useIsAccessToChangeStatus';
import { useTicketCommentsWebSocket } from 'features/Ticket/hooks/useTicketCommentsWebSocket';
import { Attachment, ParentType } from 'types/models/attachment';
import { ApiClient, createError, getUrl } from 'utils';
import { getEnv } from 'utils/getEnv';

import {
  addMessageAttachments,
  fetchTicketCommentsAttachmentsRequest,
} from '../ducks/attachments/actions';
import { getTicketCommentsAttachments } from '../ducks/attachments/selectors';
import { Comment, CommentType } from '../types';

import { useTicketId } from './useTicketId';

interface Props {
  content: Element | null;
}

export const useTicketComments = ({ content }: Props) => {
  const ticketId = useTicketId();

  const [isAttachments, setIsAttachments] = useState<boolean>(false);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState<boolean>(false);
  const [messageHistory, setMessageHistory] = useState<Comment[]>([]);
  const [files, setFiles] = useState<FormData | null>(null);
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [loadingComments, setLoadingComments] = useState<boolean>(false);

  const currentUserId = useSelector(getUserId);
  const isClient = useSelector(getIsClient);
  const ticketCommentsAttachments = useSelector(getTicketCommentsAttachments);
  const loading = useSelector(getLoading);
  const ticketEvaluation = useSelector(getTicketEvaluation);

  const isAccessToChangeStatus = useIsAccessToChangeStatus();
  const isAccessViewingAllTickets = useSelector(
    getIsAccessedTicketAction(ActionList.ViewingAllTickets)
  );

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const {
    state: { lastJsonMessage },
    methods: { sendMessage },
  } = useTicketCommentsWebSocket();

  const dispatch = useDispatch();

  const isSolutionAccepted = useMemo(
    () =>
      !!messageHistory?.find((comment) => {
        return comment.solutionAccepted && !comment.solutionAcceptedBySystem;
      }),
    [messageHistory]
  );

  const isAssessmentInteractionProcess = useMemo(
    () =>
      isSolutionAccepted &&
      (ticketEvaluation?.needRating || !!ticketEvaluation?.rating) &&
      isClient &&
      (ticketEvaluation.rating || isAccessToChangeStatus),
    [
      isSolutionAccepted,
      ticketEvaluation?.needRating,
      ticketEvaluation?.rating,
      isClient,
      isAccessToChangeStatus,
    ]
  );

  const spoilerMessage = useMemo(() => {
    return messageHistory.find((comment) => {
      return !!comment.spoiler;
    });
  }, [messageHistory]);

  const updateTicketStatus = useCallback(
    (type: CommentType) => {
      if (ticketId && !loading && type !== CommentType.USUAL) {
        dispatch(fetchTicketRequest({ ticketId }));
      }
    },
    [ticketId, loading]
  );

  const toggleFileUpload = () => {
    setIsFileUploadOpen(!isFileUploadOpen);
  };

  const toggleAttachments = () => {
    setIsAttachments(!isAttachments);
  };

  const setFileHandler = (acceptedFiles: File[]) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('file', file, encodeURI(file.name));
    });
    setFiles(formData);
  };

  const removeFile =
    (file: File) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      const newFiles = [...myFiles];
      newFiles.splice(newFiles.indexOf(file), 1);
      setMyFiles(newFiles);
    };

  const fetchComments = async () => {
    try {
      setLoadingComments(true);
      const comments = await ApiClient.get<Comment[]>({
        baseURL: getEnv('REACT_APP_MESSAGE_URL'),
        url: getUrl(`comment/ticket/${ticketId}`),
      });
      setMessageHistory(comments.reverse());
      setLoadingComments(false);
    } catch (e) {
      setLoadingComments(false);
      createError(e);
    }
  };

  const onErrorMaxFileSize = () => {
    dispatch(setAlert(createErrorAlert(MAX_FILE_SIZE_TEXT)));
  };

  useEffect(() => {
    if (ticketId) {
      fetchComments();
    }
  }, [ticketId]);

  useEffect(() => {
    if (lastJsonMessage) {
      const {
        message,
        solutionAccepted,
        type,
        id,
        cancelText,
        userDto,
        pathsToFiles,
      }: Comment = lastJsonMessage;

      if (content) {
        setTimeout(() => {
          content?.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }, 200);
      }

      updateTicketStatus(type);

      if (pathsToFiles?.length && ticketId) {
        dispatch(fetchTicketCommentsAttachmentsRequest(ticketId));
      }

      setMessageHistory((prev) => {
        const isMyMsg = userDto?.id === currentUserId;

        if (files?.has('file') && isMyMsg) {
          const attachmentData: Omit<Attachment, 'id' | 'attachmentsFiles'> = {
            parentId: id,
            parentType: ParentType.COMMENTS,
            systemFile: 'test',
            ticketId,
          };

          files.append(
            'objects-attachments',
            new Blob([JSON.stringify(attachmentData)], {
              type: 'application/json',
            })
          );

          dispatch(
            addMessageAttachments({
              filesData: files,
              sendMessage,
              messageData: JSON.stringify({
                id,
                type,
                message,
                solutionAccepted,
                cancelText,
              }),
            })
          );

          setFiles(null);
          setMyFiles([]);
        }
        setIsFileUploadOpen(false);
        const similarMessageId = messageHistory.findIndex(
          (msg) => msg.id === lastJsonMessage.id
        );
        if (similarMessageId >= 0) {
          const prevCopy = prev.concat();
          prevCopy[similarMessageId] = lastJsonMessage;
          return prevCopy;
        }
        return [lastJsonMessage, ...prev];
      });
    }
  }, [lastJsonMessage, setMessageHistory]);

  useEffect(() => {
    if (ticketId) {
      dispatch(fetchTicketCommentsAttachmentsRequest(ticketId));
    }
  }, [ticketId]);

  useEffect(() => {
    if (ticketId && isClient && isSolutionAccepted) {
      dispatch(fetchTicketEvaluationRequest(ticketId));
    }
  }, [ticketId, isClient, isSolutionAccepted]);

  return {
    state: {
      isAttachments,
      isFileUploadOpen,
      myFiles,
      isAccessToChangeStatus,
      messageHistory,
      ticketCommentsAttachments,
      content,
      lastJsonMessage,
      isPopoverOpen,
      isSolutionAccepted,
      isAssessmentInteractionProcess,
      spoilerMessage,
      loadingComments,
      isAccessViewingAllTickets,
    },
    methods: {
      toggleFileUpload,
      toggleAttachments,
      setFileHandler,
      removeFile,
      setMyFiles,
      setMessageHistory,
      togglePopover,
      onErrorMaxFileSize,
    },
  };
};

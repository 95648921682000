import React from 'react';

import { Loader } from 'components/Loader';

import styles from './Loading.module.scss';

export const Loading = () => {
  return (
    <div className={styles.loading__wrapper}>
      <Loader />
    </div>
  );
};

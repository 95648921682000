import React from 'react';

import { TablesLayout } from 'core/layouts/TablesLayout';

import { StorageTableContainer } from './components/StorageTableContainer';
import styles from './Storage.module.scss';

export const Storage = () => {
  const MainTable = () => <StorageTableContainer />;

  return (
    <TablesLayout
      MainTable={[MainTable]}
      classNameRoot={styles.storage}
      classNameMainTables={styles.storage__mainTable}
      classNameSubTables={styles.storage__subTable}
    />
  );
};

import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { setAlert } from 'core/ducks/actions';
import { createSuccessAlert } from 'core/layouts/AlertsLayout';
import { Organization, System } from 'core/types';
import { getFilterSystemToRequest } from 'core/utils';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import { Workflow } from '../types';

import {
  fetchOrganizationsSuccess,
  fetchSystemsSuccess,
  fetchTicketTypesForSystemSuccess,
  fetchWorkflowSuccess,
  hideSystemsLoading,
  showSystemsLoading,
} from './actions';
import { request } from './api/requests';
import { getPropsSystems, getSystemsFilter } from './selectors';
import {
  FetchTicketTypesForSystemRequestAction,
  FetchWorkflowRequestAction,
  SaveWorkflowAction,
  StatusesBuilder,
} from './types';

function* systemsFetch() {
  try {
    const {
      pageNum,
      pageSize,
      sortSystems,
    }: ReturnType<typeof getPropsSystems> = yield select(getPropsSystems);
    const filter: ReturnType<typeof getSystemsFilter> = yield select(
      getSystemsFilter
    );

    yield put(showSystemsLoading());
    const systems: ResponseWithMeta<System[]> = yield call(
      request.fetchSystems,
      pageNum,
      pageSize,
      sortSystems,
      getFilterSystemToRequest(filter)
    );
    yield put(fetchSystemsSuccess(systems));
    yield put(hideSystemsLoading());
  } catch (e) {
    createError(e);
    yield put(hideSystemsLoading());
  }
}

function* organizationsFetch() {
  try {
    const organizations: ResponseWithMeta<Organization[]> = yield call(
      request.fetchOrganizations
    );
    yield put(fetchOrganizationsSuccess(organizations.content));
  } catch (e) {
    createError(e);
  }
}

function* workflowFetch({ payload }: FetchWorkflowRequestAction) {
  try {
    const workflow: Workflow = yield call(request.fetchWorkflow, payload);
    yield put(fetchWorkflowSuccess(workflow));
  } catch (e) {
    yield put(fetchWorkflowSuccess(undefined));
    createError(e);
  }
}

function* ticketTypesFetch({
  payload,
}: FetchTicketTypesForSystemRequestAction) {
  try {
    const { typeList }: System = yield call(request.fetchTicketTypes, payload);
    if (typeList) {
      yield put(fetchTicketTypesForSystemSuccess(typeList));
    }
  } catch (e) {
    createError(e);
  }
}

function* workflowSave({ payload }: SaveWorkflowAction) {
  try {
    const { systemTitle, ...saveParams } = payload;
    const { systemId, typeId } = saveParams;
    yield call(request.saveWorkflow, saveParams);
    yield put(
      setAlert(
        createSuccessAlert(
          `Внесены изменения в настройку статусов системы "${systemTitle}"`
        )
      )
    );
    yield call(workflowFetch, {
      type: StatusesBuilder.FETCH_WORKFLOW_REQUEST,
      payload: { systemId, typeId },
    });
  } catch (e) {
    createError(e);
  }
}

export function* statusesBuilderSaga(): Generator<StrictEffect> {
  yield takeEvery(StatusesBuilder.FETCH_BUILDER_SYSTEMS_REQUEST, systemsFetch);
  yield takeEvery(
    StatusesBuilder.FETCH_BUILDER_ORGANIZATIONS_REQUEST,
    organizationsFetch
  );
  yield takeEvery(StatusesBuilder.FETCH_WORKFLOW_REQUEST, workflowFetch);
  yield takeEvery(
    StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEM_REQUEST,
    ticketTypesFetch
  );
  yield takeEvery(StatusesBuilder.SAVE_WORKFLOW, workflowSave);
}

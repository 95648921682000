import cn from 'clsx';
import React from 'react';

import { Loader } from 'components/Loader';

import styles from './LazyLoader.module.scss';

interface Props {
  className?: string;
}

export const LazyLoader: React.FC<Props> = ({ className }) => (
  <div className={cn(styles.lazyLoader, className)}>
    <Loader />
  </div>
);

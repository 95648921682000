import { getValueFromValueType } from 'components/Select';
import { ValueType } from 'components/Select/types';
import {
  OrganizationsFilter,
  OrganizationsFilterToRequest,
  OrganizationType,
} from 'core/types';

const isArrayOrgType = (
  value?: ValueType<OrganizationType> | OrganizationType[]
): value is OrganizationType[] => {
  return Array.isArray(value);
};

const getPreparedOrgTypes = (
  orgTypes?: ValueType<OrganizationType> | OrganizationType[]
) => {
  if (isArrayOrgType(orgTypes)) {
    return orgTypes;
  }
  const value = getValueFromValueType(orgTypes);
  return value ? [value] : undefined;
};

export const getFilterOrganizationToRequest = (
  filter: OrganizationsFilter = {}
): OrganizationsFilterToRequest => {
  const { organizationTypes } = filter;

  return {
    ...filter,
    organizationTypes: getPreparedOrgTypes(organizationTypes),
  };
};

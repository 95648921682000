import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { TableBodyCell } from 'components/Table/components/TableBodyCell';
import { TableFilter } from 'components/Table/components/TableFilter';
import { Size } from 'components/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import {
  OrganizationType,
  SystemsFilter as SystemsFilterData,
} from 'core/types';
import { OrganizationSelect } from 'features/Organizations/components/OrganizationSelect';
import {
  fetchOrganizationsAddRequest,
  setCurrentOrganizationsAddPage,
  setOrganizationsAddFilterAction,
} from 'features/Organizations/ducks/actions';
import { getEnvironmentsOptions } from 'features/Systems/ducks/selectors';
import { fetchFunctions } from 'features/Systems/hooks/useSystemsTableContainer';
import { checkObjectIdentity } from 'utils';

import { INITIAL_SYSTEMS_FILTER } from '../../constants';
import { setCurrentSystemsPage, setSystemsFilter } from '../../ducks/actions';
import { TableSystemsTypes } from '../../types';

import styles from './SystemsFilter.module.scss';

interface Props {
  tableType: TableSystemsTypes;
}

export const SystemsFilter: React.FC<Props> = React.memo(({ tableType }) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, reset, watch, control } =
    useForm<SystemsFilterData>({
      mode: 'onChange',
      defaultValues: INITIAL_SYSTEMS_FILTER,
    });

  const environmentsOptions = useSelector(getEnvironmentsOptions);

  const filterValues = watch();

  const resetFilter = () => {
    dispatch(setSystemsFilter({}));
    dispatch(fetchFunctions[tableType]());
    if (tableType === TableSystemsTypes.FULL) {
      dispatch(setCurrentOrganizationsAddPage(0));
      dispatch(setOrganizationsAddFilterAction({}));
      dispatch(fetchOrganizationsAddRequest({ updateType: 'update' }));
    }
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const {
      title,
      description,
      organizationId,
      versionTitle,
      contractTitle,
      environmentIds,
    } = data;

    const preparedData = {
      title: title || undefined,
      description: description || undefined,
      organizationId: organizationId || undefined,
      versionTitle: versionTitle || undefined,
      contractTitle: contractTitle || undefined,
      withOnlyActiveContract: contractTitle ? true : undefined,
      environmentIds: environmentIds || undefined,
    };

    dispatch(setSystemsFilter(preparedData));
    dispatch(setCurrentSystemsPage(0));
    dispatch(fetchFunctions[tableType]());
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });
  const descriptionToInput = register('description', {
    onChange: formSubmitHandlerDebounced,
  });
  const versionToInput = register('versionTitle', {
    onChange: formSubmitHandlerDebounced,
  });
  const contractToInput = register('contractTitle', {
    onChange: formSubmitHandlerDebounced,
  });
  const ticketTypeToInput = register('ticketType', {
    onChange: formSubmitHandlerDebounced,
  });

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell className={styles.systemsFilter__cell} key={item.key}>
        {item}
      </TableBodyCell>
    ));

  const filterElements = {
    [TableSystemsTypes.FULL]: () => [
      <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
      <Input
        size={Size.xs}
        type="description"
        key="description"
        {...descriptionToInput}
      />,
      <Input
        size={Size.xs}
        type="versionTitle"
        key="versionTitle"
        {...versionToInput}
      />,
      <Controller
        control={control}
        name="organizationId"
        key="organizationId"
        render={({ field }) => {
          return (
            <OrganizationSelect
              size={Size.xs}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              value={field.value}
              dataForRequest={{
                organizationTypes: [
                  OrganizationType.CUSTOMER,
                  OrganizationType.SERVICE,
                ],
              }}
            />
          );
        }}
      />,
      <Input
        size={Size.xs}
        type="contractTitle"
        key="contractTitle"
        {...contractToInput}
      />,
      <Input
        size={Size.xs}
        type="ticketTypeToInput"
        key="ticketTypeToInput"
        disabled
        {...ticketTypeToInput}
      />,
      <Controller
        control={control}
        name="environmentIds"
        key="environmentIds"
        render={({ field }) => {
          return (
            <Select
              size={Size.xs}
              mobileModalTitle="среда"
              options={environmentsOptions}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              isMulti
            />
          );
        }}
      />,
    ],
    [TableSystemsTypes.FROM_CONTRACTS]: () => [
      <Input
        size={Size.xs}
        type="title"
        key="titleContract"
        {...titleToInput}
      />,
      <Input
        size={Size.xs}
        type="description"
        key="descriptionContract"
        {...descriptionToInput}
      />,
    ],
  };

  const filter = (
    <>
      {tableBodyWrapper(
        filterElements[tableType as keyof typeof filterElements]()
      )}
    </>
  );

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});

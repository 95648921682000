import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getChangeButtonProps } from 'components/Table';
import {
  getActiveSystemIdContractDetail,
  getEnvironmentsBySystem,
  getEnvironmentsSettingsBySystem,
  getGroupsFilterByContractBySystemContractDetail,
  getPropsEnvironmentsBySystem,
} from 'features/Contract/ducks/selectors';
import { checkObjectIdentity } from 'utils';

import {
  fetchEnvironmentsBySystemRequest,
  fetchEnvironmentSettingsBySystemRequest,
  setPageEnvironmentsBySystem,
  setSizeEnvironmentsBySystem,
  setSortEnvironmentsBySystem,
} from '../ducks/actions';
import { getPreparedEnvironments } from '../utils';

import { useContractId } from './useContractId';

export const useEnvironmentTableContainer = () => {
  const environments = useSelector(getEnvironmentsBySystem);
  const environmentSettings = useSelector(getEnvironmentsSettingsBySystem);
  const activeSystemId = useSelector(getActiveSystemIdContractDetail);
  const contractIdFromParams = useContractId();

  const filterValues = useSelector(
    getGroupsFilterByContractBySystemContractDetail
  );

  const { totalElements, pageNum, pageSize, sort, loading } = useSelector(
    getPropsEnvironmentsBySystem
  );

  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState<boolean>(false);
  const [showFilterRow, setShowFilterRow] = useState(false);

  const isFilterActive = !checkObjectIdentity(filterValues, {});
  const searchDisabled =
    !environments?.length || (showFilterRow && isFilterActive);

  const preparedEnvironments = getPreparedEnvironments(
    environments,
    environmentSettings
  );

  const toggleModal = () => setIsModal(!isModal);

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const handleSort = (value: string) => {
    dispatch(setSortEnvironmentsBySystem(value));
    dispatch(
      fetchEnvironmentsBySystemRequest({
        systemId: activeSystemId,
      })
    );
  };

  const prepareCreateTitle = () => {
    return getChangeButtonProps(0).createTitle;
  };

  const prepareHandler = () => {
    return contractIdFromParams ? toggleModal : undefined;
  };

  const handleChangePage = (page: number) => {
    dispatch(setPageEnvironmentsBySystem(page));
    dispatch(
      fetchEnvironmentsBySystemRequest({
        systemId: activeSystemId,
      })
    );
  };

  const handleChangePageSize = (size: number) => {
    dispatch(setSizeEnvironmentsBySystem(size));
  };

  const handleFilter = () => {
    dispatch(setPageEnvironmentsBySystem(0));
    dispatch(
      fetchEnvironmentsBySystemRequest({
        systemId: activeSystemId,
      })
    );
  };

  useEffect(() => {
    if (contractIdFromParams && activeSystemId) {
      dispatch(
        fetchEnvironmentSettingsBySystemRequest({
          contractId: contractIdFromParams,
          systemId: activeSystemId,
        })
      );
    }
  }, [contractIdFromParams, activeSystemId]);

  useEffect(() => {
    dispatch(
      fetchEnvironmentsBySystemRequest({
        systemId: activeSystemId,
      })
    );
  }, [activeSystemId]);

  return {
    state: {
      searchDisabled,
      isCreateDisabled: !activeSystemId,
      pageNum,
      pageSize,
      sort,
      environments: preparedEnvironments,
      showFilterRow,
      isFilterActive,
      isModal,
      totalElements,
      loading,
    },
    methods: {
      toggleFilterRow,
      prepareCreateTitle,
      handleSort,
      prepareHandler,
      handleChangePage,
      handleChangePageSize,
      toggleModal,
      handleFilter,
    },
  };
};

import cn from 'clsx';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { CheckMarkIcon } from 'assets/icons';
import { NotificationCircle } from 'components/NotificationCircle';
import { Popover } from 'components/Popover';
import { PopoverButton } from 'components/Popover/components/PopoverButton';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { PopoverItemWrapper } from 'components/Popover/components/PopoverItemWrapper';
import { PopoverLine } from 'components/Popover/components/PopoverLine';
import { PopoverTitle } from 'components/Popover/components/PopoverTitle';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { getScreenWidth } from 'core/ducks/selectors';
import { ScreenWidth } from 'core/types';
import { convertTabTitleToPath } from 'features/Profile/utils/convertTabTitleToPath';
import { getTabsWithStatusesProfile } from 'features/Profile/utils/getTabsWithStatuses';
import { splitProfileTabsByGroup } from 'features/Profile/utils/splitProfileTabsByGroup';
import { RouterHref } from 'routes/routerHref';

import {
  fetchNotificationsProfileRequest,
  setAllCheckedNotifications,
  setNotificationsProfileFilter,
} from '../../ducks/actions';
import {
  getFilterValues,
  getNotificationTabs,
  getProfileTabsUnchecked,
} from '../../ducks/selectors';

import styles from './ProfileDrop.module.scss';

interface Props {
  withFilters?: boolean;
  withTabs?: boolean;
  className?: string;
}

export const ProfileDrop: React.FC<Props> = ({
  withFilters = false,
  withTabs = false,
  className,
}) => {
  const dispatch = useDispatch();

  const { t: filterT } = useTranslation('filter');

  const { pathname } = useLocation();

  const screenWidth = useSelector(getScreenWidth);
  const tabs = useSelector(getNotificationTabs);
  const tabsStatuses = useSelector(getProfileTabsUnchecked);
  const filterNotifications = useSelector(getFilterValues);

  const tabsWithStatuses = getTabsWithStatusesProfile(tabs, tabsStatuses);
  const tabsSplitByGroup = splitProfileTabsByGroup(tabsWithStatuses);

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const handleShowOnlyUnread = () => {
    dispatch(
      setNotificationsProfileFilter({
        ...filterNotifications,
        notChecked: !filterNotifications?.notChecked,
      })
    );
    dispatch(
      fetchNotificationsProfileRequest({ updateType: 'update', pageNum: 0 })
    );
  };

  const handleSetAllChecked = (event?: React.MouseEvent) => {
    event?.preventDefault();
    dispatch(setAllCheckedNotifications());
  };

  const handleSwitch = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    event?.preventDefault();
    handleShowOnlyUnread();
  };

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const notificationsTopContent = withTabs ? (
    <>
      {!isMobileAll && <PopoverLine />}
      {Object.keys(tabsSplitByGroup).map((item) => {
        return (
          <div key={nanoid()} className={styles.profileDrop__tabGroup}>
            {item !== ' ' && !isMobileAll && <PopoverTitle title={item} />}
            {tabsSplitByGroup[item]?.map((elem) => {
              const to = `${
                RouterHref.ProfileNotifications
              }/${convertTabTitleToPath(elem?.titleNotification)}`;
              return (
                <PopoverItemWrapper
                  className={styles.profileDrop__linkWrapper}
                  key={nanoid()}
                >
                  <Link
                    to={to}
                    onClick={togglePopover}
                    className={cn(styles.profileDrop__link, {
                      [styles.profileDrop__link_active]: pathname === to,
                    })}
                  >
                    {elem?.description}
                    {elem?.hasUnchecked && <NotificationCircle />}
                  </Link>
                </PopoverItemWrapper>
              );
            })}
          </div>
        );
      })}
    </>
  ) : undefined;

  const dropContent = (
    <PopoverContainer className={cn(styles.profileDrop, className)}>
      <PopoverTitle title="Действия" />
      <PopoverButton
        icon={<CheckMarkIcon className={styles.profileDrop__icon} />}
        onClick={handleSetAllChecked}
      >
        Отметить все как прочитанные
      </PopoverButton>
      {withFilters && (
        <>
          <PopoverLine />
          <PopoverTitle title={filterT('title')} />
          <PopoverItemWrapper className={styles.profileDrop__switchWrapper}>
            <ToggleSwitch
              label="Только непрочитанные"
              onChange={() => handleSwitch()}
              checked={!!filterNotifications?.notChecked}
            />
          </PopoverItemWrapper>
        </>
      )}
      {!isMobileAll && notificationsTopContent}
    </PopoverContainer>
  );

  return (
    <Popover
      content={dropContent}
      togglePopover={togglePopover}
      isOpen={isPopoverOpen}
      topModalContent={notificationsTopContent}
      classNameTopContent={styles.profileDrop__top}
      positions={['bottom', 'left']}
      align="end"
    />
  );
};

import { ColorScheme } from 'components-new/types';
import { SelectOption } from 'components/Select/types';
import { Priority, TicketStatus } from 'core/types';
import { Languages } from 'i18n/types';

import { CommentType, CreateNote, VisibilityType } from './types';

export const TICKET_STATUS_TITLE_MAP = {
  [TicketStatus.NEW]: 'Новый',
  [TicketStatus.WORK]: 'В работе',
  [TicketStatus.CLOSE]: 'Закрыт',
  [TicketStatus.PENDING_CLOSURE]: 'В ожидании закрытия',
  [TicketStatus.WAITING_INFO]: 'В ожидании информации',
};

export const TICKET_STATUS_TITLE_MAP_EN = {
  [TicketStatus.NEW]: 'New',
  [TicketStatus.WORK]: 'In work',
  [TicketStatus.CLOSE]: 'Closed',
  [TicketStatus.PENDING_CLOSURE]: 'Pending close',
  [TicketStatus.WAITING_INFO]: 'Pending info',
};

export const getTicketStatusTitleMap = (language: Languages) => {
  const isRU = language.includes(Languages.RU);
  return isRU ? TICKET_STATUS_TITLE_MAP : TICKET_STATUS_TITLE_MAP_EN;
};

export const PRIORITY_TITLE_MAP = {
  [Priority.NONE]: 'Без приоритета',
  [Priority.LOW]: 'Низкий приоритет',
  [Priority.MIDDLE]: 'Средний приоритет',
  [Priority.HIGH]: 'Высокий приоритет',
  [Priority.VERY_HIGH]: 'Критический приоритет',
};

export const PRIORITY_TITLE_MAP_EN = {
  [Priority.NONE]: 'Without priority',
  [Priority.LOW]: 'Low priority',
  [Priority.MIDDLE]: 'Middle priority',
  [Priority.HIGH]: 'High priority',
  [Priority.VERY_HIGH]: 'Very high priority',
};

export const getPriorityTitleMap = (language: Languages) => {
  const isRU = language.includes(Languages.RU);
  return isRU ? PRIORITY_TITLE_MAP : PRIORITY_TITLE_MAP_EN;
};

export const RADIO_TABS_FOR_SPECIALIST = [
  {
    title: 'Отправить на запрос информации',
    value: CommentType.INFO_REQUEST,
  },
  {
    title: 'Отправить на подтверждение закрытия',
    value: CommentType.CLOSE_REQUEST,
  },
];

export const RADIO_TABS_FOR_USER = [
  {
    title: 'Вернуть в работу',
    value: CommentType.RETURN_TO_WORK,
  },
];

export const PRIORITY_TITLE_MAP_SHORT = {
  [Priority.NONE]: 'Без приоритета',
  [Priority.LOW]: 'Низкий',
  [Priority.MIDDLE]: 'Средний',
  [Priority.HIGH]: 'Высокий',
  [Priority.VERY_HIGH]: 'Критический',
};

const PRIORITY_TITLE_MAP_SHORT_EN = {
  [Priority.NONE]: 'Without priority',
  [Priority.LOW]: 'Low',
  [Priority.MIDDLE]: 'Middle',
  [Priority.HIGH]: 'High',
  [Priority.VERY_HIGH]: 'Very high',
};

export const getPriorityTitleShortMap = (language: Languages) => {
  const isRU = language.includes(Languages.RU);
  return isRU ? PRIORITY_TITLE_MAP_SHORT : PRIORITY_TITLE_MAP_SHORT_EN;
};

const PRIVATE = 'Видно только вам';
const WORKGROUPS = 'Видно всем';

export const VISIBILITY_OPTIONS: SelectOption<VisibilityType>[] = [
  {
    title: PRIVATE,
    value: VisibilityType.PRIVATE,
  },
  {
    title: WORKGROUPS,
    value: VisibilityType.WORKGROUPS,
  },
];

export const VISIBILITY_TYPE_MAP = {
  [VisibilityType.PRIVATE]: PRIVATE,
  [VisibilityType.WORKGROUPS]: WORKGROUPS,
};

export const COLOR_SCHEME_MAP: Record<VisibilityType, ColorScheme> = {
  [VisibilityType.PRIVATE]: 'grey',
  [VisibilityType.WORKGROUPS]: 'blue',
};

export const NOTE_DEFAULT_VALUES: CreateNote = {
  text: '',
  visibility: VISIBILITY_OPTIONS[1],
};

import cn from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { RatingStars } from 'components/RatingStars';
import { Typography, TypographyVariants } from 'components/Typography';

import {
  evaluateSpecialist,
  fetchImprovementsRequest,
  resetAssessmentInteractionState,
} from '../../ducks/assessmentInteraction/actions';
import {
  getImprovements,
  getTicketEvaluation,
} from '../../ducks/assessmentInteraction/selectors';
import { useTicketId } from '../../hooks/useTicketId';

import styles from './AssessmentInteractionProcess.module.scss';

interface Props {
  className?: string;
}

export const AssessmentInteractionProcess: React.FC<Props> = ({
  className,
}) => {
  const improvements = useSelector(getImprovements);
  const ticketEvaluation = useSelector(getTicketEvaluation);

  const ticketId = useTicketId();

  const isRated = !!ticketEvaluation?.rating;
  const ticketRating = ticketEvaluation?.rating;
  const ticketImprovementsIds = ticketEvaluation?.checkedRatings;

  const [rating, setRating] = useState(0);
  const [activeImprovementsIds, setActiveImprovementsIds] = useState<string[]>(
    []
  );

  const dispatch = useDispatch();

  const onChangeCheckbox = useCallback(
    (id: string) => {
      setActiveImprovementsIds((prevState) => {
        const isChecked = prevState.find((activeId) => activeId === id);
        if (isChecked) {
          return prevState.filter((activeId) => activeId !== id);
        }
        return [...prevState, id];
      });
    },
    [setActiveImprovementsIds]
  );

  const onChangeRating = useCallback(
    (value: number) => {
      setRating(value);
      if (value === 5 && ticketId) {
        dispatch(
          evaluateSpecialist({ rating: 5, ticketId, improvementsIds: [] })
        );
      }
    },
    [setRating, ticketId, rating]
  );

  const evaluateSpecialistHandler = useCallback(() => {
    if (ticketId) {
      dispatch(
        evaluateSpecialist({
          rating,
          ticketId,
          improvementsIds: activeImprovementsIds,
        })
      );
    }
  }, [ticketId, activeImprovementsIds, rating]);

  const isExtraContent = useMemo(
    () =>
      (rating > 0 && rating < 5 && !isRated) ||
      (isRated && !!ticketImprovementsIds?.length),
    [isRated, rating, ticketImprovementsIds?.length]
  );

  const getTitle = useCallback(() => {
    if (isRated) {
      return 'Спасибо за оценку, вы помогаете улучшить наш сервис';
    }
    return 'Пожалуйста, оцените качество работы по этому тикету';
  }, [isRated]);

  const getSubTitle = useCallback(() => {
    if (rating === 4) {
      return 'Что мы можем улучшить?';
    }
    if (rating === 3) {
      return 'Что вам не понравилось?';
    }
    if (rating <= 2) {
      return 'Что вас разочаровало?';
    }
    return null;
  }, [rating]);

  useEffect(() => {
    if (rating > 0 && rating <= 5) {
      dispatch(fetchImprovementsRequest(rating));
      if (!isRated) {
        setActiveImprovementsIds([]);
      }
    }
  }, [rating, isRated]);

  useEffect(() => {
    if (ticketRating) {
      setRating(ticketRating);
    }
  }, [ticketRating]);

  useEffect(() => {
    if (ticketImprovementsIds?.length) {
      setActiveImprovementsIds(ticketImprovementsIds);
    }
  }, [ticketImprovementsIds]);

  useEffect(() => {
    return () => {
      dispatch(resetAssessmentInteractionState());
    };
  }, []);

  const improvementsList = improvements?.map(({ id, value }) => {
    const isChecked = !!activeImprovementsIds.find(
      (activeId) => activeId === id
    );

    return (
      <div
        key={id}
        className={styles.assessmentInteractionProcess__checkboxWrapper}
      >
        <Checkbox
          label={value}
          checked={isChecked}
          onChange={() => onChangeCheckbox(id)}
          disabled={isRated}
        />
      </div>
    );
  });

  const button = !isRated && (
    <Button
      onClick={evaluateSpecialistHandler}
      className={styles.assessmentInteractionProcess__button}
    >
      Отравить
    </Button>
  );

  const extraContent = isExtraContent && (
    <div className={styles.assessmentInteractionProcess__bottomWrapper}>
      <Typography
        variant={TypographyVariants.b1}
        className={styles.assessmentInteractionProcess__subTitle}
      >
        {getSubTitle()}
      </Typography>
      {improvementsList}
      {button}
    </div>
  );

  return (
    <div className={cn(styles.assessmentInteractionProcess, className)}>
      <div className={styles.assessmentInteractionProcess__topWrapper}>
        <Typography
          variant={TypographyVariants.b1}
          className={styles.assessmentInteractionProcess__title}
        >
          {getTitle()}
        </Typography>
        <RatingStars
          rating={rating}
          onChangeRating={onChangeRating}
          disabled={isRated}
          className={styles.assessmentInteractionProcess__starsRating}
        />
      </div>
      {extraContent}
    </div>
  );
};

import { ValueType } from 'components/Select/types';
import { Contract, ContractStatus, ContractType } from 'core/types';

export type ContractsFilterToRequest = Partial<
  Omit<
    Contract,
    'systemList' | 'endDate' | 'openDate' | 'userList' | 'workGroupList'
  >
> & {
  endDate?: string;
  openDate?: Date | string;
};

export type ContractsFilter = Partial<
  Omit<ContractsFilterToRequest, 'organizationId' | 'type' | 'status'>
> & {
  organizationId?: ValueType<string>;
  type?: ValueType<ContractType>;
  status?: ValueType<ContractStatus>;
};

export enum TableContractsTypes {
  FULL = 'FULL',
  FROM_ORGANIZATIONS = 'FROM_ORGANIZATIONS',
  FROM_SYSTEMS = 'FROM_SYSTEMS',
}

import cn from 'clsx';
import React, { useEffect } from 'react';
import { Control, Controller, useForm, UseFormSetValue } from 'react-hook-form';

import { Clock12, QuestionIcon } from 'assets/icons';
import { Input } from 'components/Input';
import { RadioSlide } from 'components/RadioSlide';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { Tooltip } from 'components/Tooltip';
import { Size } from 'components/types';
import { CreateSystemDataAdd } from 'features/Systems/types';

import { AUTO_CLOSING_INFO, TIMES_SLIDE } from '../../constants';

import styles from './SlaAutoClosing.module.scss';

export interface Props {
  isEditMode?: boolean;
  control: Control<CreateSystemDataAdd>;
  setValue: UseFormSetValue<CreateSystemDataAdd>;
  autoClosableTickets?: boolean;
  closeAfterHours?: number;
  className?: string;
  classNameToggleContainer?: string;
}

export const SLAAutoClosing: React.FC<Props> = ({
  isEditMode,
  control,
  setValue,
  autoClosableTickets,
  closeAfterHours,
  className,
  classNameToggleContainer,
}) => {
  const {
    reset,
    setValue: setLocalValue,
    control: localControl,
  } = useForm<{
    closeAfterHoursInput?: number | string;
    closeAfterHoursSlide?: number;
  }>({
    mode: 'onChange',
    defaultValues: {
      closeAfterHoursInput: '',
      closeAfterHoursSlide: 0,
    },
  });

  const setTimesSlideState = (time: number) => {
    setValue('closeAfterHours', time ? Math.round(time) : 0);
    if (time && TIMES_SLIDE.map((item) => item.value).includes(time)) {
      setLocalValue('closeAfterHoursSlide', time);
      setLocalValue('closeAfterHoursInput', time);
      return;
    }
    setLocalValue('closeAfterHoursSlide', 0);
    setLocalValue('closeAfterHoursInput', time);
  };

  useEffect(() => {
    if (!autoClosableTickets) {
      setValue('closeAfterHours', 0);
      reset();
    }
    if (autoClosableTickets) {
      setTimesSlideState(closeAfterHours || 0);
    }
  }, [autoClosableTickets, closeAfterHours]);

  return (
    <div className={cn(styles.slaAutoClosing, className)}>
      <div className={styles.slaAutoClosing__toggle}>
        <Controller
          control={control}
          name="expireReactionTimeInDecisionTime"
          render={({ field }) => {
            return (
              <ToggleSwitch
                label="Вычет времени"
                checked={!!field.value}
                id="showExpireSeconds"
                disabled={!isEditMode}
                onChange={field.onChange}
              />
            );
          }}
        />
        <Tooltip id="question">
          Вычет просрочки по реакции из времени решения
        </Tooltip>
        <QuestionIcon
          data-tip
          data-for="question"
          className={styles.slaAutoClosing__toggleIcon}
        />
      </div>
      <div
        className={cn(
          styles.slaAutoClosing__toggleContainer,
          classNameToggleContainer
        )}
      >
        <div className={styles.slaAutoClosing__toggle}>
          <Controller
            control={control}
            name="autoClosableTickets"
            render={({ field }) => {
              return (
                <ToggleSwitch
                  label="Автозакрытие"
                  checked={!!field.value}
                  onChange={field.onChange}
                  id="autoClosing"
                  disabled={!isEditMode}
                />
              );
            }}
          />
          <Tooltip id="autoClosing">{AUTO_CLOSING_INFO}</Tooltip>
          <QuestionIcon
            data-tip
            data-for="autoClosing"
            className={styles.slaAutoClosing__toggleIcon}
          />
        </div>
        <div
          className={cn(styles.slaAutoClosing__time, {
            [styles.slaAutoClosing__time_visible]: autoClosableTickets,
          })}
        >
          <Controller
            control={localControl}
            name="closeAfterHoursSlide"
            render={({ field }) => {
              return (
                <RadioSlide
                  size={Size.s}
                  items={TIMES_SLIDE}
                  value={field.value}
                  onChange={(event) => {
                    setTimesSlideState(+event.target.value);
                  }}
                  disabled={!isEditMode || !autoClosableTickets}
                  className={styles.slaAutoClosing__slide}
                />
              );
            }}
          />
          <div className={styles.slaAutoClosing__input}>
            <Controller
              control={localControl}
              name="closeAfterHoursInput"
              render={({ field }) => {
                return (
                  <Input
                    size={Size.s}
                    label="Вручную"
                    type="number"
                    value={field.value}
                    onChange={(event) => {
                      setTimesSlideState(+event.target.value);
                    }}
                    rightIcon={<Clock12 />}
                    disabled={!isEditMode}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import _ from 'lodash';
import React from 'react';

import { Address } from 'assets/icons';
import { ReactComponent as DomainIcon } from 'assets/icons/svg/domain.svg';
import { IconBlock } from 'components/IconBlock';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { Table } from 'components/Table';
import { TableContainer } from 'components/Table/components/TableContainer';
import { Typography, TypographyVariants } from 'components/Typography';
import { DeleteButton } from 'core/components/DeleteButton';
import { entityNames } from 'core/constants';
import { Organization, OrganizationType } from 'core/types';
import { deleteOrganizationTitle } from 'features/Organization/layouts/OrganizationLayout';
import { useOrganizationsTableContainer } from 'features/Organizations/hooks/useOrganizationsTableContainer';

import {
  COLUMNS_ORGANIZATIONS_FULL,
  TITLE_TYPE_ORGANIZATION,
} from '../../constants';
import { OrganizationsFilter } from '../OrganizationsFilter';

import styles from './OrganizationsTableContainer.module.scss';

const getDataTable = (organizations?: Organization[]) =>
  organizations?.map((organization) => {
    const { title, type, domain, description, address, id } = organization;
    return {
      id,
      title: { title, to: `/admin/organizations/${id}` },
      type: {
        text: TITLE_TYPE_ORGANIZATION[type],
        className:
          type === OrganizationType.SERVICE
            ? styles.organizations_service
            : undefined,
      },
      domain: (
        <IconBlock
          icon={
            <DomainIcon className={styles.organizationsTableContainer__icon} />
          }
          text={domain || '-'}
        />
      ),
      description,
      address: (
        <IconBlock
          icon={
            <Address className={styles.organizationsTableContainer__icon} />
          }
          text={address}
        />
      ),
    };
  }) || [];

export const OrganizationsTableContainer: React.FC = () => {
  const { methods, state } = useOrganizationsTableContainer();

  const {
    toggleDeleteModal,
    handleChangePage,
    handleChangePageSize,
    createOrganizationHandler,
    handleSort,
    handleClickOrganization,
    toggleFilterRow,
    handleDelete,
    handleShowModalDelete,
  } = methods;

  const {
    pageNum,
    pageSize,
    loadingOrganization,
    sortOrganizations,
    totalElements,
    organizations,
    organizationId,
    isAccessToCreateOrganization,
    isAccessToDeleteOrganization,
    filterValues,
    showFilterRow,
    showDeleteModal,
    activeOrganization,
    isWorkGroupsLoading,
    isContractsLoading,
    canDeleteOrg,
  } = state;

  const dataTable = getDataTable(organizations);
  const canDeleteOrganization =
    activeOrganization && activeOrganization.type === OrganizationType.CUSTOMER;

  const getContent = () => {
    return (
      <Table
        columns={COLUMNS_ORGANIZATIONS_FULL}
        data={dataTable}
        sort={sortOrganizations}
        onSort={handleSort}
        onRowClick={handleClickOrganization}
        activeRowId={organizationId}
        isFilterActive={showFilterRow}
        filterComponent={showFilterRow && <OrganizationsFilter />}
        dataLoading={loadingOrganization}
        valueFieldName="id"
        disabledSort={loadingOrganization}
        disabledRows={isWorkGroupsLoading || isContractsLoading}
      />
    );
  };

  const dropContent = isAccessToDeleteOrganization ? (
    <PopoverContainer>
      <DeleteButton
        onClick={handleShowModalDelete}
        disabled={!canDeleteOrganization}
      />
    </PopoverContainer>
  ) : undefined;

  return (
    <TableContainer
      classNameRoot={styles.organizationsTableContainer}
      headerProps={{
        title: 'Организации',
        createHandler: createOrganizationHandler,
        createDisabled: !isAccessToCreateOrganization,
        isSearchOpen: showFilterRow,
        dropContent,
        searchDisabled:
          !organizations?.length ||
          (showFilterRow &&
            !_.isEqual(
              _.omitBy(filterValues, (value, key) =>
                _.isNil(value) || _.isEmpty(value) ? key : false
              ),
              {}
            )),
        toggleSearch: toggleFilterRow,
        loading: loadingOrganization,
      }}
      contentTable={getContent()}
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        pageSize,
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
        disabled: loadingOrganization,
        entityName: entityNames.ORGANIZATIONS,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal && canDeleteOrg,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.organizationsTableContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: deleteOrganizationTitle,
        className: styles.organizationsTableContainer__modalDelete,
      }}
      infoProps={{
        isModal: showDeleteModal && !canDeleteOrg,
        toggleModal: toggleDeleteModal,
        className: styles.organizationsTableContainer__modal,
        cancelTitle: 'Понятно',
        children: (
          <Typography
            variant={TypographyVariants.b2}
            className={styles.organizationsTableContainer__modalText}
          >
            Организация не может быть удалена, есть активные контракты
          </Typography>
        ),
      }}
    />
  );
};

import cn from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ExitIcon, MegaphoneIcon, TelegramIcon } from 'assets/icons';
import { ReactComponent as SmallBell } from 'assets/icons/svg/notification-bell.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/svg/setting.svg';
import { InfoBlock } from 'components/InfoBlock';
import { NotificationCircle } from 'components/NotificationCircle';
import { Typography, TypographyVariants } from 'components/Typography';
import { getIsClient } from 'features/Auth/ducks/selectors';
import { getIsNotCheckedNotifications } from 'features/Notifications/ducks/selectors';
import { UserTabType } from 'features/Profile/types';
import { RouterHref } from 'routes/routerHref';
import { AuthService } from 'utils';

import { getIsUncheckedNews } from '../../ducks/selectors';

import styles from './ProfileUserTabs.module.scss';

export const ProfileUserTabs = () => {
  const { t: tGLobal } = useTranslation('global');
  const { t: tNavigation } = useTranslation('navigation');

  const { push } = useHistory();
  const { pathname } = useLocation();

  const isNotCheckedNotifications = useSelector(getIsNotCheckedNotifications);
  const isClient = useSelector(getIsClient);
  const isUncheckedNews = useSelector(getIsUncheckedNews);

  const tabs: UserTabType[] = useMemo(
    () => [
      {
        title: (
          <Typography
            variant={TypographyVariants.b3}
            className={styles.profileUserTabs__titleBlockNotifications}
          >
            {tNavigation('notifications.title')}
          </Typography>
        ),
        icon: <SmallBell className={styles.profileUserTabs__icon} />,
        classNameIconWrapper: styles.profileUserTabs__iconBlockNotifications,
        path: RouterHref.ProfileNotifications,
        onClick: () => {
          push(`${RouterHref.ProfileNotifications}/all`);
        },
        hasCircle: isNotCheckedNotifications,
      },
      {
        title: 'Чат бот',
        icon: <TelegramIcon className={styles.profileUserTabs__icon} />,
        path: RouterHref.ProfileTelegram,
        classNameIconWrapper: styles.profileUserTabs__iconBlockTelegram,
        onClick: () => {
          push(RouterHref.ProfileTelegram);
        },
        isHide: !isClient,
      },
      {
        title: (
          <Typography
            variant={TypographyVariants.b3}
            className={styles.profileUserTabs__titleBlockNotifications}
          >
            Новости
          </Typography>
        ),
        icon: <MegaphoneIcon className={styles.profileUserTabs__icon} />,
        classNameIconWrapper: styles.profileUserTabs__iconBlockNews,
        path: RouterHref.ProfileNews,
        onClick: () => {
          push(RouterHref.ProfileNews);
        },
        hasCircle: isUncheckedNews,
        circleClassName: styles.profileUserTabs__iconCircle_news,
      },
      {
        title: tGLobal('settings'),
        icon: <SettingsIcon className={styles.profileUserTabs__icon} />,
        path: RouterHref.ProfileSettings,
        classNameIconWrapper: styles.profileUserTabs__iconBlockSettings,
        onClick: () => {
          push(RouterHref.ProfileSettings);
        },
      },
      {
        title: tGLobal('exit'),
        icon: (
          <ExitIcon
            className={cn(
              styles.profileUserTabs__icon,
              styles.userTabs__iconExit
            )}
          />
        ),
        classNameIconWrapper: '',
        onClick: () => {
          AuthService.doLogout();
        },
      },
    ],
    [tNavigation, isNotCheckedNotifications, isUncheckedNews]
  );

  const tabsList = tabs.map((item) => {
    const {
      icon: Icon,
      title,
      onClick,
      classNameIconWrapper,
      path,
      hasCircle,
      isHide,
      circleClassName,
    } = item;

    const isTabActive = path?.length && pathname.includes(path);

    const circle = hasCircle && (
      <NotificationCircle
        className={cn(styles.profileUserTabs__iconCircle, circleClassName)}
      />
    );

    if (isHide) {
      return null;
    }

    return (
      <button
        key={classNameIconWrapper}
        onClick={onClick}
        className={cn(styles.profileUserTabs__tab, {
          [styles.profileUserTabs__tab_active]: isTabActive,
        })}
      >
        <InfoBlock
          icon={Icon}
          mainTitle={title}
          classNameIconWrapper={cn(styles.profileUserTabs__iconBlock, {
            [classNameIconWrapper]: isTabActive,
          })}
          classNameTitle={styles.profileUserTabs__text}
          classNameInfoWrapper={styles.profileUserTabs__textWrapper}
        />
        {circle}
      </button>
    );
  });

  return <div className={styles.profileUserTabs}>{tabsList}</div>;
};

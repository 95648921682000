import cn from 'clsx';
import { SeriesPieOptions } from 'highcharts';
import React, { useState } from 'react';

import { BarGraph, PieChartIcon } from 'assets/icons';

import { IconButtonWrapper } from '../IconButtonWrapper';
import { LineChart } from '../LineChart';
import { PieChart } from '../PieChart';
import { TableHeader } from '../Table/components/TableHeader';

import styles from './ChartBlock.module.scss';
import { ChartType, GraphType } from './types';

interface Props {
  className?: string;
  classNameClose?: string;
  classNamePieChart?: string;
  chartType: ChartType;
  title?: string | JSX.Element;
  dataChart?: SeriesPieOptions['data'];
  dataAdditional?: SeriesPieOptions['data'];
  headerElement?: JSX.Element;
  lineChartElement?: JSX.Element;
}

export const ChartBlock: React.FC<Props> = ({
  className,
  classNameClose,
  classNamePieChart,
  chartType,
  title,
  dataChart,
  dataAdditional,
  headerElement,
  lineChartElement,
}) => {
  const [tableOpened, setTableOpened] = useState(true);
  const [graphMode, setGraphMode] = useState<GraphType>('pie');

  const pieChartType = chartType === 'pieChart';
  const lineChartType = chartType === 'lineChart';

  const setGraphPieType = () => {
    if (graphMode !== 'pie') {
      setGraphMode('pie');
    }
  };
  const setGraphColumnType = () => {
    if (graphMode !== 'column') {
      setGraphMode('column');
    }
  };

  const chartButtons = pieChartType ? (
    <div className={styles.pieChartBlock__switch}>
      <IconButtonWrapper
        icon={<PieChartIcon />}
        onClick={setGraphPieType}
        className={cn(styles.pieChartBlock__switchItem, {
          [styles.pieChartBlock__switchItem_active]: graphMode === 'pie',
        })}
      />
      <IconButtonWrapper
        icon={<BarGraph />}
        onClick={setGraphColumnType}
        className={cn(styles.pieChartBlock__switchItem, {
          [styles.pieChartBlock__switchItem_active]: graphMode === 'column',
        })}
      />
    </div>
  ) : undefined;

  return (
    <div
      className={cn(styles.pieChartBlock, className, {
        [cn(styles.pieChartBlock_close, classNameClose)]: !tableOpened,
      })}
    >
      <TableHeader
        title={title}
        handleTableOpen={setTableOpened}
        tableOpened={tableOpened}
        endComponent={chartButtons}
        headerElement={headerElement}
      />
      <div className={styles.pieChartBlock__content}>
        {pieChartType && (
          <PieChart
            data={dataChart}
            isPie={graphMode === 'pie'}
            className={classNamePieChart}
          />
        )}
        {lineChartType && (
          <LineChart
            data={dataChart}
            dataAdditional={dataAdditional}
            lineChartElement={lineChartElement}
          />
        )}
      </div>
    </div>
  );
};

import cn from 'clsx';
import React, {
  type ButtonHTMLAttributes,
  type FC,
  type MouseEvent,
  type SVGProps,
} from 'react';

import { createBemClass } from 'components-new/helpers/createBemClass';
import { Ripple } from 'components-new/Ripple';
import { Spin } from 'components-new/Spin';

import { type Appearance, type Shape, type Size, type Variety } from '../types';

import styles from './IconButton.module.scss';

export interface IconButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  appearance?: Appearance;
  variety?: Variety;
  size?: Size;
  loading?: boolean;
  icon: SVGProps<SVGElement>;
  shape?: Shape;
  active?: boolean;
}

const rootClassName = createBemClass('icon-button');

export const IconButton: FC<IconButtonProps> = ({
  appearance = 'default',
  variety = 'primary',
  shape = 'square',
  size = 'xs',
  className,
  icon,
  disabled,
  loading,
  type = 'button',
  onMouseDown,
  active,
  ...other
}) => {
  const spinAppearance = appearance === 'flat' ? 'outline' : appearance;
  const spinSize = size === 'xxs' ? 'xxs' : 'xs';

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  const content = !loading ? (
    <>
      {icon}
      {!disabled && <Ripple appearance={appearance} />}
    </>
  ) : (
    <Spin appearance={spinAppearance} size={spinSize} variety={variety} />
  );

  return (
    <button
      {...other}
      className={cn(
        styles[rootClassName()],
        styles[rootClassName({ modName: appearance })],
        styles[rootClassName({ modName: size })],
        styles[rootClassName({ modName: variety })],
        styles[rootClassName({ modName: shape })],
        {
          [styles[rootClassName({ modName: 'loading' })]]: loading,
          [styles[rootClassName({ modName: 'active' })]]: active,
        },
        className
      )}
      disabled={disabled ?? loading}
      onMouseDown={handleClick}
      type={type}
    >
      {content}
    </button>
  );
};

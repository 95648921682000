import React from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'components/Table';
import { Contract } from 'core/types';
import { getLoading as getSystemsLoading } from 'features/Systems/ducks/selectors';

import { CONTRACTS_FULL_FIELDS } from '../../constants';
import { getLoading } from '../../ducks/selectors';
import { TableContractsTypes } from '../../types';
import { ContractsFilter } from '../CotractsFilter';

import { getDataTable } from './utils';

interface Props {
  contracts?: Contract[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const ContractsFullTable: React.FC<Props> = ({
  contracts,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const data = getDataTable(contracts);

  const loading = useSelector(getLoading);
  const systemsLoading = useSelector(getSystemsLoading);

  return (
    <Table
      data={data}
      columns={CONTRACTS_FULL_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <ContractsFilter tableType={TableContractsTypes.FULL} />
        )
      }
      disabledSort={loading}
      disabledRows={systemsLoading}
    />
  );
};

import cn from 'clsx';
import React, {
  type ButtonHTMLAttributes,
  type FC,
  type MouseEvent,
  type SVGProps,
} from 'react';

import { createBemClass } from 'components-new/helpers/createBemClass';
import { Ripple } from 'components-new/Ripple';
import { Spin } from 'components-new/Spin';
import { Appearance, Size, Variety } from 'components-new/types';

import styles from './Button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: Appearance;
  variety?: Variety;
  icon?: SVGProps<SVGElement>;
  size?: Size;
  loading?: boolean;
}

const rootClassName = createBemClass('button');

export const Button: FC<ButtonProps> = ({
  appearance = 'default',
  variety = 'primary',
  children,
  className,
  icon,
  disabled = false,
  size = 's',
  onMouseDown,
  loading = false,
  type = 'button',
  ...other
}) => {
  const isDisabled = disabled || loading;
  const spinAppearance = appearance === 'flat' ? 'outline' : appearance;
  const spinSize = size === 'xxs' ? 'xxs' : 'xs';

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  const content = (
    <>
      {icon}
      <span>{children}</span>
      {!isDisabled && <Ripple appearance={appearance} />}
    </>
  );

  return (
    <button
      {...other}
      className={cn(
        styles[rootClassName()],
        styles[rootClassName({ modName: size })],
        styles[rootClassName({ modName: appearance })],
        styles[rootClassName({ modName: variety })],
        { [styles[rootClassName({ modName: 'loading' })]]: loading },
        className
      )}
      disabled={isDisabled}
      onMouseDown={handleClick}
      type={type}
    >
      {content}
      {loading && (
        <Spin
          appearance={spinAppearance}
          className={styles[rootClassName({ elementName: 'spin' })]}
          size={spinSize}
          variety={variety}
        />
      )}
    </button>
  );
};

import { convertFromDateFormat } from 'components/DatePicker';
import { Contract, System } from 'core/types';
import { ContractSystem, SLAType } from 'features/Contract/types';
import { ContractsFilterToRequest } from 'features/Contracts/types';
import { SystemContractId } from 'features/Systems/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const request = {
  fetchContracts: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ContractsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Contract[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getContracts(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
        endDate: convertFromDateFormat(filter?.endDate),
      },
    }),
  fetchCurrentContract: (id: string) =>
    ApiClient.get<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCurrentContract(id),
      data: {},
    }),
  fetchContractsByOrganizationId: (
    organizationId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ContractsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Contract[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getContracts(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
        organizationId,
        endDate: convertFromDateFormat(filter?.endDate),
      },
    }),
  fetchContractsBySystemId: (
    systemId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ContractsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Contract[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getContractsBySystemId(systemId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filter,
    }),
  createContract: (body: Contract) =>
    ApiClient.post<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createContract(),
      data: body,
    }),
  addSystem: (body: ContractSystem) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.addSystem(),
      data: body,
    }),
  addSLA: (body: ContractSystem) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.addSLA(),
      data: body,
    }),
  editContract: (body: Contract) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.editContract(),
      data: body,
    }),
  updateContract: (data: Contract) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createContract(),
      data,
    }),
  fetchSystemListForContract: (id: string) =>
    ApiClient.get<System[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystemsForContract(id),
      data: {},
    }),
  fetchSLAForSystem: ({ contractId, systemId }: SystemContractId) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSLAForSystem(),
      params: { contract_id: contractId, system_id: systemId },
      data: {},
    }),
  updateGroupsToContractToSystem: (
    contractId: string,
    systemId: string,
    slaId: string,
    data: string[]
  ) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.updateGroupsInSystemInContract(
        contractId,
        systemId,
        slaId
      ),
      data,
    }),
  deleteContract: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.deleteContract(id),
      data: {},
    }),
  fetchSLA: (contractId: string, systemId: string) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSLA(),
      params: { contract_id: contractId, system_id: systemId },
    }),
};

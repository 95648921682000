import { SelectOption } from 'components/Select/types';
import { CustomFieldTypeForFilter } from 'components/Table/components/TableFilter/types';
import { Priority } from 'core/types';
import { KeyValueOption } from 'types/models/meta';

export interface TicketsFilterForRender
  extends Pick<
    TicketsFilterForRequest,
    'theme' | 'number' | 'onlyActive' | 'contractIds' | 'customFields'
  > {
  statuses?: Array<SelectOption>;
  priorities?: Array<SelectOption>;
  specialistIds?: Array<SelectOption>;
  memberIds?: Array<SelectOption | string>;
  workGroupIds?: string[];
  systemIds?: Array<SelectOption>;
  clientIds?: Array<SelectOption>;
  tagName?: string;
  dateSolve?: string;
  dateCreate?: string;
  typeIds?: Array<SelectOption>;
  name?: string;
}

export type TicketsFilterForRequest = {
  theme?: string;
  number?: string;
  statuses?: string[];
  priorities?: Priority[];
  specialistIds?: string[];
  dateSolveFrom?: Date;
  dateSolveTo?: Date;
  systemIds?: string[];
  clientIds?: string[];
  memberIds?: string[];
  workGroupIds?: string[];
  contractIds?: string[];
  tagName?: string;
  onlyActive?: boolean;
  dateCreateFrom?: Date;
  dateCreateTo?: Date;
  typeIds?: string[];
  customFields?: CustomFieldTypeForFilter[];
  name?: string;
};

export interface Filter {
  theme?: string;
  number?: string;
  onlyActive?: boolean;
  contractIds?: string[];
  statuses?: string[];
  priorities?: KeyValueOption[];
  specialists?: KeyValueOption[];
  workGroupIds?: string[];
  systems?: KeyValueOption[];
  clients?: KeyValueOption[];
  tagName?: string;
  dateSolveFrom: Date;
  dateSolveTo: Date;
  dateCreateFrom: Date;
  dateCreateTo: Date;
  types?: KeyValueOption[];
}

export interface FilterData {
  id: string;
  userId: string;
  title: string;
  filter: Filter;
}

export enum TableTicketsTypes {
  FULL = 'FULL',
  BY_CONTRACT_IDS = 'BY_CONTRACT_IDS',
  FROM_DESKTOP = 'FROM_DESKTOP',
  FROM_DESKTOP_HISTORY = 'FROM_DESKTOP_HISTORY',
}

export interface DefaultFilterValuesProps {
  userId?: string;
  userWorkGroupsIds?: string[];
  isClient?: boolean;
  contractIds?: string[];
  userManagerWorkGroupsIds?: string[];
  isMyTicketsPage?: boolean;
  isAllTicketsPage?: boolean;
  isGroupsTicketsPage?: boolean;
}

export interface CreateFilterRequest {
  title: string;
  ticketTab: TicketTabType;
  filter: TicketsFilterForRequest;
}

export interface DeleteFilterRequest {
  id: string;
  ticketTab: TicketTabType;
}

export interface SetCurrentFilterTitleRequest {
  title: string;
  id: string;
  ticketTab?: TicketTabType;
}

export interface FetchTickets {
  withFirstTicketFetch?: boolean;
  ticketTab?: TicketTabType;
}

export interface FetchStatuses {
  filter: TicketsFilterForRender;
}

export enum TicketsTableFields {
  TICKET_NUMBER = 'Номер тикета',
  SYSTEM = 'Система',
}

export enum TicketTabType {
  MY_TICKETS = 'MY_TICKETS',
  ALL_TICKETS = 'ALL_TICKETS',
  MY_GROUPS_TICKETS = 'MY_GROUPS_TICKETS',
}

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
  checkInvalidDate,
  convertFromDateFormat,
  convertToDateFormat,
  getFormattedDate,
  getFormattedDateRange,
} from 'components/DatePicker';
import { Size } from 'components/types';

import { DatePicker } from '../../DatePicker';
import { RangeDateType } from '../../types';
import { DropdownDatePicker } from '../DropdownDatePicker';
import { InputDatePickerProps } from '../InputDatePicker/types';

export const InputDate: FC<InputDatePickerProps> = (props) => {
  const {
    onCancel,
    onSubmit,
    onReset,
    showTime,
    onChange,
    setIsErrorRange,
    showRange,
    value,
    containerRef,
    classNameContainer,
    itemsClassName,
    size,
    isErrorRange,
    showCloseIcon,
    icon,
  } = props;

  const [pickedDate, setPickedDate] = useState<Date>();
  const [pickedDateRange, setPickedDateRange] = useState<RangeDateType>();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const handleSubmit = useCallback(() => {
    if (pickedDate && !showRange) {
      onChange(
        getFormattedDate(
          pickedDate,
          showTime ? 'dd.mm.yyyy  hh:mm' : 'dd.mm.yyyy'
        )
      );
      setIsPopoverOpen(false);
    }
    if (pickedDateRange && showRange && !isErrorRange) {
      onChange(
        getFormattedDateRange(
          pickedDateRange,
          showTime
            ? 'dd.mm.yyyy  hh:mm - dd.mm.yyyy  hh:mm'
            : 'dd.mm.yyyy - dd.mm.yyyy'
        )
      );
      setIsPopoverOpen(false);
    }
    if (!pickedDate && !pickedDateRange) {
      onChange('');
      setIsPopoverOpen(false);
    }
  }, [
    pickedDate,
    pickedDateRange,
    showRange,
    isErrorRange,
    onChange,
    getFormattedDate,
    getFormattedDateRange,
    setIsPopoverOpen,
  ]);

  useEffect(() => {
    if (value) {
      if (!showRange) {
        setPickedDate(convertFromDateFormat(value));
        return;
      }

      if (isErrorRange) {
        setIsErrorRange(false);
      }

      const isError = checkInvalidDate(value);
      if (isError) {
        setIsErrorRange(isError);
        return;
      }

      setPickedDateRange({
        from: convertFromDateFormat(value),
        to: convertToDateFormat(value),
      });
      return;
    }
    setPickedDateRange(undefined);
    setPickedDate(undefined);
  }, [showRange, value, isPopoverOpen, isErrorRange, setIsErrorRange]);

  const handleCancel = () => setIsPopoverOpen(false);

  const datePicker = useMemo(
    () =>
      showRange ? (
        <DatePicker
          pickedDate={pickedDateRange}
          setPickedDate={setPickedDateRange}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          showTime={showTime}
          showRange
          className={classNameContainer}
        />
      ) : (
        <DatePicker
          pickedDate={pickedDate}
          setPickedDate={setPickedDate}
          onCancel={onCancel || handleCancel}
          onSubmit={onSubmit || handleSubmit}
          showTime={showTime}
          showRange={false}
          className={classNameContainer}
        />
      ),
    [showRange, pickedDateRange, pickedDate]
  );

  return (
    <DropdownDatePicker
      isActive={isPopoverOpen}
      showCloseIcon={showCloseIcon}
      setIsActive={togglePopover}
      onReset={onReset}
      containerRef={containerRef}
      itemsClassName={itemsClassName}
      isTableFilter={size === Size.xs}
      icon={icon}
    >
      {datePicker}
    </DropdownDatePicker>
  );
};

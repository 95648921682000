import cn from 'clsx';
import React, { SVGProps } from 'react';

import { InfoIcon } from 'assets/icons';

import { Typography, TypographyVariants } from '../Typography';

import styles from './InfoBlock.module.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  mainTitle?: string | JSX.Element;
  info?: string | JSX.Element;
  icon?: SVGProps<SVGElement>;
  classNameIconWrapper?: string;
  classNameTitle?: string;
  classNameInfo?: string;
  classNameInfoWrapper?: string;
  backgroundColor?: string;
  dataInfoIcon?: string;
}

export const InfoBlock: React.FC<Props> = ({
  mainTitle,
  info,
  icon,
  className,
  classNameIconWrapper,
  classNameTitle,
  classNameInfo,
  classNameInfoWrapper,
  backgroundColor,
  dataInfoIcon,
  ...otherProps
}) => {
  const iconWrapperStyles = backgroundColor
    ? {
        backgroundColor: `var(--${backgroundColor})`,
      }
    : undefined;

  const content = mainTitle && (
    <div className={cn(styles.infoBlock__infoWrapper, classNameInfoWrapper)}>
      <div className={styles.infoBlock__titleBlock}>
        <Typography
          variant={TypographyVariants.s2}
          component="div"
          className={cn(styles.infoBlock__title, classNameTitle)}
        >
          {mainTitle}
        </Typography>
        {dataInfoIcon && (
          <InfoIcon
            className={styles.infoBlock__notificationIcon}
            id={dataInfoIcon}
            data-tip
            data-for={dataInfoIcon}
          />
        )}
      </div>
      {info && (
        <Typography
          variant={TypographyVariants.b2}
          component="div"
          className={cn(styles.infoBlock__info, classNameInfo)}
        >
          {info}
        </Typography>
      )}
    </div>
  );
  return (
    <div className={cn(styles.infoBlock, className)} {...otherProps}>
      <div
        className={cn(styles.infoBlock__iconWrapper, classNameIconWrapper)}
        style={iconWrapperStyles}
      >
        {icon}
      </div>
      {content}
    </div>
  );
};

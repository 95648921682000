import {
  Action,
  CustomFieldToRequest,
  CustomTicketFields,
  Status,
  System,
} from 'core/types';
import {
  CheckExistIntegration,
  CloseTicketRequest,
  Comment,
  CreateTicketInJiraPayload,
  NextStatusesRequest,
  Ticket,
  TicketHistory,
  TicketUpdateType,
} from 'features/Ticket/types';

import {
  AddSpecAndSetToWorkAction,
  AddSpecAndSetToWorkPayload,
  ChangeStatusAction,
  CloseTicketRequestAction,
  CreateTicketInJiraAction,
  DeleteTicketRequestAction,
  EditTicketPriorityAction,
  EditTicketPriorityPayload,
  FetchActionsForTicketDetailSuccessAction,
  FetchCurrentTicketSystemRequestAction,
  FetchHistoryRequestAction,
  FetchHistorySuccessAction,
  FetchIsSystemIntegratedWithJiraRequestAction,
  FetchIsSystemIntegratedWithJiraSuccessAction,
  FetchJiraUrlRequestAction,
  FetchJiraUrlSuccessAction,
  FetchNextStatusesRequestAction,
  FetchNextStatusesSuccessAction,
  FetchTicketRequestAction,
  FetchTicketRequestPayload,
  HideTicketLoadingAction,
  ResetTicketDetailStateAction,
  RestoreTicketRequestAction,
  SetIsErrorAction,
  SetIsSuccessAction,
  SetTicketDetailSuccessAction,
  SetTicketSystemDetailSuccessAction,
  ShowTicketLoadingAction,
  TicketDetail,
  TicketHistoryLoadingHide,
  TicketHistoryLoadingShow,
  UpdateDataTicketRequestAction,
} from './types';

export const showTicketLoading = (): ShowTicketLoadingAction => {
  return {
    type: TicketDetail.TICKET_DETAIL_LOADING_SHOW,
  };
};

export const hideTicketLoading = (): HideTicketLoadingAction => {
  return {
    type: TicketDetail.TICKET_DETAIL_LOADING_HIDE,
  };
};

export const showTicketHistoryLoading = (): TicketHistoryLoadingShow => {
  return {
    type: TicketDetail.TICKET_HISTORY_LOADING_SHOW,
  };
};

export const hideTicketHistoryLoading = (): TicketHistoryLoadingHide => {
  return {
    type: TicketDetail.TICKET_HISTORY_LOADING_HIDE,
  };
};

export const fetchTicketRequest = (
  payload: FetchTicketRequestPayload
): FetchTicketRequestAction => {
  return {
    type: TicketDetail.FETCH_TICKET_DETAIL_REQUEST,
    payload,
  };
};

export const setCurrentTicket = (
  data?: Ticket
): SetTicketDetailSuccessAction => {
  return {
    type: TicketDetail.SET_TICKET_DETAIL_SUCCESS,
    payload: data,
  };
};

export const fetchCurrentTicketSystemRequest = (
  id: string
): FetchCurrentTicketSystemRequestAction => {
  return {
    type: TicketDetail.FETCH_TICKET_SYSTEM_DETAIL_REQUEST,
    payload: id,
  };
};

export const setCurrentTicketSystemSuccess = (
  data?: System
): SetTicketSystemDetailSuccessAction => {
  return {
    type: TicketDetail.SET_TICKET_SYSTEM_DETAIL_SUCCESS,
    payload: data,
  };
};

export const addSpecAndSetToWork = (
  payload: AddSpecAndSetToWorkPayload
): AddSpecAndSetToWorkAction => {
  return {
    type: TicketDetail.ADD_SPEC_AND_SET_TO_WORK_TICKET_DETAIL,
    payload,
  };
};

export const fetchHistoryRequest = (
  ticketId: string
): FetchHistoryRequestAction => {
  return {
    type: TicketDetail.FETCH_HISTORY_REQUEST,
    payload: ticketId,
  };
};

export const fetchHistorySuccess = (
  history: TicketHistory[]
): FetchHistorySuccessAction => {
  return {
    type: TicketDetail.FETCH_HISTORY_SUCCESS,
    payload: history,
  };
};

export const resetTicketState = (): ResetTicketDetailStateAction => {
  return {
    type: TicketDetail.RESET_TICKET_DETAIL_STATE,
  };
};

export const editTicketPriority = (
  payload: EditTicketPriorityPayload
): EditTicketPriorityAction => {
  return {
    payload,
    type: TicketDetail.EDIT_TICKET_PRIORITY,
  };
};

export const setIsSuccess = (payload: boolean): SetIsSuccessAction => {
  return {
    payload,
    type: TicketDetail.SET_IS_SUCCESS,
  };
};

export const setIsError = (payload: boolean): SetIsErrorAction => {
  return {
    payload,
    type: TicketDetail.SET_IS_ERROR,
  };
};

export const fetchActionsForCurrentTicketSuccess = (
  payload: Action[]
): FetchActionsForTicketDetailSuccessAction => {
  return {
    payload,
    type: TicketDetail.FETCH_ACTIONS_FOR_TICKET_DETAIL_SUCCESS,
  };
};

export const deleteTicketRequest = (
  ticket: Ticket
): DeleteTicketRequestAction => {
  return {
    type: TicketDetail.DELETE_TICKET_DETAIL_REQUEST,
    payload: ticket,
  };
};

export const updateDataTicketRequest = (
  ticket: TicketUpdateType
): UpdateDataTicketRequestAction => {
  return {
    type: TicketDetail.UPDATE_DATA_TICKET_DETAIL,
    payload: ticket,
  };
};

export const createTicketInJira = (
  payload: CreateTicketInJiraPayload
): CreateTicketInJiraAction => {
  return {
    type: TicketDetail.CREATE_TICKET_IN_JIRA,
    payload,
  };
};

export const fetchIsSystemIntegratedWithJiraRequest = (
  payload: CheckExistIntegration
): FetchIsSystemIntegratedWithJiraRequestAction => {
  return {
    type: TicketDetail.FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_REQUEST,
    payload,
  };
};

export const fetchIsSystemIntegratedWithJiraSuccess = (
  payload: boolean
): FetchIsSystemIntegratedWithJiraSuccessAction => {
  return {
    type: TicketDetail.FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_SUCCESS,
    payload,
  };
};

export const fetchJiraUrlRequest = (
  ticketId: string
): FetchJiraUrlRequestAction => {
  return {
    type: TicketDetail.FETCH_JIRA_URL_REQUEST,
    payload: ticketId,
  };
};

export const fetchJiraUrlSuccess = (
  payload: string
): FetchJiraUrlSuccessAction => {
  return {
    type: TicketDetail.FETCH_JIRA_URL_SUCCESS,
    payload,
  };
};

export const fetchCustomFieldsByTicketIdRequest = (payload: string) => {
  return {
    type: TicketDetail.FETCH_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST,
    payload,
  };
};

export const fetchCustomFieldsByTicketIdSuccess = (
  payload: CustomTicketFields
) => {
  return {
    type: TicketDetail.FETCH_CUSTOM_FIELDS_BY_TICKET_ID_SUCCESS,
    payload,
  };
};

export const updateCustomFieldsByTicketIdRequest = (
  payload: CustomFieldToRequest[]
) => {
  return {
    type: TicketDetail.UPDATE_CUSTOM_FIELDS_BY_TICKET_ID_REQUEST,
    payload,
  };
};

export const fetchNextStatusesRequest = (
  payload: NextStatusesRequest
): FetchNextStatusesRequestAction => {
  return {
    type: TicketDetail.FETCH_NEXT_STATUSES_REQUEST,
    payload,
  };
};

export const fetchNextStatusesSuccess = (
  payload: Status[]
): FetchNextStatusesSuccessAction => {
  return {
    type: TicketDetail.FETCH_NEXT_STATUSES_SUCCESS,
    payload,
  };
};

export const changeStatus = (payload: Comment): ChangeStatusAction => {
  return {
    type: TicketDetail.CHANGE_STATUS,
    payload,
  };
};

export const closeTicketRequest = (
  payload: CloseTicketRequest
): CloseTicketRequestAction => {
  return {
    type: TicketDetail.CLOSE_TICKET_REQUEST,
    payload,
  };
};

export const restoreTicketRequest = (
  payload?: string
): RestoreTicketRequestAction => {
  return {
    type: TicketDetail.RESTORE_TICKET_REQUEST,
    payload,
  };
};

import cn from 'clsx';
import React from 'react';

import { Clock12 } from 'assets/icons';
import { ConditionBlock } from 'components/ConditionBlock';
import { InfoBlock } from 'components/InfoBlock';
import { Typography, TypographyVariants } from 'components/Typography';
import { SLA_TYPE_TITLES_MAP } from 'features/SLA/constants';
import { SLADuration } from 'features/SLA/types';
import { TicketSLAType } from 'features/Ticket/types';
import { getFormatDisplayedTime } from 'utils';

import styles from './EmployeesSlaTimer.module.scss';

const slaTypeStylesMap = {
  [TicketSLAType.DECISION]: styles.employeesSlaTimer__condition_decision,
  [TicketSLAType.REACTION]: styles.employeesSlaTimer__condition_reaction,
};

interface Props {
  type: TicketSLAType;
  avgTime: SLADuration;
  avgExpireTime: SLADuration;
  className?: string;
}

export const EmployeesSLATimer: React.FC<Props> = ({
  type,
  className,
  avgTime,
  avgExpireTime,
}) => {
  const isDecision = type === TicketSLAType.DECISION;

  return (
    <div className={cn(styles.employeesSlaTimer, className)}>
      <div className={styles.employeesSlaTimer__leftBlock}>
        <InfoBlock
          icon={
            <Clock12
              className={cn(styles.employeesSlaTimer__clock, {
                [styles.employeesSlaTimer__clock_decision]: isDecision,
              })}
            />
          }
          classNameIconWrapper={cn(styles.employeesSlaTimer__iconWrapper, {
            [styles.employeesSlaTimer__iconWrapper_decision]: isDecision,
          })}
        />
        <ConditionBlock
          text={SLA_TYPE_TITLES_MAP[type || TicketSLAType.REACTION]}
          className={cn(
            styles.employeesSlaTimer__condition,
            slaTypeStylesMap[type || TicketSLAType.REACTION]
          )}
        />
      </div>
      <div className={styles.employeesSlaTimer__rightBlock}>
        <Typography
          variant={TypographyVariants.b3}
          className={styles.employeesSlaTimer__title}
        >
          Выполнено
        </Typography>
        <div className={styles.employeesSlaTimer__timeWrapper}>
          <Typography variant={TypographyVariants.h1}>
            {getFormatDisplayedTime(avgTime.hours)}
          </Typography>
          <Typography
            variant={TypographyVariants.h1}
            className={styles.employeesSlaTimer__separator}
          >
            {' '}
            :{' '}
          </Typography>
          <Typography variant={TypographyVariants.h1}>
            {getFormatDisplayedTime(avgTime.minutes)}
          </Typography>
          <Typography
            variant={TypographyVariants.h1}
            className={styles.employeesSlaTimer__separator}
          >
            {' '}
            :{' '}
          </Typography>
          <Typography variant={TypographyVariants.h1}>
            {getFormatDisplayedTime(avgTime.seconds)}
          </Typography>
        </div>
        <hr className={styles.employeesSlaTimer__hr} />
        <Typography
          variant={TypographyVariants.b3}
          className={styles.employeesSlaTimer__title}
        >
          Просрочено
        </Typography>
        <div
          className={cn(
            styles.employeesSlaTimer__timeWrapper,
            styles.employeesSlaTimer__timeWrapper_expired
          )}
        >
          <Typography variant={TypographyVariants.h3}>
            {getFormatDisplayedTime(avgExpireTime.hours)}
          </Typography>
          <Typography
            variant={TypographyVariants.h3}
            className={cn(
              styles.employeesSlaTimer__separator,
              styles.employeesSlaTimer__separator_expired
            )}
          >
            {' '}
            :{' '}
          </Typography>
          <Typography variant={TypographyVariants.h3}>
            {getFormatDisplayedTime(avgExpireTime.minutes)}
          </Typography>
          <Typography
            variant={TypographyVariants.h3}
            className={cn(
              styles.employeesSlaTimer__separator,
              styles.employeesSlaTimer__separator_expired
            )}
          >
            {' '}
            :{' '}
          </Typography>
          <Typography variant={TypographyVariants.h3}>
            {getFormatDisplayedTime(avgExpireTime.seconds)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

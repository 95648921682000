import cn from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ClockFat, MegaphoneIcon } from 'assets/icons';
import { NotificationCircle } from 'components/NotificationCircle';
import { Popover } from 'components/Popover';
import { ButtonType } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { ApproveOrCancel } from 'core/modals/ApproveOrCancel';
import { getIsAccessedAction, getIsAdmin } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { PUBLISHED_NEWS_PATH } from 'features/Profile/constants';
import { getOrganizationsCurrentNews } from 'features/Profile/ducks/selectors';
import { getFormatDate } from 'utils';

import { newsDelete } from '../../ducks/actions';
import { News as NewsType } from '../../types';
import { NewsPopoverContent } from '../NewsPopoverContent';

import styles from './News.module.scss';

export interface Props {
  className?: string;
  disabled?: boolean;
  news: NewsType;
  onClick?(): void;
}

export const News: React.FC<Props> = React.memo(
  ({ className, disabled, news, onClick }) => {
    const { pathname } = useLocation();

    const organizationsCurrentNews = useSelector(getOrganizationsCurrentNews);
    const isAccessToEditNews = useSelector(
      getIsAccessedAction(ActionList.EditNews)
    );
    const isAdmin = useSelector(getIsAdmin);

    const isDisabled = !isAdmin && !organizationsCurrentNews?.length;
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);

    const published = pathname === PUBLISHED_NEWS_PATH;

    const dispatch = useDispatch();

    const togglePopover = () => {
      setIsPopoverOpen((prevState) => !prevState);
    };

    const toggleIsDeleteModal = () => {
      setIsDeleteModal((prevState) => !prevState);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.preventDefault();
    };

    const onDelete = useCallback(
      (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event?.stopPropagation();
        togglePopover();
        if (news.id) {
          dispatch(newsDelete({ id: news.id, published }));
        }
      },
      [togglePopover, newsDelete, news.id, published]
    );

    const onClickHandler = !disabled ? onClick : undefined;

    const tabIndex = onClick && !disabled ? 0 : -1;

    const approveOrCancelProps = useMemo(
      () => ({
        onApprove: onDelete,
        isModal: isDeleteModal,
        toggleModal: toggleIsDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline' as ButtonType,
          className: styles.news__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить новость "${news.title}"?`,
      }),
      [onDelete, isDeleteModal, toggleIsDeleteModal, news.title]
    );

    const circle = (
      <NotificationCircle
        className={cn(styles.news__circle, {
          [styles.news__circle_hide]: news.checked,
        })}
      />
    );

    const date = (
      <div className={styles.news__timeWrapper}>
        <ClockFat className={styles.news__timeIcon} />
        <span className={styles.news__time}>
          {getFormatDate(
            (!published ? news?.created : news.publicationDate) || ''
          )}
        </span>
      </div>
    );

    const deleteModal = approveOrCancelProps && (
      <ApproveOrCancel {...approveOrCancelProps} />
    );

    const popover = isAccessToEditNews && (
      <Popover
        isOpen={isPopoverOpen}
        togglePopover={togglePopover}
        disabled={isDisabled}
        content={
          <NewsPopoverContent
            onDelete={toggleIsDeleteModal}
            toggleIsPopoverOpen={togglePopover}
          />
        }
        positions={['bottom', 'left', 'right', 'top']}
        align="end"
        padding={4}
        className={styles.news__popover}
      />
    );

    return (
      <div
        className={cn(styles.news, className)}
        onClick={onClickHandler}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        role="button"
      >
        <div className={cn(styles.news__icon)}>
          <MegaphoneIcon />
        </div>
        <div className={styles.news__infoBlock}>
          <div className={styles.news__header}>
            <div className={styles.news__headerText}>
              <Typography
                variant={TypographyVariants.h5}
                component="div"
                className={styles.news__title}
              >
                {news.title}
              </Typography>
              <p className={styles.news__text}>{news.text}</p>
            </div>
            <div className={styles.news__rightWrapper}>
              {circle}
              {popover}
            </div>
          </div>
          <div className={styles.news__footer}>{date}</div>
        </div>
        {deleteModal}
      </div>
    );
  }
);

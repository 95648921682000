import React from 'react';

import { SearchThinIcon } from 'assets/icons';
import { Typography, TypographyVariants } from 'components/Typography';

import styles from './UserAssistanceSearchState.module.scss';

export const UserAssistanceSearchState = () => {
  return (
    <div className={styles.userAssistanceSearchState}>
      <SearchThinIcon className={styles.userAssistanceSearchState__icon} />
      <Typography
        variant={TypographyVariants.b2}
        className={styles.userAssistanceSearchState__text}
      >
        Начните поиск для выбора статей
      </Typography>
    </div>
  );
};

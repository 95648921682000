export enum NotificationType {
  SPECIALIST_TOOK_TICKET_TO_WORK = 'SPECIALIST_TOOK_TICKET_TO_WORK',
  ASSIGNED_ANOTHER_SPECIALIST = 'ASSIGNED_ANOTHER_SPECIALIST',
  SPECIALIST_SENT_INFORMATION_REQUEST = 'SPECIALIST_SENT_INFORMATION_REQUEST',
  SPECIALIST_SUGGESTED_SOLUTION = 'SPECIALIST_SUGGESTED_SOLUTION',
  YOU_HAVE_BEEN_ASSIGNED_TICKET = 'YOU_HAVE_BEEN_ASSIGNED_TICKET',
  TICKET_WAS_ASSIGNED_ANOTHER_SPECIALIST = 'TICKET_WAS_ASSIGNED_ANOTHER_SPECIALIST',
  CLIENT_PROVIDED_INFORMATION = 'CLIENT_PROVIDED_INFORMATION',
  CLIENT_REJECTED_SOLUTION = 'CLIENT_REJECTED_SOLUTION',
  CLIENT_ACCEPTED_SOLUTION = 'CLIENT_ACCEPTED_SOLUTION',
  SLA_DECISION_EXPIRING = 'SLA_DECISION_EXPIRING',
  SLA_DECISION_EXPIRED = 'SLA_DECISION_EXPIRED',
  SLA_REACTION_EXPIRING = 'SLA_REACTION_EXPIRING',
  SLA_REACTION_EXPIRED = 'SLA_REACTION_EXPIRED',
  SLA_DECISION_EXPIRED_FOR_MANAGER = 'SLA_DECISION_EXPIRED_FOR_MANAGER',
  TICKET_AUTO_CLOSE_NOTIFICATION = 'TICKET_AUTO_CLOSE_NOTIFICATION',
  CLIENT_CREATED_NEW_TICKET = 'CLIENT_CREATED_NEW_TICKET',
  STATUS_CHANGE_FOR_SPECIALIST = 'STATUS_CHANGE_FOR_SPECIALIST',
  STATUS_CHANGE_FOR_CLIENT = 'STATUS_CHANGE_FOR_CLIENT',
  SYSTEM_INDEX_CHANGE = 'SYSTEM_INDEX_CHANGE',
  TICKET_WAS_CLOSED_BY_ADMIN = 'TICKET_WAS_CLOSED_BY_ADMIN',
  NEW_COMMENT = 'NEW_COMMENT',
  NEW_FILE_UPLOAD_IN_STORAGE = 'NEW_FILE_UPLOAD_IN_STORAGE',
  FILE_UPDATED_IN_STORAGE = 'FILE_UPDATED_IN_STORAGE',
  STORAGE_ACCESS_GRANTED = 'STORAGE_ACCESS_GRANTED',
  TICKET_WAS_DELETED = 'TICKET_WAS_DELETED',
  TICKET_PRIORITY_CHANGED = 'TICKET_PRIORITY_CHANGED',
  YOU_HAVE_BEEN_ASSIGNED_TICKET_BY_SYSTEM = 'YOU_HAVE_BEEN_ASSIGNED_TICKET_BY_SYSTEM',
  TICKET_WAS_RESTORED = 'TICKET_WAS_RESTORED',
}

import React from 'react';

import { LabelIcon } from 'assets/icons';
import { Typography, TypographyVariants } from 'components/Typography';
import {
  getCountTickets,
  getTicketsPercents as getPercents,
} from 'features/Desktop/utils';
import { SLAProgressBar } from 'features/SLA/components/SLAProgressBar';

import { KpiData } from '../../types';
import { getActiveTicketsData, getTicketPercentsData } from '../../utils';

import styles from './KpiActiveTickets.module.scss';

interface Props {
  kpiData?: KpiData;
}

export const KpiActiveTickets: React.FC<Props> = ({ kpiData }) => {
  const getActiveTickets = getActiveTicketsData(kpiData);
  const getTicketPercents = getTicketPercentsData(getActiveTickets);

  const statusDetails = kpiData?.info.activeTickets.statusDetails;

  const allActiveTickets = (
    <div className={styles.activeTicketsPanel__block}>
      <div>
        <div className={styles.activeTicketsPanel__blockIconAllContainer}>
          <LabelIcon className={styles.activeTicketsPanel__blockIconAll} />
        </div>
        <Typography
          variant={TypographyVariants.h5}
          className={styles.activeTicketsPanel__blockAll}
        >
          Всего
        </Typography>
        <Typography
          variant={TypographyVariants.d2}
          className={styles.activeTicketsPanel__blockAllQuantity}
        >
          {getActiveTickets.totalCount()}
        </Typography>
      </div>
      <div>
        {statusDetails?.map((status) => (
          <div
            key={status.titleStatus}
            className={styles.activeTicketsPanel__field}
          >
            <Typography
              variant={TypographyVariants.h2}
              className={styles.activeTicketsPanel__fieldQuantity}
            >
              {getCountTickets(status.quantity)}
            </Typography>
            <div className={styles.activeTicketsPanel__fieldContainer}>
              <Typography
                variant={TypographyVariants.o}
                className={styles.activeTicketsPanel__fieldText}
              >
                {status.titleStatus}
              </Typography>
              <SLAProgressBar
                expirePercents={getPercents(
                  getActiveTickets.totalCount(),
                  status.quantity
                )}
                color={status.color}
                className={styles.activeTicketsPanel__fieldProgressBar}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const expireActiveTickets = (
    <div className={styles.activeTicketsPanel__block}>
      <div>
        <div className={styles.activeTicketsPanel__blockIconExpireContainer}>
          <LabelIcon className={styles.activeTicketsPanel__blockIconExpire} />
        </div>
        <Typography
          variant={TypographyVariants.h5}
          className={styles.activeTicketsPanel__blockAll}
        >
          Просрочено
        </Typography>
        <Typography
          variant={TypographyVariants.d2}
          className={styles.activeTicketsPanel__blockAllQuantity}
        >
          {getActiveTickets.expiredCount()}
        </Typography>
      </div>
      <div>
        <div className={styles.activeTicketsPanel__field}>
          <Typography
            variant={TypographyVariants.h2}
            className={styles.activeTicketsPanel__fieldQuantity}
          >
            {getActiveTickets.expiredInWork()}
          </Typography>
          <div className={styles.activeTicketsPanel__fieldContainer}>
            <Typography
              variant={TypographyVariants.o}
              className={styles.activeTicketsPanel__fieldText}
            >
              Решение
            </Typography>
            <SLAProgressBar
              expirePercents={getTicketPercents.expiredInWork()}
              className={styles.activeTicketsPanel__fieldProgressBar}
              classNameLoaded={
                styles.activeTicketsPanel__fieldProgressBar_loadedWork
              }
            />
          </div>
        </div>
        <div className={styles.activeTicketsPanel__field}>
          <Typography
            variant={TypographyVariants.h2}
            className={styles.activeTicketsPanel__fieldQuantity}
          >
            {getActiveTickets.expiredNew()}
          </Typography>
          <div className={styles.activeTicketsPanel__fieldContainer}>
            <Typography
              variant={TypographyVariants.o}
              className={styles.activeTicketsPanel__fieldText}
            >
              Реакция
            </Typography>
            <SLAProgressBar
              expirePercents={getTicketPercents.expiredNew()}
              className={styles.activeTicketsPanel__fieldProgressBar}
              classNameLoaded={
                styles.activeTicketsPanel__fieldProgressBar_loadedNew
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.activeTicketsPanel}>
      <Typography variant={TypographyVariants.h2}>Активные тикеты</Typography>
      <div className={styles.activeTicketsPanel__content}>
        {allActiveTickets}
        <div className={styles.activeTicketsPanel__contentLine} />
        {expireActiveTickets}
      </div>
    </div>
  );
};

import { ProcedureType } from 'core/types';
import { Languages } from 'i18n/types';

const TICKET_TYPE_TEXT_MAP = {
  [ProcedureType.INCIDENT]: 'Инцидент',
  [ProcedureType.CONSULTATION]: 'Консультация',
};

const TICKET_TYPE_TEXT_MAP_EN = {
  [ProcedureType.INCIDENT]: 'Incident',
  [ProcedureType.CONSULTATION]: 'Consultation',
};

export const getTicketTypeTextMap = (language: Languages) => {
  const isRU = language.includes(Languages.RU);
  return isRU ? TICKET_TYPE_TEXT_MAP : TICKET_TYPE_TEXT_MAP_EN;
};

import { RenderTypes, TableColumns } from 'components/Table/types';
import { RoleType } from 'core/types';

import { TableRolesTypes } from './types';

export const ROLES_FULL_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'name',
    fieldName: 'name',
  },
  {
    title: 'Тип',
    sortField: 'type',
    fieldName: 'type',
    renderType: RenderTypes.CONDITION,
  },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
  {
    title: 'Руководящая роль',
    fieldName: 'manager',
  },
  {
    title: 'Организация',
    fieldName: 'organization',
  },
];

export const ROLES_FROM_USERS_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'name',
    fieldName: 'name',
  },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
];

export const ROLES_ADD_MODAL_FIELDS: TableColumns = [
  {
    title: 'Название',
    fieldName: 'name',
  },
  { title: 'Описание', fieldName: 'description' },
];

export const ROLE_TYPE_TITLE = {
  [RoleType.ROLE_IN_GROUP]: 'Роль в группе',
  [RoleType.ROLE_IN_SYSTEM]: 'Роль в системе',
};

export const SLIDE_RADIO_TABS = [
  {
    title: 'Роль в системе',
    value: RoleType.ROLE_IN_SYSTEM,
  },
  {
    title: 'Роль в группе',
    value: RoleType.ROLE_IN_GROUP,
  },
];

export const ROLES_OPTIONS = [
  { title: 'Роли в группе', value: RoleType.ROLE_IN_GROUP },
  { title: 'Роли в системе', value: RoleType.ROLE_IN_SYSTEM },
];

export const MANAGER_OPTIONS = [
  { title: 'Руководитель', value: 'isManager' },
  { title: 'Не руководитель', value: 'notManager' },
];

export const INITIAL_ROLES_FILTER = {
  name: '',
  description: '',
  organizationId: null,
  type: null,
  manager: null,
};

export const PAGINATION_RANGE_MAP: Record<TableRolesTypes, number | undefined> =
  {
    [TableRolesTypes.FULL]: 1,
    [TableRolesTypes.FROM_USERS]: 0,
    [TableRolesTypes.FROM_GROUPS]: 0,
    [TableRolesTypes.ADD_MODAL]: 0,
  };

export const TABLE_TITLES = {
  [TableRolesTypes.FULL]: 'Роли',
  [TableRolesTypes.FROM_USERS]: 'Роли в системе',
  [TableRolesTypes.FROM_GROUPS]: 'Список ролей',
  [TableRolesTypes.ADD_MODAL]: 'Добавить роль',
};

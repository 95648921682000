import React, { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useWebSocket, { SendMessage } from 'react-use-websocket';

import { getUserId } from 'features/Auth/ducks/selectors';
import { splitEntitiesByType } from 'features/Notifications/utils';
import { updateTicketByIdRequest } from 'features/Tickets/ducks/actions';
import { getToken } from 'utils/authService';

import { NotificationToast } from '../../components/NotificationToast';
import {
  addCurrentNotification,
  setAllNotifications,
  setNotificationsMyTicketsIds,
  setNotificationsNewTicketsIds,
} from '../../ducks/actions';
import { getCurrentNotifications } from '../../ducks/selectors';
import {
  EntityTypes,
  Notification,
  NotificationContent,
  NotificationType,
} from '../../types';

import styles from './NotificationsLayout.module.scss';
import { getSocketUrl } from './utils';

interface NotificationContext {
  sendMessage: SendMessage;
}

export const NotificationsLayoutContext = createContext<NotificationContext>({
  sendMessage: () => null,
});

export const NotificationsLayout: React.FC = ({ children }) => {
  const userId = useSelector(getUserId);
  const currentNotifications = useSelector(getCurrentNotifications) || [];

  const [entities, setEntities] = useState<
    Partial<Record<EntityTypes, NotificationContent[]>>
  >({});
  const [socketUrl] = useState(getSocketUrl(userId));

  const didUnmount = useRef(false);

  useEffect(() => {
    setEntities(splitEntitiesByType(currentNotifications));
  }, [currentNotifications]);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  const { lastJsonMessage, sendMessage } = useWebSocket(socketUrl, {
    protocols: ['access_token', getToken() || ''],
    shouldReconnect: () => {
      return !didUnmount.current;
    },
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  const notificationActionsMap = {
    [NotificationType.ALL_CHANGES]: (content: NotificationContent) =>
      dispatch(
        setAllNotifications(content as unknown as NotificationContent[])
      ),
    [NotificationType.STATUS_CHANGE]: (content: NotificationContent) => {
      dispatch(addCurrentNotification(content));
      if (content.entityId) {
        dispatch(updateTicketByIdRequest(content.entityId));
      }
    },
    [NotificationType.MY_TICKETS]: (content: NotificationContent) =>
      dispatch(setNotificationsMyTicketsIds(content.ticketIds || [])),
    [NotificationType.NEW_TICKETS]: (content: NotificationContent) =>
      dispatch(setNotificationsNewTicketsIds(content.ticketIds || [])),
  };

  useEffect(() => {
    if (lastJsonMessage) {
      const { type, content } = lastJsonMessage as Notification;

      notificationActionsMap[type](content);
    }
  }, [lastJsonMessage]);

  const singleNotification = (notification: NotificationContent) => (
    <CSSTransition
      key={notification.id}
      timeout={500}
      className={styles.notificationsLayout__toast}
      classNames={styles.notificationsLayout__toast}
      unmountOnExit
    >
      <NotificationToast notification={notification} />
    </CSSTransition>
  );

  const multiNotification = (
    notifications?: NotificationContent[],
    type?: EntityTypes
  ) => {
    const entitiesList = notifications?.map((notification) => {
      return JSON.stringify({
        id: notification.entityId,
        number: notification.linkName,
      });
    });
    const uniqEntitiesList = Array.from(new Set(entitiesList));
    return (
      Boolean(notifications?.length) && (
        <CSSTransition
          timeout={500}
          className={styles.notificationsLayout__toast}
          classNames={styles.notificationsLayout__toast}
          unmountOnExit
        >
          <NotificationToast
            entitiesType={type}
            entities={uniqEntitiesList}
            notificationCount={notifications?.length}
          />
        </CSSTransition>
      )
    );
  };

  const notificationsList = Object.keys(entities).map((type) => {
    if (entities[type as EntityTypes]?.length) {
      return Number(entities[type as EntityTypes]?.length) <= 2
        ? entities[type as EntityTypes]?.map((entity) =>
            singleNotification(entity)
          )
        : multiNotification(entities[type as EntityTypes], type as EntityTypes);
    }
    return null;
  });

  return (
    <NotificationsLayoutContext.Provider value={{ sendMessage }}>
      <TransitionGroup exit enter appear className={styles.notificationsLayout}>
        {notificationsList}
      </TransitionGroup>
      {children}
    </NotificationsLayoutContext.Provider>
  );
};

import cn from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { CalendarIcon, CloseIcon } from 'assets/icons';
import { IconButton } from 'components/IconButton';
import { Popover } from 'components/Popover';

import { DropdownProps } from '../../types';

import styles from './DropdownDatePicker.module.scss';

export const DropdownDatePicker: FC<PropsWithChildren<DropdownProps>> = ({
  children,
  itemsClassName,
  isActive: isPopoverOpen,
  showCloseIcon,
  setIsActive: togglePopover,
  onReset,
  isTableFilter,
  icon,
}) => {
  const dropContent = (
    <div className={cn(styles.dropdownDatePicker__items, itemsClassName)}>
      {children}
    </div>
  );
  const handleReset = () => {
    onReset?.();
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      togglePopover={togglePopover}
      content={dropContent}
      positions={['bottom', 'left', 'right', 'top']}
      padding={24}
      align="center"
    >
      <div className={styles.dropdownDatePicker}>
        {showCloseIcon && (
          <IconButton
            icon={<CloseIcon />}
            appearance="flat"
            type="button"
            onClick={handleReset}
            className={cn(styles.dropdownDatePicker__icon, {
              [styles.dropdownDatePicker__icon_tableFilter]: isTableFilter,
            })}
          />
        )}
        {icon}
        <div className={styles.dropdownDatePicker__divider} />
        <IconButton
          icon={<CalendarIcon />}
          appearance="flat"
          type="button"
          onClick={togglePopover}
          className={cn(styles.dropdownDatePicker__icon, {
            [styles.dropdownDatePicker__icon_tableFilter]: isTableFilter,
          })}
        />
      </div>
    </Popover>
  );
};

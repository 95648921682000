import cn from 'clsx';
import React from 'react';

import styles from './Row.module.scss';

interface Props {
  className?: string;
  disabled?: boolean;
}

export const Row: React.FC<Props> = ({
  children,
  className,
  disabled = false,
}) => {
  return (
    <div
      className={cn(styles.row, className, { [styles.row_disabled]: disabled })}
    >
      {children}
    </div>
  );
};

import cn from 'clsx';
import React from 'react';

import styles from './PopoverLine.module.scss';

interface Props {
  className?: string;
}

export const PopoverLine: React.FC<Props> = ({ className }) => {
  return <div className={cn(styles.popoverLine, className)} />;
};

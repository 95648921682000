import { debounce } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';

import { Select } from 'components/Select';
import { SelectOption, SelectProps } from 'components/Select/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { FetchOrganizations, OrganizationsFilter } from 'core/types';
import { DataForRequest } from 'features/Organizations/types';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { RootState } from 'store/rootReducer';

import {
  fetchOrganizationsAddRequest,
  fetchOrganizationsRequest,
  resetOrganizationAddState,
  resetOrganizationOnlyState,
  setCurrentOrganizationsAddPage,
  setCurrentOrganizationsPage,
  setOrganizationsAddFilterAction,
  setOrganizationsFilter,
  setSortOrganizations,
  setSortOrganizationsAdd,
} from '../../ducks/actions';
import {
  getOrganizationsAddSelectList,
  getOrganizationsSelectList,
  getPropsOrganizations,
  getPropsOrganizationsAdd,
} from '../../ducks/selectors';

const actionsAddList: ActionsType = {
  setPage: setCurrentOrganizationsAddPage,
  setFilter: setOrganizationsAddFilterAction,
  fetchData: fetchOrganizationsAddRequest,
  setSort: setSortOrganizationsAdd,
  resetData: resetOrganizationAddState,
  getList: getOrganizationsAddSelectList,
  getPropsData: getPropsOrganizationsAdd,
};

const actionsList: ActionsType = {
  setPage: setCurrentOrganizationsPage,
  setFilter: setOrganizationsFilter,
  fetchData: fetchOrganizationsRequest,
  setSort: setSortOrganizations,
  resetData: resetOrganizationOnlyState,
  getList: getOrganizationsSelectList,
  getPropsData: getPropsOrganizations,
};

export interface ActionsType {
  setPage: (page: number) => AnyAction;
  setFilter: (filter: OrganizationsFilter) => AnyAction;
  fetchData: (data: FetchOrganizations) => AnyAction;
  setSort: (sort: string) => AnyAction;
  resetData: () => AnyAction;
  getList: (state: RootState) => SelectOption<string>[];
  getPropsData: (state: RootState) => {
    totalElements: number | undefined;
    pageNum: number | undefined;
    pageSize: number | undefined;
    loadingOrganization: boolean | undefined;
  };
}

interface Props extends Omit<SelectProps, 'options'> {
  addOrgState?: boolean;
  customFilters?: OrganizationsFilter;
  customActions?: ActionsType;
  dataForRequest?: DataForRequest;
}

export const OrganizationSelect: React.FC<Props> = ({
  addOrgState = true,
  customFilters = {},
  customActions,
  dataForRequest,
  ...other
}) => {
  const { organizationTypes } = dataForRequest || {};

  const dispatch = useDispatch();

  const getActions = () => {
    if (customActions) {
      return customActions;
    }
    return addOrgState ? actionsAddList : actionsList;
  };
  const actions = getActions();

  const {
    totalElements,
    pageNum = 0,
    pageSize,
    loadingOrganization,
  } = useSelector(actions.getPropsData);

  const organizationSelectList = useSelector(actions.getList);

  const { totalPage } = useInfiniteScroll({
    pageNum,
    pageSize,
    totalElements,
  });

  const onFilterOrganization = debounce((valueFilter: string) => {
    dispatch(actions.setPage(0));
    dispatch(
      actions.setFilter({
        ...customFilters,
        title: valueFilter,
        organizationTypes,
      })
    );
    dispatch(actions.fetchData({ updateType: 'update', organizationTypes }));
  }, DEFAULT_DEBOUNCE_DELAY);

  const setNextPageOrg = () => {
    if (!loadingOrganization && totalPage > pageNum) {
      dispatch(actions.setPage(pageNum + 1));
      dispatch(actions.fetchData({ updateType: 'join', organizationTypes }));
    }
  };

  useEffect(() => {
    dispatch(actions.setPage(0));
    dispatch(actions.setFilter({ ...customFilters, organizationTypes }));
    if (actions.setSort) {
      dispatch(actions.setSort('TITLE_ASC'));
    }
    dispatch(actions.fetchData({ updateType: 'update', organizationTypes }));
    return () => {
      dispatch(actions.resetData());
    };
  }, []);

  const selectProps = {
    ...other,
    options: organizationSelectList,
    onChangeInput: onFilterOrganization,
    isSearchable: true,
    currentPage: pageNum,
    totalPage,
    setNextPage: setNextPageOrg,
    loading: loadingOrganization,
    infiniteScrollable: true,
    mobileModalTitle: 'организацию',
  };

  return <Select<string> {...selectProps} />;
};

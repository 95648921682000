import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import {
  fetchContractAttachmentsSuccessContractDetail,
  hideAttachmentsLoadingContractDetail,
  showAttachmentsLoadingContractDetail,
} from 'features/Contract/ducks/actions';
import { getCurrentContractId } from 'features/Contracts/ducks/selectors';
import { AttachmentDeleteType, Note } from 'features/Ticket/types';
import { Attachment, AttachmentsFiles } from 'types/models/attachment';
import { createError } from 'utils';

import { fetchNotesByTicketIdSuccess } from '../notes/actions';

import {
  fetchContractAttachmentsSuccess,
  fetchTicketAttachmentsSuccess,
  fetchTicketCommentsAttachmentsSuccess,
  hideAttachmentsLoading,
  showAttachmentsLoading,
} from './actions';
import { request as attachmentRequests, request } from './api/requests';
import {
  AddAttachmentsRequestAction,
  AddMessageAttachmentsAction,
  Attachments,
  DeleteFileAttachmentsTicketRequestAction,
  FetchTicketAttachmentsRequestAction,
  FetchTicketCommentsAttachmentsRequestAction,
} from './types';

function* ticketAttachmentsFetch({
  payload,
}: FetchTicketAttachmentsRequestAction) {
  try {
    yield put(showAttachmentsLoading());
    const attachments: Attachment[] = yield call(
      request.fetchTicketAttachments,
      payload
    );
    yield put(fetchTicketAttachmentsSuccess(attachments));
    yield put(hideAttachmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideAttachmentsLoading());
  }
}

function* addMessageAttachments({ payload }: AddMessageAttachmentsAction) {
  const { filesData, messageData, sendMessage } = payload;
  try {
    yield put(showAttachmentsLoading());
    yield call(attachmentRequests.addAttachment, filesData);
    yield call(sendMessage, messageData);
    yield put(hideAttachmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideAttachmentsLoading());
  }
}

function* ticketCommentsAttachmentsFetch({
  payload,
}: FetchTicketCommentsAttachmentsRequestAction) {
  try {
    yield put(showAttachmentsLoading());
    const attachments: AttachmentsFiles[] = yield call(
      request.fetchTicketCommentsAttachments,
      payload
    );
    yield put(fetchTicketCommentsAttachmentsSuccess(attachments));
    yield put(hideAttachmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideAttachmentsLoading());
  }
}

function* contractAttachmentsFetch() {
  try {
    yield put(showAttachmentsLoading());
    const contractId: ReturnType<typeof getCurrentContractId> = yield select(
      getCurrentContractId
    );

    if (contractId) {
      const attachments: Attachment[] = yield call(
        request.fetchContractAttachments,
        contractId
      );
      yield put(fetchContractAttachmentsSuccess(attachments));
    }
    yield put(hideAttachmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideAttachmentsLoading());
  }
}

function* addAttachments({ payload }: AddAttachmentsRequestAction) {
  try {
    const { attachment, id } = payload;

    yield call(attachmentRequests.addAttachment, attachment);
    yield call(contractAttachmentsFetch);

    yield put(showAttachmentsLoadingContractDetail());
    const attachments: Attachment[] = yield call(
      request.fetchContractAttachments,
      id
    );
    yield put(fetchContractAttachmentsSuccessContractDetail(attachments));
    yield put(hideAttachmentsLoadingContractDetail());
  } catch (e) {
    createError(e);
  }
}

function* deleteFileAttachmentsTicket({
  payload,
}: DeleteFileAttachmentsTicketRequestAction) {
  try {
    const { attachmentId, attachmentFileId, ticketId, attachmentDeleteType } =
      payload;
    yield call(request.deleteFileAttachmentsTicket, {
      attachmentId,
      attachmentFileId,
    });
    if (ticketId) {
      if (attachmentDeleteType === AttachmentDeleteType.INFO) {
        const attachmentsTicketInfo: Attachment[] = yield call(
          request.fetchTicketAttachments,
          ticketId
        );
        yield put(fetchTicketAttachmentsSuccess(attachmentsTicketInfo));
      }
      if (attachmentDeleteType === AttachmentDeleteType.COMMENTS) {
        const attachments: AttachmentsFiles[] = yield call(
          request.fetchTicketCommentsAttachments,
          ticketId
        );
        yield put(fetchTicketCommentsAttachmentsSuccess(attachments));
      }
      if (attachmentDeleteType === AttachmentDeleteType.NOTES) {
        const notes: Note[] = yield call(
          request.fetchTicketNotesAttachments,
          ticketId
        );
        yield put(fetchNotesByTicketIdSuccess(notes));
      }
    }
    yield put(hideAttachmentsLoading());
  } catch (e) {
    createError(e);
    yield put(hideAttachmentsLoading());
  }
}

export function* attachmentsSaga(): Generator<StrictEffect> {
  yield takeEvery(
    Attachments.FETCH_TICKET_ATTACHMENTS_REQUEST,
    ticketAttachmentsFetch
  );
  yield takeEvery(
    Attachments.ADD_MESSAGE_ATTACHMENT_REQUEST,
    addMessageAttachments
  );
  yield takeEvery(
    Attachments.FETCH_TICKET_COMMENTS_ATTACHMENTS_REQUEST,
    ticketCommentsAttachmentsFetch
  );
  yield takeEvery(
    Attachments.FETCH_CONTRACT_ATTACHMENTS_REQUEST,
    contractAttachmentsFetch
  );
  yield takeEvery(Attachments.ADD_ATTACHMENTS_REQUEST, addAttachments);
  yield takeEvery(
    Attachments.DELETE_FILE_ATTACHMENT_TICKET_REQUEST,
    deleteFileAttachmentsTicket
  );
}

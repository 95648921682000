import cn from 'clsx';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  ArrowInBoxIcon,
  CheckMarkIcon,
  ClockThin,
  Forward,
} from 'assets/icons';
import { Avatar, AvatarColor } from 'components/Avatar';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { ConditionBlock } from 'components/ConditionBlock';
import { Input } from 'components/Input';
import { StatusBlock } from 'components/StatusBlock';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { TicketChip } from 'core/components/TicketChip';
import { TicketStatus } from 'core/types';
import { getIsClient, getIsClientPartner } from 'features/Auth/ducks/selectors';
import { changeStatus } from 'features/Ticket/ducks/ticket/actions';
import { getTicket } from 'features/Ticket/ducks/ticket/selectors';
import { getFormatDateWithYear, getTextWithLinks } from 'utils';

import { CancelMessageData, Comment, CommentType } from '../../types';
import { PreviewImageModal } from '../PreviewImageModal';
import { TicketAttachments } from '../TicketInfo/components/TicketAttachments';
import { getAttachmentFilesComment } from '../TicketInfo/utils';

import styles from './TicketComment.module.scss';

interface Props {
  comment: Comment;
  className?: string;
  isAccessToChangeStatus?: boolean;
}

export const TicketComment: React.FC<Props> = ({
  comment,
  className,
  isAccessToChangeStatus,
}) => {
  const {
    userDto,
    message,
    created,
    type,
    ticketId,
    id,
    solutionAccepted,
    cancelText,
    requestCommentText,
    isClient: commentIsClient,
    nextStatus,
    mentionedTickets,
    files,
  } = comment;

  const dispatch = useDispatch();

  const [showCancelForm, setShowCancelForm] = useState<boolean>(false);
  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  const isClient = useSelector(getIsClient);
  const isClientPartner = useSelector(getIsClientPartner);
  const ticket = useSelector(getTicket);
  const { imageFiles, documentFiles } = getAttachmentFilesComment(files);

  const toggleIsImageModal = () => {
    setIsImageModal((prevState) => !prevState);
  };

  const handleInitialImageIndex = (index: number) => {
    setInitialImageIndex(index);
  };

  const isCloseRequestComment = useMemo(
    () => type === CommentType.CLOSE_REQUEST || type === CommentType.SOLUTION,
    [type]
  );

  const isShowConditionBlock = useMemo(
    () => isCloseRequestComment || cancelText,
    [isCloseRequestComment, cancelText]
  );

  const isCustomStatus = useMemo(
    () => nextStatus && !nextStatus?.defaultStatus,
    [nextStatus]
  );

  const isWorkStatus = useMemo(
    () => nextStatus && nextStatus.defaultStatus === TicketStatus.WORK,
    [nextStatus]
  );

  const isInfoRequestComment = useMemo(
    () =>
      type === CommentType.INFO_REQUEST ||
      (type === CommentType.RETURN_TO_WORK && !cancelText),
    [type, cancelText]
  );

  const isSolutionReject = useMemo(
    () => !solutionAccepted && cancelText,
    [solutionAccepted, cancelText]
  );

  const isCloseRequest = useMemo(
    () =>
      isCloseRequestComment &&
      ticket?.customStatus?.defaultStatus === TicketStatus.PENDING_CLOSURE &&
      !isClient &&
      !isClientPartner,
    [isCloseRequestComment, ticket?.customStatus, isClient, isClientPartner]
  );

  const sendAcceptSolutionMessage = () => {
    dispatch(
      changeStatus({
        id,
        ticketId,
        type: CommentType.SOLUTION,
        message,
        solutionAccepted: true,
      })
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<CancelMessageData>({
    defaultValues: {
      cancelMessageText: '',
    },
    mode: 'onChange',
  });

  const messageInputOptions = register('cancelMessageText', {
    required: true,
    pattern: {
      value: /^.{6,240}$/,
      message:
        'Текст причины отмены решения тикета не может быть короче 6-ти и длиннее 240-а символов.',
    },
  });

  const formSubmitHandler = handleSubmit(({ cancelMessageText }) => {
    dispatch(
      changeStatus({
        id,
        ticketId,
        type: CommentType.RETURN_TO_WORK,
        message,
        cancelText: cancelMessageText,
        solutionAccepted: false,
      })
    );
    reset();
    setShowCancelForm(false);
  });

  const getCondition = () => {
    if (isShowConditionBlock) {
      return (
        <div className={styles.ticketComment__conditionBlock}>
          <ConditionBlock
            text="Решение"
            className={styles.ticketComment__conditionBlock_solution}
          />
        </div>
      );
    }
    if (type === CommentType.INFO_REQUEST) {
      return (
        <div className={styles.ticketComment__conditionBlock}>
          <ConditionBlock
            text="Запрос информации"
            className={styles.ticketComment__conditionBlock_request}
          />
        </div>
      );
    }
    if (type === CommentType.RETURN_TO_WORK && requestCommentText) {
      return (
        <div className={styles.ticketComment__request}>
          <Forward className={styles.ticketComment__requestIcon} />
          <ConditionBlock
            text="Ответ на запрос"
            className={styles.ticketComment__requestBlock}
          />
          <div className={styles.ticketComment__requestText}>
            {requestCommentText}
          </div>
        </div>
      );
    }
    if (isCustomStatus || isWorkStatus) {
      return (
        <div className={styles.ticketComment__customStatusWrapper}>
          <ArrowInBoxIcon className={styles.ticketComment__customStatusIcon} />
          <StatusBlock
            status={nextStatus?.defaultStatus}
            label={nextStatus?.name}
            color={nextStatus?.color}
          />
        </div>
      );
    }
    return null;
  };

  const acceptedSolutionForm = isCloseRequestComment &&
    (isClient || isClientPartner) &&
    !solutionAccepted && (
      <div className={styles.ticketComment__buttons}>
        <Button
          appearance="outline"
          type="button"
          onClick={() => setShowCancelForm(true)}
          disabled={showCancelForm || !isAccessToChangeStatus}
          className={styles.ticketComment__cancelButton}
        >
          Отклонить
        </Button>
        <Button
          type="button"
          icon={<CheckMarkIcon className={styles.ticketComment__checkMark} />}
          disabled={showCancelForm || !isAccessToChangeStatus}
          onClick={sendAcceptSolutionMessage}
        >
          Подтвердить
        </Button>
      </div>
    );

  const closeRequest = isCloseRequest && (
    <div className={styles.ticketComment__solution}>
      <ClockThin className={styles.ticketComment__icon} />
      <Typography
        variant={TypographyVariants.h4}
        className={cn(
          styles.ticketComment__solutionText,
          styles.ticketComment__solutionText_await
        )}
      >
        Ожидает подтверждения клиентом
      </Typography>
    </div>
  );

  const solution = solutionAccepted && (
    <div className={styles.ticketComment__solution}>
      <CheckMarkIcon className={styles.ticketComment__solutionIcon_accepted} />
      <Typography
        variant={TypographyVariants.h4}
        className={cn(
          styles.ticketComment__solutionText,
          styles.ticketComment__solutionText_accepted
        )}
      >
        Выбрано как решение
      </Typography>
    </div>
  );

  const cancelSolutionForm = showCancelForm && (
    <form
      onSubmit={formSubmitHandler}
      className={styles.ticketComment__cancelWrapper}
    >
      <Typography
        variant={TypographyVariants.o}
        className={styles.ticketComment__cancelTitle}
      >
        Отклонить решение
      </Typography>
      <Input
        label="Укажите причину"
        {...messageInputOptions}
        error={!!errors.cancelMessageText}
        errorMessage={errors.cancelMessageText?.message}
      />
      <div className={styles.ticketComment__cancelButtons}>
        <Button
          appearance="outline"
          type="button"
          onClick={() => setShowCancelForm(false)}
        >
          Отмена
        </Button>
        <Button
          type="submit"
          disabled={!isDirty || !isValid}
          className={styles.ticketComment__cancelSubmitButton}
        >
          Отправить
        </Button>
      </div>
    </form>
  );

  const solutionReject = isSolutionReject && (
    <div className={styles.ticketComment__canceledWrapper}>
      <Typography
        variant={TypographyVariants.o}
        className={styles.ticketComment__canceledWrapperTitle}
      >
        Решение отклонено
      </Typography>
      <p className={styles.ticketComment__canceledWrapperText}>{cancelText}</p>
    </div>
  );

  const attachments = (
    <div
      className={cn(styles.ticketComment__filesWrapper, {
        [styles.ticketComment__filesWrapper_image]: imageFiles.length,
      })}
    >
      <TicketAttachments
        toggleIsModal={toggleIsImageModal}
        handleInitialImageIndex={handleInitialImageIndex}
        documentFiles={documentFiles}
        imageFiles={imageFiles}
        size={Size.l}
        isButtonDelete={false}
      />
    </div>
  );

  const imageModal = isImageModal && (
    <PreviewImageModal
      toggleModal={toggleIsImageModal}
      initialImageIndex={initialImageIndex}
      imageFiles={imageFiles}
    />
  );

  const mentionedTicketsList = !!mentionedTickets?.length && (
    <div className={styles.ticketComment__tickets}>
      {mentionedTickets.map(({ key, value }) => (
        <TicketChip key={key} id={key} number={value} />
      ))}
    </div>
  );

  return (
    <Card
      className={cn(
        styles.ticketComment,
        className,
        {
          [styles.ticketComment_solution]: isShowConditionBlock,
        },
        { [styles.ticketComment_request]: isInfoRequestComment }
      )}
    >
      <div className={styles.ticketComment__leftBlock}>
        <Avatar
          size={Size.xs}
          color={commentIsClient ? AvatarColor.purple : AvatarColor.cyan}
          initialsUser={`${userDto?.firstName} ${userDto?.lastName}`}
        />
      </div>
      <div className={styles.ticketComment__rightBlock}>
        <div className={styles.ticketComment__header}>
          <Typography
            variant={TypographyVariants.b3}
            className={styles.ticketComment__name}
          >{`${userDto?.firstName} ${userDto?.lastName}`}</Typography>
          <Typography
            variant={TypographyVariants.b3}
            className={styles.ticketComment__date}
          >
            {created ? getFormatDateWithYear(created) : ''}
          </Typography>
        </div>
        {getCondition()}
        <p
          className={styles.ticketComment__message}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `${message && getTextWithLinks(message)}`,
          }}
        />
        {mentionedTicketsList}
        {acceptedSolutionForm}
        {closeRequest}
        {solution}
        {cancelSolutionForm}
        {solutionReject}
        {attachments}
        {imageModal}
      </div>
    </Card>
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddModalContainer } from 'core/modals/AddModal/components/AddModalContainer';
import { Role, RoleType } from 'core/types';
import { updateUserRolesInSystem } from 'features/Users/ducks/actions';
import { getCurrentUserId } from 'features/Users/ducks/selectors';
import { checkObjectIdentity } from 'utils';

import { ROLES_ADD_MODAL_FIELDS } from '../../constants';
import {
  fetchRolesAddInSystemByOrgIdRequest,
  fetchRolesByUserIdRequest,
  resetRolesAddState,
  setCurrentRolesAddPage,
  setRolesAddFilter,
  setRolesFilter,
  setSizePageRolesAdd,
} from '../../ducks/actions';
import {
  getLoadingAdd,
  getPropsRolesAdd,
  getRoles,
  getRolesAdd,
  getRolesAddFilter,
} from '../../ducks/selectors';
import { RolesFilter as RolesFilterData, TableRolesTypes } from '../../types';
import { getDataTable } from '../AddRolesInGroupToUser/utils';
import { RolesFilter } from '../RolesFilter';

interface Props {
  isModal: boolean;
  toggleModal: () => void;
}

export const AddRolesInSystemToUser: React.FC<Props> = ({
  isModal,
  toggleModal,
}) => {
  const dispatch = useDispatch();

  const rolesAll = useSelector(getRolesAdd) || [];
  const rolesRightTable = useSelector(getRoles) || [];
  const currentUserId = useSelector(getCurrentUserId);
  const filterAddValues = useSelector(getRolesAddFilter);
  const dataLoading = useSelector(getLoadingAdd);
  const { pageNum, pageSize, totalElements } = useSelector(getPropsRolesAdd);

  const isFilterActive = !checkObjectIdentity(filterAddValues, {});

  const onSubmit = (data: Role[]) => {
    if (currentUserId) {
      dispatch(updateUserRolesInSystem(data.map((item) => item.id)));
    }
    toggleModal();
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentRolesAddPage(page));
    dispatch(fetchRolesAddInSystemByOrgIdRequest());
  };

  const onChangeFilter = (filter: RolesFilterData) => {
    dispatch(
      setRolesAddFilter({
        ...filter,
        idsRolesDelete: filterAddValues?.idsRolesDelete,
      })
    );
    dispatch(setCurrentRolesAddPage(0));
    dispatch(fetchRolesAddInSystemByOrgIdRequest());
  };

  const onChangeIdsForDelete = (data: Role[]) => {
    dispatch(
      setRolesAddFilter({
        ...filterAddValues,
        idsRolesDelete: data.map((item) => item.id),
      })
    );
    dispatch(fetchRolesAddInSystemByOrgIdRequest());
  };

  const handleChangePageSize = (size: number) => {
    dispatch(setSizePageRolesAdd(size));
  };

  useEffect(() => {
    if (currentUserId) {
      dispatch(
        setRolesFilter({
          type: { value: RoleType.ROLE_IN_SYSTEM, title: 'Роли в системе' },
        })
      );
      dispatch(fetchRolesByUserIdRequest());
    }
  }, [currentUserId]);

  useEffect(
    () => () => {
      dispatch(resetRolesAddState());
    },
    []
  );

  return (
    <AddModalContainer<Role>
      leftData={rolesAll}
      rightData={rolesRightTable}
      getDataTable={getDataTable}
      columns={ROLES_ADD_MODAL_FIELDS}
      mainTitle="роли"
      subModalText="роли"
      leftTableTitle="Все роли"
      isModal={isModal}
      toggleModal={toggleModal}
      loadingData={dataLoading}
      isFilterActive={isFilterActive}
      onSubmit={onSubmit}
      onChangeIdsForDelete={onChangeIdsForDelete}
      paginationLeftTableProps={{
        pageNum,
        pageSize,
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
      }}
      filterComponent={
        <RolesFilter
          tableType={TableRolesTypes.ADD_MODAL}
          onFilter={onChangeFilter}
        />
      }
    />
  );
};

import { Contract, System } from 'core/types';
import { ContractDetailPayload } from 'features/Contract/ducks/types';
import { SLAType } from 'features/Contract/types';
import { ContractsFilter } from 'features/Contracts/types';
import { DeleteDataType } from 'features/Organizations/ducks/types';
import { ResponseWithMeta } from 'store/types';

import {
  Contracts,
  DeleteContractRequestAction,
  FetchContractRequestAction,
  FetchContractsByOrganizationsRequestAction,
  FetchContractsBySystemIdRequestAction,
  FetchContractsRequestAction,
  FetchContractsSuccessAction,
  FetchContractSuccessAction,
  HideContractLoadingAction,
  HideContractsLoadingAction,
  ResetContractsStateAction,
  ResetCurrentContractAction,
  SetContractFilterAction,
  SetCurrentContractIdAction,
  SetCurrentContractsPageAction,
  SetSizePageAction,
  SetSortContractsAction,
  SetSystemsForContractAction,
  ShowContractLoadingAction,
  ShowContractsLoadingAction,
  UpdateContractAction,
} from './types';

export const showContractsLoading = (): ShowContractsLoadingAction => {
  return {
    type: Contracts.CONTRACTS_LOADING_SHOW,
  };
};

export const hideContractsLoading = (): HideContractsLoadingAction => {
  return {
    type: Contracts.CONTRACTS_LOADING_HIDE,
  };
};

export const showContractLoading = (): ShowContractLoadingAction => {
  return {
    type: Contracts.CONTRACT_LOADING_SHOW,
  };
};

export const hideContractLoading = (): HideContractLoadingAction => {
  return {
    type: Contracts.CONTRACT_LOADING_HIDE,
  };
};

export const fetchContractsRequest = (): FetchContractsRequestAction => {
  return {
    type: Contracts.FETCH_CONTRACTS_REQUEST,
  };
};

export const fetchContractsSuccess = (
  data: ResponseWithMeta<Contract[]>
): FetchContractsSuccessAction => {
  return {
    type: Contracts.FETCH_CONTRACTS_SUCCESS,
    payload: data,
  };
};

export const setCurrentContractsPage = (
  page: number
): SetCurrentContractsPageAction => {
  return {
    type: Contracts.SET_CURRENT_CONTRACTS_PAGE,
    payload: page,
  };
};

export const setSizePage = (size: number): SetSizePageAction => {
  return {
    type: Contracts.SET_SIZE_PAGE_CONTRACTS,
    payload: size,
  };
};

export const setSortContracts = (sort: string): SetSortContractsAction => {
  return {
    type: Contracts.SET_SORT_CONTRACTS,
    payload: sort,
  };
};

export const fetchContractRequest = (
  id: string
): FetchContractRequestAction => {
  return {
    type: Contracts.FETCH_CONTRACT_REQUEST,
    payload: id,
  };
};

export const fetchContractSuccess = (
  data?: Contract
): FetchContractSuccessAction => {
  return {
    type: Contracts.FETCH_CONTRACT_SUCCESS,
    payload: data,
  };
};

export const resetContractsState = (): ResetContractsStateAction => {
  return {
    type: Contracts.RESET_CONTRACTS_STATE,
  };
};

export const setCurrentContractId = (
  id: string
): SetCurrentContractIdAction => {
  return {
    type: Contracts.SET_CURRENT_CONTRACT_ID,
    payload: id,
  };
};

export const fetchContractsByOrganizationsRequest =
  (): FetchContractsByOrganizationsRequestAction => {
    return {
      type: Contracts.FETCH_CONTRACTS_BY_ORGANIZATION_ID_REQUEST,
    };
  };

export const fetchContractsBySystemIdRequest =
  (): FetchContractsBySystemIdRequestAction => {
    return {
      type: Contracts.FETCH_CONTRACTS_BY_SYSTEM_ID_REQUEST,
    };
  };

export const setContractFilter = (
  data: ContractsFilter
): SetContractFilterAction => {
  return {
    type: Contracts.SET_CONTRACT_FILTER,
    payload: data,
  };
};

export const updateContract = (data: Contract): UpdateContractAction => {
  return {
    type: Contracts.UPDATE_CONTRACT,
    payload: data,
  };
};

export const setSystemsForContract = (
  data: System[]
): SetSystemsForContractAction => {
  return {
    type: Contracts.SET_SYSTEMS_FOR_CONTRACT,
    payload: data,
  };
};

export const deleteContractRequest = (
  data: DeleteDataType
): DeleteContractRequestAction => {
  return {
    type: Contracts.DELETE_CONTRACT_REQUEST,
    payload: data,
  };
};

export const resetCurrentContract = (): ResetCurrentContractAction => {
  return {
    type: Contracts.RESET_CURRENT_CONTRACT,
  };
};

export const fetchSLAByContractIdBySystemIdRequestContracts = (
  payload: ContractDetailPayload
) => {
  return {
    type: Contracts.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
    payload,
  };
};

export const fetchSLAByContractIdBySystemIdSuccessContracts = (
  sla: SLAType
) => {
  return {
    type: Contracts.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS,
    payload: sla,
  };
};

export const resetSLAByContractIdBySystemIdContracts = () => {
  return {
    type: Contracts.RESET_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID,
  };
};

import cn from 'clsx';
import React from 'react';

import styles from './TablesLayout.module.scss';

interface Props {
  MainTable: React.FC[];
  SubTable?: React.FC[];
  classNameRoot?: string;
  classNameSubTables?: string;
  classNameMainTables?: string;
  classNameWrapperSubTable?: string;
}

export const TablesLayout: React.FC<Props> = ({
  MainTable,
  SubTable,
  classNameRoot,
  classNameSubTables,
  classNameMainTables,
  classNameWrapperSubTable,
}) => {
  const mainTables = MainTable.map((Item, index) => (
    <Item key={`main${index.toString()}`} />
  ));

  const subTables = SubTable?.map((Item, index) => (
    <Item key={`sub${index.toString()}`} />
  ));

  return (
    <div className={cn(styles.tablesContainer, classNameRoot)}>
      <div
        className={cn(styles.tablesContainer__mainTable, classNameMainTables)}
      >
        {mainTables}
      </div>
      <div className={cn(styles.tablesContainer__subTable, classNameSubTables)}>
        <div
          className={cn(
            styles.tablesContainer__wrapperSubTable,
            classNameWrapperSubTable
          )}
        >
          {subTables}
        </div>
      </div>
    </div>
  );
};

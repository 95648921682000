import { KpiDataRequest } from 'features/Desktop/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const requests = {
  getKpiData: (data: KpiDataRequest) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_KPI_URL'),
      url: endpoints.getKpiData(),
      data,
    }),
};

import React from 'react';

import { AdminsLayout } from 'core/layouts/AdminsLayout';
import { WorkGroups } from 'features/WorkGroups';

const AdminGroups = () => {
  return (
    <AdminsLayout>
      <WorkGroups />
    </AdminsLayout>
  );
};

export default AdminGroups;

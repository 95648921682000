import React from 'react';

import { WorkGroup as WorkGroupContent } from 'features/WorkGroup';

import { WorkGroupLayout } from '../../layouts/WorkGroupLayout';

import styles from './WorkGroup.module.scss';

const WorkGroup = () => {
  return (
    <WorkGroupLayout>
      <WorkGroupContent className={styles.workGroup__content} />
    </WorkGroupLayout>
  );
};

export default WorkGroup;

import cn from 'clsx';
import React, { FC } from 'react';

import styles from './TimePickerCell.module.scss';

export type Props = {
  className?: string;
  value: string;
  valueForList: number;
  isActive?: boolean;
  pickValue: (value: string) => void;
};

export const TimePickerCell: FC<Props> = ({
  className,
  value,
  valueForList,
  isActive,
  pickValue,
}) => {
  const handleClick = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    pickValue(value);
  };

  return (
    <div
      role="button"
      tabIndex={-1}
      className={cn(
        styles.timePickerCell,
        { [styles.timePickerCell_active]: isActive },
        className
      )}
      onClick={handleClick}
      onKeyDown={undefined}
    >
      {valueForList}
    </div>
  );
};

import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { setAlert, setRedirectPath } from 'core/ducks/actions';
import { getErrorAlert, getSuccessAlert } from 'core/layouts/AlertsLayout';
import {
  ActionForAlertTypes,
  Contract,
  Environment,
  EnvironmentSetting,
  Organization,
  System,
  TicketType,
  TicketTypeSettings,
  User,
  WorkGroup,
} from 'core/types';
import {
  getFilterOrganizationToRequest,
  getFilterSystemToRequest,
} from 'core/utils';
import { SLAType } from 'features/Contract/types';
import { request as orgRequest } from 'features/Organizations/ducks/api/requests';
import { getFilterUsersToRequest } from 'features/Users/ducks/saga';
import { fetchWorkGroupsByContractBySystemRequest } from 'features/WorkGroups/ducks/actions';
import { getFilterGroupsToRequest } from 'features/WorkGroups/ducks/saga';
import { RouterHref } from 'routes/routerHref';
import { ResponseWithMeta } from 'store/types';
import { Attachment } from 'types/models/attachment';
import { createError } from 'utils';

import {
  checkIsExistPatternTitleSuccessContractDetail,
  fetchContractAttachmentsSuccessContractDetail,
  fetchContractDetailRequest,
  fetchContractDetailSuccess,
  fetchEnvironmentsBySystemSuccess,
  fetchEnvironmentSettingsBySystemSuccess,
  fetchOrganizationsJoinSuccessContractDetail,
  fetchOrganizationsRequestContractDetail,
  fetchOrganizationsSuccessUpdateContractDetail,
  fetchPartnerOrganizationsRequestContractDetail,
  fetchPartnerOrganizationsSuccessContractDetail,
  fetchPatternByIdSuccessContractDetail,
  fetchSlaByContractIdBySystemIdRequestContractDetail,
  fetchSlaByContractIdBySystemIdSuccessContractDetail,
  fetchSLAPatternsSuccessContractDetail,
  fetchSupplementaryAgreementsSuccess,
  fetchSystemsByContractIdRequestContractDetail,
  fetchSystemsByContractIdSuccessContractDetail,
  fetchSystemsForAddingSupplementaryAgreementSuccessContractDetail,
  fetchSystemsForAddingToContractSuccessContractDetail,
  fetchTicketTypeSettingsSuccessContractDetail,
  fetchTicketTypesSuccessContractDetail,
  fetchUsersAllowedToAddToContractDetailSuccess,
  fetchWorkGroupsByContractBySystemRequestContractDetail,
  fetchWorkGroupsByContractBySystemSuccessContractDetail,
  fetchWorkGroupsByContractSuccessContractDetail,
  hideAttachmentsLoadingContractDetail,
  hideContractDetailLoading,
  hideOrganizationsLoadingContractDetail,
  hidePartnerOrganizationsLoadingContractDetail,
  hideSupplementaryAgreementsLoading,
  hideUsersAllowedToAddToContractDetailLoading,
  loadingHideEnvironmentsBySystem,
  loadingHideSlaByContractIdBySystemIdRequestContractDetail,
  loadingHideSLAPatternsContractDetail,
  loadingHideSystemsByContractIdContractDetail,
  loadingHideSystemsForAddingSupplementaryAgreementContractDetail,
  loadingHideSystemsForAddingToContractContractDetail,
  loadingHideWorkGroupsByContractBySystemContractDetail,
  loadingHideWorkGroupsByContractContractDetail,
  loadingShowEnvironmentsBySystem,
  loadingShowSlaByContractIdBySystemIdRequestContractDetail,
  loadingShowSLAPatternsContractDetail,
  loadingShowSystemsByContractIdContractDetail,
  loadingShowSystemsForAddingSupplementaryAgreementContractDetail,
  loadingShowSystemsForAddingToContractContractDetail,
  loadingShowWorkGroupsByContractBySystemContractDetail,
  loadingShowWorkGroupsByContractContractDetail,
  setActiveSystemByContractIdContractDetail,
  setContractPermission,
  setLoadingTicketTypeSettingsContractDetail,
  showAttachmentsLoadingContractDetail,
  showContractDetailLoading,
  showOrganizationsLoadingContractDetail,
  showPartnerOrganizationsLoadingContractDetail,
  showSupplementaryAgreementsLoading,
  showUsersAllowedToAddToContractDetailLoading,
  updateContractPermissionSuccess,
  updateNotificationsExpireContractSuccess,
} from './actions';
import { request } from './api/requests';
import {
  getActiveSystemIdContractDetail,
  getContractDetail,
  getFilterValuesOrganizationsContractDetail,
  getGroupsFilterByContractBySystemContractDetail,
  getGroupsFilterByContractContractDetail,
  getPropsEnvironmentsBySystem,
  getPropsGroupsByContractContractDetail,
  getPropsGroupsContractDetail,
  getPropsOrganizationsContractDetail,
  getPropsSupplementaryAgreements,
  getPropsSystemsContractDetail,
  getPropsUsersContractDetail,
  getSLAByContractSystemContractDetail,
  getSystemsFilterContractDetail,
  getTransferSystemIds,
  getUsersFilterContractDetail,
} from './selectors';
import {
  AttachSystemAndSLAToContractDetailAction,
  CheckIsExistPatternTitleRequestContractDetailAction,
  ContractDetail,
  ContractSignRequestContractDetailAction,
  CreateContractDetailAction,
  CreateSLAPatternRequestContractDetailAction,
  DeleteContractDetailRequestAction,
  FetchContractAttachmentsRequestContractDetailAction,
  FetchEnvironmentsBySystemRequestAction,
  FetchEnvironmentSettingsBySystemRequestAction,
  FetchPatternByIdRequestContractDetailAction,
  FetchSlaByContractIdBySystemIdRequestContractDetailAction,
  FetchSLAPatternsRequestContractDetailAction,
  FetchSupplementaryAgreementsRequestAction,
  FetchSystemsByContractIdRequestContractDetailAction,
  FetchSystemsForAddingSupplementaryAgreementRequestContractDetailAction,
  FetchSystemsForAddingToContractRequestContractDetailAction,
  FetchTicketTypesRequestContractDetailAction,
  FetchTicketTypesSettingsRequestContractDetailAction,
  FetchUpdateEnvironmentSettingsRequestAction,
  FetchWorkGroupsByContractBySystemRequestContractDetailAction,
  FetchWorkGroupsByContractRequestContractDetailAction,
  SystemTransferAction,
  UpdateContractDetailAction,
  UpdateContractNotificationsAction,
  UpdateContractPermissionsAction,
  UpdateContractSLARequestContractDetailAction,
  UpdateWorkGroupsInContractInSystemContractDetailAction,
} from './types';

const ENTITY_CONTRACT_TITLE = 'Контракт';

function* contractDetailFetch({
  payload,
}: ReturnType<typeof fetchContractDetailRequest>) {
  try {
    yield put(showContractDetailLoading());
    const contract: Contract = yield call(
      request.fetchCurrentContract,
      payload
    );
    yield put(fetchContractDetailSuccess(contract));
    yield put(hideContractDetailLoading());
  } catch (e) {
    yield put(hideContractDetailLoading());
    createError(e);
  }
}

function* supplementaryAgreementsFetch({
  payload,
}: FetchSupplementaryAgreementsRequestAction) {
  try {
    if (payload) {
      yield put(showSupplementaryAgreementsLoading());
      const {
        pageNum,
        pageSize,
        sortSA,
      }: ReturnType<typeof getPropsSupplementaryAgreements> = yield select(
        getPropsSupplementaryAgreements
      );
      const supplementaryAgreements: ResponseWithMeta<Contract[]> = yield call(
        request.fetchSupplementaryAgreements,
        payload,
        pageNum,
        pageSize,
        sortSA
      );

      yield put(fetchSupplementaryAgreementsSuccess(supplementaryAgreements));
    }

    yield put(hideSupplementaryAgreementsLoading());
  } catch (e) {
    createError(e);
    yield put(hideSupplementaryAgreementsLoading());
  }
}

function* deleteContract({ payload }: DeleteContractDetailRequestAction) {
  try {
    yield call(request.deleteContract, payload);
    yield put(
      setAlert(
        getSuccessAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.DELETE)
      )
    );
    yield put(setRedirectPath(RouterHref.AdminContracts));
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.DELETE))
    );
    createError(e);
  }
}

function* contractCreate({ payload }: CreateContractDetailAction) {
  try {
    const contract: Contract = yield call(request.createContract, payload);
    if (contract?.id) {
      yield put(setRedirectPath(`/admin/contracts/${contract.id}`));
      yield put(
        setAlert(
          getSuccessAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.CREATE)
        )
      );
    }
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.CREATE))
    );
    createError(e);
  }
}

function* updateContractRequest({ payload }: UpdateContractDetailAction) {
  try {
    const data: Contract = yield call(request.updateContract, payload);
    if (data) {
      yield put(fetchWorkGroupsByContractBySystemRequest());
      yield put(fetchContractDetailRequest(data.id));
      yield put(
        setAlert(
          getSuccessAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.EDIT)
        )
      );
    }
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.EDIT))
    );
    createError(e);
  }
}

function* systemTransfer({ payload }: SystemTransferAction) {
  try {
    const contract: Contract = yield call(request.createContract, payload);

    const systemIds: ReturnType<typeof getTransferSystemIds> = yield select(
      getTransferSystemIds
    );

    if (contract?.id) {
      yield call(request.systemTransfer, systemIds, contract.id);
      yield put(setRedirectPath(`/admin/contracts/${contract.id}`));
      yield put(
        setAlert(
          getSuccessAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.CREATE)
        )
      );
    }
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.CREATE))
    );
    createError(e);
  }
}

function* updateGroupsToContractToSystem({
  payload,
}: UpdateWorkGroupsInContractInSystemContractDetailAction) {
  try {
    const systemId: ReturnType<typeof getActiveSystemIdContractDetail> =
      yield select(getActiveSystemIdContractDetail);
    const contract: ReturnType<typeof getContractDetail> = yield select(
      getContractDetail
    );
    const sla: ReturnType<typeof getSLAByContractSystemContractDetail> =
      yield select(getSLAByContractSystemContractDetail);
    if (systemId && contract?.id && sla?.id) {
      const data: Contract = yield call(
        request.updateGroupsToContractToSystem,
        contract.id,
        systemId,
        sla?.id,
        payload
      );
      if (data) {
        yield put(
          fetchWorkGroupsByContractBySystemRequestContractDetail({
            contractId: contract.id,
            systemId,
          })
        );
      }
    }
  } catch (e) {
    createError(e);
  }
}

function* organizationsFetch({
  payload,
}: ReturnType<typeof fetchOrganizationsRequestContractDetail>) {
  try {
    const { updateType = 'update' } = payload || {};

    const {
      pageNum,
      pageSize,
      sortOrganizations,
    }: ReturnType<typeof getPropsOrganizationsContractDetail> = yield select(
      getPropsOrganizationsContractDetail
    );
    const filter: ReturnType<
      typeof getFilterValuesOrganizationsContractDetail
    > = yield select(getFilterValuesOrganizationsContractDetail);

    yield put(showOrganizationsLoadingContractDetail());
    const organizations: ResponseWithMeta<Organization[]> = yield call(
      orgRequest.fetchOrganizations,
      pageNum,
      pageSize,
      sortOrganizations,
      getFilterOrganizationToRequest(filter)
    );
    yield put(
      updateType === 'update'
        ? fetchOrganizationsSuccessUpdateContractDetail(organizations)
        : fetchOrganizationsJoinSuccessContractDetail(organizations)
    );
    yield put(hideOrganizationsLoadingContractDetail());
  } catch (e) {
    createError(e);
    yield put(hideOrganizationsLoadingContractDetail());
  }
}

function* partnerOrganizationsFetch({
  payload,
}: ReturnType<typeof fetchPartnerOrganizationsRequestContractDetail>) {
  try {
    yield put(showPartnerOrganizationsLoadingContractDetail());
    const organizations: ResponseWithMeta<Organization[]> = yield call(
      request.fetchOrganizations,
      payload
    );
    yield put(fetchPartnerOrganizationsSuccessContractDetail(organizations));
  } catch (e) {
    createError(e);
  } finally {
    yield put(hidePartnerOrganizationsLoadingContractDetail());
  }
}

function* fetchContractAttachments({
  payload,
}: FetchContractAttachmentsRequestContractDetailAction) {
  try {
    yield put(showAttachmentsLoadingContractDetail());
    const attachments: Attachment[] = yield call(
      request.fetchContractAttachments,
      payload
    );
    if (attachments) {
      yield put(fetchContractAttachmentsSuccessContractDetail(attachments));
    }
    yield put(hideAttachmentsLoadingContractDetail());
  } catch (e) {
    yield put(hideAttachmentsLoadingContractDetail());
    createError(e);
  }
}

function* usersFetchByContractId() {
  try {
    const filters: ReturnType<typeof getUsersFilterContractDetail> =
      yield select(getUsersFilterContractDetail);
    const {
      pageNum,
      pageSize,
    }: ReturnType<typeof getPropsUsersContractDetail> = yield select(
      getPropsUsersContractDetail
    );
    const contract: ReturnType<typeof getContractDetail> = yield select(
      getContractDetail
    );

    if (contract?.id) {
      yield put(showUsersAllowedToAddToContractDetailLoading());
      const users: ResponseWithMeta<User[]> = yield call(
        request.fetchUserByContractId,
        contract.id,
        pageNum,
        pageSize,
        'LOGIN_ASC',
        getFilterUsersToRequest(filters)
      );
      yield put(fetchUsersAllowedToAddToContractDetailSuccess(users));
    }

    yield put(hideUsersAllowedToAddToContractDetailLoading());
  } catch (e) {
    createError(e);
    yield put(hideUsersAllowedToAddToContractDetailLoading());
  }
}

function* fetchSystemsByContractId({
  payload,
}: FetchSystemsByContractIdRequestContractDetailAction) {
  try {
    const { id, withDeletedSystems } = payload;
    yield put(loadingShowSystemsByContractIdContractDetail());
    const filters: ReturnType<typeof getSystemsFilterContractDetail> =
      yield select(getSystemsFilterContractDetail);
    const {
      pageNum,
      pageSize,
      sort,
    }: ReturnType<typeof getPropsSystemsContractDetail> = yield select(
      getPropsSystemsContractDetail
    );
    if (id) {
      const systems: ResponseWithMeta<System[]> = yield call(
        request.fetchSystemsByContractId,
        id,
        pageNum,
        pageSize,
        sort,
        getFilterSystemToRequest({
          ...filters,
          deleted: withDeletedSystems ? null : undefined,
        })
      );
      yield put(fetchSystemsByContractIdSuccessContractDetail(systems));
    }

    yield put(loadingHideSystemsByContractIdContractDetail());
  } catch (e) {
    yield put(loadingHideSystemsByContractIdContractDetail());
    createError(e);
  }
}

function* fetchSystems({
  payload,
}: FetchSystemsForAddingToContractRequestContractDetailAction) {
  try {
    const contract: ReturnType<typeof getContractDetail> = yield select(
      getContractDetail
    );
    if (contract?.id) {
      yield put(loadingShowSystemsForAddingToContractContractDetail());
      const systems: System[] = yield call(
        request.fetchSystemsForAddingToContract,
        contract.id,
        payload
      );
      yield put(fetchSystemsForAddingToContractSuccessContractDetail(systems));
      yield put(loadingHideSystemsForAddingToContractContractDetail());
    }
  } catch (e) {
    yield put(loadingHideSystemsForAddingToContractContractDetail());
    createError(e);
  }
}

function* fetchGroups({
  payload,
}: FetchWorkGroupsByContractBySystemRequestContractDetailAction) {
  try {
    const { contractId } = payload;
    const activeSystemId: ReturnType<typeof getActiveSystemIdContractDetail> =
      yield select(getActiveSystemIdContractDetail);
    const systemId = activeSystemId || payload.systemId;

    if (contractId && systemId) {
      yield put(loadingShowWorkGroupsByContractBySystemContractDetail());
      const filters: ReturnType<
        typeof getGroupsFilterByContractBySystemContractDetail
      > = yield select(getGroupsFilterByContractBySystemContractDetail);
      const {
        pageNum,
        pageSize,
        sort,
      }: ReturnType<typeof getPropsGroupsContractDetail> = yield select(
        getPropsGroupsContractDetail
      );

      const groups: ResponseWithMeta<WorkGroup[]> = yield call(
        request.fetchGroupsByContractIdBySystemId,
        contractId,
        systemId,
        pageNum,
        pageSize,
        sort,
        getFilterGroupsToRequest(filters)
      );
      yield put(fetchWorkGroupsByContractBySystemSuccessContractDetail(groups));
      yield put(loadingHideWorkGroupsByContractBySystemContractDetail());
    }
  } catch (e) {
    yield put(loadingHideWorkGroupsByContractBySystemContractDetail());
    createError(e);
  }
}

function* fetchEnvironments(systemId: string, name?: string) {
  yield put(loadingShowEnvironmentsBySystem());
  const { pageNum, pageSize, sort } = yield select(
    getPropsEnvironmentsBySystem
  );
  const environments: ResponseWithMeta<Environment[]> = yield call(
    request.fetchEnvironmentsBySystem,
    pageNum,
    pageSize,
    sort,
    { systemId, name }
  );
  yield put(fetchEnvironmentsBySystemSuccess(environments));
  yield put(loadingHideEnvironmentsBySystem());
}

function* fetchEnvironmentsBySystem({
  payload,
}: FetchEnvironmentsBySystemRequestAction) {
  try {
    const { name } = payload;
    const activeSystemId: ReturnType<typeof getActiveSystemIdContractDetail> =
      yield select(getActiveSystemIdContractDetail);
    const systemId = activeSystemId || payload.systemId;
    if (systemId) {
      yield call(fetchEnvironments, systemId, name);
    }
  } catch (e) {
    yield put(loadingHideEnvironmentsBySystem());
    createError(e);
  }
}

function* fetchEnvironmentSettings({
  payload,
}: FetchEnvironmentSettingsBySystemRequestAction) {
  try {
    if (payload) {
      const environmentSettings: EnvironmentSetting[] = yield call(
        request.fetchEnvironmentSettingsBySystem,
        payload
      );
      yield put(fetchEnvironmentSettingsBySystemSuccess(environmentSettings));
    }
  } catch (e) {
    createError(e);
  }
}

function* fetchUpdateEnvironmentSettings({
  payload,
}: FetchUpdateEnvironmentSettingsRequestAction) {
  try {
    const activeSystemId: ReturnType<typeof getActiveSystemIdContractDetail> =
      yield select(getActiveSystemIdContractDetail);
    if (activeSystemId) {
      const environmentSettings: EnvironmentSetting[] = yield call(
        request.fetchUpdateEnvironmentSettings,
        payload
      );
      yield put(fetchEnvironmentSettingsBySystemSuccess(environmentSettings));
      yield call(fetchEnvironments, activeSystemId);
    }
  } catch (e) {
    createError(e);
  }
}

function* fetchWorkGroupsByContractId({
  payload,
}: FetchWorkGroupsByContractRequestContractDetailAction) {
  try {
    const filter: ReturnType<typeof getGroupsFilterByContractContractDetail> =
      yield select(getGroupsFilterByContractContractDetail);
    const {
      pageNum,
      pageSize,
      sort,
    }: ReturnType<typeof getPropsGroupsByContractContractDetail> = yield select(
      getPropsGroupsByContractContractDetail
    );
    if (payload) {
      yield put(loadingShowWorkGroupsByContractContractDetail());
      const workGroups: ResponseWithMeta<WorkGroup[]> = yield call(
        request.fetchWorkGroupsByContractId,
        payload,
        pageNum,
        pageSize,
        sort,
        getFilterGroupsToRequest(filter)
      );
      yield put(fetchWorkGroupsByContractSuccessContractDetail(workGroups));
    }
    yield put(loadingHideWorkGroupsByContractContractDetail());
  } catch (e) {
    createError(e);
    yield put(loadingHideWorkGroupsByContractContractDetail());
  }
}

function* fetchSystemsForSA({
  payload,
}: FetchSystemsForAddingSupplementaryAgreementRequestContractDetailAction) {
  try {
    yield put(
      loadingShowSystemsForAddingSupplementaryAgreementContractDetail()
    );
    const contract: ReturnType<typeof getContractDetail> = yield select(
      getContractDetail
    );
    if (contract?.id) {
      const systems: ResponseWithMeta<System[]> = yield call(
        request.fetchSystemsByContractId,
        contract.id,
        0,
        100000,
        'title_DESC',
        { title: payload }
      );
      yield put(
        fetchSystemsForAddingSupplementaryAgreementSuccessContractDetail(
          systems.content
        )
      );
    }
    yield put(
      loadingHideSystemsForAddingSupplementaryAgreementContractDetail()
    );
  } catch (e) {
    yield put(
      loadingHideSystemsForAddingSupplementaryAgreementContractDetail()
    );
    createError(e);
  }
}

function* signContractRequest({
  payload,
}: ContractSignRequestContractDetailAction) {
  try {
    yield call(request.signContract, payload);
    yield put(fetchContractDetailRequest(payload));
  } catch (e) {
    createError(e);
  }
}

function* fetchSLAPatterns({
  payload,
}: FetchSLAPatternsRequestContractDetailAction) {
  try {
    yield put(loadingShowSLAPatternsContractDetail());
    const sla: SLAType[] = yield call(request.fetchSLAPatterns, payload);
    yield put(fetchSLAPatternsSuccessContractDetail(sla));
    yield put(loadingHideSLAPatternsContractDetail());
  } catch (e) {
    yield put(loadingHideSLAPatternsContractDetail());
    createError(e);
  }
}
function* createSLAPattern({
  payload,
}: CreateSLAPatternRequestContractDetailAction) {
  try {
    yield call(request.createPattern, payload);
  } catch (e) {
    createError(e);
  }
}

function* updateNotificationsExpireContract({
  payload,
}: UpdateContractNotificationsAction) {
  try {
    const { contractId } = payload;
    if (contractId) {
      const data: Contract = yield call(
        request.updateNotificationsExpireContract,
        payload
      );
      yield put(updateNotificationsExpireContractSuccess(data));
    }
  } catch (e) {
    createError(e);
  }
}

function* updateContractPermission({
  payload,
}: UpdateContractPermissionsAction) {
  try {
    const { contractId, isEnable } = payload;
    if (contractId) {
      const data: Contract = yield call(
        request.updateContractPermission,
        payload
      );
      yield put(updateContractPermissionSuccess(data));
      return;
    }
    yield put(setContractPermission({ isEnable }));
  } catch (e) {
    createError(e);
  }
}

function* checkExistPatternTitle({
  payload,
}: CheckIsExistPatternTitleRequestContractDetailAction) {
  try {
    const isExist: boolean = yield call(request.isExistPatternTitle, payload);
    yield put(checkIsExistPatternTitleSuccessContractDetail(isExist));
  } catch (e) {
    createError(e);
  }
}

function* fetchPatternById({
  payload,
}: FetchPatternByIdRequestContractDetailAction) {
  try {
    const pattern: SLAType = yield call(request.fetchPatternById, payload);
    yield put(fetchPatternByIdSuccessContractDetail(pattern));
  } catch (e) {
    createError(e);
  }
}

function* fetchSettingsTicketType({
  payload,
}: FetchTicketTypesSettingsRequestContractDetailAction) {
  try {
    yield put(setLoadingTicketTypeSettingsContractDetail(true));
    const ticketTypes: TicketTypeSettings[] = yield call(
      request.fetchSettingsTicketType,
      payload
    );
    yield put(fetchTicketTypeSettingsSuccessContractDetail(ticketTypes));
  } catch (e) {
    createError(e);
  } finally {
    yield put(setLoadingTicketTypeSettingsContractDetail(false));
  }
}

function* fetchSLAByContractIdBySystemId({
  payload,
}: FetchSlaByContractIdBySystemIdRequestContractDetailAction) {
  try {
    const { systemId, contractId } = payload;
    if (systemId && contractId) {
      yield put(loadingShowSlaByContractIdBySystemIdRequestContractDetail());
      const sla: SLAType = yield call(
        request.fetchSLAByContractIdBySystemId,
        contractId,
        systemId
      );
      yield put(fetchSlaByContractIdBySystemIdSuccessContractDetail(sla));
      yield put(loadingHideSlaByContractIdBySystemIdRequestContractDetail());

      yield call(fetchSettingsTicketType, {
        type: ContractDetail.FETCH_TICKET_TYPE_SETTINGS_REQUEST,
        payload: { systemId, contractId },
      });
    }
  } catch (e) {
    yield put(loadingHideSlaByContractIdBySystemIdRequestContractDetail());
    createError(e);
  }
}

function* fetchTicketTypes({
  payload,
}: FetchTicketTypesRequestContractDetailAction) {
  try {
    const ticketTypes: TicketType[] = yield call(
      request.fetchTicketTypes,
      payload
    );
    yield put(fetchTicketTypesSuccessContractDetail(ticketTypes));
  } catch (e) {
    createError(e);
  }
}

function* attachSystemAndSLAToContract({
  payload,
}: AttachSystemAndSLAToContractDetailAction) {
  try {
    const { systemId, sla, contractId } = payload;
    if (systemId && contractId && sla) {
      yield call(request.addSLAAndSystemToContract, contractId, systemId, sla);
      yield put(
        fetchSystemsByContractIdRequestContractDetail({ id: contractId })
      );
      yield put(setActiveSystemByContractIdContractDetail(systemId));
      yield put(
        fetchSlaByContractIdBySystemIdRequestContractDetail({
          contractId,
          systemId,
        })
      );
      yield put(
        setAlert(
          getSuccessAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.EDIT)
        )
      );
    }
  } catch (e) {
    yield put(
      setAlert(getErrorAlert(ENTITY_CONTRACT_TITLE, ActionForAlertTypes.EDIT))
    );
    createError(e);
  }
}

function* updateContractSLA({
  payload,
}: UpdateContractSLARequestContractDetailAction) {
  try {
    const { sla, ...other } = payload;
    const { id, ...slaOther } = sla;
    if (id) {
      yield call(request.updateContractSLA, slaOther, id);
    }
    yield put(fetchSlaByContractIdBySystemIdRequestContractDetail(other));
  } catch (e) {
    createError(e);
  }
}

export function* contractDetailSaga(): Generator<StrictEffect> {
  yield takeEvery(ContractDetail.FETCH_CONTRACT_REQUEST, contractDetailFetch);
  yield takeEvery(
    ContractDetail.FETCH_SUPPLEMENTARY_AGREEMENTS_REQUEST,
    supplementaryAgreementsFetch
  );
  yield takeEvery(ContractDetail.DELETE_CONTRACT_REQUEST, deleteContract);
  yield takeEvery(ContractDetail.CREATE_CONTRACT, contractCreate);
  yield takeEvery(ContractDetail.UPDATE_CONTRACT, updateContractRequest);
  yield takeEvery(ContractDetail.SYSTEM_TRANSFER, systemTransfer);
  yield takeEvery(
    ContractDetail.UPDATE_CONTRACT_NOTIFICATIONS,
    updateNotificationsExpireContract
  );
  yield takeEvery(
    ContractDetail.UPDATE_CONTRACT_PERMISSION,
    updateContractPermission
  );
  yield takeEvery(
    ContractDetail.FETCH_ORGANIZATIONS_REQUEST,
    organizationsFetch
  );
  yield takeEvery(
    ContractDetail.FETCH_PARTNER_ORGANIZATIONS_REQUEST,
    partnerOrganizationsFetch
  );
  yield takeEvery(
    ContractDetail.FETCH_CONTRACT_ATTACHMENTS_REQUEST,
    fetchContractAttachments
  );
  yield takeEvery(
    ContractDetail.FETCH_USERS_ALLOWED_TO_ADD_TO_CONTRACT_REQUEST,
    usersFetchByContractId
  );
  yield takeEvery(
    ContractDetail.FETCH_SYSTEMS_BY_CONTRACT_ID_REQUEST,
    fetchSystemsByContractId
  );
  yield takeEvery(ContractDetail.FETCH_SYSTEMS_REQUEST, fetchSystems);
  yield takeEvery(
    ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST,
    fetchGroups
  );
  yield takeEvery(
    ContractDetail.FETCH_ENVIRONMENTS_BY_SYSTEM_REQUEST,
    fetchEnvironmentsBySystem
  );
  yield takeEvery(
    ContractDetail.FETCH_ENVIRONMENT_SETTINGS_BY_SYSTEM_REQUEST,
    fetchEnvironmentSettings
  );
  yield takeEvery(
    ContractDetail.FETCH_UPDATE_ENVIRONMENT_SETTINGS_REQUEST,
    fetchUpdateEnvironmentSettings
  );
  yield takeEvery(
    ContractDetail.FETCH_WORK_GROUPS_BY_CONTRACT_REQUEST,
    fetchWorkGroupsByContractId
  );
  yield takeEvery(
    ContractDetail.UPDATE_WORK_GROUPS_IN_CONTRACT_IN_SYSTEM,
    updateGroupsToContractToSystem
  );
  yield takeEvery(
    ContractDetail.FETCH_SYSTEMS_FOR_ADDING_SUPPLEMENTARY_AGREEMENT_REQUEST,
    fetchSystemsForSA
  );
  yield takeEvery(ContractDetail.CONTRACT_SIGN_REQUEST, signContractRequest);
  yield takeEvery(ContractDetail.FETCH_SLA_PATTERNS_REQUEST, fetchSLAPatterns);
  yield takeEvery(ContractDetail.CREATE_SLA_PATTERN_REQUEST, createSLAPattern);
  yield takeEvery(
    ContractDetail.CHECK_IS_EXIST_PATTERN_TITLE_REQUEST,
    checkExistPatternTitle
  );
  yield takeEvery(ContractDetail.FETCH_PATTERN_BY_ID_REQUEST, fetchPatternById);
  yield takeEvery(
    ContractDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
    fetchSLAByContractIdBySystemId
  );
  yield takeEvery(
    ContractDetail.FETCH_TICKET_TYPE_SETTINGS_REQUEST,
    fetchSettingsTicketType
  );
  yield takeEvery(ContractDetail.FETCH_TICKET_TYPES_REQUEST, fetchTicketTypes);
  yield takeEvery(
    ContractDetail.ATTACH_SYSTEM_AND_SLA_TO_CONTRACT,
    attachSystemAndSLAToContract
  );
  yield takeEvery(
    ContractDetail.UPDATE_CONTRACT_SLA_REQUEST,
    updateContractSLA
  );
}

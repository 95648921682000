import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getScreenWidth } from 'core/ducks/selectors';
import { ScreenWidth } from 'core/types';
import { Article, CategoryArticle } from 'features/KnowledgeBase/types';

export const useKBCategory = () => {
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [isMouseOnTitle, setIsMouseOnTitle] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(true);

  const toggleCategoryOpen = () => {
    setIsCategoryOpen((prev) => !prev);
  };

  const screenWidth = useSelector(getScreenWidth);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const onMouseEnter = () => {
    setIsMouseOnTitle(true);
  };

  const onMouseLeave = () => {
    setIsMouseOnTitle(false);
  };

  const togglePopover = (event?: MouseEvent) => {
    event?.stopPropagation();
    setIsDropOpen((prev) => !prev);
  };

  const getListArticlesAndSubCategories = (
    fullShow: boolean,
    categories: CategoryArticle[] = [],
    articles: Article[] = []
  ) => {
    const list: { articlesList: Article[]; categoriesList: CategoryArticle[] } =
      {
        articlesList: fullShow ? articles : articles.slice(0, 5),
        categoriesList: [],
      };

    if (fullShow) {
      list.categoriesList = categories;
      return list;
    }
    if (articles.length < 5) {
      list.categoriesList = categories.slice(0, 5 - articles.length);
    }
    return list;
  };

  return {
    methods: {
      togglePopover,
      onMouseEnter,
      onMouseLeave,
      toggleCategoryOpen,
      getListArticlesAndSubCategories,
    },
    state: {
      isMobileAll,
      isDropOpen,
      isMouseOnTitle,
      isCategoryOpen,
    },
  };
};

import cn from 'clsx';
import React, { useEffect } from 'react';
import useSound from 'use-sound';

import { Notification, Props as NotificationProps } from '../Notification';

import notificationSound from './notification-sound.mp3';
import styles from './NotificationToast.module.scss';

interface Props extends NotificationProps {
  className?: string;
}

export const NotificationToast: React.FC<Props> = ({
  className,
  entities,
  ...notificationProps
}) => {
  const [play] = useSound(notificationSound);

  useEffect(() => {
    play();
  }, [play, entities?.length]);

  return (
    <div className={cn(styles.notificationToast, className)}>
      <Notification
        type="toast"
        entities={entities}
        {...notificationProps}
        classNameText={styles.notificationToast__text}
        classNameTitle={styles.notificationToast__text}
      />
    </div>
  );
};

import React from 'react';

import { AdminsLayout } from 'core/layouts/AdminsLayout';
import { Users } from 'features/Users';

const AdminUsers = () => {
  return (
    <AdminsLayout>
      <Users />
    </AdminsLayout>
  );
};

export default AdminUsers;

import cn from 'clsx';
import React, { useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import { BigBell } from 'assets/icons';
import { Loader } from 'components/Loader';
import { Typography, TypographyVariants } from 'components/Typography';
import { getScreenWidth } from 'core/ducks/selectors';
import { PageTitleLayout } from 'core/layouts/PageTitleLayout';
import { ScreenWidth } from 'core/types';
import { Notification } from 'features/Notifications/components/Notification';
import { setActiveNotificationId } from 'features/Notifications/ducks/actions';
import { getActiveNotificationId } from 'features/Notifications/ducks/selectors';
import { EntityTypes, NotificationContent } from 'features/Notifications/types';
import { convertPathToTabTitle } from 'features/Profile/utils/convertTabTitleToPath';
import { formatDateTitle } from 'features/Profile/utils/fotmatDataTitle';
import { splitByDate } from 'features/Profile/utils/splitByDate';
import {
  fetchTicketRequest,
  resetTicketsState,
} from 'features/Tickets/ducks/actions';
import { getTicketLoading } from 'features/Tickets/ducks/selectors';
import { getProfileTitle } from 'routes/utils';

import {
  fetchNotificationsProfileRequest,
  fetchNotificationTabsRequest,
  resetNotificationList,
  setCheckedNotificationRequest,
  setNotificationsProfileFilter,
} from '../../ducks/actions';
import { getNotificationTabs } from '../../ducks/selectors';
import { useTabType } from '../../hooks/useTabType';

import styles from './NotificationsList.module.scss';

interface Props {
  notifications: NotificationContent[];
  onLoadMore: (page: number) => void;
  hasMore: boolean;
  loading?: boolean;
  totalPages: number;
}

export const NotificationsList: React.FC<Props> = ({
  notifications,
  onLoadMore,
  hasMore,
  loading,
  totalPages,
}) => {
  const notificationsAll = splitByDate(notifications);

  const dispatch = useDispatch();

  const activeId = useSelector(getActiveNotificationId);
  const screenWidth = useSelector(getScreenWidth);
  const tabs = useSelector(getNotificationTabs);
  const ticketLoading = useSelector(getTicketLoading);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const tabType = useTabType();

  const notificationPathToType = convertPathToTabTitle(tabType);

  const documentTitle = useMemo(
    () =>
      getProfileTitle(
        tabs.find((tab) => tab.titleNotification === notificationPathToType)
          ?.description || 'Все уведомления'
      ),
    [tabType, tabs]
  );

  const handleNotificationClick = (value: NotificationContent) => {
    if (value.entityId && value.entityType === EntityTypes.TICKET) {
      dispatch(fetchTicketRequest(value.entityId));
    }
    if (value.id) {
      dispatch(setActiveNotificationId(value.id));
      dispatch(setCheckedNotificationRequest(value.id));
    }
  };

  const handleLoadMore = (page: number) => {
    if (page < totalPages && !loading) {
      onLoadMore(page);
    }
  };

  useEffect(() => {
    if (tabType) {
      dispatch(resetTicketsState());
      dispatch(resetNotificationList());
      dispatch(fetchNotificationTabsRequest());
      dispatch(setActiveNotificationId(''));
      dispatch(
        setNotificationsProfileFilter({
          notificationType: convertPathToTabTitle(tabType),
          notChecked: false,
        })
      );
      dispatch(
        fetchNotificationsProfileRequest({
          updateType: 'update',
          pageNum: 0,
        })
      );
    }
  }, [tabType]);

  if (!Object.keys(notificationsAll).length) {
    return (
      <div className={styles.notificationsList__noData}>
        {loading && <Loader />}
        {!loading && (
          <>
            <BigBell className={styles.notificationsList__bellIcon} />
            <Typography variant={TypographyVariants.b2}>
              У вас нет уведомлений
            </Typography>
          </>
        )}
      </div>
    );
  }

  const notificationsList = Object.keys(notificationsAll).map((key: string) => {
    return (
      <div key={key}>
        {formatDateTitle(key)}
        {notificationsAll[key].map((item) => {
          const isActiveId = item?.id === activeId;

          return (
            <div
              key={item.id}
              className={cn(styles.notificationsList__itemWrapper, {
                [styles.notificationsList__itemWrapper_active]: isActiveId,
              })}
            >
              <Notification
                notification={item}
                className={cn(styles.notificationsList__item, {
                  [styles.notificationsList__item_disabled]:
                    ticketLoading || isActiveId,
                })}
                onClick={handleNotificationClick}
                disabled={ticketLoading || isActiveId}
              />
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <PageTitleLayout pageTitle={documentTitle}>
      <div className={styles.notificationsList}>
        <InfiniteScroll
          loadMore={handleLoadMore}
          hasMore={!loading && hasMore}
          useWindow={isMobileAll}
          className={styles.notificationsList__content}
          threshold={10}
        >
          {notificationsList}
        </InfiniteScroll>
      </div>
    </PageTitleLayout>
  );
};

import cn from 'clsx';
import React from 'react';

import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { TableContainer } from 'components/Table/components/TableContainer';
import { DeleteButton } from 'core/components/DeleteButton';
import { entityNames } from 'core/constants';
import { useKBTableContainer } from 'features/KnowledgeBase/views/KBTable/hooks/useKBTableContainer';

import { KBTableArticles } from '../KBTableArticles/KBTableArticles';

import styles from './KBTableArticlesContainer.module.scss';

interface Props {
  className?: string;
}

export const KBTableArticlesContainer: React.FC<Props> = ({ className }) => {
  const { methods, state } = useKBTableContainer();

  const {
    toggleDeleteModal,
    toggleFilterRow,
    handleSort,
    handleChangePage,
    handleChangePageSize,
    handleDelete,
    handleShowModalDelete,
    onRowClick,
  } = methods;

  const {
    pageNum,
    pageSize,
    sortArticlesTable,
    totalElements,
    articles,
    showFilterRow,
    showDeleteModal,
    isFilterActive,
    searchDisabled,
    currentArticleTable,
    tableTitle,
    articleTitle,
    loadingArticlesTable,
    canEditCategory,
  } = state;

  const getContent = () => {
    return (
      <KBTableArticles
        articles={articles}
        handleSort={handleSort}
        sort={sortArticlesTable}
        handleRowClick={onRowClick}
        activeRowId={currentArticleTable?.id}
        showFilterRow={showFilterRow}
        isFilterActive={isFilterActive}
      />
    );
  };

  const dropContent = (
    <PopoverContainer>
      <DeleteButton
        onClick={handleShowModalDelete}
        disabled={!currentArticleTable?.id}
      />
    </PopoverContainer>
  );

  return (
    <TableContainer
      classNameRoot={cn(styles.KBTableArticlesContainer, className)}
      headerProps={{
        title: tableTitle,
        loading: loadingArticlesTable,
        dropContent: canEditCategory ? dropContent : undefined,
        searchDisabled,
        isSearchOpen: showFilterRow,
        toggleSearch: toggleFilterRow,
      }}
      contentTable={getContent()}
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        elementsCount: totalElements,
        pageSize,
        handleChangePage,
        handleChangePageSize,
        disabled: loadingArticlesTable,
        entityName: entityNames.KB_TABLE_ARTICLES,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.KBTableArticlesContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить статью ${articleTitle}?`,
      }}
    />
  );
};

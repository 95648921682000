import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ChartBlock } from 'components/ChartBlock';
import { RadioSlide } from 'components/RadioSlide';
import { Size } from 'components/types';

import { NO_DATA_GRAPH, radioTabs } from '../../constants';
import { KpiData, OrganizationsType, RadioTabs } from '../../types';
import { getCurrentOrganization } from '../../utils';

import styles from './KpiTicketsPieChart.module.scss';

interface Props {
  kpiData?: KpiData;
}

export const KpiTicketsPieChart: React.FC<Props> = ({ kpiData }) => {
  const organizationsInStatusNew = kpiData?.info.organizations.inStatusNew;
  const organizationsInStatusClose = kpiData?.info.organizations.inStatusClose;
  const organizationsInActiveStatus =
    kpiData?.info.organizations.inActiveStatus;

  const { watch, control } = useForm<RadioTabs>({
    mode: 'onChange',
    defaultValues: {
      infoType: OrganizationsType.IN_STATUS_CLOSE,
    },
  });

  const infoType = watch('infoType');

  const organizationsMap = {
    [OrganizationsType.IN_STATUS_CLOSE]: () => organizationsInStatusClose,
    [OrganizationsType.IN_STATUS_NEW]: () => organizationsInStatusNew,
    [OrganizationsType.IN_ACTIVE_STATUS]: () => organizationsInActiveStatus,
  };

  const organizations = organizationsMap[infoType]()
    ?.slice()
    .sort((a, b) => {
      const valueDifference = Number(b.value) - Number(a.value);
      if (valueDifference > 0 || valueDifference < 0) {
        return valueDifference;
      }
      const nameA = a.key.toLowerCase();
      const nameB = b.key.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

  const dataGraph = useMemo(
    () => getCurrentOrganization(organizations),
    [organizations, getCurrentOrganization]
  );
  const currentDataGraph =
    !!organizations?.length && organizations ? dataGraph : NO_DATA_GRAPH;

  const headerElement = organizations && (
    <Controller
      control={control}
      name="infoType"
      rules={{
        required: true,
      }}
      render={({ field }) => {
        return (
          <RadioSlide
            size={Size.xs}
            items={radioTabs}
            value={field.value}
            onChange={field.onChange}
            className={styles.tickets__headerElement}
          />
        );
      }}
    />
  );

  return (
    <ChartBlock
      chartType="pieChart"
      title="Тикеты"
      dataChart={currentDataGraph}
      className={styles.tickets__chart}
      classNamePieChart={styles.tickets__chartContainer}
      headerElement={headerElement}
    />
  );
};

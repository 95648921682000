import { Reducer } from 'redux';

import { SelectOption } from 'components/Select/types';
import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { Organization } from 'core/types';
import {
  Article,
  CategoryArticle,
  KBArticleTableFilterToRequest,
} from 'features/KnowledgeBase/types';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import {
  ArticleViewing,
  ArticleViewingActionsTypes,
  FetchArticlesJoinSuccessArticleViewingAction,
  FetchArticlesSuccessArticleViewingAction,
  FetchCategoriesSuccessArticleViewingAction,
  FetchCurrentArticleSuccessArticleViewingAction,
  FetchOrganizationsSuccessArticleViewingAction,
  SetArticlesFilterArticleViewingAction,
  SetArticlesPageArticleViewingAction,
  SetSearchValueArticleViewingAction,
  SetSelectedOrganizationArticleViewingAction,
} from './types';

export interface ArticleViewingReducerState {
  currentArticle?: Article;
  currentArticleLoading?: boolean;

  articlesFilterArticleViewing?: KBArticleTableFilterToRequest;
  articlesArticleViewing?: Article[];
  loadingArticlesArticleViewing?: boolean;
  loadingOrganizationsArticleViewing?: boolean;
  articlesPaginationArticleViewing?: Pagination;
  organizationsArticleViewing?: Organization[];
  organizationSelected?: SelectOption | null;
  searchValue?: string;
  categoriesArticleViewing?: CategoryArticle[];
  categoriesLoadingArticleViewing?: boolean;
}

const initialState: ArticleViewingReducerState = {
  currentArticleLoading: false,
  organizationsArticleViewing: [],
  loadingArticlesArticleViewing: false,
  loadingOrganizationsArticleViewing: false,
  articlesArticleViewing: [],
  articlesPaginationArticleViewing: {
    ...DEFAULT_PAGINATION_PARAMS,
    pageSize: 20,
  },
  organizationSelected: null,
  categoriesArticleViewing: [],
  categoriesLoadingArticleViewing: false,
};

const articleViewingReducerMap: ReducerMap<
  ArticleViewingReducerState,
  ArticleViewingActionsTypes
> = {
  [ArticleViewing.FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_VIEWING]: (
    state,
    action
  ) => {
    const { payload } =
      action as FetchCurrentArticleSuccessArticleViewingAction;
    return {
      ...state,
      currentArticle: payload,
    };
  },
  [ArticleViewing.LOADING_SHOW_CURRENT_ARTICLE_ARTICLE_VIEWING]: (state) => {
    return {
      ...state,
      currentArticleLoading: true,
    };
  },
  [ArticleViewing.LOADING_HIDE_CURRENT_ARTICLE_ARTICLE_VIEWING]: (state) => {
    return {
      ...state,
      currentArticleLoading: false,
    };
  },
  [ArticleViewing.SET_FILTER_ARTICLES_ARTICLE_VIEWING]: (state, action) => {
    const { payload } = action as SetArticlesFilterArticleViewingAction;
    return {
      ...state,
      articlesFilterArticleViewing: payload,
    };
  },
  [ArticleViewing.FETCH_SUCCESS_ARTICLES_ARTICLE_VIEWING]: (state, action) => {
    const { payload } = action as FetchArticlesSuccessArticleViewingAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      articlesArticleViewing: content,
      articlesPaginationArticleViewing: {
        ...state?.articlesPaginationArticleViewing,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ArticleViewing.FETCH_ARTICLES_JOIN_SUCCESS_ARTICLE_VIEWING]: (
    state,
    action
  ) => {
    const { payload } = action as FetchArticlesJoinSuccessArticleViewingAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      articlesArticleViewing: [
        ...(state?.articlesArticleViewing || []),
        ...content,
      ],
      articlesPaginationArticleViewing: {
        ...state?.articlesPaginationArticleViewing,
        pageNum: page,
        totalElements,
      },
    };
  },
  [ArticleViewing.SET_ARTICLES_PAGE_ARTICLE_VIEWING]: (state, action) => {
    const { payload } = action as SetArticlesPageArticleViewingAction;
    return {
      ...state,
      articlesPaginationArticleViewing: {
        ...state?.articlesPaginationArticleViewing,
        pageNum: payload,
      },
    };
  },
  [ArticleViewing.LOADING_SHOW_ARTICLES_ARTICLE_VIEWING]: (state) => {
    return {
      ...state,
      loadingArticlesArticleViewing: true,
    };
  },
  [ArticleViewing.LOADING_HIDE_ARTICLES_ARTICLE_VIEWING]: (state) => {
    return {
      ...state,
      loadingArticlesArticleViewing: false,
    };
  },
  [ArticleViewing.FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_VIEWING]: (
    state,
    action
  ) => {
    const { payload } = action as FetchOrganizationsSuccessArticleViewingAction;
    return {
      ...state,
      organizationsArticleViewing: payload,
    };
  },
  [ArticleViewing.ORGANIZATIONS_LOADING_SHOW_ARTICLE_VIEWING]: (state) => {
    return {
      ...state,
      loadingOrganizationsArticleViewing: true,
    };
  },
  [ArticleViewing.ORGANIZATIONS_LOADING_HIDE_ARTICLE_VIEWING]: (state) => {
    return {
      ...state,
      loadingOrganizationsArticleViewing: false,
    };
  },
  [ArticleViewing.CATEGORIES_LOADING_SHOW_ARTICLE_VIEWING]: (state) => {
    return {
      ...state,
      categoriesLoadingArticleViewing: true,
    };
  },
  [ArticleViewing.CATEGORIES_LOADING_HIDE_ARTICLE_VIEWING]: (state) => {
    return {
      ...state,
      categoriesLoadingArticleViewing: false,
    };
  },
  [ArticleViewing.FETCH_CATEGORIES_SUCCESS_ARTICLE_VIEWING]: (
    state,
    action
  ) => {
    const { payload } = action as FetchCategoriesSuccessArticleViewingAction;
    return {
      ...state,
      categoriesArticleViewing: payload,
    };
  },
  [ArticleViewing.RESET_ARTICLES_STATE_ARTICLE_VIEWING]: (state) => {
    const {
      articlesArticleViewing,
      articlesPaginationArticleViewing,
      searchValue,
      articlesFilterArticleViewing,
    } = initialState;
    return {
      ...state,
      searchValue,
      articlesArticleViewing,
      articlesPaginationArticleViewing,
      articlesFilterArticleViewing,
    };
  },
  [ArticleViewing.RESET_CATEGORIES_STATE_ARTICLE_VIEWING]: (state) => {
    const {
      categoriesArticleViewing,
      organizationSelected,
      organizationsArticleViewing,
    } = initialState;
    return {
      ...state,
      categoriesArticleViewing,
      organizationSelected,
      organizationsArticleViewing,
    };
  },
  [ArticleViewing.SET_SEARCH_VALUE_ARTICLE_VIEWING]: (state, action) => {
    const { payload } = action as SetSearchValueArticleViewingAction;
    return {
      ...state,
      searchValue: payload,
    };
  },
  [ArticleViewing.SET_ORGANIZATION_SELECTED_ARTICLE_VIEWING]: (
    state,
    action
  ) => {
    const { payload } = action as SetSelectedOrganizationArticleViewingAction;
    return {
      ...state,
      organizationSelected: payload,
    };
  },
  [ArticleViewing.RESET_STATE_ARTICLE_VIEWING]: () => {
    return {
      ...initialState,
    };
  },
};

export const articleViewing: Reducer<
  ArticleViewingReducerState,
  ArticleViewingActionsTypes
> = (state = initialState, action) => {
  const reducer = articleViewingReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};

import cn from 'clsx';
import React from 'react';

import { Props as CategoryProps, KBCategory } from '../../KBCategory';

import styles from './KBSubCategory.module.scss';

interface Props extends CategoryProps {}

export const KBSubCategory: React.FC<Props> = ({
  category,
  dropProps,
  ...other
}) => {
  const { articles } = category;
  const hasArticles = Boolean(articles?.length);

  return (
    <div
      className={cn(styles.KBSubCategory, {
        [styles.KBSubCategory_withPadding]: !hasArticles,
      })}
    >
      <KBCategory
        category={category}
        dropProps={{ ...dropProps, isSubCategory: true }}
        {...other}
        isSubCategory
      />
    </div>
  );
};

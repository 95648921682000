import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { FetchRequestArgs } from 'components/SearchSelect/types';
import { ObjectType } from 'types/models/meta';

export const useInfiniteScroll = ({
  pageNum,
  pageSize,
  totalElements,
  loading,
}: FetchRequestArgs<ObjectType>) => {
  const [infiniteScrollable, setInfiniteScrollable] = useState(false);

  const totalPage = useMemo(() => {
    return !isNil(totalElements) && !isNil(pageSize)
      ? Math.ceil(totalElements / pageSize)
      : 0;
  }, [totalElements, pageSize]);

  const hasMore = useMemo(() => {
    return pageNum !== undefined && !loading && totalPage > pageNum + 1;
  }, [loading, totalPage, pageNum]);

  useEffect(() => {
    if (pageNum) {
      setInfiniteScrollable(
        (pageNum === 0 && totalElements === 0) || pageNum < totalPage - 1
      );
    }
  }, [pageNum, totalPage, totalElements]);

  return { infiniteScrollable, setInfiniteScrollable, totalPage, hasMore };
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MainLayout } from 'core/layouts/MainLayout';
import { useArticleId } from 'features/KnowledgeBase/views/KBArticle/hooks/useArticleId';
import { useCreateArticleForm } from 'features/KnowledgeBase/views/KBCreateArticle/hooks/useCreateArticleForm';

import { KBCreateArticle } from '../../views/KBCreateArticle';
import { KBCreateArticleHeader } from '../../views/KBCreateArticle/components/KBCreateArticleHeader';
import {
  fetchCurrentArticleRequestArticleCreate,
  resetArticleCreateState,
} from '../../views/KBCreateArticle/ducks/actions';
import { getCurrentArticle } from '../../views/KBCreateArticle/ducks/selectors';

interface Props {
  isEditMode?: boolean;
}

const KBCreateArticlePage: React.FC<Props> = ({ isEditMode = false }) => {
  const dispatch = useDispatch();
  const articleId = useArticleId();
  const article = useSelector(getCurrentArticle);

  useEffect(() => {
    if (articleId && isEditMode) {
      dispatch(fetchCurrentArticleRequestArticleCreate(articleId));
    }
  }, [articleId]);

  useEffect(
    () => () => {
      dispatch(resetArticleCreateState());
    },
    []
  );

  const data = useCreateArticleForm({ article, isEditMode });

  return (
    <MainLayout withHeader={false}>
      <KBCreateArticleHeader form={data} isEditMode={isEditMode} />
      <KBCreateArticle form={data} />
    </MainLayout>
  );
};

export default KBCreateArticlePage;

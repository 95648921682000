import cn from 'clsx';
import React, { useMemo } from 'react';

import { ErrorRoundIcon } from 'assets/icons';
import { PieChartPriority } from 'features/Desktop/components/PieChartPriority';
import { TicketsDashboard } from 'features/Desktop/components/TicketsDashboard';
import { TicketsTableContainer } from 'features/Tickets/components/TicketsTableContainer';
import { TableTicketsTypes } from 'features/Tickets/types';

import { TicketsDropContent } from '../../components/TicketsDropContent';
import { useHistoryForm } from '../../hooks/useHistoryForm';

import styles from './DesktopHistory.module.scss';

interface Props {
  className?: string;
}

export const DesktopHistory: React.FC<Props> = ({ className }) => {
  const {
    state: { tickets, ticketsStatistic, isDownload },
    methods: { handleDownload },
  } = useHistoryForm();

  const tableTitle = useMemo(
    () => (
      <div className={styles.desktopHistory__titleWrapper}>
        <span className={styles.desktopHistory__title}>
          Тикеты с нарушением SLA
        </span>
        {Boolean(tickets?.length) && <ErrorRoundIcon />}
      </div>
    ),
    [tickets]
  );

  const downloadTickets = tickets?.length ? (
    <TicketsDropContent
      isDownload={isDownload}
      handleDownload={handleDownload}
    />
  ) : undefined;

  return (
    <div className={cn(styles.desktopHistory, className)}>
      <div className={styles.desktopHistory__leftContainer}>
        <TicketsDashboard ticketsStatistic={ticketsStatistic} />
        <PieChartPriority
          title="Тикеты по приоритетам"
          ticketsStatistic={ticketsStatistic}
          className={styles.desktopHistory__chart}
        />
      </div>
      <TicketsTableContainer
        tableType={TableTicketsTypes.FROM_DESKTOP_HISTORY}
        title={tableTitle}
        withOutTabs
        withFilter={false}
        dropContent={downloadTickets}
        className={styles.desktopHistory__table}
      />
    </div>
  );
};

import cn from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LazyLoader } from 'core/components/LazyLoader';
import { resetFilterOrganizationIdState } from 'features/Organizations/ducks/actions';
import { ResponsibilitiesTableContainer } from 'features/Responsibilities/components/ResponsibilitiesTableContainer';
import {
  fetchRespByGroupIdRequest,
  resetResponsibilitiesState,
} from 'features/Responsibilities/ducks/actions';
import { TableResponsibilitiesTypes } from 'features/Responsibilities/types';
import { RolesTableContainer } from 'features/Roles/components/RolesTableContainer';
import {
  fetchRolesByGroupId,
  resetRolesState,
} from 'features/Roles/ducks/actions';
import { TableRolesTypes } from 'features/Roles/types';
import { UsersTableContainer } from 'features/Users/components/UsersTableContainer';
import {
  fetchUsersByGroupId,
  resetUsersState,
} from 'features/Users/ducks/actions';
import { TableUsersTypes } from 'features/Users/types';
import {
  fetchCurrentWorkGroup,
  resetWorkGroupsState,
  setWorkGroupId,
} from 'features/WorkGroups/ducks/actions';
import {
  getLoadingWorkGroup,
  getWorkGroup,
} from 'features/WorkGroups/ducks/selectors';
import { useWorkGroupId } from 'features/WorkGroups/hooks/useWorkGroupId';

import { WorkGroupForm } from './components/WorkGroupForm';
import styles from './WorkGroup.module.scss';

interface Props {
  className?: string;
}

export const WorkGroup: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch();

  const workGroupId = useWorkGroupId();

  const workGroup = useSelector(getWorkGroup);
  const loading = useSelector(getLoadingWorkGroup);

  useEffect(() => {
    if (workGroupId) {
      dispatch(setWorkGroupId(workGroupId));
      dispatch(fetchRespByGroupIdRequest());
      dispatch(fetchUsersByGroupId());
      dispatch(fetchRolesByGroupId());
      dispatch(fetchCurrentWorkGroup(workGroupId));
    }
  }, [workGroupId]);

  useEffect(
    () => () => {
      dispatch(resetWorkGroupsState());
      dispatch(resetUsersState());
      dispatch(resetResponsibilitiesState());
      dispatch(resetFilterOrganizationIdState());
      dispatch(resetRolesState());
    },
    []
  );

  if (loading && !workGroup) {
    return <LazyLoader className={styles.workGroup__loader} />;
  }

  return (
    <div className={cn(styles.workGroup, className)}>
      <div className={styles.workGroup__leftContainer}>
        <WorkGroupForm />
        <UsersTableContainer tableType={TableUsersTypes.FROM_GROUPS} />
      </div>
      <div className={styles.workGroup__rightContainer}>
        <ResponsibilitiesTableContainer
          tableType={TableResponsibilitiesTypes.FROM_GROUPS}
        />
        <RolesTableContainer tableType={TableRolesTypes.FROM_GROUPS} />
      </div>
    </div>
  );
};

import cn from 'clsx';
import React from 'react';

import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { TableContainer } from 'components/Table/components/TableContainer';
import { DeleteButton } from 'core/components/DeleteButton';

import { PAGINATION_RANGE_MAP } from '../../constants';
import { useSystemsTableContainer } from '../../hooks/useSystemsTableContainer';
import { TableSystemsTypes } from '../../types';
import { SystemsFromContractsTable } from '../SystemsFromContractsTable';
import { SystemsFullTable } from '../SystemsFullTable';

import styles from './SystemsTableContainer.module.scss';

interface Props {
  tableType: TableSystemsTypes;
  className?: string;
}

export const SystemsTableContainer: React.FC<Props> = ({
  tableType,
  className,
}) => {
  const { methods, state } = useSystemsTableContainer(tableType);

  const {
    handleDelete,
    toggleDeleteModal,
    toggleFilterRow,
    handleClickSystem,
    handleChangePage,
    handleChangePageSize,
    handleSort,
    handleShowModalDelete,
    getCreateTitle,
    handleCreate,
  } = methods;

  const {
    pageNum,
    pageSize,
    sort,
    totalElements,
    systems,
    system,
    currentSystemId,
    isAccessToCreateSystem,
    isAccessToDeleteSystem,
    showFilterRow,
    showDeleteModal,
    isFilterActive,
    searchDisabled,
    canCreateSystem,
    loadingSystem,
  } = state;

  const getTableComponent: Record<TableSystemsTypes, () => JSX.Element> = {
    [TableSystemsTypes.FROM_CONTRACTS]: () => (
      <SystemsFromContractsTable
        systems={systems}
        showFilterRow={showFilterRow}
        handleSort={handleSort}
        sort={sort}
        handleClickSystem={handleClickSystem}
        activeRowId={currentSystemId}
        isFilterActive={isFilterActive}
      />
    ),
    [TableSystemsTypes.FULL]: () => (
      <SystemsFullTable
        systems={systems}
        handleClickSystem={handleClickSystem}
        activeRowId={currentSystemId}
        handleSort={handleSort}
        sort={sort}
        showFilterRow={showFilterRow}
        isFilterActive={isFilterActive}
      />
    ),
  };

  const getContent = () => {
    return getTableComponent[tableType]();
  };

  const dropContent =
    tableType === TableSystemsTypes.FULL && isAccessToDeleteSystem ? (
      <PopoverContainer>
        <DeleteButton onClick={handleShowModalDelete} disabled={!system} />
      </PopoverContainer>
    ) : undefined;

  return (
    <TableContainer
      classNameRoot={cn(
        {
          [styles.systemsTableContainer_full]:
            tableType === TableSystemsTypes.FULL,
          [styles.systemsTableContainer_fromContracts]:
            tableType === TableSystemsTypes.FROM_CONTRACTS,
        },
        className
      )}
      headerProps={{
        title: 'Системы',
        createHandler: handleCreate,
        createDisabled: canCreateSystem && !isAccessToCreateSystem,
        createTitle: getCreateTitle(),
        searchDisabled,
        toggleSearch: toggleFilterRow,
        isSearchOpen: showFilterRow,
        dropContent,
        loading: loadingSystem,
      }}
      contentTable={getContent()}
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        pageSize,
        marginPagesDisplayed: PAGINATION_RANGE_MAP[tableType],
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
        disabled: loadingSystem,
        entityName: `SYSTEMS_${tableType}`,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.systemsTableContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить систему ${system?.title}?`,
      }}
    />
  );
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AdminsLayout } from 'core/layouts/AdminsLayout';
import { TablesLayout } from 'core/layouts/TablesLayout';
import { ContractsTableContainer } from 'features/Contracts/components/ContractsTableContainer';
import { resetContractsState } from 'features/Contracts/ducks/actions';
import { TableContractsTypes } from 'features/Contracts/types';
import { WorkGroupsTableContainer } from 'features/WorkGroups/components/WorkGroupsTableContainer';
import { resetWorkGroupsState } from 'features/WorkGroups/ducks/actions';
import { TableWorkGroupsTypes } from 'features/WorkGroups/types';

import { OrganizationsTableContainer } from './components/OrganizationsTableContainer';
import {
  fetchOrganizationsRequest,
  resetOrganizationState,
  setCurrentOrganizationId,
} from './ducks/actions';
import styles from './Organizations.module.scss';

const SubTableFirst = () => {
  return (
    <ContractsTableContainer
      tableType={TableContractsTypes.FROM_ORGANIZATIONS}
    />
  );
};

const SubTableSecond = () => {
  return (
    <WorkGroupsTableContainer
      tableType={TableWorkGroupsTypes.FROM_ORGANIZATIONS}
    />
  );
};

export const Organizations = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrganizationsRequest());
    return () => {
      dispatch(setCurrentOrganizationId(''));
      dispatch(resetContractsState());
      dispatch(resetWorkGroupsState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <AdminsLayout>
      <TablesLayout
        MainTable={[OrganizationsTableContainer]}
        SubTable={[SubTableFirst, SubTableSecond]}
        classNameRoot={styles.organizations}
      />
    </AdminsLayout>
  );
};

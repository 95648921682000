import cn from 'clsx';
import React from 'react';

import { Orientation, TabItem } from '../types';

import { RadioItem } from './components';
import styles from './Radio.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  items: TabItem[];
  orientation?: Orientation;
}

export const Radio: React.FC<Props> = ({
  className,
  items,
  value: currentValue,
  orientation = 'vertical',
  ...otherProps
}) => {
  const itemsEls = items.map(({ title, value, icon }) => {
    return (
      <RadioItem
        key={String(value)}
        value={value}
        title={title}
        checked={value === currentValue}
        icon={icon}
        {...otherProps}
      />
    );
  });

  return (
    <div
      className={cn(styles.radio, styles[`radio_${orientation}`], className)}
    >
      {itemsEls}
    </div>
  );
};

import cn from 'clsx';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Accordion } from 'components/Accordion';
import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { SelectOption } from 'components/Select/types';
import { TextArea } from 'components/TextArea';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { Typography, TypographyVariants } from 'components/Typography';
import { BottomButtonsBlock } from 'core/components/BottomButtonsBlock';
import { ApproveOrCancel } from 'core/modals/ApproveOrCancel';
import { Organization, OrganizationType } from 'core/types';
import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { TYPE_OPTIONS } from 'features/Organizations/constants';
import {
  createOrganization,
  editOrganization,
} from 'features/Organizations/ducks/actions';
import { getCurrentOrganization } from 'features/Organizations/ducks/selectors';
import { RouterHref } from 'routes/routerHref';
import { resetActiveElementFocus } from 'utils';

import { useOrganizationId } from '../../hooks/useOrganizationId';
import { CreateOrganizationData } from '../../types';

import styles from './OrganizationForm.module.scss';

interface Props {
  className?: string;
}

export const OrganizationForm: React.FC<Props> = ({ className }) => {
  const organization = useSelector(getCurrentOrganization);
  const isAccessToCreateOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateCompany)
  );

  const organizationId = useOrganizationId();
  const mainLayoutSticky = document.getElementById('mainLayoutSticky');

  const [isModal, setIsModal] = useState<boolean>(false);
  const [isIdenticalData, setIsIdenticalData] = useState<boolean>(false);

  const toggleModal = () => setIsModal(!isModal);

  const { push } = useHistory();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<CreateOrganizationData>({
    mode: 'onChange',
  });

  const currentFormValues = watch();

  const getInitialOrganizationType = () => {
    if (organization) {
      return {
        value: organization.type,
        title:
          TYPE_OPTIONS.find(
            (item: SelectOption<OrganizationType>) =>
              item.value === organization?.type
          )?.title || '',
      };
    }
    if (!organizationId) {
      return TYPE_OPTIONS[0];
    }
    return null;
  };

  useEffect(() => {
    if (organization && organizationId) {
      setValue('title', organization?.title);
      setValue('domain', organization?.domain);
      setValue('address', organization?.address);
      setValue('description', organization?.description);
      setValue(
        'emailTwoStepAuthIsEnable',
        organization?.emailTwoStepAuthIsEnable
      );
    }
    setValue('type', getInitialOrganizationType());
  }, [organization, organizationId]);

  const normalizeData = (data?: Organization) => {
    const newOrganizationData = {
      ...data,
      type: getInitialOrganizationType(),
    };
    delete newOrganizationData.id;
    delete newOrganizationData.workGroupList;
    delete newOrganizationData.contractList;
    delete newOrganizationData.dateCreate;
    return newOrganizationData;
  };

  const initialFormValues = normalizeData(organization);

  useEffect(() => {
    if (_.isEqual(currentFormValues, initialFormValues) && organizationId) {
      return setIsIdenticalData(true);
    }
    return setIsIdenticalData(false);
  }, [currentFormValues, initialFormValues, organizationId]);

  const dispatch = useDispatch();

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название организации не может быть длиннее 100 символов.',
    },
  });

  const domainInputOptions = register('domain', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Домен организации не может быть длиннее 255-ти символов.',
    },
  });

  const addressInputOptions = register('address', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Адрес организации не может быть длиннее 255-ти символов.',
    },
  });

  const descriptionTextAreaOptions = register('description', {
    required: true,
    maxLength: {
      value: 512,
      message: 'Описание организации не может быть длиннее 512-ти символов.',
    },
  });

  const formSubmitHandler = handleSubmit((data) => {
    if (!organizationId) {
      dispatch(createOrganization(data));
      return;
    }
    dispatch(editOrganization({ ...data, id: organization?.id }));
    resetActiveElementFocus();
  });

  const onApproveModal = () => {
    push(RouterHref.AdminOrganizations);
    reset();
  };

  return (
    <div className={cn(styles.organizationForm, className)}>
      <Card className={styles.organizationForm__formHeader}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.organizationForm__formHeaderTab}
        >
          Информация
        </Typography>
      </Card>
      <Card className={styles.organizationForm__formContent}>
        <form className={styles.organizationForm__form}>
          <Input
            {...titleInputOptions}
            label="Название организации"
            error={!!errors.title}
            errorMessage={errors.title?.message}
            disabled={!isAccessToCreateOrganization}
            className={cn(
              styles.organizationForm__input,
              styles.organizationForm__input_oneOfThree
            )}
          />
          <Input
            {...domainInputOptions}
            label="Домен"
            error={!!errors.domain}
            errorMessage={errors.domain?.message}
            disabled={!isAccessToCreateOrganization}
            className={cn(
              styles.organizationForm__input,
              styles.organizationForm__input_oneOfThree
            )}
          />
          <Input
            {...addressInputOptions}
            label="Адрес"
            error={!!errors.address}
            errorMessage={errors.address?.message}
            disabled={!isAccessToCreateOrganization}
            className={cn(
              styles.organizationForm__input,
              styles.organizationForm__input_oneOfThree
            )}
          />
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <Select<OrganizationType>
                  label="Тип"
                  mobileModalTitle="тип"
                  options={TYPE_OPTIONS}
                  value={field.value}
                  onChange={field.onChange}
                  className={cn(
                    styles.organizationForm__input,
                    styles.organizationForm__input_oneOfThree
                  )}
                  isTooltip={false}
                />
              );
            }}
          />
          <TextArea
            {...descriptionTextAreaOptions}
            disabled={!isAccessToCreateOrganization}
            label="Описание"
            error={!!errors.description}
            errorMessage={errors.description?.message}
          />
          <Accordion
            title="Авторизация"
            className={styles.organizationForm__accordion}
          >
            <div className={styles.organizationForm__accordionContent}>
              <Controller
                control={control}
                name="emailTwoStepAuthIsEnable"
                rules={{ deps: 'title' }}
                render={({ field }) => {
                  return (
                    <ToggleSwitch
                      label="Двухфакторная аутентификация"
                      checked={!!field.value}
                      onChange={field.onChange}
                    />
                  );
                }}
              />
            </div>
          </Accordion>
          <BottomButtonsBlock
            isOpen={
              isDirty &&
              isValid &&
              !isIdenticalData &&
              isAccessToCreateOrganization
            }
            parentNode={mainLayoutSticky}
            onCancel={toggleModal}
            onSave={formSubmitHandler}
            disabledSubmit={
              !isDirty ||
              !isValid ||
              isIdenticalData ||
              !isAccessToCreateOrganization
            }
          />
        </form>
      </Card>
      <ApproveOrCancel
        onApprove={onApproveModal}
        isModal={isModal}
        toggleModal={toggleModal}
        text={`Вы уверены, что хотите отменить ${
          organizationId ? 'редактирование' : 'создание'
        } организации?`}
      />
    </div>
  );
};

import cn from 'clsx';
import React, { useState } from 'react';

import { CloseIcon } from 'assets/icons';
import { Button } from 'components/Button';
import { IconButtonWrapper } from 'components/IconButtonWrapper';
import { Input } from 'components/Input';
import { Typography, TypographyVariants } from 'components/Typography';

import styles from './DeleteByConditionModal.module.scss';

interface Props {
  toggleModal(): void;
  isModal: boolean;
  title: string;
  description: string;
  onDelete?(): void;
  className?: string;
  deleteTitle?: string;
  condition?: string;
  label?: string;
}

export const DeleteByConditionModal: React.FC<Props> = ({
  toggleModal,
  isModal,
  title,
  description,
  onDelete,
  className,
  deleteTitle = 'Удалить',
  condition,
  label,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const deleteHandler = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event?.stopPropagation();
    if (onDelete) {
      onDelete();
    }
    toggleModal();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setIsDisabled(value !== condition);
  };

  if (!isModal) return null;

  return (
    <div
      className={styles.deleteByConditionModal}
      onMouseDown={toggleModal}
      aria-hidden="true"
    >
      <div
        className={cn(styles.deleteByConditionModal__content, className)}
        onMouseDown={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <div className={styles.deleteByConditionModal__header}>
          <h4 className={styles.deleteByConditionModal__title}>{title}</h4>
          <IconButtonWrapper
            onClick={toggleModal}
            icon={
              <CloseIcon className={styles.deleteByConditionModal__cross} />
            }
            className={styles.deleteByConditionModal__button}
          />
        </div>
        <div className={styles.deleteByConditionModal__form}>
          <Typography
            variant={TypographyVariants.b2}
            className={styles.deleteByConditionModal__description}
          >
            {description}
          </Typography>
          <Input label={label} onChange={onChange} />
          <div className={styles.deleteByConditionModal__buttons}>
            <Button
              appearance="flat"
              type="button"
              onClick={toggleModal}
              className={styles.deleteByConditionModal__cancelButton}
            >
              Отмена
            </Button>
            <Button disabled={isDisabled} onClick={deleteHandler}>
              {deleteTitle}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

import cn from 'clsx';
import React from 'react';

import { CloseIcon, DownloadIcon, UploadFileIcon } from 'assets/icons';
import { IconButton } from 'components/IconButton';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { PreviewAttachmentType } from 'features/Ticket/types';
import {
  AttachmentOthersFields,
  AttachmentsFiles,
} from 'types/models/attachment';

import { getIsImage } from '../TicketInfo/utils';
import { getDataCreateAttachment } from '../TicketInfo/utils/getDataCreateAttachment';

import styles from './PreviewAttachment.module.scss';

interface ContentPreviewAttachmentType extends PreviewAttachmentType {
  attachmentField: AttachmentOthersFields;
  file: AttachmentsFiles;
}

export const PreviewAttachment: React.FC<ContentPreviewAttachmentType> = ({
  file,
  index,
  handleAttachment,
  handleDeleteFile,
  handleDownloadFile,
  attachmentField,
  size = Size.s,
  isButtonDelete = true,
}) => {
  const { path, fileName, size: fileSize, previewPath } = file;

  const { attachmentFileId, attachmentId, created } = attachmentField;

  const isImage = getIsImage(fileName);
  const isSmallPreview = Size.s === size;
  const isDefaultPreview = !isImage || isSmallPreview;

  const info = (
    <div
      className={cn(styles[`previewAttachment__description_${size}`], {
        [styles[`previewAttachment__description_${size}Img`]]: isImage,
        [styles[`previewAttachment__description_${size}Doc`]]: !isImage,
      })}
    >
      <Typography
        variant={TypographyVariants.b3}
        className={styles[`previewAttachment__name_${size}`]}
      >
        {fileName}
      </Typography>
      <div
        className={cn(
          styles.previewAttachment__info,
          styles[`previewAttachment__info_${size}`]
        )}
      >
        <Typography
          variant={TypographyVariants.s2}
          className={styles[`previewAttachment__info_${size}FileSize`]}
        >
          {fileSize}
        </Typography>
        {isSmallPreview && (
          <Typography variant={TypographyVariants.s2}>
            {getDataCreateAttachment(created)}
          </Typography>
        )}
      </div>
    </div>
  );

  const buttons = (
    <div
      className={cn(
        styles.previewAttachment__buttons,
        styles[`previewAttachment__buttons_${size}`],
        {
          [styles[`previewAttachment__buttons_${size}Img`]]: isImage,
        }
      )}
    >
      {isButtonDelete && (
        <IconButton
          icon={<CloseIcon />}
          appearance="flat"
          type="button"
          onClick={(e) =>
            handleDeleteFile({ e, attachmentId, attachmentFileId, fileName })
          }
          className={cn(
            styles.previewAttachment__iconButton,
            styles[`previewAttachment__iconButton_${size}`],
            {
              [styles[`previewAttachment__iconButton_${size}Doc`]]: !isImage,
              [styles[`previewAttachment__iconButton_${size}Img`]]: isImage,
            }
          )}
        />
      )}
      <IconButton
        icon={<DownloadIcon />}
        appearance="flat"
        type="button"
        onClick={(e) => handleDownloadFile({ e, path, fileName })}
        className={cn(
          styles.previewAttachment__iconButton,
          styles[`previewAttachment__iconButton_${size}`],
          {
            [styles[`previewAttachment__iconButton_${size}Doc`]]: !isImage,
            [styles[`previewAttachment__iconButton_${size}Img`]]: isImage,
          }
        )}
      />
    </div>
  );

  const preview = isImage ? (
    <div className={styles[`previewAttachment__imageContainer_${size}`]}>
      <div className={styles[`previewAttachment__image_${size}`]}>
        <img
          alt="Thumbnail"
          src={previewPath}
          className={styles.previewAttachment__image_logo}
        />
        {!isSmallPreview && buttons}
      </div>
      {!isSmallPreview && info}
    </div>
  ) : (
    <UploadFileIcon className={styles.previewAttachment__icon} />
  );

  return (
    <div
      className={cn(
        styles.previewAttachment,
        styles[`previewAttachment__${size}`],
        {
          [styles.previewAttachment_isPointer]: isImage,
          [styles[`previewAttachment__${size}Doc`]]: !isImage,
          [styles[`previewAttachment__${size}Img`]]: isImage,
        }
      )}
      onClick={() => handleAttachment?.(index)}
      aria-hidden="true"
    >
      {preview}
      {isDefaultPreview && info}
      {isDefaultPreview && buttons}
    </div>
  );
};

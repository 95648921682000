import { createSelector } from 'reselect';

import { getReverseDateFromCustomField } from 'components/Table/components/SettingFields';
import { CustomFieldType } from 'components/types';
import { Action, CustomFieldToRequest } from 'core/types';
import { ActionList } from 'features/Auth/types';
import { TicketHistory } from 'features/Ticket/types';
import { RootState } from 'store/rootReducer';

export const getLoading = (state: RootState) => state.ticket?.loading;
export const getTicketHistoryLoading = (state: RootState) =>
  state.ticket?.historyLoading;
export const getTicket = (state: RootState) => state.ticket?.ticket;
export const getTicketSystem = (state: RootState) => state.ticket?.ticketSystem;
export const getTicketWorkGroups = (state: RootState) =>
  state.ticket.ticket?.workGroups;
export const getSystemId = (state: RootState) => state.ticket.ticket?.systemId;
export const getHistory = (state: RootState): TicketHistory[] | undefined =>
  state.ticket?.history;
export const getIsError = (state: RootState) => state.ticket.isError;
export const getIsSuccess = (state: RootState) => state.ticket.isSuccess;
export const getTicketActionsList = (state: RootState) =>
  state.ticket.ticketActionsList;
export const getIsSystemIntegratedWithJira = (state: RootState) =>
  state.ticket.isSystemIntegratedWithJira;
export const getJiraUrl = (state: RootState) => state.ticket.jiraUrl;
export const getSpecialistTicketCustomFields = (state: RootState) =>
  state.ticket.specialistCustomFields || [];
export const getClientTicketCustomFields = (state: RootState) =>
  state.ticket.clientCustomFields || [];
export const getNextStatuses = (state: RootState) => state.ticket.nextStatuses;
export const getTicketDeleted = (state: RootState) =>
  state.ticket.ticket?.isDeleted;
export const getClientInfoOrgTypeFromTicket = (state: RootState) =>
  state.ticket.ticket?.clientInfo?.organizationType;
export const getClientInfo = (state: RootState) =>
  state.ticket.ticket?.clientInfo;

export const getIsAccessedTicketAction = (action: ActionList) =>
  createSelector<RootState, Action[] | undefined, boolean>(
    [getTicketActionsList],
    (ticketActions): boolean => {
      return !!ticketActions?.find(
        (ticketAction) => ticketAction.title === action
      );
    }
  );

export const getClientCustomFields = createSelector<
  RootState,
  CustomFieldToRequest[],
  CustomFieldToRequest[]
>([getClientTicketCustomFields], (customFields) => {
  return customFields.map((customField) => {
    if (
      customField.customFieldType === CustomFieldType.FIELD_DATE &&
      customField.date
    ) {
      getReverseDateFromCustomField(customField.date);
    }
    return customField;
  });
});

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { createSelectValue } from 'core/utils';
import {
  AccessArticleType,
  Article,
  ArticleCreateFormData,
} from 'features/KnowledgeBase/types';

interface Props {
  article?: Article;
  isEditMode?: boolean;
}

export const useCreateArticleForm = ({ article, isEditMode }: Props) => {
  const defaultValues = {
    organization: null,
    category: null,
    parentCategory: null,
    accessType: AccessArticleType.COMMON,
    text: '',
    title: '',
  };

  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<ArticleCreateFormData>({
    mode: 'onChange',
    defaultValues,
  });

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название статьи не может быть длиннее 100 символов.',
    },
  });

  useEffect(() => {
    if (article && isEditMode) {
      const category = createSelectValue(
        article.categoryTitle,
        article.categoryId
      );
      const parentCategory = createSelectValue(
        article.parentCategoryTitle,
        article.parentCategoryId
      );
      setValue('title', article.title);
      setValue(
        'organization',
        createSelectValue(article.organizationTitle, article.organizationId)
      );
      setValue('parentCategory', parentCategory || category);
      setValue('category', parentCategory ? category : null);
      setValue('accessType', article.accessType || AccessArticleType.COMMON);
    }
  }, [article]);

  return {
    methods: {
      watch,
      reset,
      setValue,
      handleSubmit,
    },
    state: {
      control,
      text: article?.text,
      id: article?.id,
      titleInputOptions,
      isEditMode,
      isValid,
      isDirty,
    },
  };
};

import { CreateTagData, Tag, TagsFilter } from 'features/Ticket/types';
import { ResponseWithMeta } from 'store/types';
import { UpdateContentType } from 'types/models/meta';

export enum Tags {
  FETCH_TAGS_REQUEST = 'TICKET/TAGS/FETCH_TAGS_REQUEST',
  TAGS_LOADING_SHOW = 'TICKET/TAGS/TAGS_LOADING_SHOW',
  TAGS_LOADING_HIDE = 'TICKET/TAGS/TAGS_LOADING_HIDE',
  FETCH_TAGS_SUCCESS_UPDATE = 'TICKET/TAGS/FETCH_TAGS_SUCCESS_UPDATE',
  FETCH_TAGS_SUCCESS_JOIN = 'TICKET/TAGS/FETCH_TAGS_SUCCESS_JOIN',
  CREATE_TAG_REQUEST = 'TICKET/TAGS/CREATE_TAG_REQUEST',
  DELETE_TAG_BY_ID_REQUEST = 'TICKET/TAGS/DELETE_TAG_BY_ID_REQUEST',
  RESET_TAGS_STATE = 'TICKET/TAGS/RESET_TAGS_STATE',
  SET_FILTER_TAGS = 'TICKET/TAGS/SET_FILTER_TAGS',
  SET_CURRENT_PAGE_TAGS = 'TICKET/TAGS/SET_CURRENT_PAGE_TAGS',
  GET_TAGS_BY_NAME_REQUEST = 'TICKET/TAGS/GET_TAGS_BY_NAME_REQUEST',
  GET_TAGS_BY_NAME_SUCCESS = 'TICKET/TAGS/GET_TAGS_BY_NAME_SUCCESS',
}

export interface FetchTagsRequestAction {
  type: Tags.FETCH_TAGS_REQUEST;
  payload: UpdateContentType;
}

export interface TagsLoadingShowAction {
  type: Tags.TAGS_LOADING_SHOW;
}

export interface TagsLoadingHideAction {
  type: Tags.TAGS_LOADING_HIDE;
}

export interface FetchTagsSuccessUpdateAction {
  type: Tags.FETCH_TAGS_SUCCESS_UPDATE;
  payload: ResponseWithMeta<Tag[]>;
}

export interface FetchTagsSuccessJoinAction {
  type: Tags.FETCH_TAGS_SUCCESS_JOIN;
  payload: ResponseWithMeta<Tag[]>;
}

export interface CreateTagRequestAction {
  type: Tags.CREATE_TAG_REQUEST;
  payload: CreateTagData;
}

export interface DeleteTagRequestAction {
  type: Tags.DELETE_TAG_BY_ID_REQUEST;
  payload: string;
}

export interface ResetTagsStateAction {
  type: Tags.RESET_TAGS_STATE;
}

export interface SetFilterTagsAction {
  type: Tags.SET_FILTER_TAGS;
  payload: TagsFilter;
}

export interface SetCurrentPageTagsAction {
  type: Tags.SET_CURRENT_PAGE_TAGS;
  payload: number;
}

export interface GetTagsByNameRequestAction {
  type: Tags.GET_TAGS_BY_NAME_REQUEST;
  payload: string;
}

export interface GetTagsByNameSuccessAction {
  type: Tags.GET_TAGS_BY_NAME_SUCCESS;
  payload: Tag[];
}

export type TagsActionsTypes =
  | FetchTagsSuccessUpdateAction
  | TagsLoadingHideAction
  | TagsLoadingShowAction
  | ResetTagsStateAction
  | FetchTagsSuccessJoinAction
  | SetFilterTagsAction
  | SetCurrentPageTagsAction
  | GetTagsByNameSuccessAction;

import { CreateTagData, Tag, TagsFilter } from 'features/Ticket/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const request = {
  fetchTags: (pageNum = 0, pageSize?: number, filter: TagsFilter = {}) =>
    ApiClient.post<ResponseWithMeta<Tag[]>>({
      baseURL: getEnv('REACT_APP_TICKET_URL'),
      url: endpoints.getTags(),
      params: { pageNum, pageSize },
      data: {
        ...filter,
      },
    }),
  createTag: (data: CreateTagData) =>
    ApiClient.post<Tag>({
      baseURL: getEnv('REACT_APP_TICKET_URL'),
      url: endpoints.createTag(),
      data,
    }),
  fetchTagByName: (name: string) =>
    ApiClient.get<Tag[]>({
      baseURL: getEnv('REACT_APP_TICKET_URL'),
      url: endpoints.getTagByName(name),
      data: {},
    }),
};

import React from 'react';

import { MainLayout } from 'core/layouts/MainLayout';

import { StorageHeader } from '../../components/StorageHeader';
import { Storage as CurrentStorageContent } from '../../Storage';

const Storage = () => {
  return (
    <MainLayout customHeader={<StorageHeader />}>
      <CurrentStorageContent />
    </MainLayout>
  );
};

export default Storage;

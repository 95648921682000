import cn from 'clsx';
import React from 'react';

import styles from './UserAssistanceContentWrapper.module.scss';

interface Props {
  className?: string;
}

export const UserAssistanceContentWrapper: React.FC<Props> = ({
  children,
  className,
}) => {
  return (
    <div className={cn(styles.userAssistanceContentWrapper, className)}>
      {children}
    </div>
  );
};

import cn from 'clsx';
import React, { useEffect } from 'react';

import { CloseIcon } from 'assets/icons';
import { Button } from 'components/Button';
import { IconButtonWrapper } from 'components/IconButtonWrapper';
import { ButtonType } from 'components/types';

import styles from './Info.module.scss';

export interface Props {
  toggleModal(): void;
  isModal: boolean;
  title?: string;
  className?: string;
  classNameTitle?: string;
  cancelTitle?: string;
  buttonType?: ButtonType;
  children?: JSX.Element;
}

export const Info: React.FC<Props> = ({
  children,
  toggleModal,
  isModal,
  title,
  className,
  classNameTitle,
  cancelTitle = 'Закрыть',
  buttonType = 'flat',
}) => {
  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = 'visible';
  }, [isModal]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  if (!isModal) {
    return null;
  }

  return (
    <div className={styles.info} aria-hidden="true" onMouseDown={toggleModal}>
      <div
        className={cn(styles.info__content, className)}
        onMouseDown={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        {title && (
          <div className={styles.info__header}>
            <h4 className={cn(styles.info__title, classNameTitle)}>{title}</h4>
            <IconButtonWrapper
              onClick={toggleModal}
              icon={<CloseIcon className={styles.info__cross} />}
              className={cn(styles.info__button, styles.info__button_right)}
            />
          </div>
        )}
        <form className={styles.info__formList}>{children}</form>
        <div className={cn(styles.info__buttonsBlock)}>
          <Button
            appearance={buttonType}
            onClick={toggleModal}
            type="button"
            className={styles.info__cancelButton}
          >
            {cancelTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

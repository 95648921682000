import { createSelector } from 'reselect';

import { SelectOption } from 'components/Select/types';
import { Organization, OrganizationType } from 'core/types';
import { RootState } from 'store/rootReducer';

export const getArticlesMain = (state: RootState) =>
  state.articlesMain.articles || [];
export const getFilterArticlesMain = (state: RootState) =>
  state.articlesMain.articlesFilter || {};
export const getLoadingArticlesMain = (state: RootState) =>
  state.articlesMain.loadingArticles || false;
export const getPaginationArticlesMain = (state: RootState) =>
  state.articlesMain.articlesPagination || {};
export const getDefaultArticlesMain = (state: RootState) =>
  state.articlesMain.defaultArticles || [];
export const getOrganizationsArticlesMain = (state: RootState) =>
  state.articlesMain.organizations || [];

export const getCategoriesArticlesMain = (state: RootState) =>
  state.articlesMain.categories;
export const getLoadingCategoriesArticlesMain = (state: RootState) =>
  state.articlesMain.categoriesLoading || false;
export const getSelectedOrganizationArticlesMain = (state: RootState) =>
  state.articlesMain.organizationSelected;
export const getOrganizationsArticlesMainSelectList = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizationsArticlesMain], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization: Organization): SelectOption => ({
        title: organization.title,
        value: organization.id || '',
        meta: `${
          organization.type === OrganizationType.CUSTOMER
            ? 'Клиентская'
            : 'Админская'
        }`,
      })
    );
  }
  return [];
});

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentResponsibilityId } from 'features/Responsibilities/ducks/selectors';
import { checkObjectIdentity } from 'utils';

import {
  fetchAccessesByRespIdRequest,
  setCurrentAccessesPage,
  setSizePage,
  setSortAccesses,
} from '../ducks/actions';
import {
  getAccesses,
  getAccessesFilter,
  getPropsAccesses,
} from '../ducks/selectors';

export const useAccessesTableContainer = () => {
  const dispatch = useDispatch();

  const accesses = useSelector(getAccesses);
  const { sortAccesses, totalElements, pageSize, pageNum, loadingAccesses } =
    useSelector(getPropsAccesses);
  const filterValues = useSelector(getAccessesFilter);
  const currentResponsibilityId = useSelector(getCurrentResponsibilityId);

  const [modalCreateOpen, setModalOpen] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(false);

  const isFilterActive = !checkObjectIdentity(filterValues, {});

  const searchDisabled = !accesses?.length || (showFilterRow && isFilterActive);

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const handleSort = (value: string) => {
    dispatch(setSortAccesses(value));
    dispatch(fetchAccessesByRespIdRequest());
  };

  const handleAddAccess = () => {
    setModalOpen((prevState) => !prevState);
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentAccessesPage(page));
    dispatch(fetchAccessesByRespIdRequest());
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePage(arg));
  };

  useEffect(() => {
    if (showFilterRow) {
      setShowFilterRow(false);
    }
  }, [currentResponsibilityId]);

  return {
    methods: {
      toggleFilterRow,
      handleSort,
      handleAddAccess,
      handleChangePage,
      handleChangePageSize,
    },
    state: {
      accesses,
      sortAccesses,
      totalElements,
      pageSize,
      pageNum,
      modalCreateOpen,
      showFilterRow,
      searchDisabled,
      isFilterActive,
      currentResponsibilityId,
      loadingAccesses,
    },
  };
};

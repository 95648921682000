import cn from 'clsx';
import React, { forwardRef } from 'react';

import styles from './TextArea.module.scss';

export interface Props
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  textAreaClassName?: string;
  classNameContainer?: string;
  labelClassName?: string;
  error?: boolean;
  errorMessage?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const {
    label,
    className,
    textAreaClassName,
    labelClassName,
    error,
    errorMessage,
    placeholder = '',
    classNameContainer,
    ...otherProps
  } = props;

  const errorWarning = errorMessage && (
    <span className={styles.textArea__errorMessage}>{errorMessage}</span>
  );

  return (
    <div className={cn(styles.textArea, className)}>
      <span className={cn(styles.textArea__container, classNameContainer)}>
        <textarea
          ref={ref}
          autoComplete="on"
          placeholder={placeholder}
          className={cn(styles.textArea__item, textAreaClassName, {
            [styles.textArea__item_error]: error,
          })}
          {...otherProps}
        />
        <label className={cn(styles.textArea__label, labelClassName)}>
          {label}
        </label>
      </span>
      {errorWarning}
    </div>
  );
});

import { KpiData } from '../types';

import { getCountTickets } from './getCountTickets';
import { getTicketsPercents } from './getTicketsPercents';

interface Props {
  totalCount: () => string | number;
  expiredCount: () => string | number;
  expiredInWork: () => string | number;
  expiredNew: () => string | number;
}

export const getActiveTicketsData = (kpiData?: KpiData) => {
  const totalCount = kpiData?.info.activeTickets.totalCount;
  const expiredCount = kpiData?.info.activeTickets.expiredCount;
  const expiredInWork = kpiData?.info.activeTickets.expiredInWork;
  const expiredNew = kpiData?.info.activeTickets.expiredNew;

  return {
    totalCount: () => getCountTickets(totalCount),
    expiredCount: () => getCountTickets(expiredCount),
    expiredInWork: () => getCountTickets(expiredInWork),
    expiredNew: () => getCountTickets(expiredNew),
  };
};

export const getTicketPercentsData = (props: Props) => {
  const { expiredNew, expiredInWork, expiredCount } = props;

  return {
    expiredInWork: () => getTicketsPercents(expiredCount(), expiredInWork()),
    expiredNew: () => getTicketsPercents(expiredCount(), expiredNew()),
  };
};

import React from 'react';

import { ProfileTelegram } from '../../components/ProfileTelegram';
import { ProfileLayout } from '../../layouts/ProfileLayout';

const ProfileTelegramPage = () => (
  <ProfileLayout>
    <ProfileTelegram />
  </ProfileLayout>
);

export default ProfileTelegramPage;

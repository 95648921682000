import { Role } from 'core/types';
import { ResponseWithMeta } from 'store/types';

import { CreateRoleData, RolesFilter } from '../types';

export enum Roles {
  FETCH_ROLES_SUCCESS = 'ROLES/FETCH_ROLES_SUCCESS',
  FETCH_ROLES_REQUEST = 'ROLES/FETCH_ROLES_REQUEST',
  ROLES_LOADING_SHOW = 'ROLES/ROLES_LOADING_SHOW',
  ROLES_LOADING_HIDE = 'ROLES/ROLES_LOADING_HIDE',
  ROLES_ADD_LOADING_SHOW = 'ROLES/ROLES_ADD_LOADING_SHOW',
  ROLES_ADD_LOADING_HIDE = 'ROLES/ROLES_ADD_LOADING_HIDE',
  SET_CURRENT_ROLES_PAGE = 'ROLES/SET_CURRENT_ROLES_PAGE',
  SET_SORT_ROLES = 'ROLES/SET_SORT_ROLES',
  SET_SIZE_PAGE_ROLES = 'ROLES/SET_SIZE_PAGE_ROLES',
  SET_CURRENT_ROLES_ADD_PAGE = 'ROLES/SET_CURRENT_ROLES_ADD_PAGE',
  SET_SIZE_PAGE_ROLES_ADD = 'ROLES/SET_SIZE_PAGE_ROLES_ADD',
  SET_CURRENT_ROLE_ID = 'ROLES/SET_CURRENT_ROLE_ID',
  RESET_ROLES_STATE = 'ROLES/RESET_ROLES_STATE',
  RESET_ROLES_ADD_STATE = 'ROLES/RESET_ROLES_ADD_STATE',
  FETCH_ROLES_BY_USERID_REQUEST = 'ROLES/FETCH_ROLES_BY_USERID_REQUEST',
  CREATE_ROLE = 'ROLES/CREATE_ROLE',
  FETCH_ROLES_IN_GROUP_BY_USERID_REQUEST = 'ROLES/FETCH_ROLES_IN_GROUP_BY_USERID_REQUEST',
  FETCH_ROLES_BY_GROUP_ID = 'ROLES/FETCH_ROLES_BY_GROUP_ID',
  FETCH_ROLES_ADD_SUCCESS = 'ROLES/FETCH_ROLES_ADD_SUCCESS',
  SET_ROLES_ADD_FILTER = 'ROLES/SET_ROLES_ADD_FILTER',
  SET_ROLES_FILTER = 'ROLES/SET_ROLES_FILTER',
  UPDATE_ROLE = 'ROLES/UPDATE_ROLE',
  FETCH_ROLE_BY_ID_REQUEST = 'ROLES/FETCH_ROLE_BY_ID_REQUEST',
  FETCH_ROLE_BY_ID_SUCCESS = 'ROLES/FETCH_ROLE_BY_ID_SUCCESS',
  FETCH_ROLES_ADD_IN_SYSTEM_BY_ORG_ID_REQUEST = 'ROLES/FETCH_ROLES_ADD_IN_SYSTEM_BY_ORG_ID_REQUEST',
  FETCH_ROLES_ADD_IN_GROUP_BY_ORG_ID_REQUEST = 'ROLES/FETCH_ROLES_ADD_IN_GROUP_BY_ORG_ID_REQUEST',
  CHECK_IS_EXIST_REQUEST = 'ROLES/CHECK_IS_EXIST_REQUEST',
  CHECK_IS_EXIST_SUCCESS = 'ROLES/CHECK_IS_EXIST_SUCCESS',
  DELETE_ROLE_BY_ID_REQUEST = 'ROLES/DELETE_ROLE_BY_ID_REQUEST',
  RESET_CURRENT_ROLE = 'ROLES/RESET_CURRENT_ROLE',
}

export interface ShowRolesLoadingAction {
  type: Roles.ROLES_LOADING_SHOW;
}

export interface HideRolesLoadingAction {
  type: Roles.ROLES_LOADING_HIDE;
}

export interface ShowRolesAddLoadingAction {
  type: Roles.ROLES_ADD_LOADING_SHOW;
}

export interface HideRolesAddLoadingAction {
  type: Roles.ROLES_ADD_LOADING_HIDE;
}

export interface FetchRolesRequestAction {
  type: Roles.FETCH_ROLES_REQUEST;
}

export interface FetchRolesSuccessAction {
  type: Roles.FETCH_ROLES_SUCCESS;
  payload: ResponseWithMeta<Role[]>;
}

export interface SetCurrentRolesPageAction {
  type: Roles.SET_CURRENT_ROLES_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: Roles.SET_SIZE_PAGE_ROLES;
  payload: number;
}

export interface SetCurrentRolesAddPageAction {
  type: Roles.SET_CURRENT_ROLES_ADD_PAGE;
  payload: number;
}

export interface SetSizePageRolesAddAction {
  type: Roles.SET_SIZE_PAGE_ROLES_ADD;
  payload: number;
}

export interface SetSortRolesAction {
  type: Roles.SET_SORT_ROLES;
  payload: string;
}

export interface ResetRolesStateAction {
  type: Roles.RESET_ROLES_STATE;
}

export interface SetCurrentRoleIdAction {
  type: Roles.SET_CURRENT_ROLE_ID;
  payload?: string;
}

export interface FetchRolesByUserIdRequestAction {
  type: Roles.FETCH_ROLES_BY_USERID_REQUEST;
}

export interface CreateRoleAction {
  type: Roles.CREATE_ROLE;
  payload: CreateRoleData;
}

export interface FetchRoleInGroupByUserIdAction {
  type: Roles.FETCH_ROLES_IN_GROUP_BY_USERID_REQUEST;
}
export interface FetchRolesByGroupIdAction {
  type: Roles.FETCH_ROLES_BY_GROUP_ID;
}

export interface SetRolesAddFilterAction {
  type: Roles.SET_ROLES_ADD_FILTER;
  payload: RolesFilter;
}

export interface SetRolesFilterAction {
  type: Roles.SET_ROLES_FILTER;
  payload: RolesFilter;
}

export interface FetchRolesAddSuccessAction {
  type: Roles.FETCH_ROLES_ADD_SUCCESS;
  payload: ResponseWithMeta<Role[]>;
}

export interface UpdateRoleAction {
  type: Roles.UPDATE_ROLE;
  payload: Role;
}

export interface FetchRoleByIdRequestAction {
  type: Roles.FETCH_ROLE_BY_ID_REQUEST;
  payload: string;
}

export interface FetchRoleByIdSuccessAction {
  type: Roles.FETCH_ROLE_BY_ID_SUCCESS;
  payload?: Role;
}
export interface ResetRolesAddStateAction {
  type: Roles.RESET_ROLES_ADD_STATE;
}

export interface FetchRolesInSystemByOrgIdRequestAction {
  type: Roles.FETCH_ROLES_ADD_IN_SYSTEM_BY_ORG_ID_REQUEST;
}

export interface FetchRolesInGroupByOrgIdRequestAction {
  type: Roles.FETCH_ROLES_ADD_IN_GROUP_BY_ORG_ID_REQUEST;
}

export interface CheckIsExistRequestAction {
  type: Roles.CHECK_IS_EXIST_REQUEST;
  payload: string;
}

export interface CheckIsExistSuccessAction {
  type: Roles.CHECK_IS_EXIST_SUCCESS;
  payload: boolean;
}

export interface DeleteRoleRequestAction {
  type: Roles.DELETE_ROLE_BY_ID_REQUEST;
  payload: string;
}

export interface ResetCurrentRoleAction {
  type: Roles.RESET_CURRENT_ROLE;
}

export type RolesActionsTypes =
  | ShowRolesLoadingAction
  | HideRolesLoadingAction
  | FetchRolesSuccessAction
  | SetCurrentRolesPageAction
  | SetSizePageAction
  | SetSortRolesAction
  | ResetRolesStateAction
  | SetCurrentRoleIdAction
  | FetchRolesByUserIdRequestAction
  | SetRolesAddFilterAction
  | FetchRolesAddSuccessAction
  | FetchRoleByIdSuccessAction
  | SetRolesFilterAction
  | ResetRolesAddStateAction
  | CheckIsExistSuccessAction
  | SetCurrentRolesAddPageAction
  | SetSizePageRolesAddAction
  | ResetCurrentRoleAction;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TablesLayout } from 'core/layouts/TablesLayout';
import { ContractsTableContainer } from 'features/Contracts/components/ContractsTableContainer';
import { resetContractsState } from 'features/Contracts/ducks/actions';
import { TableContractsTypes } from 'features/Contracts/types';
import { resetOrganizationState } from 'features/Organizations/ducks/actions';
import { SLATableShort } from 'features/SLA/components/SLATableShort';

import { SystemsTableContainer } from './components/SystemsTableContainer';
import {
  fetchEnvironmentsRequest,
  fetchSystemsRequest,
  resetSystemsState,
} from './ducks/actions';
import { getSLASystems } from './ducks/selectors';
import styles from './Systems.module.scss';
import { TableSystemsTypes } from './types';

const SubContractsTable = () => {
  return (
    <ContractsTableContainer tableType={TableContractsTypes.FROM_SYSTEMS} />
  );
};

const MainTable = () => {
  return <SystemsTableContainer tableType={TableSystemsTypes.FULL} />;
};

const SLA = () => {
  const sla = useSelector(getSLASystems);
  return <SLATableShort slaSettings={sla?.settings} />;
};

export const Systems = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSystemsRequest());
    dispatch(fetchEnvironmentsRequest());
    return () => {
      dispatch(resetContractsState());
      dispatch(resetSystemsState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubContractsTable, SLA]}
      classNameRoot={styles.systems}
    />
  );
};

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { convertFromDateFormat } from 'components/DatePicker';
import { getValueFromValueType } from 'components/Select';

import { resetDesktopState, setDesktopFilters } from '../ducks/Desktop';
import {
  fetchEmployeesStatisticRequest,
  fetchSlaStatisticRequest,
  fetchTicketsStatisticRequest,
  resetEmployeesState,
} from '../ducks/Employees';
import { EmployeesFormData } from '../types';

export const useEmployeesForm = () => {
  const dispatch = useDispatch();

  const { control, watch, resetField } = useForm<EmployeesFormData>({
    mode: 'onChange',
  });

  const { date, workGroupId } = watch();

  useEffect(() => {
    if (workGroupId) {
      dispatch(
        setDesktopFilters({
          workgroupId: getValueFromValueType(workGroupId),
          to: convertFromDateFormat(date),
          from: convertFromDateFormat(date),
          onlyActualTickets: false,
        })
      );
      dispatch(fetchEmployeesStatisticRequest());
      dispatch(fetchSlaStatisticRequest());
      dispatch(fetchTicketsStatisticRequest());
    }
  }, [workGroupId, date]);

  useEffect(() => {
    return () => {
      dispatch(resetEmployeesState());
      dispatch(resetDesktopState());

      resetField('workGroupId');
      resetField('date');
    };
  }, []);

  return {
    methods: { employeesControl: control },
  };
};

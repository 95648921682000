import React from 'react';

import { getChangeButtonProps } from 'components/Table';
import { TableContainer } from 'components/Table/components/TableContainer';
import { entityNames } from 'core/constants';
import { AddGroups } from 'features/WorkGroups/components/AddGroups';
import { WorkGroupsFilter } from 'features/WorkGroups/components/WorkGroupsFilter';
import { WorkGroupsShortTable } from 'features/WorkGroups/components/WorkGroupsShortTable';
import { PAGINATION_RANGE_MAP } from 'features/WorkGroups/constants';
import { TableWorkGroupsTypes } from 'features/WorkGroups/types';

import { useWorkGroupsTableContainer } from '../../hooks/useWorkGroupsTableContainer';

import styles from './WorkGroupsTableContainer.module.scss';

interface Props {
  customHook?: () => ReturnType<typeof useWorkGroupsTableContainer>;
}

export const WorkGroupsTableContainer: React.FC<Props> = ({ customHook }) => {
  const hook = customHook || useWorkGroupsTableContainer;
  const { methods, state } = hook();
  const {
    toggleFilterRow,
    handleSort,
    prepareHandler,
    handleChangePage,
    handleChangePageSize,
    toggleModal,
    handleFilter,
  } = methods;
  const {
    searchDisabled,
    prepareCreateTitle,
    prepareDisabled,
    pageNum,
    pageSize,
    sort,
    workGroups,
    showFilterRow,
    isFilterActive,
    isModal,
    totalElements,
    loading,
  } = state;

  const filter = (
    <WorkGroupsFilter
      tableType={TableWorkGroupsTypes.FROM_ORGANIZATIONS}
      onFilter={handleFilter}
    />
  );

  const getTableComponent = () => (
    <WorkGroupsShortTable
      showFilterRow={showFilterRow}
      workGroups={workGroups}
      handleSort={handleSort}
      sort={sort}
      isFilterActive={isFilterActive}
      tableType={TableWorkGroupsTypes.ADD_GROUPS}
      customLoading={loading}
      customFilter={filter}
    />
  );

  const addGroupsModal = isModal && (
    <AddGroups isModal={isModal} toggleModal={toggleModal} />
  );

  return (
    <TableContainer
      classNameRoot={styles.workGroupsTableContainer}
      headerProps={{
        title: 'Группы',
        createHandler: prepareHandler(),
        createDisabled: prepareDisabled(),
        createTitle: prepareCreateTitle(),
        searchDisabled,
        toggleSearch: toggleFilterRow,
        icon: getChangeButtonProps(totalElements || 0).icon,
        isSearchOpen: showFilterRow,
        loading,
      }}
      contentTable={
        <>
          {getTableComponent()}
          {addGroupsModal}
        </>
      }
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        elementsCount: totalElements,
        pageSize,
        marginPagesDisplayed:
          PAGINATION_RANGE_MAP[TableWorkGroupsTypes.ADD_GROUPS],
        handleChangePage,
        handleChangePageSize,
        entityName: entityNames.GROUPS_ADD_GROUPS,
      }}
    />
  );
};

import cn from 'clsx';
import React from 'react';

import { Size } from 'components/types';

import styles from './MenuItem.module.scss';

export interface MenuItemProps {
  size?: Size;
  selected?: boolean;
  className?: string;
  classNameSelected?: string;
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
  onKeyDown?(event: React.KeyboardEvent<HTMLLIElement>): void;
  isMenuOpen?: boolean;
  disabled?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  size = Size.m,
  selected,
  onClick,
  onKeyDown,
  className,
  classNameSelected,
  children,
  isMenuOpen,
  disabled,
}): React.ReactElement => (
  <li
    className={cn(
      styles.menuItem,
      styles[`menu-item_${size}`],
      {
        [styles.menuItem_selected]: selected,
        [`${classNameSelected}`]: selected,
        [styles.menuItem_disabled]: disabled,
      },
      className
    )}
    onClick={!disabled ? onClick : undefined}
    onKeyDown={onKeyDown}
    role="menuitem"
    tabIndex={isMenuOpen && !selected ? 0 : -1}
  >
    {children}
  </li>
);

import cn from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProcedureType } from 'core/types';
import { Languages } from 'i18n/types';

import styles from './ConditionBlock.module.scss';
import { getTicketTypeTextMap } from './constants';

export interface Props {
  text?: string | React.ReactNode;
  ticketType?: ProcedureType;
  isSolution?: boolean;
  className?: string;
}

export const ConditionBlock: React.FC<Props> = ({
  text,
  ticketType,
  isSolution,
  className,
}) => {
  const { i18n } = useTranslation();

  const title = ticketType
    ? getTicketTypeTextMap(i18n.language as Languages)[ticketType]
    : text;

  return (
    <div
      className={cn(
        styles.conditionBlock,
        {
          [styles.conditionBlock_solution]: isSolution,
        },
        className
      )}
    >
      {title}
    </div>
  );
};

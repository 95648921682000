import _, { debounce } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { TableBodyCell } from 'components/Table/components/TableBodyCell';
import { TableFilter } from 'components/Table/components/TableFilter';
import { Size } from 'components/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { KBArticleTableFilter } from 'features/KnowledgeBase/types';

import {
  fetchArticlesTableRequest,
  fetchOrganizationsRequestArticleTable,
  setArticlesTableFilter,
  setCurrentArticlesTablePage,
} from '../../ducks/actions';
import { getSubCategoriesSelectList } from '../../ducks/selectors';
import { useCategoryId } from '../../hooks/useCategoryId';

import styles from './KBTableArticlesFilter.module.scss';

export const KBTableArticlesFilter = React.memo(() => {
  const dispatch = useDispatch();

  const categoryId = useCategoryId();

  const subCategories = useSelector(getSubCategoriesSelectList);

  const { register, handleSubmit, control, reset, watch } =
    useForm<KBArticleTableFilter>({
      mode: 'onChange',
      defaultValues: {
        category: null,
        organization: null,
      },
    });

  const filterValues = watch();
  const disableReset = _.isEqual(
    _.omitBy(filterValues, (value, key) =>
      _.isNil(value) || (_.isEmpty(value) && typeof value !== 'boolean')
        ? key
        : false
    ),
    {}
  );

  const onFilter = (data: KBArticleTableFilter) => {
    if (categoryId) {
      dispatch(setCurrentArticlesTablePage(0));
      dispatch(setArticlesTableFilter(data));
      dispatch(fetchArticlesTableRequest(categoryId));
    }
  };

  const resetFilter = () => {
    onFilter({});
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    onFilter(data);
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });
  const userNameToInput = register('username', {
    onChange: formSubmitHandlerDebounced,
  });

  const updatedToInputOptions = register('updated', {
    onChange: formSubmitHandlerDebounced,
  });

  useEffect(() => {
    dispatch(fetchOrganizationsRequestArticleTable({}));
  }, []);

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell
        className={styles.KBTableArticlesFilter__cell}
        key={item.key}
      >
        {item}
      </TableBodyCell>
    ));

  const filterElements = () => [
    <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
    <Controller
      control={control}
      name="category"
      key="category"
      render={({ field }) => {
        return (
          <Select<string>
            size={Size.xs}
            mobileModalTitle="тип"
            options={subCategories}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
          />
        );
      }}
    />,
    <Input
      size={Size.xs}
      type="username"
      key="username"
      {...userNameToInput}
    />,
    <Input size={Size.xs} type="datetime-local" {...updatedToInputOptions} />,
    <Controller
      control={control}
      name="organization"
      key="organization"
      render={({ field }) => {
        return (
          <Select<string>
            size={Size.xs}
            options={[]}
            disabled
            value={field.value}
          />
        );
      }}
    />,
  ];

  const filter = <>{tableBodyWrapper(filterElements())}</>;

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});

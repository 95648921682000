import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TablesLayout } from 'core/layouts/TablesLayout';
import { resetFilterOrganizationIdState } from 'features/Organizations/ducks/actions';
import { ResponsibilitiesTableContainer } from 'features/Responsibilities/components/ResponsibilitiesTableContainer';
import { resetResponsibilitiesState } from 'features/Responsibilities/ducks/actions';
import { TableResponsibilitiesTypes } from 'features/Responsibilities/types';

import { RolesTableContainer } from './components/RolesTableContainer';
import { fetchRolesRequest, resetRolesState } from './ducks/actions';
import styles from './Roles.module.scss';
import { TableRolesTypes } from './types';

const SubTableFirst = () => {
  return (
    <ResponsibilitiesTableContainer
      tableType={TableResponsibilitiesTypes.FROM_ROLES}
    />
  );
};

const MainTable = () => {
  return <RolesTableContainer tableType={TableRolesTypes.FULL} />;
};

export const Roles = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRolesRequest());
    return () => {
      dispatch(resetRolesState());
      dispatch(resetResponsibilitiesState());
      dispatch(resetFilterOrganizationIdState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubTableFirst]}
      classNameRoot={styles.roles}
    />
  );
};

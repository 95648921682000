import React from 'react';

import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { TableContainer } from 'components/Table/components/TableContainer';
import { DeleteButton } from 'core/components/DeleteButton';
import { entityNames } from 'core/constants';
import { useCustomFieldsTableContainer } from 'features/CustomFields/hooks/useCustomFieldsTableContainer';

import { PAGINATION_RANGE_MAP } from '../../constants';
import { CustomFieldsCreate } from '../CustomFieldsCreate';
import { CustomFieldsFullTable } from '../CustomFieldsFullTable';

import styles from './CustomFieldsTableContainer.module.scss';

export const CustomFieldsTableContainer = () => {
  const { methods, state } = useCustomFieldsTableContainer();

  const {
    handleDelete,
    toggleDeleteModal,
    toggleIsOpenChangeModals,
    toggleFilterRow,
    handleEditClick,
    handleChangePage,
    handleChangePageSize,
    handleSort,
    handleCreateClick,
    handleShowModalDelete,
    getCreateTitle,
    onRowClick,
  } = methods;

  const {
    pageNum,
    pageSize,
    sortCustomFields,
    totalElements,
    customFields,
    isAccessToDeleteCustomField,
    canCreateCustomField,
    changeModals,
    showFilterRow,
    searchDisabled,
    showDeleteModal,
    activeCustomField,
    customFieldId,
    loadingCustomFields,
  } = state;

  const createCustomFieldModal = changeModals.isOpenModal &&
    canCreateCustomField && (
      <CustomFieldsCreate
        isModal={changeModals.isOpenModal}
        toggleModal={toggleIsOpenChangeModals}
        isEditMode={changeModals.isEditMode}
      />
    );

  const dropContent = isAccessToDeleteCustomField ? (
    <PopoverContainer>
      <DeleteButton
        onClick={handleShowModalDelete}
        disabled={!activeCustomField}
      />
    </PopoverContainer>
  ) : undefined;

  return (
    <TableContainer
      classNameRoot={styles.customFieldsTableContainer}
      headerProps={{
        title: 'Кастомные поля',
        createHandler: handleCreateClick,
        createDisabled: false,
        createTitle: getCreateTitle(),
        searchDisabled,
        toggleSearch: toggleFilterRow,
        isSearchOpen: showFilterRow,
        dropContent,
        loading: loadingCustomFields,
      }}
      contentTable={
        <>
          <CustomFieldsFullTable
            customFields={customFields}
            handleSort={handleSort}
            sort={sortCustomFields}
            handleClickCustomField={onRowClick}
            activeRowId={customFieldId}
            showFilterRow={showFilterRow}
            isFilterActive={false}
            handleClickCustomFieldEdit={handleEditClick}
          />
          {createCustomFieldModal}
        </>
      }
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        pageSize,
        marginPagesDisplayed: PAGINATION_RANGE_MAP,
        elementsCount: totalElements,
        handleChangePage,
        handleChangePageSize,
        disabled: loadingCustomFields,
        entityName: entityNames.CUSTOM_FIELDS,
      }}
      approveOrCancelProps={{
        onApprove: handleDelete,
        isModal: showDeleteModal,
        toggleModal: toggleDeleteModal,
        approveTitle: 'Удалить',
        approveBtnProps: {
          appearance: 'outline',
          className: styles.customFieldsTableContainer__iconDelete,
        },
        cancelTitle: 'Отмена',
        text: `Вы уверены, что хотите удалить кастомное поле ${activeCustomField?.title}?`,
      }}
    />
  );
};

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { CloseIcon } from 'assets/icons';
import { AttachFiles } from 'components-new/AttachFiles';
import { Button } from 'components/Button';
import { Drawer } from 'components/Drawer';
import { IconButtonWrapper } from 'components/IconButtonWrapper';
import { getOptionValue, Select } from 'components/Select';
import { TextArea } from 'components/TextArea';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { MAX_FILE_SIZE_TEXT } from 'core/constants';
import { setAlert } from 'core/ducks/actions';
import { createErrorAlert } from 'core/layouts/AlertsLayout';
import {
  getUserFirstName,
  getUserId,
  getUserLastName,
} from 'features/Auth/ducks/selectors';
import {
  NOTE_DEFAULT_VALUES,
  VISIBILITY_OPTIONS,
} from 'features/Ticket/constants';
import { getTicket } from 'features/Ticket/ducks/ticket/selectors';
import { CreateNote, VisibilityType } from 'features/Ticket/types';

import { createNoteRequest } from '../../ducks/notes/actions';
import { prepareAttachments } from '../../utils';

import styles from './AddNoteModal.module.scss';

interface Props {
  isModal: boolean;
  onHideModal: () => void;
  onCreateNote: () => void;
}

export const AddNoteModal: React.FC<Props> = ({
  isModal,
  onHideModal,
  onCreateNote,
}) => {
  const dispatch = useDispatch();

  const ticket = useSelector(getTicket);
  const userId = useSelector(getUserId);
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);

  const [files, setFiles] = useState<FormData | null>(null);
  const [myFiles, setMyFiles] = useState<File[]>([]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<CreateNote>({
    mode: 'onChange',
    defaultValues: NOTE_DEFAULT_VALUES,
  });

  const inputText = watch('text');

  const hideNoteModal = () => {
    onHideModal();
    setFiles(null);
    setMyFiles([]);
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { text, visibility } = data;

    const preparedData = {
      text,
      ticketId: ticket?.id,
      userId,
      userFirstName,
      userLastName,
      created: new Date(),
      visibility: getOptionValue(visibility),
    };

    const attachmentsData = prepareAttachments(files, preparedData);

    dispatch(createNoteRequest({ note: preparedData, attachmentsData, files }));
    onCreateNote();
    hideNoteModal();
  });

  const setFileHandler = (acceptedFiles?: File[]) => {
    if (acceptedFiles) {
      setMyFiles(acceptedFiles);
    }

    const formData = new FormData();
    acceptedFiles?.forEach((file) => {
      formData.append('file', file, encodeURI(file.name));
    });
    setFiles(formData);
  };

  const onErrorMaxFileSize = () => {
    dispatch(setAlert(createErrorAlert(MAX_FILE_SIZE_TEXT)));
  };

  const textInputOptions = register('text', {
    maxLength: {
      value: 500,
      message: 'Заметка не может быть длиннее 500 символов.',
    },
  });

  const fileUpload = (
    <AttachFiles
      onChange={setFileHandler}
      value={myFiles}
      onError={onErrorMaxFileSize}
      multiple
    />
  );

  const addButtons = (
    <div className={styles.addNoteModal__buttons}>
      <Button
        size={Size.xs}
        onClick={formSubmitHandler}
        disabled={!isValid || (!myFiles.length && !inputText)}
      >
        Добавить
      </Button>
      <Button size={Size.xs} onClick={hideNoteModal} appearance="flat">
        Отмена
      </Button>
    </div>
  );

  const headerModal = (
    <div className={styles.addNoteModal__header}>
      <Typography variant={TypographyVariants.h4}>
        Добавить новую заметку
      </Typography>
      <IconButtonWrapper
        onClick={hideNoteModal}
        icon={<CloseIcon className={styles.addNoteModal__close} />}
      />
    </div>
  );

  return (
    <Drawer isOpen={isModal} onClose={hideNoteModal}>
      <div className={styles.addNoteModal}>
        {headerModal}
        <div className={styles.addNoteModal__body}>
          <Controller
            control={control}
            name="visibility"
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return (
                // TODO  Select Underline
                <Select<VisibilityType>
                  label="Видимость"
                  mobileModalTitle="видимость"
                  value={field.value}
                  options={VISIBILITY_OPTIONS}
                  onChange={field.onChange}
                  isTooltip={false}
                />
              );
            }}
          />
          <TextArea
            label="Текст заметки"
            error={!!errors.text}
            errorMessage={errors.text?.message}
            {...textInputOptions}
            textAreaClassName={styles.addNoteModal__textarea}
            className={styles.addNoteModal__textareaWrapper}
            classNameContainer={styles.addNoteModal__textareaContainer}
          />
          <Typography
            className={styles.addNoteModal__title}
            variant={TypographyVariants.h4}
          >
            Вложение
          </Typography>

          {fileUpload}
        </div>
        {addButtons}
      </div>
    </Drawer>
  );
};

import { Reducer } from 'redux';

import { Organization } from 'core/types';
import { Article, CategoryArticle } from 'features/KnowledgeBase/types';
import { ReducerMap } from 'store/types';

import {
  ArticleCreate,
  ArticleCreateActionsTypes,
  FetchCategoriesSuccessArticleCreateAction,
  FetchCurrentArticleSuccessArticleCreateAction,
  FetchInitialOrganizationSuccessArticleCreateAction,
  FetchOrganizationsSuccessArticleCreateAction,
  FetchSubCategoriesSuccessArticleCreateAction,
  SetOrganizationsFilterArticleCreateAction,
} from './types';

export interface ArticleCreateReducerState {
  organizations?: Organization[];
  organizationsFilter?: string;
  categories?: CategoryArticle[];
  subCategories?: CategoryArticle[];
  currentArticle?: Article;
  currentArticleLoading?: boolean;
  initialOrganization?: Organization;
}

const initialState: ArticleCreateReducerState = {
  organizations: [],
  categories: [],
  subCategories: [],
  currentArticleLoading: false,
};

const articleCreateReducerMap: ReducerMap<
  ArticleCreateReducerState,
  ArticleCreateActionsTypes
> = {
  [ArticleCreate.SET_ORGANIZATIONS_FILTER_ARTICLE_CREATE]: (state, action) => {
    const { payload } = action as SetOrganizationsFilterArticleCreateAction;
    return {
      ...state,
      organizationsFilter: payload,
    };
  },
  [ArticleCreate.FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_CREATE]: (
    state,
    action
  ) => {
    const { payload } = action as FetchOrganizationsSuccessArticleCreateAction;
    return {
      ...state,
      organizations: payload,
    };
  },
  [ArticleCreate.FETCH_INITIAL_ORGANIZATION_SUCCESS_ARTICLE_CREATE]: (
    state,
    action
  ) => {
    const { payload } =
      action as FetchInitialOrganizationSuccessArticleCreateAction;
    return {
      ...state,
      initialOrganization: payload,
    };
  },
  [ArticleCreate.FETCH_CATEGORIES_SUCCESS_ARTICLE_CREATE]: (state, action) => {
    const { payload } = action as FetchCategoriesSuccessArticleCreateAction;
    return {
      ...state,
      categories: payload,
    };
  },
  [ArticleCreate.FETCH_SUB_CATEGORIES_SUCCESS_ARTICLE_CREATE]: (
    state,
    action
  ) => {
    const { payload } = action as FetchSubCategoriesSuccessArticleCreateAction;
    return {
      ...state,
      subCategories: payload,
    };
  },
  [ArticleCreate.FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_CREATE]: (
    state,
    action
  ) => {
    const { payload } = action as FetchCurrentArticleSuccessArticleCreateAction;
    return {
      ...state,
      currentArticle: payload,
    };
  },
  [ArticleCreate.CURRENT_ARTICLE_LOADING_SHOW_ARTICLE_CREATE]: (state) => {
    return {
      ...state,
      currentArticleLoading: true,
    };
  },
  [ArticleCreate.CURRENT_ARTICLE_LOADING_HIDE_ARTICLE_CREATE]: (state) => {
    return {
      ...state,
      currentArticleLoading: false,
    };
  },
  [ArticleCreate.RESET_ARTICLE_CREATE_STATE]: () => {
    return {
      ...initialState,
    };
  },
};

export const articleCreate: Reducer<
  ArticleCreateReducerState,
  ArticleCreateActionsTypes
> = (state = initialState, action) => {
  const reducer = articleCreateReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};

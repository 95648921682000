import {
  CustomField,
  Environment,
  EnvironmentForSystem,
  TicketType,
} from 'core/types';
import { Ticket } from 'features/Ticket/types';
import { ResponseWithMeta } from 'store/types';
import { UpdateContentType } from 'types/models/meta';

import { TicketsFilter } from '../types';

import {
  CreateTicket,
  CreateTicketAction,
  CreateTicketPayload,
  FetchEnvironmentsRequestAction,
  FetchEnvironmentsSuccessAction,
  FetchRequiredCustomFieldsRequestAction,
  FetchRequiredCustomFieldsSuccessAction,
  FetchTicketsRequestAction,
  FetchTicketsSuccessJoinAction,
  FetchTicketsSuccessUpdateAction,
  FetchTicketTypesRequestAction,
  FetchTicketTypesSuccessAction,
  HideCreateLoadingAction,
  HideEnvironmentsLoadingAction,
  ResetCreateTicketStateAction,
  ResetTicketsStateAction,
  SetCurrentPageTicketsAction,
  SetFilterTicketsAction,
  ShowCreateLoadingAction,
  ShowEnvironmentsLoadingAction,
  TicketsLoadingHideAction,
  TicketsLoadingShowAction,
} from './types';

export const createTicket = (data: CreateTicketPayload): CreateTicketAction => {
  return {
    type: CreateTicket.CREATE_TICKET,
    payload: data,
  };
};

export const showEnvironmentsLoading = (): ShowEnvironmentsLoadingAction => {
  return {
    type: CreateTicket.ENVIRONMENTS_LOADING_SHOW,
  };
};

export const hideEnvironmentsLoading = (): HideEnvironmentsLoadingAction => {
  return {
    type: CreateTicket.ENVIRONMENTS_LOADING_HIDE,
  };
};

export const fetchEnvironmentsRequest = (
  data: EnvironmentForSystem
): FetchEnvironmentsRequestAction => {
  return {
    type: CreateTicket.FETCH_ENVIRONMENTS_REQUEST,
    payload: data,
  };
};

export const fetchEnvironmentsSuccess = (
  data: ResponseWithMeta<Environment[]>
): FetchEnvironmentsSuccessAction => {
  return {
    type: CreateTicket.FETCH_ENVIRONMENTS_SUCCESS,
    payload: data,
  };
};

export const showCreateLoading = (): ShowCreateLoadingAction => {
  return {
    type: CreateTicket.CREATE_LOADING_SHOW,
  };
};

export const hideCreateLoading = (): HideCreateLoadingAction => {
  return {
    type: CreateTicket.CREATE_LOADING_HIDE,
  };
};

export const fetchTicketTypesRequest = (
  systemId: string
): FetchTicketTypesRequestAction => {
  return {
    type: CreateTicket.FETCH_CREATE_TICKET_TYPES_REQUEST,
    payload: systemId,
  };
};

export const fetchTicketTypesSuccess = (
  payload: TicketType[]
): FetchTicketTypesSuccessAction => {
  return {
    type: CreateTicket.FETCH_CREATE_TICKET_TYPES_SUCCESS,
    payload,
  };
};

export const resetCreateTicketState = (): ResetCreateTicketStateAction => {
  return {
    type: CreateTicket.RESET_CREATE_TICKET_STATE,
  };
};

export const fetchTicketsRequest = (
  updateType: UpdateContentType
): FetchTicketsRequestAction => {
  return {
    type: CreateTicket.FETCH_TICKETS_REQUEST,
    payload: updateType,
  };
};

export const loadingTicketsShow = (): TicketsLoadingShowAction => {
  return {
    type: CreateTicket.TICKETS_LOADING_SHOW,
  };
};

export const loadingTicketsHide = (): TicketsLoadingHideAction => {
  return {
    type: CreateTicket.TICKETS_LOADING_HIDE,
  };
};

export const fetchTicketsSuccessUpdate = (
  data: ResponseWithMeta<Ticket[]>
): FetchTicketsSuccessUpdateAction => {
  return {
    type: CreateTicket.FETCH_TICKETS_SUCCESS_UPDATE,
    payload: data,
  };
};

export const fetchTicketsSuccessJoin = (
  data: ResponseWithMeta<Ticket[]>
): FetchTicketsSuccessJoinAction => {
  return {
    type: CreateTicket.FETCH_TICKETS_SUCCESS_JOIN,
    payload: data,
  };
};

export const resetTicketsState = (): ResetTicketsStateAction => {
  return {
    type: CreateTicket.RESET_TICKETS_STATE,
  };
};

export const setFilterTickets = (
  filter: TicketsFilter
): SetFilterTicketsAction => {
  return {
    type: CreateTicket.SET_FILTER_TICKETS,
    payload: filter,
  };
};

export const setCurrentPageTickets = (
  page: number
): SetCurrentPageTicketsAction => {
  return {
    type: CreateTicket.SET_CURRENT_PAGE_TICKETS,
    payload: page,
  };
};

export const fetchRequiredCustomFieldsRequest = (
  systemId: string
): FetchRequiredCustomFieldsRequestAction => {
  return {
    type: CreateTicket.FETCH_REQUIRED_CUSTOM_FIELDS_REQUEST,
    payload: systemId,
  };
};

export const fetchRequiredCustomFieldsSuccess = (
  payload: CustomField[]
): FetchRequiredCustomFieldsSuccessAction => {
  return {
    type: CreateTicket.FETCH_REQUIRED_CUSTOM_FIELDS_SUCCESS,
    payload,
  };
};

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import {
  getIsAccessedAction,
  getIsClientPartner,
  getIsGroupSpecialist,
  getIsOrgTypePartner,
  getManagerWorkGroupsList,
} from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { RouterHref } from 'routes/routerHref';

import {
  getOrganizationsOptions,
  getSystemsOptions,
} from '../ducks/Desktop/selectors';

export const useHeaderForm = () => {
  const { pathname } = useLocation();

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isGroupSpecialist = useSelector(getIsGroupSpecialist);
  const isPartner = useSelector(getIsOrgTypePartner);
  const isClientPartner = useSelector(getIsClientPartner);
  const isAccessToTakeTicketToWork = useSelector(
    getIsAccessedAction(ActionList.TakeTicketToWork)
  );
  const isAccessToCreateReportByOrg = useSelector(
    getIsAccessedAction(ActionList.CreateReportByOrganization)
  );
  const isAccessToCreateTicket = useSelector(
    getIsAccessedAction(ActionList.CreateTicket)
  );

  const managerWorkGroupsList = useSelector(getManagerWorkGroupsList);
  const organizationsOptions = useSelector(getOrganizationsOptions);
  const systemsOptions = useSelector(getSystemsOptions);

  const isDesktopKpiPage = pathname === RouterHref.DesktopKpi;
  const isDesktopPage = pathname === RouterHref.DesktopCurrentState;
  const isDesktopHistoryPage = pathname === RouterHref.DesktopHistory;

  const isMobileAll = isMobile || isMobileSmall;

  const hiddenDesktopKpi = isPartner
    ? isClientPartner && isAccessToCreateTicket && !isAccessToTakeTicketToWork
    : !isAccessToTakeTicketToWork;

  return {
    state: {
      isDesktopKpiPage,
      isDesktopPage,
      isDesktopHistoryPage,
      isMobileAll,
      isGroupSpecialist,
      isAccessToTakeTicketToWork,
      isAccessToCreateReportByOrg,
      managerWorkGroupsList,
      organizationsOptions,
      systemsOptions,
      hiddenDesktopKpi,
    },
  };
};

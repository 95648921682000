import { ActionFilter, ActionWithStatus } from 'features/Actions/types';
import { ResponseWithMeta } from 'store/types';

import {
  Actions,
  FetchActionsByRespIdAction,
  FetchActionsCreateRequestAction,
  FetchActionsRequestAction,
  FetchActionsSuccessAction,
  HideActionsLoadingAction,
  ResetActionsCreateAction,
  ResetActionsStateAction,
  SetActionsCreateAction,
  SetActionsFilterAction,
  SetActionsForEditRespAction,
  SetCurrentActionsPageAction,
  SetSizePageAction,
  SetSortActionsAction,
  ShowActionsLoadingAction,
} from './types';

export const showActionsLoading = (): ShowActionsLoadingAction => {
  return {
    type: Actions.ACTIONS_LOADING_SHOW,
  };
};

export const hideActionsLoading = (): HideActionsLoadingAction => {
  return {
    type: Actions.ACTIONS_LOADING_HIDE,
  };
};

export const fetchActionsRequest = (): FetchActionsRequestAction => {
  return {
    type: Actions.FETCH_ACTIONS_REQUEST,
  };
};

export const fetchActionsSuccess = (
  data: ResponseWithMeta<ActionWithStatus[]>
): FetchActionsSuccessAction => {
  return {
    type: Actions.FETCH_ACTIONS_SUCCESS,
    payload: data,
  };
};

export const setActionsCreate = (
  data: ActionWithStatus[]
): SetActionsCreateAction => {
  return {
    type: Actions.SET_ACTIONS_CREATE_STATE,
    payload: data,
  };
};

export const fetchActionsCreateRequest = (
  organizationId: string
): FetchActionsCreateRequestAction => {
  return {
    type: Actions.FETCH_ACTIONS_CREATE_REQUEST,
    payload: organizationId,
  };
};

export const fetchActionsByRespIdRequest = (): FetchActionsByRespIdAction => {
  return {
    type: Actions.FETCH_ACTION_BY_RESP_ID_REQUEST,
  };
};

export const resetActionsState = (): ResetActionsStateAction => {
  return {
    type: Actions.RESET_ACTIONS_STATE,
  };
};

export const setActionsForEditResp = (): SetActionsForEditRespAction => {
  return {
    type: Actions.SET_ACTIONS_FOR_EDIT_RESPONSIBILITY,
  };
};

export const setActionFilter = (data: ActionFilter): SetActionsFilterAction => {
  return {
    type: Actions.SET_ACTIONS_FILTER,
    payload: data,
  };
};

export const setCurrentActionPage = (
  page: number
): SetCurrentActionsPageAction => {
  return {
    type: Actions.SET_CURRENT_ACTIONS_PAGE,
    payload: page,
  };
};

export const setSizePage = (size: number): SetSizePageAction => {
  return {
    type: Actions.SET_SIZE_PAGE_ACTIONS,
    payload: size,
  };
};

export const setSortAction = (sort: string): SetSortActionsAction => {
  return {
    type: Actions.SET_SORT_ACTIONS,
    payload: sort,
  };
};

export const resetActionsCreate = (): ResetActionsCreateAction => {
  return {
    type: Actions.RESET_ACTIONS_CREATE,
  };
};

import { getValueFromValueType } from 'components/Select';
import { ValueType } from 'components/Select/types';
import { Attributes } from 'core/types';

interface Props {
  attributesFields: Attributes;
  attribute: ValueType<string>;
}

export const getAttributesValueOptions = ({
  attributesFields,
  attribute,
}: Props) => {
  const optionValue = getValueFromValueType(attribute) || '';
  const fields = attributesFields[optionValue as keyof typeof attributesFields];

  return (
    fields?.map((field) => ({ title: field.value, value: field.key })) || []
  );
};

import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { TicketStatus } from 'core/types';

import { Props as StatusDetailProps } from '../components/StatusDetail';
import {
  DEFAULT_STATUS_FORM_VALUES,
  WAITING_INFO_SETTINGS,
} from '../constants';
import { StatusForm } from '../types';
import { getNormalizedData } from '../utils';

interface Props extends Omit<StatusDetailProps, 'onDeleteNodeHandler'> {}

export const useStatusForm = ({
  onClose,
  onCreateNodeHandler,
  onCreateEdgeHandler,
  nodes,
  selectedNode,
  onResetSelectedNode,
  onEditNodeHandler,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset,
    setValue,
  } = useForm<StatusForm>({
    mode: 'onChange',
    defaultValues: DEFAULT_STATUS_FORM_VALUES,
  });
  const [isModal, setIsModal] = useState(false);

  const toggleModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const { label, isComment } = watch();

  const currentFormValues = watch();

  const labelInputOptions = register('label', {
    required: true,
    maxLength: {
      value: 24,
      message: 'Название статуса не может быть длиннее 24 символов.',
    },
  });

  const colorRadioOptions = register('color', { required: true });

  const nodeStatusWork = useMemo(
    () => nodes.find((node) => node.data.label === 'В работе'),
    [nodes]
  );

  const onCloseModalHandler = useCallback(() => {
    if (selectedNode) {
      onResetSelectedNode();
    }
    onClose();
  }, [selectedNode, onResetSelectedNode, onClose]);

  const formSubmitHandler = handleSubmit((data) => {
    if (!selectedNode) {
      onCreateNodeHandler({
        id: uuidv4(),
        data: {
          ...data,
          deletable: true,
          modified: true,
        },
        position: { x: 100, y: 100 },
      });
    } else {
      onEditNodeHandler(selectedNode?.id, {
        ...data,
      });
    }
    onCloseModalHandler();
    reset();
  });

  const isIdenticalLabel = useMemo(() => {
    if (label?.length) {
      if (!selectedNode) {
        return !!nodes.find((node) => node.data.label === label);
      }
      return !!nodes
        .filter((node) => node.id !== selectedNode.id)
        .find((node) => node.data.label === label);
    }
    return false;
  }, [label, nodes, selectedNode]);

  const isWaitingInfoLabel =
    label === 'В ожидании информации' &&
    selectedNode?.data.defaultStatus !== TicketStatus.WAITING_INFO;

  const isWaitingInfoStatus = useMemo(() => {
    return !!nodes.find((node) => node.data.label === 'В ожидании информации');
  }, [nodes]);

  const onCreateWaitingInfoStatus = useCallback(() => {
    const id = uuidv4();

    if (nodeStatusWork) {
      onCreateNodeHandler({
        id,
        data: {
          ...WAITING_INFO_SETTINGS,
          deletable: true,
          modified: false,
          hideRightHandle: true,
          hideLeftHandle: true,
        },
        position: {
          x: nodeStatusWork.position.x + 50,
          y: nodeStatusWork.position.y + 140,
        },
      });
      onCreateEdgeHandler({
        id: uuidv4(),
        target: nodeStatusWork?.id,
        source: id,
        sourceHandle: 'b',
        data: { protected: true },
      });
      onCreateEdgeHandler({
        id: uuidv4(),
        target: id,
        targetHandle: 'b',
        source: nodeStatusWork?.id,
        sourceHandle: 'b',
        data: { protected: true },
      });
    }
    onClose();
  }, [uuidv4, onCreateNodeHandler, onClose, nodeStatusWork]);

  const isEditDisabled = useMemo(
    () => selectedNode && !selectedNode.data.modified,
    [selectedNode]
  );

  const isDeleteDisabled = useMemo(
    () => !selectedNode || !selectedNode.data.deletable,
    [selectedNode]
  );

  const modalTitle = useMemo(
    () => (selectedNode ? 'Просмотр статуса' : 'Создать статус'),
    [selectedNode]
  );

  const isIdenticalData = useMemo(() => {
    return (
      _.isEqual(currentFormValues, getNormalizedData(selectedNode?.data)) &&
      !!selectedNode
    );
  }, [_, currentFormValues, selectedNode, getNormalizedData]);

  const isSubmitDisabled = useMemo(
    () =>
      !isDirty ||
      !isValid ||
      isIdenticalLabel ||
      isIdenticalData ||
      isWaitingInfoLabel,
    [isDirty, isValid, isIdenticalLabel, isIdenticalData, isWaitingInfoLabel]
  );

  useEffect(() => {
    if (selectedNode) {
      setValue('label', selectedNode.data.label);
      setValue('accessibility', selectedNode.data.accessibility);
      setValue('color', selectedNode.data.color);
      setValue('isNotifications', selectedNode.data.isNotifications);
      setValue('isSLA', selectedNode.data.isSLA);
      setValue('isComment', selectedNode.data.isComment);
      setValue('deletable', selectedNode.data.deletable);
      setValue('modified', selectedNode.data.modified);
      setValue('jumpCondition', selectedNode.data.jumpCondition);
      setValue('commentTypes', selectedNode.data.commentTypes);
      return;
    }
    reset();
  }, [selectedNode]);

  return {
    methods: {
      control,
      formSubmitHandler,
      onCreateWaitingInfoStatus,
      onCloseModalHandler,
      toggleModal,
    },
    state: {
      errors,
      labelInputOptions,
      colorRadioOptions,
      isIdenticalLabel,
      isWaitingInfoStatus,
      isEditDisabled,
      isDeleteDisabled,
      modalTitle,
      isWaitingInfoLabel,
      isSubmitDisabled,
      isComment,
      isModal,
    },
  };
};

import { Responsibility } from 'core/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import {
  ActionList,
  CreateResponsibilityToRequest,
  ResponsibilityFilterToRequest,
} from '../../types';

import { endpoints } from './endpoints';

export const request = {
  fetchResponsibilities: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ResponsibilityFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Responsibility[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getResponsibilities(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchResponsibilityByPosId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ResponsibilityFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Responsibility[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getResponsibilitiesByPosId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchResponsibilityByGroupId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ResponsibilityFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Responsibility[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getResponsibilitiesByGroupId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchResponsibilityByRoleId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: ResponsibilityFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<Responsibility[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getResponsibilitiesByRoleId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  createResponsibility: (
    data: Omit<CreateResponsibilityToRequest, 'actionList'>
  ) =>
    ApiClient.post<Responsibility>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createResponsibility(),
      params: {},
      data: { ...data },
    }),
  updateResponsibilityAction: (id: string, data: ActionList[]) =>
    ApiClient.put<Responsibility>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.updateResponsibilityActions(id),
      params: {},
      data,
    }),
  updateResponsibilityData: (data: CreateResponsibilityToRequest) =>
    ApiClient.put<Responsibility>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.updateResponsibilityData(),
      params: {},
      data,
    }),
  addAllActionsResponsibility: (id: string) =>
    ApiClient.get<ResponseWithMeta<Responsibility[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.addAllActionsResponsibility(id),
      params: {},
      data: {},
    }),
  fetchResponsibilityById: (id: string) =>
    ApiClient.get<ResponseWithMeta<Responsibility[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getResponsibilityById(id),
      params: {},
      data: {},
    }),
  deleteResponsibility: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.deleteResponsibility(id),
      params: {},
      data: {},
    }),
};

import { RenderTypes, TableColumns } from 'components/Table/types';

import { TableWorkGroupsTypes } from './types';

export const WORK_GROUPS_FIELDS_SHORT: TableColumns = [
  { title: 'Название', fieldName: 'title', sortField: 'title' },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
];

export const WORK_GROUPS_FIELDS_FULL: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
    sortField: 'title',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Организация',
    fieldName: 'organizationId',
    sortField: 'organization',
  },
  { title: 'Описание', fieldName: 'description', sortField: 'description' },
];

export const WORK_GROUPS_ADD_ALL_FULL: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
  },
  {
    title: 'Организация',
    fieldName: 'organizationId',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  { title: 'Описание', fieldName: 'description' },
];

export const INITIAL_GROUPS_FILTER = {
  title: '',
  description: '',
  organizationId: null,
};

export const PAGINATION_RANGE_MAP: Record<
  TableWorkGroupsTypes,
  number | undefined
> = {
  [TableWorkGroupsTypes.FULL]: 1,
  [TableWorkGroupsTypes.FROM_ORGANIZATIONS]: 0,
  [TableWorkGroupsTypes.ADD_GROUPS]: 0,
  [TableWorkGroupsTypes.FROM_USERS]: 0,
};

import cn from 'clsx';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input/input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Accordion } from 'components/Accordion';
import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { PhoneInput } from 'components/PhoneInput';
import { getNormalizedPhoneNumber } from 'components/PhoneInput/utils';
import { TextArea } from 'components/TextArea';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { Typography, TypographyVariants } from 'components/Typography';
import { BottomButtonsBlock } from 'core/components/BottomButtonsBlock';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { ApproveOrCancel } from 'core/modals/ApproveOrCancel';
import { User } from 'core/types';
import {
  getIsAccessedAction,
  getIsClient,
  getUserOrganization,
} from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { OrganizationSelect } from 'features/Organizations/components/OrganizationSelect';
import {
  checkIsExistEmailRequest,
  checkIsExistEmailSuccess,
  checkIsExistLoginRequest,
  checkIsExistLoginSuccess,
  checkIsExistPhoneRequest,
  createUser,
  editUser,
} from 'features/Users/ducks/actions';
import {
  getIsExistEmail,
  getIsExistLogin,
  getIsExistPhone,
  getUser,
} from 'features/Users/ducks/selectors';
import { RouterHref } from 'routes/routerHref';
import { resetActiveElementFocus } from 'utils';

import { useUserId } from '../../hooks/useUserId';
import { CreateUserData } from '../../types';

import styles from './UserForm.module.scss';

interface Props {
  className?: string;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export const UserForm: React.FC<Props> = ({ className }) => {
  const user = useSelector(getUser);
  const isAccessToCreateUser = useSelector(
    getIsAccessedAction(ActionList.CreateUser)
  );
  const isExistLogin = useSelector(getIsExistLogin);
  const isExistEmail = useSelector(getIsExistEmail);
  const isExistPhone = useSelector(getIsExistPhone);
  const isClient = useSelector(getIsClient);
  const organization = useSelector(getUserOrganization);
  const mainLayoutSticky = document.getElementById('mainLayoutSticky');

  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isMobileAll = isMobile || isMobileSmall;

  const isEditMode = !!user?.id;

  const dispatch = useDispatch();

  const userId = useUserId();

  const [isModal, setIsModal] = useState<boolean>(false);
  const [isIdenticalData, setIsIdenticalData] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const toggleModal = () => setIsModal(!isModal);

  const { push } = useHistory();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<CreateUserData>({
    mode: 'onChange',
  });

  const currentFormValues = watch();

  const isExistEmailError = isEditMode
    ? isExistEmail && user?.email !== currentFormValues.email
    : isExistEmail;

  const isExistLoginErrorMessage = isExistLogin
    ? 'Пользователь с таким логином уже существует'
    : '';
  const isExistEmailErrorMessage = isExistEmailError
    ? 'Пользователь с таким E-mail уже существует'
    : '';

  const getInitialOrgOption = () => {
    if (isEditMode && user) {
      return {
        value: user?.organization?.id || '',
        title: user?.organization?.title || '',
      };
    }
    if (!isEditMode && isClient && organization) {
      return {
        title: organization?.organizationTitle || '',
        value: organization?.organizationId || '',
      };
    }
    return null;
  };

  useEffect(() => {
    if (user && userId) {
      setValue('firstName', user?.firstName);
      setValue('lastName', user?.lastName);
      setValue('middleName', user?.middleName);
      setValue('description', user?.description);
      setValue('login', user?.login);
      setValue('email', user?.email);
      setValue('phoneNumber', user?.phoneNumber);
      setValue('requireUpdatePassword', user?.requireUpdatePassword);
      setPhoneNumber(user?.phoneNumber);
    }
    setValue('organizationId', getInitialOrgOption());
  }, [user, userId]);

  const normalizeData = (data?: User) => {
    const newUserData = {
      ...data,
      organizationId: data?.organization
        ? {
            title: data.organization.title,
            value: data.organization.id,
          }
        : null,
    };
    delete newUserData.id;
    delete newUserData.workGroupList;
    delete newUserData.organization;
    delete newUserData.positions;
    delete newUserData.roles;
    delete newUserData.dateCreate;
    delete newUserData.password;
    return newUserData;
  };

  const normalizeCurrentEditFormValues = (data: CreateUserData) => {
    const newUserData = {
      ...data,
    };
    delete newUserData.password;
    return newUserData;
  };

  const currentEditFormValues =
    normalizeCurrentEditFormValues(currentFormValues);
  const initialFormValues = normalizeData(user);

  useEffect(() => {
    return () => {
      dispatch(checkIsExistLoginSuccess(false));
      dispatch(checkIsExistEmailSuccess(false));
    };
  }, []);

  useEffect(() => {
    if (_.isEqual(currentEditFormValues, initialFormValues) && userId) {
      return setIsIdenticalData(true);
    }
    return setIsIdenticalData(false);
  }, [currentEditFormValues, initialFormValues, userId]);

  const isPhoneError = isEditMode
    ? isExistPhone &&
      initialFormValues.phoneNumber !== currentFormValues.phoneNumber
    : isExistPhone;

  const isExistPhoneErrorMessage = isPhoneError
    ? 'Пользователь с таким номером телефона уже существует'
    : '';

  const lastNameInputOptions = register('lastName', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Фамилия пользователя не может быть длиннее 100 символов.',
    },
  });

  const firstNameInputOptions = register('firstName', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Имя пользователя не может быть длиннее 100 символов.',
    },
  });

  const middleNameInputOptions = register('middleName', {
    maxLength: {
      value: 100,
      message: 'Отчество пользователя не может быть длиннее 100 символов.',
    },
  });

  const descriptionTextAreaOptions = register('description', {
    required: true,
    maxLength: {
      value: 255,
      message: 'Описание пользователя не может быть длиннее 255-ти символов.',
    },
  });

  const loginInputOptions = register('login', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Логин пользователя не может быть длиннее 100 символов.',
    },
    pattern: {
      value: /^[a-zA-Z0-9]+$/,
      message: 'Логин пользователя должен включать только латинские буквы',
    },
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const login = e.target.value;
      if (login) {
        dispatch(checkIsExistLoginRequest(login));
      }
    },
  });

  const passwordInputOptions = register('password', {
    required: !isEditMode,
    minLength: {
      value: 8,
      message: 'Пароль пользователя должен содержать как минимум 8 символов.',
    },
    maxLength: {
      value: 128,
      message: 'Пароль пользователя не может быть длиннее 128 символов.',
    },
    pattern: {
      value:
        /(?=.*[0-9])(?=.*[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|])(?=.*[a-z])(?=.*[A-Z])^[0-9a-zA-Z-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|]*$/g,
      message:
        'Пароль пользователя должен содержать хотя бы один спецсимвол, одну латинскую букву в верхнем и нижнем регистрах, одно число и не содержать пробелов',
    },
  });

  const emailInputOptions = register('email', {
    required: true,
    pattern: {
      value: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/,
      message: 'Введите корректный E-mail пользователя',
    },
    maxLength: {
      value: 100,
      message: 'Email пользователя не может быть длиннее 100 символов.',
    },
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const email = e.target.value;
      if (email) {
        dispatch(checkIsExistEmailRequest(email));
      }
    },
  });

  const phoneNumberInputOptions = register('phoneNumber', {
    required: true,
    validate: (value) => {
      const country = parsePhoneNumber(phoneNumber || '')?.country;
      return isValidPhoneNumber(value || '', country) || 'Некорректный номер';
    },
    maxLength: {
      value: 100,
      message:
        'Номер телефона пользователя не может быть длиннее 100 символов.',
    },
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const phone = e.target.value;
      if (phone) {
        dispatch(checkIsExistPhoneRequest(phone));
      }
    },
  });

  const formSubmitHandler = handleSubmit((data) => {
    const { organizationId } = data;
    const normalizedPhoneNumber = getNormalizedPhoneNumber(phoneNumber);

    const newUserData = {
      ...user,
      ...data,
      phoneNumber: normalizedPhoneNumber,
      organizationId,
    };

    if (!userId) {
      dispatch(createUser(newUserData));
      return;
    }
    dispatch(editUser({ ...newUserData, id: userId }));
    resetActiveElementFocus();
  });

  const onApproveModal = () => {
    push(RouterHref.AdminUsers);
    reset();
  };

  return (
    <div className={cn(styles.userForm, className)}>
      <Card className={styles.userForm__formHeader}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.userForm__formHeaderTab}
        >
          Информация
        </Typography>
      </Card>
      <Card className={styles.userForm__formContent}>
        <form className={styles.userForm__form}>
          <Input
            {...lastNameInputOptions}
            label="Фамилия"
            disabled={!isAccessToCreateUser}
            error={!!errors.lastName}
            errorMessage={errors.lastName?.message}
            className={cn(
              styles.userForm__input,
              styles.userForm__input_oneOfThree
            )}
          />
          <Input
            {...firstNameInputOptions}
            label="Имя"
            disabled={!isAccessToCreateUser}
            error={!!errors.firstName}
            errorMessage={errors.firstName?.message}
            className={cn(
              styles.userForm__input,
              styles.userForm__input_oneOfThree
            )}
          />
          <Input
            {...middleNameInputOptions}
            label="Отчество"
            disabled={!isAccessToCreateUser}
            error={!!errors.middleName}
            errorMessage={errors.middleName?.message}
            className={cn(
              styles.userForm__input,
              styles.userForm__input_oneOfThree
            )}
          />
          <Controller
            control={control}
            name="organizationId"
            key="organizationId"
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return (
                <OrganizationSelect
                  onChange={field.onChange}
                  disabled={!isAccessToCreateUser || isEditMode || isClient}
                  placeholder="Организация"
                  value={field.value}
                  className={cn(
                    styles.userForm__input,
                    styles.userForm__input_oneOfThree
                  )}
                />
              );
            }}
          />
          <TextArea
            {...descriptionTextAreaOptions}
            label="Описание"
            disabled={!isAccessToCreateUser}
            error={!!errors.description}
            errorMessage={errors.description?.message}
          />
          <Accordion title="Авторизация" className={styles.userForm__accordion}>
            <div className={styles.userForm__accordionContainer}>
              <Input
                {...loginInputOptions}
                label="Логин"
                disabled={!isAccessToCreateUser || Boolean(userId)}
                error={!!errors.login || isExistLogin}
                errorMessage={errors.login?.message || isExistLoginErrorMessage}
                className={cn(styles.userForm__input_oneOfThree, {
                  [styles.userForm__input]: isMobileAll,
                  [styles.userForm__input_mr]: !isMobileAll,
                })}
              />
              {!isEditMode && (
                <Input
                  {...passwordInputOptions}
                  label="Пароль"
                  disabled={!isAccessToCreateUser}
                  error={!!errors.password}
                  errorMessage={errors.password?.message}
                  isPassword
                  className={cn(styles.userForm__input_oneOfThree, {
                    [styles.userForm__input]: isMobileAll,
                    [styles.userForm__input_mr]: !isMobileAll,
                  })}
                />
              )}
              <Controller
                control={control}
                name="requireUpdatePassword"
                rules={{ deps: 'lastName' }}
                render={({ field }) => {
                  return (
                    <ToggleSwitch
                      label="Изменить пароль при первом входе"
                      checked={!!field.value}
                      onChange={field.onChange}
                      className={cn(
                        styles.userForm__input_oneOfThree,
                        styles.userForm__toggleSwitch
                      )}
                    />
                  );
                }}
              />
            </div>
          </Accordion>
          <Accordion
            title="Контактная информация"
            className={styles.userForm__accordion}
          >
            <div className={styles.userForm__accordionContainer}>
              <Input
                {...emailInputOptions}
                label="E-mail"
                disabled={!isAccessToCreateUser}
                error={!!errors.email || isExistEmailError}
                errorMessage={errors.email?.message || isExistEmailErrorMessage}
                className={cn(styles.userForm__input_oneOfThree, {
                  [styles.userForm__input]: isMobileAll,
                  [styles.userForm__input_mr]: !isMobileAll,
                })}
              />

              <Controller
                name="phoneNumber"
                key="phoneNumber"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    {...phoneNumberInputOptions}
                    type="tel"
                    id="phoneNumber"
                    isWithCountry
                    isEditMode={isEditMode}
                    value={field.value || ''}
                    disabled={!isAccessToCreateUser}
                    errorMessage={
                      errors.phoneNumber?.message || isExistPhoneErrorMessage
                    }
                    onChange={(value) => {
                      field.onChange(value);
                      setPhoneNumber(value);
                    }}
                    className={styles.userForm__input_oneOfThree}
                  />
                )}
              />
            </div>
          </Accordion>
          <BottomButtonsBlock
            isOpen={
              isDirty &&
              isValid &&
              !isIdenticalData &&
              isAccessToCreateUser &&
              !isExistLogin &&
              !isExistEmailError &&
              !isPhoneError
            }
            parentNode={mainLayoutSticky}
            onCancel={toggleModal}
            onSave={formSubmitHandler}
            disabledSubmit={
              !isDirty ||
              !isValid ||
              isIdenticalData ||
              !isAccessToCreateUser ||
              !!isExistLogin ||
              !!isExistEmailError
            }
          />
        </form>
      </Card>
      <ApproveOrCancel
        onApprove={onApproveModal}
        isModal={isModal}
        toggleModal={toggleModal}
        text={`Вы уверены, что хотите отменить ${
          userId ? 'редактирование' : 'создание'
        } пользователя?`}
      />
    </div>
  );
};

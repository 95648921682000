import { Note } from 'features/Ticket/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const request = {
  createNote: (data: Note) =>
    ApiClient.post<Note>({
      baseURL: getEnv('REACT_APP_NOTES_URL'),
      url: endpoints.getCreateNoteUrl(),
      data,
    }),
  fetchNotesByTicketId: (ticketId: string) =>
    ApiClient.get<Note[]>({
      baseURL: getEnv('REACT_APP_NOTES_URL'),
      url: endpoints.getNotesByTicketId(ticketId),
      data: {},
    }),
};

import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { SearchIcon } from 'assets/icons';
import { Input, Props as InputProps } from 'components/Input';
import { Loader } from 'components/Loader';
import { OutsideClickHandler } from 'components/OutsideClickHandler';
import { Menu } from 'components/Select/components/Menu';
import { MenuItem } from 'components/Select/components/MenuItem';
import { Size } from 'components/types';

import styles from './InputWithList.module.scss';

export interface Props<T> extends Omit<InputProps, 'ref'> {
  options?: T[];
  renderFunction: (elem: T) => JSX.Element;
  currentPage?: number;
  totalPage?: number;
  loading?: boolean;
  setNextPage: (page: number) => void;
  withDropDown?: boolean;
}

export const InputWithList = <T,>({
  options = [],
  renderFunction,
  currentPage = 0,
  totalPage = 0,
  loading,
  setNextPage,
  withDropDown = true,
  ...other
}: Props<T>) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isListOpen, setIsListOpen] = useState(false);

  const fetchNextData = () => {
    if (!loading && totalPage > currentPage) {
      setNextPage(currentPage + 1);
    }
  };

  const handleOpenList = (): void => {
    setIsListOpen(true);
  };

  const handleCloseList = (): void => {
    setIsListOpen(false);
  };

  useEffect((): void => {
    const { current: inputElement } = inputRef;
    if (isListOpen) {
      inputElement?.focus();
      return;
    }
    inputElement?.blur();
  }, [isListOpen]);

  const listForRender = options?.length ? (
    options?.map((item) => (
      <React.Fragment key={nanoid()}>{renderFunction(item)}</React.Fragment>
    ))
  ) : (
    <MenuItem className={styles.inputWithList__noResult}>
      Нет результатов
    </MenuItem>
  );

  const listWithWrapper = (
    <InfiniteScroll
      hasMore={!loading && totalPage > currentPage + 1}
      loadMore={fetchNextData}
      initialLoad={false}
      useWindow={false}
      loader={<Loader key={nanoid()} />}
      threshold={20}
    >
      {listForRender}
    </InfiniteScroll>
  );

  return (
    <OutsideClickHandler
      onClickOutside={handleCloseList}
      className={styles.inputWithList__wrapper}
    >
      <div
        onClick={handleOpenList}
        onKeyDown={undefined}
        role="button"
        tabIndex={0}
        className={styles.inputWithList}
      >
        <Input
          {...other}
          ref={inputRef}
          rightIcon={<SearchIcon />}
          label="Поиск"
          size={Size.s}
          className={styles.inputWithList__input}
        />
        {withDropDown && (
          <Menu open={isListOpen} classNameList={styles.inputWithList__list}>
            {listWithWrapper}
          </Menu>
        )}
      </div>
    </OutsideClickHandler>
  );
};

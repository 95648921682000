import React from 'react';

import { AdminsLayout } from 'core/layouts/AdminsLayout';

import { StatusesBuilder as StatusesBuilderContent } from '../../StatusesBuilder';

const StatusesBuilder = () => {
  return (
    <AdminsLayout>
      <StatusesBuilderContent />
    </AdminsLayout>
  );
};

export default StatusesBuilder;

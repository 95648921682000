import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentResponsibilityId } from 'features/Responsibilities/ducks/selectors';
import { checkObjectIdentity } from 'utils';

import {
  fetchActionsByRespIdRequest,
  setCurrentActionPage,
  setSizePage,
  setSortAction,
} from '../ducks/actions';
import {
  getActions,
  getActionsFilter,
  getPropsActions,
} from '../ducks/selectors';

export const useActionsTableContainer = () => {
  const dispatch = useDispatch();

  const actions = useSelector(getActions);
  const filterValues = useSelector(getActionsFilter);
  const responsibilityId = useSelector(getCurrentResponsibilityId);

  const [showFilterRow, setShowFilterRow] = useState(false);
  const { sortActions, totalElements, pageSize, pageNum, loadingActions } =
    useSelector(getPropsActions);

  const isFilterActive = !checkObjectIdentity(filterValues, {});

  const searchDisabled = !actions?.length || (showFilterRow && isFilterActive);

  const toggleFilterRow = () => {
    setShowFilterRow((prevState) => !prevState);
  };

  const handleSort = (value: string) => {
    dispatch(setSortAction(value));
    dispatch(fetchActionsByRespIdRequest());
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentActionPage(page));
    dispatch(fetchActionsByRespIdRequest());
  };

  const handleChangePageSize = (arg: number) => {
    dispatch(setSizePage(arg));
  };

  useEffect(() => {
    if (showFilterRow) {
      setShowFilterRow(false);
    }
  }, [responsibilityId]);

  return {
    methods: {
      handleChangePageSize,
      handleChangePage,
      handleSort,
      toggleFilterRow,
    },
    state: {
      searchDisabled,
      sortActions,
      totalElements,
      pageSize,
      pageNum,
      isFilterActive,
      actions,
      showFilterRow,
      loadingActions,
    },
  };
};

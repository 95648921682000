import { createSelector } from 'reselect';

import { SelectOption } from 'components/Select/types';
import { Organization } from 'core/types';
import { CategoryArticle } from 'features/KnowledgeBase/types';
import { RootState } from 'store/rootReducer';

export const getArticleCreateOrganizationsFilter = (state: RootState) =>
  state.articleCreate.organizationsFilter;
export const getArticleCreateOrganizations = (state: RootState) =>
  state.articleCreate.organizations;
export const getArticleCreateCategories = (state: RootState) =>
  state.articleCreate.categories;
export const getSubCategoriesArticleCreate = (state: RootState) =>
  state.articleCreate.subCategories;
export const getCurrentArticle = (state: RootState) =>
  state.articleCreate.currentArticle;
export const getCurrentArticleLoading = (state: RootState) =>
  state.articleCreate.currentArticleLoading;
export const getInitialOrganization = (state: RootState) =>
  state.articleCreate.initialOrganization;

export const getArticleOrganizationsSelectList = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getArticleCreateOrganizations], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization: Organization): SelectOption => ({
        title: organization.title,
        value: organization.id || '',
      })
    );
  }
  return [];
});

export const getArticleCategoriesSelectList = createSelector<
  RootState,
  CategoryArticle[] | undefined,
  SelectOption[]
>([getArticleCreateCategories], (categories): SelectOption[] => {
  if (categories) {
    return categories.map(
      (category: CategoryArticle): SelectOption => ({
        title: category.title,
        value: category.id || '',
      })
    );
  }
  return [];
});

export const getSubCategoriesSelectList = createSelector<
  RootState,
  CategoryArticle[] | undefined,
  SelectOption[]
>([getSubCategoriesArticleCreate], (subCategories): SelectOption[] => {
  if (subCategories) {
    return subCategories.map(
      (category: CategoryArticle): SelectOption => ({
        title: category.title,
        value: category.id || '',
      })
    );
  }
  return [];
});

import cn from 'clsx';
import React, { SVGProps } from 'react';

import { Ripple } from 'components/Ripple';

import styles from './PopoverButton.module.scss';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: SVGProps<SVGElement>;
}

export const PopoverButton: React.FC<Props> = ({
  className,
  type,
  icon,
  disabled,
  onClick,
  children,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onButtonClick}
      onMouseDown={handleClick}
      className={cn(
        styles.popoverButton,
        { [styles.popoverButton_disabled]: disabled },
        className
      )}
    >
      <div className={styles.popoverButtonIcon}>{icon}</div>
      {children}
      {!disabled && <Ripple appearance="grey" />}
    </button>
  );
};

import React from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { Select } from 'components/Select';
import { SelectOption } from 'components/Select/types';

import { KPI_PERIOD_OPTIONS } from '../../constants';
import { KpiFormData } from '../../types';

import styles from './KpiForm.module.scss';

interface Props {
  control: Control<KpiFormData>;
  workGroupsIds?: SelectOption[];
  organizationsIds?: SelectOption[];
  systemsIds?: SelectOption[];
  workGroupsOptions: SelectOption[];
  organizationsOptions: SelectOption[];
  systemsOptions: SelectOption[];
  ticketTypesOptions: SelectOption[];
  fetchKpiHandler: () => void;
  setValue: UseFormSetValue<KpiFormData>;
}

export const KpiForm: React.FC<Props> = (props) => {
  const {
    control,
    systemsOptions,
    organizationsOptions,
    organizationsIds,
    systemsIds,
    workGroupsOptions,
    workGroupsIds,
    ticketTypesOptions,
    setValue,
    fetchKpiHandler,
  } = props;

  const isDisabledOrganization = !!workGroupsIds?.length;
  const isDisabledSystem =
    !!workGroupsIds?.length && !!organizationsIds?.length;
  const isDisabledTicketType =
    !!workGroupsIds?.length &&
    !!organizationsIds?.length &&
    !!systemsIds?.length;

  return (
    <>
      <Controller
        control={control}
        name="period"
        render={({ field }) => {
          return (
            <Select<string>
              label="Период"
              mobileModalTitle="период"
              value={field.value}
              options={KPI_PERIOD_OPTIONS}
              className={styles.kpiForm__multiSelect}
              onChange={(event) => {
                field.onChange(event);
                fetchKpiHandler();
              }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="workGroupsIds"
        render={({ field }) => {
          return (
            <Select<string>
              label="Рабочая группа"
              mobileModalTitle="рабочую группу"
              value={field.value}
              className={styles.kpiForm__multiSelect}
              options={workGroupsOptions}
              onChange={(event) => {
                setValue('organizationsIds', []);
                setValue('systemsIds', []);
                setValue('ticketTypesIds', []);
                field.onChange(event);
                fetchKpiHandler();
              }}
              isMulti
            />
          );
        }}
      />
      <Controller
        control={control}
        name="organizationsIds"
        render={({ field }) => {
          return (
            <Select<string>
              label="Организация"
              mobileModalTitle="организация"
              value={field.value}
              className={styles.kpiForm__multiSelect}
              options={organizationsOptions}
              onChange={(event) => {
                setValue('systemsIds', []);
                setValue('ticketTypesIds', []);
                field.onChange(event);
                fetchKpiHandler();
              }}
              disabled={!isDisabledOrganization}
              isMulti
            />
          );
        }}
      />
      <Controller
        control={control}
        name="systemsIds"
        render={({ field }) => {
          return (
            <Select<string>
              label="Система"
              mobileModalTitle="систему"
              value={field.value}
              className={styles.kpiForm__multiSelect}
              options={systemsOptions}
              onChange={(event) => {
                setValue('ticketTypesIds', []);
                field.onChange(event);
                fetchKpiHandler();
              }}
              disabled={!isDisabledSystem}
              isMulti
            />
          );
        }}
      />
      <Controller
        control={control}
        name="ticketTypesIds"
        render={({ field }) => {
          return (
            <Select<string>
              label="Тип тикета"
              mobileModalTitle="тип тикета"
              value={field.value}
              options={ticketTypesOptions}
              className={styles.kpiForm__multiSelect}
              onChange={(event) => {
                field.onChange(event);
                fetchKpiHandler();
              }}
              disabled={!isDisabledTicketType}
              isMulti
            />
          );
        }}
      />
    </>
  );
};

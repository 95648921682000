import React from 'react';

import {
  ConditionBlock,
  Props as ConditionBlockProps,
} from 'components/ConditionBlock';
import { IconBlock, Props as IconBlockProps } from 'components/IconBlock';
import {
  NotificationLink,
  Props as NotificationLinkProps,
} from 'components/NotificationLink';
import { RenderTypes } from 'components/Table/types';

const components = {
  [RenderTypes.NOTIFICATION_LINK]: (props: NotificationLinkProps) => (
    <NotificationLink {...props} />
  ),
  [RenderTypes.CONDITION]: (props: ConditionBlockProps) => (
    <ConditionBlock {...props} />
  ),
  [RenderTypes.ICON_BLOCK]: (props: IconBlockProps) => <IconBlock {...props} />,
};

export const getTableComponent = (componentType: RenderTypes) => {
  return components[componentType];
};

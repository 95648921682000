import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { setBreadCrumbsConfig } from 'core/ducks/actions';
import { getBreadCrumbsConfig } from 'core/layouts/BreadCrumbsLayout';
import { RouterConfig } from 'routes/types';

interface Props {
  routes: RouterConfig[];
  props: RouteComponentProps<{ [p: string]: string | undefined }>;
}

export const BreadCrumbsLayout: React.FC<Props> = ({
  children,
  routes,
  props,
}) => {
  const dispatch = useDispatch();

  const breadCrumbsConfig = getBreadCrumbsConfig(routes, props);

  useEffect(() => {
    dispatch(setBreadCrumbsConfig(breadCrumbsConfig));
  }, [breadCrumbsConfig]);

  return <>{children}</>;
};

import React from 'react';

import { WarningIcon } from 'assets/icons';
import { Tooltip } from 'components/Tooltip';

import styles from './InfoTooltip.module.scss';

interface Props {
  isUpdated?: boolean;
  updatedFrom?: string | null;
}

export const InfoTooltip: React.FC<Props> = ({ isUpdated, updatedFrom }) => {
  return isUpdated && updatedFrom ? (
    <>
      <WarningIcon data-tip data-for={updatedFrom} />
      <Tooltip id={updatedFrom} place="bottom" className={styles.infoTooltip}>
        {updatedFrom}
      </Tooltip>
    </>
  ) : null;
};

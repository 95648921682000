import React from 'react';

import { MainLayout } from 'core/layouts/MainLayout';
import { Header } from 'features/Desktop/components/Header';

import { DesktopCurrentState as DesktopCurrentStateContent } from '../../views/DesktopCurrentState';

import styles from './DesktopCurrentState.module.scss';

const DesktopCurrentState = () => {
  return (
    <MainLayout customHeader={<Header />}>
      <DesktopCurrentStateContent className={styles.desktop__content} />
    </MainLayout>
  );
};

export default DesktopCurrentState;

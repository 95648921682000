import {
  CreateSettingFields,
  SettingFields,
} from 'components/Table/components/SettingFields/types';
import { PAGE_SIZE_SEARCH_SELECT } from 'constants/meta';
import { Action, TicketStatus, TicketType } from 'core/types';
import { Ticket } from 'features/Ticket/types';
import { FetchTicketsPayload } from 'features/Tickets/ducks/types';
import {
  CreateFilterRequest,
  FilterData,
  TicketsFilterForRequest,
  TicketTabType,
} from 'features/Tickets/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const request = {
  fetchTickets: ({
    pageNum = 0,
    pageSize,
    sort,
    filterValues,
    ticketTab,
  }: FetchTicketsPayload) =>
    ApiClient.post<ResponseWithMeta<Ticket[]>>({
      url: endpoints.getTickets(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase(), ticketTab },
      data: filterValues,
    }),
  fetchTicket: (id: string) =>
    ApiClient.get<Ticket>({
      url: endpoints.getTicket(id),
    }),
  setTicketToWork: (ids: string[]) =>
    ApiClient.post<Ticket[]>({
      url: endpoints.setTicketToWork(),
      data: ids,
    }),
  addSpecialist: (ticketId: string | string[], userId: string) =>
    ApiClient.post<Ticket[]>({
      url: endpoints.addSpecialist(),
      params: { userId },
      data: Array.isArray(ticketId) ? ticketId : [ticketId],
    }),
  fetchOpenTicketsCountByContractId: (contractIds?: string[]) =>
    ApiClient.post<number>({
      url: endpoints.getTicketsCount(),
      data: {
        contractIds,
        statuses: [
          TicketStatus.NEW,
          TicketStatus.WORK,
          TicketStatus.WAITING_INFO,
          TicketStatus.PENDING_CLOSURE,
        ],
      },
    }),
  fetchCurrentTicketActions: (ticketGroupsIds: string[]) =>
    ApiClient.post<Action[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCurrentTicketActions(),
      data: ticketGroupsIds,
    }),
  deleteTicket: (id: string) =>
    ApiClient.delete<Ticket>({
      url: endpoints.deleteTicket(id),
    }),
  fetchStatuses: (
    pageNum = 0,
    pageSize = PAGE_SIZE_SEARCH_SELECT,
    sort?: string,
    filter?: TicketsFilterForRequest
  ) =>
    ApiClient.post<string[]>({
      url: endpoints.getStatuses(),
      params: { pageNum, pageSize, sort },
      data: filter,
    }),
  fetchFiltersData: (ticketTab?: TicketTabType) =>
    ApiClient.get<FilterData[]>({
      url: endpoints.getFiltersData(),
      params: { ticketTab },
    }),
  createFilter: (data: CreateFilterRequest) =>
    ApiClient.post({
      url: endpoints.createFilter(),
      data,
    }),
  deleteFilter: (id: string) =>
    ApiClient.delete({
      url: endpoints.deleteFilter(id),
    }),
  setFilterTitle: (id: string, newTitle: string) =>
    ApiClient.patch({
      url: endpoints.setFilterTitle(id),
      params: { newTitle },
    }),
  fetchTicketsTableConfig: () =>
    ApiClient.get<SettingFields>({
      url: endpoints.getTicketsTableConfig(),
    }),
  fetchActualTicketsTableConfig: () =>
    ApiClient.get<SettingFields>({
      url: endpoints.getActualTicketsTableConfig(),
    }),
  createTicketsTableConfig: (data: CreateSettingFields) =>
    ApiClient.post<SettingFields>({
      url: endpoints.createTicketsTableConfig(),
      data,
    }),
  fetchTicketTypes: (
    pageNum = 0,
    pageSize = PAGE_SIZE_SEARCH_SELECT,
    queryString?: string
  ) =>
    ApiClient.get<ResponseWithMeta<TicketType[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getTicketTypes(),
      params: { pageNum, pageSize, queryString },
    }),
};

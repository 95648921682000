import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Input } from 'components/Input';
import { PhoneInput } from 'components/PhoneInput';
import { TableBodyCell } from 'components/Table/components/TableBodyCell';
import { TableFilter } from 'components/Table/components/TableFilter';
import { Size } from 'components/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { OrganizationSelect } from 'features/Organizations/components/OrganizationSelect';
import {
  fetchOrganizationsAddRequest,
  setCurrentOrganizationsAddPage,
  setOrganizationsAddFilterAction,
} from 'features/Organizations/ducks/actions';
import { checkObjectIdentity } from 'utils';

import { INITIAL_USERS_FILTER } from '../../constants';
import { TableUsersTypes, UsersFilter as UsersFilterData } from '../../types';

import styles from './UsersFilter.module.scss';

interface Props {
  tableType: TableUsersTypes;
  onFilter: (data: UsersFilterData) => void;
}

export const UsersFilter: React.FC<Props> = React.memo(
  ({ tableType, onFilter }) => {
    const { register, handleSubmit, reset, watch, control } =
      useForm<UsersFilterData>({
        mode: 'onChange',
        defaultValues: INITIAL_USERS_FILTER,
      });
    const dispatch = useDispatch();
    const filterValues = watch();

    const resetFilter = () => {
      onFilter({});
      if (tableType === TableUsersTypes.FULL) {
        dispatch(setCurrentOrganizationsAddPage(0));
        dispatch(setOrganizationsAddFilterAction({}));
        dispatch(fetchOrganizationsAddRequest({ updateType: 'update' }));
      }
      reset();
    };

    const formSubmitHandler = handleSubmit((data) => {
      const {
        login,
        firstName,
        lastName,
        middleName,
        organizationId,
        phoneNumber,
        email,
      } = data;
      const preparedData = {
        login: login || undefined,
        firstName: firstName || undefined,
        organizationId: organizationId || undefined,
        lastName: lastName || undefined,
        middleName: middleName || undefined,
        phoneNumber: phoneNumber || undefined,
        email: email || undefined,
      };
      onFilter(preparedData);
    });

    const formSubmitHandlerDebounced = useMemo(
      () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
      [onFilter]
    );

    const loginToInput = register('login', {
      onChange: formSubmitHandlerDebounced,
    });
    const firstNameToInput = register('firstName', {
      onChange: formSubmitHandlerDebounced,
    });
    const lastNameToInput = register('lastName', {
      onChange: formSubmitHandlerDebounced,
    });
    const middleNameToInput = register('middleName', {
      onChange: formSubmitHandlerDebounced,
    });
    const phoneNumberToInput = register('phoneNumber', {
      onChange: formSubmitHandlerDebounced,
    });
    const emailToInput = register('email', {
      onChange: formSubmitHandlerDebounced,
    });

    const tableBodyWrapper = (elements: JSX.Element[]) =>
      elements.map((item) => (
        <TableBodyCell className={styles.usersFilter__cell} key={item.key}>
          {item}
        </TableBodyCell>
      ));

    const phoneInput = (
      <Controller
        control={control}
        name="phoneNumber"
        key="phoneNumber"
        render={({ field }) => (
          <PhoneInput
            {...phoneNumberToInput}
            size={Size.xs}
            value={field.value || ''}
            onChange={field.onChange}
          />
        )}
      />
    );

    const filterElements = {
      [TableUsersTypes.FULL]: () => [
        <Input size={Size.xs} type="login" key="login" {...loginToInput} />,
        <Input
          size={Size.xs}
          type="lastName"
          key="lastName"
          {...lastNameToInput}
        />,
        <Input
          size={Size.xs}
          type="firstName"
          key="firstName"
          {...firstNameToInput}
        />,
        <Input
          size={Size.xs}
          type="middleName"
          key="middleName"
          {...middleNameToInput}
        />,
        <Controller
          control={control}
          name="organizationId"
          key="organizationId"
          render={({ field }) => {
            return (
              <OrganizationSelect
                size={Size.xs}
                onChange={(value) => {
                  field.onChange(value);
                  formSubmitHandler();
                }}
                value={field.value}
              />
            );
          }}
        />,
        <Input size={Size.xs} type="email" key="email" {...emailToInput} />,
        phoneInput,
      ],
      [TableUsersTypes.ADD_MODAL]: () => [
        <div key="select" />,
        <Input size={Size.xs} type="login" key="login" {...loginToInput} />,
        <Input
          size={Size.xs}
          type="lastName"
          key="lastName"
          {...lastNameToInput}
        />,
        <Input
          size={Size.xs}
          type="firstName"
          key="firstName"
          {...firstNameToInput}
        />,
        <Input
          size={Size.xs}
          type="middleName"
          key="middleName"
          {...middleNameToInput}
        />,
        <Controller
          control={control}
          name="organizationId"
          key="organizationId"
          render={({ field }) => {
            return (
              <OrganizationSelect
                size={Size.xs}
                onChange={(value) => {
                  field.onChange(value);
                  formSubmitHandler();
                }}
                value={field.value}
                disabled
              />
            );
          }}
        />,
        <Input size={Size.xs} type="email" key="email" {...emailToInput} />,
        phoneInput,
      ],
      [TableUsersTypes.FROM_GROUPS]: () => [
        <Input
          size={Size.xs}
          type="login"
          key="loginGroups"
          {...loginToInput}
        />,
        <Input
          size={Size.xs}
          type="lastName"
          key="lastName"
          {...lastNameToInput}
        />,
        <Input
          size={Size.xs}
          type="firstName"
          key="firstName"
          {...firstNameToInput}
        />,
        <Input
          size={Size.xs}
          type="middleName"
          key="middleName"
          {...middleNameToInput}
        />,
        <Input size={Size.xs} type="email" key="email" {...emailToInput} />,
        phoneInput,
      ],
    };

    const filterComponents = (
      <>
        {tableBodyWrapper(
          filterElements[tableType as keyof typeof filterElements]()
        )}
      </>
    );

    const disableReset = checkObjectIdentity(filterValues, {});

    return (
      <TableFilter
        filterComponent={filterComponents}
        onReset={resetFilter}
        disableReset={disableReset}
      />
    );
  }
);

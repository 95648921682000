import { ValueType } from 'components/Select/types';
import { Responsibility } from 'core/types';

export type ActionList = {
  actionId: string;
  actionStatus: string;
};

export type ResponsibilityFilter = Pick<
  Responsibility,
  'title' | 'description'
> & {
  organizationId?: ValueType<string>;
  idsResponsibilitiesDelete?: Array<string>;
};

export type ResponsibilityFilterToRequest = Omit<
  ResponsibilityFilter,
  'organizationId'
> & {
  organizationId?: string;
};

export type UpdateResponsibilityData = {
  actions: ActionList[];
  responsibilityData?: Omit<Responsibility, 'organization' | 'actionList'> & {
    organizationId?: ValueType<string>;
  };
};

export type CreateResponsibilityType = Omit<
  Responsibility,
  'organization' | 'id' | 'actionList'
> & {
  organizationId?: ValueType<string>;
  actionList?: ActionList[];
};

export type CreateResponsibilityToRequest = Omit<
  CreateResponsibilityType,
  'organizationId'
> & {
  organizationId?: string;
};

export enum TableResponsibilitiesTypes {
  FULL = 'FULL',
  FROM_GROUPS = 'FROM_GROUPS',
  FROM_ROLES = 'FROM_ROLES',
  ADD_MODAL = 'ADD_MODAL',
}

import cn from 'clsx';
import React, { useMemo } from 'react';

import { ErrorRoundIcon } from 'assets/icons';
import { TicketsTableContainer } from 'features/Tickets/components/TicketsTableContainer';
import { TableTicketsTypes } from 'features/Tickets/types';

import { PieChartPriority } from '../../components/PieChartPriority';
import { PieChartStatus } from '../../components/PieChartStatus';
import { TicketsDashboard } from '../../components/TicketsDashboard';
import { TicketsDropContent } from '../../components/TicketsDropContent';
import { useCurrentStateForm } from '../../hooks/useCurrentStateForm';

import styles from './DesktopCurrentState.module.scss';

interface Props {
  className?: string;
}

export const DesktopCurrentState: React.FC<Props> = ({ className }) => {
  const {
    state: { tickets, ticketsStatistic, isDownload },
    methods: { handleDownload },
  } = useCurrentStateForm();

  const tableTitle = useMemo(
    () => (
      <div className={styles.desktop__titleWrapper}>
        <span className={styles.desktop__title}>Просрочено</span>
        {Boolean(tickets?.length) && <ErrorRoundIcon />}
      </div>
    ),
    [tickets]
  );

  const downloadTickets = tickets?.length ? (
    <TicketsDropContent
      isDownload={isDownload}
      handleDownload={handleDownload}
    />
  ) : undefined;

  return (
    <div className={cn(styles.desktop, className)}>
      <div className={styles.desktop__topContainer}>
        <TicketsDashboard
          className={styles.desktop__ticketsDashboard}
          ticketsStatistic={ticketsStatistic}
        />
        <TicketsTableContainer
          tableType={TableTicketsTypes.FROM_DESKTOP}
          title={tableTitle}
          withOutTabs
          withFilter={false}
          dropContent={downloadTickets}
          className={styles.desktop__ticketsTable}
        />
      </div>
      <div className={styles.desktop__bottomContainer}>
        <PieChartStatus
          className={styles.desktop__pieChart}
          ticketsStatistic={ticketsStatistic}
        />
        <PieChartPriority
          className={styles.desktop__pieChart}
          ticketsStatistic={ticketsStatistic}
        />
      </div>
    </div>
  );
};

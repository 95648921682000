import cn from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MailIcon, PhoneIcon, UserIcon } from 'assets/icons';
import { Checkbox } from 'components/Checkbox';
import { NotificationLink } from 'components/NotificationLink';
import { AddModalContainer } from 'core/modals/AddModal/components/AddModalContainer';
import { User } from 'core/types';
import {
  fetchUsersAllowedToAddToContractDetailRequest,
  resetUsersOnlyStateContractDetail,
  setUsersFilterContractDetail,
  setUsersPageContractDetail,
  updateContractDetail,
} from 'features/Contract/ducks/actions';
import {
  getContractDetail,
  getPropsUsersContractDetail,
  getUsersContractDetail,
  getUsersFilterContractDetail,
} from 'features/Contract/ducks/selectors';
import { checkObjectIdentity } from 'utils';

import { ADD_MODAL_FROM_GROUPS_FIELDS } from '../../constants';
import { TableUsersTypes, UsersFilter as UsersFilterData } from '../../types';
import { UsersFilter } from '../UsersFilter';

import styles from './UsersAddToContract.module.scss';

const getDataTable = (
  changeCheckbox: (
    item: User
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  selected: string[],
  data?: User[]
) =>
  data?.map((user) => {
    const {
      login,
      firstName,
      lastName,
      middleName,
      id,
      email,
      phoneNumber,
      organization,
    } = user;
    return {
      checkbox: (
        <Checkbox
          name="usersCheckbox"
          onChange={changeCheckbox(user)}
          checked={selected.includes(id || '')}
        />
      ),
      id,
      login: {
        icon: (
          <UserIcon
            className={cn(
              styles.usersAddToContract__icon,
              styles.usersAddToContract__icon_blue
            )}
          />
        ),
        children: <NotificationLink to={`/admin/users/${id}`} title={login} />,
        to: `/admin/users/${id}`,
      },
      firstName,
      lastName,
      middleName,
      organizationId: organization?.title,
      email: {
        text: email,
        icon: <MailIcon className={styles.usersAddToContract__icon} />,
      },
      phoneNumber: {
        text: phoneNumber,
        icon: <PhoneIcon className={styles.usersAddToContract__icon} />,
      },
    };
  }) || [];

interface Props {
  isModal: boolean;
  toggleModal: () => void;
}

export const UsersAddToContract: React.FC<Props> = ({
  isModal,
  toggleModal,
}) => {
  const dispatch = useDispatch();

  const usersAll = useSelector(getUsersContractDetail) || [];
  const contractData = useSelector(getContractDetail);
  const filterValues = useSelector(getUsersFilterContractDetail) || {};
  const { pageNum, pageSize, totalElements, loadingUsers } = useSelector(
    getPropsUsersContractDetail
  );

  const isFilterActive = !checkObjectIdentity(filterValues, {});

  useEffect(
    () => () => {
      dispatch(resetUsersOnlyStateContractDetail());
    },
    []
  );

  const onSubmit = (data: User[]) => {
    if (contractData && !loadingUsers) {
      dispatch(
        updateContractDetail({
          ...contractData,
          userList: data,
        })
      );
    }
    toggleModal();
  };

  const handleChangePage = (page: number) => {
    dispatch(setUsersPageContractDetail(page));
    dispatch(fetchUsersAllowedToAddToContractDetailRequest());
  };

  const onChangeFilter = (filter: UsersFilterData) => {
    dispatch(
      setUsersFilterContractDetail({
        ...filter,
        idsUsersDelete: filterValues.idsUsersDelete,
      })
    );
    dispatch(setUsersPageContractDetail(0));
    dispatch(fetchUsersAllowedToAddToContractDetailRequest());
  };

  const onChangeIdsForDelete = (data: User[]) => {
    dispatch(
      setUsersFilterContractDetail({
        ...filterValues,
        idsUsersDelete: data.map((item) => item.id),
      })
    );
    dispatch(fetchUsersAllowedToAddToContractDetailRequest());
  };

  return (
    <AddModalContainer<User>
      leftData={usersAll}
      rightData={contractData?.userList || []}
      getDataTable={getDataTable}
      classNameLeftTable={styles.usersAddToContract__leftTable}
      columns={ADD_MODAL_FROM_GROUPS_FIELDS}
      isModal={isModal}
      toggleModal={toggleModal}
      mainTitle="ответственных"
      leftTableTitle="Все пользователи"
      subModalText="ответственных"
      onSubmit={onSubmit}
      loadingData={loadingUsers}
      isFilterActive={isFilterActive}
      onChangeIdsForDelete={onChangeIdsForDelete}
      paginationLeftTableProps={{
        pageNum,
        pageSize,
        elementsCount: totalElements,
        handleChangePage,
      }}
      filterComponent={
        <UsersFilter
          tableType={TableUsersTypes.ADD_MODAL}
          onFilter={onChangeFilter}
        />
      }
    />
  );
};

import { NotificationContent } from 'features/Notifications/types';
import { ResponseWithMeta } from 'store/types';
import { KeyValueOption } from 'types/models/meta';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import {
  FetchNews,
  FilterNotificationsProfile,
  HasUncheckedNotificationsType,
  News,
  NewsCreateData,
  ProfileSettings,
} from '../../types';

import { endpoints } from './endpoints';

export const request = {
  fetchNotificationProfile: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: FilterNotificationsProfile
  ) =>
    ApiClient.post<ResponseWithMeta<NotificationContent[]>>({
      baseURL: getEnv('REACT_APP_NOTIFICATIONS_PROFILE_URL'),
      url: endpoints.getNotificationsProfile(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filter,
    }),
  setAllCheckedNotificationProfile: () =>
    ApiClient.post<boolean>({
      baseURL: getEnv('REACT_APP_NOTIFICATIONS_PROFILE_URL'),
      url: endpoints.setAllCheckedNotificationsProfile(),
    }),
  getIsUncheckedNotificationProfile: (data: HasUncheckedNotificationsType[]) =>
    ApiClient.post<HasUncheckedNotificationsType[]>({
      baseURL: getEnv('REACT_APP_NOTIFICATIONS_PROFILE_URL'),
      url: endpoints.getUncheckedNotificationsProfile(),
      data,
    }),
  setCheckedNotification: (id: string) =>
    ApiClient.put({
      baseURL: getEnv('REACT_APP_NOTIFICATIONS_PROFILE_URL'),
      url: endpoints.setCheckedNotifications(id),
    }),
  fetchNotificationTabs: () =>
    ApiClient.get({
      baseURL: getEnv('REACT_APP_NOTIFICATIONS_PROFILE_URL'),
      url: endpoints.fetchNotificationsTabs(),
    }),
  fetchNotificationSettings: () =>
    ApiClient.get({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.fetchNotificationsSettings(),
    }),
  updateNotificationSettings: (settings: ProfileSettings) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.fetchNotificationsSettings(),
      data: settings,
    }),
  fetchNews: ({ pageNum = 0, pageSize, sort, published }: FetchNews) =>
    ApiClient.post<ResponseWithMeta<News[]>>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getNews(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { published },
    }),
  createNews: (data: NewsCreateData) =>
    ApiClient.post<News>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.createNews(),
      data,
    }),
  editNews: (data: NewsCreateData) =>
    ApiClient.patch<News>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.editNews(data.id || ''),
      data,
    }),
  fetchCurrentNews: (id: string) =>
    ApiClient.get<ResponseWithMeta<News>>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getCurrentNews(id),
    }),
  deleteNews: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.deleteNews(id),
    }),
  fetchIsUnchecked: () =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.getIsUncheckedNews(),
    }),
  checkNews: (id: string) =>
    ApiClient.patch<boolean>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.checkNews(id),
    }),
  fetchOrganizations: () =>
    ApiClient.get<KeyValueOption[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getOrganizations(),
    }),
  fetchWorkGroups: (data: string[]) =>
    ApiClient.post<KeyValueOption[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroups(),
      data,
    }),
  checkHasUnpublishedNews: () =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_KNOWLEDGE_BASE_URL'),
      url: endpoints.checkHasUnpublishedNews(),
    }),
};

import cn from 'clsx';
import React, { useState } from 'react';

import { Card } from '../Card';
import { RollUpButton } from '../RollUpButton';
import { Typography, TypographyVariants } from '../Typography';

import styles from './Accordion.module.scss';

interface Props {
  title: string;
  className?: string;
  contentClassName?: string;
  defaultHidden?: boolean;
  headerContent?: JSX.Element;
}

export const Accordion: React.FC<Props> = ({
  children,
  title,
  defaultHidden = false,
  className,
  contentClassName,
  headerContent,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(!defaultHidden);

  const toggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLOrSVGElement>) => {
    if (event.key === 'Enter') {
      toggleOpen();
    }
  };

  return (
    <div className={cn(styles.accordion, className)}>
      <Card className={styles.accordion__header}>
        <div className={styles.accordion__headerItem}>
          <RollUpButton
            isOpen={isOpen}
            toggleButton={toggleOpen}
            onKeyDown={handleKeyDown}
          />
          <Typography
            variant={TypographyVariants.h4}
            className={styles.accordion__title}
          >
            {title}
          </Typography>
        </div>
        {headerContent}
      </Card>
      <div
        className={cn(
          styles.accordion__content,
          { [styles.accordion__content_collapsed]: !isOpen },
          { [styles.accordion__content_visible]: isOpen },
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};

import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { setAlert, setRedirectPath } from 'core/ducks/actions';
import { getErrorAlert, getSuccessAlert } from 'core/layouts/AlertsLayout';
import { ActionForAlertTypes, User } from 'core/types';
import { getCurrentContractId } from 'features/Contracts/ducks/selectors';
import { setFilterOrganizationId } from 'features/Organizations/ducks/actions';
import { getFilterOrganizationId } from 'features/Organizations/ducks/selectors';
import {
  fetchRoleInGroupByUserId,
  fetchRolesByUserIdRequest,
  resetRolesState,
} from 'features/Roles/ducks/actions';
import { getTicket } from 'features/Ticket/ducks/ticket/selectors';
import { CreateUserData, CreateUserDataToRequest } from 'features/User/types';
import { resetWorkGroupsState } from 'features/WorkGroups/ducks/actions';
import { getCurrentGroupId } from 'features/WorkGroups/ducks/selectors';
import { RouterHref } from 'routes/routerHref';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import { UsersFilter, UsersFilterToRequest } from '../types';
import { fetchUsersSuccessMap } from '../utils';

import {
  checkIsExistEmailSuccess,
  checkIsExistLoginSuccess,
  checkIsExistPhoneSuccess,
  createUser,
  editUser,
  fetchCurrentUser,
  fetchSubordinatesSuccess,
  fetchUsersAddSuccessUpdate,
  fetchUsersClientsRequest,
  fetchUsersSpecialistsRequest,
  fetchUsersSuccessUpdate,
  hideUserLoading,
  hideUsersAddLoading,
  hideUsersLoading,
  resetCurrentUserAfterDelete,
  setCurrentUserSuccess,
  showUserLoading,
  showUsersAddLoading,
  showUsersLoading,
  updateUserDataRequest,
  updateUserRolesInGroup,
  updateUserRolesInSystem,
} from './actions';
import { request } from './api/requests';
import {
  getCurrentUserId,
  getPropsSubordinates,
  getPropsUsers,
  getPropsUsersAdd,
  getSubordinatesFilter,
  getUsersAddFilter,
  getUsersFilter,
} from './selectors';
import {
  CheckIsExistEmailRequestAction,
  CheckIsExistLoginRequestAction,
  CheckIsExistPhoneRequestAction,
  DeleteUserRequestAction,
  Users,
} from './types';

const ENTITY_USER = 'Пользователь';

export const getFilterUsersToRequest = (
  filter: UsersFilter
): UsersFilterToRequest => {
  const { organizationId } = filter;
  return {
    ...filter,
    organizationId:
      organizationId && !Array.isArray(organizationId)
        ? organizationId.value
        : undefined,
  };
};

export const getUserDataToRequest = (
  data: CreateUserData
): CreateUserDataToRequest => {
  const { organizationId } = data;
  return {
    ...data,
    organization:
      organizationId && !Array.isArray(organizationId)
        ? {
            id: organizationId.value,
          }
        : undefined,
  };
};

function* usersFetch() {
  try {
    const { pageNum, pageSize, sort }: ReturnType<typeof getPropsUsers> =
      yield select(getPropsUsers);
    const filters: ReturnType<typeof getUsersFilter> = yield select(
      getUsersFilter
    );
    yield put(showUsersLoading());
    const users: ResponseWithMeta<User[]> = yield call(
      request.fetchUsers,
      pageNum,
      pageSize,
      sort,
      getFilterUsersToRequest(filters)
    );
    yield put(fetchUsersSuccessUpdate(users));
    yield put(hideUsersLoading());
  } catch (e) {
    createError(e);
    yield put(hideUsersLoading());
  }
}

function* subordinatesFetch() {
  try {
    const ticket: ReturnType<typeof getTicket> = yield select(getTicket);
    const ticketContactId = ticket?.contractId?.key;
    const ticketSystemId = ticket?.systemId?.key;
    const {
      pageNum,
      pageSize,
      sortSubordinates,
    }: ReturnType<typeof getPropsSubordinates> = yield select(
      getPropsSubordinates
    );
    const filters: ReturnType<typeof getSubordinatesFilter> = yield select(
      getSubordinatesFilter
    );
    if (ticketContactId && ticketSystemId) {
      const subordinates: ResponseWithMeta<User[]> = yield call(
        request.fetchSubordinates,
        ticketContactId,
        ticketSystemId,
        pageNum,
        pageSize,
        sortSubordinates,
        getFilterUsersToRequest(filters)
      );
      yield put(fetchSubordinatesSuccess(subordinates.content));
    }
  } catch (e) {
    createError(e);
  }
}

function* currentUserFetch({ payload }: ReturnType<typeof fetchCurrentUser>) {
  try {
    if (payload) {
      yield put(showUserLoading());
      const user: User = yield call(request.fetchUser, payload);
      yield put(setCurrentUserSuccess(user));
      if (user.organization?.id) {
        yield put(setFilterOrganizationId(user.organization?.id));
      }
      yield put(hideUserLoading());
    }
  } catch (e) {
    yield put(hideUserLoading());
    createError(e);
  }
}

function* userCreate({ payload }: ReturnType<typeof createUser>) {
  try {
    yield put(showUsersLoading());
    const user: User = yield call(
      request.createUser,
      getUserDataToRequest(payload)
    );
    if (user.id) {
      yield put(
        setAlert(getSuccessAlert(ENTITY_USER, ActionForAlertTypes.CREATE))
      );
      yield put(setRedirectPath(`/admin/users/${user.id}`));
    }

    yield put(hideUsersLoading());
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_USER, ActionForAlertTypes.CREATE)));
    createError(e);
    yield put(hideUsersLoading());
  }
}

function* userEdit({ payload }: ReturnType<typeof editUser>) {
  try {
    yield put(showUsersLoading());
    const user: User = yield call(
      request.editUser,
      getUserDataToRequest(payload)
    );
    yield put(setCurrentUserSuccess(user));
    yield put(setAlert(getSuccessAlert(ENTITY_USER, ActionForAlertTypes.EDIT)));
    yield put(hideUsersLoading());
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_USER, ActionForAlertTypes.EDIT)));
    createError(e);
    yield put(hideUsersLoading());
  }
}

function* fetchByGroupId() {
  try {
    const { pageNum, pageSize, sort }: ReturnType<typeof getPropsUsers> =
      yield select(getPropsUsers);
    const filters: ReturnType<typeof getUsersFilter> = yield select(
      getUsersFilter
    );
    const groupId: ReturnType<typeof getCurrentGroupId> = yield select(
      getCurrentGroupId
    );
    if (groupId) {
      yield put(showUsersLoading());
      const users: ResponseWithMeta<User[]> = yield call(
        request.fetchUsersByGroupId,
        groupId,
        pageNum,
        pageSize,
        sort,
        getFilterUsersToRequest(filters)
      );
      yield put(fetchUsersSuccessUpdate(users));
    }
    yield put(hideUsersLoading());
  } catch (e) {
    createError(e);
    yield put(hideUsersLoading());
  }
}

function* updateUserData({
  payload,
}: ReturnType<typeof updateUserDataRequest>) {
  try {
    yield call(request.updateUser, payload);
  } catch (e) {
    createError(e);
  }
}

function* updateRolesInGroup({
  payload,
}: ReturnType<typeof updateUserRolesInGroup>) {
  try {
    const userId: ReturnType<typeof getCurrentUserId> = yield select(
      getCurrentUserId
    );
    const groupId: ReturnType<typeof getCurrentGroupId> = yield select(
      getCurrentGroupId
    );
    if (userId && groupId) {
      yield call(request.updateUserRolesInGroup, userId, groupId, payload);
      yield put(fetchRoleInGroupByUserId());
    }
  } catch (e) {
    createError(e);
  }
}

function* updateRolesInSystem({
  payload,
}: ReturnType<typeof updateUserRolesInSystem>) {
  try {
    const userId: ReturnType<typeof getCurrentUserId> = yield select(
      getCurrentUserId
    );
    if (userId) {
      yield call(request.updateUserRolesInSystem, userId, payload);
      yield put(fetchRolesByUserIdRequest());
      yield put(fetchCurrentUser(userId));
    }
  } catch (e) {
    createError(e);
  }
}

function* usersTicketFetchClients({
  payload,
}: ReturnType<typeof fetchUsersClientsRequest>) {
  try {
    const { pageNum, pageSize, filter, updateType, searchValue } = payload;
    const users = (yield call(
      request.fetchUsersTicket,
      pageNum,
      pageSize,
      'FIRSTNAME_ASC',
      { organizationType: filter?.organizationType, fullName: searchValue }
    )) as ResponseWithMeta<User[]>;
    const fetchUsersSuccess =
      updateType && fetchUsersSuccessMap.CUSTOMER[updateType];
    const action = users && fetchUsersSuccess?.(users);
    if (action) {
      yield put(action);
    }
  } catch (e) {
    createError(e);
  }
}

function* usersTicketFetchSpecialists({
  payload,
}: ReturnType<typeof fetchUsersSpecialistsRequest>) {
  try {
    const { pageNum, pageSize, filter, updateType, searchValue } = payload;
    const users = (yield call(
      request.fetchUsersTicket,
      pageNum,
      pageSize,
      'FIRSTNAME_ASC',
      { organizationType: filter?.organizationType, fullName: searchValue }
    )) as ResponseWithMeta<User[]>;
    const fetchUsersSuccess =
      updateType && fetchUsersSuccessMap.SERVICE[updateType];
    const action = fetchUsersSuccess?.(users);
    if (action) {
      yield put(action);
    }
  } catch (e) {
    createError(e);
  }
}

function* usersAddFetchByOrgId() {
  try {
    const { sort, pageNum, pageSize }: ReturnType<typeof getPropsUsersAdd> =
      yield select(getPropsUsersAdd);
    const filters: ReturnType<typeof getUsersAddFilter> = yield select(
      getUsersAddFilter
    );
    const orgId: ReturnType<typeof getFilterOrganizationId> = yield select(
      getFilterOrganizationId
    );

    if (orgId) {
      yield put(showUsersAddLoading());
      const users: ResponseWithMeta<User[]> = yield call(
        request.fetchUsers,
        pageNum,
        pageSize,
        sort,
        {
          ...filters,
          organizationId: orgId,
        }
      );
      yield put(fetchUsersAddSuccessUpdate(users));
    }

    yield put(hideUsersAddLoading());
  } catch (e) {
    createError(e);
    yield put(hideUsersAddLoading());
  }
}

function* usersAddFetchByContractId() {
  try {
    const filters: ReturnType<typeof getUsersAddFilter> = yield select(
      getUsersAddFilter
    );
    const { sort, pageNum, pageSize }: ReturnType<typeof getPropsUsersAdd> =
      yield select(getPropsUsersAdd);
    const contractId: ReturnType<typeof getCurrentContractId> = yield select(
      getCurrentContractId
    );

    if (contractId) {
      yield put(showUsersAddLoading());
      const users: ResponseWithMeta<User[]> = yield call(
        request.fetchUserByContractId,
        contractId,
        pageNum,
        pageSize,
        sort,
        getFilterUsersToRequest(filters)
      );
      yield put(fetchUsersAddSuccessUpdate(users));
    }

    yield put(hideUsersAddLoading());
  } catch (e) {
    createError(e);
    yield put(hideUsersAddLoading());
  }
}

function* checkIsExistLogin({ payload }: CheckIsExistLoginRequestAction) {
  try {
    const isExistLogin: boolean = yield call(
      request.checkIsExistLogin,
      payload
    );
    yield put(checkIsExistLoginSuccess(isExistLogin));
  } catch (e) {
    createError(e);
  }
}

function* checkIsExistEmail({ payload }: CheckIsExistEmailRequestAction) {
  try {
    const isExistEmail: boolean = yield call(
      request.checkIsExistEmail,
      payload
    );
    yield put(checkIsExistEmailSuccess(isExistEmail));
  } catch (e) {
    createError(e);
  }
}

function* checkIsExistPhone({ payload }: CheckIsExistPhoneRequestAction) {
  try {
    const isExistPhone: boolean = yield call(
      request.checkIsExistPhone,
      payload
    );
    yield put(checkIsExistPhoneSuccess(isExistPhone));
  } catch (e) {
    createError(e);
  }
}

function* deleteUser({ payload }: DeleteUserRequestAction) {
  try {
    const { id, withRedirect } = payload;
    yield call(request.deleteUser, id);
    yield put(
      setAlert(getSuccessAlert(ENTITY_USER, ActionForAlertTypes.DELETE))
    );
    if (withRedirect) {
      yield put(setRedirectPath(RouterHref.AdminUsers));
      return;
    }
    yield put(resetCurrentUserAfterDelete());
    yield put(resetWorkGroupsState());
    yield put(resetRolesState());
    yield call(usersFetch);
  } catch (e) {
    yield put(setAlert(getErrorAlert(ENTITY_USER, ActionForAlertTypes.DELETE)));
    createError(e);
  }
}

export function* usersSaga(): Generator<StrictEffect> {
  yield takeEvery(Users.FETCH_USERS_REQUEST, usersFetch);
  yield takeEvery(Users.FETCH_USER_REQUEST, currentUserFetch);
  yield takeEvery(Users.CREATE_USER, userCreate);
  yield takeEvery(Users.EDIT_USER, userEdit);
  yield takeEvery(Users.FETCH_USERS_BY_GROUPS_ID_REQUEST, fetchByGroupId);
  yield takeEvery(Users.UPDATE_USER_DATA_REQUEST, updateUserData);
  yield takeEvery(Users.FETCH_SUBORDINATES_REQUEST, subordinatesFetch);
  yield takeEvery(Users.UPDATE_USER_ROLES_IN_GROUP, updateRolesInGroup);
  yield takeEvery(Users.UPDATE_USER_ROLES_IN_SYSTEM, updateRolesInSystem);
  yield takeEvery(Users.FETCH_USER_CLIENTS_REQUEST, usersTicketFetchClients);
  yield takeEvery(
    Users.FETCH_USER_SPECIALISTS_REQUEST,
    usersTicketFetchSpecialists
  );
  yield takeEvery(
    Users.FETCH_USERS_ADD_BY_ORG_ID_REQUEST,
    usersAddFetchByOrgId
  );
  yield takeEvery(
    Users.FETCH_USERS_ADD_BY_CONTRACT_ID_REQUEST,
    usersAddFetchByContractId
  );
  yield takeEvery(Users.CHECK_IS_EXIST_LOGIN_REQUEST, checkIsExistLogin);
  yield takeEvery(Users.CHECK_IS_EXIST_EMAIL_REQUEST, checkIsExistEmail);
  yield takeEvery(Users.CHECK_IS_EXIST_PHONE_REQUEST, checkIsExistPhone);
  yield takeEvery(Users.DELETE_USER_BY_ID_REQUEST, deleteUser);
}

import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { SystemPriority } from 'features/Systems/types';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import {
  Field,
  FieldsIntegration,
  JiraIntegration,
  JiraIntegrationsFilter,
  JiraPriority,
  JiraStatus,
  JiraTicketType,
  Status,
  TicketTypes,
} from '../types';

import {
  FetchFieldsIntegrationsSuccessAction,
  FetchJiraFieldsSuccessAction,
  FetchJiraIntegrationsSuccessAction,
  FetchJiraIntegrationSuccessAction,
  FetchJiraPrioritiesByIdSuccessAction,
  FetchJiraPrioritiesByIntegrationIdSuccessAction,
  FetchJiraPrioritiesSuccessAction,
  FetchJiraSPStatusesForCurrentSuccessAction,
  FetchJiraSPStatusesSuccessAction,
  FetchJiraStatusesByIdSuccessAction,
  FetchJiraStatusesSuccessAction,
  FetchJiraTicketTypeByIntegrationIdSuccessAction,
  FetchJiraTicketTypesByIntegrationIdSuccessAction,
  FetchJiraTicketTypesSuccessAction,
  FetchSpFieldsSuccessAction,
  FetchSystemPrioritiesSuccessAction,
  FetchTicketTypesSuccessAction,
  Jira,
  JiraIntegrationsActionsTypes,
  SetCurrentJiraIntegrationIdAction,
  SetCurrentJiraIntegrationsPageAction,
  SetCurrentSystemIdAction,
  SetCurrentTypeIdAction,
  SetJiraIntegrationsFilterAction,
  SetSizeJiraIntegrationsPageAction,
  SetSortJiraIntegrationsAction,
  UpdateJiraIntegrationByIdAction,
} from './types';

export interface JiraReducerState {
  jiraIntegrations?: JiraIntegration[];
  loadingIntegrations?: boolean;
  pagination?: Pagination;
  sort?: string;
  filter?: JiraIntegrationsFilter;
  jiraStatuses?: JiraStatus[];
  jiraPriorities?: JiraPriority[];
  jiraPrioritiesForCurrent?: JiraPriority[];
  jiraStatusesBySystemId?: JiraStatus[];
  jiraPrioritiesBySystemId?: JiraPriority[];
  jiraIntegrationId?: string;
  systemId?: string;
  loadingStatuses?: boolean;
  loadingPriorities?: boolean;
  jiraIntegration?: JiraIntegration;
  spFields?: Field[];
  jiraFields?: Field[];
  loadingJiraIntegrationInfo?: boolean;
  fieldsIntegrations?: FieldsIntegration[];
  systemPriorities?: SystemPriority[];
  spStatuses?: Status[];
  spStatusesForCurrent?: Status[];
  jiraTicketTypes?: JiraTicketType[];
  jiraTicketTypesForCurrent?: JiraTicketType[];
  ticketTypes?: TicketTypes[];
  typeId?: string;
  jiraTicketType?: JiraTicketType[];
}

const initialState: JiraReducerState = {
  jiraIntegrations: [],
  loadingIntegrations: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  sort: 'date_create_DESC',
  filter: {},
  jiraStatuses: [],
  jiraPriorities: [],
  loadingStatuses: false,
  loadingPriorities: false,
  spFields: [],
  jiraFields: [],
  loadingJiraIntegrationInfo: false,
  fieldsIntegrations: [],
  systemPriorities: [],
  spStatuses: [],
  spStatusesForCurrent: [],
  jiraTicketTypes: [],
  jiraTicketTypesForCurrent: [],
  ticketTypes: [],
};

const accessesReducerMap: ReducerMap<
  JiraReducerState,
  JiraIntegrationsActionsTypes
> = {
  [Jira.JIRA_INTEGRATIONS_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loadingIntegrations: true,
    };
  },
  [Jira.JIRA_INTEGRATIONS_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loadingIntegrations: false,
    };
  },
  [Jira.FETCH_JIRA_INTEGRATIONS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraIntegrationsSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      jiraIntegrations: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [Jira.SET_CURRENT_JIRA_INTEGRATIONS_PAGE]: (state, action) => {
    const { payload } = action as SetCurrentJiraIntegrationsPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [Jira.SET_SIZE_JIRA_INTEGRATIONS_PAGE]: (state, action) => {
    const { payload } = action as SetSizeJiraIntegrationsPageAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageSize: payload,
      },
    };
  },
  [Jira.SET_SORT_JIRA_INTEGRATIONS]: (state, action) => {
    const { payload } = action as SetSortJiraIntegrationsAction;
    return {
      ...state,
      sort: payload,
    };
  },
  [Jira.SET_FILTER_JIRA_INTEGRATIONS]: (state, action) => {
    const { payload } = action as SetJiraIntegrationsFilterAction;
    return {
      ...state,
      filter: payload,
    };
  },
  [Jira.FETCH_JIRA_STATUSES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraStatusesSuccessAction;
    return {
      ...state,
      jiraStatuses: payload,
    };
  },
  [Jira.FETCH_JIRA_PRIORITIES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraPrioritiesSuccessAction;
    return {
      ...state,
      jiraPriorities: payload,
    };
  },
  [Jira.FETCH_JIRA_PRIORITIES_BY_INTEGRATION_ID_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchJiraPrioritiesByIntegrationIdSuccessAction;
    return {
      ...state,
      jiraPrioritiesForCurrent: payload,
    };
  },
  [Jira.SET_CURRENT_JIRA_INTEGRATION_ID]: (state, action) => {
    const { payload } = action as SetCurrentJiraIntegrationIdAction;
    return {
      ...state,
      jiraIntegrationId: payload,
    };
  },
  [Jira.SET_CURRENT_SYSTEM_ID]: (state, action) => {
    const { payload } = action as SetCurrentSystemIdAction;
    return {
      ...state,
      systemId: payload,
    };
  },
  [Jira.RESET_JIRA_STATUSES]: (state) => {
    return {
      ...state,
      jiraStatuses: [],
    };
  },
  [Jira.RESET_JIRA_PRIORITIES]: (state) => {
    return {
      ...state,
      jiraPriorities: [],
    };
  },
  [Jira.STATUSES_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loadingStatuses: true,
    };
  },
  [Jira.STATUSES_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loadingStatuses: false,
    };
  },
  [Jira.PRIORITIES_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loadingPriorities: true,
    };
  },
  [Jira.PRIORITIES_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loadingPriorities: false,
    };
  },
  [Jira.FETCH_JIRA_STATUSES_BY_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraStatusesByIdSuccessAction;
    return {
      ...state,
      jiraStatusesBySystemId: payload,
    };
  },
  [Jira.FETCH_JIRA_PRIORITIES_BY_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraPrioritiesByIdSuccessAction;
    return {
      ...state,
      jiraPrioritiesBySystemId: payload,
    };
  },
  [Jira.FETCH_JIRA_INTEGRATION_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraIntegrationSuccessAction;
    return {
      ...state,
      jiraIntegration: payload,
    };
  },
  [Jira.UPDATE_JIRA_INTEGRATION_BY_ID]: (state, action) => {
    const { payload } = action as UpdateJiraIntegrationByIdAction;

    return {
      ...state,
      jiraIntegrations: state?.jiraIntegrations?.map((jiraIntegration) => {
        if (jiraIntegration.id === payload.id) {
          return payload;
        }
        return jiraIntegration;
      }),
    };
  },
  [Jira.FETCH_SP_FIELDS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSpFieldsSuccessAction;
    return {
      ...state,
      spFields: payload,
    };
  },
  [Jira.FETCH_JIRA_FIELDS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraFieldsSuccessAction;
    return {
      ...state,
      jiraFields: payload,
    };
  },
  [Jira.JIRA_INTEGRATION_INFO_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loadingJiraIntegrationInfo: true,
    };
  },
  [Jira.JIRA_INTEGRATION_INFO_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loadingJiraIntegrationInfo: false,
    };
  },
  [Jira.FETCH_FIELDS_INTEGRATIONS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchFieldsIntegrationsSuccessAction;
    return {
      ...state,
      fieldsIntegrations: payload,
    };
  },
  [Jira.FETCH_SYSTEM_PRIORITIES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSystemPrioritiesSuccessAction;
    return {
      ...state,
      systemPriorities: payload,
    };
  },
  [Jira.RESET_SYSTEM_PRIORITIES]: (state) => {
    return {
      ...state,
      systemPriorities: initialState.systemPriorities,
    };
  },
  [Jira.FETCH_JIRA_SP_STATUSES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraSPStatusesSuccessAction;
    return {
      ...state,
      spStatuses: payload,
    };
  },
  [Jira.RESET_JIRA_SP_STATUSES]: (state) => {
    return {
      ...state,
      spStatuses: initialState.spStatuses,
    };
  },
  [Jira.FETCH_JIRA_SP_STATUSES_FOR_CURRENT_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraSPStatusesForCurrentSuccessAction;
    return {
      ...state,
      spStatusesForCurrent: payload,
    };
  },
  [Jira.RESET_JIRA_STATE]: () => ({
    ...initialState,
  }),
  [Jira.FETCH_TICKET_TYPES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketTypesSuccessAction;
    return {
      ...state,
      ticketTypes: payload,
    };
  },
  [Jira.FETCH_JIRA_TICKET_TYPES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraTicketTypesSuccessAction;
    return {
      ...state,
      jiraTicketTypes: payload,
    };
  },
  [Jira.FETCH_JIRA_TICKET_TYPES_BY_INTEGRATION_ID_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchJiraTicketTypesByIntegrationIdSuccessAction;
    return {
      ...state,
      jiraTicketTypesForCurrent: payload,
    };
  },
  [Jira.FETCH_JIRA_TICKET_TYPE_BY_INTEGRATION_ID_SUCCESS]: (state, action) => {
    const { payload } =
      action as FetchJiraTicketTypeByIntegrationIdSuccessAction;
    return {
      ...state,
      jiraTicketType: payload,
    };
  },
  [Jira.SET_CURRENT_TYPE_ID]: (state, action) => {
    const { payload } = action as SetCurrentTypeIdAction;
    return {
      ...state,
      typeId: payload,
    };
  },
};

export const jira: Reducer<JiraReducerState, JiraIntegrationsActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = accessesReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};

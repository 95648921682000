import cn from 'clsx';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import Dropzone, { DropzoneOptions } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { AttachmentIcon, FileIcon } from 'assets/icons';
import { getScreenWidth } from 'core/ducks/selectors';
import { ScreenWidth } from 'core/types';

import { Button } from '../Button';
import { FileChip } from '../FileChip';
import { Typography, TypographyVariants } from '../Typography';

import styles from './FileInput.module.scss';

type Arg = ((files: File[]) => File[]) | File[];

interface Props {
  classNameWrapper?: string;
  fileHandler: <T extends File>(acceptedFiles: T[]) => void;
  classNameContent?: string;
  classNameFiles?: string;
  classNameIcon?: string;
  classNameTitle?: string;
  classNameSubTitle?: string;
  classNameButton?: string;
  myFiles?: File[];
  setMyFiles?: (arg: Arg) => void;
  myFile?: File[];
  setMyFile?: (arg: Arg) => void;
  showFiles?: boolean;
  maxFileSize?: number;
  maxFileSizeWarning?: string;
  getMaxFileName?: (name: string) => void;
  onErrorMaxFileSize?(): void;
  isDisabled?: boolean;
}

export const FileInput: React.FC<Props> = ({
  classNameWrapper,
  fileHandler,
  classNameContent,
  classNameFiles,
  children,
  myFiles,
  setMyFiles,
  myFile,
  setMyFile,
  showFiles = true,
  classNameIcon,
  classNameTitle,
  classNameSubTitle,
  classNameButton,
  maxFileSize,
  maxFileSizeWarning,
  getMaxFileName,
  onErrorMaxFileSize,
  isDisabled = false,
}) => {
  const screenWidth = useSelector(getScreenWidth);

  const isMobile = screenWidth === ScreenWidth.MOBILE;
  const isMobileSmall = screenWidth === ScreenWidth.MOBILE_SMALL;
  const isMobileAll = isMobile || isMobileSmall;

  const [fileNames, setFileNames] = useState<string[]>([]);

  const handleDrop: DropzoneOptions['onDrop'] = (acceptedFiles: File[]) => {
    const acceptedFiledCopy = maxFileSize
      ? acceptedFiles.filter((file) => {
          if (file.size > maxFileSize && onErrorMaxFileSize) {
            onErrorMaxFileSize();
          }
          if (getMaxFileName && file.size > maxFileSize) {
            getMaxFileName(file.name);
          }
          return file.size <= maxFileSize;
        })
      : acceptedFiles;
    if (setMyFiles) {
      setMyFiles((prev: File[]) => [...prev, ...acceptedFiledCopy]);
    }
    if (setMyFile) {
      setMyFile(() => [...acceptedFiledCopy]);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const removeFile =
    (file: File) => (event: React.MouseEvent<HTMLButtonElement>) => {
      if (myFiles && setMyFiles) {
        event.stopPropagation();
        event.preventDefault();
        const newFiles = [...myFiles];
        newFiles.splice(newFiles.indexOf(file), 1);
        setMyFiles(newFiles);
      }
      if (myFile && setMyFile) {
        event.stopPropagation();
        event.preventDefault();
        const newFiles = [...myFile];
        newFiles.splice(newFiles.indexOf(file), 1);
        setMyFile(newFiles);
      }
    };

  useEffect(() => {
    if (myFiles) {
      fileHandler(myFiles);
      const fileNamesList = myFiles.map((file) => file.name);
      setFileNames(() => [...fileNamesList]);
    }
    if (myFile) {
      fileHandler(myFile);
      const fileNamesList = myFile.map((file) => file.name);
      setFileNames(() => [...fileNamesList]);
    }
  }, [myFiles, myFile]);

  const fileNamesList = Boolean(fileNames.length) && (
    <ul className={cn(styles.fileInput__list, classNameFiles)}>
      {myFiles?.map((file) => (
        <FileChip
          key={nanoid()}
          title={file.name}
          onDelete={removeFile(file)}
        />
      ))}
    </ul>
  );

  return (
    <>
      <Dropzone
        disabled={isDisabled}
        onDrop={handleDrop}
        noClick={!isMobileAll}
      >
        {({ getRootProps, getInputProps, open }) => {
          return (
            <div
              {...getRootProps()}
              onMouseDown={handleClick}
              role="button"
              tabIndex={0}
              className={cn(styles.fileInput__wrapper, classNameWrapper)}
            >
              <input {...getInputProps()} />
              <div className={cn(styles.fileInput__content, classNameContent)}>
                {!isMobileAll && (
                  <>
                    <FileIcon
                      className={cn(
                        styles.fileInput__contentIcon,
                        classNameIcon
                      )}
                    />
                    <Typography
                      variant={TypographyVariants.b2}
                      className={classNameTitle}
                    >
                      Переместите сюда файлы
                    </Typography>
                    <Typography
                      variant={TypographyVariants.b3}
                      className={cn(
                        styles.fileInput__contentSpan,
                        classNameSubTitle
                      )}
                    >
                      или
                    </Typography>
                    <Button
                      className={classNameButton}
                      onClick={open}
                      type="button"
                      disabled={isDisabled}
                    >
                      Выбрать файл
                    </Button>
                    {!!maxFileSizeWarning && (
                      <Typography
                        variant={TypographyVariants.s2}
                        className={styles.fileInput__warning}
                      >
                        Максимальный размер файла {maxFileSizeWarning} MB
                      </Typography>
                    )}
                  </>
                )}
                {isMobileAll && (
                  <>
                    <AttachmentIcon className={styles.fileInput__contentIcon} />
                    <Typography
                      variant={TypographyVariants.b3}
                      className={styles.fileInput__contentSpan}
                    >
                      Прикрепить файл
                    </Typography>
                  </>
                )}
              </div>
            </div>
          );
        }}
      </Dropzone>
      {children}
      {showFiles && fileNamesList}
    </>
  );
};

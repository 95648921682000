import cn from 'clsx';
import React, { useMemo, useRef, useState } from 'react';

import { Popover } from 'components/Popover';
import { Tabs } from 'components/Tabs';
import { Tab } from 'components/Tabs/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { RouterHref } from 'routes/routerHref';

import { useCurrentStateForm } from '../../hooks/useCurrentStateForm';
import { useEmployeesForm } from '../../hooks/useEmployeesForm';
import { useHeaderForm } from '../../hooks/useHeaderForm';
import { useHistoryForm } from '../../hooks/useHistoryForm';
import { useKpiForm } from '../../hooks/useKpiForm';
import { CurrentStateForm } from '../CurrentStateForm';
import { EmployeesForm } from '../EmployeesForm';
import { HistoryForm } from '../HistoryForm';
import { KpiForm } from '../KpiForm';

import styles from './DesktopHeader.module.scss';

export const Header = () => {
  const tabsRef = useRef<HTMLDivElement>(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const {
    state: {
      isDesktopKpiPage,
      isDesktopPage,
      isDesktopHistoryPage,
      isMobileAll,
      isGroupSpecialist,
      isAccessToTakeTicketToWork,
      isAccessToCreateReportByOrg,
      managerWorkGroupsList,
      organizationsOptions,
      systemsOptions,
      hiddenDesktopKpi,
    },
  } = useHeaderForm();

  const {
    state: {
      kpiWorkGroupsIds,
      kpiOrganizationsIds,
      kpiSystemsIds,
      kpiWorkGroupsOptions,
      kpiOrganizationsOptions,
      kpiSystemsOptions,
      kpiTicketTypesOptions,
    },
    methods: { kpiControl, fetchKpiHandler, setValue },
  } = useKpiForm();

  const {
    methods: { employeesControl },
  } = useEmployeesForm();

  const {
    methods: { historyControl, setHistoryValue },
  } = useHistoryForm();

  const {
    state: { radioTabs, isOrganizationType },
    methods: { currentStateControl, setCurrentStateValue },
  } = useCurrentStateForm();

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const tabsConfig: Tab[] = useMemo(
    () => [
      {
        to: RouterHref.DesktopKpi,
        exact: true,
        title: 'Моя эффективность',
        className: cn(styles.desktopHeader__tab, {
          [styles.desktopHeader__tab_hidden]: hiddenDesktopKpi,
        }),
      },
      {
        to: RouterHref.DesktopCurrentState,
        exact: false,
        title: 'Текущее состояние',
        className: cn(styles.desktopHeader__tab, {
          [styles.desktopHeader__tab_hidden]:
            !isAccessToCreateReportByOrg && !isGroupSpecialist,
        }),
      },
      {
        to: RouterHref.DesktopHistory,
        exact: false,
        title: 'История',
        className: cn(styles.desktopHeader__tab, {
          [styles.desktopHeader__tab_hidden]: !isAccessToCreateReportByOrg,
        }),
      },
      {
        to: RouterHref.DesktopEmployees,
        exact: false,
        title: 'Сотрудники',
        className: cn(styles.desktopHeader__tab, {
          [styles.desktopHeader__tab_hidden]: !isGroupSpecialist,
        }),
      },
    ],
    [isAccessToCreateReportByOrg, isGroupSpecialist, isAccessToTakeTicketToWork]
  );

  const getForm = () => {
    if (isDesktopKpiPage) {
      return (
        <KpiForm
          control={kpiControl}
          fetchKpiHandler={fetchKpiHandler}
          setValue={setValue}
          workGroupsIds={kpiWorkGroupsIds}
          organizationsIds={kpiOrganizationsIds}
          systemsIds={kpiSystemsIds}
          workGroupsOptions={kpiWorkGroupsOptions}
          organizationsOptions={kpiOrganizationsOptions}
          systemsOptions={kpiSystemsOptions}
          ticketTypesOptions={kpiTicketTypesOptions}
        />
      );
    }
    if (isDesktopPage) {
      return (
        <CurrentStateForm
          control={currentStateControl}
          setValue={setCurrentStateValue}
          radioTabs={radioTabs}
          isOrganizationType={isOrganizationType}
          managersOptions={managerWorkGroupsList}
          systemsOptions={systemsOptions}
          organizationsOptions={organizationsOptions}
        />
      );
    }
    if (isDesktopHistoryPage) {
      return (
        <HistoryForm
          control={historyControl}
          setValue={setHistoryValue}
          organizationsOptions={organizationsOptions}
          systemsOptions={systemsOptions}
        />
      );
    }
    return (
      <EmployeesForm
        control={employeesControl}
        managerWorkGroupsList={managerWorkGroupsList}
      />
    );
  };

  const rightContainer = !isMobileAll && (
    <div className={styles.desktopHeader__rightContainer}>{getForm()}</div>
  );

  const title = isMobileAll && (
    <Typography
      variant={TypographyVariants.h3}
      className={styles.desktopHeader__title}
    >
      Рабочий стол
      <Popover
        className={styles.desktopHeader__drop}
        content={
          <div className={styles.desktopHeader__dropContentWrapper}>
            {getForm()}
          </div>
        }
        togglePopover={togglePopover}
        isOpen={isPopoverOpen}
      />
    </Typography>
  );

  return (
    <div className={styles.desktopHeader}>
      <div className={styles.desktopHeader__leftContainer}>
        {title}
        <div className={styles.desktopHeader__tabsWrapper}>
          <Tabs
            className={styles.desktopHeader__tabs}
            tabs={tabsConfig}
            tabsRef={tabsRef}
            fontSize={14}
          />
        </div>
      </div>
      {rightContainer}
    </div>
  );
};

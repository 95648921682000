import React from 'react';
import { useSelector } from 'react-redux';

import { ListRoundIcon, SearchFilters } from 'assets/icons';
import { Button } from 'components/Button';
import { Loader } from 'components/Loader';
import { Typography, TypographyVariants } from 'components/Typography';

import { getIsDownloadingExcel, getTicketsCount } from '../../ducks/selectors';

import styles from './ExportSubmit.module.scss';

interface Props {
  countFields: number;
  handleSearch: () => void;
  handleExport: () => void;
  handleClose: () => void;
}

export const ExportSubmit = (props: Props) => {
  const { countFields, handleSearch, handleExport, handleClose } = props;

  const ticketsCount = useSelector(getTicketsCount);
  const isDownloadingExcel = useSelector(getIsDownloadingExcel);

  const currentTicketsCount = ticketsCount === undefined ? 0 : ticketsCount;

  const currentExportContent = () => {
    if (ticketsCount === undefined) {
      return (
        <div className={styles.exportSubmit__content}>
          <SearchFilters className={styles.exportSubmit__listRoundIcon} />
          <Typography
            variant={TypographyVariants.h3}
            className={styles.exportSubmit__selectText}
          >
            Выбрано фильтров
          </Typography>
          <Typography
            variant={TypographyVariants.h1}
            className={styles.exportSubmit__quantityTickets}
          >
            {countFields}
          </Typography>
          <Button
            className={styles.exportSubmit__searchButton}
            appearance="outline"
            onClick={handleSearch}
          >
            Поиск тикетов
          </Button>
        </div>
      );
    }
    if (ticketsCount === 0) {
      return (
        <div className={styles.exportSubmit__content}>
          <ListRoundIcon className={styles.exportSubmit__listRoundIcon} />
          <Typography
            variant={TypographyVariants.h3}
            className={styles.exportSubmit__selectText}
          >
            Выбрано записей
          </Typography>
          <Typography
            variant={TypographyVariants.h1}
            className={styles.exportSubmit__quantityTickets}
          >
            {currentTicketsCount}
          </Typography>
          <Button
            className={styles.exportSubmit__exportButton}
            appearance="default"
            onClick={handleExport}
            disabled
          >
            Экспорт
          </Button>
        </div>
      );
    }
    return (
      <div className={styles.exportSubmit__content}>
        <ListRoundIcon className={styles.exportSubmit__listRoundIcon} />
        <Typography
          variant={TypographyVariants.h3}
          className={styles.exportSubmit__selectText}
        >
          Выбрано записей
        </Typography>
        <Typography
          variant={TypographyVariants.h1}
          className={styles.exportSubmit__quantityTickets}
        >
          {currentTicketsCount}
        </Typography>
        {isDownloadingExcel && (
          <Loader classNameRoot={styles.exportSubmit__loader} />
        )}
        <Button
          className={styles.exportSubmit__exportButton}
          appearance="default"
          onClick={handleExport}
        >
          Экспорт
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.exportSubmit}>
      <Typography
        variant={TypographyVariants.b3}
        className={styles.exportSubmit__all}
      >
        Выберите поля для экспорта и при необходимости примените фильтрацию
      </Typography>
      {currentExportContent()}
      <Button
        className={styles.exportSubmit__cancelButton}
        appearance="outline"
        onClick={handleClose}
      >
        Отмена
      </Button>
    </div>
  );
};

import React from 'react';

import { ApproveOrCancel } from 'core/modals/ApproveOrCancel';

import { Props as ApproveOrCancelProps } from '../ApproveOrCancel';

import styles from './DeleteApproveOrCancel.module.scss';

interface Props extends Omit<ApproveOrCancelProps, 'text'> {
  entityTitle: string;
}

export const DeleteApproveOrCancel: React.FC<Props> = ({
  toggleModal,
  isModal,
  entityTitle,
  onApprove,
  withHideBodyScroll = true,
}) => {
  return (
    <ApproveOrCancel
      text={`Вы уверены, что хотите удалить ${entityTitle}?`}
      toggleModal={toggleModal}
      isModal={isModal}
      onApprove={onApprove}
      approveTitle="Удалить"
      withHideBodyScroll={withHideBodyScroll}
      approveBtnProps={{
        appearance: 'outline',
        className: styles.deleteApproveOrCancel__iconDelete,
      }}
    />
  );
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TablesLayout } from 'core/layouts/TablesLayout';
import { resetOrganizationState } from 'features/Organizations/ducks/actions';
import { ResponsibilitiesTableContainer } from 'features/Responsibilities/components/ResponsibilitiesTableContainer';
import { resetResponsibilitiesState } from 'features/Responsibilities/ducks/actions';
import { TableResponsibilitiesTypes } from 'features/Responsibilities/types';
import { RolesTableContainer } from 'features/Roles/components/RolesTableContainer';
import { resetRolesState } from 'features/Roles/ducks/actions';
import { TableRolesTypes } from 'features/Roles/types';
import { UsersTableContainer } from 'features/Users/components/UsersTableContainer';
import { resetUsersState } from 'features/Users/ducks/actions';
import { TableUsersTypes } from 'features/Users/types';

import { WorkGroupsTableContainer } from './components/WorkGroupsTableContainer';
import { fetchWorkGroupsRequest, resetWorkGroupsState } from './ducks/actions';
import { TableWorkGroupsTypes } from './types';
import styles from './WorkGroups.module.scss';

const Main = () => {
  return (
    <div className={styles.workGroups__mainWrapper}>
      <WorkGroupsTableContainer tableType={TableWorkGroupsTypes.FULL} />
      <UsersTableContainer tableType={TableUsersTypes.FROM_GROUPS} />
    </div>
  );
};

const SubResponsibilitiesTable = () => {
  return (
    <ResponsibilitiesTableContainer
      tableType={TableResponsibilitiesTypes.FROM_GROUPS}
    />
  );
};

const SubRolesTable = () => {
  return <RolesTableContainer tableType={TableRolesTypes.FROM_GROUPS} />;
};

export const WorkGroups = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWorkGroupsRequest());
    return () => {
      dispatch(resetWorkGroupsState());
      dispatch(resetUsersState());
      dispatch(resetResponsibilitiesState());
      dispatch(resetRolesState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[Main]}
      SubTable={[SubResponsibilitiesTable, SubRolesTable]}
      classNameRoot={styles.workGroups}
      classNameSubTables={styles.workGroups__subWrapper}
    />
  );
};

import cn from 'clsx';
import React, { forwardRef, useEffect, useState } from 'react';
import PhoneInputWithoutCountry, {
  Country,
  parsePhoneNumber,
  Value,
} from 'react-phone-number-input/input';
import ru from 'react-phone-number-input/locale/ru.json';

import { Input } from 'components/Input';
import { Size } from 'components/types';

import { CountrySelect } from './components/CountrySelect/CountrySelect';
import styles from './PhoneInput.module.css';

interface Props
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'size' | 'onChange'
  > {
  size?: Size;
  label?: string;
  errorMessage?: string;
  onChange: (value: Value) => void;
  value?: Value;
  isWithCountry?: boolean;
  isEditMode?: boolean;
}

export const PhoneInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { isWithCountry, isEditMode, className, size, value, ...otherProps } =
    props;

  const [selectedCountry, setSelectedCountry] = useState<Country>();

  useEffect(() => {
    if (isEditMode) {
      const parsedPhoneNumber = parsePhoneNumber(value || '')?.country;
      if (parsedPhoneNumber) {
        setSelectedCountry(parsedPhoneNumber);
      }
    }
  }, [isEditMode, value]);

  return isWithCountry ? (
    <div className={cn(styles.phoneInput, className)}>
      <CountrySelect
        className={styles.select}
        size={size}
        labels={ru}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
      />
      <PhoneInputWithoutCountry
        {...otherProps}
        ref={ref}
        international
        defaultCountry={isEditMode ? undefined : 'RU'}
        country={selectedCountry}
        value={value}
        inputComponent={Input}
      />
    </div>
  ) : (
    <PhoneInputWithoutCountry
      {...props}
      ref={ref}
      international
      withCountryCallingCode
      inputComponent={Input}
    />
  );
});

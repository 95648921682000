import cn from 'clsx';
import React from 'react';

import styles from './NotificationCircle.module.scss';

interface Props {
  newTicketNotification?: boolean;
  className?: string;
}

export const NotificationCircle: React.FC<Props> = ({
  className,
  newTicketNotification,
}) => {
  return (
    <div
      className={cn(
        styles.notificationCircle,
        {
          [styles.notificationCircle_new]: newTicketNotification,
        },
        className
      )}
    />
  );
};

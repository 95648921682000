import { SelectOption } from 'components/Select/types';
import { Organization } from 'core/types';
import {
  Article,
  CategoryArticle,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from 'features/KnowledgeBase/types';
import { ResponseWithMeta } from 'store/types';

import { KBArticleTableFilter } from '../../../types';
import { FetchArticlesParams } from '../../KBMain/ducks/types';
import { getDataTable } from '../components/KBTableArticles/utils';

import {
  ArticlesTable,
  DeleteArticlesTableRequestAction,
  FetchArticlesTableCategoryRequestAction,
  FetchArticlesTableCategorySuccessAction,
  FetchArticlesTableRequestAction,
  FetchArticlesTableSuccessAction,
  HideArticlesTableLoadingAction,
  ResetArticlesTableStateAction,
  SetArticlesTableFilterAction,
  SetCurrentArticlesTablePageAction,
  SetCurrentArticleTableIdAction,
  SetSizePageAction,
  SetSortArticlesTableAction,
  ShowArticlesTableLoadingAction,
} from './types';

export const fetchArticlesTableRequest = (
  categoryId: string
): FetchArticlesTableRequestAction => {
  return {
    type: ArticlesTable.FETCH_ARTICLES_REQUEST_ARTICLES_TABLE,
    payload: categoryId,
  };
};

export const fetchArticlesTableSuccess = (
  data: ResponseWithMeta<Article[]>
): FetchArticlesTableSuccessAction => {
  return {
    type: ArticlesTable.FETCH_ARTICLES_SUCCESS_ARTICLES_TABLE,
    payload: data,
  };
};

export const fetchArticlesTableCategoryRequest = (
  id: string
): FetchArticlesTableCategoryRequestAction => {
  return {
    type: ArticlesTable.FETCH_CATEGORY_REQUEST_ARTICLES_TABLE,
    payload: id,
  };
};

export const fetchArticlesTableCategorySuccess = (
  data: CategoryArticle
): FetchArticlesTableCategorySuccessAction => {
  return {
    type: ArticlesTable.FETCH_CATEGORY_SUCCESS_ARTICLES_TABLE,
    payload: data,
  };
};

export const showArticlesTableLoading = (): ShowArticlesTableLoadingAction => {
  return {
    type: ArticlesTable.ARTICLES_TABLE_LOADING_SHOW,
  };
};

export const hideArticlesTableLoading = (): HideArticlesTableLoadingAction => {
  return {
    type: ArticlesTable.ARTICLES_TABLE_LOADING_HIDE,
  };
};

export const setCurrentArticlesTablePage = (
  page: number
): SetCurrentArticlesTablePageAction => {
  return {
    type: ArticlesTable.SET_CURRENT_ARTICLES_PAGE_ARTICLES_TABLE,
    payload: page,
  };
};

export const setSortArticlesTable = (
  sort: string
): SetSortArticlesTableAction => {
  return {
    type: ArticlesTable.SET_SORT_ARTICLES_ARTICLES_TABLE,
    payload: sort,
  };
};

export const setSizePage = (size: number): SetSizePageAction => {
  return {
    type: ArticlesTable.SET_SIZE_PAGE_ARTICLES_ARTICLES_TABLE,
    payload: size,
  };
};

export const setCurrentArticleTableId = (
  article?: ReturnType<typeof getDataTable>[number]
): SetCurrentArticleTableIdAction => {
  return {
    type: ArticlesTable.SET_CURRENT_ARTICLE_ID_ARTICLES_TABLE,
    payload: article,
  };
};

export const resetArticlesTableState = (): ResetArticlesTableStateAction => {
  return {
    type: ArticlesTable.RESET_STATE_ARTICLES_TABLE,
  };
};

export const setArticlesTableFilter = (
  data: KBArticleTableFilter
): SetArticlesTableFilterAction => {
  return {
    type: ArticlesTable.SET_ARTICLES_FILTER_ARTICLES_TABLE,
    payload: data,
  };
};

export const fetchOrganizationsRequestArticleTable = (
  filter: KBOrganizationsFilter
) => {
  return {
    type: ArticlesTable.FETCH_ORGANIZATIONS_REQUEST_ARTICLES_TABLE,
    payload: filter,
  };
};

export const fetchOrganizationsSuccessArticleTable = (data: Organization[]) => {
  return {
    type: ArticlesTable.FETCH_ORGANIZATIONS_SUCCESS_ARTICLES_TABLE,
    payload: data,
  };
};

export const deleteArticleTableRequest = (
  id: string
): DeleteArticlesTableRequestAction => {
  return {
    type: ArticlesTable.DELETE_ARTICLE_BY_ID_REQUEST_ARTICLES_TABLE,
    payload: id,
  };
};

export const setArticlesFilterRightArticlesTable = (
  filter: KBArticleTableFilterToRequest
) => {
  return {
    type: ArticlesTable.SET_FILTER_ARTICLES_RIGHT_ARTICLES_TABLE,
    payload: filter,
  };
};

export const fetchArticlesRightArticlesTableRequest = (
  params: FetchArticlesParams
) => {
  return {
    type: ArticlesTable.FETCH_REQUEST_ARTICLES_RIGHT_ARTICLES_TABLE,
    payload: params,
  };
};

export const fetchArticlesSuccessRightArticlesTable = (
  articles: ResponseWithMeta<Article[]>
) => {
  return {
    type: ArticlesTable.FETCH_SUCCESS_ARTICLES_RIGHT_ARTICLES_TABLE,
    payload: articles,
  };
};

export const fetchArticlesJoinSuccessRightArticlesTable = (
  articles: ResponseWithMeta<Article[]>
) => {
  return {
    type: ArticlesTable.FETCH_ARTICLES_JOIN_SUCCESS_RIGHT_ARTICLES_TABLE,
    payload: articles,
  };
};

export const setArticlesPageRightArticlesTable = (page: number) => {
  return {
    type: ArticlesTable.SET_ARTICLES_PAGE_RIGHT_ARTICLES_TABLE,
    payload: page,
  };
};

export const resetArticlesStateRightArticlesTable = () => {
  return {
    type: ArticlesTable.RESET_ARTICLES_STATE_RIGHT_ARTICLES_TABLE,
  };
};

export const loadingShowRightArticlesTable = () => {
  return {
    type: ArticlesTable.LOADING_SHOW_ARTICLES_RIGHT_ARTICLES_TABLE,
  };
};

export const loadingHideRightArticlesTable = () => {
  return {
    type: ArticlesTable.LOADING_HIDE_ARTICLES_RIGHT_ARTICLES_TABLE,
  };
};

export const fetchOrganizationsRequestRightArticlesTable = (
  filter?: KBOrganizationsFilter
) => {
  return {
    type: ArticlesTable.FETCH_ORGANIZATIONS_REQUEST_RIGHT_ARTICLES_TABLE,
    payload: filter,
  };
};

export const fetchOrganizationsSuccessRightArticlesTable = (
  data: Organization[]
) => {
  return {
    type: ArticlesTable.FETCH_ORGANIZATIONS_SUCCESS_RIGHT_ARTICLES_TABLE,
    payload: data,
  };
};

export const organizationsLoadingShowRightArticlesTable = () => {
  return {
    type: ArticlesTable.ORGANIZATIONS_LOADING_SHOW_RIGHT_ARTICLES_TABLE,
  };
};

export const organizationsLoadingHideRightArticlesTable = () => {
  return {
    type: ArticlesTable.ORGANIZATIONS_LOADING_HIDE_RIGHT_ARTICLES_TABLE,
  };
};

export const fetchCategoriesRequestRightArticlesTable = (title?: string) => {
  return {
    type: ArticlesTable.FETCH_CATEGORIES_REQUEST_RIGHT_ARTICLES_TABLE,
    payload: title,
  };
};

export const fetchCategoriesSuccessRightArticlesTable = (
  categories: CategoryArticle[]
) => {
  return {
    type: ArticlesTable.FETCH_CATEGORIES_SUCCESS_RIGHT_ARTICLES_TABLE,
    payload: categories,
  };
};

export const categoriesLoadingShowRightArticlesTable = () => {
  return {
    type: ArticlesTable.CATEGORIES_LOADING_SHOW_RIGHT_ARTICLES_TABLE,
  };
};

export const categoriesLoadingHideRightArticlesTable = () => {
  return {
    type: ArticlesTable.CATEGORIES_LOADING_HIDE_RIGHT_ARTICLES_TABLE,
  };
};

export const resetCategoriesStateRightArticlesTable = () => {
  return {
    type: ArticlesTable.RESET_CATEGORIES_STATE_RIGHT_ARTICLES_TABLE,
  };
};

export const setSelectedOrganizationRightArticlesTable = (
  option: SelectOption | null
) => {
  return {
    type: ArticlesTable.SET_ORGANIZATION_SELECTED_RIGHT_ARTICLES_TABLE,
    payload: option,
  };
};

export const setSearchValueRightArticlesTable = (value: string) => {
  return {
    type: ArticlesTable.SET_SEARCH_VALUE_RIGHT_ARTICLES_TABLE,
    payload: value,
  };
};

export const resetOnlyTableArticlesTableState = () => {
  return {
    type: ArticlesTable.RESET_ONLY_TABLE_STATE_ARTICLES_TABLE,
  };
};

export const createCategoryRequestRightArticlesTable = (
  data: Partial<CategoryArticle>
) => {
  return {
    type: ArticlesTable.CREATE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE,
    payload: data,
  };
};

export const editCategoryRequestRightArticlesTable = (
  data: CategoryArticle
) => {
  return {
    type: ArticlesTable.EDIT_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE,
    payload: data,
  };
};

export const deleteCategoryRequestRightArticlesTable = (id: string) => {
  return {
    type: ArticlesTable.DELETE_CATEGORY_REQUEST_RIGHT_ARTICLES_TABLE,
    payload: id,
  };
};

export const editArticleRequestRightArticlesTable = (
  article: Partial<Article>
) => {
  return {
    type: ArticlesTable.EDIT_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE,
    payload: article,
  };
};

export const deleteArticleRequestRightArticlesTable = (id: string) => {
  return {
    type: ArticlesTable.DELETE_ARTICLE_REQUEST_RIGHT_ARTICLES_TABLE,
    payload: id,
  };
};

import React, { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface ModalProps {
  children: ReactNode;
}

export const ModalPortal: React.FC<ModalProps> = ({ children }) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  if (!modalRef.current) {
    modalRef.current = document.createElement('div');
    document.body.appendChild(modalRef.current);
  }

  useEffect(
    () => () => {
      if (modalRef.current) {
        document.body.removeChild(modalRef.current);
      }
    },
    []
  );

  return modalRef.current && ReactDOM.createPortal(children, modalRef.current);
};

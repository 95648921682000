import cn from 'clsx';
import DOMPurify from 'dompurify';
import { nanoid } from 'nanoid';
import React from 'react';
import { useSelector } from 'react-redux';

import { ConditionBlock } from 'components/ConditionBlock';
import { FileChip } from 'components/FileChip';
import { Solution } from 'components/Solution';
import { Typography, TypographyVariants } from 'components/Typography';
import { ParentType } from 'types/models/attachment';

import { getSimilarTicketAttachments, getTicket } from '../../ducks/selectors';

import styles from './SimilarTicketContent.module.scss';

export const SimilarTicketContent = () => {
  const ticket = useSelector(getTicket);
  const ticketAttachments = useSelector(getSimilarTicketAttachments);

  const attachments = Boolean(ticketAttachments?.length) && (
    <div className={styles.ticketInfo__attachments}>
      {ticketAttachments
        ?.find(
          (ticketAttachment) =>
            ticketAttachment.parentType === ParentType.TICKET
        )
        ?.attachmentsFiles?.map((file) => {
          return (
            <FileChip
              key={nanoid()}
              title={file.fileName}
              className={styles.ticketInfo__attachmentsItem}
              filePath={file.path}
            />
          );
        })}
    </div>
  );

  return (
    <div className={styles.similarTicketContent}>
      <ConditionBlock
        text={ticket?.ticketType?.value}
        className={styles.similarTicketContent__conditionBlock}
      />
      <Typography
        variant={TypographyVariants.h3}
        className={styles.similarTicketContent__title}
      >
        {ticket?.theme}
      </Typography>
      <p
        className={cn('ck-content', styles.similarTicketContent__description)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(ticket?.description || ''),
        }}
      />
      {attachments}
      {ticket?.solution?.textSolution && (
        <Solution text={ticket?.solution?.textSolution} withCopy />
      )}
    </div>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'components/Table';
import { System } from 'core/types';

import { TABLE_FIELDS_FROM_CONTRACTS } from '../../constants';
import { getLoading } from '../../ducks/selectors';
import { TableSystemsTypes } from '../../types';
import { SystemsFilter } from '../SystemsFilter';

import { getDataTable } from './utils';

interface Props {
  systems?: System[];
  showFilterRow?: boolean;
  sort?: string;
  handleSort?: (value: string) => void;
  handleClickSystem?: (value: string) => void;
  activeRowId?: string;
  isFilterActive?: boolean;
}

export const SystemsFromContractsTable: React.FC<Props> = ({
  systems,
  showFilterRow,
  sort,
  handleSort,
  handleClickSystem,
  activeRowId,
  isFilterActive,
}) => {
  const data = getDataTable(systems);

  const loading = useSelector(getLoading);

  return (
    <Table
      data={data}
      sort={sort}
      onSort={handleSort}
      onRowClick={handleClickSystem}
      columns={TABLE_FIELDS_FROM_CONTRACTS}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <SystemsFilter tableType={TableSystemsTypes.FROM_CONTRACTS} />
        )
      }
      disabledSort={loading}
    />
  );
};

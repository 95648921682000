import { ValueType } from 'components/Select/types';
import { OrganizationType } from 'core/types';

export type UsersFilterToRequest = {
  login?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  email?: string;
  phoneNumber?: string;
  organizationId?: string;
  idsUsersDelete?: Array<string>;
  idsWorkGroupsDelete?: Array<string>;
  fullName?: string;
  organizationType?: OrganizationType;
};

export type UsersFilter = Omit<UsersFilterToRequest, 'organizationId'> & {
  fullName?: string;
  organizationId?: ValueType<string>;
};

export enum TableUsersTypes {
  FULL = 'FULL',
  FROM_GROUPS = 'FROM_GROUPS',
  ADD_MODAL = 'ADD_MODAL',
  FROM_DESKTOP = 'FROM_DESKTOP',
}

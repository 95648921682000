import cn from 'clsx';
import React from 'react';

import { PenIcon } from 'assets/icons';
import { Button } from 'components/Button';
import { ConditionBlock } from 'components/ConditionBlock';
import { Typography, TypographyVariants } from 'components/Typography';

import { InfoTooltip } from '../InfoTooltip';

import styles from './SlaTableHeader.module.scss';

interface Props {
  onChangeSla?: () => void;
  changeDisabled?: boolean;
  workTime?: string;
  autoClosing?: string;
  updatedWorkingHours?: boolean;
  updateWorkingHoursNameAgreement?: string;
  updatedAutoClose?: boolean;
  updateAutoCloseNameAgreement?: string;
  className?: string;
  loading?: boolean;
}

export const SlaTableHeader: React.FC<Props> = ({
  onChangeSla,
  changeDisabled = true,
  workTime,
  autoClosing,
  className,
  updatedWorkingHours,
  updateWorkingHoursNameAgreement,
  updatedAutoClose,
  updateAutoCloseNameAgreement,
  loading,
}) => {
  const workTimeBlock = !!workTime && (
    <ConditionBlock
      text={
        <>
          <Typography variant={TypographyVariants.o}>{workTime}</Typography>
          <InfoTooltip
            isUpdated={updatedWorkingHours}
            updatedFrom={updateWorkingHoursNameAgreement}
          />
        </>
      }
      className={styles.slaTableHeader__conditionBlock}
    />
  );

  const autoClosingBlock = !!autoClosing && (
    <ConditionBlock
      text={
        <>
          <Typography
            variant={TypographyVariants.o}
          >{`Автозакрытие тикета: ${autoClosing}ч`}</Typography>
          <InfoTooltip
            isUpdated={updatedAutoClose}
            updatedFrom={updateAutoCloseNameAgreement}
          />
        </>
      }
      className={cn(
        styles.slaTableHeader__autoClosing,
        styles.slaTableHeader__conditionBlock
      )}
    />
  );

  const editButton = onChangeSla && (
    <Button
      appearance="flat"
      onClick={onChangeSla}
      icon={<PenIcon />}
      disabled={changeDisabled || loading}
      className={styles.slaTableHeader__change}
    >
      <span className={styles.slaTableHeader__changeTitle}>Изменить</span>
    </Button>
  );

  return (
    <div className={cn(styles.slaTableHeader, className)}>
      <div className={styles.slaTableHeader__data}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.slaTableHeader__title}
        >
          SLA
        </Typography>
        {workTimeBlock}
        {autoClosingBlock}
      </div>
      {editButton}
    </div>
  );
};

import { format } from 'date-fns';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getValueFromValueType } from 'components/Select';
import { convertKeyValueOptionToArraySelectOption } from 'core/utils';
import { getIsAdmin } from 'features/Auth/ducks/selectors';
import { checkIsDateValid } from 'utils';

import {
  DEFAULT_VALUES_NEWS,
  PUBLISHED_NEWS_PATH,
  SELECT_PUBLICATION_DATE_LIST,
  SELECT_RECIPIENT_LIST,
} from '../constants';
import {
  fetchOrganizationsRequest,
  fetchWorkGroupsRequest,
} from '../ducks/actions';
import {
  getIsEditNewsMode,
  getLoadingOptions,
  getOrganizationsOptions,
  getWorkGroupsOptions,
} from '../ducks/selectors';
import { News, NewsCreateForm, PublicationType, RecipientType } from '../types';

interface Props {
  news?: News;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useCreateNewsForm = ({ news }: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isEditMode = useSelector(getIsEditNewsMode);
  const isLoadingOptions = useSelector(getLoadingOptions);
  const organizationsOptions = useSelector(getOrganizationsOptions);
  const workGroupsOptions = useSelector(getWorkGroupsOptions);

  const isAdmin = useSelector(getIsAdmin);

  const published = pathname === PUBLISHED_NEWS_PATH;

  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    setValue,
    resetField,
    formState: { errors, isValid, isDirty },
  } = useForm<NewsCreateForm>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES_NEWS,
  });

  const { organizationsIds, publicationDate, recipient, publicationType } =
    watch();

  const isSelectivelyRecipients =
    getValueFromValueType(recipient) === RecipientType.SELECTIVELY;
  const isSelectByTime =
    getValueFromValueType(publicationType) === PublicationType.BY_TIME;
  const isSelectByCurrently =
    getValueFromValueType(publicationType) === PublicationType.CURRENTLY;
  const isAllRecipients =
    news && !news.organizations?.length && !news.workgroups?.length;

  const isEmptyOrganizationsIds =
    news && isEditMode && !isSelectivelyRecipients
      ? !news.organizations?.length
      : !organizationsIds?.length;

  const isDisabledPublicationDate = isEditMode && news?.published;

  const isDateValid = checkIsDateValid(publicationDate);

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название новости не может быть длиннее 100 символов.',
    },
  });

  useEffect(() => {
    if (isSelectivelyRecipients && !organizationsOptions.length) {
      dispatch(fetchOrganizationsRequest());
    }
  }, [isSelectivelyRecipients, organizationsOptions.length]);

  useEffect(() => {
    if (news && isEditMode) {
      setValue('title', news.title);
      setValue(
        'organizationsIds',
        convertKeyValueOptionToArraySelectOption(news.organizations)
      );
      setValue(
        'workgroupsIds',
        convertKeyValueOptionToArraySelectOption(news.workgroups)
      );
      setValue(
        'publicationDate',
        format(new Date(news?.publicationDate || ''), 'dd.MM.yyyy  HH:mm')
      );

      if (isAdmin) {
        setValue(
          'recipient',
          isAllRecipients ? SELECT_RECIPIENT_LIST[0] : SELECT_RECIPIENT_LIST[1]
        );
        setValue(
          'publicationType',
          news.publishNow
            ? SELECT_PUBLICATION_DATE_LIST[0]
            : SELECT_PUBLICATION_DATE_LIST[1]
        );
      }

      const preparedIds = news.organizations?.map(
        (organization) => organization.key
      );

      if (preparedIds?.length) {
        dispatch(fetchWorkGroupsRequest(preparedIds));
      }
    }
  }, [news, isEditMode, isAdmin, isAllRecipients]);

  return {
    methods: {
      watch,
      reset,
      resetField,
      handleSubmit,
      setValue,
    },
    state: {
      errors,
      control,
      text: news?.text,
      id: news?.id,
      titleInputOptions,
      isEditMode,
      isValid,
      isDirty,
      isEmptyOrganizationsIds,
      isLoadingOptions,
      isAdmin,
      isSelectivelyRecipients,
      isSelectByTime,
      isSelectByCurrently,
      isDateValid,
      organizationsOptions,
      workGroupsOptions,
      organizationsCurrentNews: news?.organizations,
      workGroupsCurrentNews: news?.workgroups,
      recipient,
      publicationType,
      published,
      isDisabledPublicationDate,
    },
  };
};

import cn from 'clsx';
import DOMPurify from 'dompurify';
import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  // JiraLogoIcon,
  PlusIcon,
  SearchIcon,
  System,
  UsersGroupIcon,
} from 'assets/icons';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { ConditionBlock } from 'components/ConditionBlock';
import { InfoBlock } from 'components/InfoBlock';
import { Loader } from 'components/Loader';
import { Solution } from 'components/Solution';
import { Tooltip } from 'components/Tooltip';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { TicketChip } from 'core/components/TicketChip';
import { ApproveOrCancel } from 'core/modals/ApproveOrCancel';
import { TicketStatus } from 'core/types';
import {
  getCustomerAttribute,
  getIsClient,
  getIsMyTicket,
  getIsOrgTypePartner,
  getSpecialistAttribute,
} from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { SimilarSolutions } from 'features/SimilarSolutions';
import {
  getIsAccessedTicketAction,
  // getJiraUrl,
  getTicket,
  getTicketSystem,
} from 'features/Ticket/ducks/ticket/selectors';
import { useTicketInfoTabs } from 'features/Ticket/hooks/useTicketInfoTabs';
import { AttachmentDeleteType } from 'features/Ticket/types';

import { getTicketAttachments } from '../../ducks/attachments/selectors';
import { useTicket } from '../../hooks/useTicket';
import { PreviewImageModal } from '../PreviewImageModal';
import { SpecialistForm } from '../SpecialistForm';
// import { TicketInfoDropContent } from '../TicketInfoDropContent';
import { TicketTabsBlock } from '../TicketTabsBlock';
import { TicketTags } from '../TicketTags';

import { TicketAttachments } from './components/TicketAttachments';
import { TicketCustomFieldsInfo } from './components/TicketCustomFieldsInfo';
import { SYSTEM_TICKET_INFO } from './constants';
import { useCurrentAttachment } from './hooks/useCurrentAttachment';
import styles from './TicketInfo.module.scss';
import { getAttachmentFilesInfo } from './utils';

interface Props {
  className?: string;
  isLoading?: boolean;
}

export const TicketInfo = forwardRef<HTMLDivElement, Props>(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  ({ className, isLoading }, ref) => {
    const ticket = useSelector(getTicket);
    const ticketAttachments = useSelector(getTicketAttachments);
    const isClient = useSelector(getIsClient);

    const isAccessToTakeTicketToWork = useSelector(
      getIsAccessedTicketAction(ActionList.TakeTicketToWork)
    );
    const isAccessToAssigningTicketToSpecialist = useSelector(
      getIsAccessedTicketAction(ActionList.AssigningTicketToSpecialist)
    );
    // const jiraUrl = useSelector(getJiraUrl);
    const ticketSystem = useSelector(getTicketSystem);
    const customerAttribute = useSelector(getCustomerAttribute);
    const specialistAttribute = useSelector(getSpecialistAttribute);
    const isPartner = useSelector(getIsOrgTypePartner);
    const isMyTicket = useSelector(getIsMyTicket(ticket));

    const isTicketDeleted = ticket?.isDeleted;

    const [isModal, setIsModal] = useState<boolean>(false);
    const [isImageModal, setIsImageModal] = useState<boolean>(false);
    const [initialImageIndex, setInitialImageIndex] = useState(0);
    const { attachment } = useCurrentAttachment(ticketAttachments);
    const { imageFiles, documentFiles } =
      getAttachmentFilesInfo(ticketAttachments);

    const showBtnToWork = isPartner
      ? customerAttribute && specialistAttribute && !isMyTicket
      : !isClient;

    const toggleIsModal = () => {
      setIsModal((prevState) => !prevState);
    };

    const toggleIsImageModal = () => {
      setIsImageModal((prevState) => !prevState);
    };

    const handleInitialImageIndex = (index: number) => {
      setInitialImageIndex(index);
    };

    const {
      isTakeToWorkDisabled,
      // isJiraButtonDisabled,
      setTicketToWorkHandler,
      restoreTicketHandler,
      // setTicketToWorkAndCreateTaskInJiraHandler,
      toggleRestoreModal,
      isSeeTags,
      isEditTags,
      isRestoreModal,
      isAccessToRestore,
    } = useTicket();

    const { mode, ticketInfoTabs } = useTicketInfoTabs();

    const onApproveRestoreModal = () => {
      restoreTicketHandler?.();
    };

    const solution = ticket?.customStatus?.defaultStatus ===
      TicketStatus.CLOSE &&
      ticket.solution?.textSolution && (
        <Solution
          text={ticket.solution?.textSolution}
          className={styles.ticketInfo__solution}
        />
      );

    const setSpecAndGoToWork = showBtnToWork && !isTicketDeleted && (
      <div className={styles.ticketInfo__buttonsWrapper}>
        {isAccessToTakeTicketToWork && (
          <Button
            onClick={setTicketToWorkHandler}
            disabled={isTakeToWorkDisabled}
            icon={<PlusIcon width={20} />}
            type="button"
            className={styles.ticketInfo__takeInProcess}
            // extraActionContent={
            //   !isJiraButtonDisabled ? (
            //     <TicketInfoDropContent
            //       onClick={setTicketToWorkAndCreateTaskInJiraHandler}
            //       disabled={isJiraButtonDisabled}
            //     />
            //   ) : undefined
            // }
          >
            Взять в работу
          </Button>
        )}
        {isAccessToAssigningTicketToSpecialist && <SpecialistForm />}
      </div>
    );

    const restoreButton = isTicketDeleted && (
      <Button
        onClick={toggleRestoreModal}
        type="button"
        disabled={!isAccessToRestore}
        className={styles.ticketInfo__buttonRestore}
      >
        Восстановить
      </Button>
    );

    const systemInfo = (
      <div className={styles.ticketInfo__systemInfo}>
        <div className={styles.ticketInfo__systemInfoItem}>
          <InfoBlock
            mainTitle="Система"
            info={ticket?.systemId?.value}
            icon={<System className={styles.ticketInfo__iconInner} />}
            classNameInfo={styles.ticketInfo__blockInfo}
            dataInfoIcon={SYSTEM_TICKET_INFO}
          />
          <Tooltip
            id={SYSTEM_TICKET_INFO}
            className={styles.ticketInfo__tooltipSystem}
          >
            <Typography className={styles.ticketInfo__tooltipSystemVersion}>
              {ticketSystem?.versionTitle}
            </Typography>
            <Typography>{ticketSystem?.description}</Typography>
          </Tooltip>
        </div>
        <div className={styles.ticketInfo__systemInfoItem}>
          <InfoBlock
            mainTitle="Среда"
            info={ticket?.environmentId?.value}
            icon={<UsersGroupIcon className={styles.ticketInfo__iconInner} />}
            classNameInfo={styles.ticketInfo__blockInfo}
          />
        </div>
        {/* {jiraUrl && ( */}
        {/*  <div className={styles.ticketInfo__systemInfoItem}> */}
        {/*    <InfoBlock */}
        {/*      mainTitle="Jira" */}
        {/*      info={ */}
        {/*        <a */}
        {/*          href={jiraUrl} */}
        {/*          target="_blank" */}
        {/*          className={styles.ticketInfo__jiraLink} */}
        {/*          rel="noreferrer" */}
        {/*        > */}
        {/*          {jiraUrl} */}
        {/*        </a> */}
        {/*      } */}
        {/*      icon={<JiraLogoIcon className={styles.ticketInfo__iconInner} />} */}
        {/*      classNameInfo={styles.ticketInfo__blockInfo} */}
        {/*    /> */}
        {/*  </div> */}
        {/* )} */}
      </div>
    );

    const tags = isSeeTags && <TicketTags canEditTags={isEditTags} />;

    const mentionedTickets = !!ticket?.mentionedTickets?.length && (
      <div className={styles.ticketInfo__tickets}>
        {ticket.mentionedTickets.map(({ key, value }) => (
          <TicketChip key={key} id={key} number={value} />
        ))}
      </div>
    );

    const attachments = (
      <div className={styles.ticketInfo__ticketAttachments}>
        <TicketAttachments
          toggleIsModal={toggleIsImageModal}
          handleInitialImageIndex={handleInitialImageIndex}
          attachment={attachment}
          imageFiles={imageFiles}
          documentFiles={documentFiles}
          size={Size.s}
          attachmentDeleteType={AttachmentDeleteType.INFO}
        />
      </div>
    );

    const imageModal = isImageModal && (
      <PreviewImageModal
        toggleModal={toggleIsImageModal}
        initialImageIndex={initialImageIndex}
        imageFiles={imageFiles}
      />
    );

    const ticketMainInfo = (
      <>
        <div className={styles.ticketInfo__inner}>
          <ConditionBlock text={ticket?.ticketType?.value} />
          <div className={styles.ticketInfo__titleWrapper}>
            <Typography
              variant={TypographyVariants.h3}
              className={styles.ticketInfo__title}
            >
              {ticket?.theme}
            </Typography>
            {isAccessToTakeTicketToWork && (
              <button
                onClick={toggleIsModal}
                className={styles.ticketInfo__similarSolutions}
              >
                <SearchIcon
                  className={styles.ticketInfo__similarSolutionsIcon}
                />
                <Typography
                  variant={TypographyVariants.h5}
                  className={styles.ticketInfo__similarSolutionsTitle}
                >
                  Искать похожие решения
                </Typography>
              </button>
            )}
          </div>
          <p
            className={cn('ck-content', styles.ticketInfo__text)}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(ticket?.description || ''),
            }}
          />
          {mentionedTickets}
          {imageModal}
          {attachments}
          {solution}
          {systemInfo}
          {setSpecAndGoToWork}
          {restoreButton}
        </div>
        {tags}
      </>
    );

    const content = !isLoading && (
      <>
        <TicketTabsBlock tabs={ticketInfoTabs} />
        {mode === 'info' ? ticketMainInfo : <TicketCustomFieldsInfo />}
        <SimilarSolutions isOpen={isModal} toggleIsOpen={toggleIsModal} />
      </>
    );

    const loader = isLoading && (
      <div className={styles.ticketInfo__loaderWrapper}>
        <Loader />
      </div>
    );

    return (
      <Card ref={ref} className={cn(styles.ticketInfo, className)}>
        {loader}
        {content}
        <ApproveOrCancel
          onApprove={onApproveRestoreModal}
          isModal={isRestoreModal}
          toggleModal={toggleRestoreModal}
          text={`Вы уверены, что хотите восстановить тикет ${ticket?.number}?`}
        />
      </Card>
    );
  }
);

import React from 'react';

import { Clock12, LabelIcon, QuestionIcon } from 'assets/icons';
import { Tooltip } from 'components/Tooltip';
import { Typography, TypographyVariants } from 'components/Typography';
import { SLAProgressBar } from 'features/SLA/components/SLAProgressBar';

import { KpiData } from '../../types';
import { getAllTicketsData, getTicketPersonData } from '../../utils';
import { KpiRating } from '../KpiRating';

import styles from './KpiAllTickets.module.scss';

interface Props {
  kpiData?: KpiData;
}

interface CountAndComponent {
  percent: number;
  name: string;
  component: React.ReactNode;
}

export const KpiAllTickets: React.FC<Props> = ({ kpiData }) => {
  const getAllTickets = getAllTicketsData(kpiData);
  const getTicketPercents = getTicketPersonData(getAllTickets);

  const closedWithDecisionExpire =
    kpiData?.info.closedInfo.closedWithDecisionExpire;
  const closedWithReactionExpire =
    kpiData?.info.closedInfo.closedWithReactionExpire;

  const tooltip = (!!closedWithDecisionExpire ||
    !!closedWithReactionExpire) && (
    <div>
      <QuestionIcon
        className={styles.allTicketsPanel__tooltipQuestionIcon}
        data-tip
        data-for="expire"
      />
      <Tooltip offset={{ bottom: 3 }} id="expire">
        <div className={styles.allTicketsPanel__tooltipContainer}>
          <div className={styles.allTicketsPanel__tooltipBlock}>
            <div
              className={styles.allTicketsPanel__tooltipClockIconContainerLeft}
            >
              <Clock12
                className={styles.allTicketsPanel__tooltipClockIconLeft}
              />
            </div>
            <div>
              <Typography
                variant={TypographyVariants.s2}
                className={styles.allTicketsPanel__tooltipText}
              >
                Реакция
              </Typography>
              <Typography
                variant={TypographyVariants.h4}
                className={styles.allTicketsPanel__tooltipQuantity}
              >
                {closedWithReactionExpire}
              </Typography>
            </div>
          </div>
          <div className={styles.allTicketsPanel__tooltipBlock}>
            <div
              className={styles.allTicketsPanel__tooltipClockIconContainerRight}
            >
              <Clock12
                className={styles.allTicketsPanel__tooltipClockIconRight}
              />
            </div>
            <div>
              <Typography
                variant={TypographyVariants.s2}
                className={styles.allTicketsPanel__tooltipText}
              >
                Решение
              </Typography>
              <Typography
                variant={TypographyVariants.h4}
                className={styles.allTicketsPanel__tooltipQuantity}
              >
                {closedWithDecisionExpire}
              </Typography>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );

  const firstTimeClosed = (
    <div className={styles.allTicketsPanel__field}>
      <Typography
        variant={TypographyVariants.h2}
        className={styles.allTicketsPanel__fieldQuantity}
      >
        {getAllTickets.firstTimeClosed()}
      </Typography>
      <div className={styles.allTicketsPanel__fieldContainer}>
        <Typography
          variant={TypographyVariants.o}
          className={styles.allTicketsPanel__fieldText}
        >
          С первого раза
        </Typography>
        <SLAProgressBar
          expirePercents={getTicketPercents.firstTimeClosed()}
          className={styles.allTicketsPanel__fieldProgressBar}
          classNameLoaded={styles.allTicketsPanel__fieldProgressBar_loadedFirst}
        />
      </div>
    </div>
  );

  const totalCountDeclined = (
    <div className={styles.allTicketsPanel__field}>
      <Typography
        variant={TypographyVariants.h2}
        className={styles.allTicketsPanel__fieldQuantity}
      >
        {getAllTickets.totalCountDeclined()}
      </Typography>
      <div className={styles.allTicketsPanel__fieldContainer}>
        <Typography
          variant={TypographyVariants.o}
          className={styles.allTicketsPanel__fieldText}
        >
          Отказы
        </Typography>
        <SLAProgressBar
          expirePercents={getTicketPercents.totalCountDeclined()}
          className={styles.allTicketsPanel__fieldProgressBar}
          classNameLoaded={
            styles.allTicketsPanel__fieldProgressBar_loadedRefusal
          }
        />
      </div>
    </div>
  );

  const totalCountClosedWithExpire = (
    <div className={styles.allTicketsPanel__field}>
      <Typography
        variant={TypographyVariants.h2}
        className={styles.allTicketsPanel__fieldQuantity}
      >
        {getAllTickets.totalCountClosedWithExpire()}
      </Typography>
      <div className={styles.allTicketsPanel__fieldContainer}>
        <div className={styles.allTicketsPanel__fieldTitleContainer}>
          <Typography
            variant={TypographyVariants.o}
            className={styles.allTicketsPanel__fieldText}
          >
            С просрочкой
          </Typography>
          {tooltip}
        </div>
        <SLAProgressBar
          expirePercents={getTicketPercents.totalCountClosedWithExpire()}
          className={styles.allTicketsPanel__fieldProgressBar}
          classNameLoaded={
            styles.allTicketsPanel__fieldProgressBar_loadedExpire
          }
        />
      </div>
    </div>
  );

  const ticketInfoCountComponents: CountAndComponent[] = [
    {
      percent: getTicketPercents.firstTimeClosed(),
      name: 'С первого раза',
      component: firstTimeClosed,
    },
    {
      percent: getTicketPercents.totalCountDeclined(),
      name: 'Отказы',
      component: totalCountDeclined,
    },
    {
      percent: getTicketPercents.totalCountClosedWithExpire(),
      name: 'С просрочкой',
      component: totalCountClosedWithExpire,
    },
  ].sort((a, b) => {
    const nameA = a.percent;
    const nameB = b.percent;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const allTickets = (
    <div className={styles.allTicketsPanel__content}>
      <div>
        <div className={styles.allTicketsPanel__contentIconBlock}>
          <LabelIcon className={styles.allTicketsPanel__contentIcon} />
        </div>
        <Typography
          variant={TypographyVariants.h5}
          className={styles.allTicketsPanel__contentAll}
        >
          Решено
        </Typography>
        <Typography
          variant={TypographyVariants.d2}
          className={styles.allTicketsPanel__contentAllQuantity}
        >
          {getAllTickets.totalCountClosed()}
        </Typography>
      </div>
      <div>
        {ticketInfoCountComponents.map((info) => (
          <React.Fragment key={info.name}>{info.component}</React.Fragment>
        ))}
      </div>
    </div>
  );

  return (
    <div className={styles.allTicketsPanel}>
      <Typography variant={TypographyVariants.h2}>Все тикеты</Typography>
      <div className={styles.allTicketsPanel__container}>
        {allTickets}
        {kpiData && <div className={styles.allTicketsPanel__line} />}
        {kpiData && <KpiRating kpiData={kpiData} />}
      </div>
    </div>
  );
};

import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { Tag, TagsFilter } from 'features/Ticket/types';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import { getSystemId } from '../ticket/selectors';

import {
  fetchTagsSuccessJoin,
  fetchTagsSuccessUpdate,
  getTagsByNameSuccess,
  loadingTagsHideRequest,
  loadingTagsShowRequest,
} from './actions';
import { request } from './api/requests';
import { getFilterTags, getPropsTags } from './selectors';
import {
  CreateTagRequestAction,
  FetchTagsRequestAction,
  GetTagsByNameRequestAction,
  Tags,
} from './types';

function* tagsFetch({ payload }: FetchTagsRequestAction) {
  try {
    yield put(loadingTagsShowRequest());
    const { pageNum, pageSize } = yield select(getPropsTags);
    const filter: TagsFilter = yield select(getFilterTags);
    const systemId: ReturnType<typeof getSystemId> = yield select(getSystemId);
    const tags: ResponseWithMeta<Tag[]> = yield call(
      request.fetchTags,
      pageNum,
      pageSize,
      { ...filter, systemIds: [systemId?.key || ''] }
    );
    yield put(
      payload === 'join'
        ? fetchTagsSuccessJoin(tags)
        : fetchTagsSuccessUpdate(tags)
    );
    yield put(loadingTagsHideRequest());
  } catch (e) {
    createError(e);
    yield put(loadingTagsHideRequest());
  }
}

function* tagCreate({ payload }: CreateTagRequestAction) {
  try {
    yield call(request.createTag, payload);
  } catch (e) {
    createError(e);
  }
}

function* fetchTagByName({ payload }: GetTagsByNameRequestAction) {
  try {
    const tags: Tag[] = yield call(request.fetchTagByName, payload);
    if (tags) {
      yield put(getTagsByNameSuccess(tags));
    }
  } catch (e) {
    createError(e);
  }
}

export function* tagsSaga(): Generator<StrictEffect> {
  yield takeEvery(Tags.FETCH_TAGS_REQUEST, tagsFetch);
  yield takeEvery(Tags.CREATE_TAG_REQUEST, tagCreate);
  yield takeEvery(Tags.GET_TAGS_BY_NAME_REQUEST, fetchTagByName);
}

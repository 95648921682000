import cn from 'clsx';
import React from 'react';

import { ContractStatus } from 'core/types';

import { CONTRACT_STATUS_TITLE_MAP } from '../../constants';

import styles from './ContractStatusBlock.module.scss';

export const styleContractStatus = {
  [ContractStatus.ACTIVE]: styles.contractStatus_active,
  [ContractStatus.CLOSED]: styles.contractStatus_closed,
  [ContractStatus.PLANNED]: styles.contractStatus_planned,
  [ContractStatus.NOT_SIGNED]: styles.contractStatus_notSigned,
};

interface Props {
  status?: ContractStatus;
}

export const ContractStatusBlock: React.FC<Props> = ({
  status = ContractStatus.ACTIVE,
}) => {
  return (
    <div className={cn(styles.contractStatus, styleContractStatus[status])}>
      {CONTRACT_STATUS_TITLE_MAP[status]}
    </div>
  );
};

import { isBoolean, isString } from 'lodash';

import { ACTIVE_SELECT_OPTIONS } from '../../TableFilter/constants';

import { getReverseDateFromCustomField } from './getReverseDateFromCustomField';

export const getValueFromFieldFlag = (value: string | boolean) => {
  if (isBoolean(value)) {
    return value ? ACTIVE_SELECT_OPTIONS[0] : ACTIVE_SELECT_OPTIONS[1];
  }
  return null;
};

export const getValueFromFieldText = (value?: string | boolean | null) => {
  return isString(value) ? value : '';
};

export const getValueFromFieldDate = (value?: string | boolean | null) => {
  return getReverseDateFromCustomField(getValueFromFieldText(value));
};

import { System } from 'core/types';

import { SystemFormData } from '../types';

export const getDefaultCreateSystemFormValues = (
  system: System
): SystemFormData => {
  return {
    system: {
      id: system?.id,
      title: system?.title || '',
      versionTitle: system?.versionTitle || '',
      description: system?.description || '',
      organization: {
        title: system?.organization?.title || '',
        value: system?.organization?.id || '',
      },
      index: system?.index || '',
    },
    ticketType: system?.typeList || [],
    environment: system?.environmentList || [],
  };
};

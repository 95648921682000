import { ProcedureType, TicketType, TicketTypeSettings } from 'core/types';

const checkProcedureType = (value: string): boolean => {
  return (
    ProcedureType.CONSULTATION === value || ProcedureType.INCIDENT === value
  );
};

const sortTicketTypes = (ticketTypes: TicketType[]) =>
  ticketTypes.sort((a, b) => {
    const aIsMain = checkProcedureType(a.value);
    const bIsMain = checkProcedureType(b.value);

    if (aIsMain === bIsMain) {
      return 0;
    }

    if (aIsMain) {
      return -1;
    }

    return 1;
  });

export const getPreparedTicketTypes = (
  ticketTypes: TicketType[]
): TicketTypeSettings[] =>
  sortTicketTypes(ticketTypes).map(({ id, title, value }) => {
    const isMain = checkProcedureType(value);

    return {
      ticketTypeId: id,
      active: isMain,
      title,
    };
  });

import cn from 'clsx';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CommentFile, FlagIcon, LabelIcon, TicketSmall } from 'assets/icons';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { DeleteButton } from 'core/components/DeleteButton';
import { HEADER_TYPE, NO_DATA } from 'core/constants';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { MainLayout } from 'core/layouts/MainLayout';
import { ApproveOrCancel } from 'core/modals/ApproveOrCancel';
import { ContractStatus, ContractType } from 'core/types';
import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import {
  CONTRACT_STATUS_TITLE_MAP,
  CONTRACT_TYPE_TITLE_MAP,
} from 'features/Contract/constants';
import { deleteSupplementaryAgreementDetailRequest } from 'features/SupplementaryAgreement/ducks/actions';
import {
  getLoadingSupAgDetail,
  getSupAgDetail,
} from 'features/SupplementaryAgreement/ducks/selectors';
import { getFormatDateWithoutTime } from 'utils';

import styles from './SupplementaryAgreementLayout.module.scss';

export const SupplementaryAgreementLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const supplementaryAgreement = useSelector(getSupAgDetail);
  const loading = useSelector(getLoadingSupAgDetail);
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isAccessToDeleteContract = useSelector(
    getIsAccessedAction(ActionList.DeleteEntries)
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { supplementaryAgreementId } = useParams<{
    supplementaryAgreementId: string;
  }>();

  const isMobileAll = isMobile || isMobileSmall;
  const isSupAgViewing = supplementaryAgreementId && supplementaryAgreement;

  const prepareDate =
    supplementaryAgreement &&
    getFormatDateWithoutTime(supplementaryAgreement?.endDate);

  const status =
    supplementaryAgreement &&
    CONTRACT_STATUS_TITLE_MAP[supplementaryAgreement?.status];

  const supplementaryAgreementStatusStylesMap = useMemo(() => {
    const color = {
      [ContractStatus.ACTIVE]: styles.contract__status_active,
      [ContractStatus.CLOSED]: styles.contract__status_closed,
      [ContractStatus.PLANNED]: styles.contract__status_planned,
      [ContractStatus.NOT_SIGNED]: styles.contract__status_notSigned,
    };
    return (
      isSupAgViewing &&
      supplementaryAgreement?.status &&
      color[supplementaryAgreement?.status]
    );
  }, [supplementaryAgreement?.status, isSupAgViewing]);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleDelete = () => {
    if (supplementaryAgreement) {
      dispatch(
        deleteSupplementaryAgreementDetailRequest(supplementaryAgreement)
      );
    }
  };

  const handleShowModalDelete = () => {
    setShowDeleteModal(true);
  };

  const infoBlocksConfig = useMemo(
    () => [
      {
        title: 'Статус',
        info: isSupAgViewing ? status : NO_DATA,
        icon: (
          <div
            className={cn(
              styles.contract__iconStatus,
              supplementaryAgreementStatusStylesMap
            )}
          >
            <LabelIcon />
          </div>
        ),
      },
      {
        title: 'Дата окончания',
        info: isSupAgViewing ? prepareDate : NO_DATA,
        icon: <TicketSmall className={styles.contract__icon} />,
      },
      {
        title: 'Тип',
        info: CONTRACT_TYPE_TITLE_MAP[ContractType.SUPPLEMENTARY_AGREEMENT],
        icon: <FlagIcon className={styles.contract__icon} />,
      },
    ],
    [
      supplementaryAgreementId,
      status,
      prepareDate,
      supplementaryAgreement?.type,
    ]
  );

  const breadCrumbsConfig = useMemo(
    () => [
      {
        title: `Контракт #${supplementaryAgreement?.number?.split('-')[0]}`,
        path: `/admin/contracts/${supplementaryAgreement?.mainContractId}`,
      },
      { title: `Допсоглашение #${supplementaryAgreement?.number}` },
    ],
    [supplementaryAgreement?.number]
  );

  return (
    <>
      <MainLayout
        headerType={HEADER_TYPE}
        title={
          supplementaryAgreementId
            ? supplementaryAgreement?.title
            : 'Создать допсоглашение'
        }
        titleIcon={
          <CommentFile
            className={cn(styles.contract__icon, styles.contract__iconMain)}
          />
        }
        withGoBack={isMobileAll}
        breadCrumbsConfig={
          supplementaryAgreementId ? breadCrumbsConfig : undefined
        }
        infoBlocksConfig={infoBlocksConfig}
        classNameTitleInfoBlock={styles.contract__iconWrapper}
        dropContent={
          supplementaryAgreementId ? (
            <PopoverContainer>
              <DeleteButton
                onClick={handleShowModalDelete}
                disabled={!supplementaryAgreement || !isAccessToDeleteContract}
              />
            </PopoverContainer>
          ) : undefined
        }
        loading={loading && !supplementaryAgreement}
      >
        {children}
      </MainLayout>
      <ApproveOrCancel
        onApprove={handleDelete}
        isModal={showDeleteModal}
        toggleModal={toggleDeleteModal}
        approveTitle="Удалить"
        approveBtnProps={{
          appearance: 'outline',
          className: styles.contract__iconDelete,
        }}
        cancelTitle="Отмена"
        text={`Вы уверены, что хотите удалить ${supplementaryAgreement?.title}?`}
      />
    </>
  );
};

import React from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { RadioSlide } from 'components/RadioSlide';
import { Select } from 'components/Select';
import { SelectOption } from 'components/Select/types';
import { TabItem } from 'components/types';
import { ApiClient } from 'utils';

import { CurrentStateFormData } from '../../types';

import styles from './CurrentStateForm.module.scss';

interface Props {
  control: Control<CurrentStateFormData>;
  setValue: UseFormSetValue<CurrentStateFormData>;
  managersOptions: SelectOption[];
  organizationsOptions: SelectOption[];
  systemsOptions: SelectOption[];
  radioTabs: TabItem[];
  isOrganizationType: boolean;
}

export const CurrentStateForm: React.FC<Props> = (props) => {
  const {
    control,
    setValue,
    systemsOptions,
    organizationsOptions,
    managersOptions,
    radioTabs,
    isOrganizationType,
  } = props;

  return (
    <>
      <Controller
        control={control}
        name="infoType"
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <RadioSlide
              value={field.value}
              onChange={(event) => {
                ApiClient.finishPendingRequests();
                field.onChange(event);
              }}
              items={radioTabs}
              className={styles.currentStateForm__radio}
            />
          );
        }}
      />
      {isOrganizationType ? (
        <>
          <Controller
            control={control}
            name="organizationId"
            render={({ field }) => {
              return (
                <Select<string>
                  label="Организация"
                  mobileModalTitle="организацию"
                  value={field.value}
                  options={organizationsOptions}
                  className={styles.currentStateForm__select}
                  onChange={(value) => {
                    setValue('systemId', null);
                    field.onChange(value);
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="systemId"
            render={({ field }) => {
              return (
                <Select<string>
                  label="Система"
                  mobileModalTitle="систему"
                  value={field.value}
                  className={styles.currentStateForm__select}
                  options={systemsOptions}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </>
      ) : (
        <Controller
          control={control}
          name="workGroupId"
          render={({ field }) => {
            return (
              <Select<string>
                label="Рабочая группа"
                mobileModalTitle="рабочую группу"
                value={field.value}
                className={styles.currentStateForm__select}
                options={managersOptions}
                onChange={field.onChange}
              />
            );
          }}
        />
      )}
    </>
  );
};

import cn from 'clsx';
import React from 'react';

import { Typography, TypographyVariants } from '../../../Typography';

import styles from './PopoverTitle.module.scss';

export interface Props {
  title?: string;
  className?: string;
}

export const PopoverTitle: React.FC<Props> = ({ title, className }) => {
  return (
    <Typography
      variant={TypographyVariants.o}
      className={cn(styles.popoverTitle, className)}
    >
      {title}
    </Typography>
  );
};

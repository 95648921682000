import { useEffect } from 'react';

interface Props {
  handleChangePageSize?: (size: number) => void;
  initialPageSize?: number;
  entityName?: string;
}

export const usePaginationPageSizeStored = ({
  handleChangePageSize,
  initialPageSize,
  entityName,
}: Props) => {
  const storedPageSize = entityName && localStorage.getItem(entityName);
  const currentPageSize = Number(storedPageSize) || initialPageSize;

  useEffect(() => {
    if (Number(storedPageSize) === currentPageSize) {
      handleChangePageSize?.(currentPageSize);
    }
  }, []);

  const handleChangeCurrentPageSize = (pageSize: number) => {
    handleChangePageSize?.(pageSize);
    if (entityName) {
      localStorage.setItem(entityName, String(pageSize));
    }
  };

  return { currentPageSize, handleChangeCurrentPageSize };
};

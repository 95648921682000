import cn from 'clsx';
import React, { useEffect, useState } from 'react';

import { BackArrowIcon, CloseIcon } from 'assets/icons';
import { Button } from 'components/Button';
import { IconButtonWrapper } from 'components/IconButtonWrapper';
import { Tooltip } from 'components/Tooltip';
import { ApproveOrCancel } from 'core/modals/ApproveOrCancel';

import styles from './Create.module.scss';

interface Props {
  toggleModal(): void;
  isModal: boolean;
  title: string;
  disabledSubmit?: boolean;
  disabledText?: string;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  subModalText?: string;
  className?: string;
  createTitle?: string;
  cancelTitle?: string;
  withCloseIconButton?: boolean;
  classNameBlockAdd?: string;
  classNameCancelButton?: string;
}

export const Create: React.FC<Props> = ({
  children,
  toggleModal,
  isModal,
  title,
  disabledSubmit,
  disabledText,
  onSubmit,
  subModalText,
  className,
  createTitle = 'Создать',
  cancelTitle = 'Отмена',
  withCloseIconButton = true,
  classNameBlockAdd,
  classNameCancelButton,
}) => {
  const [isSubModal, setIsSubModal] = useState<boolean>(false);

  const closeModal = () => {
    toggleModal();
  };

  const toggleSubModal = () => {
    setIsSubModal(!isSubModal);
  };

  const onApproveSubModal = () => {
    closeModal();
    toggleSubModal();
  };

  useEffect(() => {
    if (isModal || isSubModal) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = 'visible';
  }, [isModal, isSubModal]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  if (!isModal) return null;

  return (
    <>
      <div
        className={styles.create}
        onMouseDown={toggleSubModal}
        aria-hidden="true"
      >
        <div
          className={cn(styles.create__content, className)}
          onMouseDown={(e) => e.stopPropagation()}
          aria-hidden="true"
        >
          <div className={styles.create__header}>
            <IconButtonWrapper
              onClick={toggleSubModal}
              icon={<BackArrowIcon className={styles.create__arrow} />}
              className={cn(styles.create__button, styles.create__button_left)}
            />
            <h4 className={styles.create__title}>{title}</h4>
            {withCloseIconButton && (
              <IconButtonWrapper
                onClick={toggleSubModal}
                icon={<CloseIcon className={styles.create__cross} />}
                className={cn(
                  styles.create__button,
                  styles.create__button_right
                )}
              />
            )}
          </div>
          <form className={styles.create__formCreate} onSubmit={onSubmit}>
            {children}
            <div className={cn(styles.create__buttonsBlock, classNameBlockAdd)}>
              <Button
                appearance="flat"
                onClick={toggleSubModal}
                type="button"
                className={cn(
                  styles.create__cancelButton,
                  classNameCancelButton
                )}
              >
                {cancelTitle}
              </Button>
              {disabledSubmit && disabledText && (
                <Tooltip id="disabledText">{disabledText}</Tooltip>
              )}
              <div data-tip data-for="disabledText">
                <Button disabled={disabledSubmit}>{createTitle}</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ApproveOrCancel
        onApprove={onApproveSubModal}
        isModal={isSubModal}
        toggleModal={toggleSubModal}
        text={`Вы уверены, что хотите отменить ${subModalText}?`}
      />
    </>
  );
};

import cn from 'clsx';
import React from 'react';

import { PickedTime } from 'components/DatePicker/types';

import { TimePickerCellProps } from '../TimePickerCell';
import { TimePickerCell } from '../TimePickerCell/TimePickerCell';

import styles from './TimePickerCellList.module.scss';

type Props = {
  list: PickedTime[];
} & Pick<TimePickerCellProps, 'pickValue' | 'className'>;

export const TimePickerCellList: React.FC<Props> = ({
  list,
  pickValue,
  className,
  ...rest
}) => {
  return (
    <div className={cn(styles.timePickerCellList, className)}>
      {list.map(({ isActive, value, valueForList }) => (
        <TimePickerCell
          {...rest}
          key={value}
          value={value}
          valueForList={valueForList}
          pickValue={pickValue}
          isActive={isActive}
        />
      ))}
    </div>
  );
};

import { Organization } from 'core/types';

export type AccessedRoute = {
  id: number;
  name: string;
  route: string;
};

export type CurrentUser = {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  middleName?: string;
  phoneNumber?: string;
  organizationTitle?: string;
  email?: string;
  userId?: string;
  roles?: string[];
};

export type CurrentUserOrganization = {
  organizationId?: string;
  organizationTitle: string;
  hasCustomerAttribute?: boolean;
  hasSpecialistAttribute?: boolean;
  organization: Organization;
};

export enum ActionList {
  ViewingCompanyAdministration = 'Просмотр администрирования организации',
  CreateSystem = 'Создание и редактирование системы',
  CreateContract = 'Создание и редактирование контракта',
  CreateCompany = 'Создание и редактирование компании',
  TakeTicketToWork = 'Взять в работу тикет',
  AssigningTicketToSpecialist = 'Назначение тикета специалисту',
  DeleteTicket = 'Удаление тикета',
  ViewMyGroupTickets = 'Просмотр тикетов моей группы',
  CreateTicket = 'Создание тикета',
  CreateWorkGroup = 'Создание и редактирование рабочей группы',
  CreateAuthority = 'Создание и редактирование полномочия',
  CreateUser = 'Создание и редактирование пользователя',
  ViewingUserAdministration = 'Просмотр администрирования пользователей',
  CreateRole = 'Создание и редактирование роли',
  EditTicketPriority = 'Изменение приоритета тикета',
  WorkingWithMyGroupsTicketCards = 'Работа с карточками чужих тикетов в группе',
  DeleteEntries = 'Удаление записей',
  CreateReportByOrganization = 'Составление отчетов по организации клиента',
  ExportToExcel = 'Выгрузка данных в Excel',
  ViewingSettings = 'Работа с экраном настроек администратора',
  CreateKBArticleInClientOrganization = 'Создание статьи в Базе знаний клиентских организаций',
  CreateKBArticleInMyOrganization = 'Создание статьи в Базе знаний своей организации',
  AddingArticleToTheHelp = 'Добавление статьи на панель Помощь',
  ViewingAllTickets = 'Просмотр всех тикетов портала',
  EditNews = 'Создание, редактирование и удаление новостей',
  CreateStorage = 'Создание и редактирование хранилищ',
  CreateRules = 'Создание правил автоназначения тикета',
}

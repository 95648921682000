import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Clock12, LabelIcon } from 'assets/icons';
import { Typography, TypographyVariants } from 'components/Typography';
import { Info } from 'core/modals/Info';
import { TicketStatus } from 'core/types';

import { getTicket } from '../../ducks/ticket/selectors';
import { Comment } from '../../types';

import styles from './TicketSpoiler.module.scss';

interface Props {
  comment: Comment;
}

export const TicketSpoiler: React.FC<Props> = ({ comment }) => {
  const { message, spoiler } = comment;

  const ticket = useSelector(getTicket);

  const [isModal, setIsModal] = useState(false);

  const toggleModal = () => {
    setIsModal((prev) => !prev);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const spoilerText = spoiler?.text
    ?.split('.')
    .map((item) => Boolean(item.length) && <span key={item}>{item}.</span>);

  const iconSpoiler =
    ticket?.customStatus?.defaultStatus === TicketStatus.PENDING_CLOSURE ? (
      <Clock12 />
    ) : (
      <LabelIcon />
    );

  return (
    <div className={styles.ticketSpoiler}>
      {iconSpoiler}
      <Typography
        variant={TypographyVariants.b3}
        className={styles.ticketSpoiler__message}
      >
        {message}
      </Typography>
      <Typography
        variant={TypographyVariants.b3}
        onClick={toggleModal}
        role="button"
        onKeyDown={handleKeyDown}
        tabIndex={0}
        className={styles.ticketSpoiler__titleSpoiler}
      >
        {spoiler?.titleSpoiler}
      </Typography>
      <Info
        isModal={isModal}
        toggleModal={toggleModal}
        title={spoiler?.header || ''}
        cancelTitle="Продолжить"
        buttonType="default"
      >
        <div className={styles.ticketSpoiler__modal}>{spoilerText}</div>
      </Info>
    </div>
  );
};

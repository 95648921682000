import cn from 'clsx';
import React, { useEffect } from 'react';

import { Button, Props as ButtonProps } from 'components/Button';
import { Typography, TypographyVariants } from 'components/Typography';

import styles from './ApproveOrCancel.module.scss';

export interface Props {
  onApprove?(): void;
  onCancel?(): void;
  text: string | JSX.Element;
  description?: string;
  toggleModal(): void;
  isModal: boolean;
  approveTitle?: string | JSX.Element;
  cancelTitle?: string | JSX.Element;
  approveBtnProps?: ButtonProps;
  cancelBtnProps?: ButtonProps;
  withHideBodyScroll?: boolean;
  className?: string;
  classNameFormPortalCancel?: string;
}

export const ApproveOrCancel: React.FC<Props> = ({
  text,
  description,
  onApprove,
  onCancel,
  toggleModal,
  isModal,
  approveTitle = 'Да',
  cancelTitle = 'Нет',
  approveBtnProps,
  cancelBtnProps,
  withHideBodyScroll = true,
  className,
  classNameFormPortalCancel,
}) => {
  const approveHandler = (
    event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event?.stopPropagation();
    if (onApprove) {
      onApprove();
    }
    toggleModal();
  };

  const cancelHandler = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event?.stopPropagation();
    if (onCancel) {
      onCancel();
    }
    toggleModal();
  };

  const getDescription = description && (
    <Typography
      variant={TypographyVariants.b3}
      className={styles.approveOrCancel__description}
    >
      {description}
    </Typography>
  );

  useEffect(() => {
    if (withHideBodyScroll) {
      if (isModal) {
        document.body.style.overflow = 'hidden';
        return;
      }
      document.body.style.overflow = 'visible';
    }
  }, [isModal, withHideBodyScroll]);

  useEffect(() => {
    return () => {
      if (withHideBodyScroll) {
        document.body.style.overflow = 'visible';
      }
    };
  }, [withHideBodyScroll]);

  if (!isModal) return null;

  return (
    <div
      className={cn(styles.approveOrCancel, classNameFormPortalCancel)}
      onMouseDown={toggleModal}
      aria-hidden="true"
    >
      <div
        className={cn(styles.approveOrCancel__content, className)}
        onMouseDown={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <Typography
          variant={TypographyVariants.b2}
          className={styles.approveOrCancel__text}
        >
          {text}
        </Typography>
        {getDescription}
        <div className={styles.approveOrCancel__buttonsBlock}>
          <Button
            appearance="flat"
            onClick={cancelHandler}
            type="button"
            className={styles.approveOrCancel__cancelButton}
            {...cancelBtnProps}
          >
            {cancelTitle}
          </Button>
          <Button onClick={approveHandler} {...approveBtnProps}>
            {approveTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

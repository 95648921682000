import cn from 'clsx';
import { debounce } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Select } from 'components/Select';
import { MenuItem } from 'components/Select/components/MenuItem';
import {
  RenderMenuItemProps,
  SelectOption,
  ValueType,
} from 'components/Select/types';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { KBOrganizationsFilter } from 'features/KnowledgeBase/types';
import { RootState } from 'store/rootReducer';

import styles from './KBOrganizationSelect.module.scss';

export interface ValuesProps {
  searchValue?: string;
  organizationSelected?: SelectOption | null;
}

export interface SelectorProps {
  getSelectList: (state: RootState) => SelectOption[];
}

export interface ActionsProps {
  onInputFilter: (value: string) => void;
  fetchCategories: () => void;
  fetchOrganizations: (filter: KBOrganizationsFilter) => void;
  resetCategories: () => void;
  onChangeOrganization: (value: SelectOption) => void;
}
interface Props {
  clearWhenIsInputValue?: boolean;
  className?: string;
  valuesProps: ValuesProps;
  selectorProps: SelectorProps;
  actionsProps: ActionsProps;
}

export const KBOrganizationSelect: React.FC<Props> = ({
  clearWhenIsInputValue = false,
  className,
  valuesProps,
  selectorProps,
  actionsProps,
}) => {
  const {
    onInputFilter,
    fetchCategories,
    fetchOrganizations,
    resetCategories,
    onChangeOrganization,
  } = actionsProps;

  const { getSelectList } = selectorProps;
  const searchValue = valuesProps?.searchValue;
  const organizationSelected = valuesProps?.organizationSelected;

  const organizationsList = useSelector(getSelectList);

  const onFilterOrganization = debounce((valueFilter: string) => {
    onInputFilter(valueFilter);
  }, DEFAULT_DEBOUNCE_DELAY);

  const { control, setValue } = useForm<{
    organization: ValueType<string>;
  }>({
    mode: 'onChange',
  });
  useEffect(() => {
    if (searchValue && organizationSelected && clearWhenIsInputValue) {
      setValue('organization', null);
      resetCategories();
      fetchOrganizations({});
    }
  }, [searchValue, clearWhenIsInputValue]);

  useEffect(() => {
    fetchOrganizations({});
    fetchCategories();
  }, []);

  const renderItemType = ({
    onChange,
    option,
    selected,
  }: RenderMenuItemProps) => {
    return (
      <MenuItem
        onClick={onChange}
        selected={selected}
        className={styles.KBOrganizationSelect__option}
      >
        <Typography
          variant={TypographyVariants.b2}
          className={styles.KBOrganizationSelect__optionTitle}
        >
          {option.title}
        </Typography>
        <Typography
          variant={TypographyVariants.s2}
          className={styles.KBOrganizationSelect__optionType}
        >
          <Typography
            component="span"
            variant={TypographyVariants.s2}
            className={
              option?.meta === 'Админская'
                ? styles.KBOrganizationSelect__optionType_purple
                : styles.KBOrganizationSelect__optionType_cyan
            }
          >
            {`${option.meta} `}
          </Typography>
          организация
        </Typography>
      </MenuItem>
    );
  };

  return (
    <Controller
      control={control}
      name="organization"
      key="organization"
      rules={{
        required: true,
      }}
      render={() => {
        return (
          <Select<string>
            onChange={(value: SelectOption) => {
              onChangeOrganization(value);
            }}
            mobileModalTitle="организацию"
            renderMenuItem={renderItemType}
            placeholder="Организация"
            options={organizationsList}
            onChangeInput={onFilterOrganization}
            className={cn(styles.KBOrganizationSelect, className)}
            value={organizationSelected}
            isSearchable
            size={Size.s}
          />
        );
      }}
    />
  );
};

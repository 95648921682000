import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TablesLayout } from 'core/layouts/TablesLayout';
import { ContractsTableContainer } from 'features/Contracts/components/ContractsTableContainer';
import {
  fetchContractsRequest,
  resetContractsState,
} from 'features/Contracts/ducks/actions';
import { TableContractsTypes } from 'features/Contracts/types';
import { resetOrganizationState } from 'features/Organizations/ducks/actions';
import { SLATableShort } from 'features/SLA/components/SLATableShort';
import { SystemsTableContainer } from 'features/Systems/components/SystemsTableContainer';
import { resetSystemsState } from 'features/Systems/ducks/actions';
import { TableSystemsTypes } from 'features/Systems/types';

import styles from './Contracts.module.scss';
import { getSLAContracts } from './ducks/selectors';

const MainTable = () => {
  return <ContractsTableContainer tableType={TableContractsTypes.FULL} />;
};

const SubTable = () => {
  return <SystemsTableContainer tableType={TableSystemsTypes.FROM_CONTRACTS} />;
};

const SLA = () => {
  const sla = useSelector(getSLAContracts);
  return <SLATableShort slaSettings={sla?.settings} />;
};

export const Contracts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContractsRequest());
    return () => {
      dispatch(resetContractsState());
      dispatch(resetSystemsState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      classNameRoot={styles.contracts}
      SubTable={[SubTable, SLA]}
    />
  );
};

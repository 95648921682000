import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { InputDatePicker } from 'components/DatePicker/components/InputDatePicker';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { TableBodyCell } from 'components/Table/components/TableBodyCell';
import { TableFilter } from 'components/Table/components/TableFilter';
import { Size } from 'components/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { ContractStatus, ContractType } from 'core/types';
import { SELECT_TYPES_LIST } from 'features/Contract/constants';
import { OrganizationSelect } from 'features/Organizations/components/OrganizationSelect';
import {
  fetchOrganizationsAddRequest,
  setCurrentOrganizationsAddPage,
  setOrganizationsAddFilterAction,
} from 'features/Organizations/ducks/actions';
import { checkObjectIdentity } from 'utils';

import { DEFAULT_FILTER, STATUS_OPTIONS } from '../../constants';
import {
  setContractFilter,
  setCurrentContractsPage,
} from '../../ducks/actions';
import { fetchFunctions } from '../../hooks/useContractsTableContainer';
import {
  ContractsFilter as ContractsFilterType,
  TableContractsTypes,
} from '../../types';

import styles from './CotractsFilter.module.scss';

interface Props {
  tableType: TableContractsTypes;
}

export const ContractsFilter: React.FC<Props> = React.memo(({ tableType }) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, control, reset, watch } =
    useForm<ContractsFilterType>({
      mode: 'onChange',
      defaultValues: DEFAULT_FILTER,
    });

  const filterValues = watch();

  const resetFilter = () => {
    dispatch(setContractFilter(DEFAULT_FILTER));
    dispatch(fetchFunctions[tableType]());
    if (tableType === TableContractsTypes.FULL) {
      dispatch(setCurrentOrganizationsAddPage(0));
      dispatch(setOrganizationsAddFilterAction({}));
      dispatch(fetchOrganizationsAddRequest({ updateType: 'update' }));
    }
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const {
      title,
      description,
      type,
      organizationId,
      number,
      endDate,
      status,
      openDate,
    } = data;
    const preparedData = {
      title: title || undefined,
      description: description || undefined,
      organizationId: organizationId || null,
      number: number || undefined,
      type: type || null,
      endDate: endDate || '',
      openDate,
      status: status || null,
    };
    dispatch(setContractFilter(preparedData));
    dispatch(setCurrentContractsPage(0));
    dispatch(fetchFunctions[tableType]());
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });
  const descriptionToInput = register('description', {
    onChange: formSubmitHandlerDebounced,
  });
  const numberToInput = register('number', {
    onChange: formSubmitHandlerDebounced,
  });

  const dataPicker = (
    <Controller
      name="endDate"
      control={control}
      render={({ field }) => {
        return (
          <InputDatePicker
            size={Size.xs}
            type="datePicker"
            value={field.value}
            onChange={(options) => {
              field.onChange(options);
              formSubmitHandlerDebounced();
            }}
          />
        );
      }}
    />
  );

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell className={styles.contractsFilter__cell} key={item.key}>
        {item}
      </TableBodyCell>
    ));
  const filterElems = {
    [TableContractsTypes.FULL]: () => [
      <Input size={Size.xs} key="title" {...titleToInput} />,
      <Controller
        control={control}
        name="organizationId"
        key="organizationId"
        render={({ field }) => {
          return (
            <OrganizationSelect
              size={Size.xs}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              value={field.value}
            />
          );
        }}
      />,
      <Controller
        control={control}
        name="type"
        key="type"
        render={({ field }) => {
          return (
            <Select<ContractType>
              size={Size.xs}
              mobileModalTitle="тип"
              options={SELECT_TYPES_LIST}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              isTooltip={false}
            />
          );
        }}
      />,
      <Input size={Size.xs} key="description" {...descriptionToInput} />,
      <Controller
        control={control}
        name="status"
        key="status"
        render={({ field }) => {
          return (
            <Select<ContractStatus>
              size={Size.xs}
              mobileModalTitle="статус"
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              options={STATUS_OPTIONS}
              value={field.value}
              isTooltip={false}
            />
          );
        }}
      />,
      <Input size={Size.xs} type="number" key="number" {...numberToInput} />,
      dataPicker,
    ],
    [TableContractsTypes.FROM_ORGANIZATIONS]: () => [
      <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
      <Controller
        control={control}
        name="type"
        key="type"
        render={({ field }) => {
          return (
            <Select<ContractType>
              size={Size.xs}
              mobileModalTitle="тип"
              key="typeOrg"
              options={SELECT_TYPES_LIST}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              isTooltip={false}
            />
          );
        }}
      />,
      <Controller
        control={control}
        name="status"
        key="status"
        render={({ field }) => {
          return (
            <Select<ContractStatus>
              size={Size.xs}
              mobileModalTitle="статус"
              key="statusOrg"
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              options={STATUS_OPTIONS}
              value={field.value}
              isTooltip={false}
            />
          );
        }}
      />,
    ],
    [TableContractsTypes.FROM_SYSTEMS]: () => [
      <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
      <Controller
        control={control}
        name="type"
        key="type"
        render={({ field }) => {
          return (
            <Select<ContractType>
              size={Size.xs}
              mobileModalTitle="тип"
              key="statusSys"
              options={SELECT_TYPES_LIST}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              isTooltip={false}
            />
          );
        }}
      />,
      <Controller
        control={control}
        name="status"
        key="status"
        render={({ field }) => {
          return (
            <Select<ContractStatus>
              size={Size.xs}
              mobileModalTitle="статус"
              key="statusSys"
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              options={STATUS_OPTIONS}
              value={field.value}
              isTooltip={false}
            />
          );
        }}
      />,
      dataPicker,
    ],
  };

  const filter = (
    <>
      {tableBodyWrapper(filterElems[tableType as keyof typeof filterElems]())}
    </>
  );

  const disableReset = checkObjectIdentity(filterValues, DEFAULT_FILTER);

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RadioPriority } from 'components/RadioPriority';
import { Toast } from 'components/Toast';
import { Create } from 'core/modals/Create';
import { Priority } from 'core/types';
import {
  fetchSystemPrioritiesRequest,
  resetSystemPriorities,
} from 'features/Systems/ducks/actions';
import { getPriorityRadioTabs } from 'features/Systems/ducks/selectors';
import {
  editTicketPriority,
  setIsError,
  setIsSuccess,
} from 'features/Ticket/ducks/ticket/actions';
import {
  getIsError,
  getIsSuccess,
  getTicket,
} from 'features/Ticket/ducks/ticket/selectors';

interface Props {
  isModal: boolean;
  toggleModal(): void;
}

export const TicketPriorityEdit: React.FC<Props> = ({
  isModal,
  toggleModal,
}) => {
  const closeModal = () => {
    toggleModal();
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, isValid },
  } = useForm<{ priority: Priority }>({
    mode: 'onChange',
  });

  const { priority } = watch();

  const radioPriorityTabs = useSelector(getPriorityRadioTabs);
  const ticket = useSelector(getTicket);
  const isError = useSelector(getIsError);
  const isSuccess = useSelector(getIsSuccess);

  const dispatch = useDispatch();

  useEffect(() => {
    if (ticket?.systemId?.key && isModal) {
      dispatch(fetchSystemPrioritiesRequest(ticket?.systemId?.key));
    }
  }, [ticket?.systemId?.key, isModal]);

  useEffect(() => {
    return () => {
      dispatch(resetSystemPriorities());
    };
  }, []);

  useEffect(() => {
    if (ticket?.priority) {
      setValue('priority', ticket?.priority);
    }
  }, [ticket, isModal]);

  const priorityRadioOptions = register('priority', { required: true });

  const formSubmitHandler = handleSubmit((data) => {
    if (ticket?.id) {
      dispatch(
        editTicketPriority({ priority: data.priority, ticketId: ticket?.id })
      );
    }
    closeModal();
    reset();
  });

  const handleCloseSuccessToast = () => {
    dispatch(setIsSuccess(false));
  };

  const handleCloseErrorToast = () => {
    dispatch(setIsError(false));
  };

  const errorToast = isError && (
    <Toast
      type="error"
      title="Что-то пошло не так..."
      onClose={handleCloseErrorToast}
    />
  );

  const successToast = isSuccess && (
    <Toast title="Приоритет тикета изменен" onClose={handleCloseSuccessToast} />
  );

  return (
    <>
      <Create
        toggleModal={toggleModal}
        isModal={isModal && !ticket?.isDeleted}
        title="Приоритет"
        createTitle="Применить"
        cancelTitle="Отменить"
        onSubmit={formSubmitHandler}
        disabledSubmit={!isDirty || !isValid || priority === ticket?.priority}
        subModalText="изменение приоритета"
      >
        <RadioPriority items={radioPriorityTabs} {...priorityRadioOptions} />
      </Create>
      {errorToast}
      {successToast}
    </>
  );
};

import cn from 'clsx';
import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowLeftIcon, ClockFat, TimeAndDateIcon } from 'assets/icons';
import { Card } from 'components/Card';
import { Loader } from 'components/Loader';
import { Typography, TypographyVariants } from 'components/Typography';
import { DataEmpty } from 'core/components/DataEmpty';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { getFormatDate } from 'utils';

import { fetchCurrentNewsSuccess } from '../../ducks/actions';
import { News } from '../../types';

import styles from './NewsDetail.module.scss';

interface Props {
  news?: News;
  loading?: boolean;
  className?: string;
}

export const NewsDetail: React.FC<Props> = ({ news, loading, className }) => {
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);

  const dispatch = useDispatch();

  const isMobileAll = isMobile || isMobileSmall;

  const goBack = () => {
    dispatch(fetchCurrentNewsSuccess(undefined));
  };

  useEffect(() => {
    if (isMobileAll) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = 'visible';
  }, [isMobileAll]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const created = !!news?.created && getFormatDate(news?.created);

  const content = !loading && !!news && (
    <>
      <div className={styles.newsDetail__header}>
        <TimeAndDateIcon className={styles.newsDetail__icon} />
        <div className={styles.newsDetail__infoWrapper}>
          <Typography
            variant={TypographyVariants.h3}
            className={styles.newsDetail__title}
          >
            {news?.title}
          </Typography>
          <div className={styles.newsDetail__timeWrapper}>
            <ClockFat className={styles.newsDetail__timeIcon} />
            <span className={styles.newsDetail__time}>{created}</span>
          </div>
        </div>
      </div>
      <div
        className={cn('ck-content', styles.newsDetail__text)} // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(news?.text || ''),
        }}
      />
    </>
  );

  const loader = loading && <Loader className={styles.newsDetail__loader} />;

  const emptyData = !loading && !news && (
    <DataEmpty className={styles.newsDetail__dataEmpty} />
  );

  if (isMobileAll) {
    return (
      <div className={cn(styles.newsDetail, className)}>
        <Typography
          variant={TypographyVariants.h3}
          className={styles.newsDetail__mobileHeader}
        >
          <button onClick={goBack} className={styles.newsDetail__backButton}>
            <ArrowLeftIcon />
          </button>
          Новость
        </Typography>
        <div className={styles.newsDetail__mobileContent}>{content}</div>
      </div>
    );
  }

  return (
    <Card
      className={cn(
        styles.newsDetail,
        { [styles.newsDetail_loading]: loading },
        className
      )}
    >
      {content}
      {loader}
      {emptyData}
    </Card>
  );
};

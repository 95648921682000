import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { OrganizationType } from 'core/types';
import {
  getIsAccessedAction,
  getUserOrganization,
} from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';

import { getIsAccessEditCategoryOrArticle } from '../utils';

export const useIsAccessEditCategoryOrArticle = (
  id?: string,
  type?: OrganizationType
) => {
  const currentUserOrganization = useSelector(getUserOrganization);
  const isAccessCreateArticleInClientOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateKBArticleInClientOrganization)
  );
  const isAccessCreateArticleInMyOrganization = useSelector(
    getIsAccessedAction(ActionList.CreateKBArticleInMyOrganization)
  );

  return useMemo(
    () =>
      getIsAccessEditCategoryOrArticle({
        myOrganizationId: currentUserOrganization?.organizationId,
        currentOrganizationId: id,
        currentOrganizationType: type,
        isAccessCreateArticleInClientOrganization,
        isAccessCreateArticleInMyOrganization,
      }),
    [
      currentUserOrganization?.organizationId,
      id,
      type,
      isAccessCreateArticleInClientOrganization,
      isAccessCreateArticleInMyOrganization,
    ]
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { DeleteButton } from 'core/components/DeleteButton';
import { getCurrentContract } from 'features/Contracts/ducks/selectors';

import styles from './ContractsDropContent.module.scss';

interface Props {
  handleDelete: () => void;
}

export const ContractsDropContent: React.FC<Props> = ({ handleDelete }) => {
  const contract = useSelector(getCurrentContract);

  return (
    <div className={styles.contractsDropContent}>
      <div className={styles.contractsDropContent__buttonWrapper}>
        <PopoverContainer>
          <DeleteButton onClick={handleDelete} disabled={!contract} />
        </PopoverContainer>
      </div>
    </div>
  );
};

import React from 'react';

import { getChangeButtonProps } from 'components/Table';
import { TableContainer } from 'components/Table/components/TableContainer';
import { entityNames } from 'core/constants';
import { useEnvironmentTableContainer } from 'features/Contract/hooks/useEnvironmentTableContainer';

import { Environment } from './components/Environment';
import { EnvironmentTable } from './components/EnvironmentTable';
import styles from './EnvironmentTableContainer.module.scss';

interface Props {
  customHook?: () => ReturnType<typeof useEnvironmentTableContainer>;
}

export const EnvironmentTableContainer: React.FC<Props> = ({ customHook }) => {
  const hook = customHook || useEnvironmentTableContainer;
  const { methods, state } = hook();
  const {
    toggleFilterRow,
    handleSort,
    prepareHandler,
    handleChangePage,
    handleChangePageSize,
    toggleModal,
    prepareCreateTitle,
  } = methods;
  const {
    searchDisabled,
    isCreateDisabled,
    pageNum,
    pageSize,
    sort,
    environments,
    showFilterRow,
    isFilterActive,
    isModal,
    totalElements,
    loading,
  } = state;

  const getTableComponent = () => (
    <EnvironmentTable
      showFilterRow={showFilterRow}
      environments={environments}
      handleSort={handleSort}
      sort={sort}
      isFilterActive={isFilterActive}
    />
  );

  const modal = isModal && (
    <Environment isModal={isModal} toggleModal={toggleModal} />
  );

  return (
    <TableContainer
      classNameRoot={styles.environmentTableContainer}
      headerProps={{
        title: 'Среды',
        createHandler: prepareHandler(),
        createDisabled: isCreateDisabled,
        createTitle: prepareCreateTitle(),
        searchDisabled,
        toggleSearch: toggleFilterRow,
        icon: getChangeButtonProps(0).icon,
        isSearchOpen: showFilterRow,
        loading,
      }}
      contentTable={
        <>
          {getTableComponent()}
          {modal}
        </>
      }
      countRecordsProps={{
        records: totalElements,
      }}
      paginationProps={{
        pageNum,
        elementsCount: totalElements,
        pageSize,
        handleChangePage,
        handleChangePageSize,
        entityName: entityNames.ENVIRONMENT,
      }}
    />
  );
};

import Highcharts, { SeriesPieOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

import { getCurrentOptions } from './utils';

interface Props {
  data?: SeriesPieOptions['data'];
  dataAdditional?: SeriesPieOptions['data'];
  lineChartElement?: JSX.Element;
}

export const LineChart: React.FC<Props> = ({
  data,
  dataAdditional,
  lineChartElement,
}) => {
  const currentOptions = getCurrentOptions(dataAdditional, data);

  return (
    <>
      {lineChartElement}
      <HighchartsReact highcharts={Highcharts} options={currentOptions} />
    </>
  );
};

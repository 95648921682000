import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'components/Input';
import { TableBodyCell } from 'components/Table/components/TableBodyCell';
import { TableFilter } from 'components/Table/components/TableFilter';
import { Size } from 'components/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { SystemsFilter } from 'core/types';
import {
  fetchSystemsByContractIdRequestContractDetail,
  setFilterSystemsByContractIdContractDetail,
  setPageSystemsByContractIdContractDetail,
} from 'features/Contract/ducks/actions';
import { getSystemsTransfer } from 'features/Contract/ducks/selectors';
import { INITIAL_SYSTEMS_FILTER } from 'features/Systems/constants';
import { checkObjectIdentity } from 'utils';

import styles from './SystemsFilterContractDetail.module.scss';

interface Props {
  contractId?: string;
}

export const SystemsFilterContractDetail: React.FC<Props> = React.memo(
  ({ contractId }) => {
    const dispatch = useDispatch();

    const { isTransfer } = useSelector(getSystemsTransfer);

    const { register, handleSubmit, reset, watch } = useForm<SystemsFilter>({
      mode: 'onChange',
      defaultValues: INITIAL_SYSTEMS_FILTER,
    });

    const filterValues = watch();

    const resetFilter = () => {
      dispatch(setFilterSystemsByContractIdContractDetail({}));
      dispatch(
        fetchSystemsByContractIdRequestContractDetail({
          id: contractId,
          withDeletedSystems: true,
        })
      );
      reset();
    };

    const formSubmitHandler = handleSubmit((data) => {
      const { title, description, organizationId, versionTitle } = data;
      const preparedData = {
        title: title || undefined,
        description: description || undefined,
        organizationId: organizationId || undefined,
        versionTitle: versionTitle || undefined,
      };
      dispatch(setFilterSystemsByContractIdContractDetail(preparedData));
      dispatch(setPageSystemsByContractIdContractDetail(0));
      dispatch(
        fetchSystemsByContractIdRequestContractDetail({
          id: contractId,
          withDeletedSystems: true,
        })
      );
    });

    const formSubmitHandlerDebounced = useMemo(
      () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
      []
    );

    const titleToInput = register('title', {
      onChange: formSubmitHandlerDebounced,
    });
    const descriptionToInput = register('description', {
      onChange: formSubmitHandlerDebounced,
    });

    const tableBodyWrapper = (elements: JSX.Element[]) =>
      elements.map((item) => (
        <TableBodyCell className={styles.systemsFilter__cell} key={item.key}>
          {item}
        </TableBodyCell>
      ));

    const filterElements = [
      <Input
        size={Size.xs}
        type="title"
        key="titleContract"
        {...titleToInput}
      />,
      <Input
        size={Size.xs}
        type="description"
        key="descriptionContract"
        {...descriptionToInput}
      />,
    ];

    const filter = <>{tableBodyWrapper(filterElements)}</>;

    const disableReset = checkObjectIdentity(filterValues, {}) || isTransfer;

    return (
      <TableFilter
        filterComponent={filter}
        onReset={resetFilter}
        disableReset={disableReset}
        withCheckbox={!isTransfer}
      />
    );
  }
);

import React, { memo, useMemo } from 'react';

import { ChartBlock } from 'components/ChartBlock';

import { NO_DATA_GRAPH } from '../../constants';
import { TicketsStatistic } from '../../types';

interface Props {
  className?: string;
  ticketsStatistic?: TicketsStatistic;
}

export const PieChartStatus: React.FC<Props> = memo(
  ({ className, ticketsStatistic }) => {
    const dataGraph = useMemo(
      () =>
        ticketsStatistic?.status
          ?.map((el) => {
            return {
              name: el.name,
              y: Number(el.count) || 0,
              color: `var(--${el.color})`,
            };
          })
          .filter((data) => data.y)
          ?.sort((a, b) => {
            const valueDifference = Number(b.y) - Number(a.y);
            if (valueDifference > 0 || valueDifference < 0) {
              return valueDifference;
            }
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          }),
      [ticketsStatistic?.status]
    );

    const currentDataGraph = dataGraph?.length ? dataGraph : NO_DATA_GRAPH;

    return (
      <ChartBlock
        chartType="pieChart"
        title="Активные тикеты по статусам"
        dataChart={currentDataGraph}
        className={className}
      />
    );
  }
);

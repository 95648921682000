import DOMPurify from 'dompurify';
import React from 'react';

import { Card } from 'components/Card';

import { Article } from '../../types';

import { KBArticleViewingSearch } from './components/KBArticleViewingSearch';
import styles from './KBArticle.module.scss';

interface Props {
  article?: Article;
}

export const KBArticle: React.FC<Props> = ({ article }) => {
  return (
    <div className={styles.KBArticle__wrapper}>
      <Card className={styles.KBArticle}>
        <div
          className="ck-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(article?.text || ''),
          }}
        />
      </Card>
      <KBArticleViewingSearch className={styles.KBArticle__search} />
    </div>
  );
};

import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'components/Button';
import { ConditionBlock } from 'components/ConditionBlock';
import { ContractStatus } from 'core/types';
import { contractSignRequestContractDetail } from 'features/Contract/ducks/actions';

import styles from './ContractSign.module.scss';

interface Props {
  status?: ContractStatus;
  contractId: string;
}

export const ContractSign: React.FC<Props> = ({ status, contractId }) => {
  const dispatch = useDispatch();

  const onSign = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(contractSignRequestContractDetail(contractId));
  };

  return status === ContractStatus.NOT_SIGNED ? (
    <Button
      appearance="outline"
      className={styles.contractSign__btn}
      onClick={onSign}
    >
      Подписать
    </Button>
  ) : (
    <ConditionBlock text="Подписан" className={styles.contractSign__block} />
  );
};

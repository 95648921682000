import {
  convertValueTypeToArrayValue,
  convertValueTypeToValue,
} from 'components/Select';
import { SystemsFilter, SystemsFilterToRequest } from 'core/types';

export const getFilterSystemToRequest = (
  filter: SystemsFilter
): SystemsFilterToRequest => {
  const { organizationId, environmentIds } = filter;

  return {
    ...filter,
    organizationId: convertValueTypeToValue(organizationId),
    environmentIds: convertValueTypeToArrayValue(environmentIds),
  };
};

import cn from 'clsx';
import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'components/Input';
import { Loader } from 'components/Loader';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { fetchSystemsForAddingToContractRequestContractDetail } from 'features/Contract/ducks/actions';
import {
  getSystemsForAddingContractDetail,
  getSystemsForAddingToContractLoadingContractDetail,
} from 'features/Contract/ducks/selectors';

import styles from './SystemSelection.module.scss';

interface Props {
  selectedSystem?: string;
  setSelectedSystem?: (value: string) => void;
}

export const SystemSelection: React.FC<Props> = ({
  selectedSystem,
  setSelectedSystem,
}) => {
  const dispatch = useDispatch();

  const systemsList = useSelector(getSystemsForAddingContractDetail);
  const loading = useSelector(
    getSystemsForAddingToContractLoadingContractDetail
  );

  const { register, watch } = useForm<{ title?: string }>({
    mode: 'onChange',
  });

  const { title } = watch();

  const onFilterSystems = useMemo(
    () =>
      debounce((value?: string) => {
        dispatch(fetchSystemsForAddingToContractRequestContractDetail(value));
      }, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const inputSearch = register('title', {
    onChange: (e) => {
      onFilterSystems(e.target.value);
    },
  });

  const systemsListComponent = (
    <ul>
      {systemsList?.map((item) => (
        <li
          key={item.id}
          aria-hidden
          className={cn(styles.systemSelection__system, {
            [styles.systemSelection__system_active]:
              selectedSystem === item?.id,
          })}
          onClick={() => setSelectedSystem && setSelectedSystem(item?.id)}
        >
          {item.title}
        </li>
      ))}
      {!systemsList?.length && title && (
        <span className={styles.systemSelection__noResult}>
          Нет результатов
        </span>
      )}
    </ul>
  );

  return (
    <div className={styles.systemSelection}>
      <Typography
        variant={TypographyVariants.h4}
        className={styles.systemSelection__header}
      >
        Выберите систему
      </Typography>
      <Input
        size={Size.s}
        label="Поиск"
        className={styles.systemSelection__input}
        {...inputSearch}
      />
      {loading && <Loader classNameRoot={styles.systemSelection__loader} />}
      {!loading && systemsListComponent}
    </div>
  );
};

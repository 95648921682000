import cn from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, TypographyVariants } from 'components/Typography';
import { TicketStatus } from 'core/types';
import { getTicketStatusTitleMap } from 'features/Ticket/constants';
import { Languages } from 'i18n/types';

import styles from './StatusBlock.module.scss';

const statusStylesMap = {
  [TicketStatus.NEW]: styles.statusBlock_new,
  [TicketStatus.WORK]: styles.statusBlock_work,
  [TicketStatus.CLOSE]: styles.statusBlock_close,
  [TicketStatus.PENDING_CLOSURE]: styles.statusBlock_pendingClosure,
  [TicketStatus.WAITING_INFO]: styles.statusBlock_waitingInfo,
};

interface Props {
  status?: TicketStatus;
  color?: string;
  label?: string;
  className?: string;
}

export const StatusBlock: React.FC<Props> = ({
  status,
  className,
  label,
  color,
}) => {
  const { i18n } = useTranslation();
  const style =
    color && !status ? { backgroundColor: `var(--${color})` } : undefined;

  return (
    <Typography
      variant={TypographyVariants.o}
      className={cn(
        styles.statusBlock,
        status ? statusStylesMap[status] : '',
        className
      )}
      style={style}
    >
      {status
        ? getTicketStatusTitleMap(i18n.language as Languages)[status]
        : label}
    </Typography>
  );
};

import cn from 'clsx';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { CheckMarkIcon, CloseIcon } from 'assets/icons';
import { Input } from 'components/Input';
import { PopoverButton } from 'components/Popover/components/PopoverButton';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { PopoverItemWrapper } from 'components/Popover/components/PopoverItemWrapper';
import { PopoverLine } from 'components/Popover/components/PopoverLine';
import { PopoverTitle } from 'components/Popover/components/PopoverTitle';
import { getValueFromValueType, Select } from 'components/Select';
import { Size } from 'components/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import {
  CategoryArticle,
  CategoryArticleCreateForm,
} from 'features/KnowledgeBase/types';

import {
  fetchOrganizationsRequestArticleCreate,
  setOrganizationsFilterArticleCreate,
} from '../../../../views/KBCreateArticle/ducks/actions';
import { getArticleOrganizationsSelectList } from '../../../../views/KBCreateArticle/ducks/selectors';

import styles from './KBCreateCategory.module.scss';

interface Props {
  onClick?: (data: Partial<CategoryArticle>) => void;
  togglePopover?: () => void;
}

export const KBCreateCategory: React.FC<Props> = ({
  onClick,
  togglePopover,
}) => {
  const dispatch = useDispatch();

  const organizationsOptions = useSelector(getArticleOrganizationsSelectList);

  const [orgInput, setOrgInput] = useState<string | undefined>();

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { isValid },
  } = useForm<CategoryArticleCreateForm>({
    mode: 'onChange',
  });

  const toggleCreatePopover = () => {
    reset();
    if (togglePopover) {
      togglePopover();
    }
  };

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название категории не может быть длиннее 100 символов.',
    },
  });

  const formSubmitHandler = handleSubmit((data) => {
    if (onClick) {
      const { organizationId, title } = data;
      const organizationValue = getValueFromValueType(organizationId);
      const prepareData = {
        title,
        organizationId: organizationValue || '',
      };
      onClick(prepareData);
    }
    toggleCreatePopover();
  });

  const onFilterOrganization = debounce((valueFilter: string) => {
    setOrgInput(valueFilter);
    dispatch(setOrganizationsFilterArticleCreate(valueFilter));
    dispatch(fetchOrganizationsRequestArticleCreate());
  }, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    dispatch(fetchOrganizationsRequestArticleCreate());
  }, []);

  return (
    <PopoverContainer className={styles.KBCreateCategory}>
      <PopoverTitle title="Создать категорию" />
      <PopoverItemWrapper>
        <Input
          {...titleInputOptions}
          size={Size.xs}
          label="Названиe"
          name="title"
        />
      </PopoverItemWrapper>
      <PopoverItemWrapper>
        <Controller
          control={control}
          name="organizationId"
          key="organizationId"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <Select<string>
                size={Size.xs}
                onChange={field.onChange}
                value={field.value}
                options={organizationsOptions}
                isSearchable
                inputValue={orgInput}
                onChangeInput={onFilterOrganization}
                placeholder="Организация"
                mobileModalTitle="организацию"
              />
            );
          }}
        />
      </PopoverItemWrapper>
      <PopoverLine />
      <div
        className={cn(
          styles.KBCreateCategory__inner,
          styles.KBCreateCategory__buttons
        )}
      >
        <PopoverButton
          type="submit"
          icon={<CheckMarkIcon className={styles.KBCreateCategory__icon} />}
          onClick={formSubmitHandler}
          disabled={!isValid}
        >
          Создать
        </PopoverButton>
        <PopoverButton
          icon={<CloseIcon className={styles.KBCreateCategory__icon} />}
          onClick={toggleCreatePopover}
        >
          Отмена
        </PopoverButton>
      </div>
    </PopoverContainer>
  );
};

import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Input } from 'components/Input';
import { TableBodyCell } from 'components/Table/components/TableBodyCell';
import { TableFilter } from 'components/Table/components/TableFilter';
import { Size } from 'components/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { OrganizationSelect } from 'features/Organizations/components/OrganizationSelect';
import {
  fetchOrganizationsAddRequest,
  setCurrentOrganizationsAddPage,
  setOrganizationsAddFilterAction,
} from 'features/Organizations/ducks/actions';
import { checkObjectIdentity } from 'utils';

import { INITIAL_RESPONSIBILITIES_FILTER } from '../../constants';
import { ResponsibilityFilter, TableResponsibilitiesTypes } from '../../types';

import styles from './ResponsibilitiesFilter.module.scss';

interface Props {
  tableType: TableResponsibilitiesTypes;
  onFilter: (data: ResponsibilityFilter) => void;
}

export const ResponsibilitiesFilter: React.FC<Props> = ({
  tableType,
  onFilter,
}) => {
  const { register, handleSubmit, reset, watch, control } =
    useForm<ResponsibilityFilter>({
      mode: 'onChange',
      defaultValues: INITIAL_RESPONSIBILITIES_FILTER,
    });
  const filterValues = watch();
  const dispatch = useDispatch();

  const resetFilter = () => {
    onFilter({});
    if (tableType === TableResponsibilitiesTypes.FULL) {
      dispatch(setCurrentOrganizationsAddPage(0));
      dispatch(setOrganizationsAddFilterAction({}));
      dispatch(fetchOrganizationsAddRequest({ updateType: 'update' }));
    }
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { title, description, organizationId } = data;
    const preparedData = {
      title: title || undefined,
      description: description || undefined,
      organizationId: organizationId || undefined,
    };
    onFilter(preparedData);
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    [onFilter]
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });
  const descriptionToInput = register('description', {
    onChange: formSubmitHandlerDebounced,
  });

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell
        className={styles.responsibilitiesFilter__cell}
        key={item.key}
      >
        {item}
      </TableBodyCell>
    ));

  const filterElements = {
    [TableResponsibilitiesTypes.FULL]: () => [
      <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
      <Input
        size={Size.xs}
        type="description"
        key="description"
        {...descriptionToInput}
      />,
      <Controller
        control={control}
        name="organizationId"
        key="organizationId"
        render={({ field }) => {
          return (
            <OrganizationSelect
              size={Size.xs}
              onChange={(value) => {
                field.onChange(value);
                formSubmitHandler();
              }}
              value={field.value}
            />
          );
        }}
      />,
    ],
    [TableResponsibilitiesTypes.ADD_MODAL]: () => [
      <div />,
      <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
      <Input
        size={Size.xs}
        type="description"
        key="descriptions"
        {...descriptionToInput}
      />,
    ],
  };

  const filter = (
    <>
      {tableBodyWrapper(
        filterElements[tableType as keyof typeof filterElements]()
      )}
    </>
  );

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
};

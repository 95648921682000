import cn from 'clsx';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { InfoQuestionMarkIcon } from 'assets/icons';
import { Card } from 'components/Card';
import { InputDatePicker } from 'components/DatePicker/components/InputDatePicker';
import { regExpDate } from 'components/DatePicker/constants';
import { Input } from 'components/Input';
import { Radio } from 'components/Radio';
import { Select } from 'components/Select';
import { TextArea } from 'components/TextArea';
import { Tooltip } from 'components/Tooltip';
import { Typography, TypographyVariants } from 'components/Typography';
import { SUPPORT_RADIO_TABS } from 'core/constants';
import { ContractType, SupportType } from 'core/types';
import { SELECT_TYPES_LIST } from 'features/Contract/constants';
import { getSupportType } from 'features/Contract/ducks/selectors';
import { CreateContractData } from 'features/Contract/types';
import { OrganizationSelect } from 'features/Organizations/components/OrganizationSelect';
import { INITIAL_TYPE_OPTION } from 'features/SupplementaryAgreement/constants';
import { getNumberForCreateRequestSupAgDetail } from 'features/SupplementaryAgreement/ducks/actions';
import {
  getSupAgDetail,
  getTitleForCreateSupAg,
} from 'features/SupplementaryAgreement/ducks/selectors';
import {
  endAfterDateContract,
  endAfterStart,
  startAfterDateContract,
} from 'features/SupplementaryAgreement/utils';

import { FormDataSupAgType } from '../CreateSupAgWrapper/CreateSupAgWrapper';

import styles from './SupplementaryAgreementForm.module.scss';

interface Props {
  className?: string;
  isCreateMode?: boolean;
  contractId?: string;
  onChange?: (data: FormDataSupAgType) => void;
}

type ErrorsType = {
  openDate?: string;
  endDate?: string;
};

export const SupplementaryAgreementForm: React.FC<Props> = ({
  className,
  isCreateMode = false,
  contractId,
  onChange,
}) => {
  const dispatch = useDispatch();
  const supplementaryAgreement = useSelector(getSupAgDetail);
  const numberSupAgForCreate = useSelector(getTitleForCreateSupAg);
  const contractSupportType = useSelector(getSupportType);
  const supportType =
    contractSupportType || supplementaryAgreement?.supportType;

  useEffect(() => {
    if (isCreateMode && contractId) {
      dispatch(getNumberForCreateRequestSupAgDetail(contractId));
    }
  }, [contractId]);

  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<CreateContractData>({
    mode: 'onChange',
    defaultValues: {
      openDate: '',
      endDate: '',
      supportType: SupportType.EXTENDED,
    },
  });
  const { openDate, endDate, description } = watch();

  const organizationIdValue = {
    value: supplementaryAgreement?.organization?.id || '',
    title: supplementaryAgreement?.organization?.title || '',
  };

  useEffect(() => {
    if (isCreateMode) {
      setValue('number', numberSupAgForCreate);
    }
    if (!isCreateMode && supplementaryAgreement) {
      setValue('number', supplementaryAgreement?.number || '');
      setValue(
        'openDate',
        format(new Date(supplementaryAgreement.openDate), 'dd.MM.yyyy')
      );
      setValue(
        'endDate',
        format(new Date(supplementaryAgreement.endDate), 'dd.MM.yyyy')
      );
      setValue('description', supplementaryAgreement.description);
    }
    setValue('supportType', supportType);
  }, [supplementaryAgreement, isCreateMode, numberSupAgForCreate, supportType]);

  const [dateErrors, setDateErrors] = useState<ErrorsType>({
    openDate: undefined,
    endDate: undefined,
  });

  useEffect(() => {
    if (isCreateMode) {
      const openDateErrorTitle = startAfterDateContract(
        openDate,
        supplementaryAgreement?.openDate
      );
      const endDateErrorTitle =
        endAfterStart(openDate, endDate) ||
        endAfterDateContract(endDate, supplementaryAgreement?.endDate);
      setDateErrors({
        openDate: openDateErrorTitle,
        endDate: endDateErrorTitle,
      });
    }
  }, [openDate, endDate, supplementaryAgreement?.endDate, isCreateMode]);

  const numberInputOptions = register('number');

  const descriptionTextAreaOptions = register('description', {
    maxLength: {
      value: 255,
      message: 'Описание допсоглашения не может быть длиннее 255-ти символов.',
    },
  });

  const isValidData =
    isValid && isDirty && !dateErrors.endDate && !dateErrors.openDate;

  useEffect(() => {
    if (onChange) {
      onChange({
        isValid: isValidData,
        data: { openDate, endDate, description },
      });
    }
  }, [isValidData, openDate, endDate, description, onChange]);

  const supportRadioTabs = SUPPORT_RADIO_TABS.filter(
    (tab) => tab.value === supportType
  );

  const supportItems = supportRadioTabs.map(({ title, value, ...other }) => ({
    title,
    value,
    icon: (
      <>
        <InfoQuestionMarkIcon
          data-tip
          data-for={value}
          className={styles.supplementaryAgreementForm__icon}
        />
        <Tooltip
          id={value}
          className={styles.supplementaryAgreementForm__tooltip}
        >
          {other.description}
        </Tooltip>
      </>
    ),
  }));

  return (
    <div className={cn(styles.supplementaryAgreementForm, className)}>
      <Card className={styles.supplementaryAgreementForm__formHeader}>
        <Typography
          variant={TypographyVariants.h4}
          className={styles.supplementaryAgreementForm__formHeaderTab}
        >
          Информация
        </Typography>
      </Card>
      <Card className={styles.supplementaryAgreementForm__formContent}>
        <div className={styles.supplementaryAgreementForm__formInputData}>
          <div className={styles.supplementaryAgreementForm__formUpperPart}>
            <Input
              {...numberInputOptions}
              label="Номер договора"
              disabled
              error={!!errors.number}
              errorMessage={errors.number?.message}
              className={cn(
                styles.supplementaryAgreementForm__input,
                styles.supplementaryAgreementForm__input_oneOfThree
              )}
            />
            <OrganizationSelect
              placeholder="Организация"
              className={cn(
                styles.supplementaryAgreementForm__input,
                styles.supplementaryAgreementForm__input_oneOfThree
              )}
              value={organizationIdValue}
              disabled
            />
            <Select<ContractType>
              label="Тип"
              mobileModalTitle="тип"
              disabled
              value={INITIAL_TYPE_OPTION}
              options={SELECT_TYPES_LIST}
              className={cn(
                styles.supplementaryAgreementForm__input,
                styles.supplementaryAgreementForm__input_oneOfThree
              )}
              isTooltip={false}
            />
          </div>
          <div className={styles.supplementaryAgreementForm__formLowerPart}>
            <Controller
              control={control}
              name="openDate"
              rules={{
                required: true,
                pattern: {
                  value: regExpDate,
                  message: 'Некорректный формат даты',
                },
              }}
              render={({ field }) => {
                return (
                  <InputDatePicker
                    type="datePicker"
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Дата начала"
                    disabled={!isCreateMode}
                    error={errors.openDate || Boolean(dateErrors.openDate)}
                    errorMessage={
                      errors.openDate?.message || dateErrors.openDate
                    }
                    className={cn(
                      styles.supplementaryAgreementForm__input,
                      styles.supplementaryAgreementForm__input_oneOfThree
                    )}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="endDate"
              rules={{
                required: true,
                pattern: {
                  value: regExpDate,
                  message: 'Некорректный формат даты',
                },
              }}
              render={({ field }) => {
                return (
                  <InputDatePicker
                    type="datePicker"
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Дата окончания"
                    disabled={!isCreateMode}
                    error={errors.endDate || Boolean(dateErrors.endDate)}
                    errorMessage={errors.endDate?.message || dateErrors.endDate}
                    className={cn(
                      styles.supplementaryAgreementForm__input,
                      styles.supplementaryAgreementForm__input_oneOfThree
                    )}
                  />
                );
              }}
            />
          </div>
        </div>
        <TextArea
          {...descriptionTextAreaOptions}
          label="Описание"
          disabled={!isCreateMode}
          error={!!errors.description}
          errorMessage={errors.description?.message}
          className={styles.supplementaryAgreementForm__textarea}
        />

        <div className={styles.supplementaryAgreementForm__radio}>
          <Typography variant={TypographyVariants.h5}>Тип поддержки</Typography>

          <Controller
            name="supportType"
            control={control}
            render={({ field }) => {
              return (
                <Radio
                  className={styles.supplementaryAgreementForm__radioGroup}
                  disabled
                  value={field.value}
                  items={supportItems}
                />
              );
            }}
          />
        </div>
      </Card>
    </div>
  );
};

import { Edge, Node } from 'reactflow';

import { ValueType } from 'components/Select/types';
import { TicketStatus } from 'core/types';

export type SystemsFilterToRequest = {
  title?: string;
  organizationId?: string;
};

export type SystemsFilter = Omit<SystemsFilterToRequest, 'organizationId'> & {
  organizationId?: ValueType<string>;
};

export enum Accessibility {
  CLIENT = 'CLIENT',
  SPECIALIST = 'SPECIALIST',
  ALL = 'ALL',
}

export type NodeType = {
  label: string;
  jumpCondition?: string[];
  color: string;
  accessibility: Accessibility;
  isNotifications: boolean;
  isSLA: boolean;
  isComment: boolean;
  commentTypes?: string[];
  deletable?: boolean;
  modified?: boolean;
  hideTopHandle?: boolean;
  hideBottomHandle?: boolean;
  hideLeftHandle?: boolean;
  hideRightHandle?: boolean;
  defaultStatus?: TicketStatus;
};

export type EdgeType = {
  protected?: boolean;
};

export interface StatusForm extends NodeType {}

export type Workflow = {
  systemId: string;
  systemTitle?: string;
  version?: number;
  nodes: Node<NodeType>[];
  edges: Edge<EdgeType>[];
  typeId?: string;
};

export interface WorkflowParams
  extends Pick<Workflow, 'typeId' | 'systemId' | 'version'> {}

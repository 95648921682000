import { createSelector } from 'reselect';

import { DEFAULT_CUSTOM_FIELD_VALUES } from 'core/constants';
import { StorageCustomField } from 'core/types';
import { RootState } from 'store/rootReducer';

import { FileCardCustomFields } from '../types';

export const getStorage = (state: RootState) => state.storage.storage;
export const getStorageFiles = (state: RootState) => state.storage.storageFiles;
export const getCurrentFileCard = (state: RootState) =>
  state.storage.currentFileCard;
export const getFileCardCustomFields = (state: RootState) =>
  state.storage.currentFileCard?.fileCardCustomFields || [];
export const getDownloadedFiles = (state: RootState) =>
  state.storage.downloadedFiles || [];
export const getStoragePageNum = (state: RootState) => state.storage.pageNum;
export const getStoragePageSize = (state: RootState) => state.storage.pageSize;
export const getLoading = (state: RootState) => state.storage.loading;
export const getStorageTitle = (state: RootState) =>
  state.storage.storage?.title || '';
export const getStorageDescription = (state: RootState) =>
  state.storage.storage?.description || '';
export const getUploadingFiles = (state: RootState) =>
  state.storage.uploadingFiles;
export const getCustomFields = (state: RootState) =>
  state.storage.storage?.customFields || [];

export const getCustomFieldsWithValue = createSelector<
  RootState,
  StorageCustomField[],
  FileCardCustomFields[],
  StorageCustomField[]
>(
  [getCustomFields, getFileCardCustomFields],
  (customFields, fileCardCustomFields) => {
    return customFields?.reduce<StorageCustomField[]>(
      (acc, { id, ...other }) => {
        const valueCustomField = fileCardCustomFields.find(
          (item) => item.customFieldId === id
        );
        const result = [...acc];

        if (valueCustomField) {
          const { text, flag, date } = valueCustomField;
          return [...result, { text, flag, date, id, ...other }];
        }

        return [...result, { DEFAULT_CUSTOM_FIELD_VALUES, id, ...other }];
      },
      []
    );
  }
);

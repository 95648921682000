import { FetchRequestArgs } from 'components/SearchSelect/types';
import {
  CreateSettingFields,
  SettingFields,
} from 'components/Table/components/SettingFields/types';
import { Action, OrganizationType, TicketType } from 'core/types';
import { Ticket } from 'features/Ticket/types';
import {
  CreateFilterRequest,
  DeleteFilterRequest,
  FetchStatuses,
  FetchTickets,
  FilterData,
  SetCurrentFilterTitleRequest,
  TicketsFilterForRender,
  TicketsFilterForRequest,
  TicketTabType,
} from 'features/Tickets/types';
import { UsersFilterToRequest } from 'features/Users/types';
import { ResponseWithMeta } from 'store/types';

export enum Tickets {
  FETCH_TICKETS_SUCCESS = 'TICKETS/FETCH_TICKETS_SUCCESS',
  FETCH_TICKETS_REQUEST = 'TICKETS/FETCH_TICKETS_REQUEST',
  SET_CURRENT_TICKET_SUCCESS = 'TICKETS/SET_CURRENT_TICKET_SUCCESS',
  FETCH_TICKET_REQUEST = 'TICKETS/FETCH_TICKET_REQUEST',
  TICKETS_LOADING_SHOW = 'TICKETS/TICKETS_LOADING_SHOW',
  TICKETS_LOADING_HIDE = 'TICKETS/TICKETS_LOADING_HIDE',
  TICKET_LOADING_SHOW = 'TICKETS/TICKET_LOADING_SHOW',
  TICKET_LOADING_HIDE = 'TICKETS/TICKET_LOADING_HIDE',
  TOGGLE_TICKETS_SEARCH = 'TICKETS/TOGGLE_TICKETS_SEARCH',
  SET_CURRENT_TICKETS_PAGE = 'TICKETS/SET_CURRENT_TICKETS_PAGE',
  SET_SORT_TICKETS = 'TICKETS/SET_SORT_TICKETS',
  SET_FILTER_TICKETS = 'TICKETS/SET_FILTER_TICKETS',
  SET_SIZE_PAGE = 'TICKETS/SET_SIZE_PAGE',
  ADD_SPEC_AND_SET_TO_WORK = 'TICKETS/ADD_SPEC_AND_SET_TO_WORK',
  FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_SUCCESS = 'TICKETS/FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_SUCCESS',
  FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_REQUEST = 'TICKETS/FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_REQUEST',
  RESET_TICKETS_STATE = 'TICKETS/RESET_TICKETS_STATE',
  UPDATE_TICKET_BY_ID_SUCCESS = 'TICKETS/UPDATE_TICKET_BY_ID_SUCCESS',
  UPDATE_TICKET_BY_ID_REQUEST = 'TICKETS/UPDATE_TICKET_BY_ID_REQUEST',
  SET_CURRENT_TICKET_ID = 'TICKETS/SET_CURRENT_TICKET_ID',
  FETCH_ACTIONS_FOR_CURRENT_TICKET_REQUEST = 'TICKETS/FETCH_ACTIONS_FOR_CURRENT_TICKET_REQUEST',
  FETCH_ACTIONS_FOR_CURRENT_TICKET_SUCCESS = 'TICKETS/FETCH_ACTIONS_FOR_CURRENT_TICKET_SUCCESS',
  DELETE_TICKET_REQUEST = 'TICKETS/DELETE_TICKET_REQUEST',
  RESET_CURRENT_TICKET = 'TICKETS/RESET_CURRENT_TICKET',
  FETCH_STATUSES_REQUEST = 'TICKETS/FETCH_STATUSES_REQUEST',
  FETCH_STATUSES_SUCCESS = 'TICKETS/FETCH_STATUSES_SUCCESS',
  FETCH_FILTERS_DATA_REQUEST = 'FETCH_FILTERS_DATA_REQUEST',
  FETCH_FILTERS_DATA_SUCCESS = 'FETCH_FILTERS_DATA_SUCCESS',
  CREATE_FILTER_REQUEST = 'CREATE_FILTER_REQUEST',
  DELETE_FILTER_REQUEST = 'DELETE_FILTER_REQUEST',
  SET_CURRENT_FILTER_TITLE_REQUEST = 'SET_CURRENT_FILTER_TITLE_REQUEST',
  SET_IS_USED_FILTER = 'SET_IS_USED_FILTER',
  SET_CURRENT_FILTER_ID = 'SET_CURRENT_FILTER_ID',
  RESET_FILTERS_STATE = 'RESET_FILTERS_STATE',
  OPEN_SETTING_FIELDS = 'OPEN_SETTING_FIELDS',
  FETCH_TICKETS_SETTING_FIELDS_REQUEST = 'TICKETS/FETCH_TICKETS_SETTING_FIELDS_REQUEST',
  FETCH_TICKETS_SETTING_FIELD_SUCCESS = 'TICKETS/FETCH_TICKETS_SETTING_FIELD_SUCCESS',
  FETCH_ACTUAL_TICKETS_SETTING_FIELDS_REQUEST = 'TICKETS/FETCH_ACTUAL_TICKETS_SETTING_FIELDS_REQUEST',
  CREATE_TICKETS_SETTING_FIELDS_REQUEST = 'TICKETS/CREATE_TICKETS_SETTING_FIELDS_REQUEST',
  CREATE_TICKETS_SETTING_FIELD_SUCCESS = 'TICKETS/CREATE_TICKETS_SETTING_FIELD_SUCCESS',
  FETCH_TICKET_TYPES_REQUEST = 'TICKETS/FETCH_TICKET_TYPES_REQUEST',
  FETCH_TICKET_TYPES_SUCCESS_JOIN = 'TICKETS/FETCH_TICKET_TYPES_SUCCESS_JOIN',
  FETCH_TICKET_TYPES_SUCCESS_UPDATE = 'TICKETS/FETCH_TICKET_TYPES_SUCCESS_UPDATE',
  RESET_TICKET_TYPES = 'TICKETS/RESET_TICKET_TYPES',
  SET_SIZE_TYPE_PAGE = 'TICKETS/SET_SIZE_TYPE_PAGE',
  TYPE_LOADING_SHOW = 'TICKETS/TYPE_LOADING_SHOW',
  TYPE_LOADING_HIDE = 'TICKETS/TYPE_LOADING_HIDE',
  FETCH_TICKET_STATUS_SUCCESS_UPDATE = 'TICKETS/FETCH_TICKET_STATUS_SUCCESS_UPDATE',
  FETCH_TICKET_STATUS_SUCCESS_JOIN = 'TICKETS/FETCH_TICKET_STATUS_SUCCESS_JOIN',
  RESET_TICKET_STATUS = 'TICKETS/RESET_TICKET_STATUS',
  SET_SIZE_STATUS_PAGE = 'TICKETS/SET_SIZE_STATUS_PAGE',
  FETCH_TICKET_STATUS_REQUEST = 'TICKETS/FETCH_TICKET_STATUS_REQUEST',
}

export interface ShowTicketsLoadingAction {
  type: Tickets.TICKETS_LOADING_SHOW;
}

export interface HideTicketsLoadingAction {
  type: Tickets.TICKETS_LOADING_HIDE;
}

export interface ToggleTicketsSearchAction {
  type: Tickets.TOGGLE_TICKETS_SEARCH;
}

export interface ShowTicketLoadingAction {
  type: Tickets.TICKET_LOADING_SHOW;
}

export interface HideTicketLoadingAction {
  type: Tickets.TICKET_LOADING_HIDE;
}

export interface FetchTicketsRequestAction {
  type: Tickets.FETCH_TICKETS_REQUEST;
  payload: FetchTickets;
}

export interface FetchTicketsSuccessAction {
  type: Tickets.FETCH_TICKETS_SUCCESS;
  payload: ResponseWithMeta<Ticket[]>;
}

export interface FetchOpenTicketsCountByContactIdRequestAction {
  type: Tickets.FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_REQUEST;
  payload: string[];
}

export interface FetchOpenTicketsCountByContactIdSuccessAction {
  type: Tickets.FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_SUCCESS;
  payload: number;
}

export interface FetchTicketRequestAction {
  type: Tickets.FETCH_TICKET_REQUEST;
  payload: string;
}

export interface SetCurrentTicketSuccessAction {
  type: Tickets.SET_CURRENT_TICKET_SUCCESS;
  payload?: Ticket;
}

export interface SetCurrentTicketPageAction {
  type: Tickets.SET_CURRENT_TICKETS_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: Tickets.SET_SIZE_PAGE;
  payload: number;
}

export interface SetSortTicketsAction {
  type: Tickets.SET_SORT_TICKETS;
  payload: string;
}

export interface SetTicketsFilterAction {
  type: Tickets.SET_FILTER_TICKETS;
  payload?: TicketsFilterForRender;
}

export interface AddSpecAndSetToWorkPayload {
  ticketId: string[];
  specialistId?: string;
  withTicketsUpdate?: boolean;
  isTicketNew?: boolean;
}

export interface AddSpecAndSetToWorkAction {
  type: Tickets.ADD_SPEC_AND_SET_TO_WORK;
  payload: AddSpecAndSetToWorkPayload;
}

export interface ResetTicketsStateAction {
  type: Tickets.RESET_TICKETS_STATE;
}

export interface UpdateTicketByIdSuccessAction {
  type: Tickets.UPDATE_TICKET_BY_ID_SUCCESS;
  payload: Ticket;
}

export interface UpdateTicketByIdRequestAction {
  type: Tickets.UPDATE_TICKET_BY_ID_REQUEST;
  payload: string;
}

export interface SetCurrentTicketId {
  type: Tickets.SET_CURRENT_TICKET_ID;
  payload?: string;
}

export interface FetchActionsForCurrentTicketRequestAction {
  type: Tickets.FETCH_ACTIONS_FOR_CURRENT_TICKET_REQUEST;
  payload: string[];
}

export interface FetchActionsForCurrentTicketSuccessAction {
  type: Tickets.FETCH_ACTIONS_FOR_CURRENT_TICKET_SUCCESS;
  payload: Action[];
}

export interface DeleteTicketRequestAction {
  type: Tickets.DELETE_TICKET_REQUEST;
  payload: Ticket;
}

export interface CreateTicketInJiraPayload {
  ticketId: string;
  systemId: string;
  userId: string;
}

export interface ResetCurrentTicketAction {
  type: Tickets.RESET_CURRENT_TICKET;
}

export interface FetchStatusesRequestAction {
  type: Tickets.FETCH_STATUSES_REQUEST;
  payload: FetchStatuses;
}

export interface FetchStatusesSuccessAction {
  type: Tickets.FETCH_STATUSES_SUCCESS;
  payload: string[];
}

export interface FetchFiltersDataRequestAction {
  type: Tickets.FETCH_FILTERS_DATA_REQUEST;
  payload: TicketTabType;
}

export interface FetchFiltersDataSuccessAction {
  type: Tickets.FETCH_FILTERS_DATA_SUCCESS;
  payload: FilterData[];
}

export interface CreateFilterRequestAction {
  type: Tickets.CREATE_FILTER_REQUEST;
  payload: CreateFilterRequest;
}

export interface DeleteFilterRequestAction {
  type: Tickets.DELETE_FILTER_REQUEST;
  payload: DeleteFilterRequest;
}

export interface SetCurrentFilterTitleRequestAction {
  type: Tickets.SET_CURRENT_FILTER_TITLE_REQUEST;
  payload: SetCurrentFilterTitleRequest;
}

export interface SetCurrentFilterIdAction {
  type: Tickets.SET_CURRENT_FILTER_ID;
  payload: string;
}

export interface SetIsUsedFilterAction {
  type: Tickets.SET_IS_USED_FILTER;
  payload: boolean;
}

export interface ResetFiltersStateAction {
  type: Tickets.RESET_FILTERS_STATE;
}

export interface FetchTicketsPayload
  extends FetchRequestArgs<TicketsFilterForRequest> {
  filterValues?: TicketsFilterForRequest;
  ticketTab?: TicketTabType;
}

export interface OpenSettingFieldsAction {
  type: Tickets.OPEN_SETTING_FIELDS;
  payload: boolean;
}

export interface FetchTicketsSettingFieldsRequestAction {
  type: Tickets.FETCH_TICKETS_SETTING_FIELDS_REQUEST;
}

export interface FetchTicketsSettingFieldsSuccessAction {
  type: Tickets.FETCH_TICKETS_SETTING_FIELD_SUCCESS;
  payload: SettingFields;
}

export interface FetchActualTicketsSettingFieldsRequestAction {
  type: Tickets.FETCH_ACTUAL_TICKETS_SETTING_FIELDS_REQUEST;
}

export interface CreateTicketsSettingFieldsRequestAction {
  type: Tickets.CREATE_TICKETS_SETTING_FIELDS_REQUEST;
  payload: CreateSettingFields;
}

export interface CreateTicketsSettingFieldsSuccessAction {
  type: Tickets.CREATE_TICKETS_SETTING_FIELD_SUCCESS;
  payload: SettingFields;
}

export interface TicketsTypesPayload
  extends FetchRequestArgs<UsersFilterToRequest> {
  organizationType?: OrganizationType;
}

export interface FetchTicketTypesRequestAction {
  type: Tickets.FETCH_TICKET_TYPES_REQUEST;
  payload: TicketsTypesPayload;
}

export interface ShowTypesLoadingAction {
  type: Tickets.TYPE_LOADING_SHOW;
}

export interface HideTypesLoadingAction {
  type: Tickets.TYPE_LOADING_HIDE;
}

export interface TicketsStatusFilterForRender extends TicketsFilterForRender {
  searchValue?: string;
}

export interface TicketStatusPayload
  extends FetchRequestArgs<TicketsStatusFilterForRender> {}

export interface FetchTicketStatusRequestAction {
  type: Tickets.FETCH_TICKET_STATUS_REQUEST;
  payload: TicketStatusPayload;
}

export interface FetchTicketTypesSuccessJoinAction {
  type: Tickets.FETCH_TICKET_TYPES_SUCCESS_JOIN;
  payload: ResponseWithMeta<TicketType[]>;
}

export interface FetchTicketTypesSuccessUpdateAction {
  type: Tickets.FETCH_TICKET_TYPES_SUCCESS_UPDATE;
  payload: ResponseWithMeta<TicketType[]>;
}

export interface ResetTicketTypeStateAction {
  type: Tickets.RESET_TICKET_TYPES;
}
export interface SetSizePageTypeAction {
  type: Tickets.SET_SIZE_TYPE_PAGE;
  payload: number;
}

export interface FetchTicketStatusSuccessJoinAction {
  type: Tickets.FETCH_TICKET_STATUS_SUCCESS_JOIN;
  payload: ResponseWithMeta<string[]>;
}

export interface FetchTicketStatusSuccessUpdateAction {
  type: Tickets.FETCH_TICKET_STATUS_SUCCESS_UPDATE;
  payload: ResponseWithMeta<string[]>;
}

export interface ResetTicketStatusStateAction {
  type: Tickets.RESET_TICKET_STATUS;
}
export interface SetSizePageStatusAction {
  type: Tickets.SET_SIZE_STATUS_PAGE;
  payload: number;
}

export type TicketsActionsTypes =
  | FetchTicketsSuccessAction
  | SetCurrentTicketSuccessAction
  | ToggleTicketsSearchAction
  | SetCurrentTicketPageAction
  | SetSizePageAction
  | SetSortTicketsAction
  | SetTicketsFilterAction
  | FetchOpenTicketsCountByContactIdSuccessAction
  | UpdateTicketByIdSuccessAction
  | SetCurrentTicketId
  | FetchActionsForCurrentTicketSuccessAction
  | ResetCurrentTicketAction
  | FetchStatusesSuccessAction
  | FetchFiltersDataRequestAction
  | FetchFiltersDataSuccessAction
  | CreateFilterRequestAction
  | DeleteFilterRequestAction
  | SetCurrentFilterIdAction
  | SetIsUsedFilterAction
  | SetCurrentFilterTitleRequestAction
  | ResetFiltersStateAction
  | OpenSettingFieldsAction
  | FetchTicketsSettingFieldsSuccessAction
  | CreateTicketsSettingFieldsSuccessAction
  | FetchTicketTypesSuccessJoinAction
  | FetchTicketTypesSuccessUpdateAction
  | SetSizePageTypeAction
  | FetchTicketStatusRequestAction
  | FetchTicketStatusSuccessUpdateAction
  | FetchTicketStatusSuccessJoinAction;

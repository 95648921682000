import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TablesLayout } from 'core/layouts/TablesLayout';
import { AccessesTableContainer } from 'features/Accesses/components/AccessesTableContainer';
import { resetAccessesState } from 'features/Accesses/ducks/actions';
import { ActionsTableContainer } from 'features/Actions/components/ActionsTableContainer';
import { resetActionsState } from 'features/Actions/ducks/actions';

import { ResponsibilitiesTableContainer } from './components/ResponsibilitiesTableContainer';
import {
  fetchResponsibilitiesRequest,
  resetResponsibilitiesState,
} from './ducks/actions';
import styles from './Responsibilities.module.scss';
import { TableResponsibilitiesTypes } from './types';

const SubTableFirst = () => {
  return <AccessesTableContainer />;
};

const SubTableSecond = () => {
  return <ActionsTableContainer />;
};

const MainTable = () => {
  return (
    <ResponsibilitiesTableContainer
      tableType={TableResponsibilitiesTypes.FULL}
    />
  );
};

export const Responsibilities = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchResponsibilitiesRequest());
    return () => {
      dispatch(resetResponsibilitiesState());
      dispatch(resetAccessesState());
      dispatch(resetActionsState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubTableFirst, SubTableSecond]}
      classNameRoot={styles.responsibilities}
    />
  );
};

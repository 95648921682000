import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps, useLocation } from 'react-router-dom';

import { setIsModal } from 'features/UserAssistance/ducks';
import { getIsModal } from 'features/UserAssistance/ducks/selectors';
import { ApiClient } from 'utils';

export const CustomRoute = (props: RouteProps) => {
  const location = useLocation();

  const dispatch = useDispatch();

  const isModal = useSelector(getIsModal);

  useEffect(() => {
    return () => {
      ApiClient.finishPendingRequests();
    };
  }, [location?.pathname]);

  useEffect(() => {
    return () => {
      if (isModal) {
        dispatch(setIsModal(false));
      }
    };
  }, [location?.pathname, isModal]);

  return <Route {...props} />;
};

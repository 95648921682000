import React from 'react';

import { Contract as ContractContent } from 'features/Contract';

import { ContractLayout } from '../../layouts/ContractLayout';

import styles from './Contract.module.scss';

const Contract = () => {
  return (
    <ContractLayout>
      <ContractContent className={styles.contract__content} />
    </ContractLayout>
  );
};

export default Contract;

import { SelectOption } from 'components/Select/types';
import { Tab } from 'components/Tabs/types';
import { RouterHref } from 'routes/routerHref';

import {
  NewsCreateForm,
  PublicationType,
  RecipientType,
  WorkGroupsData,
} from './types';

export const PUBLISHED_NEWS_PATH = `${RouterHref.ProfileNews}/published-news`;
export const UNPUBLISHED_NEWS_PATH = `${RouterHref.ProfileNews}/unpublished-news`;

export const DEFAULT_NEWS_TAB: Tab[] = [
  {
    to: PUBLISHED_NEWS_PATH,
    title: 'Новости',
  },
  {
    to: UNPUBLISHED_NEWS_PATH,
    title: 'Отложенные новости',
  },
];

export const DEFAULT_VALUES_WORK_GROUPS_FORM: WorkGroupsData = {
  workGroupsIds: [],
  withNotification: false,
};

export const SELECT_RECIPIENT_LIST: SelectOption<RecipientType>[] = [
  {
    title: 'Отправить всем',
    value: RecipientType.ALL,
  },
  {
    title: 'Выборочно',
    value: RecipientType.SELECTIVELY,
  },
];

export const SELECT_PUBLICATION_DATE_LIST: SelectOption<PublicationType>[] = [
  {
    title: 'Сейчас',
    value: PublicationType.CURRENTLY,
  },
  {
    title: 'Ко времени',
    value: PublicationType.BY_TIME,
  },
];

export const DEFAULT_VALUES_NEWS: NewsCreateForm = {
  title: '',
  text: '',
  organizationsIds: [],
  workgroupsIds: [],
  publicationDate: '',
  recipient: SELECT_RECIPIENT_LIST[0],
  publicationType: SELECT_PUBLICATION_DATE_LIST[0],
};

import cn from 'clsx';
import React, { FC, useRef, useState } from 'react';
import MaskedInput from 'react-input-mask';

import { Size } from 'components/types';
import { containsNumbers } from 'utils';

import { InputDate } from '../InputDate/InputDate';

import styles from './InputDatePicker.module.scss';
import { InputProps } from './types';

export const InputDatePicker: FC<InputProps> = (props) => {
  const {
    size = Size.m,
    disabled,
    errorMessage,
    className,
    classNameErrorMessage,
    classNameContainer,
    onChange,
    value,
    placeholder,
    error,
    showTime,
    showRange,
    alwaysShowMask,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isErrorRange, setIsErrorRange] = useState<boolean>(false);

  const isPlaceholderMinimized = isFocused || value;

  const handleReset = () => {
    setIsFocused(false);
    onChange('');
  };

  const errorWarning = errorMessage && (
    <span
      className={cn(
        styles.inputDatePicker__errorMessage,
        styles[`input-date-picker__error-message_${size}`],
        {
          [styles.inputDatePicker__errorMargin]: errorMessage,
          [styles[`input-date-picker__error-margin_${size}`]]: errorMessage,
        },
        classNameErrorMessage
      )}
    >
      {errorMessage}
    </span>
  );

  const getMask = () => {
    if (showRange) {
      if (showTime) {
        return '99.99.9999 - 99.99.9999  99:99';
      }
      return '99.99.9999 - 99.99.9999';
    }
    if (showTime) {
      return '99.99.9999  99:99';
    }
    return '99.99.9999';
  };

  const handleChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event || {};
    if (target) {
      onChange(target.value);
    }
  };

  const input = (
    <MaskedInput
      inputRef={inputRef}
      className={cn(
        styles.inputDatePicker__input,
        styles[`input-date-picker__input_${size}`]
      )}
      value={value}
      onChange={handleChange}
      mask={getMask()}
      alwaysShowMask={alwaysShowMask}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  );

  return (
    <div className={cn(styles.inputDatePicker, className)}>
      <div
        className={cn(
          styles.inputDatePicker__content,
          styles[`input-date-picker__content_${size}`],
          {
            [styles.inputDatePicker__content_disabled]: disabled,
            [styles.inputDatePicker__content_error]: error || isErrorRange,
          }
        )}
      >
        <div
          className={cn(styles.inputDatePicker__typography, {
            [styles.inputDatePicker__typography_filled]: isPlaceholderMinimized,
          })}
        >
          <div
            className={cn(
              styles.inputDatePicker__placeholder,
              styles[`input-date-picker__placeholder_${size}`],
              {
                [styles.inputDatePicker__placeholder_minimized]:
                  isPlaceholderMinimized,
                [styles[`input-date-picker__placeholder_minimized_${size}`]]:
                  isPlaceholderMinimized,
              }
            )}
          >
            {placeholder}
          </div>
          {input}
        </div>
        <div ref={ref} className={styles.inputDatePicker__iconsContainer}>
          <InputDate
            containerRef={ref}
            classNameContainer={classNameContainer}
            isErrorRange={isErrorRange}
            showCloseIcon={containsNumbers(value)}
            setIsErrorRange={setIsErrorRange}
            onReset={handleReset}
            {...props}
          />
        </div>
      </div>
      {errorWarning}
    </div>
  );
};

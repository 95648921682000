import cn from 'clsx';
import React, { useState } from 'react';

import { StarIcon } from 'assets/icons';

import styles from './RatingStars.module.scss';

interface Props {
  className?: string;
  rating: number;
  onChangeRating(rating: number): void;
  disabled?: boolean;
}

export const RatingStars: React.FC<Props> = ({
  className,
  rating,
  onChangeRating,
  disabled,
}) => {
  const [hover, setHover] = useState(0);

  return (
    <div className={cn(styles.ratingStars, className)}>
      {[...Array(5)].map((star, index) => {
        const isActive = index < (hover || rating);

        return (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={styles.ratingStars__button}
            onClick={() => onChangeRating(index + 1)}
            onMouseEnter={() => setHover(index + 1)}
            onMouseLeave={() => setHover(rating)}
            disabled={disabled}
          >
            <StarIcon
              className={cn(styles.ratingStars__icon, {
                [styles.ratingStars__icon_disabled]: disabled,
                [styles.ratingStars__icon_active]: isActive,
              })}
            />
          </button>
        );
      })}
    </div>
  );
};

import { ValueType } from 'components/Select/types';

export type WorkGroupFilterToRequest = {
  title?: string;
  organizationId?: string;
  description?: string;
  idsWorkGroupsDelete?: Array<string>;
};

export type WorkGroupFilter = Omit<
  WorkGroupFilterToRequest,
  'organizationId'
> & {
  organizationId?: ValueType<string>;
};

export enum TableWorkGroupsTypes {
  FULL = 'FULL',
  FROM_ORGANIZATIONS = 'FROM_ORGANIZATIONS',
  ADD_GROUPS = 'ADD_GROUPS',
  FROM_USERS = 'FROM_USERS',
}

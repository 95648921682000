import cn from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';

import { BackArrowIcon, CloseIcon } from 'assets/icons';
import { Button } from 'components/Button';
import { IconButtonWrapper } from 'components/IconButtonWrapper';
import { ApproveOrCancel } from 'core/modals/ApproveOrCancel';

import styles from './AddEntityModal.module.scss';

interface Props {
  toggleModal(): void;
  isModal: boolean;
  title: string;
  disabledSubmit: boolean;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  subModalText?: string;
  className?: string;
  classNameForm?: string;
  classNameFormPortal?: string;
  classNameFormPortalCancel?: string;
  isChanging?: boolean;
  saveTitle?: string;
  bottomElement?: ReactElement;
  classNameButtonsBlock?: string;
  withHideBodyScroll?: boolean;
  withHideBodyScrollSubModal?: boolean;
}

export const AddModalComponent: React.FC<Props> = ({
  children,
  toggleModal,
  isModal,
  title,
  disabledSubmit,
  onSubmit,
  subModalText,
  className,
  classNameForm,
  classNameFormPortal,
  classNameFormPortalCancel,
  isChanging = false,
  saveTitle = 'Добавить',
  bottomElement,
  classNameButtonsBlock,
  withHideBodyScroll = true,
  withHideBodyScrollSubModal = true,
}) => {
  const [isSubModal, setIsSubModal] = useState<boolean>(false);

  const closeModal = () => {
    toggleModal();
  };

  const toggleSubModal = () => {
    setIsSubModal(!isSubModal);
  };

  const onApproveSubModal = () => {
    closeModal();
    toggleSubModal();
  };

  useEffect(() => {
    if (withHideBodyScroll) {
      if (isModal || isSubModal) {
        document.body.style.overflow = 'hidden';
        return;
      }
      document.body.style.overflow = 'visible';
    }
  }, [isModal, isSubModal, withHideBodyScroll]);

  useEffect(() => {
    return () => {
      if (withHideBodyScroll) {
        document.body.style.overflow = 'visible';
      }
    };
  }, [withHideBodyScroll]);

  if (!isModal) return null;

  return (
    <>
      <div
        className={cn(styles.create, classNameFormPortal)}
        onMouseDown={toggleSubModal}
        aria-hidden="true"
      >
        <div
          className={cn(styles.create__content, className)}
          onMouseDown={(e) => e.stopPropagation()}
          aria-hidden="true"
        >
          <div className={styles.create__header}>
            <IconButtonWrapper
              onClick={toggleSubModal}
              icon={<BackArrowIcon className={styles.create__arrow} />}
              className={cn(styles.create__button, styles.create__button_left)}
            />
            <h4 className={styles.create__title}>{title}</h4>
            <IconButtonWrapper
              onClick={toggleSubModal}
              icon={<CloseIcon className={styles.create__cross} />}
              className={cn(styles.create__button, styles.create__button_right)}
            />
          </div>
          <div className={cn(styles.create__form, classNameForm)}>
            {children}
            <div
              className={cn(styles.create__buttonsBlock, classNameButtonsBlock)}
            >
              <div>{bottomElement}</div>
              <div
                className={cn(styles.create__buttons, {
                  [styles.create__buttons_mt]: bottomElement,
                })}
              >
                <Button
                  appearance="flat"
                  onClick={toggleSubModal}
                  type="button"
                  className={styles.create__cancelButton}
                >
                  Отмена
                </Button>
                <Button disabled={disabledSubmit} onClick={onSubmit}>
                  {saveTitle}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApproveOrCancel
        onApprove={onApproveSubModal}
        isModal={isSubModal}
        toggleModal={toggleSubModal}
        text={`Вы уверены, что хотите отменить ${
          isChanging ? 'изменение' : 'добавление'
        } ${subModalText}?`}
        withHideBodyScroll={withHideBodyScrollSubModal}
        classNameFormPortalCancel={classNameFormPortalCancel}
      />
    </>
  );
};

import React, { useState } from 'react';

import { ArrowLeftIcon, CloseIcon } from 'assets/icons';
import { Drawer } from 'components/Drawer';
import { IconButtonWrapper } from 'components/IconButtonWrapper';

import { useRuleForm } from '../../hooks/useRuleForm';
import { RuleModalContent } from '../RuleModalContent';

import styles from './RuleModal.module.scss';

interface Props {
  isModal: boolean;
  isEditMode: boolean;
  toggleIsModal(): void;
  toggleIsChangeModals?(): void;
}

export const RuleModal: React.FC<Props> = ({
  isModal = true,
  isEditMode,
  toggleIsModal,
  toggleIsChangeModals,
}) => {
  const [isSubModal, setIsSubModal] = useState<boolean>(false);

  const toggleIsSubModal = () => {
    setIsSubModal((prevState) => !prevState);
  };

  const ruleForm = useRuleForm({ isModal, isEditMode, toggleIsModal });

  const title = !isEditMode
    ? 'Создать правило автоназначения'
    : 'Редактировать правило автоназначения';

  const subModalText = !isEditMode ? 'создание' : 'редактирование';

  return (
    <Drawer
      isOpen={isModal}
      onClose={toggleIsChangeModals}
      approveOrCancelProps={{
        onApprove: toggleIsModal,
        isModal: isSubModal,
        toggleModal: toggleIsSubModal,
        text: `Вы уверены, что хотите отменить ${subModalText} правила?`,
      }}
    >
      <div className={styles.ruleModal}>
        <div className={styles.ruleModal__container}>
          <div className={styles.ruleModal__header}>
            <p className={styles.ruleModal__headerTitle}>{title}</p>
            <div className={styles.ruleModal__buttonsWrapper}>
              <IconButtonWrapper
                className={styles.ruleModal__headerCloseButton}
                onClick={toggleIsSubModal}
                icon={
                  <CloseIcon className={styles.ruleModal__headerCloseIcon} />
                }
              />
            </div>
            <IconButtonWrapper
              className={styles.ruleModal__headerCloseButtonMobile}
              onClick={toggleIsSubModal}
              icon={
                <ArrowLeftIcon className={styles.ruleModal__headerCloseIcon} />
              }
            />
          </div>
          <RuleModalContent ruleForm={ruleForm} onReset={toggleIsSubModal} />
        </div>
      </div>
    </Drawer>
  );
};

import React, { useState } from 'react';

import { Button } from 'components/Button';
import { getChangeButtonProps } from 'components/Table';
import { Typography, TypographyVariants } from 'components/Typography';
import { DataEmpty } from 'core/components/DataEmpty';
import { User } from 'core/types';
import { UsersAddToContract } from 'features/Users/components/UsersAddToContract';

import { ResponsibleUser } from './components/ResponsibleUser';
import styles from './ResponsibleForContract.module.scss';

interface Props {
  usersList?: User[];
  showCreateBtn?: boolean;
}

export const ResponsibleForContract: React.FC<Props> = ({
  usersList = [],
  showCreateBtn = false,
}) => {
  const [isModal, setIsModal] = useState<boolean>(false);

  const toggleModal = () => {
    setIsModal((prev) => !prev);
  };
  const createHandler = () => {
    toggleModal();
  };

  const { createTitle, icon } = getChangeButtonProps(usersList?.length || 0);

  const content = usersList?.length ? (
    usersList?.map((user) => (
      <div className={styles.responsibleForContract__user} key={user.id}>
        <ResponsibleUser user={user} />
      </div>
    ))
  ) : (
    <DataEmpty />
  );

  const modal = isModal && (
    <UsersAddToContract isModal={isModal} toggleModal={toggleModal} />
  );

  return (
    <div className={styles.responsibleForContract}>
      <div className={styles.responsibleForContract__header}>
        <Typography variant={TypographyVariants.h4}> Ответственные</Typography>
        {showCreateBtn && (
          <Button
            appearance="flat"
            onClick={createHandler}
            icon={icon}
            className={styles.responsibleForContract__button}
          >
            {createTitle}
          </Button>
        )}
      </div>
      <div className={styles.responsibleForContract__usersList}>{content}</div>
      {modal}
    </div>
  );
};

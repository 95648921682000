import { TableColumns } from 'components/Table/types';

export const ACTIONS_FROM_RESP_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
  },
  { title: 'Название в базе', fieldName: 'nameInDatabase' },
  { title: 'Статус', fieldName: 'status' },
];

export const INITIAL_ACTIONS_FILTER = {
  title: '',
  nameInDatabase: '',
};

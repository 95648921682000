import { debounce } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Select } from 'components/Select';
import { SelectProps } from 'components/Select/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { fetchOrganizationRequest } from 'features/Storages/ducks';

interface Props extends SelectProps {}

export const StorageOrganizationsSelect: React.FC<Props> = ({
  className,
  placeholder,
  options,
  ...other
}) => {
  const dispatch = useDispatch();

  const onFilterUsers = debounce((valueFilter: string) => {
    dispatch(fetchOrganizationRequest(valueFilter));
  }, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    dispatch(fetchOrganizationRequest(''));
  }, []);

  return (
    <Select
      {...other}
      placeholder={placeholder}
      onChangeInput={onFilterUsers}
      options={options}
      infiniteScrollable
      className={className}
      isSearchable
      isMulti
      isClearable
    />
  );
};

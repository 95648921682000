import React from 'react';

import { SendToIcon } from 'assets/icons';
import { PopoverButton } from 'components/Popover/components/PopoverButton';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { Typography } from 'components/Typography';

import styles from './SystemsWithSLADropContent.module.scss';

interface Props {
  handleExpand: () => void;
  disabled: boolean;
}

export const SystemsWithSLADropContent: React.FC<Props> = ({
  handleExpand,
  disabled,
}) => {
  return (
    <PopoverContainer>
      <PopoverButton
        icon={<SendToIcon className={styles.systemsWithSlaDropContent__icon} />}
        disabled={disabled}
        onClick={handleExpand}
      >
        <Typography>Расширить поддержку</Typography>
      </PopoverButton>
    </PopoverContainer>
  );
};

import { getMultiOptionValues } from 'components/Select';
import { StorageCustomField } from 'core/types';

import { SetStorageRequest } from '../types';

export const setStorageRequest = ({
  data,
  customFields,
}: SetStorageRequest) => {
  const {
    id,
    title,
    description,
    organizations,
    viewersGroups,
    editorsGroups,
    size,
  } = data;

  const preparedCustomFields = customFields?.reduce<StorageCustomField[]>(
    (acc, { required, ...other }) => {
      const result = [...acc];
      if (!required) {
        return [...result, { ...other }];
      }
      return result;
    },
    []
  );

  return {
    id,
    title,
    description,
    organizationIds: getMultiOptionValues(organizations),
    editorsWorkgroupIds: getMultiOptionValues(editorsGroups),
    viewersWorkgroupIds: getMultiOptionValues(viewersGroups),
    size,
    customFields: preparedCustomFields,
  };
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setRedirectPath } from 'core/ducks/actions';
import { getRedirectPath } from 'core/ducks/selectors';

export const withRedirectPath = <Props,>(Component: React.FC<Props>) => {
  return (props: Props) => {
    const redirectPath = useSelector(getRedirectPath);

    const dispatch = useDispatch();

    const { push } = useHistory();

    useEffect(() => {
      if (redirectPath) {
        push(redirectPath);
        dispatch(setRedirectPath(null));
      }
    }, [redirectPath]);

    return <Component {...props} />;
  };
};

import cn from 'clsx';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'components/Button';
import { Size } from 'components/types';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';

import { RangeDateType } from '../../types';
import { Range } from '../Range';

import styles from './Footer.module.scss';

interface Props {
  className?: string;
  cancel: () => void;
  submit: () => void;
  pickedDate?: RangeDateType;
  resetRange: () => void;
  showTime: boolean;
  range: boolean;
}

export const Footer: FC<Props> = ({
  className,
  cancel,
  submit,
  showTime,
  pickedDate,
  range,
  resetRange,
}) => {
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);

  const isMobileAll = isMobile || isMobileSmall;

  const selectedPeriod = useMemo(
    () =>
      range && (
        <Range
          className={styles.footer__range}
          pickedDate={pickedDate}
          showTime={showTime}
          reset={resetRange}
        />
      ),
    [range, pickedDate, showTime, resetRange]
  );

  return (
    <div className={cn(styles.footer, className)}>
      {selectedPeriod}
      <div
        className={cn(styles.footer__btns, {
          [styles.footer__btns_noRange]: !range,
        })}
      >
        <Button
          size={isMobileAll ? Size.s : Size.xs}
          className={cn(styles.footer__btn, {
            [styles.footer__btn_noRange]: !range,
          })}
          appearance="flat"
          type="button"
          onClick={cancel}
        >
          Отменить
        </Button>
        <Button
          size={isMobileAll ? Size.s : Size.xs}
          className={cn(styles.footer__btn, {
            [styles.footer__btn_noRange]: !range,
          })}
          type="button"
          onClick={submit}
        >
          Применить
        </Button>
      </div>
    </div>
  );
};

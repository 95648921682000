import { Organization, System } from 'core/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { SystemsFilterToRequest, Workflow, WorkflowParams } from '../../types';

import { endpoints } from './endpoints';

export const request = {
  fetchSystems: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: SystemsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<System[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystems(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchOrganizations: () =>
    ApiClient.post<ResponseWithMeta<Organization[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getOrganizations(),
      params: {
        pageNum: 0,
        pageSize: 500,
        sort: 'date_create_DESC'.toUpperCase(),
      },
      data: {},
    }),
  fetchWorkflow: ({ systemId, typeId }: WorkflowParams) =>
    ApiClient.get<Workflow>({
      baseURL: getEnv('REACT_APP_TICKET_STATUS'),
      url: endpoints.getWorkflow(systemId, typeId),
    }),
  fetchTicketTypes: (systemId: string) =>
    ApiClient.get<System>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getTicketTypes(systemId),
    }),
  saveWorkflow: ({ systemId, typeId, version, nodes, edges }: Workflow) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_TICKET_STATUS'),
      url: endpoints.saveWorkflow({ systemId, typeId, version }),
      data: { nodes, edges },
    }),
};

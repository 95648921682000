import React from 'react';

import { T1Icon } from 'assets/icons';
import { Loader } from 'components/Loader';
import { Typography, TypographyVariants } from 'components/Typography';

import styles from './AuthLoading.module.scss';

export const AuthLoading = () => (
  <div className={styles.authLoading}>
    <div className={styles.authLoading__header}>
      <T1Icon width={44} height={32} className={styles.authLoading__icon} />
      <Typography
        variant={TypographyVariants.d2}
        className={styles.authLoading__title}
      >
        SupPortal
      </Typography>
    </div>
    <Loader />
  </div>
);

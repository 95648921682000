import { Tab } from 'components/Tabs/types';
import { RouterHref } from 'routes/routerHref';

import { ProfileTabSettings } from '../types';

import { convertTabTitleToPath } from './convertTabTitleToPath';

export const convertProfileTabToTab = (
  tabs?: (ProfileTabSettings & Partial<Tab>)[]
) => {
  return (
    tabs?.map((item) => {
      const { titleNotification, description, hasUnchecked } = item;
      return {
        to: `${RouterHref.ProfileNotifications}/${convertTabTitleToPath(
          titleNotification
        )}`,
        title: description,
        exact: !titleNotification,
        notificationType: titleNotification,
        hasUnchecked,
      };
    }) || []
  );
};

import { getUrl } from 'utils';

export const endpoints = {
  getFiltersFields: () => getUrl('assigning/filter/form'),
  getFiltersStatus: () => getUrl('statuses/names'),
  getTriggersFields: () => getUrl('assigning/form'),
  getTriggers: () => getUrl('triggered-rules/filter'),
  getCurrentTrigger: (id: string) => getUrl(`triggered-rules/${id}`),
  getCustomFieldBySystemId: (id: string) => getUrl(`custom-field/system/${id}`),
  createTrigger: () => getUrl('triggered-rules'),
  editTrigger: (id: string) => getUrl(`triggered-rules/${id}`),
  deleteTrigger: (id: string) => getUrl(`triggered-rules/${id}`),
  getTicketStatus: () => getUrl('statuses/simple-filter'),
};

import React from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'components/Table';
import { System } from 'core/types';
import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';

import { SYSTEM_TABLE_FIELDS } from '../../constants';
import { getLoading } from '../../ducks/selectors';
import { SystemsFilter } from '../SystemsFilter';

import { getDataTable } from './utils';

interface Props {
  systems?: System[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleClickSystem?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const SystemsTable: React.FC<Props> = ({
  systems = [],
  sort,
  handleSort,
  handleClickSystem,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const isAccessToViewingCompany = useSelector(
    getIsAccessedAction(ActionList.ViewingCompanyAdministration)
  );

  const data = getDataTable(systems, isAccessToViewingCompany);

  const loading = useSelector(getLoading);

  return (
    <Table
      data={data}
      columns={SYSTEM_TABLE_FIELDS}
      sort={sort}
      onRowClick={handleClickSystem}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={showFilterRow && <SystemsFilter />}
      disabledSort={loading}
    />
  );
};

import cn from 'clsx';
import React, { SVGProps } from 'react';

import { Ripple } from '../Ripple';

import styles from './EllipseIconButton.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: SVGProps<SVGElement>;
}

export const EllipseIconButton: React.FC<Props> = ({
  className,
  icon,
  disabled,
  onMouseDown,
  ...otherProps
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  return (
    <button
      className={cn(styles.ellipseIconButton, className)}
      onMouseDown={handleClick}
      disabled={disabled}
      {...otherProps}
    >
      {icon}
      {!disabled && <Ripple />}
    </button>
  );
};

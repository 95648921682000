import cn from 'clsx';
import React from 'react';

import styles from './Cell.module.scss';

interface Props {
  className?: string;
}

export const Cell: React.FC<Props> = ({ className, children }) => {
  return <div className={cn(styles.cell, className)}>{children}</div>;
};

import React from 'react';

import { Checkbox } from 'components/Checkbox';
import { Role } from 'core/types';

export const getDataTable = (
  changeCheckbox: (
    item: Role
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  selected: string[],
  data?: Role[]
) =>
  data?.map((role) => {
    const { name, description, id } = role;
    return {
      checkbox: (
        <Checkbox
          name="rolesCheckbox"
          onChange={changeCheckbox(role)}
          checked={selected.includes(id)}
        />
      ),
      id,
      name,
      description,
    };
  }) || [];

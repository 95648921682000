import cn from 'clsx';
import React, { forwardRef, useState } from 'react';

import {
  ApproveOrCancel,
  Props as ApproveOrCancelProps,
} from 'core/modals/ApproveOrCancel';

import styles from './Drawer.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;
  onClose?: () => void;
  approveOrCancelProps?: ApproveOrCancelProps;
}

export const Drawer = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, isOpen, className, onClose, approveOrCancelProps } = props;

  const [panelOpened, setPanelOpened] = useState(false);

  const isOpened = isOpen && panelOpened;

  const handleClose = () => {
    if (isOpened && onClose) {
      onClose();
      setPanelOpened(false);
    }
  };

  const approveHandleClose = () => {
    if (isOpened && approveOrCancelProps) {
      approveOrCancelProps.toggleModal();
    }
  };

  const transitionHandler = (event: React.TransitionEvent<HTMLDivElement>) => {
    if (event.propertyName === 'transform') {
      setPanelOpened(!!(isOpen && !panelOpened));
    }
  };

  const approveOrCancel = approveOrCancelProps && (
    <ApproveOrCancel {...approveOrCancelProps} />
  );

  return (
    <div
      role="dialog"
      ref={ref}
      className={cn(styles.drawer, className, {
        [styles.drawer_active]: isOpen,
      })}
      onMouseDown={approveOrCancelProps ? approveHandleClose : handleClose}
      aria-hidden="true"
      onTransitionEnd={transitionHandler}
    >
      <div
        className={cn(styles.drawer__content, {
          [styles.drawer__content_active]: isOpen,
        })}
        onMouseDown={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        {children}
      </div>
      {approveOrCancel}
    </div>
  );
});

import React from 'react';
import { useSelector } from 'react-redux';

import { PriorityBlock } from 'components/PriorityBlock';
import { Table } from 'components/Table';
import { Ticket } from 'features/Ticket/types';

import { TICKETS_BY_CONTRACT_IDS_TABLE_FIELDS } from '../../constants';
import { getLoading } from '../../ducks/selectors';
import { TicketsFilter } from '../TicketsFilter';

const getDataTable = (tickets?: Ticket[]) =>
  tickets?.map((ticket) => {
    const { number, id, priority, theme } = ticket;
    return {
      id,
      number: String(number),
      priority: <PriorityBlock priority={priority} />,
      theme,
    };
  }) || [];

interface Props {
  tickets?: Ticket[];
  sort?: string;
  handleSort?: (value: string) => void;
  isSearchOpen?: boolean;
  isFilterActive?: boolean;
}

export const TicketsByContractIdsTable: React.FC<Props> = ({
  isSearchOpen,
  tickets,
  sort,
  handleSort,
  isFilterActive,
}) => {
  const data = getDataTable(tickets);

  const loading = useSelector(getLoading);

  const bodySearch = isSearchOpen && <TicketsFilter />;

  return (
    <Table
      columns={TICKETS_BY_CONTRACT_IDS_TABLE_FIELDS}
      data={data}
      sort={sort}
      onSort={handleSort}
      filterComponent={bodySearch}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      disabledSort={loading}
    />
  );
};

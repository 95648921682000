import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/Button';
import { addArticlesRequest } from 'features/UserAssistance/ducks';
import { getSelectedArticlesIds } from 'features/UserAssistance/ducks/selectors';

import styles from './UserAssistanceAddButtons.module.scss';

interface Props {
  onReset(): void;
  toggleIsAddMode(): void;
}

export const UserAssistanceAddButtons: React.FC<Props> = ({
  onReset,
  toggleIsAddMode,
}) => {
  const dispatch = useDispatch();

  const selectedArticlesIds = useSelector(getSelectedArticlesIds);

  const onAdd = () => {
    dispatch(
      addArticlesRequest({
        articlesIds: selectedArticlesIds,
        toggleIsAddMode,
      })
    );
    onReset();
  };

  return (
    <div className={styles.userAssistanceAddButtons}>
      <Button
        onClick={onAdd}
        type="button"
        className={styles.userAssistanceAddButtons__button}
      >
        Добавить
      </Button>
      <Button
        onClick={onReset}
        type="button"
        className={styles.userAssistanceAddButtons__button}
        appearance="flat"
      >
        Отмена
      </Button>
    </div>
  );
};

import cn from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LazyLoader } from 'core/components/LazyLoader';
import { RolesTableContainer } from 'features/Roles/components/RolesTableContainer';
import {
  fetchRolesByUserIdRequest,
  resetRolesState,
} from 'features/Roles/ducks/actions';
import { TableRolesTypes } from 'features/Roles/types';
import {
  fetchCurrentUser,
  resetUsersState,
  setUserId,
} from 'features/Users/ducks/actions';
import { getLoadingUser, getUser } from 'features/Users/ducks/selectors';
import { WorkGroupsTableContainer } from 'features/WorkGroups/components/WorkGroupsTableContainer';
import {
  fetchGroupsByUserIdRequest,
  resetWorkGroupsState,
} from 'features/WorkGroups/ducks/actions';
import { TableWorkGroupsTypes } from 'features/WorkGroups/types';

import { UserForm } from './components/UserForm';
import { useUserId } from './hooks/useUserId';
import styles from './User.module.scss';

interface Props {
  className?: string;
}

export const User: React.FC<Props> = ({ className }) => {
  const userId = useUserId();

  const loading = useSelector(getLoadingUser);
  const user = useSelector(getUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(setUserId(userId));
      dispatch(fetchGroupsByUserIdRequest());
      dispatch(fetchRolesByUserIdRequest());
      dispatch(fetchCurrentUser(userId));
    }
  }, [userId]);

  useEffect(
    () => () => {
      dispatch(resetWorkGroupsState());
      dispatch(resetUsersState());
      dispatch(resetRolesState());
    },
    []
  );

  if (loading && !user) {
    return <LazyLoader className={styles.user__loader} />;
  }

  return (
    <div className={cn(styles.user, className)}>
      <div className={styles.user__leftContainer}>
        <UserForm />
      </div>
      <div className={styles.user__rightContainer}>
        <WorkGroupsTableContainer
          tableType={TableWorkGroupsTypes.FROM_USERS}
          className={styles.user__tableContainer}
        />
        <RolesTableContainer
          tableType={TableRolesTypes.FROM_USERS}
          className={styles.user__tableContainer}
        />
      </div>
    </div>
  );
};

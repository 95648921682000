import React from 'react';

import { ExportIcon } from 'assets/icons';
import { Loader } from 'components/Loader';
import { PopoverButton } from 'components/Popover/components/PopoverButton';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';

import styles from './DesktopTicketsDropContent.module.scss';

interface Props {
  handleDownload?: () => void;
  isDownload?: boolean;
}

export const TicketsDropContent: React.FC<Props> = ({
  isDownload,
  handleDownload,
}) => {
  return (
    <PopoverContainer>
      <PopoverButton
        icon={
          isDownload ? (
            <Loader
              classNameRoot={styles.dropContent__loader}
              className={styles.dropContent__loader}
            />
          ) : (
            <ExportIcon className={styles.dropContent__icon} />
          )
        }
        onClick={handleDownload}
        disabled={isDownload}
      >
        Экспорт данных Excel
      </PopoverButton>
    </PopoverContainer>
  );
};

import cn from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  AdminNavIcon,
  ArrowRightDoubleIcon,
  BookNavIcon,
  HomeNavIcon,
  QuestionMarkIcon,
  StorageIcon,
  T1Icon,
  TicketNavIcon,
  UserNavIcon,
} from 'assets/icons';
import { NotificationCircle } from 'components/NotificationCircle';
import {
  getIsDesktop,
  getIsDesktopSmall,
  getIsMobile,
  getIsMobileSmall,
} from 'core/ducks/selectors';
import {
  getIsAccessedAction,
  getIsAccessedByRoute,
  getIsClient,
  getIsClientPartner,
  getIsGroupSpecialist,
  getIsSpecialist,
  getIsSpecialistPartner,
} from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { getIsNotCheckedNotifications } from 'features/Notifications/ducks/selectors';
import { getIsUncheckedNews } from 'features/Profile/ducks/selectors';
import { setIsModal } from 'features/UserAssistance/ducks';
import { getIsModal } from 'features/UserAssistance/ducks/selectors';
import { RouterHref } from 'routes/routerHref';

import { NavigationTab } from './components/NavigationTab';
import styles from './Navigation.module.scss';

interface Props {
  authLoading?: boolean;
}

export const Navigation: React.FC<Props> = React.memo(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  ({ authLoading = false }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState<boolean>(
      localStorage.getItem('expanded-nav') === 'true'
    );

    const toggleIsOpen = () => {
      setIsOpen((prevState) => {
        localStorage.setItem('expanded-nav', String(!prevState));
        return !prevState;
      });
    };

    const isAccessToViewingCompany = useSelector(
      getIsAccessedAction(ActionList.ViewingCompanyAdministration)
    );
    const isAccessToViewingUser = useSelector(
      getIsAccessedAction(ActionList.ViewingUserAdministration)
    );
    const isAccessToCreateReportByOrg = useSelector(
      getIsAccessedAction(ActionList.CreateReportByOrganization)
    );
    const isAccessToTakeTicketToWork = useSelector(
      getIsAccessedAction(ActionList.TakeTicketToWork)
    );
    // TODO: tsp-1519 переписать логику позже
    const isAccessToStoragesByRoute = useSelector(
      getIsAccessedByRoute(RouterHref.Storage)
    );
    const isClient = useSelector(getIsClient);
    const isClientPartner = useSelector(getIsClientPartner);
    const isSpecialist = useSelector(getIsSpecialist);
    const isSpecialistPartner = useSelector(getIsSpecialistPartner);
    const isGroupSpecialist = useSelector(getIsGroupSpecialist);
    const isMobile = useSelector(getIsMobile);
    const isMobileSmall = useSelector(getIsMobileSmall);
    const isDesktop = useSelector(getIsDesktop);
    const isDesktopSmall = useSelector(getIsDesktopSmall);
    const isUserAssistanceModal = useSelector(getIsModal);
    const isNotCheckedNotifications = useSelector(getIsNotCheckedNotifications);
    const isUncheckedNews = useSelector(getIsUncheckedNews);

    const isMobileAll = isMobile || isMobileSmall;

    const hideHomeNav =
      isClient ||
      (!isGroupSpecialist &&
        !isAccessToCreateReportByOrg &&
        !isAccessToTakeTicketToWork);

    const hideTicketNav = !(
      isClient ||
      isClientPartner ||
      isSpecialist ||
      isSpecialistPartner
    );

    // TODO: Добавить при интеграции i18n для смены языка

    // const changeLocalization = () => {
    //   if (language === Languages.RU) {
    //     changeLanguage(Languages.EN);
    //     return;
    //   }
    //   changeLanguage(Languages.RU);
    // };

    const getDesktopLink = useCallback(() => {
      if (isAccessToTakeTicketToWork) {
        return RouterHref.DesktopKpi;
      }
      if (isGroupSpecialist || isAccessToCreateReportByOrg) {
        return RouterHref.DesktopCurrentState;
      }
      if (!isGroupSpecialist && isAccessToCreateReportByOrg) {
        return RouterHref.DesktopHistory;
      }
      return RouterHref.DesktopEmployees;
    }, [
      isAccessToTakeTicketToWork,
      isGroupSpecialist,
      isAccessToCreateReportByOrg,
    ]);

    const toggleIsUserAssistanceModal = () => {
      dispatch(setIsModal(!isUserAssistanceModal));
    };

    const links = useMemo(
      () => [
        {
          title: 'Профиль',
          icon: (
            <div className={styles.navigation__iconWrapper}>
              <UserNavIcon />
              {isNotCheckedNotifications && (
                <NotificationCircle
                  className={cn(
                    styles.navigation__notification,
                    styles.navigation__notification_purple
                  )}
                />
              )}
              {isUncheckedNews && (
                <NotificationCircle
                  className={cn(
                    styles.navigation__notification,
                    styles.navigation__notification_yellow
                  )}
                />
              )}
            </div>
          ),
          exact: false,
          link: RouterHref.Profile,
          className: cn(styles.navigation__tab, {
            [styles.navigation__tab_hidden]: !isMobileAll,
          }),
        },
        {
          title: isMobileAll ? 'Главная' : t('tabs.desktop'),
          icon: <HomeNavIcon />,
          exact: false,
          link: getDesktopLink(),
          className: cn(styles.navigation__tab, {
            [styles.navigation__tab_hidden]: hideHomeNav,
          }),
        },
        {
          title: t('tabs.tickets'),
          icon: <TicketNavIcon />,
          exact: isMobileAll,
          link: RouterHref.Tickets,
          className: cn(styles.navigation__tab, {
            [styles.navigation__tab_hidden]: hideTicketNav,
          }),
        },
        {
          title: isMobileAll ? 'Админ' : 'Администратор',
          icon: <AdminNavIcon />,
          exact: false,
          link: RouterHref.Admin,
          className: cn(styles.navigation__tab, {
            [styles.navigation__tab_hidden]: !(
              isAccessToViewingCompany || isAccessToViewingUser
            ),
          }),
        },
        {
          title: 'Хранилища',
          icon: <StorageIcon />,
          exact: false,
          link: RouterHref.Storages,
          className: cn(styles.navigation__tab, {
            [styles.navigation__tab_hidden]: !isAccessToStoragesByRoute,
          }),
        },
      ],
      [
        isAccessToViewingCompany,
        isAccessToViewingUser,
        isMobileAll,
        isAccessToTakeTicketToWork,
        isNotCheckedNotifications,
        isUncheckedNews,
        isAccessToStoragesByRoute,
        hideHomeNav,
        hideTicketNav,
      ]
    );

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
    };

    useEffect(() => {
      if (!isDesktop && !isDesktopSmall && isOpen) {
        toggleIsOpen();
      }
    }, [isDesktop, isDesktopSmall, isOpen, toggleIsOpen]);

    const tabs = links.map((link) => (
      <NavigationTab
        title={link.title}
        icon={link.icon}
        key={link.link}
        href={link.link}
        exact={link.exact}
        className={link.className}
        authLoading={authLoading}
        expanded={isOpen}
      />
    ));

    const skeletonTabs = Array.from(Array(isMobileAll ? 4 : 3).keys()).map(
      (link) => (
        <NavigationTab
          key={link}
          href={String(link)}
          authLoading={authLoading}
          className={styles.navigation__tab}
        />
      )
    );

    const logoContent = (
      <>
        <T1Icon />
        <h3
          className={cn(styles.navigation__t1IconTitle, {
            [styles.navigation__t1IconTitle_expanded]: isOpen,
          })}
        >
          SupPortal
        </h3>
      </>
    );

    const logo = authLoading ? (
      <div className={styles.navigation__t1IconLabel}>{logoContent}</div>
    ) : (
      <Link
        to={RouterHref.Tickets}
        onMouseDown={handleClick}
        className={styles.navigation__t1IconLabel}
      >
        {logoContent}
      </Link>
    );

    const toggleButton = !authLoading && (
      <button
        onClick={toggleIsOpen}
        className={cn(styles.navigation__toggleButton, {
          [styles.navigation__toggleButton_open]: isOpen,
        })}
      >
        <ArrowRightDoubleIcon />
      </button>
    );

    const helpButton = !authLoading && (
      <button
        onClick={toggleIsUserAssistanceModal}
        className={styles.navigation__helpButtonWrapper}
      >
        <div className={styles.navigation__helpButton}>
          <QuestionMarkIcon className={styles.navigation__helpButtonIcon} />
        </div>
      </button>
    );

    return (
      <>
        <div
          className={cn(styles.navigation, {
            [styles.navigation_open]: isOpen,
          })}
        >
          <div className={styles.navigation__tabs}>
            <div className={styles.navigation__t1IconBlock}>{logo}</div>
            {authLoading ? skeletonTabs : tabs}
            <div className={styles.navigation__separator} />
            <NavigationTab
              title="База знаний"
              icon={<BookNavIcon />}
              href={RouterHref.KnowledgeBase}
              exact={false}
              authLoading={authLoading}
              expanded={isOpen}
              className={cn({
                [styles.navigation__tab_hidden]: isMobileAll,
              })}
            />
            {helpButton}
          </div>
          {toggleButton}
        </div>
      </>
    );
  }
);

import { isNull } from 'lodash';

import { getReverseDateFromCustomField } from 'components/Table/components/SettingFields';
import { ACTIVE_SELECT_OPTIONS } from 'components/Table/components/TableFilter/constants';

import { FileCardCustomFields } from '../types';

const getValue = (field: FileCardCustomFields) => {
  if (field.text) {
    return field.text;
  }
  if (field.date) {
    return getReverseDateFromCustomField(field.date);
  }
  if (field.flag || !isNull(field.flag)) {
    return field.flag
      ? ACTIVE_SELECT_OPTIONS[0].title
      : ACTIVE_SELECT_OPTIONS[1].title;
  }
  return '-';
};

export const getCustomFieldsForStorageTable = (
  fields?: FileCardCustomFields[]
) =>
  fields?.reduce<Record<string, string>>((acc, field) => {
    const result = { ...acc };

    if (field.customFieldId) {
      result[field.customFieldId] = getValue(field);
    }

    return result;
  }, {});

import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { SelectOption } from 'components/Select/types';
import { Organization } from 'core/types';
import {
  createCategory,
  deleteArticle,
  deleteCategory,
  editArticle,
  editCategory,
  fetchArticle,
} from 'features/KnowledgeBase/generators/generators';
import { Article, CategoryArticle } from 'features/KnowledgeBase/types';
import { ResponseWithMeta } from 'store/types';
import { createError } from 'utils';

import {
  categoriesLoadingHideArticleViewing,
  categoriesLoadingShowArticleViewing,
  createCategoryRequestRightArticleViewing,
  deleteArticleRequestRightArticleViewing,
  deleteCategoryRequestRightArticleViewing,
  editArticleRequestRightArticleViewing,
  editCategoryRequestRightArticleViewing,
  fetchArticlesArticleViewingRequest,
  fetchArticlesJoinSuccessArticleViewing,
  fetchArticlesSuccessArticleViewing,
  fetchCategoriesRequestArticleViewing,
  fetchCategoriesSuccessArticleViewing,
  fetchCurrentArticleRequestArticleViewing,
  fetchCurrentArticleSuccessArticleViewing,
  fetchOrganizationsSuccessArticleViewing,
  loadingHideArticleViewing,
  loadingHideCurrentArticleArticleViewing,
  loadingShowArticleViewing,
  loadingShowCurrentArticleArticleViewing,
  organizationsLoadingHideArticleViewing,
  organizationsLoadingShowArticleViewing,
} from './actions';
import { request } from './api/requests';
import {
  getFilterArticlesArticleViewing,
  getPaginationArticlesArticleViewing,
  getSelectedOrganizationArticlesArticleViewing,
} from './selectors';
import {
  ArticleViewing,
  FetchOrganizationsRequestArticleViewingAction,
} from './types';

function* articlesArticleViewingFetch({
  payload,
}: ReturnType<typeof fetchArticlesArticleViewingRequest>) {
  try {
    const filter: ReturnType<typeof getFilterArticlesArticleViewing> =
      yield select(getFilterArticlesArticleViewing);
    const { pageSize }: ReturnType<typeof getPaginationArticlesArticleViewing> =
      yield select(getPaginationArticlesArticleViewing);
    yield put(loadingShowArticleViewing());
    const articles: ResponseWithMeta<Article[]> = yield call(
      request.fetchArticles,
      payload?.page,
      pageSize,
      'title_desc',
      filter
    );
    yield put(
      payload?.updateType === 'update'
        ? fetchArticlesSuccessArticleViewing(articles)
        : fetchArticlesJoinSuccessArticleViewing(articles)
    );
    yield put(loadingHideArticleViewing());
  } catch (e) {
    yield put(loadingHideArticleViewing());
    createError(e);
  }
}

function* organizationsArticleViewingFetch({
  payload,
}: FetchOrganizationsRequestArticleViewingAction) {
  try {
    yield put(organizationsLoadingShowArticleViewing());
    const organizations: Organization[] = yield call(
      request.fetchOrganizations,
      payload
    );
    yield put(fetchOrganizationsSuccessArticleViewing(organizations));
    yield put(organizationsLoadingHideArticleViewing());
  } catch (e) {
    yield put(organizationsLoadingHideArticleViewing());
    createError(e);
  }
}

function* fetchCategories({
  payload,
}: ReturnType<typeof fetchCategoriesRequestArticleViewing>) {
  try {
    yield put(categoriesLoadingShowArticleViewing());
    const categories: CategoryArticle[] = yield call(
      request.fetchCategoriesRequest,
      payload
    );
    yield put(fetchCategoriesSuccessArticleViewing(categories));
    yield put(categoriesLoadingHideArticleViewing());
  } catch (e) {
    yield put(categoriesLoadingHideArticleViewing());
    yield put(fetchCategoriesSuccessArticleViewing([]));
    createError(e);
  }
}

function* fetchCurrentArticle({
  payload,
}: ReturnType<typeof fetchCurrentArticleRequestArticleViewing>) {
  try {
    yield put(loadingShowCurrentArticleArticleViewing());
    const article: Article = yield fetchArticle(payload);
    yield put(fetchCurrentArticleSuccessArticleViewing(article));
    yield put(loadingHideCurrentArticleArticleViewing());
  } catch (e) {
    yield put(loadingHideCurrentArticleArticleViewing());
    createError(e);
  }
}

function* createCategoryFunction({
  payload,
}: ReturnType<typeof createCategoryRequestRightArticleViewing>) {
  try {
    const organization: SelectOption | null = yield select(
      getSelectedOrganizationArticlesArticleViewing
    );
    yield call(createCategory, payload);
    yield put(fetchCategoriesRequestArticleViewing(organization?.value));
  } catch (e) {
    createError(e);
  }
}

function* editCategoryFunction({
  payload,
}: ReturnType<typeof editCategoryRequestRightArticleViewing>) {
  try {
    const organization: SelectOption | null = yield select(
      getSelectedOrganizationArticlesArticleViewing
    );
    yield call(editCategory, payload);
    yield put(fetchCategoriesRequestArticleViewing(organization?.value));
  } catch (e) {
    createError(e);
  }
}

function* deleteCategoryFunction({
  payload,
}: ReturnType<typeof deleteCategoryRequestRightArticleViewing>) {
  try {
    const organization: SelectOption | null = yield select(
      getSelectedOrganizationArticlesArticleViewing
    );
    yield call(deleteCategory, payload);
    yield put(fetchCategoriesRequestArticleViewing(organization?.value));
  } catch (e) {
    createError(e);
  }
}

function* editArticleFunction({
  payload,
}: ReturnType<typeof editArticleRequestRightArticleViewing>) {
  try {
    const organization: SelectOption | null = yield select(
      getSelectedOrganizationArticlesArticleViewing
    );
    yield call(editArticle, payload);
    yield put(fetchCategoriesRequestArticleViewing(organization?.value));
  } catch (e) {
    createError(e);
  }
}

function* deleteArticleFunction({
  payload,
}: ReturnType<typeof deleteArticleRequestRightArticleViewing>) {
  try {
    const organization: SelectOption | null = yield select(
      getSelectedOrganizationArticlesArticleViewing
    );
    yield call(deleteArticle, payload);
    yield put(fetchCategoriesRequestArticleViewing(organization?.value));
  } catch (e) {
    createError(e);
  }
}

export function* articleViewingSaga(): Generator<StrictEffect> {
  yield takeEvery(
    ArticleViewing.FETCH_REQUEST_ARTICLES_ARTICLE_VIEWING,
    articlesArticleViewingFetch
  );
  yield takeEvery(
    ArticleViewing.FETCH_ORGANIZATIONS_REQUEST_ARTICLE_VIEWING,
    organizationsArticleViewingFetch
  );
  yield takeEvery(
    ArticleViewing.FETCH_CATEGORIES_REQUEST_ARTICLE_VIEWING,
    fetchCategories
  );
  yield takeEvery(
    ArticleViewing.FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_VIEWING,
    fetchCurrentArticle
  );
  yield takeEvery(
    ArticleViewing.CREATE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING,
    createCategoryFunction
  );
  yield takeEvery(
    ArticleViewing.EDIT_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING,
    editCategoryFunction
  );
  yield takeEvery(
    ArticleViewing.DELETE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING,
    deleteCategoryFunction
  );
  yield takeEvery(
    ArticleViewing.EDIT_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING,
    editArticleFunction
  );
  yield takeEvery(
    ArticleViewing.DELETE_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING,
    deleteArticleFunction
  );
}

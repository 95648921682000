import { CustomField, System } from 'core/types';
import { ResponseWithMeta } from 'store/types';

import { ExportFields, TicketsRequestData } from '../types';

export enum TicketsExport {
  OPEN_EXPORT_PANEL = 'TICKET_EXPORT/OPEN_EXPORT_PANEL',
  CLOSE_EXPORT_PANEL = 'TICKET_EXPORT/CLOSE_EXPORT_PANEL',

  GET_EXPORT_FIELDS = 'TICKET_EXPORT/GET_EXPORT_FIELDS',
  GET_EXPORT_FIELDS_REQUEST = 'TICKET_EXPORT/GET_EXPORT_FIELDS_REQUEST',

  SET_TICKETS_COUNT = 'TICKET_EXPORT/SET_TICKETS_COUNT',
  GET_TICKETS_COUNT_REQUEST = 'TICKET_EXPORT/GET_TICKETS_COUNT_REQUEST',

  DOWNLOAD_EXCEL_REQUEST = 'TICKET_EXPORT/DOWNLOAD_EXCEL_REQUEST',

  DOWNLOADING_EXCEL = 'TICKET_EXPORT/DOWNLOADING_EXCEL',

  FETCH_SYSTEMS_EXPORT_REQUEST = 'TICKET_EXPORT/FETCH_SYSTEMS_EXPORT_REQUEST',
  FETCH_SYSTEMS_EXPORT_SUCCESS = 'TICKET_EXPORT/FETCH_SYSTEMS_EXPORT_SUCCESS',
  SYSTEMS_EXPORT_LOADING_SHOW = 'TICKET_EXPORT/SYSTEMS_EXPORT_LOADING_SHOW',
  SYSTEMS_EXPORT_LOADING_HIDE = 'TICKET_EXPORT/SYSTEMS_EXPORT_LOADING_HIDE',

  FETCH_CUSTOM_FIELDS_EXPORT_REQUEST = 'TICKET_EXPORT/FETCH_CUSTOM_FIELDS_EXPORT_REQUEST',
  FETCH_CUSTOM_FIELDS_EXPORT_SUCCESS = 'TICKET_EXPORT/FETCH_CUSTOM_FIELDS_EXPORT_SUCCESS',
  CUSTOM_FIELDS_EXPORT_LOADING_SHOW = 'TICKET_EXPORT/CUSTOM_FIELDS_EXPORT_LOADING_SHOW',
  CUSTOM_FIELDS_EXPORT_LOADING_HIDE = 'TICKET_EXPORT/CUSTOM_FIELDS_EXPORT_LOADING_HIDE',

  RESET_CUSTOM_FIELDS_EXPORT = 'TICKET_EXPORT/RESET_CUSTOM_FIELDS_EXPORT',
  RESET_TICKETS_EXPORT_STATE = 'TICKET_EXPORT/RESET_TICKETS_EXPORT_STATE',
  RESET_SELECTED_CUSTOM_FIELDS = 'TICKET_EXPORT/RESET_SELECTED_CUSTOM_FIELDS',

  SELECT_CUSTOM_FIELD = 'TICKET_EXPORT/SELECT_CUSTOM_FIELD',
  SELECT_ALL_CUSTOM_FIELDS = 'TICKET_EXPORT/SELECT_ALL_CUSTOM_FIELDS',

  SET_EXTRA_EXPORT_FIELDS = 'TICKET_EXPORT/SET_EXTRA_EXPORT_FIELDS',
}

export interface OpenExportPanelAction {
  type: TicketsExport.OPEN_EXPORT_PANEL;
}

export interface CloseExportPanelAction {
  type: TicketsExport.CLOSE_EXPORT_PANEL;
}

export interface GetExportFieldsAction {
  type: TicketsExport.GET_EXPORT_FIELDS;
  payload: ExportFields;
}

export interface GetExportFieldsRequestAction {
  type: TicketsExport.GET_EXPORT_FIELDS_REQUEST;
}

export interface SetTicketsCountAction {
  type: TicketsExport.SET_TICKETS_COUNT;
  payload?: number;
}

export interface GetTicketsCountRequestAction {
  type: TicketsExport.GET_TICKETS_COUNT_REQUEST;
  payload: TicketsRequestData;
}

export interface DownloadExcelRequestAction {
  type: TicketsExport.DOWNLOAD_EXCEL_REQUEST;
}

export interface DownloadingExcel {
  type: TicketsExport.DOWNLOADING_EXCEL;
  payload: boolean;
}

export interface FetchSystemsExportRequestAction {
  type: TicketsExport.FETCH_SYSTEMS_EXPORT_REQUEST;
  payload?: string;
}

export interface FetchSystemsExportSuccessAction {
  type: TicketsExport.FETCH_SYSTEMS_EXPORT_SUCCESS;
  payload: ResponseWithMeta<System[]>;
}

export interface SystemsExportLoadingShowAction {
  type: TicketsExport.SYSTEMS_EXPORT_LOADING_SHOW;
}

export interface SystemsExportLoadingHideAction {
  type: TicketsExport.SYSTEMS_EXPORT_LOADING_HIDE;
}

export interface FetchCustomFieldsExportRequestAction {
  type: TicketsExport.FETCH_CUSTOM_FIELDS_EXPORT_REQUEST;
  payload: string;
}

export interface FetchCustomFieldsExportSuccessAction {
  type: TicketsExport.FETCH_CUSTOM_FIELDS_EXPORT_SUCCESS;
  payload: ResponseWithMeta<CustomField[]>;
}

export interface CustomFieldsExportLoadingShowAction {
  type: TicketsExport.CUSTOM_FIELDS_EXPORT_LOADING_SHOW;
}

export interface CustomFieldsExportLoadingHideAction {
  type: TicketsExport.CUSTOM_FIELDS_EXPORT_LOADING_HIDE;
}

export interface ResetTicketsExportStateAction {
  type: TicketsExport.RESET_TICKETS_EXPORT_STATE;
}

export interface ResetCustomFieldsExportAction {
  type: TicketsExport.RESET_CUSTOM_FIELDS_EXPORT;
}

export interface SelectCustomFieldAction {
  type: TicketsExport.SELECT_CUSTOM_FIELD;
  payload: CustomField;
}

export interface SelectAllCustomFieldsAction {
  type: TicketsExport.SELECT_ALL_CUSTOM_FIELDS;
}

export interface ResetSelectedCustomFieldsAction {
  type: TicketsExport.RESET_SELECTED_CUSTOM_FIELDS;
}

export interface SetExtraExportFields {
  type: TicketsExport.SET_EXTRA_EXPORT_FIELDS;
  payload?: CustomField[];
}

export type TicketsExportActionsTypes =
  | OpenExportPanelAction
  | CloseExportPanelAction
  | GetExportFieldsAction
  | GetExportFieldsRequestAction
  | SetTicketsCountAction
  | GetTicketsCountRequestAction
  | DownloadExcelRequestAction
  | DownloadingExcel
  | FetchSystemsExportSuccessAction
  | FetchCustomFieldsExportSuccessAction
  | SelectCustomFieldAction
  | SetExtraExportFields;

import React from 'react';

import { NotFound as NotFoundContent } from 'core/components/NotFound';
import { MainLayout } from 'core/layouts/MainLayout';

const NotFound = () => {
  return (
    <MainLayout withHeader={false}>
      <NotFoundContent />
    </MainLayout>
  );
};

export default NotFound;

import cn from 'clsx';
import React, { forwardRef } from 'react';

import { TabItem } from '../types';
import { Typography, TypographyVariants } from '../Typography';

import styles from './RadioPriority.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  items: TabItem[];
}

export const RadioPriority = forwardRef<HTMLInputElement, Props>(
  ({ className, items, onMouseDown, ...otherProps }, ref) => {
    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (onMouseDown) {
        onMouseDown(event);
      }
    };

    const itemsEls = items.map(
      ({ title, value, classNameTab, disabled, defaultChecked }) => {
        return (
          <label
            key={String(value)}
            className={cn(styles.radioPriority__label, {
              [styles.radioPriority__label_disabled]: disabled,
            })}
          >
            <input
              ref={ref}
              type="radio"
              value={value}
              onMouseDown={handleClick}
              defaultChecked={defaultChecked}
              disabled={disabled}
              className={styles.radioPriority__input}
              {...otherProps}
            />
            <Typography
              variant={TypographyVariants.b3}
              className={cn(styles.radioPriority__tabTitle, classNameTab)}
            >
              {title}
            </Typography>
          </label>
        );
      }
    );

    return (
      <div className={cn(styles.radioPriority, className)}>{itemsEls}</div>
    );
  }
);

const getPrepareHours = (hours: number) => {
  if (hours < 10) {
    return `00${hours}`;
  }
  if (hours < 100) {
    return `0${hours}`;
  }
  return `${hours}`;
};

export const getConvertedTimeToHours = (time: number) => {
  const allMinutes = time;
  const newTime = String(time / 60);

  const convertedHours = newTime.split('.');
  const [hours] = convertedHours;

  const convertedMinutes = allMinutes - Number(hours) * 60;
  const prepareHours = getPrepareHours(Number(hours));
  const prepareMinutes =
    convertedMinutes < 10 ? `0${convertedMinutes}` : `${convertedMinutes}`;

  return `${prepareHours}:${prepareMinutes}`;
};

export const convertedTimeToMinutes = (time?: string | number) => {
  if (!time) {
    return 0;
  }
  const newTime = String(time);

  const convertedStr = newTime.split(':');
  const [hours, minutes] = convertedStr;

  return Number(hours) * 60 + Number(minutes);
};

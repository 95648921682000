import React from 'react';

import { Typography, TypographyVariants } from 'components/Typography';
import { DataEmpty } from 'core/components/DataEmpty';
import { Attachment } from 'types/models/attachment';

import { AttachmentDetail } from '../../../Contract/components/AttachmentDetail';

import styles from './AttachmentsSupAg.module.scss';

interface Props {
  attachments?: Attachment[];
}

export const AttachmentsSupAg: React.FC<Props> = ({ attachments }) => {
  const content = attachments?.length ? (
    attachments?.map((attachment) => (
      <AttachmentDetail attachment={attachment} key={attachment.id} />
    ))
  ) : (
    <DataEmpty className={styles.attachmentsContract__empty} />
  );

  return (
    <div className={styles.attachmentsContract}>
      <Typography
        variant={TypographyVariants.h4}
        className={styles.attachmentsContract__header}
      >
        Вложения
      </Typography>
      <div className={styles.attachmentsContract__list}>{content}</div>
    </div>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'components/Table';
import { System } from 'core/types';
import { getSystemsTransfer } from 'features/Contract/ducks/selectors';

import { TABLE_FIELDS_FROM_CONTRACTS } from '../../constants';
import { getLoading } from '../../ducks/selectors';

import { getDataTable } from './utils';

interface Props {
  systems?: System[];
  handleClickSystem?: (value: string) => void;
  activeRowId?: string;
  sort?: string;
  handleSort?: (value: string) => void;
  onChangeCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectAllCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isFilterActive?: boolean;
  customLoading?: boolean;
  showFilterRow?: boolean;
  filterComponent?: JSX.Element;
}

export const SystemsFromContractTable: React.FC<Props> = ({
  systems,
  activeRowId,
  handleClickSystem,
  sort,
  handleSort,
  onChangeCheckbox,
  onSelectAllCheckbox,
  isFilterActive,
  customLoading = false,
  showFilterRow,
  filterComponent,
}) => {
  const data = getDataTable(systems);

  const loading = useSelector(getLoading);
  const { isTransfer } = useSelector(getSystemsTransfer);

  return (
    <Table
      data={data}
      sort={sort}
      onSort={handleSort}
      // TODO: пустое состояние таблицы должно быть без полей
      columns={TABLE_FIELDS_FROM_CONTRACTS}
      activeRowId={activeRowId}
      onRowClick={handleClickSystem}
      dataLoading={loading || customLoading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={showFilterRow && filterComponent}
      checkboxProps={{
        withCheckbox: !isTransfer,
        onChangeCheckbox,
        onSelectAllCheckbox,
      }}
      disabledSort={isTransfer}
    />
  );
};

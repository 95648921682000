import { FetchRequestArgs } from 'components/SearchSelect/types';
import { User } from 'core/types';
import { DeleteDataType } from 'features/Organizations/ducks/types';
import { CreateUserData } from 'features/User/types';
import { ResponseWithMeta } from 'store/types';

import { UsersFilter } from '../types';

import {
  CheckIsExistEmailRequestAction,
  CheckIsExistEmailSuccessAction,
  CheckIsExistLoginRequestAction,
  CheckIsExistLoginSuccessAction,
  CheckIsExistPhoneRequestAction,
  CheckIsExistPhoneSuccessAction,
  CreateUserAction,
  DeleteUserRequestAction,
  EditUserAction,
  FetchCurrentUserSuccessAction,
  FetchSpecialistsRequestAction,
  FetchSubordinatesRequestAction,
  FetchSubordinatesSuccessAction,
  FetchUserClientsRequestAction,
  FetchUserRequestAction,
  FetchUsersAddByContractIdRequestAction,
  FetchUsersAddByOrgIdRequestAction,
  FetchUsersByGroupsIdRequestAction,
  FetchUsersClientsSuccessJoinAction,
  FetchUsersClientsSuccessUpdateAction,
  FetchUsersRequestAction,
  FetchUsersSpecialistsSuccessJoinAction,
  FetchUsersSpecialistsSuccessUpdateAction,
  FetchUsersSuccessJoinAction,
  FetchUsersSuccessUpdateAction,
  HideUserLoadingAction,
  HideUsersAddLoadingAction,
  HideUsersLoadingAction,
  ResetCurrentUserAction,
  ResetCurrentUserAfterDeleteAction,
  ResetUsersAddStateAction,
  ResetUsersStateAction,
  SetSizePageAction,
  SetSizePageAddAction,
  SetSortUsersAction,
  SetSortUsersAddAction,
  SetUserIdAction,
  SetUserLoginAction,
  SetUsersAddPageAction,
  SetUsersPageAction,
  ShowUserLoadingAction,
  ShowUsersAddLoadingAction,
  ShowUsersLoadingAction,
  UpdateUserDataRequestAction,
  Users,
  UsersTicketsPayload,
} from './types';

export const showUsersLoading = (): ShowUsersLoadingAction => {
  return {
    type: Users.USERS_LOADING_SHOW,
  };
};

export const hideUsersLoading = (): HideUsersLoadingAction => {
  return {
    type: Users.USERS_LOADING_HIDE,
  };
};

export const showUsersAddLoading = (): ShowUsersAddLoadingAction => {
  return {
    type: Users.USERS_ADD_LOADING_SHOW,
  };
};

export const hideUsersAddLoading = (): HideUsersAddLoadingAction => {
  return {
    type: Users.USERS_ADD_LOADING_HIDE,
  };
};

export const showUserLoading = (): ShowUserLoadingAction => {
  return {
    type: Users.USER_LOADING_SHOW,
  };
};

export const hideUserLoading = (): HideUserLoadingAction => {
  return {
    type: Users.USER_LOADING_HIDE,
  };
};

export const fetchUsersRequest = (): FetchUsersRequestAction => {
  return {
    type: Users.FETCH_USERS_REQUEST,
  };
};

export const fetchUsersSuccessUpdate = (
  data: ResponseWithMeta<User[]>
): FetchUsersSuccessUpdateAction => {
  return {
    type: Users.FETCH_USERS_SUCCESS_UPDATE,
    payload: data,
  };
};

export const fetchUsersSuccessJoin = (
  data: ResponseWithMeta<User[]>
): FetchUsersSuccessJoinAction => {
  return {
    type: Users.FETCH_USERS_SUCCESS_JOIN,
    payload: data,
  };
};

export const fetchUsersClientsSuccessUpdate = (
  data: ResponseWithMeta<User[]>
): FetchUsersClientsSuccessUpdateAction => {
  return {
    type: Users.FETCH_USERS_CLIENTS_SUCCESS_UPDATE,
    payload: data,
  };
};

export const fetchUsersClientsSuccessJoin = (
  data: ResponseWithMeta<User[]>
): FetchUsersClientsSuccessJoinAction => {
  return {
    type: Users.FETCH_USERS_CLIENTS_SUCCESS_JOIN,
    payload: data,
  };
};

export const fetchUsersSpecialistsSuccessUpdate = (
  data: ResponseWithMeta<User[]>
): FetchUsersSpecialistsSuccessUpdateAction => {
  return {
    type: Users.FETCH_USERS_SPECIALISTS_SUCCESS_UPDATE,
    payload: data,
  };
};

export const fetchUsersSpecialistsSuccessJoinAction = (
  data: ResponseWithMeta<User[]>
): FetchUsersSpecialistsSuccessJoinAction => {
  return {
    type: Users.FETCH_USERS_SPECIALISTS_SUCCESS_JOIN,
    payload: data,
  };
};

export const fetchUsersClientsRequest = (
  payload: FetchRequestArgs<UsersTicketsPayload>
): FetchUserClientsRequestAction => {
  return {
    type: Users.FETCH_USER_CLIENTS_REQUEST,
    payload,
  };
};

export const fetchUsersSpecialistsRequest = (
  payload: FetchRequestArgs<UsersTicketsPayload>
): FetchSpecialistsRequestAction => {
  return {
    type: Users.FETCH_USER_SPECIALISTS_REQUEST,
    payload,
  };
};

export const fetchSubordinatesRequest = (): FetchSubordinatesRequestAction => {
  return {
    type: Users.FETCH_SUBORDINATES_REQUEST,
  };
};

export const fetchSubordinatesSuccess = (
  data: User[]
): FetchSubordinatesSuccessAction => {
  return {
    type: Users.FETCH_SUBORDINATES_SUCCESS,
    payload: data,
  };
};

export const setUsersPage = (page: number): SetUsersPageAction => {
  return {
    type: Users.SET_USERS_PAGE,
    payload: page,
  };
};

export const setSizePage = (size: number): SetSizePageAction => {
  return {
    type: Users.SET_SIZE_PAGE_USERS,
    payload: size,
  };
};

export const setSortUsers = (sort: string): SetSortUsersAction => {
  return {
    type: Users.SET_SORT_USERS,
    payload: sort,
  };
};

export const fetchCurrentUser = (id: string): FetchUserRequestAction => {
  return {
    type: Users.FETCH_USER_REQUEST,
    payload: id,
  };
};

export const setCurrentUserSuccess = (
  data?: User
): FetchCurrentUserSuccessAction => {
  return {
    type: Users.FETCH_CURRENT_USER_SUCCESS,
    payload: data,
  };
};

export const createUser = (data: CreateUserData): CreateUserAction => {
  return {
    type: Users.CREATE_USER,
    payload: data,
  };
};

export const editUser = (data: CreateUserData): EditUserAction => {
  return {
    type: Users.EDIT_USER,
    payload: data,
  };
};

export const setUserId = (id?: string): SetUserIdAction => {
  return {
    type: Users.SET_USER_ID,
    payload: id,
  };
};

export const resetUsersState = (): ResetUsersStateAction => {
  return {
    type: Users.RESET_USERS_STATE,
  };
};

export const fetchUsersByGroupId = (): FetchUsersByGroupsIdRequestAction => {
  return {
    type: Users.FETCH_USERS_BY_GROUPS_ID_REQUEST,
  };
};

export const setUserLogin = (login?: string): SetUserLoginAction => {
  return {
    type: Users.SET_USER_LOGIN,
    payload: login,
  };
};

export const resetUserCurrentState = (): ResetCurrentUserAction => {
  return {
    type: Users.RESET_CURRENT_USER_STATE,
  };
};

export const updateUserDataRequest = (
  data: User
): UpdateUserDataRequestAction => {
  return {
    type: Users.UPDATE_USER_DATA_REQUEST,
    payload: data,
  };
};

export const setUsersAddFilter = (data: UsersFilter) => {
  return {
    type: Users.SET_USERS_ADD_FILTER,
    payload: data,
  };
};

export const setSubordinatesFilter = (data: UsersFilter) => {
  return {
    type: Users.SET_SUBORDINATES_FILTER,
    payload: data,
  };
};

export const fetchUsersAddSuccessUpdate = (data: ResponseWithMeta<User[]>) => {
  return {
    type: Users.FETCH_USERS_ADD_SUCCESS_UPDATE,
    payload: data,
  };
};

export const fetchUsersAddSuccessJoin = (data: ResponseWithMeta<User[]>) => {
  return {
    type: Users.FETCH_USERS_ADD_SUCCESS_JOIN,
    payload: data,
  };
};

export const setUsersFilter = (data: UsersFilter) => {
  return {
    type: Users.SET_USERS_FILTER,
    payload: data,
  };
};

export const updateUserRolesInGroup = (data: string[]) => {
  return {
    type: Users.UPDATE_USER_ROLES_IN_GROUP,
    payload: data,
  };
};

export const updateUserRolesInSystem = (data: string[]) => {
  return {
    type: Users.UPDATE_USER_ROLES_IN_SYSTEM,
    payload: data,
  };
};

export const resetUsersAddState = (): ResetUsersAddStateAction => {
  return {
    type: Users.RESET_USERS_ADD_STATE,
  };
};

export const fetchUsersAddByOrgId = (): FetchUsersAddByOrgIdRequestAction => {
  return {
    type: Users.FETCH_USERS_ADD_BY_ORG_ID_REQUEST,
  };
};

export const setUsersAddPage = (page: number): SetUsersAddPageAction => {
  return {
    type: Users.SET_USERS_ADD_PAGE,
    payload: page,
  };
};

export const setSizePageUsersAdd = (size: number): SetSizePageAddAction => {
  return {
    type: Users.SET_SIZE_PAGE_USERS_ADD,
    payload: size,
  };
};

export const setSortUsersAdd = (sort: string): SetSortUsersAddAction => {
  return {
    type: Users.SET_SORT_USERS_ADD,
    payload: sort,
  };
};

export const checkIsExistLoginRequest = (
  login: string
): CheckIsExistLoginRequestAction => {
  return {
    type: Users.CHECK_IS_EXIST_LOGIN_REQUEST,
    payload: login,
  };
};

export const checkIsExistLoginSuccess = (
  isExist: boolean
): CheckIsExistLoginSuccessAction => {
  return {
    type: Users.CHECK_IS_EXIST_LOGIN_SUCCESS,
    payload: isExist,
  };
};

export const checkIsExistEmailRequest = (
  email: string
): CheckIsExistEmailRequestAction => {
  return {
    type: Users.CHECK_IS_EXIST_EMAIL_REQUEST,
    payload: email,
  };
};

export const checkIsExistEmailSuccess = (
  isExist: boolean
): CheckIsExistEmailSuccessAction => {
  return {
    type: Users.CHECK_IS_EXIST_EMAIL_SUCCESS,
    payload: isExist,
  };
};

export const fetchUsersAddByContractId =
  (): FetchUsersAddByContractIdRequestAction => {
    return {
      type: Users.FETCH_USERS_ADD_BY_CONTRACT_ID_REQUEST,
    };
  };

export const checkIsExistPhoneRequest = (
  phone: string
): CheckIsExistPhoneRequestAction => {
  return {
    type: Users.CHECK_IS_EXIST_PHONE_REQUEST,
    payload: phone,
  };
};

export const checkIsExistPhoneSuccess = (
  isExist: boolean
): CheckIsExistPhoneSuccessAction => {
  return {
    type: Users.CHECK_IS_EXIST_PHONE_SUCCESS,
    payload: isExist,
  };
};

export const deleteUserRequest = (
  data: DeleteDataType
): DeleteUserRequestAction => {
  return {
    type: Users.DELETE_USER_BY_ID_REQUEST,
    payload: data,
  };
};

export const resetCurrentUserAfterDelete =
  (): ResetCurrentUserAfterDeleteAction => {
    return {
      type: Users.RESET_CURRENT_USER_AFTER_DELETE,
    };
  };

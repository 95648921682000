import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'components/Table';
import { WorkGroup } from 'core/types';
import { fetchFunctions } from 'features/WorkGroups/hooks/useWorkGroupsTableContainer';

import { WORK_GROUPS_FIELDS_SHORT } from '../../constants';
import {
  setCurrentWorkGroupsPage,
  setWorkGroupsFilter,
} from '../../ducks/actions';
import { getLoading } from '../../ducks/selectors';
import { TableWorkGroupsTypes, WorkGroupFilter } from '../../types';
import { WorkGroupsFilter } from '../WorkGroupsFilter';

import { getDataTable } from './utils';

interface Props {
  workGroups?: WorkGroup[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  activeRowId?: string;
  tableType: TableWorkGroupsTypes;
  customLoading?: boolean;
  customFilter?: JSX.Element;
}

export const WorkGroupsShortTable: React.FC<Props> = ({
  workGroups,
  sort,
  handleSort,
  handleRowClick,
  showFilterRow,
  isFilterActive,
  activeRowId,
  tableType,
  customLoading = false,
  customFilter,
}) => {
  const dispatch = useDispatch();

  const data = getDataTable(workGroups);

  const handleFilter = (filterData: WorkGroupFilter) => {
    dispatch(setCurrentWorkGroupsPage(0));
    dispatch(setWorkGroupsFilter(filterData));
    dispatch(fetchFunctions[tableType]());
  };

  const loading = useSelector(getLoading);

  const filterComponent = customFilter || (
    <WorkGroupsFilter
      tableType={TableWorkGroupsTypes.FROM_ORGANIZATIONS}
      onFilter={handleFilter}
    />
  );

  return (
    <Table
      data={data}
      columns={WORK_GROUPS_FIELDS_SHORT}
      onRowClick={handleRowClick}
      sort={sort}
      onSort={handleSort}
      dataLoading={loading || customLoading}
      isFilterActive={isFilterActive}
      activeRowId={activeRowId}
      valueFieldName="id"
      filterComponent={showFilterRow && filterComponent}
      disabledSort={loading}
    />
  );
};

import { ValueType } from 'components/Select/types';
import { OrganizationType } from 'core/types';

export enum AccessArticleType {
  COMMON = 'COMMON',
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
}

export type Article = {
  id?: string;
  title: string;
  text: string;
  organizationId: string;
  organizationTitle: string;
  organizationType?: OrganizationType;
  userId: string;
  userFullName: string;
  categoryId: string;
  categoryTitle: string;
  parentCategoryTitle?: string;
  parentCategoryId?: string;
  accessType: AccessArticleType;
  systemId?: string;
  systemVersion?: string;
  systemTitle?: string;
  created: Date;
  updated: Date;
  isSolution?: boolean;
};

export type ArticleCreateData = Partial<Article>;

export type ArticleCreateFormData = Pick<
  Article,
  'accessType' | 'text' | 'title'
> & {
  organization: ValueType<string>;
  category: ValueType<string>;
  parentCategory?: ValueType<string>;
};

export type CategoryArticle = {
  id?: string;
  title: string;
  parentId?: string;
  parentTitle?: string;
  organizationId?: string;
  organizationType?: OrganizationType;
  children?: Array<CategoryArticle>;
  articles?: Article[];
};

export type CategoryArticleCreateForm = Omit<
  CategoryArticle,
  'organizationId' | 'children'
> & {
  organizationId: ValueType<string>;
};

export interface KBArticleTableFilter
  extends Partial<Pick<Article, 'updated' | 'title'>> {
  organization?: ValueType<string>;
  category?: ValueType<string>;
  parentCategory?: ValueType<string>;
  username?: string;
}

export interface KBArticleTableFilterToRequest
  extends Omit<
    KBArticleTableFilter,
    'organization' | 'category' | 'parentCategory'
  > {
  organizationIds?: string[];
  categoryId?: string;
  parentCategoryId?: string;
}

export interface KBOrganizationsFilter {
  organizationTitle?: string;
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'components/Table';
import { Role } from 'core/types';
import { getFetchFunction } from 'features/Roles/hooks/useRolesTableContainer';
import { getCurrentUserId } from 'features/Users/ducks/selectors';
import { getCurrentGroupId } from 'features/WorkGroups/ducks/selectors';

import { ROLES_FROM_USERS_FIELDS } from '../../constants';
import { setCurrentRolesPage, setRolesFilter } from '../../ducks/actions';
import { getLoading } from '../../ducks/selectors';
import { RolesFilter as RolesFilterData, TableRolesTypes } from '../../types';
import { RolesFilter } from '../RolesFilter';

import { getDataTable } from './utils';

interface Props {
  roles?: Role[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const RolesFromGroupsTable: React.FC<Props> = ({
  roles,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const dispatch = useDispatch();

  const groupId = useSelector(getCurrentGroupId);
  const userId = useSelector(getCurrentUserId);
  const loading = useSelector(getLoading);

  const data = getDataTable(roles);

  const handleFilter = (values: RolesFilterData) => {
    dispatch(setRolesFilter(values));
    dispatch(setCurrentRolesPage(0));
    dispatch(getFetchFunction(TableRolesTypes.FROM_GROUPS, groupId, userId)());
  };

  return (
    <Table
      data={data}
      columns={ROLES_FROM_USERS_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <RolesFilter
            tableType={TableRolesTypes.FROM_GROUPS}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
    />
  );
};

import { FetchRequestArgs } from 'components/SearchSelect/types';
import {
  CreateSettingFields,
  SettingFields,
} from 'components/Table/components/SettingFields/types';
import { Action, TicketType } from 'core/types';
import { Ticket } from 'features/Ticket/types';
import {
  CreateFilterRequest,
  DeleteFilterRequest,
  FetchTickets,
  FilterData,
  SetCurrentFilterTitleRequest,
  TicketsFilterForRender,
  TicketTabType,
} from 'features/Tickets/types';
import { ResponseWithMeta } from 'store/types';

import {
  AddSpecAndSetToWorkAction,
  AddSpecAndSetToWorkPayload,
  CreateFilterRequestAction,
  CreateTicketsSettingFieldsRequestAction,
  CreateTicketsSettingFieldsSuccessAction,
  DeleteFilterRequestAction,
  DeleteTicketRequestAction,
  FetchActionsForCurrentTicketSuccessAction,
  FetchActualTicketsSettingFieldsRequestAction,
  FetchFiltersDataRequestAction,
  FetchFiltersDataSuccessAction,
  FetchOpenTicketsCountByContactIdRequestAction,
  FetchOpenTicketsCountByContactIdSuccessAction,
  FetchTicketRequestAction,
  FetchTicketsRequestAction,
  FetchTicketsSettingFieldsRequestAction,
  FetchTicketsSettingFieldsSuccessAction,
  FetchTicketsSuccessAction,
  FetchTicketStatusRequestAction,
  FetchTicketStatusSuccessJoinAction,
  FetchTicketStatusSuccessUpdateAction,
  FetchTicketTypesRequestAction,
  FetchTicketTypesSuccessJoinAction,
  FetchTicketTypesSuccessUpdateAction,
  HideTicketLoadingAction,
  HideTicketsLoadingAction,
  HideTypesLoadingAction,
  OpenSettingFieldsAction,
  ResetCurrentTicketAction,
  ResetFiltersStateAction,
  ResetTicketsStateAction,
  ResetTicketStatusStateAction,
  ResetTicketTypeStateAction,
  SetCurrentFilterIdAction,
  SetCurrentFilterTitleRequestAction,
  SetCurrentTicketId,
  SetCurrentTicketPageAction,
  SetCurrentTicketSuccessAction,
  SetIsUsedFilterAction,
  SetSizePageAction,
  SetSizePageStatusAction,
  SetSizePageTypeAction,
  SetSortTicketsAction,
  SetTicketsFilterAction,
  ShowTicketLoadingAction,
  ShowTicketsLoadingAction,
  ShowTypesLoadingAction,
  Tickets,
  TicketsStatusFilterForRender,
  TicketsTypesPayload,
  ToggleTicketsSearchAction,
  UpdateTicketByIdRequestAction,
  UpdateTicketByIdSuccessAction,
} from './types';

export const showTicketsLoading = (): ShowTicketsLoadingAction => {
  return {
    type: Tickets.TICKETS_LOADING_SHOW,
  };
};

export const hideTicketsLoading = (): HideTicketsLoadingAction => {
  return {
    type: Tickets.TICKETS_LOADING_HIDE,
  };
};

export const showTicketLoading = (): ShowTicketLoadingAction => {
  return {
    type: Tickets.TICKET_LOADING_SHOW,
  };
};

export const hideTicketLoading = (): HideTicketLoadingAction => {
  return {
    type: Tickets.TICKET_LOADING_HIDE,
  };
};

export const toggleTicketsSearch = (): ToggleTicketsSearchAction => {
  return {
    type: Tickets.TOGGLE_TICKETS_SEARCH,
  };
};

export const fetchTicketsRequest = ({
  ticketTab = TicketTabType.ALL_TICKETS,
  withFirstTicketFetch = false,
}: FetchTickets): FetchTicketsRequestAction => {
  return {
    type: Tickets.FETCH_TICKETS_REQUEST,
    payload: {
      ticketTab,
      withFirstTicketFetch,
    },
  };
};

export const fetchTicketsSuccess = (
  data: ResponseWithMeta<Ticket[]>
): FetchTicketsSuccessAction => {
  return {
    type: Tickets.FETCH_TICKETS_SUCCESS,
    payload: data,
  };
};

export const fetchTicketsCountByContractIdRequest = (
  contractIds: string[]
): FetchOpenTicketsCountByContactIdRequestAction => {
  return {
    type: Tickets.FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_REQUEST,
    payload: contractIds,
  };
};

export const fetchTicketsCountByContractIdSuccess = (
  count: number
): FetchOpenTicketsCountByContactIdSuccessAction => {
  return {
    type: Tickets.FETCH_OPEN_TICKETS_COUNT_BY_CONTRACT_ID_SUCCESS,
    payload: count,
  };
};

export const fetchTicketRequest = (
  payload: string
): FetchTicketRequestAction => {
  return {
    type: Tickets.FETCH_TICKET_REQUEST,
    payload,
  };
};

export const setCurrentTicket = (
  data?: Ticket
): SetCurrentTicketSuccessAction => {
  return {
    type: Tickets.SET_CURRENT_TICKET_SUCCESS,
    payload: data,
  };
};

export const setCurrentTicketPage = (
  page: number
): SetCurrentTicketPageAction => {
  return {
    type: Tickets.SET_CURRENT_TICKETS_PAGE,
    payload: page,
  };
};

export const setSizePage = (size: number): SetSizePageAction => {
  return {
    type: Tickets.SET_SIZE_PAGE,
    payload: size,
  };
};

export const setSortTickets = (sort: string): SetSortTicketsAction => {
  return {
    type: Tickets.SET_SORT_TICKETS,
    payload: sort,
  };
};

export const setTicketsFilter = (
  filter?: TicketsFilterForRender
): SetTicketsFilterAction => {
  return {
    type: Tickets.SET_FILTER_TICKETS,
    payload: filter,
  };
};

export const addSpecAndSetToWork = (
  payload: AddSpecAndSetToWorkPayload
): AddSpecAndSetToWorkAction => {
  return {
    type: Tickets.ADD_SPEC_AND_SET_TO_WORK,
    payload,
  };
};

export const resetTicketsState = (): ResetTicketsStateAction => {
  return {
    type: Tickets.RESET_TICKETS_STATE,
  };
};

export const updateTicketByIdSuccess = (
  payload: Ticket
): UpdateTicketByIdSuccessAction => {
  return {
    type: Tickets.UPDATE_TICKET_BY_ID_SUCCESS,
    payload,
  };
};

export const updateTicketByIdRequest = (
  ticketId: string
): UpdateTicketByIdRequestAction => {
  return {
    payload: ticketId,
    type: Tickets.UPDATE_TICKET_BY_ID_REQUEST,
  };
};

export const setCurrentTicketId = (ticketId?: string): SetCurrentTicketId => {
  return {
    payload: ticketId,
    type: Tickets.SET_CURRENT_TICKET_ID,
  };
};

export const fetchActionsForCurrentTicketSuccess = (
  payload: Action[]
): FetchActionsForCurrentTicketSuccessAction => {
  return {
    payload,
    type: Tickets.FETCH_ACTIONS_FOR_CURRENT_TICKET_SUCCESS,
  };
};

export const deleteTicketRequest = (
  ticket: Ticket
): DeleteTicketRequestAction => {
  return {
    type: Tickets.DELETE_TICKET_REQUEST,
    payload: ticket,
  };
};

export const resetCurrentTicket = (): ResetCurrentTicketAction => {
  return {
    type: Tickets.RESET_CURRENT_TICKET,
  };
};

export const fetchFiltersDataRequest = (
  payload: TicketTabType
): FetchFiltersDataRequestAction => {
  return {
    type: Tickets.FETCH_FILTERS_DATA_REQUEST,
    payload,
  };
};

export const fetchFiltersDataSuccess = (
  payload: FilterData[]
): FetchFiltersDataSuccessAction => {
  return {
    type: Tickets.FETCH_FILTERS_DATA_SUCCESS,
    payload,
  };
};

export const createFilterRequest = (
  payload: CreateFilterRequest
): CreateFilterRequestAction => {
  return {
    type: Tickets.CREATE_FILTER_REQUEST,
    payload,
  };
};

export const deleteFilterRequest = (
  payload: DeleteFilterRequest
): DeleteFilterRequestAction => {
  return {
    type: Tickets.DELETE_FILTER_REQUEST,
    payload,
  };
};

export const setCurrentFilterTitleRequest = (
  payload: SetCurrentFilterTitleRequest
): SetCurrentFilterTitleRequestAction => {
  return {
    type: Tickets.SET_CURRENT_FILTER_TITLE_REQUEST,
    payload,
  };
};

export const setCurrentFilterId = (
  payload: string
): SetCurrentFilterIdAction => {
  return {
    type: Tickets.SET_CURRENT_FILTER_ID,
    payload,
  };
};

export const setIsUsedFilter = (payload: boolean): SetIsUsedFilterAction => {
  return {
    type: Tickets.SET_IS_USED_FILTER,
    payload,
  };
};

export const resetFiltersState = (): ResetFiltersStateAction => {
  return {
    type: Tickets.RESET_FILTERS_STATE,
  };
};

export const openSettingFields = (
  payload: boolean
): OpenSettingFieldsAction => {
  return {
    type: Tickets.OPEN_SETTING_FIELDS,
    payload,
  };
};

export const fetchTicketsSettingFieldsRequest =
  (): FetchTicketsSettingFieldsRequestAction => {
    return {
      type: Tickets.FETCH_TICKETS_SETTING_FIELDS_REQUEST,
    };
  };

export const fetchTicketsSettingFieldSuccess = (
  payload: SettingFields
): FetchTicketsSettingFieldsSuccessAction => {
  return {
    type: Tickets.FETCH_TICKETS_SETTING_FIELD_SUCCESS,
    payload,
  };
};

export const fetchActualTicketsSettingFieldsRequest =
  (): FetchActualTicketsSettingFieldsRequestAction => {
    return {
      type: Tickets.FETCH_ACTUAL_TICKETS_SETTING_FIELDS_REQUEST,
    };
  };

export const createTicketsSettingFieldsRequest = (
  payload: CreateSettingFields
): CreateTicketsSettingFieldsRequestAction => {
  return {
    type: Tickets.CREATE_TICKETS_SETTING_FIELDS_REQUEST,
    payload,
  };
};

export const createTicketsSettingFieldSuccess = (
  payload: SettingFields
): CreateTicketsSettingFieldsSuccessAction => {
  return {
    type: Tickets.CREATE_TICKETS_SETTING_FIELD_SUCCESS,
    payload,
  };
};

export const fetchTicketTypesRequest = (
  payload: TicketsTypesPayload
): FetchTicketTypesRequestAction => {
  return {
    type: Tickets.FETCH_TICKET_TYPES_REQUEST,
    payload,
  };
};

export const fetchTicketTypesUpdateSuccess = (
  payload: ResponseWithMeta<TicketType[]>
): FetchTicketTypesSuccessUpdateAction => {
  return {
    type: Tickets.FETCH_TICKET_TYPES_SUCCESS_UPDATE,
    payload,
  };
};

export const fetchTicketTypesJoinSuccess = (
  payload: ResponseWithMeta<TicketType[]>
): FetchTicketTypesSuccessJoinAction => {
  return {
    type: Tickets.FETCH_TICKET_TYPES_SUCCESS_JOIN,
    payload,
  };
};

export const showTypesLoading = (): ShowTypesLoadingAction => {
  return {
    type: Tickets.TYPE_LOADING_SHOW,
  };
};

export const hideTypesLoading = (): HideTypesLoadingAction => {
  return {
    type: Tickets.TYPE_LOADING_HIDE,
  };
};

export const resetTicketTypeState = (): ResetTicketTypeStateAction => {
  return {
    type: Tickets.RESET_TICKET_TYPES,
  };
};

export const setSizeTypePage = (size: number): SetSizePageTypeAction => {
  return {
    type: Tickets.SET_SIZE_TYPE_PAGE,
    payload: size,
  };
};

export const fetchStatusesRequest = (
  payload: FetchRequestArgs<TicketsStatusFilterForRender>
): FetchTicketStatusRequestAction => {
  return {
    type: Tickets.FETCH_TICKET_STATUS_REQUEST,
    payload,
  };
};

export const fetchTicketStatusUpdateSuccess = (
  payload: ResponseWithMeta<string[]>
): FetchTicketStatusSuccessUpdateAction => {
  return {
    type: Tickets.FETCH_TICKET_STATUS_SUCCESS_UPDATE,
    payload,
  };
};

export const fetchTicketStatusJoinSuccess = (
  payload: ResponseWithMeta<string[]>
): FetchTicketStatusSuccessJoinAction => {
  return {
    type: Tickets.FETCH_TICKET_STATUS_SUCCESS_JOIN,
    payload,
  };
};

export const resetTicketStatusState = (): ResetTicketStatusStateAction => {
  return {
    type: Tickets.RESET_TICKET_STATUS,
  };
};

export const setSizeStatusPage = (size: number): SetSizePageStatusAction => {
  return {
    type: Tickets.SET_SIZE_STATUS_PAGE,
    payload: size,
  };
};

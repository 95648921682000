import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { Tag, TagsFilter } from 'features/Ticket/types';
import { ReducerMap } from 'store/types';
import { Pagination } from 'types/models/meta';

import {
  FetchTagsSuccessJoinAction,
  FetchTagsSuccessUpdateAction,
  GetTagsByNameSuccessAction,
  SetCurrentPageTagsAction,
  SetFilterTagsAction,
  Tags,
  TagsActionsTypes,
} from './types';

export interface TagsReducerState {
  tags?: Tag[];
  currentTag?: Tag[];
  loading?: boolean;
  pagination?: Pagination;
  filter?: TagsFilter;
}

const initialState: TagsReducerState = {
  tags: [],
  loading: false,
  pagination: DEFAULT_PAGINATION_PARAMS,
  filter: {},
};

const tagsReducerMap: ReducerMap<TagsReducerState, TagsActionsTypes> = {
  [Tags.TAGS_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [Tags.TAGS_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [Tags.FETCH_TAGS_SUCCESS_JOIN]: (state, action) => {
    const { payload } = action as FetchTagsSuccessJoinAction;
    const { content, totalElements } = payload;
    return {
      ...state,
      tags: [...(state?.tags || []), ...content],
      pagination: {
        ...state?.pagination,
        totalElements,
      },
    };
  },
  [Tags.FETCH_TAGS_SUCCESS_UPDATE]: (state, action) => {
    const { payload } = action as FetchTagsSuccessUpdateAction;
    const { content, totalElements } = payload;
    return {
      ...state,
      tags: content,
      pagination: {
        ...state?.pagination,
        totalElements,
      },
    };
  },
  [Tags.RESET_TAGS_STATE]: () => {
    return {
      ...initialState,
    };
  },
  [Tags.SET_FILTER_TAGS]: (state, action) => {
    const { payload } = action as SetFilterTagsAction;
    return {
      ...state,
      filter: payload,
    };
  },
  [Tags.SET_CURRENT_PAGE_TAGS]: (state, action) => {
    const { payload } = action as SetCurrentPageTagsAction;
    return {
      ...state,
      pagination: {
        ...state?.pagination,
        pageNum: payload,
      },
    };
  },
  [Tags.GET_TAGS_BY_NAME_SUCCESS]: (state, action) => {
    const { payload } = action as GetTagsByNameSuccessAction;
    return {
      ...state,
      currentTag: payload,
    };
  },
};

export const tags: Reducer<TagsReducerState, TagsActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = tagsReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};

import { SelectOption } from 'components/Select/types';
import { RenderTypes, TableColumns } from 'components/Table/types';
import { ContractStatus, ContractType, SupportType } from 'core/types';

export const SELECT_TYPES_LIST: SelectOption<ContractType>[] = [
  { title: 'Первичный', value: ContractType.PRIMARY },
  { title: 'Вторичный', value: ContractType.SECONDARY },
];

export const CONTRACT_STATUS_TITLE_MAP = {
  [ContractStatus.ACTIVE]: 'Активен',
  [ContractStatus.CLOSED]: 'Завершен',
  [ContractStatus.PLANNED]: 'Запланирован',
  [ContractStatus.NOT_SIGNED]: 'Не подписан',
};

export const CONTRACT_TYPE_TITLE_MAP = {
  [ContractType.PRIMARY]: 'Первичный',
  [ContractType.SECONDARY]: 'Вторичный',
  [ContractType.SUPPLEMENTARY_AGREEMENT]: 'Дополнительное соглашение',
};

export const TABLE_HEADER_FROM_SUPPLEMENTARY_AGREEMENT: TableColumns = [
  {
    title: 'Название',
    fieldName: 'title',
    sortField: 'title',
    renderType: RenderTypes.NOTIFICATION_LINK,
  },
  {
    title: 'Система',
    fieldName: 'systemTitle',
  },
  {
    title: 'Срок поддержки',
    fieldName: 'supportPeriod',
  },
];

export const DEFAULT_CONTRACT_FORM_VALUES = {
  openDate: '',
  endDate: '',
  supportType: SupportType.EXTENDED,
  notificationConfig: {
    contractExpireIsEnable: false,
    notifyBeforeExpire: 0,
  },
};

import cn from 'clsx';
import React from 'react';

import { CircleIcon } from 'assets/icons';

import { Typography, TypographyVariants } from '../../Typography';

import styles from './RadioItem.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  icon?: React.ReactNode;
}

export const RadioItem: React.FC<Props> = React.memo(
  ({ title, disabled, icon, ...other }) => {
    return (
      <label
        className={cn(styles.radioItem, {
          [styles.radioItem_disabled]: disabled,
        })}
      >
        <input
          type="radio"
          disabled={disabled}
          className={styles.radioItem__input}
          {...other}
        />
        <CircleIcon className={styles.radioItem__icon} />
        {title && (
          <Typography
            variant={TypographyVariants.b3}
            className={styles.radioItem__title}
          >
            {title}
          </Typography>
        )}
        {icon}
      </label>
    );
  }
);

import { Priority, ProcedureType } from 'core/types';
import { SLAChoiceType, SLAType } from 'features/Contract/types';

export type SystemContractId = {
  systemId: string;
  contractId: string;
};

export type SystemPriority = {
  id: string;
  title: string;
  value: Priority;
  type: ProcedureType;
};

export enum TableSystemsTypes {
  FULL = 'FULL',
  FROM_CONTRACTS = 'FROM_CONTRACTS',
}

export interface CreateSystemDataAdd
  extends Pick<
    SLAType,
    | 'title'
    | 'workingHoursFrom'
    | 'workingHoursTo'
    | 'autoClosableTickets'
    | 'closeAfterHours'
    | 'expireReactionTimeInDecisionTime'
  > {
  systemId?: string;
  createType?: SLAChoiceType;
}

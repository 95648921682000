import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MoreTag } from 'components/MoreTag/MoreTag';
import { Table } from 'components/Table';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { TABLE_HEADER } from 'features/Rules/constants';
import { updateRule } from 'features/Rules/ducks';
import { getKeyFromKeyValue } from 'features/Rules/utils';
import { KeyValueOption } from 'types/models/meta';

import { getLoading } from '../../ducks/selectors';
import { CreateRule, Rule } from '../../types';
import { RulesFilter } from '../RulesFilter';

import styles from './RulesFullTable.module.scss';

const getMoreTag = (data: KeyValueOption[]) => {
  return (!!data?.length && <MoreTag data={data} />) || '-';
};

const getDataTable = (
  rules?: Rule[],
  ruleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  updateHandler?: (data: CreateRule) => void
) =>
  rules?.map((rule) => {
    const {
      title,
      id,
      organizations,
      systems,
      ticketTypes,
      ticketPriorities,
      environments,
      clients,
      isEnable,
    } = rule;

    const onChangeAction = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (updateHandler) {
        const selectedRule = rules.find((item) => item.id === rule.id);

        if (selectedRule) {
          const attributes = {
            organizations: getKeyFromKeyValue(selectedRule.organizations),
            systems: getKeyFromKeyValue(selectedRule.systems),
            ticketTypes: getKeyFromKeyValue(selectedRule.ticketTypes),
            ticketPriorities: getKeyFromKeyValue(selectedRule.ticketPriorities),
            environments: getKeyFromKeyValue(selectedRule.environments),
            clients: getKeyFromKeyValue(selectedRule.clients),
          };

          const targetSpecialist = selectedRule.targetSpecialist?.key;
          const targetWorkgroup = selectedRule.targetWorkgroup?.key;

          const preparedData = {
            title: selectedRule.title,
            conditionType: selectedRule.conditionType,
            minutes: selectedRule.minutes,
            ...(targetSpecialist && { targetSpecialist }),
            ...(targetWorkgroup && { targetWorkgroup }),
            isEnable: event.target.checked,
            ...attributes,
          };
          updateHandler(preparedData);
        }
      }
    };

    return {
      id,
      title: (
        <button onClick={ruleClick} className={styles.rulesFullTable__title}>
          {title}
        </button>
      ),
      isActive: <ToggleSwitch checked={isEnable} onChange={onChangeAction} />,
      organizations: getMoreTag(organizations),
      systems: getMoreTag(systems),
      ticketTypes: getMoreTag(ticketTypes),
      ticketPriorities: getMoreTag(ticketPriorities),
      environments: getMoreTag(environments),
      clients: getMoreTag(clients),
    };
  }) || [];

interface Props {
  rules?: Rule[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleClickRule?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  handleClickRuleEdit?: () => void;
}

export const RulesFullTable: React.FC<Props> = ({
  rules,
  sort,
  handleSort,
  handleClickRule,
  activeRowId,
  showFilterRow,
  isFilterActive,
  handleClickRuleEdit,
}) => {
  const dispatch = useDispatch();

  const updateHandler = (data: CreateRule) => {
    dispatch(updateRule(data));
  };

  const onRuleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (handleClickRuleEdit) {
      handleClickRuleEdit();
    }
  };

  const data = getDataTable(rules, onRuleClick, updateHandler);

  const loading = useSelector(getLoading);

  return (
    <Table
      data={data}
      columns={TABLE_HEADER}
      sort={sort}
      onRowClick={handleClickRule}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={showFilterRow && <RulesFilter />}
      disabledSort={loading}
    />
  );
};

import { ValueType } from 'components/Select/types';
import { RoleType } from 'core/types';

export type RoleCreateToRequest = {
  name: string;
  description: string;
  type?: RoleType;
  manager?: boolean;
  organization?: { id: string };
};

export interface CreateRoleData
  extends Omit<RoleCreateToRequest, 'organization'> {
  organizationId?: ValueType<string>;
}

export type RolesFilter = {
  name?: string;
  description?: string;
  manager?: ValueType<string>;
  organizationId?: ValueType<string>;
  type?: ValueType<RoleType>;
  idsRolesDelete?: Array<string>;
};

export interface RolesFilterToRequest
  extends Omit<RolesFilter, 'organizationId' | 'type' | 'manager'> {
  organizationId?: string;
  type?: RoleType;
  manager?: boolean | string;
}

export enum TableRolesTypes {
  FULL = 'FULL',
  FROM_USERS = 'FROM_USERS',
  ADD_MODAL = 'ADD_MODAL',
  FROM_GROUPS = 'FROM_GROUPS',
}

import { TableColumns } from 'components/Table/types';

export const ENVIRONMENT_FIELDS: TableColumns = [
  { title: 'Название', fieldName: 'name', sortField: 'name' },
];

export const ENVIRONMENT_MAINS = ['TEST', 'DEV', 'PROD'];

export const INITIAL_ENVIRONMENT_FILTER = {
  id: '',
  name: '',
  systemId: '',
  description: '',
};

import { ValueType } from 'components/Select/types';
import {
  Contract,
  ContractNotificationConfig,
  ContractType,
  EnvironmentsWithStatus,
} from 'core/types';
import { CurrentUser } from 'features/Auth/types';

import { SLATableDataType } from './components/SlaTable/types';

export type ContractSystem = {
  contractId: string;
  systemId?: string;
  slaId?: string;
  id?: string;
};

export enum ValidDateType {
  NO_DATA = 'NO_DATA',
  BEYOND_CONTRACT_START_DATE = 'VALID_BEYOND_CONTRACT_START_DATE',
  BEYOND_CONTRACT_END_DATE = 'VALID_BEYOND_CONTRACT_END_DATE',
  END_AFTER_START_DATE = 'END_AFTER_START_DATE',
}

export const validSupplementaryAgreementMap = {
  [ValidDateType.NO_DATA]: '',
  [ValidDateType.BEYOND_CONTRACT_START_DATE]:
    'Выходит за рамки сроков контракта',
  [ValidDateType.BEYOND_CONTRACT_END_DATE]: 'Выходит за рамки сроков контракта',
  [ValidDateType.END_AFTER_START_DATE]:
    'Дата окончания не может быть раньше даты начала',
};

export interface CreateContractData
  extends Pick<
    Contract,
    | 'id'
    | 'title'
    | 'number'
    | 'description'
    | 'openDate'
    | 'endDate'
    | 'status'
    | 'supportType'
  > {
  organizationId?: ValueType<string>;
  type?: ValueType<ContractType>;
  partnerOrganizationId?: ValueType<string>;
  notificationConfig: ContractNotificationConfig;
}

export enum SLAChoiceType {
  CREATE_NEW = 'CREATE_NEW',
  SELECT_FROM_LIST = 'SELECT_FROM_LIST',
}

export type SLAType = {
  id?: string;
  title?: string;
  workingHoursFrom: string;
  workingHoursTo: string;
  settings: SLATableDataType;
  searchTemplates?: string;
  searchSystems?: string;
  user?: CurrentUser;
  closeAfterHours?: number;
  autoClosableTickets?: boolean;
  expireReactionTimeInDecisionTime?: boolean;
  updatedWorkingHours?: boolean;
  updateWorkingHoursNameAgreement?: string;
  updatedAutoClose?: boolean;
  updateAutoCloseNameAgreement?: string;
  updatedReactionInDecision?: boolean;
  updateReactionInDecisionNameAgreement?: string;
};

export type SLASettings = {
  id?: string;
  reactionTime: number | string;
  decisionTime: number | string;
  workMode: boolean;
  updateDecisionTime?: boolean;
  updateReactionTime?: boolean;
  updateReactionNameSupplementaryAgreement?: string;
  updateDecisionNameSupplementaryAgreement?: string;
  updateWorkMode?: boolean;
  updateWorkModeNameSupplementaryAgreement?: string;
};

export type SLASettingsPriority = {
  name: string;
  checked: boolean;
};

export type UpdateEnvironmentSettings = EnvironmentsWithStatus &
  ContractIdSystemIdArgs;

export type ContractIdSystemIdArgs = {
  contractId: string;
  systemId: string;
};

import { Access, AccessesFilter } from 'features/Accesses/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const request = {
  fetchAccessesByRespId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: AccessesFilter
  ) =>
    ApiClient.post<ResponseWithMeta<Access>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getAccessesByRespId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  createAccessCategory: (data: Access) =>
    ApiClient.post<Access>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createAccess(),
      data: { ...data },
    }),
};

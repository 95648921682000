import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getReverseDateForRequest } from 'components/Table/components/TableFilter/utils';
import { DEFAULT_CUSTOM_FIELD_VALUES } from 'core/constants';
import { StorageCustomField, ValueCustomField } from 'core/types';

import {
  addStorageFileCardRequest,
  deleteDownloadedStorageFile,
  editStorageFileCardRequest,
  fetchCurrentStorageFileCardRequest,
  fetchStorageFileCardsRequest,
  filterUploadingFiles,
  resetCurrentStorageFileCard,
  resetDownloadedStorageFiles,
  resetUploadingFiles,
  uploadStorageFileRequest,
} from '../ducks';
import {
  getCurrentFileCard,
  getCustomFieldsWithValue,
  getDownloadedFiles,
  getUploadingFiles,
} from '../ducks/selectors';
import { AddFilesForm, ModalType } from '../types';
import { getCustomFieldsForRequest } from '../utils';

import { useStorageId } from './useStorageId';

interface Props {
  modalType: ModalType;
  fileCardId?: string;
  selectedStorages?: string[];
}

export const useStorageAddFiles = ({
  modalType,
  selectedStorages,
  fileCardId,
}: Props) => {
  const { add: addModalType, edit: editModalType } = modalType;

  const dispatch = useDispatch();

  const [myFiles, setMyFiles] = useState<File[]>([]);
  const [mainFileId, setMainFileId] = useState<string>('');
  const [warning, setWarning] = useState(false);
  const [error, setError] = useState(false);
  const [errorFileName, setErrorFileName] = useState('');
  const [currentFileId, setCurrentFileId] = useState('');
  const [customFields, setCustomFields] = useState<StorageCustomField[]>();

  const storageId = useStorageId();

  const downloadedFiles = useSelector(getDownloadedFiles);
  const currentFileCard = useSelector(getCurrentFileCard);
  const uploadingFiles = useSelector(getUploadingFiles);
  const customFieldsWithValue = useSelector(getCustomFieldsWithValue);

  const { setValue, handleSubmit, register, control, watch } =
    useForm<AddFilesForm>({
      mode: 'onChange',
    });
  const { fields } = useFieldArray({
    name: 'files',
    keyName: 'currentKey',
    control,
  });

  useEffect(() => {
    setValue('files', downloadedFiles);
  }, [downloadedFiles]);

  useEffect(() => {
    setCustomFields(customFieldsWithValue);
  }, [customFieldsWithValue]);

  useEffect(() => {
    if (editModalType) {
      if (currentFileCard) {
        setValue('files', [
          currentFileCard?.mainFile,
          ...currentFileCard.additionalFiles,
        ]);
      }
      if (currentFileCard?.mainFile.id) {
        setMainFileId(currentFileCard?.mainFile.id);
      }
    }
  }, [currentFileCard, editModalType]);

  useEffect(() => {
    if (editModalType) {
      dispatch(fetchCurrentStorageFileCardRequest({ storageId, fileCardId }));
    }
  }, [editModalType, storageId, fileCardId]);

  useEffect(() => {
    return () => {
      dispatch(resetUploadingFiles());
      dispatch(resetCurrentStorageFileCard());
      dispatch(resetDownloadedStorageFiles());
      setCustomFields(undefined);
    };
  }, []);

  const setFileHandler = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      dispatch(uploadStorageFileRequest(file));
    });
  };

  const cancelUploadingFile = (id: string) => {
    dispatch(filterUploadingFiles(id));
  };

  const removeField = (id: string) => {
    const currentFields = fields.filter((field) => field.id !== id);
    setValue('files', currentFields);

    dispatch(deleteDownloadedStorageFile(currentFields));
  };

  const getMaxFileName = (name: string) => {
    setErrorFileName(name);
    setError(true);
  };

  const handleCancelError = () => {
    setError(false);
  };

  const changeCurrentFile = (id: string) => {
    setCurrentFileId(id);
  };

  const changeMainFile = (id: string) => {
    setMainFileId(id);

    if (warning) {
      setWarning(false);
    }
    if (!warning) {
      setWarning(true);
    }
  };

  const changeCustomFields = (value: ValueCustomField, id?: string) => {
    setCustomFields((prev) => {
      return prev?.map((customField) => {
        if (customField.id === id) {
          return { ...customField, ...value };
        }
        return customField;
      });
    });
  };

  const addTextToCustomFields = useCallback((id?: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const values: ValueCustomField = {
        ...DEFAULT_CUSTOM_FIELD_VALUES,
        text: event.target.value,
      };
      changeCustomFields(values, id);
    };
  }, []);

  const addFlagToCustomFields = useCallback((id?: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const values: ValueCustomField = {
        ...DEFAULT_CUSTOM_FIELD_VALUES,
        flag: event.target.checked,
      };
      changeCustomFields(values, id);
    };
  }, []);

  const addDateToCustomFields = useCallback((id?: string) => {
    return (value: string) => {
      const values: ValueCustomField = {
        ...DEFAULT_CUSTOM_FIELD_VALUES,
        date: getReverseDateForRequest(value),
      };
      changeCustomFields(values, id);
    };
  }, []);

  useEffect(() => {
    if (downloadedFiles?.length) {
      setWarning(true);
    }
  }, [downloadedFiles]);

  const onSubmit = handleSubmit(({ files }) => {
    const mainFile = files?.find((file) => file.id === mainFileId);
    const additionalFiles = files?.filter((file) => file.id !== mainFileId);

    if (addModalType) {
      if (storageId && mainFile && additionalFiles) {
        dispatch(
          addStorageFileCardRequest({
            storageIds: [storageId],
            mainFile,
            additionalFiles,
            fileCardCustomFields: getCustomFieldsForRequest(customFields),
          })
        );

        dispatch(
          fetchStorageFileCardsRequest({
            id: storageId,
          })
        );
      }

      if (selectedStorages && mainFile && additionalFiles) {
        dispatch(
          addStorageFileCardRequest({
            storageIds: selectedStorages,
            mainFile,
            additionalFiles,
          })
        );
      }
    }

    if (editModalType && storageId && mainFile && additionalFiles) {
      dispatch(
        editStorageFileCardRequest({
          storageId,
          fileCardId,
          fileCard: {
            mainFile,
            additionalFiles,
            fileCardCustomFields: getCustomFieldsForRequest(customFields),
          },
        })
      );

      dispatch(
        fetchStorageFileCardsRequest({
          id: storageId,
        })
      );
    }
  });

  return {
    state: {
      myFiles,
      downloadedFiles: fields,
      mainFileId,
      warning,
      currentFileId,
      error,
      errorFileName,
      uploadingFiles,
      customFields,
    },
    methods: {
      setFileHandler,
      setMyFiles,
      cancelUploadingFile,
      changeMainFile,
      changeCurrentFile,
      removeField,
      onSubmit,
      register,
      getValues: watch,
      getMaxFileName,
      handleCancelError,
      addTextToCustomFields,
      addFlagToCustomFields,
      addDateToCustomFields,
    },
  };
};

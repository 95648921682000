import { WorkflowParams } from 'features/StatusesBuilder/types';
import { getUrl } from 'utils';

export const endpoints = {
  getSystems: () => getUrl('system/filter'),
  getOrganizations: () => getUrl('organization/filter'),
  getWorkflow: (systemId: string, typeId: string | undefined) =>
    getUrl(`workflows/system/${systemId}/type/${typeId}`),
  getTicketTypes: (systemId: string) => getUrl(`system/${systemId}`),
  saveWorkflow: ({ systemId, typeId, version }: WorkflowParams) =>
    getUrl(`workflows/system/${systemId}/type/${typeId}/version/${version}`),
};

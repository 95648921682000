import React from 'react';
import { useTranslation } from 'react-i18next';

import { MainLayout } from 'core/layouts/MainLayout';
import { Tickets as TicketsContent } from 'features/Tickets';

import styles from './Tickets.module.scss';

const Tickets = () => {
  const { t } = useTranslation('tickets');

  return (
    <MainLayout
      title={t('headerTitle')}
      withCreateTicketButton
      headerType="default"
      classNameRoot={styles.tickets}
    >
      <TicketsContent />
    </MainLayout>
  );
};

export default Tickets;

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthLoading } from 'features/Auth';
import {
  fetchPermissionsRequest,
  setCurrentUser,
} from 'features/Auth/ducks/actions';
import { getIsAuthorized } from 'features/Auth/ducks/selectors';
import { CurrentUser } from 'features/Auth/types';
import { AuthService, createError, sendMessage } from 'utils';
import { getEnv } from 'utils/getEnv';

export const withAuthAwaiter = <Props,>(Component: React.FC<Props>) => {
  return memo((props: Props) => {
    const isAuthorized = useSelector(getIsAuthorized);
    const dispatch = useDispatch();

    const loginHandler = () => {
      AuthService.initKeycloak()
        .then(() => {
          const parsedToken = AuthService.kc.tokenParsed;
          sendMessage({
            token: AuthService.kc.token,
            url: getEnv('REACT_APP_ATTACHMENT_URL'),
          });
          const user: CurrentUser = {
            firstName: parsedToken?.given_name,
            lastName: parsedToken?.family_name,
            fullName: parsedToken?.name,
            middleName: parsedToken?.middle_name,
            phoneNumber: parsedToken?.phone_number,
            email: parsedToken?.email,
            organizationTitle: parsedToken?.organization_title,
            userId: parsedToken?.business_user_id,
            roles: parsedToken?.realm_access?.roles,
          };
          if (parsedToken) {
            dispatch(setCurrentUser(user));
            dispatch(fetchPermissionsRequest());
          }

          setInterval(() => {
            AuthService.kc
              .updateToken(10)
              .then(() => {
                sendMessage({
                  token: AuthService.kc.token,
                  url: getEnv('REACT_APP_ATTACHMENT_URL'),
                });
              })
              .catch(() => AuthService.kc.logout());
          }, 10000);
        })
        .catch((e: string) => createError(e));
    };

    useEffect(() => {
      loginHandler();
    }, []);

    if (isAuthorized) {
      return <Component {...props} />;
    }
    return <AuthLoading />;
  });
};

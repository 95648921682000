import cn from 'clsx';
import React, { forwardRef, useMemo } from 'react';

import { BackArrowIcon } from 'assets/icons';
import { callbackWithStopPropagation } from 'components/DatePicker';

import { MONTHS } from '../../constants';

import styles from './Dropdown.module.scss';

interface Props {
  className?: string;
  items: number[];
  selectedItem: number;
  selectItem: (item: number) => void;
  isActive: boolean;
  setActive: () => void;
  increaseSelectedYear: () => void;
  decreaseSelectedYear: () => void;
}

export const Dropdown = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      items,
      selectedItem,
      selectItem,
      isActive,
      setActive,
      increaseSelectedYear,
      decreaseSelectedYear,
    },
    ref
  ) => {
    const dropMonths = useMemo(
      () =>
        items.map((item) => {
          return (
            <button
              type="button"
              key={item}
              className={cn(styles.dropdown__item, {
                [styles.dropdown__item_selected]: item === selectedItem,
              })}
              onClick={() => selectItem(item)}
            >
              <div className={styles.dropdown__itemTypography}>
                {MONTHS[item] || item}
              </div>
            </button>
          );
        }),
      [items]
    );

    const dropYears = useMemo(
      () =>
        !MONTHS[selectedItem] && (
          <div
            className={cn(
              styles.dropdown__itemsControls,
              styles.dropdown__itemsControlsee
            )}
          >
            <div
              role="button"
              tabIndex={-1}
              onClick={(arg) =>
                callbackWithStopPropagation(arg, decreaseSelectedYear)
              }
              onKeyDown={undefined}
              className={styles.dropdown__button}
            >
              <BackArrowIcon
                className={styles.dropdown__itemsControlseeArrow_left}
              />
            </div>
            <div className={styles.dropdown__itemsControlseeTypography}>
              {selectedItem}
            </div>
            <div
              role="button"
              tabIndex={-1}
              onClick={(arg) =>
                callbackWithStopPropagation(arg, increaseSelectedYear)
              }
              onKeyDown={undefined}
              className={styles.dropdown__button}
            >
              <BackArrowIcon
                className={styles.dropdown__itemsControlseeArrow_right}
              />
            </div>
          </div>
        ),
      [MONTHS]
    );

    return (
      <div
        ref={ref}
        className={cn(styles.dropdown, className)}
        onClick={() => setActive()}
        onKeyDown={() => setActive()}
        tabIndex={-1}
        role="button"
      >
        <div
          className={cn(styles.dropdown__selectedItem, {
            [styles.dropdown__selectedItem_month]: MONTHS[selectedItem],
          })}
        >
          <div className={styles.dropdown__selectedItemText}>
            {MONTHS[selectedItem] || selectedItem}
          </div>

          <div className={styles.dropdown__selectedItemIconWrapper}>
            <div
              className={cn(styles.dropdown__selectedItemIcon, {
                [styles.dropdown__selectedItemIcon_rotated]: isActive,
              })}
            />
          </div>
        </div>

        <div
          className={cn(styles.dropdown__itemsWrapper, {
            [styles.dropdown__itemsWrapper_show]: isActive,
          })}
        >
          {dropYears}
          <div className={styles.dropdown__items}>{dropMonths}</div>
        </div>
      </div>
    );
  }
);

import _ from 'lodash';

import { getValueFromValueType } from 'components/Select';
import { ValueType } from 'components/Select/types';
import { CustomFieldTypeForFilter } from 'components/Table/components/TableFilter/types';
import { CustomFieldType } from 'components/types';

import { getReverseDateForRequest } from './getReverseDateForRequest';

export const getPreparedCustomFieldsForSelect = <T>(
  valueName: CustomFieldTypeForFilter,
  value: ValueType<T> | string
) => {
  const isString = _.isString(value);

  if (valueName.type === CustomFieldType.FIELD_DATE && isString) {
    return { date: getReverseDateForRequest(value) };
  }
  if (valueName.type === CustomFieldType.FIELD_TEXT && isString) {
    return { text: value };
  }
  if (valueName.type === CustomFieldType.FIELD_FLAG && !isString) {
    return { flag: !!getValueFromValueType(value) };
  }

  return undefined;
};

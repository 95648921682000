import cn from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, TypographyVariants } from 'components/Typography';

import styles from './CountRecords.module.scss';

export interface Props {
  records?: number;
  className?: string;
}

export const CountRecords: React.FC<Props> = (props) => {
  const { records, className } = props;

  const { t } = useTranslation('countRecords');

  return (
    <div className={cn(styles.countRecords, className)}>
      <Typography
        variant={TypographyVariants.b3}
        className={styles.countRecords__text}
      >{`${t('title')}:`}</Typography>
      <Typography
        variant={TypographyVariants.h5}
        className={styles.countRecords__number}
      >
        {records || 0}
      </Typography>
    </div>
  );
};

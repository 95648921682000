import React from 'react';

import { Clock12 } from 'assets/icons';
import { ChartBlock } from 'components/ChartBlock';
import { Typography, TypographyVariants } from 'components/Typography';

import { KpiData } from '../../types';

import styles from './KpiSlaLineChart.module.scss';

interface Props {
  kpiData?: KpiData;
}

export const KpiSlaLineChart: React.FC<Props> = ({ kpiData }) => {
  const slaPercents = kpiData?.info.sla.slaInfo;
  const slaDays = kpiData?.info.sla.days;
  const totalSla = kpiData?.info.sla.totalSla;

  const slaPercent = kpiData ? (
    <div className={styles.slaPercent}>
      <div className={styles.slaPercentIcon__container}>
        <Clock12 className={styles.slaPercentIcon} />
      </div>
      <Typography
        variant={TypographyVariants.h5}
        className={styles.slaPercent__title}
      >
        SLA
      </Typography>
      <Typography
        variant={TypographyVariants.d2}
        component="div"
        className={styles.slaPercent__value}
      >
        {totalSla}
        <Typography
          variant={TypographyVariants.h1}
          className={styles.slaPercent__percent}
        >
          %
        </Typography>
      </Typography>
    </div>
  ) : (
    <p className={styles.slaPercent__noData}>Нет данных</p>
  );

  return (
    <ChartBlock
      title="SLA"
      chartType="lineChart"
      lineChartElement={slaPercent}
      dataChart={slaPercents}
      dataAdditional={slaDays}
      className={styles.slaPercent__chart}
    />
  );
};

import cn from 'clsx';
import DOMPurify from 'dompurify';
import React from 'react';

import { Article } from 'features/KnowledgeBase/types';

import styles from './UserAssistanceArticleDetailContent.module.scss';

interface Props {
  article?: Article;
}

export const UserAssistanceArticleDetailContent: React.FC<Props> = ({
  article,
}) => {
  return (
    <div
      className={cn('ck-content', styles.userAssistanceArticleDetailContent)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(article?.text || ''),
      }}
    />
  );
};

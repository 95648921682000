import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { IconBlock } from 'components/IconBlock';
import { Input } from 'components/Input';
import { getValueFromValueType, Select } from 'components/Select';
import { MenuItem } from 'components/Select/components/MenuItem';
import { RenderMenuItemProps, ValueType } from 'components/Select/types';
import { TableBodyCell } from 'components/Table/components/TableBodyCell';
import { TableFilter } from 'components/Table/components/TableFilter';
import { CustomFieldType, Size } from 'components/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { OrganizationType } from 'core/types';
import { OrganizationSelect } from 'features/Organizations/components/OrganizationSelect';
import { SystemSelect } from 'features/Systems/components/SystemSelect';
import { checkObjectIdentity } from 'utils';

import {
  CUSTOM_FIELD_ACCESS_OPTIONS,
  INITIAL_CUSTOM_FIELDS_FILTER,
  SLIDE_RADIO_TABS,
} from '../../constants';
import {
  fetchCustomFieldsRequest,
  setCurrentCustomFieldsPage,
  setCustomFieldsFilter,
} from '../../ducks/actions';
import { CustomFieldsFilter as CustomFieldsFilterData } from '../../types';
import { getPropsCustomFieldType } from '../CustomFieldsFullTable';

import styles from './CustomFieldsFilter.module.scss';

const customFilterOrganization = {
  organizationTypes: {
    value: OrganizationType.CUSTOMER,
    title: 'Клиентская',
  },
};

export const CustomFieldsFilter: React.FC = React.memo(() => {
  const dispatch = useDispatch();

  const { register, handleSubmit, control, reset, watch } =
    useForm<CustomFieldsFilterData>({
      mode: 'onChange',
      defaultValues: INITIAL_CUSTOM_FIELDS_FILTER,
    });

  const filterValues = watch();

  const resetFilter = () => {
    dispatch(setCustomFieldsFilter({}));
    dispatch(setCurrentCustomFieldsPage(0));
    dispatch(fetchCustomFieldsRequest());
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { title, customFieldType, organizationId, systemId, accessType } =
      data;
    const preparedData = {
      title: title || undefined,
      customFieldType: customFieldType || undefined,
      organizationId: organizationId || undefined,
      systemId: systemId || undefined,
      accessType: accessType || undefined,
    };
    dispatch(setCustomFieldsFilter(preparedData));
    dispatch(setCurrentCustomFieldsPage(0));
    dispatch(fetchCustomFieldsRequest());
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    []
  );

  const titleToInput = register('title', {
    onChange: formSubmitHandlerDebounced,
  });

  const tableBodyWrapper = (elements: JSX.Element[]) =>
    elements.map((item) => (
      <TableBodyCell className={styles.customFieldsFilter__cell} key={item.key}>
        {item}
      </TableBodyCell>
    ));

  const renderItemType = ({
    onChange,
    option,
    selected,
  }: RenderMenuItemProps) => {
    const itemProps = getPropsCustomFieldType(option.value as CustomFieldType);
    return (
      <MenuItem
        size={Size.xs}
        className={styles.customFieldsFilter__item}
        onClick={onChange}
        selected={selected}
      >
        <IconBlock {...itemProps} />
      </MenuItem>
    );
  };

  const renderValue = (value: ValueType<CustomFieldType>) => {
    const selectValue = getValueFromValueType(value);
    const itemProps = getPropsCustomFieldType(selectValue as CustomFieldType);
    return <IconBlock {...itemProps} />;
  };

  const filterElements = [
    <Input size={Size.xs} type="title" key="title" {...titleToInput} />,
    <Controller
      control={control}
      name="accessType"
      key="accessType"
      render={({ field }) => {
        return (
          <Select<OrganizationType>
            size={Size.xs}
            mobileModalTitle="доступ"
            options={CUSTOM_FIELD_ACCESS_OPTIONS}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isTooltip={false}
          />
        );
      }}
    />,
    <Controller
      control={control}
      name="customFieldType"
      key="customFieldType"
      render={({ field }) => {
        return (
          <Select<CustomFieldType>
            size={Size.xs}
            mobileModalTitle="тип"
            options={SLIDE_RADIO_TABS}
            renderMenuItem={renderItemType}
            renderValue={renderValue}
            value={field.value}
            onChange={(value: ValueType<CustomFieldType>) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isTooltip={false}
          />
        );
      }}
    />,
    <Controller
      control={control}
      name="organizationId"
      key="organizationId"
      render={({ field }) => {
        return (
          <OrganizationSelect
            size={Size.xs}
            value={field.value}
            onChange={(value: ValueType<string>) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            customFilters={customFilterOrganization}
          />
        );
      }}
    />,
    <Controller
      control={control}
      name="systemId"
      key="systemId"
      render={({ field }) => {
        return (
          <SystemSelect
            mobileModalTitle="систему"
            value={field.value}
            onChange={(value: ValueType<string>) => {
              field.onChange(value);
              formSubmitHandler();
            }}
            isMulti={false}
          />
        );
      }}
    />,
  ];

  const filter = <>{tableBodyWrapper(filterElements)}</>;

  const disableReset = checkObjectIdentity(filterValues, {});

  return (
    <TableFilter
      filterComponent={filter}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});

import React from 'react';

import { Ticket } from 'features/Ticket';

import { TicketLayout } from '../../layouts/TicketLayout';

const TicketPage = () => {
  return (
    <TicketLayout>
      <Ticket />
    </TicketLayout>
  );
};

export default TicketPage;

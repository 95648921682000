import { TicketTypeSettings } from 'core/types';

import { SLATableDataType } from '../types';

export const getSlaTicketTypesFromSettings = (
  slaSettings?: SLATableDataType
): TicketTypeSettings[] => {
  return (
    slaSettings
      ? Array.from(
          new Set(
            Object.keys(slaSettings).reduce(
              (arr: string[], priority: string) => {
                return [
                  ...arr,
                  ...Object.keys(
                    slaSettings[priority as keyof typeof slaSettings]
                  ),
                ];
              },
              []
            )
          )
        )
      : []
  ).map((item, index) => ({
    ticketTypeId: index.toString(),
    title: item,
    active: true,
  }));
};

import { ActionWithStatus } from '../types';

export const sortActions = (actions: ActionWithStatus[]) => {
  const access: { [key: string]: ActionWithStatus[] } = {};
  actions?.forEach((item) => {
    access[item.accessCategory.id] = [
      ...(access[item.accessCategory.id] || []),
      {
        ...item,
      },
    ] as ActionWithStatus[];
  });
  return access;
};

import React from 'react';

import { System as SystemContent } from 'features/System';

import { SystemLayout } from '../../layouts/SystemLayout';

const System = () => {
  return (
    <SystemLayout>
      <SystemContent />
    </SystemLayout>
  );
};
export default System;

import { RenderTypes, TableColumns } from 'components/Table/types';
import { AccessArticleType } from 'features/KnowledgeBase/types';

export const TABS_ARTICLE_ACCESS = [
  {
    title: 'Общий доступ',
    value: AccessArticleType.COMMON,
  },
  {
    title: 'Админ доступ',
    value: AccessArticleType.ADMIN,
  },
  {
    title: 'Клиент доступ',
    value: AccessArticleType.CLIENT,
  },
];

export const TABLE_ARTICLE_FIELDS: TableColumns = [
  {
    title: 'Название',
    sortField: 'title',
    fieldName: 'title',
  },
  { title: 'Подкатегория', fieldName: 'categoryTitle' },
  {
    title: 'Создатель',
    fieldName: 'userFullName',
    sortField: 'USERFULLNAME',
  },
  {
    title: 'Редактирован',
    fieldName: 'updated',
    sortField: 'dateupdate',
    renderType: RenderTypes.ICON_BLOCK,
  },
  {
    title: 'Организация',
    fieldName: 'organizationTitle',
    sortField: 'organizationTitle',
    renderType: RenderTypes.CONDITION,
  },
];

import React from 'react';

import { TicketNavIcon } from 'assets/icons';
import { Typography, TypographyVariants } from 'components/Typography';

import styles from './SlaWarning.module.scss';

export const SlaWarning = () => {
  return (
    <div className={styles.slaWarning}>
      <div className={styles.slaWarning__icons}>
        <TicketNavIcon className={styles.slaWarning__iconsNav} />
      </div>
      <Typography
        variant={TypographyVariants.b3}
        className={styles.slaWarning__text}
      >
        Нет данных
      </Typography>
    </div>
  );
};

import _ from 'lodash';

import { getReverseDateForRequest } from 'components/Table/components/TableFilter/utils';
import { CustomFieldType } from 'components/types';
import { CustomFieldToRequest } from 'core/types';

import { AbstractDataType } from '../types';

import { getFieldNameByType } from './getConvertedCustomFields';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkValueIsNotEmpty = (value: any) =>
  value !== undefined && !_.isNull(value);

const joinCustomFieldWithValue = (
  field: CustomFieldToRequest,
  data: AbstractDataType
) => {
  const isDate = field.customFieldType === CustomFieldType.FIELD_DATE;

  return field?.id &&
    checkValueIsNotEmpty(data[field.id]) &&
    field?.customFieldType
    ? {
        ...field,
        [getFieldNameByType(field.customFieldType)]: isDate
          ? getReverseDateForRequest(data[field.id])
          : data[field.id],
      }
    : { ...field };
};

export const convertCustomFieldsForUpdate = (
  data: AbstractDataType,
  customArray: CustomFieldToRequest[]
) => {
  return customArray.map((item) => joinCustomFieldWithValue(item, data)) || [];
};

import { createSelector } from 'reselect';

import { SelectOption } from 'components/Select/types';
import { OrganizationType, WorkGroup } from 'core/types';
import {
  AccessedRoute,
  ActionList,
  CurrentUserOrganization,
} from 'features/Auth/types';
import { Ticket } from 'features/Ticket/types';
import { RouterHref } from 'routes/routerHref';
import { RootState } from 'store/rootReducer';

export const getUserId = (state: RootState): string | undefined =>
  state.currentUser.user?.userId;
export const getUserFirstName = (state: RootState): string | undefined =>
  state.currentUser.user?.firstName;
export const getUserLastName = (state: RootState): string | undefined =>
  state.currentUser.user?.lastName;
export const getUserMiddleName = (state: RootState): string | undefined =>
  state.currentUser.user?.middleName;
export const getUserFullName = (state: RootState): string | undefined =>
  state.currentUser.user?.fullName;
export const getUserEmail = (state: RootState): string | undefined =>
  state.currentUser.user?.email;
export const getUserPhoneNumber = (state: RootState): string | undefined =>
  state.currentUser.user?.phoneNumber;
export const getUserOrganizationTitle = (
  state: RootState
): string | undefined => state.currentUser.user?.organizationTitle;
export const getIsAuthorized = (state: RootState): boolean | undefined =>
  state.currentUser.isAuthorized;
export const getAccessedRoutes = (
  state: RootState
): AccessedRoute[] | undefined => state.currentUser.accessedRoutes;
export const getActionList = (state: RootState): string[] | undefined =>
  state.currentUser.actionList;
export const getWorkGroupsIds = (state: RootState): string[] | undefined =>
  state.currentUser.workGroupsIds;
export const getManagerWorkGroupsIds = (
  state: RootState
): string[] | undefined => state.currentUser.managerWorkGroupsIds;
export const getWorkGroups = (state: RootState): WorkGroup[] | undefined =>
  state.currentUser.workGroups;
export const getManagerWorkGroups = (
  state: RootState
): WorkGroup[] | undefined => state.currentUser.managerWorkGroups;
export const getUserOrganization = (
  state: RootState
): CurrentUserOrganization | undefined => state.currentUser.organization;
export const getCustomerAttribute = (state: RootState) =>
  state.currentUser.organization?.hasCustomerAttribute;
export const getSpecialistAttribute = (state: RootState) =>
  state.currentUser.organization?.hasSpecialistAttribute;

export const getIsAccessedAction = (action: ActionList) =>
  createSelector<RootState, string[] | undefined, boolean>(
    [getActionList],
    (actionList): boolean => {
      if (actionList) {
        return actionList?.includes(action);
      }
      return false;
    }
  );
// TODO: tsp-1519 переписать логику позже
export const getIsAccessedByRoute = (routerHref: RouterHref) =>
  createSelector<RootState, AccessedRoute[] | undefined, boolean>(
    [getAccessedRoutes],
    (routeList): boolean => {
      return !!routeList?.filter(({ route }) => route === routerHref)?.length;
    }
  );

export const getIsOrgTypeCustomer = createSelector<
  RootState,
  CurrentUserOrganization | undefined,
  boolean
>(
  [getUserOrganization],
  (userOrganization): boolean =>
    userOrganization?.organization.type === OrganizationType.CUSTOMER
);

export const getIsOrgTypeService = createSelector<
  RootState,
  CurrentUserOrganization | undefined,
  boolean
>(
  [getUserOrganization],
  (userOrganization): boolean =>
    userOrganization?.organization.type === OrganizationType.SERVICE
);

export const getIsOrgTypePartner = createSelector<
  RootState,
  CurrentUserOrganization | undefined,
  boolean
>(
  [getUserOrganization],
  (userOrganization): boolean =>
    userOrganization?.organization.type === OrganizationType.PARTNER
);

export const getIsClient = createSelector<
  RootState,
  boolean,
  boolean | undefined,
  boolean
>(
  [getIsOrgTypeCustomer, getCustomerAttribute],
  (isOrgTypeCustomer, customerAttribute): boolean => {
    return !!(isOrgTypeCustomer && customerAttribute);
  }
);

export const getIsClientPartner = createSelector<
  RootState,
  boolean,
  boolean | undefined,
  boolean
>(
  [getIsOrgTypePartner, getCustomerAttribute],
  (isOrgTypePartner, customerAttribute): boolean => {
    return !!(isOrgTypePartner && customerAttribute);
  }
);

export const getIsMyTicket = (ticket?: Ticket) =>
  createSelector<RootState, string | undefined, boolean>(
    getUserId,
    (userId): boolean => userId === ticket?.clientInfo?.id
  );

export const getIsSpecialist = createSelector<
  RootState,
  boolean,
  boolean | undefined,
  boolean
>(
  [getIsOrgTypeService, getSpecialistAttribute],
  (isOrgTypeService, specialistAttribute): boolean => {
    return !!(isOrgTypeService && specialistAttribute);
  }
);

export const getIsSpecialistPartner = createSelector<
  RootState,
  boolean,
  boolean | undefined,
  boolean
>(
  [getIsOrgTypePartner, getSpecialistAttribute],
  (isOrgTypePartner, specialistAttribute): boolean => {
    return !!(isOrgTypePartner && specialistAttribute);
  }
);

export const getIsGroupSpecialist = createSelector<
  RootState,
  string[] | undefined,
  boolean
>(
  [getManagerWorkGroupsIds],
  (userManagerWorkGroupsIds): boolean => !!userManagerWorkGroupsIds?.length
);

export const getManagerWorkGroupsList = createSelector<
  RootState,
  WorkGroup[] | undefined,
  SelectOption[]
>([getManagerWorkGroups], (managerWorkGroups): SelectOption[] => {
  if (Array.isArray(managerWorkGroups)) {
    return managerWorkGroups.map(
      (workGroup: WorkGroup): SelectOption => ({
        title: workGroup.title,
        value: workGroup.id,
      })
    );
  }
  return [];
});

export const getIsAdmin = getIsAccessedAction(ActionList.ViewingAllTickets);

export const getIsManagerCurrentWorkGroups = (workGroups?: WorkGroup[]) =>
  createSelector<RootState, string[] | undefined, boolean>(
    getManagerWorkGroupsIds,
    (managerWorkGroupsIds): boolean => {
      return !!workGroups?.find((workGroup) =>
        managerWorkGroupsIds?.includes(workGroup.id)
      );
    }
  );

import React from 'react';

import { Contract } from 'core/types';
import { ContractStatusBlock } from 'features/Contract/components/ContractStatusBlock';
import { CONTRACT_TYPE_TITLE_MAP } from 'features/Contract/constants';

export const getDataTable = (contracts?: Contract[]) =>
  contracts?.map((contract) => {
    const { title, type, status, id } = contract;
    return {
      id,
      title: { title, to: `/admin/contracts/${id}` },
      type: { text: CONTRACT_TYPE_TITLE_MAP[type] },
      status: <ContractStatusBlock status={status} />,
    };
  }) || [];

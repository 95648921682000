import { KpiData } from '../types';

import { getCountTickets } from './getCountTickets';
import { getTicketsPercents } from './getTicketsPercents';

interface Props {
  totalCount: () => string | number;
  totalCountClosed: () => string | number;
  firstTimeClosed: () => string | number;
  totalCountDeclined: () => string | number;
  totalCountClosedWithExpire: () => string | number;
}

export const getAllTicketsData = (kpiData?: KpiData) => {
  const totalCount = kpiData?.info.activeTickets.totalCount;
  const totalCountClosed = kpiData?.info.closedInfo.totalCountClosed;
  const firstTimeClosed = kpiData?.info.closedInfo.firstTimeClosed;
  const totalCountDeclined = kpiData?.info.closedInfo.totalCountDeclined;
  const totalCountClosedWithExpire =
    kpiData?.info.closedInfo.totalCountClosedWithExpire;

  return {
    totalCount: () => getCountTickets(totalCount),
    totalCountClosed: () => getCountTickets(totalCountClosed),
    firstTimeClosed: () => getCountTickets(firstTimeClosed),
    totalCountDeclined: () => getCountTickets(totalCountDeclined),
    totalCountClosedWithExpire: () =>
      getCountTickets(totalCountClosedWithExpire),
  };
};

export const getTicketPersonData = (props: Props) => {
  const {
    totalCountClosed,
    totalCountClosedWithExpire,
    firstTimeClosed,
    totalCountDeclined,
  } = props;

  return {
    firstTimeClosed: () =>
      getTicketsPercents(totalCountClosed(), firstTimeClosed()),
    totalCountDeclined: () =>
      getTicketsPercents(totalCountClosed(), totalCountDeclined()),
    totalCountClosedWithExpire: () =>
      getTicketsPercents(totalCountClosed(), totalCountClosedWithExpire()),
  };
};

export const getRatingPercents = (current: string | number) =>
  (Number(current) / 5) * 100;

import cn from 'clsx';
import React from 'react';
import { Control } from 'react-hook-form';

import { Accordion } from 'components/Accordion';
import { CustomFieldToRequest } from 'core/types';

import { AbstractDataType } from '../../types';
import { TicketCustomField } from '../TicketCustomField/TicketCustomField';

import styles from './TicketCustomFieldAccordion.module.scss';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<AbstractDataType, any>;
  onChange?: () => void;
  clientFields: CustomFieldToRequest[];
  disabled: boolean;
}

export const TicketCustomFieldAccordion: React.FC<Props> = ({
  control,
  onChange,
  clientFields,
  disabled,
}) => {
  const customFieldsList = clientFields.map((item: CustomFieldToRequest) => (
    <TicketCustomField
      customField={item}
      control={control}
      disabled={!item.required || disabled}
      key={item.id}
      onChange={onChange}
    />
  ));

  return (
    <Accordion title="Поля клиента">
      <div
        className={cn(
          styles.ticketCustomFieldsAccordion,
          styles.ticketCustomFieldsAccordion__accordion
        )}
      >
        {customFieldsList}
      </div>
    </Accordion>
  );
};

import React from 'react';

import { ToggleSwitch } from 'components/ToggleSwitch';
import { Action } from 'core/types';

export const getDataTable = (actions?: Action[]) =>
  actions?.map((action) => {
    const { title, nameInDatabase, id } = action;
    return {
      id,
      title,
      nameInDatabase,
      status: <ToggleSwitch checked disabled readOnly />,
    };
  }) || [];

import React from 'react';

import { CloseIcon } from 'assets/icons';
import {
  PopoverButton,
  Props,
} from 'components/Popover/components/PopoverButton';

import styles from './DeleteButton.module.scss';

export const DeleteButton: React.FC<Props> = (props) => {
  return (
    <PopoverButton
      icon={<CloseIcon className={styles.deleteButton__icon} />}
      {...props}
    >
      Удалить
    </PopoverButton>
  );
};

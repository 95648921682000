import cn from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { AddLineIcon, CloseIcon, PlusIcon } from 'assets/icons';
import { PopoverButton } from 'components/Popover/components/PopoverButton';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { CategoryArticle } from 'features/KnowledgeBase/types';
import { RouterHref } from 'routes/routerHref';

import { KBChangeFieldDrop } from '../KBChangeFieldDrop';

import styles from './KBCategoryDrop.module.scss';

export interface Props {
  category?: CategoryArticle;
  isSubCategory?: boolean;
  withArticleCreate?: boolean;
  onSubCategoryCreate?: (data: Partial<CategoryArticle>) => void;
  onChangeTitle?: (id: string, title?: string) => void;
  onDeleteCategory?: (id?: string) => void;
  toggleModal?(): void;
}

export const KBCategoryDrop: React.FC<Props> = ({
  category,
  withArticleCreate,
  isSubCategory,
  onSubCategoryCreate,
  onChangeTitle,
  onDeleteCategory,
  toggleModal,
}) => {
  const { push } = useHistory();

  const onArticleCreate = () => {
    push(RouterHref.KnowledgeBaseArticleCreate, { category });
  };

  const handleChangeTitle = (title = '') => {
    if (category?.id && onChangeTitle) {
      onChangeTitle(category.id, title);
    }
  };

  const handleSubCategoryCreate = (title = '') => {
    if (
      title &&
      category?.id &&
      category.organizationId &&
      onSubCategoryCreate
    ) {
      onSubCategoryCreate({
        parentId: category.id,
        title,
        organizationId: category.organizationId,
      });
    }
  };

  return (
    <PopoverContainer>
      {withArticleCreate && (
        <PopoverButton
          icon={<PlusIcon className={styles.KBCreateCategoryDrop__icon} />}
          onClick={onArticleCreate}
        >
          Создать статью
        </PopoverButton>
      )}
      {onChangeTitle && (
        <KBChangeFieldDrop
          onChange={handleChangeTitle}
          initialValue={category?.title}
        />
      )}
      {onSubCategoryCreate && !isSubCategory && (
        <KBChangeFieldDrop
          onChange={handleSubCategoryCreate}
          customDropButtonProps={{
            icon: <AddLineIcon className={styles.KBCreateCategoryDrop__icon} />,
            children: 'Создать подкатегорию',
          }}
        />
      )}
      {onDeleteCategory && (
        <PopoverButton
          icon={
            <CloseIcon
              className={cn(
                styles.KBCreateCategoryDrop__icon,
                styles.KBCreateCategoryDrop__iconRed
              )}
            />
          }
          onClick={toggleModal}
        >
          {`Удалить ${isSubCategory ? 'подкатегорию' : 'категорию'} `}
        </PopoverButton>
      )}
    </PopoverContainer>
  );
};

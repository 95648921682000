import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { MainLayout } from 'core/layouts/MainLayout';
import { RouterHref } from 'routes/routerHref';

const Companies = () => {
  const { push } = useHistory();

  useEffect(() => {
    push(RouterHref.Tickets);
  }, []);

  return <MainLayout headerType="default" title="Организации" />;
};

export default Companies;

import cn from 'clsx';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { BookIcon, Delete2Icon } from 'assets/icons';
import { Checkbox } from 'components/Checkbox';
import { Popover } from 'components/Popover';
import { PopoverButton } from 'components/Popover/components/PopoverButton';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { Typography, TypographyVariants } from 'components/Typography';
import { DeleteApproveOrCancel } from 'core/modals/DeleteApproveOrCancel';
import { Article } from 'features/KnowledgeBase/types';
import { deleteArticleRequest } from 'features/UserAssistance/ducks';

import styles from './UserAssistanceArticle.module.scss';

interface Props {
  article: Article;
  className?: string;
  onClick(): void;
  withDelete?: boolean;
  isAddMode?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  checked?: boolean;
}

export const UserAssistanceArticle: React.FC<Props> = ({
  article,
  className,
  onClick,
  withDelete,
  isAddMode,
  onChange,
  checked,
}) => {
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const togglePopover = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const deleteArticle = (id?: string) => {
    if (id) {
      dispatch(deleteArticleRequest(id));
    }
  };

  const deleteArticleHandler = () => {
    deleteArticle(article.id);
  };

  const onDeleteArticle = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.stopPropagation();
    toggleModal();
  };

  const popoverContent = (
    <PopoverContainer>
      <PopoverButton
        icon={<Delete2Icon />}
        onClick={onDeleteArticle}
        className={styles.userAssistanceArticle__popoverButton}
      >
        Удалить
      </PopoverButton>
    </PopoverContainer>
  );

  const popover = withDelete && !isAddMode && (
    <Popover
      content={popoverContent}
      togglePopover={togglePopover}
      isOpen={isPopoverOpen}
      className={styles.userAssistanceArticle__popover}
      positions={['bottom', 'right', 'top', 'left']}
      align="end"
      padding={4}
    />
  );

  const leftItem = isAddMode ? (
    <Checkbox
      onChange={onChange}
      checked={checked}
      className={styles.userAssistanceArticle__checkbox}
    />
  ) : (
    <div className={styles.userAssistanceArticle__iconWrapper}>
      <BookIcon className={styles.userAssistanceArticle__icon} />
    </div>
  );

  return (
    <>
      <div
        onClick={!isAddMode ? onClick : undefined}
        onKeyDown={!isAddMode ? onClick : undefined}
        tabIndex={-1}
        role="button"
        className={cn(
          styles.userAssistanceArticle,
          { [styles.userAssistanceArticle_noAddMode]: !isAddMode },
          className
        )}
      >
        <div className={styles.userAssistanceArticle__contentWrapper}>
          {leftItem}
          <div className={styles.userAssistanceArticle__content}>
            <Typography
              variant={TypographyVariants.b3}
              className={styles.userAssistanceArticle__title}
            >
              {article.title}
            </Typography>
            <p className={styles.userAssistanceArticle__text}>{article.text}</p>
          </div>
        </div>
        {popover}
      </div>
      <DeleteApproveOrCancel
        isModal={isModal}
        toggleModal={toggleModal}
        entityTitle="статью"
        onApprove={deleteArticleHandler}
      />
    </>
  );
};

import { Organization } from 'core/types';
import {
  Article,
  ArticleCreateData,
  CategoryArticle,
} from 'features/KnowledgeBase/types';

import {
  ArticleCreate,
  CreateArticleRequestArticleCreateAction,
  DeleteArticleRequestArticleCreateAction,
  EditArticleRequestArticleCreateAction,
} from './types';

export const createArticleRequestArticleCreate = (
  data: ArticleCreateData
): CreateArticleRequestArticleCreateAction => {
  return {
    type: ArticleCreate.CREATE_ARTICLE_REQUEST_ARTICLE_CREATE,
    payload: data,
  };
};

export const editArticleRequestArticleCreate = (
  data: ArticleCreateData
): EditArticleRequestArticleCreateAction => {
  return {
    type: ArticleCreate.EDIT_ARTICLE_REQUEST_ARTICLE_CREATE,
    payload: data,
  };
};

export const deleteArticleRequestArticleCreate = (
  id: string
): DeleteArticleRequestArticleCreateAction => {
  return {
    type: ArticleCreate.DELETE_ARTICLE_REQUEST_ARTICLE_CREATE,
    payload: id,
  };
};

export const createCategoryRequestArticleCreate = (
  data: Partial<CategoryArticle>
) => {
  return {
    type: ArticleCreate.CREATE_CATEGORY_REQUEST_ARTICLE_CREATE,
    payload: data,
  };
};

export const setOrganizationsFilterArticleCreate = (filter: string) => {
  return {
    type: ArticleCreate.SET_ORGANIZATIONS_FILTER_ARTICLE_CREATE,
    payload: filter,
  };
};

export const fetchOrganizationsRequestArticleCreate = () => {
  return {
    type: ArticleCreate.FETCH_ORGANIZATIONS_REQUEST_ARTICLE_CREATE,
  };
};

export const fetchOrganizationsSuccessArticleCreate = (
  data: Organization[]
) => {
  return {
    type: ArticleCreate.FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_CREATE,
    payload: data,
  };
};

export const fetchInitialOrganizationRequestArticleCreate = (id: string) => {
  return {
    type: ArticleCreate.FETCH_INITIAL_ORGANIZATION_REQUEST_ARTICLE_CREATE,
    payload: id,
  };
};

export const fetchInitialOrganizationSuccessArticleCreate = (
  data: Organization
) => {
  return {
    type: ArticleCreate.FETCH_INITIAL_ORGANIZATION_SUCCESS_ARTICLE_CREATE,
    payload: data,
  };
};

export const fetchCategoriesRequestArticleCreate = (id: string) => {
  return {
    type: ArticleCreate.FETCH_CATEGORIES_REQUEST_ARTICLE_CREATE,
    payload: id,
  };
};

export const fetchCategoriesSuccessArticleCreate = (
  data: CategoryArticle[]
) => {
  return {
    type: ArticleCreate.FETCH_CATEGORIES_SUCCESS_ARTICLE_CREATE,
    payload: data,
  };
};

export const fetchSubCategoriesRequestArticleCreate = (id: string) => {
  return {
    type: ArticleCreate.FETCH_SUB_CATEGORIES_REQUEST_ARTICLE_CREATE,
    payload: id,
  };
};

export const fetchSubCategoriesSuccessArticleCreate = (
  data: CategoryArticle[]
) => {
  return {
    type: ArticleCreate.FETCH_SUB_CATEGORIES_SUCCESS_ARTICLE_CREATE,
    payload: data,
  };
};

export const fetchCurrentArticleRequestArticleCreate = (id: string) => {
  return {
    type: ArticleCreate.FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_CREATE,
    payload: id,
  };
};

export const fetchCurrentArticleSuccessArticleCreate = (data?: Article) => {
  return {
    type: ArticleCreate.FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_CREATE,
    payload: data,
  };
};

export const currentArticleLoadingShowArticleCreate = () => {
  return {
    type: ArticleCreate.CURRENT_ARTICLE_LOADING_SHOW_ARTICLE_CREATE,
  };
};

export const currentArticleLoadingHideArticleCreate = () => {
  return {
    type: ArticleCreate.CURRENT_ARTICLE_LOADING_HIDE_ARTICLE_CREATE,
  };
};

export const resetArticleCreateState = () => {
  return {
    type: ArticleCreate.RESET_ARTICLE_CREATE_STATE,
  };
};

import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  getOptionTitle,
  getOptionValue,
  getValueFromValueType,
} from 'components/Select';
import { SelectOption, ValueType } from 'components/Select/types';

import {
  CUSTOM_FIELD_TYPE_CONDITION_MAP,
  DEFAULT_VALUES_TRIGGER_FORM,
  INITIAL_ATTRIBUTES,
  INITIAL_ATTRIBUTES_VALUES,
  TICKET_STATUS,
} from '../constants';
import {
  createTrigger,
  fetchAttributesFieldsRequest,
  fetchCustomFieldsRequest,
  fetchNextTicketStatusRequest,
  fetchTicketStatusRequest,
  resetCurrentTrigger,
  updateTrigger,
} from '../ducks';
import {
  getAttributesFields,
  getCurrentTrigger,
  getCustomFields,
  getCustomFieldsMap,
  getNextTicketStatusOptions,
} from '../ducks/selectors';
import {
  CreateTriggerData,
  CustomCondition,
  TriggerAttribute,
  TriggerCondition,
} from '../types';
import {
  getAttributesOptions,
  getCustomFieldsOptions,
  getNormalizedAttributes,
  getNormalizedCustomConditions,
  getNormalizedTriggerConditions,
  getTriggerConditionOptions,
  prepareAttributeForRequest,
  prepareAttributeForSubmit,
  prepareCustomConditionForSubmit,
  prepareTriggerConditionForSubmit,
} from '../utils';

interface Props {
  isModal: boolean;
  isEditMode: boolean;
  toggleIsModal(): void;
}

export const useTriggerForm = ({
  isModal,
  isEditMode,
  toggleIsModal,
}: Props) => {
  const dispatch = useDispatch();

  const trigger = useSelector(getCurrentTrigger);
  const attributesFields = useSelector(getAttributesFields);
  const customFields = useSelector(getCustomFields);
  const customFieldsMap = useSelector(getCustomFieldsMap);
  const nextTicketStatusOptions = useSelector(getNextTicketStatusOptions);

  const [attributes, setAttributes] =
    useState<TriggerAttribute[]>(INITIAL_ATTRIBUTES);
  const [customConditions, setCustomConditions] = useState<CustomCondition[]>();
  const [triggerConditions, setTriggerConditions] =
    useState<TriggerCondition[]>();
  const [alertIsOpen, setAlertIsOpen] = useState(true);

  const attributesOptions = getAttributesOptions(attributes);

  const customFieldsOptions = getCustomFieldsOptions(
    customConditions,
    customFields
  );

  const triggerConditionOptions = getTriggerConditionOptions(triggerConditions);

  const [
    organizationAttribute,
    systemAttribute,
    ticketTypeAttribute,
    statusAttribute,
  ] = attributes;

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    register,
    resetField,
    formState: { errors, isValid },
  } = useForm<CreateTriggerData>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES_TRIGGER_FORM,
  });

  const titleInputOptions = register('title', {
    required: true,
    maxLength: {
      value: 100,
      message: 'Название триггера не может быть длиннее 100 символов.',
    },
  });

  const isDisabledAttribute = useMemo(() => {
    let valid = false;

    if (!attributesOptions.length) {
      return true;
    }

    attributes.forEach((attribute) => {
      if (isEmpty(attribute.value)) {
        valid = true;
      }
    });

    return valid;
  }, [attributes, attributesOptions]);

  const isDisabledCustomCondition = useMemo(() => {
    let valid = false;

    if (isEmpty(systemAttribute.value) || !customFieldsOptions?.length) {
      return true;
    }

    if (customConditions) {
      customConditions.forEach((condition) => {
        if (
          isEmpty(condition.condition) ||
          !getValueFromValueType(condition.customField)
        ) {
          valid = true;
        }
      });
    }

    return valid;
  }, [customConditions, customFieldsOptions, systemAttribute]);

  const isDisabledTriggerCondition = useMemo(() => {
    let valid = false;

    if (!isEmpty(triggerConditions) && !triggerConditionOptions.length) {
      return true;
    }

    if (triggerConditions) {
      triggerConditions.forEach((condition) => {
        if (
          !condition.condition ||
          !getValueFromValueType(condition.condition)
        ) {
          valid = true;
        }
      });
    }

    return valid;
  }, [triggerConditions, triggerConditionOptions]);

  const isDisabledSubmit = useMemo(() => {
    const customConditionsIsEmpty =
      customConditions &&
      customConditions.find((item) => getValueFromValueType(item.customField));

    const triggerConditionsIsEmpty =
      triggerConditions &&
      triggerConditions.find((item) => getValueFromValueType(item.condition));

    const conditionsIsEmpty = !(
      customConditionsIsEmpty || triggerConditionsIsEmpty
    );

    const attributeValueIsEmpty = !!attributes.filter((attribute) =>
      isEmpty(attribute.value)
    ).length;

    return !isValid || conditionsIsEmpty || attributeValueIsEmpty;
  }, [isValid, customConditions, triggerConditions, attributes]);

  const isDisabledTargetStatus = !getValueFromValueType(statusAttribute.value);

  const resetTrigger = () => {
    resetField('targetStatus');
    reset();
    setAttributes(INITIAL_ATTRIBUTES);
    setCustomConditions(undefined);
    setTriggerConditions(undefined);
  };

  const closeModal = () => {
    toggleIsModal();
    resetTrigger();
  };

  const onCloseAlert = () => {
    setAlertIsOpen(false);
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { title, isEnable } = data;

    const targetStatus = getValueFromValueType(data.targetStatus) || '';

    const preparedAttributes = prepareAttributeForSubmit(attributes);

    const preparedTriggerCondition =
      prepareTriggerConditionForSubmit(triggerConditions);
    const preparedCustomConditions =
      prepareCustomConditionForSubmit(customConditions);

    const preparedData = {
      title,
      isEnable,
      targetStatus,
      defaultConditions: preparedTriggerCondition,
      customConditionRequests: preparedCustomConditions,
      ...preparedAttributes,
    };

    if (isEditMode && trigger) {
      dispatch(updateTrigger({ ...preparedData, isEnable: trigger.isEnable }));
      closeModal();
      return;
    }

    dispatch(createTrigger(preparedData));
    closeModal();
  });

  const fetchTriggersFieldsHandler = (data: TriggerAttribute[]) => {
    const preparedAttributes = prepareAttributeForRequest(data);
    dispatch(fetchAttributesFieldsRequest(preparedAttributes));
  };

  const fetchCustomFieldHandler = (systemId: string) => {
    dispatch(fetchCustomFieldsRequest(systemId));
  };

  const fetchTicketStatus = (systemId: string) => {
    resetField('targetStatus');
    dispatch(
      fetchTicketStatusRequest({
        excludeStatuses: TICKET_STATUS,
        systemIds: [systemId],
      })
    );
  };

  const fetchNextTicketStatus = (statusId: string) => {
    resetField('targetStatus');
    const systemId = getValueFromValueType(systemAttribute.value);
    const typeIds = getValueFromValueType(ticketTypeAttribute.value);

    dispatch(
      fetchNextTicketStatusRequest({
        nextToIds: [statusId],
        excludeStatuses: TICKET_STATUS,
        ...(typeIds && { typeIds: [typeIds] }),
        ...(systemId && { systemIds: [systemId] }),
      })
    );
  };

  const onAddAttributeHandler = useCallback(() => {
    setAttributes((prevState) => {
      return [
        ...prevState,
        {
          id: uuidv4(),
          isActive: true,
          attribute: { title: '', value: '' },
          value: [],
        },
      ];
    });
  }, [setAttributes]);

  const onDeleteAttributeHandler = useCallback(
    (id: string) => {
      return () => {
        setAttributes((prevState) => {
          const newState = prevState.filter((attribute) => attribute.id !== id);
          fetchTriggersFieldsHandler(newState);
          return newState;
        });
      };
    },
    [setAttributes]
  );

  const onChangeRequiredAttributeHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
      event.preventDefault();
      resetField('targetStatus');
      setAttributes((prevState) => {
        const attributeIndex = prevState.findIndex(
          (attribute) => attribute.id === id
        );

        const newState = prevState.map((attribute, index) => {
          if (index >= attributeIndex) {
            return { ...attribute, value: [] };
          }
          return attribute;
        });

        fetchTriggersFieldsHandler(newState);
        return newState;
      });

      const isOrganizationAttr = organizationAttribute.id === id;
      const isSystemAttr = systemAttribute.id === id;

      if (isOrganizationAttr || isSystemAttr) {
        setCustomConditions(undefined);
      }
    },
    [setAttributes, setCustomConditions, organizationAttribute, systemAttribute]
  );

  const onChangeAttributeValue = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (id: string, attributeValue?: string) => {
      return (value: ValueType<string>) => {
        const isSystems = attributeValue === 'systems';
        const systemId = isSystems ? getValueFromValueType(value) : undefined;

        if (isSystems && customConditions?.length) {
          setCustomConditions(undefined);
        }

        if (systemId) {
          fetchCustomFieldHandler(systemId);
          fetchTicketStatus(systemId);
        }

        const isStatus = attributeValue === 'status';
        const statusId = isStatus ? getValueFromValueType(value) : undefined;

        if (statusId) {
          fetchNextTicketStatus(statusId);
        }

        setAttributes((prevState) => {
          const attributeIndex = prevState.findIndex(
            (attribute) => attribute.id === id
          );

          const newState = prevState.map((attribute, index) => {
            if (index > attributeIndex) {
              return { ...attribute, value: [] };
            }

            if (attribute.id === id) {
              return { ...attribute, value };
            }

            return attribute;
          });
          fetchTriggersFieldsHandler(newState);
          return newState;
        });
      };
    },
    [attributes, customConditions, setAttributes, setCustomConditions]
  );

  const onChangeAttribute = useCallback(
    (id: string) => {
      return (value: SelectOption) => {
        setAttributes((prevState) => {
          fetchTriggersFieldsHandler(prevState);
          return prevState.map((attribute) => {
            if (!prevState.length || attribute.id === id) {
              return { ...attribute, isActive: true, attribute: value };
            }
            return attribute;
          });
        });
        onChangeAttributeValue(id)([]);
      };
    },
    [setAttributes]
  );

  const onAddCustomConditionHandler = useCallback(() => {
    setCustomConditions((prevState) => {
      return [
        ...(prevState || []),
        {
          id: uuidv4(),
          isActive: true,
          customField: { title: '', value: '' },
          type: null,
          condition: [],
          value: null,
        },
      ];
    });
  }, [setCustomConditions]);

  const onDeleteCustomConditionHandler = useCallback(
    (id: string) => {
      return () => {
        setCustomConditions((prevState) => {
          return prevState?.filter((attribute) => attribute.id !== id);
        });
      };
    },
    [setCustomConditions]
  );

  const onChangeCustomCondition = useCallback(
    (id: string) => {
      return (value: ValueType<string>) => {
        setCustomConditions((prevState) => {
          if (prevState) {
            return prevState.map((customField) => {
              if (customField.id === id) {
                return { ...customField, condition: value };
              }
              return customField;
            });
          }
          return prevState;
        });
      };
    },
    [setCustomConditions]
  );

  const onChangeCustomField = useCallback(
    (id: string) => {
      return (value: ValueType<string>) => {
        const customFieldId = getValueFromValueType(value) || '';
        const type = customFieldsMap[customFieldId];

        setCustomConditions((prevState) => {
          if (prevState) {
            return prevState.map((customField) => {
              if (customField.id === id) {
                return {
                  ...customField,
                  customField: value,
                  type,
                };
              }
              return customField;
            });
          }
          return prevState;
        });

        const customField = {
          title: getOptionTitle(CUSTOM_FIELD_TYPE_CONDITION_MAP[type]) || '',
          value: getOptionValue(CUSTOM_FIELD_TYPE_CONDITION_MAP[type]) || '',
        };

        onChangeCustomCondition(id)(customField);
      };
    },
    [setCustomConditions, onChangeCustomCondition, customFieldsMap]
  );

  const onChangeCustomConditionValue = useCallback(
    (id: string) => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomConditions((prevState) => {
          if (prevState) {
            return prevState.map((condition) => {
              if (condition.id === id) {
                return { ...condition, value: event.target.value };
              }
              return condition;
            });
          }
          return prevState;
        });
      };
    },
    [setCustomConditions]
  );

  const onAddTriggerConditionHandler = useCallback(() => {
    setTriggerConditions((prevState) => {
      return [
        ...(prevState || []),
        {
          id: uuidv4(),
          isActive: true,
          condition: { title: '', value: '' },
          value: '00:00',
        },
      ];
    });
  }, [setTriggerConditions]);

  const onChangeTriggerCondition = useCallback(
    (id: string) => {
      return (value: ValueType<string>) => {
        setTriggerConditions((prevState) => {
          if (prevState) {
            return prevState.map((condition) => {
              if (condition.id === id) {
                return { ...condition, condition: value };
              }
              return condition;
            });
          }
          return prevState;
        });
      };
    },
    [setTriggerConditions]
  );

  const onChangeTriggerConditionValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      // eslint-disable-next-line sonarjs/no-identical-functions
      setTriggerConditions((prevState) => {
        if (prevState) {
          // eslint-disable-next-line sonarjs/no-identical-functions
          return prevState.map((condition) => {
            if (condition.id === id) {
              return { ...condition, value: event.target.value };
            }
            return condition;
          });
        }
        return prevState;
      });
    },
    [setTriggerConditions]
  );

  const onDeleteTriggerConditionHandler = useCallback(
    (id: string) => {
      return () => {
        setTriggerConditions((prevState) => {
          return prevState?.filter((attribute) => attribute.id !== id);
        });
      };
    },
    [setTriggerConditions]
  );

  useEffect(() => {
    if (isModal) {
      resetTrigger();
    }

    if (isEditMode && trigger) {
      const normalizedAttributes = getNormalizedAttributes(trigger);
      const normalizedCustomConditions = getNormalizedCustomConditions(
        trigger.customConditions
      );
      const normalizedTriggerConditions = getNormalizedTriggerConditions(
        trigger.defaultConditions
      );

      const system = normalizedAttributes.find(
        (item) => item.attribute.value === 'systems'
      );
      const systemId = !Array.isArray(system?.value) ? system?.value.value : '';

      if (systemId) {
        fetchCustomFieldHandler(systemId);
      }

      setValue('title', trigger.title);
      setValue('targetStatus', {
        title: trigger.targetStatus.value,
        value: trigger.targetStatus.key,
      });
      setAttributes(normalizedAttributes);
      setCustomConditions(normalizedCustomConditions);
      setTriggerConditions(normalizedTriggerConditions);
    }
  }, [isEditMode, isModal, trigger]);

  useEffect(() => {
    if (!isModal) {
      dispatch(resetCurrentTrigger());
      dispatch(fetchAttributesFieldsRequest(INITIAL_ATTRIBUTES_VALUES));
    }
  }, [isModal]);

  return {
    methods: {
      control,
      closeModal,
      resetTrigger,
      reset,
      resetField,
      handleSubmit,
      onAddAttributeHandler,
      onAddCustomConditionHandler,
      onAddTriggerConditionHandler,
      onDeleteAttributeHandler,
      onDeleteCustomConditionHandler,
      onDeleteTriggerConditionHandler,
      onChangeAttribute,
      onChangeCustomField,
      onChangeCustomCondition,
      onChangeRequiredAttributeHandler,
      onChangeAttributeValue,
      onChangeCustomConditionValue,
      onChangeTriggerConditionValue,
      onChangeTriggerCondition,
      setAttributes,
      formSubmitHandler,
      onCloseAlert,
    },
    state: {
      attributes,
      customConditions,
      triggerConditions,
      errors,
      isDisabledSubmit,
      isDisabledTargetStatus,
      isDisabledAttribute,
      isDisabledCustomCondition,
      isDisabledTriggerCondition,
      attributesFields,
      attributesOptions,
      customFieldsOptions,
      triggerConditionOptions,
      titleInputOptions,
      nextTicketStatusOptions,
      alertIsOpen,
    },
  };
};

import { WorkGroup } from 'core/types';
import { DeleteDataType } from 'features/Organizations/ducks/types';
import { CreateWorkGroupData } from 'features/WorkGroup/types';
import {
  CreateWorkGroupAction,
  DeleteWorkGroupRequestAction,
  EditWorkGroupAction,
  FetchCurrentWorkGroupSuccessAction,
  FetchGroupsByOrganizationRequestAction,
  FetchGroupsByUserIdRequestAction,
  FetchWorkGroupRequestAction,
  FetchWorkGroupsFromMyOrgRequestAction,
  FetchWorkGroupsFromMyOrgSuccessAction,
  FetchWorkGroupsRequestAction,
  FetchWorkGroupsSuccessAction,
  HideWorkGroupLoadingAction,
  HideWorkGroupsAddLoadingAction,
  HideWorkGroupsLoadingAction,
  ResetCurrentWorkGroupAction,
  ResetWorkGroupsAddStateAction,
  ResetWorkGroupsStateAction,
  SetCurrentGroupsPageAction,
  SetGroupsAddPageAction,
  SetGroupTitleAction,
  SetSizePageAction,
  SetSizePageGroupsAddAction,
  SetSortGroupsAction,
  SetWorkGroupFilterAction,
  SetWorkGroupIdAction,
  ShowWorkGroupLoadingAction,
  ShowWorkGroupsAddLoadingAction,
  ShowWorkGroupsLoadingAction,
  UpdateWorkGroupAction,
  WorkGroups,
} from 'features/WorkGroups/ducks/types';
import { ResponseWithMeta } from 'store/types';

import { WorkGroupFilter } from '../types';

export const showWorkGroupsLoading = (): ShowWorkGroupsLoadingAction => {
  return {
    type: WorkGroups.WORK_GROUPS_LOADING_SHOW,
  };
};

export const hideWorkGroupsLoading = (): HideWorkGroupsLoadingAction => {
  return {
    type: WorkGroups.WORK_GROUPS_LOADING_HIDE,
  };
};

export const showWorkGroupLoading = (): ShowWorkGroupLoadingAction => {
  return {
    type: WorkGroups.WORK_GROUP_LOADING_SHOW,
  };
};

export const hideWorkGroupLoading = (): HideWorkGroupLoadingAction => {
  return {
    type: WorkGroups.WORK_GROUP_LOADING_HIDE,
  };
};

export const showWorkGroupsAddLoading = (): ShowWorkGroupsAddLoadingAction => {
  return {
    type: WorkGroups.WORK_GROUPS_ADD_LOADING_SHOW,
  };
};

export const hideWorkGroupsAddLoading = (): HideWorkGroupsAddLoadingAction => {
  return {
    type: WorkGroups.WORK_GROUPS_ADD_LOADING_HIDE,
  };
};

export const fetchWorkGroupsRequest = (): FetchWorkGroupsRequestAction => {
  return {
    type: WorkGroups.FETCH_WORK_GROUPS_REQUEST,
  };
};

export const fetchWorkGroupsSuccess = (
  data: ResponseWithMeta<WorkGroup[]>
): FetchWorkGroupsSuccessAction => {
  return {
    type: WorkGroups.FETCH_WORK_GROUPS_SUCCESS,
    payload: data,
  };
};

export const setCurrentWorkGroupsPage = (
  page: number
): SetCurrentGroupsPageAction => {
  return {
    type: WorkGroups.SET_CURRENT_GROUPS_PAGE,
    payload: page,
  };
};

export const setSizePage = (size: number): SetSizePageAction => {
  return {
    type: WorkGroups.SET_SIZE_PAGE_WORK_GROUPS,
    payload: size,
  };
};

export const setGroupsAddPage = (page: number): SetGroupsAddPageAction => {
  return {
    type: WorkGroups.SET_GROUPS_ADD_PAGE,
    payload: page,
  };
};

export const setSizePageGroupsAdd = (
  size: number
): SetSizePageGroupsAddAction => {
  return {
    type: WorkGroups.SET_SIZE_PAGE_GROUPS_ADD,
    payload: size,
  };
};

export const setSortWorkGroups = (sort: string): SetSortGroupsAction => {
  return {
    type: WorkGroups.SET_SORT_GROUPS,
    payload: sort,
  };
};

export const resetWorkGroupsState = (): ResetWorkGroupsStateAction => {
  return {
    type: WorkGroups.RESET_WORK_GROUPS_STATE,
  };
};

export const fetchGroupsByOrganizationId =
  (): FetchGroupsByOrganizationRequestAction => {
    return {
      type: WorkGroups.FETCH_WORK_GROUPS_BY_ORGANIZATION_ID_REQUEST,
    };
  };

export const fetchCurrentWorkGroup = (
  id: string
): FetchWorkGroupRequestAction => {
  return {
    type: WorkGroups.FETCH_WORK_GROUP_REQUEST,
    payload: id,
  };
};

export const fetchCurrentWorkGroupSuccess = (
  data?: WorkGroup
): FetchCurrentWorkGroupSuccessAction => {
  return {
    type: WorkGroups.FETCH_WORK_GROUP_SUCCESS,
    payload: data,
  };
};

export const createWorkGroup = (
  data: CreateWorkGroupData
): CreateWorkGroupAction => {
  return {
    type: WorkGroups.CREATE_WORK_GROUP,
    payload: data,
  };
};

export const editWorkGroup = (
  data: CreateWorkGroupData
): EditWorkGroupAction => {
  return {
    type: WorkGroups.EDIT_WORK_GROUP,
    payload: data,
  };
};

export const fetchGroupsByUserIdRequest =
  (): FetchGroupsByUserIdRequestAction => {
    return {
      type: WorkGroups.FETCH_WORK_GROUPS_BY_USERID_REQUEST,
    };
  };

export const setWorkGroupId = (id: string): SetWorkGroupIdAction => {
  return {
    type: WorkGroups.SET_WORK_GROUP_ID,
    payload: id,
  };
};

export const setGroupTitle = (title?: string): SetGroupTitleAction => {
  return {
    type: WorkGroups.SET_WORK_GROUP_TITLE,
    payload: title,
  };
};

export const updateWorkGroup = (data: WorkGroup): UpdateWorkGroupAction => {
  return {
    type: WorkGroups.UPDATE_WORK_GROUP,
    payload: data,
  };
};

export const setWorkGroupsFilter = (
  data: WorkGroupFilter
): SetWorkGroupFilterAction => {
  return {
    type: WorkGroups.SET_WORK_GROUP_FILTER,
    payload: data,
  };
};

export const setWorkGroupsAddFilter = (data: WorkGroupFilter) => {
  return {
    type: WorkGroups.SET_WORK_GROUP_ADD_FILTER,
    payload: data,
  };
};

export const fetchWorkGroupsAddRequest = () => {
  return {
    type: WorkGroups.FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_REQUEST,
  };
};

export const fetchWorkGroupsAddSuccess = (
  data: ResponseWithMeta<WorkGroup[]>
) => {
  return {
    type: WorkGroups.FETCH_WORK_GROUPS_BY_ORG_ID_BY_SYSTEM_ID_ADD_SUCCESS,
    payload: data,
  };
};

export const fetchWorkGroupsByContractBySystemRequest = () => {
  return {
    type: WorkGroups.FETCH_WORK_GROUPS_BY_CONTRACT_BY_SYSTEM_REQUEST,
  };
};

export const resetWorkGroupsAddState = (): ResetWorkGroupsAddStateAction => {
  return {
    type: WorkGroups.RESET_WORK_GROUPS_ADD_STATE,
  };
};

export const fetchWorkGroupsFromMyOrgRequest =
  (): FetchWorkGroupsFromMyOrgRequestAction => {
    return {
      type: WorkGroups.FETCH_WORK_GROUPS_FROM_MY_ORG_REQUEST,
    };
  };

export const fetchWorkGroupsFromMyOrgSuccess = (
  workGroups: WorkGroup[]
): FetchWorkGroupsFromMyOrgSuccessAction => {
  return {
    type: WorkGroups.FETCH_WORK_GROUPS_FROM_MY_ORG_SUCCESS,
    payload: workGroups,
  };
};

export const deleteWorkGroupRequest = (
  data: DeleteDataType
): DeleteWorkGroupRequestAction => {
  return {
    type: WorkGroups.DELETE_WORK_GROUP_REQUEST,
    payload: data,
  };
};

export const resetCurrentWorkGroup = (): ResetCurrentWorkGroupAction => {
  return {
    type: WorkGroups.RESET_CURRENT_WORK_GROUP,
  };
};

import { FetchRequestArgs } from 'components/SearchSelect/types';
import { OrganizationType, User } from 'core/types';
import { DeleteDataType } from 'features/Organizations/ducks/types';
import { CreateUserData } from 'features/User/types';
import { ResponseWithMeta } from 'store/types';

import { UsersFilter, UsersFilterToRequest } from '../types';

export enum Users {
  FETCH_USERS_SUCCESS_UPDATE = 'USERS/FETCH_USERS_SUCCESS_UPDATE',
  FETCH_USERS_SUCCESS_JOIN = 'USERS/FETCH_USERS_SUCCESS_JOIN',
  FETCH_USERS_CLIENTS_SUCCESS_UPDATE = 'USERS/FETCH_USERS_CLIENTS_SUCCESS_UPDATE',
  FETCH_USERS_CLIENTS_SUCCESS_JOIN = 'USERS/FETCH_USERS_CLIENTS_SUCCESS_JOIN',
  FETCH_USERS_SPECIALISTS_SUCCESS_UPDATE = 'USERS/FETCH_USERS_SPECIALISTS_SUCCESS_UPDATE',
  FETCH_USERS_SPECIALISTS_SUCCESS_JOIN = 'USERS/FETCH_USERS_SPECIALISTS_SUCCESS_JOIN',
  FETCH_USERS_REQUEST = 'USERS/FETCH_USERS_REQUEST',
  USERS_LOADING_SHOW = 'USERS/USERS_LOADING_SHOW',
  USERS_LOADING_HIDE = 'USERS/USERS_LOADING_HIDE',
  USERS_ADD_LOADING_SHOW = 'USERS/USERS_ADD_LOADING_SHOW',
  USERS_ADD_LOADING_HIDE = 'USERS/USERS_ADD_LOADING_HIDE',
  FETCH_USER_CLIENTS_REQUEST = 'USERS/FETCH_USER_CLIENTS_REQUEST',
  FETCH_USER_SPECIALISTS_REQUEST = 'USERS/FETCH_USER_SPECIALISTS_REQUEST',
  FETCH_SUBORDINATES_SUCCESS = 'USERS/FETCH_SUBORDINATES_SUCCESS',
  FETCH_SUBORDINATES_REQUEST = 'USERS/FETCH_SUBORDINATES_REQUEST',
  SET_USERS_PAGE = 'USERS/SET_USERS_PAGE',
  SET_SORT_USERS = 'USERS/SET_SORT_USERS',
  SET_SIZE_PAGE_USERS = 'USERS/SET_SIZE_PAGE_USERS',
  SET_USER_LOGIN = 'USERS/SET_USER_LOGIN',
  RESET_CURRENT_USER_STATE = 'USERS/RESET_CURRENT_USER_STATE',
  FETCH_USER_REQUEST = 'USERS/FETCH_USER_REQUEST',
  FETCH_CURRENT_USER_SUCCESS = 'USERS/FETCH_CURRENT_USER_SUCCESS',
  CREATE_USER = 'USERS/CREATE_USER',
  EDIT_USER = 'USERS/EDIT_USER',
  SET_USER_ID = 'USERS/SET_USER_ID',
  RESET_USERS_STATE = 'USERS/RESET_USERS_STATE',
  FETCH_USERS_BY_GROUPS_ID_REQUEST = 'USERS/FETCH_USERS_BY_GROUPS_ID_REQUEST',
  UPDATE_USER_DATA_REQUEST = 'USERS/UPDATE_USER_DATA_REQUEST',
  FETCH_USERS_ADD_SUCCESS_UPDATE = 'USERS/FETCH_USERS_ADD_SUCCESS_UPDATE',
  FETCH_USERS_ADD_SUCCESS_JOIN = 'USERS/FETCH_USERS_ADD_SUCCESS_JOIN',
  SET_USERS_ADD_FILTER = 'USERS/SET_USERS_ADD_FILTER',
  SET_SUBORDINATES_FILTER = 'USERS/SET_SUBORDINATES_FILTER',
  SET_USERS_FILTER = 'USERS/SET_USERS_FILTER',
  UPDATE_USER_ROLES_IN_GROUP = 'USERS/UPDATE_USER_ROLES_IN_GROUP',
  UPDATE_USER_ROLES_IN_SYSTEM = 'USERS/UPDATE_USER_ROLES_IN_SYSTEM',
  RESET_USERS_ADD_STATE = 'USERS/RESET_USERS_ADD_STATE',
  FETCH_USERS_ADD_BY_ORG_ID_REQUEST = 'USERS/FETCH_USERS_ADD_BY_ORG_ID_REQUEST',
  SET_USERS_ADD_PAGE = 'USERS/SET_USERS_ADD_PAGE',
  SET_SORT_USERS_ADD = 'USERS/SET_SORT_USERS_ADD',
  SET_SIZE_PAGE_USERS_ADD = 'USERS/SET_SIZE_PAGE_USERS_ADD',
  CHECK_IS_EXIST_LOGIN_REQUEST = 'USERS/CHECK_IS_EXIST_LOGIN_REQUEST',
  CHECK_IS_EXIST_LOGIN_SUCCESS = 'USERS/CHECK_IS_EXIST_LOGIN_SUCCESS',
  CHECK_IS_EXIST_EMAIL_REQUEST = 'USERS/CHECK_IS_EXIST_EMAIL_REQUEST',
  CHECK_IS_EXIST_EMAIL_SUCCESS = 'USERS/CHECK_IS_EXIST_EMAIL_SUCCESS',
  CHECK_IS_EXIST_PHONE_REQUEST = 'USERS/CHECK_IS_EXIST_PHONE_REQUEST',
  CHECK_IS_EXIST_PHONE_SUCCESS = 'USERS/CHECK_IS_EXIST_PHONE_SUCCESS',
  FETCH_USERS_ADD_BY_CONTRACT_ID_REQUEST = 'USERS/FETCH_USERS_ADD_BY_CONTRACT_ID_REQUEST',
  DELETE_USER_BY_ID_REQUEST = 'USERS/DELETE_USER_BY_ID_REQUEST',
  USER_LOADING_SHOW = 'USERS/USER_LOADING_SHOW',
  USER_LOADING_HIDE = 'USERS/USER_LOADING_HIDE',
  RESET_CURRENT_USER_AFTER_DELETE = 'USERS/RESET_CURRENT_USER_AFTER_DELETE',
}

export interface ShowUsersLoadingAction {
  type: Users.USERS_LOADING_SHOW;
}

export interface HideUsersLoadingAction {
  type: Users.USERS_LOADING_HIDE;
}

export interface ShowUsersAddLoadingAction {
  type: Users.USERS_ADD_LOADING_SHOW;
}

export interface HideUsersAddLoadingAction {
  type: Users.USERS_ADD_LOADING_HIDE;
}

export interface ShowUserLoadingAction {
  type: Users.USER_LOADING_SHOW;
}

export interface HideUserLoadingAction {
  type: Users.USER_LOADING_HIDE;
}

export interface FetchUsersRequestAction {
  type: Users.FETCH_USERS_REQUEST;
}

export interface FetchUsersSuccessUpdateAction {
  type: Users.FETCH_USERS_SUCCESS_UPDATE;
  payload: ResponseWithMeta<User[]>;
}

export interface FetchUsersSuccessJoinAction {
  type: Users.FETCH_USERS_SUCCESS_JOIN;
  payload: ResponseWithMeta<User[]>;
}

export interface FetchUsersClientsSuccessUpdateAction {
  type: Users.FETCH_USERS_CLIENTS_SUCCESS_UPDATE;
  payload: ResponseWithMeta<User[]>;
}

export interface FetchUsersClientsSuccessJoinAction {
  type: Users.FETCH_USERS_CLIENTS_SUCCESS_JOIN;
  payload: ResponseWithMeta<User[]>;
}

export interface FetchUsersSpecialistsSuccessUpdateAction {
  type: Users.FETCH_USERS_SPECIALISTS_SUCCESS_UPDATE;
  payload: ResponseWithMeta<User[]>;
}

export interface FetchUsersSpecialistsSuccessJoinAction {
  type: Users.FETCH_USERS_SPECIALISTS_SUCCESS_JOIN;
  payload: ResponseWithMeta<User[]>;
}

export interface UsersTicketsPayload
  extends FetchRequestArgs<UsersFilterToRequest> {
  organizationType?: OrganizationType;
}

export interface FetchUserClientsRequestAction {
  type: Users.FETCH_USER_CLIENTS_REQUEST;
  payload: UsersTicketsPayload;
}

export interface FetchSpecialistsRequestAction {
  type: Users.FETCH_USER_SPECIALISTS_REQUEST;
  payload: UsersTicketsPayload;
}

export interface FetchSubordinatesRequestAction {
  type: Users.FETCH_SUBORDINATES_REQUEST;
}

export interface FetchSubordinatesSuccessAction {
  type: Users.FETCH_SUBORDINATES_SUCCESS;
  payload: User[];
}

export interface SetUsersPageAction {
  type: Users.SET_USERS_PAGE;
  payload: number;
}

export interface SetSizePageAction {
  type: Users.SET_SIZE_PAGE_USERS;
  payload: number;
}

export interface SetSortUsersAction {
  type: Users.SET_SORT_USERS;
  payload: string;
}

export interface FetchUserRequestAction {
  type: Users.FETCH_USER_REQUEST;
  payload: string;
}

export interface FetchCurrentUserSuccessAction {
  type: Users.FETCH_CURRENT_USER_SUCCESS;
  payload?: User;
}

export interface CreateUserAction {
  type: Users.CREATE_USER;
  payload: CreateUserData;
}

export interface EditUserAction {
  type: Users.EDIT_USER;
  payload: CreateUserData;
}

export interface SetUserIdAction {
  type: Users.SET_USER_ID;
  payload?: string;
}

export interface ResetUsersStateAction {
  type: Users.RESET_USERS_STATE;
}

export interface FetchUsersByGroupsIdRequestAction {
  type: Users.FETCH_USERS_BY_GROUPS_ID_REQUEST;
}

export interface FetchUsersAddSuccessUpdateAction {
  type: Users.FETCH_USERS_ADD_SUCCESS_UPDATE;
  payload: ResponseWithMeta<User[]>;
}

export interface FetchUsersAddSuccessJoinAction {
  type: Users.FETCH_USERS_ADD_SUCCESS_JOIN;
  payload: ResponseWithMeta<User[]>;
}

export interface SetUserLoginAction {
  type: Users.SET_USER_LOGIN;
  payload?: string;
}

export interface ResetCurrentUserAction {
  type: Users.RESET_CURRENT_USER_STATE;
}

export interface UpdateUserDataRequestAction {
  type: Users.UPDATE_USER_DATA_REQUEST;
  payload: User;
}

export interface SetUsersAddFilterAction {
  type: Users.SET_USERS_ADD_FILTER;
  payload: UsersFilter;
}

export interface SetSubordinatesFilterAction {
  type: Users.SET_SUBORDINATES_FILTER;
  payload: UsersFilter;
}

export interface SetUsersFilterAction {
  type: Users.SET_USERS_FILTER;
  payload: UsersFilter;
}

export interface UpdateUseRolesInSystemAction {
  type: Users.UPDATE_USER_ROLES_IN_SYSTEM;
  payload: string[];
}

export interface ResetUsersAddStateAction {
  type: Users.RESET_USERS_ADD_STATE;
}

export interface FetchUsersAddByOrgIdRequestAction {
  type: Users.FETCH_USERS_ADD_BY_ORG_ID_REQUEST;
}

export interface SetUsersAddPageAction {
  type: Users.SET_USERS_ADD_PAGE;
  payload: number;
}

export interface SetSizePageAddAction {
  type: Users.SET_SIZE_PAGE_USERS_ADD;
  payload: number;
}

export interface SetSortUsersAddAction {
  type: Users.SET_SORT_USERS_ADD;
  payload: string;
}

export interface CheckIsExistLoginRequestAction {
  type: Users.CHECK_IS_EXIST_LOGIN_REQUEST;
  payload: string;
}

export interface CheckIsExistLoginSuccessAction {
  type: Users.CHECK_IS_EXIST_LOGIN_SUCCESS;
  payload: boolean;
}

export interface CheckIsExistEmailRequestAction {
  type: Users.CHECK_IS_EXIST_EMAIL_REQUEST;
  payload: string;
}

export interface CheckIsExistEmailSuccessAction {
  type: Users.CHECK_IS_EXIST_EMAIL_SUCCESS;
  payload: boolean;
}

export interface CheckIsExistPhoneRequestAction {
  type: Users.CHECK_IS_EXIST_PHONE_REQUEST;
  payload: string;
}

export interface CheckIsExistPhoneSuccessAction {
  type: Users.CHECK_IS_EXIST_PHONE_SUCCESS;
  payload: boolean;
}

export interface FetchUsersAddByContractIdRequestAction {
  type: Users.FETCH_USERS_ADD_BY_CONTRACT_ID_REQUEST;
}

export interface DeleteUserRequestAction {
  type: Users.DELETE_USER_BY_ID_REQUEST;
  payload: DeleteDataType;
}

export interface ResetCurrentUserAfterDeleteAction {
  type: Users.RESET_CURRENT_USER_AFTER_DELETE;
}

export type UsersActionsTypes =
  | FetchUsersSuccessUpdateAction
  | FetchUsersSuccessJoinAction
  | FetchCurrentUserSuccessAction
  | SetUserIdAction
  | ResetUsersStateAction
  | SetUsersPageAction
  | SetSizePageAction
  | SetSortUsersAction
  | SetUserLoginAction
  | ResetCurrentUserAction
  | SetUsersAddFilterAction
  | SetSubordinatesFilterAction
  | FetchUsersClientsSuccessJoinAction
  | FetchUsersClientsSuccessUpdateAction
  | FetchUsersSpecialistsSuccessUpdateAction
  | FetchUsersSpecialistsSuccessJoinAction
  | FetchSubordinatesSuccessAction
  | SetUsersFilterAction
  | ResetUsersAddStateAction
  | SetUsersAddPageAction
  | SetSizePageAddAction
  | SetSortUsersAddAction
  | CheckIsExistLoginSuccessAction
  | CheckIsExistEmailSuccessAction
  | CheckIsExistPhoneSuccessAction
  | ResetCurrentUserAfterDeleteAction
  | FetchUserClientsRequestAction
  | FetchSpecialistsRequestAction
  | FetchUsersAddSuccessUpdateAction
  | FetchUsersAddSuccessJoinAction;

import { Reducer } from 'redux';

import { DEFAULT_PAGINATION_PARAMS } from 'constants/meta';
import { Article } from 'features/KnowledgeBase/types';
import { Ticket } from 'features/Ticket/types';
import { ReducerMap } from 'store/types';
import { Attachment } from 'types/models/attachment';
import { Pagination } from 'types/models/meta';

import {
  FetchArticlesSuccessAction,
  FetchSimilarArticleSuccessAction,
  FetchSimilarTicketAttachmentsSuccessAction,
  FetchSimilarTicketSuccessAction,
  FetchTicketsSuccessAction,
  SimilarSolutions,
  SimilarSolutionsActionsTypes,
} from './types';

export interface SimilarSolutionsReducerState {
  tickets?: Ticket[];
  articles?: Article[];
  loading?: boolean;
  loadingDetail?: boolean;
  pagination?: Pagination;
  ticket?: Ticket;
  article?: Article;
  ticketAttachments?: Attachment[];
}

const initialState: SimilarSolutionsReducerState = {
  tickets: [],
  articles: [],
  loading: false,
  loadingDetail: false,
  pagination: { ...DEFAULT_PAGINATION_PARAMS, pageSize: 1000 },
  ticketAttachments: [],
};

const similarSolutionsReducerMap: ReducerMap<
  SimilarSolutionsReducerState,
  SimilarSolutionsActionsTypes
> = {
  [SimilarSolutions.SIMILAR_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [SimilarSolutions.SIMILAR_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [SimilarSolutions.FETCH_SIMILAR_TICKETS_SUCCESS]: (state, action) => {
    const { payload } = action as FetchTicketsSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      tickets: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [SimilarSolutions.FETCH_SIMILAR_ARTICLES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchArticlesSuccessAction;
    const { content, page, totalElements } = payload;
    return {
      ...state,
      articles: content,
      pagination: {
        ...state?.pagination,
        pageNum: page,
        totalElements,
      },
    };
  },
  [SimilarSolutions.FETCH_SIMILAR_TICKET_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSimilarTicketSuccessAction;
    return {
      ...state,
      ticket: payload,
    };
  },
  [SimilarSolutions.FETCH_SIMILAR_ARTICLE_SUCCESS]: (state, action) => {
    const { payload } = action as FetchSimilarArticleSuccessAction;
    return {
      ...state,
      article: payload,
    };
  },
  [SimilarSolutions.SIMILAR_DETAIL_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loadingDetail: true,
    };
  },
  [SimilarSolutions.SIMILAR_DETAIL_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loadingDetail: false,
    };
  },
  [SimilarSolutions.FETCH_SIMILAR_TICKET_ATTACHMENTS_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchSimilarTicketAttachmentsSuccessAction;
    return {
      ...state,
      ticketAttachments: payload,
    };
  },
  [SimilarSolutions.RESET_SIMILAR_SOLUTIONS_STATE]: () => {
    return {
      ...initialState,
    };
  },
};

export const similarSolutions: Reducer<
  SimilarSolutionsReducerState,
  SimilarSolutionsActionsTypes
> = (state = initialState, action) => {
  const reducer = similarSolutionsReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};

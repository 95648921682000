import { isString } from 'lodash';

import { CustomFieldType } from 'components/types';

import { ACTIVE_SELECT_OPTIONS } from '../../TableFilter/constants';
import { CustomField } from '../types';

import { getReverseDateFromCustomField } from './getReverseDateFromCustomField';

const getPropsCustomFieldType = (field: CustomField) => {
  const fieldTypeMap = {
    [CustomFieldType.FIELD_DATE]: () =>
      isString(field.date) ? getReverseDateFromCustomField(field.date) : '-',
    [CustomFieldType.FIELD_FLAG]: () =>
      field.flag
        ? ACTIVE_SELECT_OPTIONS[0].title
        : ACTIVE_SELECT_OPTIONS[1].title,
    [CustomFieldType.FIELD_TEXT]: () => field.text || '-',
  };

  return fieldTypeMap[field.customFieldType]();
};

export const getCustomFieldsForTable = (fields: CustomField[]) => {
  const customFieldsForTable: Record<string, string | boolean> = {};

  fields.forEach((field) => {
    if (field.customFieldId) {
      customFieldsForTable[field.customFieldId] =
        getPropsCustomFieldType(field);
    }
  });

  return customFieldsForTable;
};

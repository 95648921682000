import React from 'react';

import { AdminsLayout } from 'core/layouts/AdminsLayout';
import { Systems } from 'features/Systems';

const AdminSystems = () => {
  return (
    <AdminsLayout>
      <Systems />
    </AdminsLayout>
  );
};

export default AdminSystems;

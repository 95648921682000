import cn from 'clsx';
import React, { useState } from 'react';

import { Card } from 'components/Card';
import { Loader } from 'components/Loader';
import { CurrentTicketAdaptive } from 'features/Tickets/components/CurrentTicketAdaptive';

import styles from './TicketAdaptiveInfo.module.scss';

interface Props {
  isLoading?: boolean;
}

export const TicketAdaptiveInfo: React.FC<Props> = ({ isLoading }) => {
  const [, setIsOpen] = useState(true);

  const toggleOpenHandler = () => {
    setIsOpen((prevState) => !prevState);
  };

  const loader = isLoading && (
    <Card className={styles.ticketAdaptiveInfo__loaderWrapper}>
      <div className={styles.ticketAdaptiveInfo__loader}>
        <Loader />
      </div>
    </Card>
  );

  return (
    <div
      onClick={toggleOpenHandler}
      onKeyDown={toggleOpenHandler}
      role="button"
      tabIndex={-1}
      className={styles.ticketAdaptiveInfo}
    >
      <CurrentTicketAdaptive
        className={cn(styles.ticketAdaptiveInfo__content, {
          // [styles.ticketAdaptiveInfo__content_hidden]: !isOpen,
        })}
        withPriority
      />
      {loader}
    </div>
  );
};

import { Contract, System, TicketTypeSettings, WorkGroup } from 'core/types';
import { ContractDetailPayload } from 'features/Contract/ducks/types';
import { ContractIdSystemIdArgs, SLAType } from 'features/Contract/types';
import { WorkGroupFilter } from 'features/WorkGroups/types';
import { ResponseWithMeta } from 'store/types';
import { Attachment } from 'types/models/attachment';

import { CreateSupplementaryAgreementType } from '../types';

import {
  CreateSupplementaryAgreementSupAgDetailAction,
  DeleteSupplementaryAgreementDetailRequestAction,
  FetchAttachmentsRequestSupAgDetailAction,
  FetchAttachmentsSuccessSupAgDetailAction,
  FetchSlaBySAIdBySystemIdRequestSupAgDetailAction,
  FetchSlaBySAIdBySystemIdSuccessSupAgDetailAction,
  FetchSupplementaryAgreementRequestSupAgDetailAction,
  FetchSupplementaryAgreementSuccessSupAgDetailAction,
  FetchSystemByIdRequestSupAgDetailAction,
  FetchSystemByIdSuccessSupAgDetailAction,
  FetchSystemsBySAIdRequestSupAgDetailAction,
  FetchSystemsBySAIdSuccessSupAgDetailAction,
  FetchTicketTypesRequestSupAgDetailAction,
  FetchTicketTypesSuccessSupAgDetailAction,
  FetchWorkGroupsBySABySystemRequestSupAgDetailAction,
  FetchWorkGroupsBySABySystemSuccessSupAgDetailAction,
  HideAttachmentsLoadingSupAgDetailAction,
  LoadingHideSlaBySAIdBySystemIdRequestSupAgDetailAction,
  LoadingHideSystemByIdSupAgDetailAction,
  LoadingHideWorkGroupsBySABySystemSupAgDetailAction,
  LoadingShowSlaBySAIdBySystemIdRequestSupAgDetailAction,
  LoadingShowSystemByIdSupAgDetailAction,
  LoadingShowWorkGroupsBySABySystemSupAgDetailAction,
  ResetSupplementaryAgreementDetailStateAction,
  SetFilterWorkGroupsBySABySystemSupAgDetailAction,
  SetPageWorkGroupsBySABySystemSupAgDetailAction,
  SetSizePageWorkGroupsBySABySystemSupAgDetailAction,
  SetSortWorkGroupsBySABySystemSupAgDetailAction,
  ShowAttachmentsLoadingSupAgDetailAction,
  SupplementaryAgreementDetail,
} from './types';

export interface EditContractPayload {
  contract: Contract;
  systemId?: string;
}

export const showSupplementaryAgreementLoadingSADetail = () => {
  return {
    type: SupplementaryAgreementDetail.SUPPLEMENTARY_AGREEMENT_LOADING_SHOW,
  };
};

export const hideSupplementaryAgreementLoadingSADetail = () => {
  return {
    type: SupplementaryAgreementDetail.SUPPLEMENTARY_AGREEMENT_LOADING_HIDE,
  };
};

export const fetchSupplementaryAgreementRequestSADetail = (
  id: string
): FetchSupplementaryAgreementRequestSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_SUP_AG_REQUEST,
    payload: id,
  };
};

export const fetchSupplementaryAgreementSuccessSADetail = (
  contract: Contract
): FetchSupplementaryAgreementSuccessSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_SUP_AG_SUCCESS,
    payload: contract,
  };
};

export const createSupplementaryAgreementSADetail = (
  data: CreateSupplementaryAgreementType
): CreateSupplementaryAgreementSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.CREATE_SUP_AG_DETAIL,
    payload: data,
  };
};
// подгрузка системы для создания допсоглашения (1 система по id)
export const fetchSystemByIdRequestSupAgDetail = (
  id: string
): FetchSystemByIdRequestSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_SYSTEM_BY_ID_REQUEST,
    payload: id,
  };
};

export const fetchSystemByIdSuccessSupAgDetail = (
  system: System
): FetchSystemByIdSuccessSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_SYSTEM_BY_ID_SUCCESS,
    payload: system,
  };
};

export const loadingShowSystemByIdSADetail =
  (): LoadingShowSystemByIdSupAgDetailAction => {
    return {
      type: SupplementaryAgreementDetail.LOADING_SHOW_SYSTEM_BY_ID,
    };
  };

export const loadingHideSystemByIdSADetail =
  (): LoadingHideSystemByIdSupAgDetailAction => {
    return {
      type: SupplementaryAgreementDetail.LOADING_HIDE_SYSTEM_BY_ID,
    };
  };

// подгрузка систем по id сохраненного допсоглашения (массив систем с размером 1)
export const fetchSystemsBySAIdRequestSADetail = (
  id: string
): FetchSystemsBySAIdRequestSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_SYSTEMS_BY_SUP_AG_ID_REQUEST,
    payload: id,
  };
};

export const fetchSystemsBySAIdSuccessSADetail = (
  system: System[]
): FetchSystemsBySAIdSuccessSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_SYSTEMS_BY_SUP_AG_ID_SUCCESS,
    payload: system,
  };
};

// удаление допсоглашения
export const deleteSupplementaryAgreementDetailRequest = (
  contract: Contract
): DeleteSupplementaryAgreementDetailRequestAction => {
  return {
    type: SupplementaryAgreementDetail.DELETE_SUP_AG_REQUEST,
    payload: contract,
  };
};

export const resetSupplementaryAgreementDetailState =
  (): ResetSupplementaryAgreementDetailStateAction => {
    return {
      type: SupplementaryAgreementDetail.RESET_SUP_AG_DETAIL_STATE,
    };
  };

// attachments
export const showAttachmentsLoadingSADetail =
  (): ShowAttachmentsLoadingSupAgDetailAction => {
    return {
      type: SupplementaryAgreementDetail.ATTACHMENTS_LOADING_SHOW,
    };
  };

export const hideAttachmentsLoadingSADetail =
  (): HideAttachmentsLoadingSupAgDetailAction => {
    return {
      type: SupplementaryAgreementDetail.ATTACHMENTS_LOADING_HIDE,
    };
  };

export const fetchAttachmentsRequestSupAgDetail = (
  id: string
): FetchAttachmentsRequestSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_SUP_AG_ATTACHMENTS_REQUEST,
    payload: id,
  };
};

export const fetchAttachmentsSuccessSupAgDetail = (
  data: Attachment[]
): FetchAttachmentsSuccessSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_SUP_AG_ATTACHMENTS_SUCCESS,
    payload: data,
  };
};

// work groups
export const fetchWorkGroupsBySupAgBySystemRequestSupAgDetail = (
  data: ContractDetailPayload
): FetchWorkGroupsBySABySystemRequestSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_REQUEST,
    payload: data,
  };
};

export const fetchWorkGroupsBySupAgBySystemSuccessSupAgDetail = (
  groups: ResponseWithMeta<WorkGroup[]>
): FetchWorkGroupsBySABySystemSuccessSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM_SUCCESS,
    payload: groups,
  };
};

export const loadingShowWorkGroupsBySupAgBySystemSupAgDetail =
  (): LoadingShowWorkGroupsBySABySystemSupAgDetailAction => {
    return {
      type: SupplementaryAgreementDetail.LOADING_SHOW_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM,
    };
  };

export const loadingHideWorkGroupsBySupAgBySystemSupAgDetail =
  (): LoadingHideWorkGroupsBySABySystemSupAgDetailAction => {
    return {
      type: SupplementaryAgreementDetail.LOADING_HIDE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM,
    };
  };

export const setPageWorkGroupsBySupAgBySystemSupAgDetail = (
  pageNum: number
): SetPageWorkGroupsBySABySystemSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.SET_WORK_GROUPS_PAGE_BY_SUP_AG_BY_SYSTEM,
    payload: pageNum,
  };
};

export const setFilterWorkGroupsBySupAgBySystemSupAgDetail = (
  filter: WorkGroupFilter
): SetFilterWorkGroupsBySABySystemSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.SET_WORK_GROUPS_FILTER_BY_SUP_AG_BY_SYSTEM,
    payload: filter,
  };
};

export const setSizePageWorkGroupsBySupAgBySystemSupAgDetail = (
  pageSize: number
): SetSizePageWorkGroupsBySABySystemSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.SET_SIZE_PAGE_WORK_GROUPS_BY_SUP_AG_BY_SYSTEM,
    payload: pageSize,
  };
};

export const setSortWorkGroupsBySupAgBySystemSupAgDetail = (
  sort: string
): SetSortWorkGroupsBySABySystemSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.SET_WORK_GROUPS_SORT_BY_SUP_AG_BY_SYSTEM,
    payload: sort,
  };
};

export const fetchSlaBySAIdBySystemIdRequestSADetail = (
  data: ContractDetailPayload
): FetchSlaBySAIdBySystemIdRequestSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
    payload: data,
  };
};

export const fetchSlaBySAIdBySystemIdSuccessSADetail = (
  sla: SLAType
): FetchSlaBySAIdBySystemIdSuccessSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_SUCCESS,
    payload: sla,
  };
};

export const fetchTicketTypesRequestSADetail = (
  payload: ContractIdSystemIdArgs
): FetchTicketTypesRequestSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_TICKET_TYPES_REQUEST,
    payload,
  };
};

export const fetchTicketTypesSuccessSADetail = (
  payload: TicketTypeSettings[]
): FetchTicketTypesSuccessSupAgDetailAction => {
  return {
    type: SupplementaryAgreementDetail.FETCH_TICKET_TYPES_SUCCESS,
    payload,
  };
};

export const loadingShowSlaBySAIdBySystemIdRequestSADetail =
  (): LoadingShowSlaBySAIdBySystemIdRequestSupAgDetailAction => {
    return {
      type: SupplementaryAgreementDetail.LOADING_SHOW_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
    };
  };

export const loadingHideSlaBySAIdBySystemIdRequestSADetail =
  (): LoadingHideSlaBySAIdBySystemIdRequestSupAgDetailAction => {
    return {
      type: SupplementaryAgreementDetail.LOADING_HIDE_SLA_BY_CONTRACT_ID_BY_SYSTEM_ID_REQUEST,
    };
  };

export const getNumberForCreateRequestSupAgDetail = (id: string) => {
  return {
    type: SupplementaryAgreementDetail.GET_NUMBER_FOR_CREATE_REQUEST,
    payload: id,
  };
};

export const getNumberForCreateSuccessSupAgDetail = (title: string) => {
  return {
    type: SupplementaryAgreementDetail.GET_NUMBER_FOR_CREATE_SUCCESS,
    payload: title,
  };
};

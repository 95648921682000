import { omit } from 'lodash';

import { getReverseDateForRequest } from 'components/Table/components/TableFilter/utils';
import { CustomFieldType } from 'components/types';

import { CreateTicketCustomField } from '../types';

export const getPreparedCustomFields = (
  customFields?: CreateTicketCustomField[]
) => {
  return customFields?.map((customField) => {
    if (customField.type === CustomFieldType.FIELD_DATE && customField.date) {
      return omit(
        {
          ...customField,
          date: getReverseDateForRequest(customField.date),
        },
        ['title']
      );
    }
    return omit(customField, ['title']);
  });
};

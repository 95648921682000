import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { SearchIcon } from 'assets/icons';
import { Input } from 'components/Input';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { SimilarSolution } from 'features/SimilarSolutions/components/SimilarSolution';

import { tabs } from '../../constants';
import {
  fetchArticlesRequest,
  fetchTicketsRequest,
  resetSimilarSolutionsState,
} from '../../ducks/actions';
import { SearchData, SolutionType, Tab } from '../../types';
import { SimilarSolutionsList } from '../SimilarSolutionsList';

import styles from './SimilarSolutionsContent.module.scss';

interface Props {
  toggleModal(): void;
}

export const SimilarSolutionsContent: React.FC<Props> = ({ toggleModal }) => {
  const [activeTab, setActiveTab] = useState<Tab>('Тикеты');
  const [isOnlyTitles, setIsOnlyTitles] = useState(false);
  const [isDetailModal, setIsDetailModal] = useState(false);

  const dispatch = useDispatch();

  const isTicketsTab = activeTab === 'Тикеты';

  const toggleIsDetailModal = () => {
    setIsDetailModal((prevState) => !prevState);
  };

  const onChangeTabHandler = (tab: Tab) => {
    setActiveTab(tab);
  };

  const { register, handleSubmit, resetField, watch } = useForm<{
    text: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      text: '',
    },
  });

  const { text } = watch();

  const formSubmitHandler = handleSubmit((data) => {
    const searchData: SearchData = {
      onlyTitleSearch: isOnlyTitles,
      queryText: data.text,
    };
    if (data.text.length >= 3) {
      if (isTicketsTab) {
        dispatch(fetchTicketsRequest(searchData));
        return;
      }
      dispatch(fetchArticlesRequest(searchData));
      return;
    }
    dispatch(resetSimilarSolutionsState());
  });

  const resetTextHandler = () => {
    resetField('text');
    dispatch(resetSimilarSolutionsState());
  };

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    [isTicketsTab, isOnlyTitles]
  );

  const toggleIsOnlyTitles = () => {
    setIsOnlyTitles((prevState) => !prevState);
  };

  const textInputOptions = register('text', {
    onChange: formSubmitHandlerDebounced,
  });

  useEffect(() => {
    formSubmitHandler();
  }, [isTicketsTab, isOnlyTitles]);

  return (
    <div className={styles.similarSolutionsContent}>
      <div className={styles.similarSolutionsContent__header}>
        <Input
          {...textInputOptions}
          label="Поиск"
          className={styles.similarSolutionsContent__searchInput}
          onClear={resetTextHandler}
          rightIcon={<SearchIcon />}
        />
        <div className={styles.similarSolutionsContent__switchWrapper}>
          <ToggleSwitch
            label="Только в названиях"
            checked={isOnlyTitles}
            onChange={toggleIsOnlyTitles}
          />
        </div>
      </div>
      <SimilarSolutionsList
        keyWord={text}
        isOnlyTitles={isOnlyTitles}
        activeTab={activeTab}
        onTabChangeHandler={onChangeTabHandler}
        toggleIsDetailModal={toggleIsDetailModal}
        tabs={tabs}
      />
      <SimilarSolution
        type={isTicketsTab ? SolutionType.TICKET : SolutionType.ARTICLE}
        isOpen={isDetailModal}
        toggleIsOpen={toggleIsDetailModal}
        toggleParentModal={toggleModal}
      />
    </div>
  );
};

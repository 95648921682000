import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { Card } from 'components/Card';
import { SLA } from 'features/SLA';
import { Ticket } from 'features/Ticket/types';

import { fetchTicketRequest } from '../../ducks/ticket/actions';

import styles from './TicketSla.module.scss';

interface Props {
  ticket: Ticket;
}

export const TicketSLA = forwardRef<HTMLDivElement, Props>(
  ({ ticket }, ref) => {
    const dispatch = useDispatch();

    const updateTicket = () => {
      if (ticket.id) {
        dispatch(fetchTicketRequest({ ticketId: ticket.id }));
      }
    };

    return (
      <Card ref={ref} className={styles.ticketSla}>
        <SLA
          ticket={ticket}
          updateTicket={updateTicket}
          size="small"
          isTicketPage
        />
      </Card>
    );
  }
);

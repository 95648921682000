import { Priority } from 'core/types';
import { SLASettings } from 'features/Contract/types';

import {
  CheckedPriorityType,
  SLARowType,
  SLATableDataType,
} from '../../SlaTable/types';

const checkValidSettingsValue = (value?: string | number) => {
  return Number.isNaN(Number(value))
    ? !(value?.toString().includes('_') || value?.toString() === '00:00')
    : Boolean(value);
};

const checkValidSettings = (data: SLASettings) =>
  checkValidSettingsValue(data?.decisionTime) &&
  checkValidSettingsValue(data?.reactionTime);

const checkValidRow = (data: SLARowType) => {
  const ticketTypes = Object.keys(data);
  return ticketTypes.reduce((isValidRow, type) => {
    return isValidRow && checkValidSettings(data[type]);
  }, true);
};

export const checkValidTableSLA = (
  data: SLATableDataType,
  checkedRows: CheckedPriorityType
) => {
  const priorities = Object.keys(data);
  const checkedKeys = Object.entries(checkedRows)
    .filter(([, isChecked]) => isChecked)
    ?.map(([priority]) => priority);

  if (!priorities.length || !checkedKeys.length) {
    return false;
  }

  return (
    priorities.reduce((isValidTable, priority: string) => {
      return (
        isValidTable &&
        checkValidRow(data[priority as Priority]) &&
        checkedKeys.includes(priority)
      );
    }, true) && checkedKeys.length === priorities.length
  );
};

import React, { useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setTheme } from 'core/ducks/actions';
import {
  getContrastTheme,
  getSystemTheme,
  getTheme,
} from 'core/ducks/selectors';
import { useSystemTheme } from 'core/hooks/useSystemTheme';
import { Theme, ThemeActive } from 'core/types';

export const withTheme = <Props,>(Component: React.FC<Props>) => {
  return (props: Props) => {
    const theme = useSelector(getTheme);
    const systemTheme = useSelector(getSystemTheme);
    const contrastTheme = useSelector(getContrastTheme);

    const dispatch = useDispatch();

    const currentSystemTheme = useSystemTheme();

    const isSystemTheme = systemTheme === ThemeActive.ON;
    const isContrastTheme = contrastTheme === ThemeActive.ON;
    const lightTheme = !isContrastTheme ? Theme.LIGHT : Theme.LIGHT_CONTRAST;

    const currentSystemThemeWithContrast = useMemo(
      () =>
        currentSystemTheme === Theme.DARK
          ? Theme.DARK_CONTRAST
          : Theme.LIGHT_CONTRAST,
      [currentSystemTheme]
    );

    useLayoutEffect(() => {
      if (isSystemTheme) {
        if (!isContrastTheme) {
          dispatch(setTheme(currentSystemTheme));
          return;
        }
        dispatch(setTheme(currentSystemThemeWithContrast));
        return;
      }

      const currentTheme = localStorage.getItem('theme') as Theme;

      if (!Object.values(Theme).includes(currentTheme)) {
        dispatch(setTheme(lightTheme));
      }
    }, [isSystemTheme, isContrastTheme, currentSystemTheme]);

    useLayoutEffect(() => {
      document.documentElement.dataset.theme = theme;
      localStorage.setItem('theme', theme || lightTheme);
    }, [theme]);

    useLayoutEffect(() => {
      localStorage.setItem('system-theme', systemTheme || ThemeActive.ON);
    }, [systemTheme]);

    useLayoutEffect(() => {
      localStorage.setItem('contrast-theme', contrastTheme || ThemeActive.OFF);
    }, [contrastTheme]);

    return <Component {...props} />;
  };
};

import React from 'react';

import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { TableContainer } from 'components/Table/components/TableContainer';
import { DeleteButton } from 'core/components/DeleteButton';
import { entityNames } from 'core/constants';

import { useRulesTableContainer } from '../../hooks/useRulesTableContainer';
import { RuleModal } from '../RuleModal';
import { RulesFullTable } from '../RulesFullTable';

import styles from './RulesTableContainer.module.scss';

export const RulesTableContainer = () => {
  const { methods, state } = useRulesTableContainer();

  const {
    handleDelete,
    toggleDeleteModal,
    toggleFilterRow,
    toggleIsOpenChangeModals,
    handleEditClick,
    handleChangePage,
    handleChangePageSize,
    handleSort,
    handleCreateClick,
    handleShowModalDelete,
    onRowClick,
  } = methods;

  const {
    pageNum,
    pageSize,
    sortRules,
    totalElements,
    rules,
    changeModals,
    showFilterRow,
    searchDisabled,
    showDeleteModal,
    currentRule,
    currentRuleId,
    loadingRules,
  } = state;

  const dropContent = (
    <PopoverContainer>
      <DeleteButton onClick={handleShowModalDelete} disabled={!currentRule} />
    </PopoverContainer>
  );

  return (
    <>
      <TableContainer
        classNameRoot={styles.rulesTableContainer}
        headerProps={{
          title: 'Правила',
          createHandler: handleCreateClick,
          createTitle: 'Создать',
          searchDisabled,
          toggleSearch: toggleFilterRow,
          isSearchOpen: showFilterRow,
          dropContent,
          loading: loadingRules,
        }}
        contentTable={
          <RulesFullTable
            rules={rules}
            handleSort={handleSort}
            sort={sortRules}
            handleClickRule={onRowClick}
            activeRowId={currentRuleId}
            showFilterRow={showFilterRow}
            handleClickRuleEdit={handleEditClick}
          />
        }
        countRecordsProps={{
          records: totalElements,
        }}
        paginationProps={{
          pageNum,
          pageSize,
          elementsCount: totalElements,
          handleChangePage,
          handleChangePageSize,
          disabled: loadingRules,
          entityName: entityNames.RULES,
        }}
        approveOrCancelProps={{
          onApprove: handleDelete,
          isModal: showDeleteModal,
          toggleModal: toggleDeleteModal,
          approveTitle: 'Удалить',
          approveBtnProps: {
            appearance: 'outline',
            className: styles.rules__iconDelete,
          },
          cancelTitle: 'Отмена',
          text: `Вы уверены, что хотите удалить правило ${currentRule?.title}?`,
        }}
      />
      <RuleModal
        isModal={changeModals.isOpenModal}
        isEditMode={changeModals.isEditMode}
        toggleIsModal={toggleIsOpenChangeModals}
      />
    </>
  );
};

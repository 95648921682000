import {
  Contract,
  Environment,
  EnvironmentSetting,
  EnvironmentsFilter,
  Organization,
  OrganizationType,
  System,
  SystemsFilterToRequest,
  TicketType,
  TicketTypeSettings,
  User,
  WorkGroup,
} from 'core/types';
import {
  ContractIdSystemIdArgs,
  SLAType,
  UpdateEnvironmentSettings,
} from 'features/Contract/types';
import { UsersFilterToRequest } from 'features/Users/types';
import { WorkGroupFilterToRequest } from 'features/WorkGroups/types';
import { ResponseWithMeta } from 'store/types';
import { Attachment, ParentType } from 'types/models/attachment';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import {
  AttachSystemAndSLAToContractType,
  UpdateContractNotificationsPayload,
  UpdateContractPermissionsPayload,
} from '../types';

import { endpoints } from './endpoints';

export const request = {
  fetchSystemsByContractId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: SystemsFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<System[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystemsByContractId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {
        ...filter,
      },
    }),
  fetchCurrentContract: (id: string) =>
    ApiClient.get<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCurrentContract(id),
      data: {},
    }),

  createContract: (body: Contract) =>
    ApiClient.post<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createContract(),
      data: body,
    }),
  updateContract: (data: Contract) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.createContract(),
      data,
    }),
  updateGroupsToContractToSystem: (
    contractId: string,
    systemId: string,
    slaId: string,
    data: string[]
  ) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.updateGroupsInSystemInContract(
        contractId,
        systemId,
        slaId
      ),
      data,
    }),
  deleteContract: (id: string) =>
    ApiClient.delete<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.deleteContract(id),
      data: {},
    }),
  fetchSupplementaryAgreements: (
    contractId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string
  ) =>
    ApiClient.post<ResponseWithMeta<Contract[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSupplementaryAgreements(contractId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: {},
    }),
  fetchContractAttachments: (contractId: string) =>
    ApiClient.get<Attachment[]>({
      baseURL: getEnv('REACT_APP_ATTACHMENT_URL'),
      url: endpoints.fetchAttachments(),
      params: {
        parent: ParentType.CONTRACT,
        id: contractId,
      },
    }),
  fetchUserByContractId: (
    id: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: UsersFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<User[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUsersByContractId(id),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchEnvironmentsBySystem: (
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: EnvironmentsFilter
  ) =>
    ApiClient.post<ResponseWithMeta<Environment[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getEnvironments(),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: filter,
    }),
  fetchEnvironmentSettingsBySystem: (data: ContractIdSystemIdArgs) =>
    ApiClient.get<EnvironmentSetting[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getEnvironmentSettings(data),
    }),
  fetchUpdateEnvironmentSettings: ({
    contractId,
    systemId,
    ...data
  }: UpdateEnvironmentSettings) => {
    return ApiClient.put<EnvironmentSetting>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getEnvironmentSettings({
        contractId,
        systemId,
      }),
      data,
    });
  },
  fetchGroupsByContractIdBySystemId: (
    contractId: string,
    systemId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getGroupsByContractIdBySystemId(contractId, systemId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchWorkGroupsByContractId: (
    contractId: string,
    pageNum = 0,
    pageSize?: number,
    sort?: string,
    filter?: WorkGroupFilterToRequest
  ) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getWorkGroupsByContractId(contractId),
      params: { pageNum, pageSize, sort: sort?.toUpperCase() },
      data: { ...filter },
    }),
  fetchSystemsForAddingToContract: (contractId: string, filter?: string) =>
    ApiClient.post<System[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystemsForAddingToContract(contractId),
      params: { title: filter },
    }),
  signContract: (contractId: string) =>
    ApiClient.get<string>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSignContractUrl(contractId),
    }),
  fetchSLAPatterns: (filter?: string) =>
    ApiClient.get<SLAType[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSLAPatternsUrl(filter),
    }),
  isExistPatternTitle: (title: string) =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getExistPatternTitleUrl(title),
    }),
  fetchPatternById: (id: string) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getPatternByIdUrl(id),
    }),
  createPattern: (sla: SLAType) =>
    ApiClient.post<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCreatePatternUrl(),
      data: sla,
    }),
  fetchSLAByContractIdBySystemId: (contractId: string, systemId: string) =>
    ApiClient.get<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSLAByContractIdBySystemIdUrl(),
      params: { contract_id: contractId, system_id: systemId },
    }),
  addSLAAndSystemToContract: (
    contractId: string,
    systemId: string,
    sla: SLAType
  ) =>
    ApiClient.put<AttachSystemAndSLAToContractType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getAttachSLAAndSystemToContractUrl(contractId),
      data: { systemId, sla },
    }),
  updateContractSLA: (sla: SLAType, id: string) =>
    ApiClient.put<SLAType>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUpdateSLAUrl(id),
      data: sla,
    }),
  updateNotificationsExpireContract: ({
    contractId,
    isEnable,
    notifyBefore,
  }: UpdateContractNotificationsPayload) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getNotificationsExpireContract(contractId),
      params: { isEnable, notifyBefore },
    }),
  updateContractPermission: ({
    contractId,
    isEnable,
  }: UpdateContractPermissionsPayload) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getContractPermission(contractId),
      params: { isEnable },
    }),
  systemTransfer: (data: string[], contractId: string) =>
    ApiClient.put<Contract>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystemTransfer(contractId),
      data,
    }),
  fetchOrganizations: (organizationTypes: OrganizationType[]) =>
    ApiClient.post<ResponseWithMeta<Organization[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getOrganizations(),
      params: {
        pageNum: 0,
        pageSize: 500,
        sort: 'date_create_DESC'.toUpperCase(),
      },
      data: { organizationTypes },
    }),
  fetchSettingsTicketType: (data: ContractIdSystemIdArgs) =>
    ApiClient.get<TicketTypeSettings[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSettingsTicketType(data),
    }),
  fetchTicketTypes: (systemId: string) =>
    ApiClient.get<TicketType[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getTicketTypes(systemId),
    }),
};

import cn from 'clsx';
import Highcharts, { PointOptionsObject, SeriesPieOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useRef, useState } from 'react';

import { Typography, TypographyVariants } from '../Typography';

import styles from './PieChart.module.scss';
import { getOptions } from './utils';

interface Props {
  className?: string;
  data?: SeriesPieOptions['data'];
  isPie?: boolean;
}

export const PieChart: React.FC<Props> = ({
  data,
  isPie = true,
  className,
}) => {
  const [currentOptions, setCurrentOptions] = useState<Highcharts.Options>(
    getOptions(data, isPie)
  );
  const [legendItems, setLegendItems] = useState<PointOptionsObject[]>([]);
  const [sumValues, setSumValues] = useState(0);

  useEffect(() => {
    setCurrentOptions(getOptions(data, isPie));
  }, [data, isPie]);

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (chartComponentRef.current) {
      const arrElems: PointOptionsObject[] = [];
      chartComponentRef.current.chart?.series[0]?.data?.forEach((item) => {
        arrElems.push(item.options);
      });
      setLegendItems(arrElems);
      setSumValues(
        arrElems.reduce((prev, current) => prev + +(current?.y || 0), 0)
      );
    }
  }, [chartComponentRef.current, currentOptions, isPie]);

  const legendComponent = (
    <div className={styles.pieChartLegend}>
      {legendItems.map((item) => {
        return (
          <div className={styles.pieChartLegend__item} key={item.name}>
            <div
              className={styles.pieChartLegend__circle}
              style={{
                background: item?.color?.toString(),
              }}
            />
            <div className={styles.pieChartLegend__itemInfo}>
              <span className={styles.pieChartLegend__itemName}>
                {item.name}
              </span>
              <br />
              {!!item.y && (
                <Typography
                  variant={TypographyVariants.h3}
                  className={styles.pieChartLegend__itemValue}
                >
                  {Math.round((+(item?.y || 0) / sumValues) * 100) || 0}%
                </Typography>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <div className={cn(styles.highchartsReact__wrapper, className)}>
        <HighchartsReact
          options={currentOptions}
          ref={chartComponentRef}
          highcharts={Highcharts}
          immutable
        />
      </div>
      {legendComponent}
    </>
  );
};

import { Organization, System } from 'core/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const requests = {
  fetchOrganizations: () =>
    ApiClient.get<Organization[]>({
      baseURL: getEnv('REACT_APP_TICKET_REPORT_URL'),
      url: endpoints.getOrganizations(),
    }),
  fetchSystems: (id: string) =>
    ApiClient.get<System[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getSystems(id),
    }),
};

import { createBrowserHistory } from 'history';
import React from 'react';
import { Router } from 'react-router-dom';
import { compose } from 'redux';

import { withRedirectPath } from 'core/hocs/withRedirectPath';
import { withScrollWindow } from 'core/hocs/withScrollWindow';
import { AlertsLayout } from 'core/layouts/AlertsLayout';
import { SidebarLayout } from 'core/layouts/SidebarLayout';
import { NotificationsLayout } from 'features/Notifications/layouts/NotificationsLayout';
import { SwitchElements } from 'routes/SwitchElements';

const history = createBrowserHistory();

const withRouterHistoryData = (Component: React.FC): React.FC =>
  compose<React.FC>(withRedirectPath, withScrollWindow)(Component);

const SwitchComponent = withRouterHistoryData(SwitchElements);

export const RouterHistory: React.FC = () => (
  <Router history={history}>
    <SidebarLayout>
      <NotificationsLayout>
        <AlertsLayout>
          <SwitchComponent />
        </AlertsLayout>
      </NotificationsLayout>
    </SidebarLayout>
  </Router>
);

import { createSelector } from 'reselect';

import { SelectOption } from 'components/Select/types';
import { Organization, OrganizationType } from 'core/types';
import { RootState } from 'store/rootReducer';

export const getArticlesArticleViewing = (state: RootState) =>
  state.articleViewing.articlesArticleViewing || [];

export const getCurrentArticleArticleViewing = (state: RootState) =>
  state.articleViewing?.currentArticle;
export const getCurrentArticleLoadingArticleViewing = (state: RootState) =>
  state.articleViewing.currentArticleLoading || false;

export const getCategoriesArticlesArticleViewing = (state: RootState) =>
  state.articleViewing.categoriesArticleViewing;
export const getLoadingCategoriesArticleViewing = (state: RootState) =>
  state.articleViewing.categoriesLoadingArticleViewing || false;

export const getLoadingArticlesArticleViewing = (state: RootState) =>
  state.articleViewing.loadingArticlesArticleViewing || false;
export const getSelectedOrganizationArticlesArticleViewing = (
  state: RootState
) => state.articleViewing.organizationSelected;

export const getFilterArticlesArticleViewing = (state: RootState) =>
  state.articleViewing.articlesFilterArticleViewing || {};
export const getPaginationArticlesArticleViewing = (state: RootState) =>
  state.articleViewing.articlesPaginationArticleViewing || {};

export const getOrganizationsArticleViewing = (state: RootState) =>
  state.articleViewing.organizationsArticleViewing || [];

export const getSearchValueArticlesArticleViewing = (state: RootState) =>
  state.articleViewing.searchValue;
export const getOrganizationsArticleViewingSelectList = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizationsArticleViewing], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization: Organization): SelectOption => ({
        title: organization.title,
        value: organization.id || '',
        meta: `${
          organization.type === OrganizationType.CUSTOMER
            ? 'Клиентская'
            : 'Админская'
        }`,
      })
    );
  }
  return [];
});

import cn from 'clsx';
import React, { MouseEventHandler } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { NotificationCircle } from 'components/NotificationCircle';

import { Tab } from '../../types';

import styles from './TabsItem.module.scss';

const fontSizeStyles = {
  14: styles.tabsItem_14,
  16: styles.tabsItem_16,
};

interface Props {
  tab: Tab;
  className?: string;
  disabled?: boolean;
  fontSize: 14 | 16;
}

export const TabsItem: React.FC<Props> = React.memo(
  ({ tab, className: classNameTab, disabled, fontSize }) => {
    const { to, exact, className, title, content, hasUnchecked } = tab;
    const { pathname } = useLocation();

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
      if (disabled) {
        e.preventDefault();
      }
    };

    return (
      <NavLink
        to={to || ''}
        className={cn(
          styles.tabsItem,
          fontSizeStyles[fontSize],
          {
            [styles.tabsItem_withContent]: content,
            [styles.tabsItem_disabled]: disabled,
          },
          className,
          classNameTab
        )}
        activeClassName={styles.tabsItem_active}
        tabIndex={to === pathname || disabled ? -1 : 0}
        exact={exact}
        onClick={handleClick}
      >
        {title}
        {content}
        {hasUnchecked && (
          <NotificationCircle className={styles.tabsItem_unchecked} />
        )}
      </NavLink>
    );
  }
);

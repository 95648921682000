import React from 'react';

import { CalendarIcon, DocumentIcon } from 'assets/icons';
import { ConditionBlock } from 'components/ConditionBlock';
import { Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip';
import { Typography, TypographyVariants } from 'components/Typography';
import { StorageCustomField } from 'core/types';
import {
  getColumns,
  getCustomFieldsForStorageTable,
} from 'features/Storage/utils';
import { getFormatDateWithYear } from 'utils';

import { useStorageId } from '../../hooks/useStorageId';
import { DownloadStorageFileRequest, StorageFileCard } from '../../types';

import styles from './StorageTableContent.module.scss';

type TableDataProps = {
  storageId?: string;
} & Pick<Props, 'storageFiles' | 'downloadFile'>;

const getTableData = ({
  storageFiles,
  storageId,
  downloadFile,
}: TableDataProps) =>
  storageFiles?.map(
    ({ id, mainFile, additionalFiles, fileCardCustomFields }) => {
      const handleDownloadFiles = () => {
        downloadFile({ storageId, fileId: mainFile.id });

        additionalFiles?.forEach((file) => {
          downloadFile({ storageId, fileId: file.id });
        });
      };

      return {
        id,
        title: (
          <button
            className={styles.storageFullTable__block}
            onClick={handleDownloadFiles}
          >
            <DocumentIcon className={styles.storageFullTable__icon} />
            <Typography
              variant={TypographyVariants.b3}
              className={styles.storageFullTable__textDecoration}
            >
              {mainFile.fileName}
            </Typography>
          </button>
        ),
        size: <ConditionBlock text={mainFile.size} />,
        date: (
          <div className={styles.storageFullTable__block}>
            <CalendarIcon className={styles.storageFullTable__icon} />
            <Typography
              variant={TypographyVariants.b3}
              className={styles.storageFullTable__text}
            >
              {getFormatDateWithYear(mainFile?.updateAt ?? '')}
            </Typography>
          </div>
        ),
        user: (
          <div className={styles.storageFullTable__block}>
            <div className={styles.storageFullTable__user}>
              <Typography
                variant={TypographyVariants.other}
                className={styles.storageFullTable__userText}
              >
                ИС
              </Typography>
            </div>
            <Typography
              variant={TypographyVariants.b3}
              className={styles.storageFullTable__text}
            >
              {mainFile.updatedBy?.value}
            </Typography>
          </div>
        ),
        additionalFiles: !!additionalFiles?.length && (
          <div className={styles.storageFullTable__block}>
            <ConditionBlock text={additionalFiles[0].fileName} />
            <div
              className={styles.storageFullTable__additional}
              data-tip
              data-for={additionalFiles[0].id}
            >
              {additionalFiles.length >= 2 && (
                <Typography
                  variant={TypographyVariants.h6}
                  className={styles.storageFullTable__additionalText}
                >
                  +{additionalFiles ? additionalFiles?.length - 1 : 0}
                </Typography>
              )}
            </div>
            <Tooltip id={additionalFiles[0].id}>
              {additionalFiles?.slice(1).map(({ fileName }) => (
                <Typography>{fileName}</Typography>
              ))}
            </Tooltip>
          </div>
        ),
        description: mainFile.description,
        ...getCustomFieldsForStorageTable(fileCardCustomFields),
      };
    }
  ) ?? [];

interface Props {
  storageFiles?: StorageFileCard[];
  downloadFile: (data: DownloadStorageFileRequest) => void;
  activeFileCardId?: string;
  handleClickFileCard: (fileCardId: string) => void;
  customFields?: StorageCustomField[];
}

export const StorageTableContent: React.FC<Props> = ({
  storageFiles,
  downloadFile,
  handleClickFileCard,
  activeFileCardId,
  customFields,
}) => {
  const storageId = useStorageId();

  const data = getTableData({
    storageFiles,
    downloadFile,
    storageId,
  });

  const columns = getColumns(customFields);

  return (
    <Table
      data={data}
      columns={columns}
      onRowClick={handleClickFileCard}
      activeRowId={activeFileCardId}
      valueFieldName="id"
    />
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { resetTicketsState } from 'features/Tickets/ducks/actions';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { RouterHref } from 'routes/routerHref';

import {
  fetchNotificationsProfileRequest,
  resetNotificationsProfileState,
} from '../../ducks/actions';
import {
  getNotificationsProfile,
  getPropsNotificationsProfile,
} from '../../ducks/selectors';
import { ProfileLayout } from '../../layouts/ProfileLayout';
import { ProfileNotificationsLayout } from '../../layouts/ProfileNotificationsLayout';
import { NotificationsList } from '../NotificationsList';

const ProfileNotifications = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { push } = useHistory();

  const notifications = useSelector(getNotificationsProfile);
  const {
    totalElements,
    pageNum = 0,
    pageSize,
    loading,
  } = useSelector(getPropsNotificationsProfile);

  const { totalPage } = useInfiniteScroll({
    pageNum,
    pageSize,
    totalElements,
  });

  const onLoadMore = (page: number) => {
    dispatch(
      fetchNotificationsProfileRequest({ updateType: 'join', pageNum: page })
    );
  };

  useEffect(() => {
    if (pathname === RouterHref.ProfileNotifications) {
      push(`${RouterHref.ProfileNotifications}/all`);
    }
  }, [pathname]);

  useEffect(() => {
    return () => {
      dispatch(resetNotificationsProfileState());
      dispatch(resetTicketsState());
    };
  }, []);

  return (
    <ProfileLayout>
      <ProfileNotificationsLayout>
        <Switch>
          <Route
            path="/profile/notifications/:tabType"
            render={() => (
              <NotificationsList
                notifications={notifications}
                onLoadMore={onLoadMore}
                hasMore={totalPage > pageNum + 1}
                loading={loading}
                totalPages={totalPage}
              />
            )}
          />
        </Switch>
      </ProfileNotificationsLayout>
    </ProfileLayout>
  );
};

export default ProfileNotifications;

import React from 'react';

import { AdminsLayout } from 'core/layouts/AdminsLayout';
import { Responsibilities } from 'features/Responsibilities';

const AdminPowers = () => {
  return (
    <AdminsLayout>
      <Responsibilities />
    </AdminsLayout>
  );
};

export default AdminPowers;

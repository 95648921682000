import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { TextArea } from 'components/TextArea';
import { Create } from 'core/modals/Create';
import { closeTicketRequest } from 'features/Ticket/ducks/ticket/actions';
import { CloseTicketRequest } from 'features/Ticket/types';

import styles from './TicketCloseModal.module.scss';

export interface Props {
  id?: string;
  isModal: boolean;
  toggleModal(): void;
}

export const TicketCloseModal: React.FC<Props> = ({
  id,
  isModal,
  toggleModal,
}) => {
  const dispatch = useDispatch();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CloseTicketRequest>({
    mode: 'onChange',
  });

  const handleSubmitForm = handleSubmit(({ reasonClosing }) => {
    dispatch(closeTicketRequest({ id, reasonClosing }));
    toggleModal();
  });

  const reasonClosingError = register('reasonClosing', {
    maxLength: {
      value: 500,
      message: 'Причина не может быть длиннее 500 символов.',
    },
  });

  return (
    <Create
      title="Введите причину закрытия тикета"
      createTitle="Закрыть тикет"
      subModalText="закрытие тикета"
      withCloseIconButton={false}
      disabledSubmit={!isValid || !watch('reasonClosing')}
      isModal={isModal}
      toggleModal={toggleModal}
      onSubmit={handleSubmitForm}
    >
      <TextArea
        className={styles.ticketCloseModal__text}
        placeholder="Причина"
        error={!!errors.reasonClosing}
        errorMessage={errors.reasonClosing?.message}
        {...reasonClosingError}
      />
    </Create>
  );
};

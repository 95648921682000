import { Article } from 'features/KnowledgeBase/types';
import { Ticket } from 'features/Ticket/types';
import { ResponseWithMeta } from 'store/types';
import { Attachment } from 'types/models/attachment';

import { SearchData } from '../types';

export enum SimilarSolutions {
  FETCH_SIMILAR_TICKETS_REQUEST = 'SIMILAR_SOLUTIONS/FETCH_SIMILAR_TICKETS_REQUEST',
  FETCH_SIMILAR_TICKETS_SUCCESS = 'SIMILAR_SOLUTIONS/FETCH_SIMILAR_TICKETS_SUCCESS',
  FETCH_SIMILAR_ARTICLES_REQUEST = 'SIMILAR_SOLUTIONS/FETCH_SIMILAR_ARTICLES_REQUEST',
  FETCH_SIMILAR_ARTICLES_SUCCESS = 'SIMILAR_SOLUTIONS/FETCH_SIMILAR_ARTICLES_SUCCESS',
  SIMILAR_LOADING_SHOW = 'SIMILAR_SOLUTIONS/SIMILAR_LOADING_SHOW',
  SIMILAR_LOADING_HIDE = 'SIMILAR_SOLUTIONS/SIMILAR_LOADING_HIDE',
  SIMILAR_DETAIL_LOADING_SHOW = 'SIMILAR_SOLUTIONS/SIMILAR_DETAIL_LOADING_SHOW',
  SIMILAR_DETAIL_LOADING_HIDE = 'SIMILAR_SOLUTIONS/SIMILAR_DETAIL_LOADING_HIDE',
  RESET_SIMILAR_SOLUTIONS_STATE = 'SIMILAR_SOLUTIONS/RESET_SIMILAR_SOLUTIONS_STATE',
  FETCH_SIMILAR_TICKET_REQUEST = 'SIMILAR_SOLUTIONS/FETCH_SIMILAR_TICKET_REQUEST',
  FETCH_SIMILAR_TICKET_SUCCESS = 'SIMILAR_SOLUTIONS/FETCH_SIMILAR_TICKET_SUCCESS',
  FETCH_SIMILAR_ARTICLE_REQUEST = 'SIMILAR_SOLUTIONS/FETCH_SIMILAR_ARTICLE_REQUEST',
  FETCH_SIMILAR_ARTICLE_SUCCESS = 'SIMILAR_SOLUTIONS/FETCH_SIMILAR_ARTICLE_SUCCESS',
  FETCH_SIMILAR_TICKET_ATTACHMENTS_REQUEST = 'SIMILAR_SOLUTIONS/FETCH_SIMILAR_TICKET_ATTACHMENTS_REQUEST',
  FETCH_SIMILAR_TICKET_ATTACHMENTS_SUCCESS = 'SIMILAR_SOLUTIONS/FETCH_SIMILAR_TICKET_ATTACHMENTS_SUCCESS',
}

export interface ShowLoadingAction {
  type: SimilarSolutions.SIMILAR_LOADING_SHOW;
}

export interface HideLoadingAction {
  type: SimilarSolutions.SIMILAR_LOADING_HIDE;
}

export interface ShowSimilarDetailLoadingAction {
  type: SimilarSolutions.SIMILAR_DETAIL_LOADING_SHOW;
}

export interface HideSimilarDetailLoadingAction {
  type: SimilarSolutions.SIMILAR_DETAIL_LOADING_HIDE;
}

export interface FetchTicketsRequestAction {
  type: SimilarSolutions.FETCH_SIMILAR_TICKETS_REQUEST;
  payload: SearchData;
}

export interface FetchTicketsSuccessAction {
  type: SimilarSolutions.FETCH_SIMILAR_TICKETS_SUCCESS;
  payload: ResponseWithMeta<Ticket[]>;
}

export interface FetchArticlesRequestAction {
  type: SimilarSolutions.FETCH_SIMILAR_ARTICLES_REQUEST;
  payload: SearchData;
}

export interface FetchArticlesSuccessAction {
  type: SimilarSolutions.FETCH_SIMILAR_ARTICLES_SUCCESS;
  payload: ResponseWithMeta<Article[]>;
}

export interface ResetSimilarSolutionsStateAction {
  type: SimilarSolutions.RESET_SIMILAR_SOLUTIONS_STATE;
}

export interface FetchSimilarTicketRequestAction {
  type: SimilarSolutions.FETCH_SIMILAR_TICKET_REQUEST;
  payload: string;
}

export interface FetchSimilarTicketSuccessAction {
  type: SimilarSolutions.FETCH_SIMILAR_TICKET_SUCCESS;
  payload: Ticket;
}

export interface FetchSimilarArticleRequestAction {
  type: SimilarSolutions.FETCH_SIMILAR_ARTICLE_REQUEST;
  payload: string;
}

export interface FetchSimilarArticleSuccessAction {
  type: SimilarSolutions.FETCH_SIMILAR_ARTICLE_SUCCESS;
  payload: Article;
}

export interface FetchSimilarTicketAttachmentsRequestAction {
  type: SimilarSolutions.FETCH_SIMILAR_TICKET_ATTACHMENTS_REQUEST;
  payload: string;
}

export interface FetchSimilarTicketAttachmentsSuccessAction {
  type: SimilarSolutions.FETCH_SIMILAR_TICKET_ATTACHMENTS_SUCCESS;
  payload: Attachment[];
}

export type SimilarSolutionsActionsTypes =
  | FetchTicketsSuccessAction
  | FetchArticlesSuccessAction
  | FetchSimilarTicketSuccessAction
  | FetchSimilarArticleSuccessAction
  | FetchSimilarTicketAttachmentsSuccessAction;

import React from 'react';
import { useSelector } from 'react-redux';

import { KpiActiveTickets } from '../../components/KpiActiveTickets';
import { KpiAllTickets } from '../../components/KpiAllTickets';
import { KpiSlaLineChart } from '../../components/KpiSlaLineChart';
import { KpiTicketsPieChart } from '../../components/KpiTicketsPieChart';
import { getKpiData } from '../../ducks/Kpi/selectors';

import styles from './DesktopKpi.module.scss';

export const DesktopKpi = () => {
  const kpiData = useSelector(getKpiData);

  return (
    <div className={styles.kpi}>
      <div className={styles.kpi__container}>
        <KpiActiveTickets kpiData={kpiData} />
        <KpiAllTickets kpiData={kpiData} />
      </div>
      <div className={styles.kpi__container}>
        <KpiSlaLineChart kpiData={kpiData} />
        <KpiTicketsPieChart kpiData={kpiData} />
      </div>
    </div>
  );
};

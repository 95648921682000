import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { Storage } from '../../types';
import { Storage as StorageItem } from '../Storage';

interface Props {
  storages?: Storage[];
  handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedStorages: string[];
  hasMore?: boolean;
  handleLoadMore: (page: number) => void;
}

export const StoragesList: React.FC<Props> = ({
  storages,
  selectedStorages,
  handleChangeCheckbox,
  handleLoadMore,
  hasMore,
}) => (
  <InfiniteScroll
    loadMore={handleLoadMore}
    hasMore={hasMore}
    useWindow={false}
    threshold={10}
  >
    {storages?.map((storage) => (
      <StorageItem
        {...storage}
        key={storage.id}
        selectedStorages={selectedStorages}
        handleChangeCheckbox={handleChangeCheckbox}
      />
    ))}
  </InfiniteScroll>
);

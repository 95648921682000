import React from 'react';

import { MainLayout } from 'core/layouts/MainLayout';
import { Header } from 'features/Desktop/components/Header';

import { DesktopKpi as DesktopKpiContent } from '../../views/DesktopKpi';

const DesktopKpi = () => {
  return (
    <MainLayout customHeader={<Header />}>
      <DesktopKpiContent />
    </MainLayout>
  );
};

export default DesktopKpi;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'components/Table';
import { Responsibility } from 'core/types';
import { fetchFunction } from 'features/Responsibilities/hooks/useResponsibilitiesTableContainer';

import { RESPONSIBILITIES_FIELDS } from '../../constants';
import {
  setCurrentResponsibilitiesPage,
  setResponsibilitiesFilter,
} from '../../ducks/actions';
import { getLoading } from '../../ducks/selectors';
import { ResponsibilityFilter, TableResponsibilitiesTypes } from '../../types';
import { ResponsibilitiesFilter } from '../ResponsibilitiesFilter';

import { getDataTable } from './utils';

interface Props {
  responsibilities?: Responsibility[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  tableType: TableResponsibilitiesTypes;
}

export const ResponsibilitiesShortTable: React.FC<Props> = ({
  responsibilities,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
  tableType,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);

  const data = getDataTable(responsibilities);

  const handleFilter = useCallback(
    (filter: ResponsibilityFilter) => {
      dispatch(setResponsibilitiesFilter(filter));
      dispatch(setCurrentResponsibilitiesPage(0));
      dispatch(fetchFunction[tableType]());
    },
    [dispatch]
  );

  return (
    <Table
      data={data}
      columns={RESPONSIBILITIES_FIELDS}
      sort={sort}
      onRowClick={handleRowClick}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={
        showFilterRow && (
          <ResponsibilitiesFilter
            tableType={TableResponsibilitiesTypes.ADD_MODAL}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
    />
  );
};

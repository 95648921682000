import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import { LazyLoader } from 'core/components/LazyLoader';
import { getAccessedRoutes } from 'features/Auth/ducks/selectors';

import { routerConfig } from './config';
import { getRouteList } from './utils';

export const SwitchElements = () => {
  const accessedRoutes = useSelector(getAccessedRoutes);

  const switchesEls = getRouteList(routerConfig, accessedRoutes);

  return (
    <Suspense fallback={<LazyLoader />}>
      <Switch>{switchesEls}</Switch>
    </Suspense>
  );
};

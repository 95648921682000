import cn from 'clsx';
import React, { FC } from 'react';

import { CloseIcon } from 'assets/icons';
import { convertDateToString } from 'components/DatePicker';

import { RangeDateType } from '../../types';

import styles from './Range.module.scss';

interface Props {
  className?: string;
  pickedDate?: RangeDateType;
  showTime: boolean;
  reset?: () => void;
}

export const Range: FC<Props> = ({
  className,
  pickedDate,
  showTime,
  reset,
}) => {
  const dateFrom = pickedDate?.from ? (
    <div>{convertDateToString(pickedDate.from, showTime)}</div>
  ) : (
    <div>{` ...`}</div>
  );

  const dateTo = pickedDate?.to ? (
    <div>{convertDateToString(pickedDate.to, showTime)}</div>
  ) : (
    <div>{` ...`}</div>
  );

  if (!pickedDate?.from?.getTime() && !pickedDate?.to?.getTime())
    return <div className={styles.range__text}>Период не выбран</div>;

  return (
    <div className={cn(styles.range, className)}>
      <div className={styles.range__from}>
        <div className={styles.range__typography}>С &nbsp;</div>
        {dateFrom}
      </div>

      <div className={styles.range__to}>
        <div className={styles.range__typography}>по &nbsp;</div>
        {dateTo}
      </div>

      <button
        type="button"
        className={styles.range__btnClear}
        onClick={(event) => {
          event.stopPropagation();
          if (reset) {
            reset();
          }
        }}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

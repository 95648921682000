import React, { useState } from 'react';

import { Size } from 'components/types';
import { AttachmentDeleteType } from 'features/Ticket/types';
import { AttachmentsFiles } from 'types/models/attachment';

import { PreviewImageModal } from '../PreviewImageModal';
import { TicketAttachments } from '../TicketInfo/components/TicketAttachments';
import { getAttachmentFilesComment } from '../TicketInfo/utils';

interface Props {
  files?: AttachmentsFiles[];
}

export const TicketNoteAttachments: React.FC<Props> = ({ files }) => {
  const { imageFiles, documentFiles } = getAttachmentFilesComment(files);
  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  const toggleIsImageModal = () => {
    setIsImageModal((prevState) => !prevState);
  };

  const handleInitialImageIndex = (index: number) => {
    setInitialImageIndex(index);
  };

  const imageModal = isImageModal && (
    <PreviewImageModal
      toggleModal={toggleIsImageModal}
      initialImageIndex={initialImageIndex}
      imageFiles={imageFiles}
    />
  );

  return (
    <>
      <TicketAttachments
        toggleIsModal={toggleIsImageModal}
        handleInitialImageIndex={handleInitialImageIndex}
        imageFiles={imageFiles}
        documentFiles={documentFiles}
        size={Size.m}
        attachmentDeleteType={AttachmentDeleteType.NOTES}
      />
      {imageModal}
    </>
  );
};

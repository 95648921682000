import { combineReducers, Reducer } from 'redux';

import { core } from 'core/ducks';
import { accesses } from 'features/Accesses/ducks';
import { actions } from 'features/Actions/ducks';
import { currentUser } from 'features/Auth/ducks';
import { contractDetail } from 'features/Contract/ducks';
import { contracts } from 'features/Contracts/ducks';
import { createTicket } from 'features/CreateTicket/ducks';
import { customFields } from 'features/CustomFields/ducks';
import { desktopCurrentState } from 'features/Desktop/ducks/CurrentState';
import { desktop } from 'features/Desktop/ducks/Desktop';
import { desktopEmployees } from 'features/Desktop/ducks/Employees';
import { desktopHistory } from 'features/Desktop/ducks/History';
import { desktopKpi } from 'features/Desktop/ducks/Kpi';
import { jira } from 'features/JiraIntegrations/ducks';
import { articleViewing } from 'features/KnowledgeBase/views/KBArticle/ducks';
import { articleCreate } from 'features/KnowledgeBase/views/KBCreateArticle/ducks';
import { articlesMain } from 'features/KnowledgeBase/views/KBMain/ducks';
import { articlesTable } from 'features/KnowledgeBase/views/KBTable/ducks';
import { notifications } from 'features/Notifications/ducks';
import { organizations } from 'features/Organizations/ducks';
import { notificationsProfile } from 'features/Profile/ducks';
import { responsibilities } from 'features/Responsibilities/ducks';
import { roles } from 'features/Roles/ducks';
import { rules } from 'features/Rules/ducks';
import { similarSolutions } from 'features/SimilarSolutions/ducks';
import { statusesBuilder } from 'features/StatusesBuilder/ducks';
import { storage } from 'features/Storage/ducks';
import { storages } from 'features/Storages/ducks';
import { supplementaryAgreementDetail } from 'features/SupplementaryAgreement/ducks';
import { system } from 'features/System/ducks';
import { systems } from 'features/Systems/ducks';
import { assessmentInteraction } from 'features/Ticket/ducks/assessmentInteraction';
import { attachments } from 'features/Ticket/ducks/attachments';
import { notes } from 'features/Ticket/ducks/notes';
import { tags } from 'features/Ticket/ducks/tags';
import { ticket } from 'features/Ticket/ducks/ticket';
import { tickets } from 'features/Tickets/ducks';
import { ticketsExport } from 'features/TicketsExport/ducks';
import { triggers } from 'features/Triggers/ducks';
import { userAssistance } from 'features/UserAssistance/ducks';
import { users } from 'features/Users/ducks';
import { workGroups } from 'features/WorkGroups/ducks';

import { Root } from './types';

const appReducer = combineReducers({
  core,
  tickets,
  ticket,
  users,
  attachments,
  currentUser,
  systems,
  system,
  createTicket,
  organizations,
  contracts,
  workGroups,
  responsibilities,
  roles,
  accesses,
  actions,
  notifications,
  notificationsProfile,
  tags,
  notes,
  ticketsExport,
  jira,
  customFields,
  articleCreate,
  articlesTable,
  articlesMain,
  articleViewing,
  similarSolutions,
  assessmentInteraction,
  contractDetail,
  supplementaryAgreementDetail,
  statusesBuilder,
  desktop,
  desktopKpi,
  desktopCurrentState,
  desktopHistory,
  desktopEmployees,
  userAssistance,
  rules,
  triggers,
  storages,
  storage,
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === Root.RESET_STORE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

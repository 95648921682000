import React from 'react';

import { AdminsLayout } from 'core/layouts/AdminsLayout';
import { Roles } from 'features/Roles';

const AdminRoles = () => {
  return (
    <AdminsLayout>
      <Roles />
    </AdminsLayout>
  );
};

export default AdminRoles;

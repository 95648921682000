import React from 'react';

import { TicketNavIcon } from 'assets/icons';
import { PopoverButton } from 'components/Popover/components/PopoverButton';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { PopoverTitle } from 'components/Popover/components/PopoverTitle';

interface Props {
  togglePopover(): void;
  toggleIsTicketsIds(): void;
  isTicketsIds: boolean;
}

export const TicketCommentsDropContent: React.FC<Props> = ({
  togglePopover,
  toggleIsTicketsIds,
  isTicketsIds,
}) => {
  const addTickets = () => {
    if (!isTicketsIds) {
      toggleIsTicketsIds();
      togglePopover();
    }
  };

  return (
    <PopoverContainer>
      <PopoverTitle title="Дополнительно" />
      <PopoverButton
        onClick={addTickets}
        icon={<TicketNavIcon style={{ color: 'var(--blue120)' }} />}
      >
        Указать тикет
      </PopoverButton>
    </PopoverContainer>
  );
};

import React from 'react';

import { ProfileSettings } from '../../components/ProfileSettings';
import { ProfileLayout } from '../../layouts/ProfileLayout';

const ProfileSettingsPage = () => (
  <ProfileLayout>
    <ProfileSettings />
  </ProfileLayout>
);

export default ProfileSettingsPage;

import cn from 'clsx';
import React from 'react';

import styles from './TableBody.module.scss';

interface Props {
  className?: string;
}

export const TableBody: React.FC<Props> = ({ children, className }) => {
  return <div className={cn(styles.tableBody, className)}>{children}</div>;
};

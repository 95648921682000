import React from 'react';

import { KBLayout } from '../../components/KBLayout';
import { KBTable } from '../../views/KBTable';

import styles from './KBTablePage.module.scss';

const KBTablePage = () => {
  return (
    <KBLayout className={styles.KBTablePage}>
      <KBTable />
    </KBLayout>
  );
};

export default KBTablePage;

import { CurrentUser, CurrentUserOrganization } from 'features/Auth/types';

import {
  ChangeUserPasswordAction,
  CurrentUserTypes,
  FetchPermissionsRequestAction,
  FetchPermissionsSuccessAction,
  FetchPermissionsSuccessActionPayload,
  SetAuthorizedAction,
  SetCurrentUserAction,
  SetUserOrganizationAction,
} from './types';

export const setCurrentUser = (user: CurrentUser): SetCurrentUserAction => {
  return {
    type: CurrentUserTypes.SET_CURRENT_USER,
    payload: user,
  };
};

export const setAuthorized = (payload: boolean): SetAuthorizedAction => {
  return {
    type: CurrentUserTypes.SET_AUTHORIZED,
    payload,
  };
};

export const fetchPermissionsRequest = (): FetchPermissionsRequestAction => {
  return {
    type: CurrentUserTypes.FETCH_USER_PERMISSIONS_REQUEST,
  };
};

export const fetchPermissionsSuccess = (
  payload: FetchPermissionsSuccessActionPayload
): FetchPermissionsSuccessAction => {
  return {
    type: CurrentUserTypes.FETCH_USER_PERMISSIONS_SUCCESS,
    payload,
  };
};

export const changePassword = (
  newPassword: string
): ChangeUserPasswordAction => {
  return {
    type: CurrentUserTypes.CHANGE_PASSWORD,
    payload: newPassword,
  };
};

export const setUserOrganization = (
  payload: CurrentUserOrganization
): SetUserOrganizationAction => {
  return {
    type: CurrentUserTypes.SET_USER_ORGANIZATION,
    payload,
  };
};

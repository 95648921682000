import React from 'react';
import { useSelector } from 'react-redux';

import { CalendarIcon, CheckCircleIcon, TextSizeIcon } from 'assets/icons';
import { NotificationLink } from 'components/NotificationLink';
import { Table } from 'components/Table';
import { CustomFieldType } from 'components/types';
import { CustomField, OrganizationType } from 'core/types';

import {
  CUSTOM_FIELD_ACCESS_TABLE,
  TABLE_HEADER_CUSTOM_FIELDS,
} from '../../constants';
import { getLoading } from '../../ducks/selectors';
import { CustomFieldsFilter } from '../CustomFieldsFilter';

import styles from './CustomFieldsFullTable.module.scss';

export const getPropsCustomFieldType = (type: CustomFieldType) => {
  const components = {
    [CustomFieldType.FIELD_DATE]: () => ({
      text: 'DatePicker',
      icon: <CalendarIcon className={styles.utils__icon} />,
    }),
    [CustomFieldType.FIELD_FLAG]: () => ({
      text: 'Flag',
      icon: <CheckCircleIcon className={styles.utils__icon} />,
    }),
    [CustomFieldType.FIELD_TEXT]: () => ({
      text: 'Text',
      icon: <TextSizeIcon className={styles.utils__icon} />,
    }),
  };
  return components[type]();
};

const getDataTable = (
  customFields?: CustomField[],
  customFieldClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
) =>
  customFields?.map((customField) => {
    const { title, customFieldType, id, organization, system, accessType } =
      customField;
    return {
      id,
      title: (
        <button onClick={customFieldClick} className={styles.utils__title}>
          {title}
        </button>
      ),
      type: customFieldType
        ? getPropsCustomFieldType(customFieldType)
        : undefined,
      accessType: accessType
        ? {
            text: CUSTOM_FIELD_ACCESS_TABLE[accessType],
            className:
              accessType === OrganizationType.CUSTOMER
                ? styles.utils_customer
                : styles.utils_service,
          }
        : '-',
      organization: (
        <NotificationLink
          to={`/admin/organizations/${organization?.id}`}
          title={organization?.title}
        />
      ),
      system: { text: system?.title },
    };
  }) || [];

interface Props {
  customFields?: CustomField[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleClickCustomField?: (value: string) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
  handleClickCustomFieldEdit?: () => void;
}

export const CustomFieldsFullTable: React.FC<Props> = ({
  customFields,
  sort,
  handleSort,
  handleClickCustomField,
  activeRowId,
  showFilterRow,
  isFilterActive,
  handleClickCustomFieldEdit,
}) => {
  const onCustomFieldClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (handleClickCustomFieldEdit) {
      handleClickCustomFieldEdit();
    }
  };

  const data = getDataTable(customFields, onCustomFieldClick);

  const loading = useSelector(getLoading);

  return (
    <Table
      data={data}
      columns={TABLE_HEADER_CUSTOM_FIELDS}
      sort={sort}
      onRowClick={handleClickCustomField}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      valueFieldName="id"
      filterComponent={showFilterRow && <CustomFieldsFilter />}
      disabledSort={loading}
    />
  );
};

import React from 'react';

import { SupplementaryAgreement as SupplementaryAgreementContent } from 'features/SupplementaryAgreement';

import { SupplementaryAgreementLayout } from '../../layouts/SupplementaryAgreementLayout';

import styles from './SupplementaryAgreement.module.scss';

const SupplementaryAgreement = () => {
  return (
    <SupplementaryAgreementLayout>
      <SupplementaryAgreementContent
        className={styles.supplementaryAgreement__content}
      />
    </SupplementaryAgreementLayout>
  );
};

export default SupplementaryAgreement;

import cn from 'clsx';
import React, { useCallback } from 'react';

import { Sort } from '../../types';

import styles from './TableHeadCell.module.scss';

interface Props {
  title?: string;
  sort?: string;
  handleSort?: (arg: string) => void;
  sortField?: string;
  className?: string;
  disabled?: boolean;
}

export const TableHeadCell: React.FC<Props> = ({
  title,
  sort,
  children,
  sortField,
  handleSort,
  className,
  disabled,
}) => {
  const currentSortField = sort?.split('_')[0];
  const currentSortOrder = sort?.split('_')[1];

  const arrow =
    currentSortOrder === Sort.DESC
      ? styles.tableHeadCell_desc
      : styles.tableHeadCell_asc;

  const isSorted = currentSortField === sortField && sort;

  const sortHandler = useCallback(
    (cellSortField: string) => {
      if (currentSortField === cellSortField && !disabled) {
        if (currentSortOrder === Sort.ASC && handleSort) {
          handleSort(`${cellSortField}_${Sort.DESC}`);
        }
        if (currentSortOrder === Sort.DESC && handleSort) {
          handleSort(`${cellSortField}_${Sort.ASC}`);
        }
      }

      if (currentSortField !== cellSortField && handleSort && !disabled) {
        handleSort(`${cellSortField}_${Sort.DESC}`);
      }
    },
    [sort, disabled]
  );

  return (
    <div
      onClick={() => sortField && sortHandler(sortField)}
      onKeyDown={() => sortField && sortHandler(sortField)}
      role="button"
      tabIndex={-1}
      className={cn(
        styles.tableHeadCell,
        {
          [styles.tableHeadCell_sortedField]: sortField,
          [styles.tableHeadCell_sort]: isSorted,
          [arrow]: isSorted,
          [styles.tableHeadCell_disabled]: sortField && disabled,
        },
        className
      )}
    >
      {children}
      {title}
    </div>
  );
};

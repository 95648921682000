import { SelectOption } from 'components/Select/types';
import { Organization } from 'core/types';
import {
  Article,
  CategoryArticle,
  KBArticleTableFilterToRequest,
  KBOrganizationsFilter,
} from 'features/KnowledgeBase/types';
import { ResponseWithMeta } from 'store/types';

import { FetchArticlesParams } from '../../KBMain/ducks/types';

import { ArticleViewing } from './types';

export const fetchCurrentArticleRequestArticleViewing = (id: string) => {
  return {
    type: ArticleViewing.FETCH_CURRENT_ARTICLE_REQUEST_ARTICLE_VIEWING,
    payload: id,
  };
};

export const fetchCurrentArticleSuccessArticleViewing = (data: Article) => {
  return {
    type: ArticleViewing.FETCH_CURRENT_ARTICLE_SUCCESS_ARTICLE_VIEWING,
    payload: data,
  };
};

export const loadingShowCurrentArticleArticleViewing = () => {
  return {
    type: ArticleViewing.LOADING_SHOW_CURRENT_ARTICLE_ARTICLE_VIEWING,
  };
};

export const loadingHideCurrentArticleArticleViewing = () => {
  return {
    type: ArticleViewing.LOADING_HIDE_CURRENT_ARTICLE_ARTICLE_VIEWING,
  };
};

export const setArticlesFilterArticleViewing = (
  filter: KBArticleTableFilterToRequest
) => {
  return {
    type: ArticleViewing.SET_FILTER_ARTICLES_ARTICLE_VIEWING,
    payload: filter,
  };
};

export const fetchArticlesArticleViewingRequest = (
  params: FetchArticlesParams
) => {
  return {
    type: ArticleViewing.FETCH_REQUEST_ARTICLES_ARTICLE_VIEWING,
    payload: params,
  };
};

export const fetchArticlesSuccessArticleViewing = (
  articles: ResponseWithMeta<Article[]>
) => {
  return {
    type: ArticleViewing.FETCH_SUCCESS_ARTICLES_ARTICLE_VIEWING,
    payload: articles,
  };
};

export const fetchArticlesJoinSuccessArticleViewing = (
  articles: ResponseWithMeta<Article[]>
) => {
  return {
    type: ArticleViewing.FETCH_ARTICLES_JOIN_SUCCESS_ARTICLE_VIEWING,
    payload: articles,
  };
};

export const setArticlesPageArticleViewing = (page: number) => {
  return {
    type: ArticleViewing.SET_ARTICLES_PAGE_ARTICLE_VIEWING,
    payload: page,
  };
};

export const resetArticlesStateArticleViewing = () => {
  return {
    type: ArticleViewing.RESET_ARTICLES_STATE_ARTICLE_VIEWING,
  };
};

export const loadingShowArticleViewing = () => {
  return {
    type: ArticleViewing.LOADING_SHOW_ARTICLES_ARTICLE_VIEWING,
  };
};

export const loadingHideArticleViewing = () => {
  return {
    type: ArticleViewing.LOADING_HIDE_ARTICLES_ARTICLE_VIEWING,
  };
};

export const fetchOrganizationsRequestArticleViewing = (
  filter?: KBOrganizationsFilter
) => {
  return {
    type: ArticleViewing.FETCH_ORGANIZATIONS_REQUEST_ARTICLE_VIEWING,
    payload: filter,
  };
};

export const fetchOrganizationsSuccessArticleViewing = (
  data: Organization[]
) => {
  return {
    type: ArticleViewing.FETCH_ORGANIZATIONS_SUCCESS_ARTICLE_VIEWING,
    payload: data,
  };
};

export const organizationsLoadingShowArticleViewing = () => {
  return {
    type: ArticleViewing.ORGANIZATIONS_LOADING_SHOW_ARTICLE_VIEWING,
  };
};

export const organizationsLoadingHideArticleViewing = () => {
  return {
    type: ArticleViewing.ORGANIZATIONS_LOADING_HIDE_ARTICLE_VIEWING,
  };
};

export const fetchCategoriesRequestArticleViewing = (title?: string) => {
  return {
    type: ArticleViewing.FETCH_CATEGORIES_REQUEST_ARTICLE_VIEWING,
    payload: title,
  };
};

export const fetchCategoriesSuccessArticleViewing = (
  categories: CategoryArticle[]
) => {
  return {
    type: ArticleViewing.FETCH_CATEGORIES_SUCCESS_ARTICLE_VIEWING,
    payload: categories,
  };
};

export const categoriesLoadingShowArticleViewing = () => {
  return {
    type: ArticleViewing.CATEGORIES_LOADING_SHOW_ARTICLE_VIEWING,
  };
};

export const categoriesLoadingHideArticleViewing = () => {
  return {
    type: ArticleViewing.CATEGORIES_LOADING_HIDE_ARTICLE_VIEWING,
  };
};

export const resetCategoriesStateArticleViewing = () => {
  return {
    type: ArticleViewing.RESET_CATEGORIES_STATE_ARTICLE_VIEWING,
  };
};

export const setSelectedOrganizationArticleViewing = (
  option: SelectOption | null
) => {
  return {
    type: ArticleViewing.SET_ORGANIZATION_SELECTED_ARTICLE_VIEWING,
    payload: option,
  };
};

export const setSearchValueArticleViewing = (value: string) => {
  return {
    type: ArticleViewing.SET_SEARCH_VALUE_ARTICLE_VIEWING,
    payload: value,
  };
};

export const resetStateArticleViewing = () => {
  return {
    type: ArticleViewing.RESET_STATE_ARTICLE_VIEWING,
  };
};

export const createCategoryRequestRightArticleViewing = (
  data: Partial<CategoryArticle>
) => {
  return {
    type: ArticleViewing.CREATE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING,
    payload: data,
  };
};

export const editCategoryRequestRightArticleViewing = (
  data: CategoryArticle
) => {
  return {
    type: ArticleViewing.EDIT_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING,
    payload: data,
  };
};

export const deleteCategoryRequestRightArticleViewing = (id: string) => {
  return {
    type: ArticleViewing.DELETE_CATEGORY_REQUEST_RIGHT_ARTICLE_VIEWING,
    payload: id,
  };
};

export const editArticleRequestRightArticleViewing = (
  article: Partial<Article>
) => {
  return {
    type: ArticleViewing.EDIT_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING,
    payload: article,
  };
};

export const deleteArticleRequestRightArticleViewing = (id: string) => {
  return {
    type: ArticleViewing.DELETE_ARTICLE_REQUEST_RIGHT_ARTICLE_VIEWING,
    payload: id,
  };
};

import {
  Action,
  CustomFieldToRequest,
  CustomTicketFields,
  Priority,
  Status,
  System,
} from 'core/types';
import {
  CheckExistIntegration,
  CloseTicketRequest,
  Comment,
  CreateTicketInJiraPayload,
  NextStatusesRequest,
  Ticket,
  TicketHistory,
  TicketUpdateType,
} from 'features/Ticket/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const request = {
  fetchTicket: (id: string) =>
    ApiClient.get<Ticket>({
      url: endpoints.getTicket(id),
    }),
  setTicketToWork: (ids: string[]) =>
    ApiClient.post<Ticket[]>({
      url: endpoints.setTicketToWork(),
      data: ids,
    }),
  addSpecialist: (ticketId: string | string[], userId: string) =>
    ApiClient.post<Ticket[]>({
      url: endpoints.addSpecialist(),
      params: { userId },
      data: Array.isArray(ticketId) ? ticketId : [ticketId],
    }),
  fetchHistory: (id: string) =>
    ApiClient.get<TicketHistory[]>({
      baseURL: getEnv('REACT_APP_HISTORY_URL'),
      url: endpoints.getHistory(id),
    }),
  fetchEditTicketPriority: (ticketId: string, priority: Priority) =>
    ApiClient.patch<Ticket>({
      url: endpoints.getEditTicketPriority(ticketId),
      params: { priority },
    }),
  fetchCurrentTicketActions: (ticketGroupsIds: string[]) =>
    ApiClient.post<Action[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getCurrentTicketActions(),
      data: ticketGroupsIds,
    }),
  deleteTicket: (id: string) =>
    ApiClient.delete<Ticket>({
      url: endpoints.deleteTicket(id),
    }),
  updateDataTicket: (ticket: TicketUpdateType) =>
    ApiClient.patch<Ticket>({
      url: endpoints.updateDataTicket(),
      data: ticket,
    }),
  createTicketInJira: (data: CreateTicketInJiraPayload) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.createTicketInJira(),
      data,
    }),
  fetchIsSystemIntegratedWithJira: (ids: CheckExistIntegration) =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getIsJiraIntegratedWithJira(ids),
    }),
  fetchJiraUrl: (ticketId: string) =>
    ApiClient.get<string>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.getJiraUrl(ticketId),
    }),
  fetchCustomFields: (ticketId: string) =>
    ApiClient.get<CustomFieldToRequest[]>({
      url: endpoints.getCustomFieldsByTicketIdUrl(ticketId),
    }),
  updateCustomFields: (ticketId: string, data: CustomFieldToRequest[]) =>
    ApiClient.patch<CustomTicketFields>({
      url: endpoints.updateCustomFieldsByTicketIdUrl(ticketId),
      data,
    }),
  fetchNextStatuses: (ids: NextStatusesRequest) =>
    ApiClient.get<Status[]>({
      baseURL: getEnv('REACT_APP_TICKET_STATUS'),
      url: endpoints.getNextStatuses(ids),
    }),
  changeStatus: (comment: Comment) =>
    ApiClient.post<Comment>({
      baseURL: getEnv('REACT_APP_MESSAGE_URL'),
      url: endpoints.getChangeStatus(),
      data: comment,
    }),
  closeTicket: ({ id, reasonClosing }: CloseTicketRequest) =>
    ApiClient.post({
      baseURL: getEnv('REACT_APP_MESSAGE_URL'),
      url: endpoints.closeTicket(id),
      params: { reasonClosing },
    }),
  restoreTicket: (id?: string) =>
    ApiClient.patch({
      url: endpoints.restoreTicket(id),
    }),
  fetchTicketSystem: (id: string) =>
    ApiClient.get<System>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getTicketSystem(id),
      data: {},
    }),
  compareWithJiraStatuses: (ticketId: string) =>
    ApiClient.get<boolean>({
      baseURL: getEnv('REACT_APP_JIRA_INTEGRATION_URL'),
      url: endpoints.compareWithJiraStatuses(ticketId),
      data: {},
    }),
};

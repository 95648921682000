import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TablesLayout } from 'core/layouts/TablesLayout';
import { resetOrganizationState } from 'features/Organizations/ducks/actions';
import { RolesTableContainer } from 'features/Roles/components/RolesTableContainer';
import { resetRolesState } from 'features/Roles/ducks/actions';
import { TableRolesTypes } from 'features/Roles/types';
import { WorkGroupsTableContainer } from 'features/WorkGroups/components/WorkGroupsTableContainer';
import { resetWorkGroupsState } from 'features/WorkGroups/ducks/actions';
import { TableWorkGroupsTypes } from 'features/WorkGroups/types';

import { UsersTableContainer } from './components/UsersTableContainer';
import { fetchUsersRequest, resetUsersState } from './ducks/actions';
import { TableUsersTypes } from './types';
import styles from './Users.module.scss';

const SubContractsTable = () => {
  return (
    <WorkGroupsTableContainer tableType={TableWorkGroupsTypes.FROM_USERS} />
  );
};

const SubUsersTable = () => {
  return <RolesTableContainer tableType={TableRolesTypes.FROM_USERS} />;
};

const MainTable = () => {
  return <UsersTableContainer tableType={TableUsersTypes.FULL} />;
};

export const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsersRequest());
    return () => {
      dispatch(resetUsersState());
      dispatch(resetWorkGroupsState());
      dispatch(resetRolesState());
      dispatch(resetOrganizationState());
    };
  }, []);

  return (
    <TablesLayout
      MainTable={[MainTable]}
      SubTable={[SubContractsTable, SubUsersTable]}
      classNameRoot={styles.users}
    />
  );
};

import React, { useEffect, useState } from 'react';

import { Checkbox } from 'components/Checkbox';
import { Props as PaginationProps } from 'components/Pagination';
import { TableColumn } from 'components/Table/types';
import { AddModal } from 'core/modals/AddModal/index';
import { Organization } from 'core/types';

export type IdAbleType = {
  id: string;
  organization?: Partial<Organization>;
  organizationIdAddl?: string;
};

interface Props<T> {
  leftData: T[];
  rightData: T[];
  getDataTable: (
    changeCheckbox: (
      item: T
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void,
    selected: string[],
    data?: T[]
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ) => any;
  columns: TableColumn[];
  isModal: boolean;
  toggleModal: () => void;
  onSubmit: (data: T[]) => void;
  filterComponent?: JSX.Element | boolean;
  mainTitle: string;
  leftTableTitle: string;
  subModalText: string;
  loadingData?: boolean;
  isFilterActive?: boolean;
  classNameLeftTable?: string;
  onChangeIdsForDelete?: (data: T[]) => void;
  paginationLeftTableProps: PaginationProps;
}

export const AddModalContainer = <T extends IdAbleType>(props: Props<T>) => {
  const {
    leftData,
    rightData,
    getDataTable,
    columns,
    isModal,
    toggleModal,
    onSubmit,
    filterComponent,
    mainTitle,
    leftTableTitle,
    subModalText,
    loadingData,
    isFilterActive,
    classNameLeftTable,
    onChangeIdsForDelete,
    paginationLeftTableProps,
  } = props;
  const [selectedLeftData, setSelectedLeftData] = useState<T[]>([]);
  const [selectedRightData, setSelectedRightData] = useState<T[]>([]);
  const [tablesRightData, setTablesRightData] = useState(rightData);
  const [tablesLeftData, setTablesLeftData] = useState(leftData);

  const isSubmitDisabled =
    loadingData ||
    (rightData.length === tablesRightData.length &&
      rightData.reduce(
        (acc, elem) =>
          acc && tablesRightData.map((item) => item.id).includes(elem.id),
        true
      ));

  useEffect(() => {
    setTablesLeftData(leftData);
  }, [leftData]);

  useEffect(() => {
    setTablesRightData(rightData);
  }, [rightData]);

  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = 'hidden';
    }
  }, [isModal]);

  useEffect(
    () => () => {
      document.body.style.overflow = 'visible';
    },
    []
  );

  const changeLeftCheckbox =
    (item: T) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedLeftData((prev) => [...prev, item]);
        return;
      }
      setSelectedLeftData((prev) => prev.filter((elem) => elem.id !== item.id));
    };

  const changeRightCheckbox =
    (item: T) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedRightData((prev) => [...prev, item]);
        return;
      }
      setSelectedRightData((prev) =>
        prev.filter((elem) => elem.id !== item.id)
      );
    };

  const changeAllLeftCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setSelectedLeftData([...tablesLeftData]);
      return;
    }
    setSelectedLeftData([]);
  };

  const changeAllRightCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setSelectedRightData([...tablesRightData]);
      return;
    }
    setSelectedRightData([]);
  };

  useEffect(() => {
    if (onChangeIdsForDelete) {
      onChangeIdsForDelete(tablesRightData);
    }
  }, [tablesRightData]);

  const onArrowClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setTablesRightData([...tablesRightData, ...selectedLeftData] || []);
    setSelectedLeftData([]);
  };
  const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setTablesRightData([
      ...tablesRightData.filter(
        (item) => !selectedRightData.map((elem) => elem.id).includes(item.id)
      ),
    ]);
    setSelectedRightData([]);
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onSubmit(tablesRightData);
  };

  const columnsLeftTable = [
    {
      title: (
        <Checkbox
          onChange={changeAllLeftCheckbox}
          checked={
            !!tablesLeftData?.length &&
            tablesLeftData?.length === selectedLeftData.length
          }
        />
      ),
      fieldName: 'checkbox',
    },
    ...columns,
  ];

  const columnsRightTable = [
    {
      title: (
        <Checkbox
          onChange={changeAllRightCheckbox}
          checked={
            !!tablesRightData?.length &&
            tablesRightData?.length === selectedRightData.length
          }
        />
      ),
      fieldName: 'checkbox',
    },
    ...columns,
  ];

  const leftTableProps = {
    columns: columnsLeftTable,
    data: getDataTable(
      changeLeftCheckbox,
      selectedLeftData.map((item) => item.id),
      tablesLeftData
    ),
    className: classNameLeftTable,
  };

  const rightDataTable = {
    columns: columnsRightTable,
    data: getDataTable(
      changeRightCheckbox,
      selectedRightData.map((item) => item.id),
      tablesRightData
    ),
  };

  return (
    <AddModal
      isModal={isModal}
      toggleModal={toggleModal}
      leftTableProps={leftTableProps}
      rightTableProps={rightDataTable}
      mainTitle={mainTitle}
      leftTableTitle={leftTableTitle}
      onArrowClick={onArrowClick}
      onDelClick={onDeleteClick}
      disabledAddBtn={!selectedLeftData.length}
      disabledDeleteBtn={!selectedRightData.length || !tablesRightData?.length}
      disabledSubmit={isSubmitDisabled}
      onSubmit={handleSubmit}
      filterComponent={filterComponent}
      subModalText={subModalText}
      loadingData={loadingData}
      isFilterActive={isFilterActive}
      saveTitle={rightData.length ? 'Сохранить' : 'Добавить'}
      paginationLeftTableProps={paginationLeftTableProps}
    />
  );
};

import React from 'react';

import { Table } from 'components/Table';
import { Contract } from 'core/types';

import { TABLE_HEADER_FROM_SUPPLEMENTARY_AGREEMENT } from '../../constants';

import { getDataTable } from './utils';

interface Props {
  supplementaryAgreements?: Contract[];
  loading?: boolean;
  onSort?: (sort: string) => void;
  sort?: string;
}

export const SupplementaryAgreementTable: React.FC<Props> = ({
  supplementaryAgreements,
  loading,
  onSort,
  sort,
}) => {
  const dataTable = getDataTable(supplementaryAgreements);

  return (
    <Table
      data={dataTable}
      onSort={onSort}
      sort={sort}
      columns={TABLE_HEADER_FROM_SUPPLEMENTARY_AGREEMENT}
      dataLoading={loading}
      valueFieldName="id"
    />
  );
};

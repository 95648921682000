import { debounce } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/Select';
import { SelectProps } from 'components/Select/types';
import { Size } from 'components/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';

import {
  resetSystemsState,
  setCurrentSystemsPage,
  setSortSystems,
  setSystemsFilter,
} from '../../ducks/actions';
import { getPropsSystems, getSystemsSelectList } from '../../ducks/selectors';

interface Props extends Omit<SelectProps, 'options'> {}

export const SystemSelect: React.FC<Props> = (props) => {
  const { className, placeholder, isMulti = true } = props;
  const dispatch = useDispatch();
  const { totalElements, pageNum, pageSize, loadingSystem } =
    useSelector(getPropsSystems);
  const systemsSelectList = useSelector(getSystemsSelectList);

  const { totalPage } = useInfiniteScroll({
    pageNum,
    pageSize,
    totalElements,
  });

  const onFilterSystem = debounce((valueFilter: string) => {
    dispatch(setCurrentSystemsPage(0));
    dispatch(setSystemsFilter({ title: valueFilter }));
  }, DEFAULT_DEBOUNCE_DELAY);

  const setNextPageSystem = (valuePage: number) => {
    dispatch(setCurrentSystemsPage(valuePage));
  };

  useEffect(() => {
    dispatch(setCurrentSystemsPage(0));
    dispatch(setSystemsFilter({}));
    dispatch(setSortSystems('TITLE_ASC'));
    return () => {
      dispatch(resetSystemsState());
    };
  }, []);
  return (
    <Select<string>
      {...props}
      size={Size.xs}
      options={systemsSelectList}
      onChangeInput={onFilterSystem}
      infiniteScrollable
      currentPage={pageNum}
      totalPage={totalPage}
      setNextPage={setNextPageSystem}
      classNameContainer={className}
      loading={loadingSystem}
      placeholder={placeholder}
      isMulti={isMulti}
      isSearchable
      isClearable
    />
  );
};

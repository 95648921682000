import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { Checkbox } from 'components/Checkbox';
import { InputDatePicker } from 'components/DatePicker/components/InputDatePicker';
import { Input } from 'components/Input';
import { CustomFieldType } from 'components/types';

import { CreateTicketData } from '../types';

type CustomFieldComponentArgs = {
  customFieldId: string;
  title?: string;
  type?: CustomFieldType;
  index: number;
  control: Control<CreateTicketData>;
};

export const getCustomFieldComponent = ({
  customFieldId,
  title,
  type,
  index,
  control,
}: CustomFieldComponentArgs) => {
  if (!type) {
    return null;
  }

  const props = {
    key: customFieldId,
    label: title,
  };

  const reactHookProps = {
    control,
    key: customFieldId,
  };

  const customFieldMap: Record<CustomFieldType, JSX.Element> = {
    [CustomFieldType.FIELD_TEXT]: (
      <Controller
        {...reactHookProps}
        name={`customFields.${index}.text`}
        rules={{ required: true }}
        render={({ field }) => <Input {...props} onChange={field.onChange} />}
      />
    ),
    [CustomFieldType.FIELD_FLAG]: (
      <Controller
        {...reactHookProps}
        name={`customFields.${index}.flag`}
        rules={{ required: true }}
        render={({ field }) => (
          <Checkbox {...props} onChange={field.onChange} />
        )}
      />
    ),
    [CustomFieldType.FIELD_DATE]: (
      <Controller
        {...reactHookProps}
        name={`customFields.${index}.date`}
        rules={{ required: true }}
        render={({ field }) => (
          <InputDatePicker
            {...props}
            type="datePicker"
            placeholder={title}
            value={field.value || ''}
            onChange={field.onChange}
          />
        )}
      />
    ),
  };

  return customFieldMap[type];
};

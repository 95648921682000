import React from 'react';

import { NotificationLink } from 'components/NotificationLink';
import { WorkGroup } from 'core/types';

interface DataTableConfig {
  workGroups?: WorkGroup[];
  isAccessToViewingCompany: boolean;
}

export const getDataTable = ({
  workGroups,
  isAccessToViewingCompany,
}: DataTableConfig) =>
  workGroups?.map((workGroup) => {
    const { title, description, id, organization } = workGroup;
    return {
      id,
      title: {
        title,
        to: `/admin/work-groups/${id}`,
      },
      description,
      organizationId: isAccessToViewingCompany ? (
        <NotificationLink
          to={`/admin/organizations/${organization?.id}`}
          title={organization?.title}
        />
      ) : (
        organization?.title
      ),
    };
  }) || [];

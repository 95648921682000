import _, { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Input } from 'components/Input';
import { TableBodyCell } from 'components/Table/components/TableBodyCell';
import { TableFilter } from 'components/Table/components/TableFilter';
import { Size } from 'components/types';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { EnvironmentsFilter } from 'core/types';
import { getActiveSystemIdContractDetail } from 'features/Contract/ducks/selectors';

import { INITIAL_ENVIRONMENT_FILTER } from '../../constants';

import styles from './EnvironmentFilter.module.scss';

interface Props {
  onFilter: (filter: EnvironmentsFilter) => void;
}

export const EnvironmentFilter: React.FC<Props> = React.memo(({ onFilter }) => {
  const activeSystemId = useSelector(getActiveSystemIdContractDetail);
  const { register, handleSubmit, reset, watch } = useForm<EnvironmentsFilter>({
    mode: 'onChange',
    defaultValues: INITIAL_ENVIRONMENT_FILTER,
  });
  const filterValues = watch();

  const resetFilter = () => {
    onFilter({ systemId: activeSystemId });
    reset();
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { name } = data;
    onFilter({ name, systemId: activeSystemId });
  });

  const formSubmitHandlerDebounced = useMemo(
    () => debounce(formSubmitHandler, DEFAULT_DEBOUNCE_DELAY),
    [onFilter]
  );

  const nameToInput = register('name', {
    onChange: formSubmitHandlerDebounced,
  });

  const tableBodyWrapper = (
    <TableBodyCell className={styles.environmentFilter__cell}>
      <Input size={Size.xs} type="name" key="name" {...nameToInput} />
    </TableBodyCell>
  );

  const disableReset = _.isEqual(
    _.omitBy(filterValues, (value, key) =>
      _.isNil(value) || _.isEmpty(value) ? key : false
    ),
    {}
  );
  return (
    <TableFilter
      filterComponent={tableBodyWrapper}
      onReset={resetFilter}
      disableReset={disableReset}
    />
  );
});

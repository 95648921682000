import React from 'react';
import { useDispatch } from 'react-redux';

import { Pen3Icon } from 'assets/icons';
import { PopoverButton } from 'components/Popover/components/PopoverButton';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { DeleteButton } from 'core/components/DeleteButton';

import { setIsEditNewsMode, setIsNewsModal } from '../../ducks/actions';

import styles from './NewsPopoverContent.module.scss';

interface Props {
  onDelete(): void;
  toggleIsPopoverOpen(): void;
}

export const NewsPopoverContent: React.FC<Props> = ({
  onDelete,
  toggleIsPopoverOpen,
}) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    toggleIsPopoverOpen();
    dispatch(setIsEditNewsMode(true));
    dispatch(setIsNewsModal(true));
  };

  const onDeleteHandler = (
    event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event?.stopPropagation();
    toggleIsPopoverOpen();
    onDelete();
  };

  return (
    <>
      <PopoverContainer>
        <PopoverButton
          onClick={onEdit}
          icon={<Pen3Icon className={styles.newsPopover__icon} />}
        >
          Редактировать
        </PopoverButton>
        <DeleteButton onClick={onDeleteHandler} />
      </PopoverContainer>
    </>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';

interface Props {
  parentNode?: HTMLElement | null;
}

export const Portal: React.FC<Props> = ({ children, parentNode }) => {
  if (parentNode) {
    return ReactDOM.createPortal(children, parentNode);
  }
  return ReactDOM.createPortal(children, document.body);
};

import { Article } from 'features/KnowledgeBase/types';
import { Ticket } from 'features/Ticket/types';
import { ResponseWithMeta } from 'store/types';
import { Attachment } from 'types/models/attachment';

import { SearchData } from '../types';

import {
  FetchArticlesRequestAction,
  FetchArticlesSuccessAction,
  FetchSimilarArticleRequestAction,
  FetchSimilarArticleSuccessAction,
  FetchSimilarTicketAttachmentsRequestAction,
  FetchSimilarTicketAttachmentsSuccessAction,
  FetchSimilarTicketRequestAction,
  FetchSimilarTicketSuccessAction,
  FetchTicketsRequestAction,
  FetchTicketsSuccessAction,
  HideLoadingAction,
  HideSimilarDetailLoadingAction,
  ResetSimilarSolutionsStateAction,
  ShowLoadingAction,
  ShowSimilarDetailLoadingAction,
  SimilarSolutions,
} from './types';

export const showLoading = (): ShowLoadingAction => {
  return {
    type: SimilarSolutions.SIMILAR_LOADING_SHOW,
  };
};

export const hideLoading = (): HideLoadingAction => {
  return {
    type: SimilarSolutions.SIMILAR_LOADING_HIDE,
  };
};

export const fetchTicketsRequest = (
  payload: SearchData
): FetchTicketsRequestAction => {
  return {
    type: SimilarSolutions.FETCH_SIMILAR_TICKETS_REQUEST,
    payload,
  };
};

export const fetchTicketsSuccess = (
  data: ResponseWithMeta<Ticket[]>
): FetchTicketsSuccessAction => {
  return {
    type: SimilarSolutions.FETCH_SIMILAR_TICKETS_SUCCESS,
    payload: data,
  };
};

export const fetchArticlesRequest = (
  payload: SearchData
): FetchArticlesRequestAction => {
  return {
    type: SimilarSolutions.FETCH_SIMILAR_ARTICLES_REQUEST,
    payload,
  };
};

export const fetchArticlesSuccess = (
  data: ResponseWithMeta<Article[]>
): FetchArticlesSuccessAction => {
  return {
    type: SimilarSolutions.FETCH_SIMILAR_ARTICLES_SUCCESS,
    payload: data,
  };
};

export const resetSimilarSolutionsState =
  (): ResetSimilarSolutionsStateAction => {
    return {
      type: SimilarSolutions.RESET_SIMILAR_SOLUTIONS_STATE,
    };
  };

export const fetchSimilarTicketRequest = (
  ticketId: string
): FetchSimilarTicketRequestAction => {
  return {
    type: SimilarSolutions.FETCH_SIMILAR_TICKET_REQUEST,
    payload: ticketId,
  };
};

export const fetchSimilarTicketSuccess = (
  payload: Ticket
): FetchSimilarTicketSuccessAction => {
  return {
    type: SimilarSolutions.FETCH_SIMILAR_TICKET_SUCCESS,
    payload,
  };
};

export const showSimilarDetailLoading = (): ShowSimilarDetailLoadingAction => {
  return {
    type: SimilarSolutions.SIMILAR_DETAIL_LOADING_SHOW,
  };
};

export const hideSimilarDetailLoading = (): HideSimilarDetailLoadingAction => {
  return {
    type: SimilarSolutions.SIMILAR_DETAIL_LOADING_HIDE,
  };
};

export const fetchSimilarArticleRequest = (
  articleId: string
): FetchSimilarArticleRequestAction => {
  return {
    type: SimilarSolutions.FETCH_SIMILAR_ARTICLE_REQUEST,
    payload: articleId,
  };
};

export const fetchSimilarArticleSuccess = (
  payload: Article
): FetchSimilarArticleSuccessAction => {
  return {
    type: SimilarSolutions.FETCH_SIMILAR_ARTICLE_SUCCESS,
    payload,
  };
};

export const fetchSimilarTicketAttachmentsRequest = (
  ticketId: string
): FetchSimilarTicketAttachmentsRequestAction => {
  return {
    type: SimilarSolutions.FETCH_SIMILAR_TICKET_ATTACHMENTS_REQUEST,
    payload: ticketId,
  };
};

export const fetchSimilarTicketAttachmentsSuccess = (
  attachments: Attachment[]
): FetchSimilarTicketAttachmentsSuccessAction => {
  return {
    type: SimilarSolutions.FETCH_SIMILAR_TICKET_ATTACHMENTS_SUCCESS,
    payload: attachments,
  };
};

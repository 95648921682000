import { User, WorkGroup } from 'core/types';
import { AccessedRoute, CurrentUserOrganization } from 'features/Auth/types';
import { ResponseWithMeta } from 'store/types';
import { ApiClient } from 'utils';
import { getEnv } from 'utils/getEnv';

import { endpoints } from './endpoints';

export const request = {
  fetchAccessedRoutes: () =>
    ApiClient.get<AccessedRoute[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getAccessedRoutes(),
    }),
  fetchActionList: () =>
    ApiClient.get<string[]>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getActionList(),
    }),
  fetchUsersWorkGroups: (userId: string) =>
    ApiClient.post<ResponseWithMeta<WorkGroup[]>>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUsersWorkGroups(userId),
      data: {},
    }),
  changePassword: (password: string) =>
    ApiClient.put<User>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getChangePasswordPath(),
      data: password,
      headers: {
        'Content-Type': 'text/plain',
      },
    }),
  fetchUserOrganization: () =>
    ApiClient.get<CurrentUserOrganization>({
      baseURL: getEnv('REACT_APP_ORGANIZATIONS_URL'),
      url: endpoints.getUserOrganization(),
    }),
};

import cn from 'clsx';
import { debounce } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/Button';
import { Select } from 'components/Select';
import { SelectOption, ValueType } from 'components/Select/types';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/meta';
import { TicketStatus } from 'core/types';
import { ActionList } from 'features/Auth/types';
import { addSpecAndSetToWork } from 'features/Ticket/ducks/ticket/actions';
import {
  getIsAccessedTicketAction,
  getTicket,
} from 'features/Ticket/ducks/ticket/selectors';
import { useTicketId } from 'features/Ticket/hooks/useTicketId';
import {
  fetchSubordinatesRequest,
  setSubordinatesFilter,
} from 'features/Users/ducks/actions';
import { getSubordinatesWithOutCurrentSelectList } from 'features/Users/ducks/selectors';

import styles from './SpecialistForm.module.scss';

interface Props {
  className?: string;
  classNameForm?: string;
  classNameSelect?: string;
  classNameButton?: string;
}

export const SpecialistForm: React.FC<Props> = ({
  className,
  classNameForm,
  classNameSelect,
  classNameButton,
}) => {
  const ticket = useSelector(getTicket);
  const isAccessToAssigningTicketToSpecialist = useSelector(
    getIsAccessedTicketAction(ActionList.AssigningTicketToSpecialist)
  );
  const executorOptions =
    useSelector(
      getSubordinatesWithOutCurrentSelectList(ticket?.specialistId?.key)
    ) || [];

  const ticketId = useTicketId();

  const dispatch = useDispatch();

  const onFilterSpecialist = debounce((valueFilter: string) => {
    dispatch(
      setSubordinatesFilter({
        fullName: valueFilter,
      })
    );
    dispatch(fetchSubordinatesRequest());
  }, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    if (isAccessToAssigningTicketToSpecialist && ticket) {
      dispatch(fetchSubordinatesRequest());
    }
  }, [isAccessToAssigningTicketToSpecialist, ticket]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, isValid },
  } = useForm<{ executor: ValueType<string> }>({
    mode: 'onChange',
    defaultValues: {
      executor: null,
    },
  });

  const currentExecutorId = watch('executor');

  const formSubmitHandler = handleSubmit(({ executor }) => {
    if (ticketId) {
      dispatch(
        addSpecAndSetToWork({
          ticketId: [ticketId],
          specialistId:
            executor && !Array.isArray(executor) ? executor.value : '',
          isTicketNew: false,
        })
      );
    }
    reset();
  });

  return (
    <div className={cn(styles.specialistForm, className)}>
      <Typography
        variant={TypographyVariants.o}
        className={styles.specialistForm__subTitle}
      >
        Назначить исполнителя
      </Typography>
      <form
        onSubmit={formSubmitHandler}
        className={cn(styles.specialistForm__form, classNameForm)}
      >
        <Controller
          control={control}
          name="executor"
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Select<string>
                size={Size.s}
                mobileModalTitle="исполнителя"
                placeholder="Исполнитель"
                options={executorOptions}
                onChange={field.onChange}
                onChangeInput={onFilterSpecialist}
                value={field.value}
                isSearchable
                className={cn(styles.specialistForm__select, classNameSelect)}
              />
            );
          }}
        />
        <Button
          className={cn(styles.specialistForm__toAppoint, classNameButton)}
          disabled={
            ticket?.customStatus?.defaultStatus === TicketStatus.CLOSE ||
            ticket?.specialistId?.key ===
              (currentExecutorId as SelectOption)?.value ||
            !isDirty ||
            !isValid
          }
        >
          Назначить
        </Button>
      </form>
    </div>
  );
};

import React, { useState } from 'react';

import { Popover } from 'components/Popover';

import { SetCurrentFilterTitleRequest } from '../../types';
import { TicketsPopoverInner } from '../TicketsPopoverInner';

interface Props {
  value: string;
  title?: string;
  onChangePopover?: (title: string) => void;
  handleSubmitPopover?: (data: SetCurrentFilterTitleRequest) => void;
  handleDelete?: (id: string) => void;
  className: string;
}

export const TicketsPopover: React.FC<Props> = (props) => {
  const {
    title,
    value,
    onChangePopover,
    handleSubmitPopover,
    handleDelete,
    className,
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleToggle = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  const handleChange = (popoverTitle: string) => {
    if (onChangePopover) {
      onChangePopover(popoverTitle);
    }
  };

  const handleSubmit = (currentTitle: string) => {
    if (handleSubmitPopover) {
      handleSubmitPopover({ id: value, title: currentTitle });
    }
  };

  const handleDeleteWithReset = () => {
    if (handleDelete) {
      handleDelete(value);
    }
  };

  return (
    <Popover
      className={className}
      positions={['right']}
      align="start"
      isOpen={isPopoverOpen}
      togglePopover={handleToggle}
      content={
        <TicketsPopoverInner
          defaultTitle={title}
          formSubmitHandler={handleSubmit}
          onChange={handleChange}
          deleteDataWithReset={handleDeleteWithReset}
          closePopoverWithReset={setIsPopoverOpen}
        />
      }
    />
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { CheckMarkIcon } from 'assets/icons';
import { PopoverButton } from 'components/Popover/components/PopoverButton';
import { PopoverContainer } from 'components/Popover/components/PopoverContainer';
import { PopoverItemWrapper } from 'components/Popover/components/PopoverItemWrapper';
import { PopoverTitle } from 'components/Popover/components/PopoverTitle';
import { DeleteButton } from 'core/components/DeleteButton';
import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { TicketStatus } from 'core/types';
import { getIsClient } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { SpecialistForm } from 'features/Ticket/components/SpecialistForm';
// import { createTicketInJira } from 'features/Ticket/ducks/ticket/actions';
import {
  getIsAccessedTicketAction,
  getTicket,
} from 'features/Ticket/ducks/ticket/selectors';

import styles from './TicketLayoutDropContent.module.scss';

interface Props {
  isJiraButtonDisabled: boolean;
  handleShowModalDelete(): void;
  handleShowModalClose(): void;
}

export const TicketLayoutDropContent: React.FC<Props> = ({
  isJiraButtonDisabled,
  handleShowModalDelete,
  handleShowModalClose,
}) => {
  // const dispatch = useDispatch();

  const ticket = useSelector(getTicket);
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isClient = useSelector(getIsClient);
  const isAccessToDeleteTicket = useSelector(
    getIsAccessedTicketAction(ActionList.DeleteTicket)
  );
  const isAccessToAssigningTicketToSpecialist = useSelector(
    getIsAccessedTicketAction(ActionList.AssigningTicketToSpecialist)
  );
  const isAccessViewingAllTickets = useSelector(
    getIsAccessedTicketAction(ActionList.ViewingAllTickets)
  );

  const isMobileAll = isMobile || isMobileSmall;
  const isShowPopoverTitle =
    !isMobileAll &&
    ((!isClient && isAccessToAssigningTicketToSpecialist) ||
      !isJiraButtonDisabled ||
      isAccessToDeleteTicket);

  // const handleCreateTicketInJira = () => {
  //   if (
  //     ticket?.systemId &&
  //     ticket?.id &&
  //     ticket?.ticketType &&
  //     ticket?.specialistId
  //   ) {
  //     dispatch(
  //       createTicketInJira({
  //         systemId: ticket?.systemId.key,
  //         ticketId: ticket?.id,
  //         ticketTypeId: ticket?.ticketType?.key,
  //         userId: ticket?.specialistId.key,
  //       })
  //     );
  //   }
  // };

  const actions = ticket?.customStatus?.defaultStatus !== TicketStatus.CLOSE &&
    isAccessViewingAllTickets && (
      <>
        <PopoverTitle title="Действия" />
        <PopoverButton
          icon={
            <CheckMarkIcon
              className={styles.ticketLayoutDropContent__closeIcon}
            />
          }
          onClick={handleShowModalClose}
        >
          Закрыть тикет
        </PopoverButton>
      </>
    );

  return (
    <PopoverContainer>
      {actions}
      {isShowPopoverTitle && <PopoverTitle title="Дополнительно" />}
      {isMobileAll && !isClient && isAccessToAssigningTicketToSpecialist && (
        <PopoverItemWrapper>
          <SpecialistForm
            className={styles.ticketLayoutDropContent__specialist}
            classNameForm={styles.ticketLayoutDropContent__specialistForm}
            classNameSelect={styles.ticketLayoutDropContent__specialistSelect}
            classNameButton={styles.ticketLayoutDropContent__specialistButton}
          />
        </PopoverItemWrapper>
      )}
      {/* {!isJiraButtonDisabled && ( */}
      {/*  <PopoverButton */}
      {/*    icon={ */}
      {/*      <JiraLogoIcon */}
      {/*        className={styles.ticketLayoutDropContent__jiraIcon} */}
      {/*      /> */}
      {/*    } */}
      {/*    onClick={handleCreateTicketInJira} */}
      {/*    disabled={isJiraButtonDisabled} */}
      {/*  > */}
      {/*    Создать связанную задачу в Jira */}
      {/*  </PopoverButton> */}
      {/* )} */}
      {isAccessToDeleteTicket && (
        <DeleteButton onClick={handleShowModalDelete} disabled={!ticket} />
      )}
    </PopoverContainer>
  );
};

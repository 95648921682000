import { Role } from 'core/types';

export const getDataTable = (roles?: Role[]) =>
  roles?.map((role) => {
    const { name, description, id } = role;
    return {
      id,
      name,
      description,
    };
  }) || [];

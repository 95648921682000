import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'components/Table';
import { WorkGroup } from 'core/types';
import { getIsAccessedAction } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { getLoading as getResponsibilitiesLoading } from 'features/Responsibilities/ducks/selectors';
import { getLoading as getRolesLoading } from 'features/Roles/ducks/selectors';
import { getLoading as getUsersLoading } from 'features/Users/ducks/selectors';

import { WORK_GROUPS_FIELDS_FULL } from '../../constants';
import {
  fetchWorkGroupsRequest,
  setCurrentWorkGroupsPage,
  setWorkGroupsFilter,
} from '../../ducks/actions';
import { getLoading } from '../../ducks/selectors';
import { TableWorkGroupsTypes, WorkGroupFilter } from '../../types';
import { WorkGroupsFilter } from '../WorkGroupsFilter';

import { getDataTable } from './utils';

interface Props {
  workGroups?: WorkGroup[];
  sort?: string;
  handleSort?: (value: string) => void;
  handleRowClick?: (value: ReturnType<typeof getDataTable>[number]) => void;
  activeRowId?: string;
  showFilterRow?: boolean;
  isFilterActive?: boolean;
}

export const WorkGroupsFullTable: React.FC<Props> = ({
  workGroups,
  sort,
  handleSort,
  handleRowClick,
  activeRowId,
  showFilterRow,
  isFilterActive,
}) => {
  const loading = useSelector(getLoading);
  const responsibilitiesLoading = useSelector(getResponsibilitiesLoading);
  const rolesLoading = useSelector(getRolesLoading);
  const usersLoading = useSelector(getUsersLoading);
  const isAccessToViewingCompany = useSelector(
    getIsAccessedAction(ActionList.ViewingCompanyAdministration)
  );

  const dispatch = useDispatch();

  const data = getDataTable({ workGroups, isAccessToViewingCompany });

  const handleFilter = (filterData: WorkGroupFilter) => {
    dispatch(setCurrentWorkGroupsPage(0));
    dispatch(setWorkGroupsFilter(filterData));
    dispatch(fetchWorkGroupsRequest());
  };

  return (
    <Table
      data={data}
      columns={WORK_GROUPS_FIELDS_FULL}
      onRowClick={handleRowClick}
      sort={sort}
      onSort={handleSort}
      activeRowId={activeRowId}
      dataLoading={loading}
      isFilterActive={isFilterActive}
      filterComponent={
        showFilterRow && (
          <WorkGroupsFilter
            tableType={TableWorkGroupsTypes.FULL}
            onFilter={handleFilter}
          />
        )
      }
      disabledSort={loading}
      disabledRows={rolesLoading || responsibilitiesLoading || usersLoading}
    />
  );
};

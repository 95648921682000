import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { ClockFat } from 'assets/icons';
import { Input } from 'components/Input';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { Typography, TypographyVariants } from 'components/Typography';
import {
  SlaTable,
  Props as SlaTableProps,
} from 'features/Contract/components/SlaTable/SlaTable';

import { Props as AutoClothingProps, SLAAutoClosing } from '../SlaAutoClosing';

import styles from './SLA.module.scss';

interface Props {
  workingHoursInput?: UseFormRegisterReturn;
  workingHoursToInput?: UseFormRegisterReturn;
  popover?: JSX.Element;
  autoClothingProps: AutoClothingProps;
  slaTableProps: SlaTableProps;
  handleActiveTicketType: (
    id: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SLA: React.FC<Props> = ({
  workingHoursInput,
  workingHoursToInput,
  popover,
  autoClothingProps,
  slaTableProps,
  handleActiveTicketType,
}) => {
  const ticketTypes = slaTableProps.slaTicketTypes;

  const ticketTypeList = ticketTypes?.map((ticketType) => (
    <ToggleSwitch
      key={ticketType.ticketTypeId}
      label={ticketType.title}
      checked={ticketType.active}
      onChange={handleActiveTicketType(ticketType.ticketTypeId)}
    />
  ));

  return (
    <div>
      <div className={styles.sla}>
        <div className={styles.sla__container}>
          <div className={styles.sla__openingHoursBlockInfo}>
            <ClockFat className={styles.sla__openingHoursBlockIcon} />
            <Typography variant={TypographyVariants.b3}>
              Часы работы:
            </Typography>
          </div>
          <div className={styles.sla__inputsBlock}>
            <Input
              {...workingHoursInput}
              type="time"
              className={styles.sla__inputsBlockItem}
              label="От"
            />
            <Input
              {...workingHoursToInput}
              type="time"
              className={styles.sla__inputsBlockItem}
              label="До"
            />
          </div>
          {popover}
        </div>
        {!!ticketTypes?.length && (
          <div className={styles.sla__ticketType}>
            <Typography variant={TypographyVariants.h5}>Тип тикета</Typography>
            <div className={styles.sla__switches}>{ticketTypeList}</div>
          </div>
        )}
      </div>
      <SlaTable {...slaTableProps} />
      <SLAAutoClosing {...autoClothingProps} />
    </div>
  );
};

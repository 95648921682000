import React, { useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';

import { Button } from 'components/Button';
import { Loader } from 'components/Loader';
import { Typography, TypographyVariants } from 'components/Typography';
import { ApiClient, createError } from 'utils';
import { getEnv } from 'utils/getEnv';

import styles from './ProfileTelegram.module.scss';

interface QRCodeData {
  codeValue?: string;
  expiredAt?: number;
}

export const ProfileTelegram = () => {
  const [regCodeData, setRegCodeData] = useState<QRCodeData>();
  const [loading, setLoading] = useState<boolean>(false);

  const tgLink = useMemo(
    () => `${getEnv('REACT_APP_TG_AUTH_LINK')}?start=${regCodeData?.codeValue}`,
    [regCodeData?.codeValue, getEnv]
  );

  const fetchQRCode = async () => {
    try {
      setLoading(true);
      const regCode: QRCodeData = await ApiClient.get<QRCodeData>({
        baseURL: getEnv('REACT_APP_TG_BOT_URL'),
        url: '/telegram-support-bot/api/reg-code',
      });
      setRegCodeData(regCode);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      createError(e);
    }
  };

  useEffect(() => {
    fetchQRCode();
  }, []);

  useEffect(() => {
    if (regCodeData?.expiredAt) {
      setTimeout(() => {
        fetchQRCode();
      }, regCodeData.expiredAt * 1000);
    }
  }, [regCodeData?.expiredAt]);

  const qrCode = !loading && !!regCodeData?.codeValue && (
    <div className={styles.profileTelegram__qrCode}>
      <QRCode
        value={tgLink}
        size={216}
        viewBox="0 0 216 216"
        bgColor="var(--common2)"
        fgColor="var(--common1)"
      />
    </div>
  );

  const loader = loading && (
    <Loader classNameRoot={styles.profileTelegram__loader} />
  );

  const button = !!regCodeData?.codeValue && (
    <a
      href={tgLink}
      target="_blank"
      rel="noreferrer"
      tabIndex={-1}
      className={styles.profileTelegram__link}
    >
      <Button
        disabled={!regCodeData?.codeValue}
        className={styles.profileTelegram__button}
      >
        Войти
      </Button>
    </a>
  );

  return (
    <div className={styles.profileTelegram}>
      <Typography
        variant={TypographyVariants.h4}
        className={styles.profileTelegram__title}
      >
        Чат бот
      </Typography>
      <Typography
        variant={TypographyVariants.b3}
        className={styles.profileTelegram__subTitle}
      >
        Работайте с тикетами прямо из Telegram
        <br />
        Для начала работы авторизуйтесь отсканируя QR код или нажав на кнопку
        ниже.
      </Typography>
      {qrCode}
      {loader}
      {button}
    </div>
  );
};

import React from 'react';

import { AuthLoading as AuthLoadingContent } from 'core/components/AuthLoading';
import { MainLayout } from 'core/layouts/MainLayout';
import { SidebarLayout } from 'core/layouts/SidebarLayout';

import styles from './Auth.module.scss';

export const AuthLoading = () => {
  return (
    <SidebarLayout authLoading>
      <MainLayout
        withHeader={false}
        classNameRoot={styles.authLoading__content}
      >
        <AuthLoadingContent />
      </MainLayout>
    </SidebarLayout>
  );
};

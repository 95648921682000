import { format } from 'date-fns';

import { NotificationContent } from 'features/Notifications/types';

export const splitByDate = (notifications: Array<NotificationContent>) => {
  const result: { [key: string]: Array<NotificationContent> } = {};
  notifications.forEach((item) => {
    const objKey = `${format(new Date(item?.createdWhen || ''), 'yyyy/MM/dd')}`;
    if (result[objKey]) {
      result[objKey] = [...result[objKey], item];
      return;
    }
    if (objKey) {
      result[objKey] = [item];
    }
  });
  return result;
};

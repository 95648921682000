import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { SelectOption } from 'components/Select/types';
import { Priority } from 'core/types';
import { RootState } from 'store/rootReducer';

import {
  Field,
  FieldType,
  JiraPriority,
  JiraStatus,
  JiraTicketType,
  TicketTypes,
} from '../types';

export const getJiraIntegrations = (state: RootState) =>
  state.jira.jiraIntegrations;
export const getLoadingJiraIntegrations = (state: RootState) =>
  state.jira.loadingIntegrations;
export const getJiraIntegrationsFilter = (state: RootState) =>
  state.jira.filter || {};
export const getPropsJiraIntegrations = (state: RootState) => ({
  pageNum: state.jira.pagination?.pageNum,
  pageSize: state.jira.pagination?.pageSize,
  sort: state.jira.sort,
  totalElements: state.jira.pagination?.totalElements,
  loading: state.jira.loadingIntegrations,
});
export const getJiraStatuses = (state: RootState) =>
  state.jira.jiraStatuses || [];
export const getJiraPriorities = (state: RootState) =>
  state.jira.jiraPriorities;
export const getJiraPrioritiesForCurrent = (state: RootState) =>
  state.jira.jiraPrioritiesForCurrent;
export const getCurrentJiraIntegrationId = (state: RootState) =>
  state.jira.jiraIntegrationId;
export const getCurrentSystemId = (state: RootState) => state.jira.systemId;
export const getLoadingStatuses = (state: RootState) =>
  state.jira.loadingStatuses;
export const getLoadingPriorities = (state: RootState) =>
  state.jira.loadingPriorities;
export const getJiraStatusesBySystemId = (state: RootState) =>
  state.jira.jiraStatusesBySystemId || [];
export const getJiraPrioritiesBySystemId = (state: RootState) =>
  state.jira.jiraPrioritiesBySystemId;
export const getJiraIntegration = (state: RootState) =>
  state.jira.jiraIntegration;
export const getSpFields = (state: RootState) => state.jira.spFields;
export const getJiraFields = (state: RootState) => state.jira.jiraFields;
export const getLoadingJiraIntegrationInfo = (state: RootState) =>
  state.jira.loadingJiraIntegrationInfo;
export const getFieldsIntegrations = (state: RootState) =>
  state.jira.fieldsIntegrations;
export const getSystemPriorities = (state: RootState) =>
  state.jira.systemPriorities;
export const getSpStatuses = (state: RootState) => state.jira.spStatuses;
export const getSpStatusesForCurrent = (state: RootState) =>
  state.jira.spStatusesForCurrent || [];
export const getTicketTypes = (state: RootState) => state.jira.ticketTypes;
export const getJiraTicketTypes = (state: RootState) =>
  state.jira.jiraTicketTypes;
export const getJiraTicketTypesForCurrent = (state: RootState) =>
  state.jira.jiraTicketTypesForCurrent;
export const getJiraTicketType = (state: RootState) =>
  state.jira.jiraTicketType || [];
export const getTypeId = (state: RootState) => state.jira.typeId;

export const getJiraPrioritiesSelectList = createSelector<
  RootState,
  JiraPriority[] | undefined,
  SelectOption<number>[]
>([getJiraPriorities], (jiraPriorities): SelectOption<number>[] => {
  if (jiraPriorities) {
    return jiraPriorities?.map(
      (jiraPriority): SelectOption<number> => ({
        title: jiraPriority.name || '',
        value: jiraPriority.idPriority || 0,
      })
    );
  }
  return [];
});

export const getSpFieldsSelectList = createSelector<
  RootState,
  Field[] | undefined,
  SelectOption[]
>([getSpFields], (spFields): SelectOption[] => {
  if (spFields) {
    return spFields?.map(
      (spField): SelectOption => ({
        title: spField.title,
        value: spField.value,
      })
    );
  }
  return [];
});

export const getJiraFieldsSelectList = (fieldType?: FieldType) =>
  createSelector<RootState, Field[] | undefined, SelectOption[]>(
    [getJiraFields],
    (jiraFields): SelectOption[] => {
      if (jiraFields && fieldType) {
        return jiraFields
          ?.filter((jiraField) => jiraField.fieldType === fieldType)
          .map(
            (jiraField): SelectOption => ({
              title: jiraField.title,
              value: jiraField.value,
            })
          );
      }
      return [];
    }
  );

export const getTicketTypesOptions = createSelector<
  RootState,
  TicketTypes[] | undefined,
  SelectOption[]
>([getTicketTypes], (ticketTypes): SelectOption[] => {
  return (
    ticketTypes?.map(({ title, id }) => ({
      title,
      value: id,
    })) || []
  );
});

export const getJiraTicketTypesOptions = createSelector<
  RootState,
  JiraTicketType[] | undefined,
  SelectOption[]
>([getJiraTicketTypes], (ticketTypes): SelectOption[] => {
  return (
    ticketTypes?.map(({ name, idType }) => ({
      title: name,
      value: idType.toString(),
    })) || []
  );
});

export const getSelectedJiraTicketType = (id?: string) =>
  createSelector<
    RootState,
    JiraTicketType[] | undefined,
    JiraTicketType | undefined
  >([getJiraTicketTypes], (jiraTicketTypes): JiraTicketType | undefined => {
    if (id) {
      return jiraTicketTypes?.find(({ idType }) => idType === +id);
    }
    return undefined;
  });

export const getJiraPrioritiesOptions = (jiraPriorities?: JiraPriority[]) =>
  sortBy(jiraPriorities, (item) => item.idPriority).map(
    (jiraPriority): SelectOption<number> => ({
      title: jiraPriority.name || '',
      value: jiraPriority.idPriority || 0,
    })
  ) || [];

export const getJiraStatusesOptions = (jiraStatuses?: JiraStatus[]) =>
  jiraStatuses?.map(
    (jiraStatus): SelectOption<number> => ({
      title: jiraStatus.name || '',
      value: jiraStatus.idStatus || 0,
    })
  ) || [];

export const getJiraTicketTypesForCurrentOptions = (
  jiraTicketType?: JiraTicketType[]
) =>
  jiraTicketType?.map(({ name, idType }) => ({
    title: name,
    value: idType,
  })) || [];

export const getJiraPrioritiesBySystemIdOptions = createSelector<
  RootState,
  JiraPriority[] | undefined,
  SelectOption<number>[]
>([getJiraPrioritiesBySystemId], (jiraPriorities) => {
  return Object.values(Priority).map((priority) => {
    const findJiraPriority = jiraPriorities?.find((item) =>
      item.ticketPriorities?.includes(priority)
    );

    return {
      title: findJiraPriority?.name || '',
      value: findJiraPriority?.idPriority || 0,
    };
  });
});

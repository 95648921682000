import { v4 as uuidv4 } from 'uuid';

import { Environment, ProcedureType, TicketType } from 'core/types';

import { SystemFormData } from './types';

export const TICKET_TYPE: TicketType[] = [
  {
    id: uuidv4(),
    title: 'Инцидент',
    value: ProcedureType.INCIDENT,
  },
  {
    id: uuidv4(),
    title: 'Консультация',
    value: ProcedureType.CONSULTATION,
  },
];

export const ENVIRONMENT = ['TEST', 'DEV', 'PROD'];

export const ENVIRONMENT_MAINS: Environment[] = ENVIRONMENT.map(
  (environment) => ({
    id: uuidv4(),
    name: environment,
    description: environment,
  })
);

export const DEFAULT_CREATE_SYSTEM_FORM_VALUES: SystemFormData = {
  system: {
    title: '',
    versionTitle: '',
    description: '',
    organization: null,
    index: '',
  },
  ticketType: TICKET_TYPE,
  environment: ENVIRONMENT_MAINS,
};

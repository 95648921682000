import cn from 'clsx';
import { nanoid } from 'nanoid';
import React, { forwardRef, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { AttachmentIcon, CloseIcon, SendIcon } from 'assets/icons';
import { Card } from 'components/Card';
import { FileChip } from 'components/FileChip';
import { FileInput } from 'components/FileInput';
import { IconButton } from 'components/IconButton';
import { Loader } from 'components/Loader';
import { Popover } from 'components/Popover';
import { RoundButton } from 'components/RoundButton';
import { Select } from 'components/Select';
import { MenuItem } from 'components/Select/components/MenuItem';
import { RenderMenuItemProps } from 'components/Select/types';
import { ToggleButton } from 'components/ToggleButton';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { DataEmpty } from 'core/components/DataEmpty';
import { MAX_FILE_SIZE } from 'core/constants';
import { getUserId } from 'features/Auth/ducks/selectors';
import { TicketCommentsDropContent } from 'features/Ticket/components/TicketCommentsDropContent';
import { useAutosizeTextArea } from 'features/Ticket/hooks/useAutosizeTextArea';
import { useTicketComments } from 'features/Ticket/hooks/useTicketComments';
import { useTicketCommentsForm } from 'features/Ticket/hooks/useTicketCommentsForm';
import { AttachmentDeleteType } from 'features/Ticket/types';

import { AssessmentInteractionProcess } from '../AssessmentInteractionProcess';
import { PreviewImageModal } from '../PreviewImageModal';
import { TicketComment } from '../TicketComment';
import { TicketAttachments } from '../TicketInfo/components/TicketAttachments';
import { getAttachmentFilesComment } from '../TicketInfo/utils';
import { TicketSpoiler } from '../TicketSpoiler';

import styles from './TicketComments.module.scss';

const renderMenuItem = ({
  onChange,
  option,
  selected,
}: RenderMenuItemProps) => {
  return (
    <MenuItem
      onClick={onChange}
      selected={selected}
      className={styles.createTicket__option}
    >
      <span className={styles.createTicket__optionTitle}>#{option.title}</span>
      <div className={styles.createTicket__optionTheme}>{option.meta}</div>
    </MenuItem>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const TicketComments = forwardRef<HTMLDivElement>((_props, ref) => {
  const currentUserId = useSelector(getUserId);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const content = document.querySelector(`.${styles.ticketComments__content}`);

  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  const {
    state: {
      isAttachments,
      isFileUploadOpen,
      messageHistory,
      myFiles,
      isAccessToChangeStatus,
      ticketCommentsAttachments,
      isSolutionAccepted,
      isAssessmentInteractionProcess,
      spoilerMessage,
      loadingComments,
      isPopoverOpen,
    },
    methods: {
      toggleAttachments,
      toggleFileUpload,
      setFileHandler,
      removeFile,
      setMyFiles,
      onErrorMaxFileSize,
      togglePopover,
    },
  } = useTicketComments({ content });

  const {
    state: {
      control,
      isValid,
      isDirty,
      currentMessage,
      ticketsOptions,
      pageNum,
      totalPagesTickets,
      loadingTickets,
      inputValue,
      isTicketsIds,
      isTicketDeleted,
    },
    methods: {
      handleKeyDown,
      formSubmitHandler,
      toggleIsTicketsIds,
      handleInput,
      hideInputRow,
      setNextPage,
    },
  } = useTicketCommentsForm();

  const { imageFiles, documentFiles } = getAttachmentFilesComment(
    ticketCommentsAttachments
  );

  const toggleIsImageModal = () => {
    setIsImageModal((prevState) => !prevState);
  };

  const handleInitialImageIndex = (index: number) => {
    setInitialImageIndex(index);
  };

  useAutosizeTextArea(textAreaRef.current, buttonRef.current, currentMessage);

  const commentsList = messageHistory?.map((comment) => {
    const { message, created, userDto, spoiler } = comment;

    const isMyTicket = currentUserId === userDto?.id;

    if (message && !spoiler) {
      return (
        <CSSTransition
          key={created}
          timeout={1000}
          classNames={styles.ticketComments__comment}
          className={cn(styles.ticketComments__comment, {
            [styles.ticketComments__comment_my]: isMyTicket,
          })}
        >
          <TicketComment
            comment={comment}
            isAccessToChangeStatus={isAccessToChangeStatus}
          />
        </CSSTransition>
      );
    }
    return null;
  });

  const commentFileList = Boolean(myFiles.length) && (
    <div className={styles.ticketComments__fileNamesWrapper}>
      {myFiles.map((file: File) => {
        return (
          <FileChip
            key={nanoid()}
            title={file.name}
            onDelete={removeFile(file)}
            className={styles.ticketComments__fileBlock}
          />
        );
      })}
    </div>
  );

  const commentsAttachments = ticketCommentsAttachments?.length ? (
    <TicketAttachments
      documentFiles={documentFiles}
      imageFiles={imageFiles}
      toggleIsModal={toggleIsImageModal}
      handleInitialImageIndex={handleInitialImageIndex}
      size={Size.s}
      classNameAttachmentsColumn={styles.ticketComments__attachmentFiles}
      attachmentDeleteType={AttachmentDeleteType.COMMENTS}
    />
  ) : (
    <DataEmpty />
  );

  const imageModal = isImageModal && (
    <PreviewImageModal
      toggleModal={toggleIsImageModal}
      initialImageIndex={initialImageIndex}
      imageFiles={imageFiles}
    />
  );

  const fileUpload = isFileUploadOpen && !loadingComments && (
    <div className={styles.ticketComments__fileLoadWrapper}>
      <Card className={styles.ticketComments__fileLoadCard}>
        <FileInput
          fileHandler={setFileHandler}
          myFiles={myFiles}
          setMyFiles={setMyFiles}
          showFiles={false}
          maxFileSize={MAX_FILE_SIZE}
          onErrorMaxFileSize={onErrorMaxFileSize}
          classNameWrapper={styles.ticketComments__fileUpload}
          classNameIcon={styles.ticketComments__fileUploadIcon}
          classNameTitle={styles.ticketComments__fileUploadTitle}
          classNameSubTitle={styles.ticketComments__fileUploadSubTitle}
          classNameButton={styles.ticketComments__fileUploadButton}
        />
      </Card>
    </div>
  );

  const assessmentInteractionProcess = isAssessmentInteractionProcess && (
    <CSSTransition
      timeout={1000}
      classNames={styles.ticketComments__comment}
      className={cn(
        styles.ticketComments__comment,
        styles.ticketComments__comment_my
      )}
    >
      <AssessmentInteractionProcess />
    </CSSTransition>
  );

  const spoiler = spoilerMessage && <TicketSpoiler comment={spoilerMessage} />;

  const commentsContent = !isFileUploadOpen && !loadingComments && (
    <TransitionGroup
      enter
      exit
      appear
      className={styles.ticketComments__content}
    >
      {spoiler}
      {assessmentInteractionProcess}
      {commentsList}
    </TransitionGroup>
  );

  const loader = loadingComments && (
    <div className={styles.ticketComments__loader}>
      <Loader />
    </div>
  );

  return (
    <div className={styles.ticketComments} ref={ref}>
      <Card className={styles.ticketComments__head}>
        <Typography variant={TypographyVariants.h5}>Комментарии</Typography>
        <ToggleButton
          icon={<AttachmentIcon />}
          onClick={toggleAttachments}
          isActive={isAttachments}
        />
      </Card>
      <div className={styles.ticketComments__allContent}>
        <div
          className={cn(styles.ticketComments__contentWrapper, {
            [styles.ticketComments__contentWrapper_hidden]: isAttachments,
          })}
        >
          {fileUpload}
          {commentsContent}
          {loader}
          <Card
            className={cn(styles.ticketComments__inputWrapper, {
              [styles.ticketComments__inputWrapper_attachmentsOpen]:
                isAttachments,
            })}
          >
            <div className={styles.ticketComments__inputField}>
              <button
                ref={buttonRef}
                onClick={toggleFileUpload}
                disabled={isTicketDeleted}
                className={cn(styles.ticketComments__attachmentInput, {
                  [styles.ticketComments__attachmentInput_open]:
                    isFileUploadOpen,
                })}
              >
                <AttachmentIcon />
              </button>
              <div className={styles.ticketComments__inputBlock}>
                <Controller
                  control={control}
                  name="message"
                  key="message"
                  rules={{
                    required: true,
                    maxLength: {
                      value: 1024,
                      message: 'Текст сообщения не может быть пустым',
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <textarea
                        ref={textAreaRef}
                        className={styles.ticketComments__input}
                        placeholder="Начать беседу"
                        onKeyDown={(event) => {
                          handleKeyDown(event);
                          if (
                            event.key === 'Enter' &&
                            (event.ctrlKey || event.metaKey)
                          ) {
                            field.onChange(`${currentMessage}\n`);
                          }
                        }}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        value={field.value}
                        disabled={
                          isSolutionAccepted ||
                          loadingComments ||
                          isTicketDeleted
                        }
                      />
                    );
                  }}
                />
                <form
                  className={styles.ticketComments__sendButtons}
                  onSubmit={formSubmitHandler}
                >
                  <Popover
                    isOpen={isPopoverOpen}
                    togglePopover={togglePopover}
                    disabled={isTicketDeleted}
                    content={
                      <TicketCommentsDropContent
                        toggleIsTicketsIds={toggleIsTicketsIds}
                        togglePopover={togglePopover}
                        isTicketsIds={isTicketsIds}
                      />
                    }
                    positions={['top', 'left', 'bottom', 'right']}
                    align="end"
                  />
                  <IconButton
                    disabled={
                      !isDirty ||
                      !isValid ||
                      isSolutionAccepted ||
                      !currentMessage?.trim()?.length ||
                      loadingComments
                    }
                    icon={<SendIcon />}
                    appearance="flat"
                    className={styles.ticketComments__sendButtonsSend}
                  />
                </form>
              </div>
            </div>
            {isTicketsIds && (
              <div className={styles.ticketComments__multiSelectWrapper}>
                <Controller
                  control={control}
                  name="ticketsIds"
                  render={({ field }) => {
                    return (
                      <Select<string>
                        options={ticketsOptions}
                        onChangeInput={handleInput}
                        currentPage={pageNum}
                        totalPage={totalPagesTickets}
                        setNextPage={setNextPage}
                        inputValue={inputValue}
                        value={field.value}
                        onChange={field.onChange}
                        mobileModalTitle="тикеты"
                        loading={loadingTickets}
                        isSearchable
                        infiniteScrollable
                        isMulti
                        isChip
                        renderMenuItem={renderMenuItem}
                        dropdownContentClassName={
                          styles.ticketComments__selectMenu
                        }
                      />
                    );
                  }}
                />
                <div className={styles.ticketComments__editButtons}>
                  <RoundButton
                    icon={<CloseIcon />}
                    onClick={hideInputRow}
                    type="button"
                    className={styles.ticketComments__editClose}
                  />
                </div>
              </div>
            )}
            {commentFileList}
          </Card>
        </div>
        <Card
          className={cn(styles.ticketComments__attachments, {
            [styles.ticketComments__attachments_closed]: !isAttachments,
          })}
        >
          <Typography
            variant={TypographyVariants.o}
            className={styles.ticketComments__attachmentsTitle}
          >
            Вложения
          </Typography>
          <div className={styles.ticketComments__attachmentsContainer}>
            {commentsAttachments}
          </div>
        </Card>
      </div>
      {imageModal}
    </div>
  );
});

import React from 'react';
import { LinkProps } from 'react-router-dom';

import { Props as ConditionBlockProps } from 'components/ConditionBlock';
import { Props as IconBlockProps } from 'components/IconBlock';

export enum RenderTypes {
  NOTIFICATION_LINK = 'NOTIFICATION_LINK',
  CONDITION = 'CONDITION',
  ICON_BLOCK = 'ICON_BLOCK',
}

export type CellContent = string | JSX.Element;

export type TableColumn = {
  title: CellContent;
  fieldName: string;
  renderType?: RenderTypes;
  sortField?: string;
};

type List<T> = Array<T>;

export type TableColumns = List<TableColumn>;

export enum Sort {
  DESC = 'DESC',
  ASC = 'ASC',
}

export type DataTypes =
  | CellContent
  | LinkProps
  | ConditionBlockProps
  | React.FC<IconBlockProps>
  | boolean
  | undefined;

export type ObjDataType = {
  [key: string]: DataTypes;
};

export type CheckboxProps = {
  withCheckbox?: boolean;
  onChangeCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectAllCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { getAlerts } from '../../ducks/selectors';

import styles from './AlertsLayout.module.scss';
import { AlertItem } from './components/AlertItem';

export const AlertsLayout: React.FC = ({ children }) => {
  const alerts = useSelector(getAlerts) || [];

  const alertsList =
    Boolean(alerts?.length) &&
    [...alerts]?.splice(-3).map((alert) => (
      <CSSTransition
        key={alert.id}
        timeout={500}
        className={styles.alertsLayout__toast}
        classNames={styles.alertsLayout__toast}
        unmountOnExit
      >
        <AlertItem alert={alert} timeout={3000} />
      </CSSTransition>
    ));

  return (
    <>
      <TransitionGroup exit enter appear className={styles.alertsLayout}>
        {alertsList}
      </TransitionGroup>
      {children}
    </>
  );
};
